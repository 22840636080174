import React from "react";
import { connect } from "react-redux";

import { selectParameter, clearSelectedParameter } from "actions";
import { Outlet } from "react-router-dom";
import ParameterTopHeader from "./ParameterTopHeader";
import { compose } from "redux";
import withRouter from "../../withRouter";

/* Data component: fetch relevant data for the current parameter */
const ParameterSetup = ({ parameterId, ...props }) => {
  const fetchData = () => {
    props.selectParameter(parameterId);
  };

  React.useEffect(() => {
    props.clearSelectedParameter();
    fetchData();
  }, []);

  return (
    <div>
      <ParameterTopHeader />
      <Outlet />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const parameterId = ownProps.params.id;

  return {
    parameterId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    selectParameter,
    clearSelectedParameter,
  }),
)(ParameterSetup);
