import React from "react";
import "./style.css";
import { Card } from "react-bootstrap";
import { activeMortalityAssessmentSelector } from "../../../../reducers";
import { connect } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { ResponsiveContainer } from "recharts";

const MortalityAssessmentCurveGraphs = ({ mortalityAssessmentInfo, ...props }) => {
  const curvesData = mortalityAssessmentInfo?.curve_data;
  const initialAge = mortalityAssessmentInfo.curve_assessment_age;
  if (!curvesData) return null;
  const durationsMap = [];
  curvesData.forEach(row => {
    if (durationsMap === []) {
      durationsMap.push({ x: row.duration, y: 0 });
    } else if (durationsMap.some(element => element.x === row.duration)) {
    } else {
      durationsMap.push({ x: row.duration, y: 0, age: Number(initialAge) + row.duration });
    }
  });

  let qxCurvesData = durationsMap.map(row => {
    const accumulated_value = curvesData.reduce((accumulator, currentElem) => {
      if (currentElem.duration === row.x) {
        return currentElem.qx + accumulator;
      } else {
        return accumulator;
      }
    }, 0);
    return { x: row.x, y: accumulated_value / 12, age: row.age };
  });
  qxCurvesData.unshift({ x: 0, y: 0, age: Number(initialAge) });

  let survivalCurvesData = durationsMap.map(row => {
    const accumulated_value = curvesData.reduce((accumulator, currentElem) => {
      if (currentElem.duration === row.x) {
        return currentElem.eom_lives + accumulator;
      } else {
        return accumulator;
      }
    }, 0);
    return { x: row.x, y: accumulated_value / 12, age: row.age };
  });
  survivalCurvesData.unshift({ x: 0, y: 1, age: Number(initialAge) });

  let deathCurveData = durationsMap.map(row => {
    const accumulated_value = curvesData.reduce((accumulator, currentElem) => {
      if (currentElem.duration === row.x) {
        return currentElem.monthly_deaths + accumulator;
      } else {
        return accumulator;
      }
    }, 0);
    return { x: row.x, y: accumulated_value, age: row.age };
  });
  deathCurveData.unshift({ x: 0, y: 0, age: Number(initialAge) });

  const cutOffPoint = survivalCurvesData.findIndex(elem => elem.y < 0.001);
  if (cutOffPoint > 1) {
    qxCurvesData = qxCurvesData.slice(0, cutOffPoint);
    survivalCurvesData = survivalCurvesData.slice(0, cutOffPoint);
    deathCurveData = deathCurveData.slice(0, cutOffPoint);
  }

  const getChartOptions = title => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              try {
                const parsed_label = context.label.split(",");
                return `year: ${parsed_label[0]} - age: ${parsed_label[1]} = ${context.formattedValue}`;
              } catch {
                return context.dataset.label || "";
              }
            },
          },
        },
        title: {
          display: true,
          text: title,
        },
        scales: {
          xAxis: {
            // The axis for this scale is determined from the first letter of the id as `'x'`
            // It is recommended to specify `position` and / or `axis` explicitly.
            type: "number",
          },
        },
      },
    };
  };

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
  const curvesLabels = qxCurvesData.map(elem => [elem.x, elem.age]);
  const qxCurvesDataY = qxCurvesData.map(elem => elem.y);
  const survivalCurvesDataY = survivalCurvesData.map(elem => elem.y);
  const deathCurveDataY = deathCurveData.map(elem => elem.y);

  const getCurvesDataObj = (labels, data, title) => {
    return {
      labels: labels,
      datasets: [
        {
          label: title || "",
          data: data,
          borderColor: "#00b050",
          backgroundColor: "#00b050",
          cubicInterpolationMode: "monotone",
          fill: false,
        },
      ],
    };
  };

  return (
    <Card>
      <Card.Body style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <ResponsiveContainer width="100%" height={400}>
            <Line options={getChartOptions("qx Curve")} data={getCurvesDataObj(curvesLabels, qxCurvesDataY, "qx")} />
          </ResponsiveContainer>
        </div>
        <div>
          <ResponsiveContainer width="100%" height={400}>
            <Line
              options={getChartOptions("Survival Curve")}
              data={getCurvesDataObj(curvesLabels, survivalCurvesDataY, "EOM Lives")}
            />
          </ResponsiveContainer>
        </div>
        <div>
          <ResponsiveContainer width="100%" height={400}>
            <Line
              options={getChartOptions("Death Curve Curve")}
              data={getCurvesDataObj(curvesLabels, deathCurveDataY, "Monthly Deaths")}
            />
          </ResponsiveContainer>
        </div>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    mortalityAssessmentInfo: activeMortalityAssessmentSelector(state),
  };
};

export default connect(mapStateToProps, {})(MortalityAssessmentCurveGraphs);
