import React from "react";
import { useField, useFormikContext } from "formik";
import { Form, InputGroup, Col } from "react-bootstrap";

import createMaskedInput from "./createMaskedInput";
import { validateLength, getValidationClassName } from "./validation";
import Label from "./Label";
import FieldErrors from "./FieldErrors";
import { getViewMode } from "../../utils";

const PHONE_LENGTH = 10;

export default ({ label, md = "", ...props }) => {
  const [field, meta] = useField({
    ...props,
    validate: validateLength(PHONE_LENGTH, /\D/g),
  });
  const { setFieldValue } = useFormikContext();
  const isViewMode = getViewMode();
  const isDisabled = props.disabled || isViewMode;

  const d = /\d/;
  const phoneMask = ["(", d, d, d, ")", " ", d, d, d, "-", d, d, d, d];

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <div style={{ marginLeft: 1, marginRight: 3 }}>{label}</div>
            {props.icon}
          </InputGroup.Text>
        </InputGroup.Prepend>
        {createMaskedInput({
          mask: phoneMask,
          field,
          setFieldValue,
          className: getValidationClassName(meta),
          disabled: isDisabled,
        })}
        <FieldErrors meta={meta} />
      </InputGroup>
    </Form.Group>
  );
};
