import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { TextInput, FormFilter, SubmitSearchButton, ClearFiltersButton } from "components/forms";
import { fetchManagerAgents, fetchSynonyms, storeSynonymsFiltersData } from "actions";

const RiskxSynonymsFilterForm = ({ fetchSynonyms, ...props }) => {
  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        const filters = {
          ...values,
        };
        props.storeSynonymsFiltersData(filters);
        await fetchSynonyms(filters);
        setSubmitting(false);
      }}
      onReset={async (values, { setSubmitting }) => {
        props.storeSynonymsFiltersData({});
        await fetchSynonyms();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, resetForm }) => {
        return (
          <FormFilter title={"SYNONYM SEARCH"} onSubmit={handleSubmit}>
            <Form.Row>
              <TextInput label="Synonym Search" name="name" />
              <TextInput label="Parameter Search" name="parameter_name" />
            </Form.Row>
            <SubmitSearchButton />
            <ClearFiltersButton onClick={resetForm} />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  fetchSynonyms,
  storeSynonymsFiltersData,
  fetchManagerAgents,
})(RiskxSynonymsFilterForm);
