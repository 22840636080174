/* Main component to render medical conditions
 *
 * Each medical category has it's own "schema": a set of possible medical conditions.
 * The already added conditions will be shown while the other conditions will be available
 * in an html select (the schema is retrieved from the server).
 */

import React from "react";
import { FieldArray } from "formik";
import MedicalCondition, { AddConditionSelect } from "./MedicalCondition";
import { predictiveMedicalConditionsSchemaSelector } from "reducers";
import { useSelector } from "react-redux";
import { partial } from "utils";

const MedicalConditionsList = ({ category, categoryIndex }) => {
  const conditions = category.conditions;

  // Get the "remaining conditions" (possible_conditions - category.conditions) to show
  // in the select
  const schema = useSelector(predictiveMedicalConditionsSchemaSelector).find(c => c.name === category.name);
  let remainingConditions = [];
  if (schema) {
    remainingConditions = schema.possible_conditions.filter(pc => {
      return conditions.find(c => c.name === pc.name) === undefined;
    });
  }

  return (
    <FieldArray
      name={`medical_info.conditions[${categoryIndex}].conditions`}
      validateOnChange={false}
      render={arrayHelpers => (
        <>
          {conditions.map((condition, conditionIndex) => (
            <MedicalCondition
              condition={condition}
              removeFunc={partial(arrayHelpers.remove, conditionIndex)}
              categoryIndex={categoryIndex}
              conditionIndex={conditionIndex}
              key={condition.condition}
            />
          ))}
          <AddConditionSelect
            conditions={remainingConditions}
            onChange={option =>
              arrayHelpers.push({
                name: option.value,
              })
            }
          />
        </>
      )}
    />
  );
};

export default MedicalConditionsList;
