import {
  SELECT_ENTITY_DILIGENCE,
  FETCH_ENTITY_DILIGENCES,
  EDIT_ENTITY_DILIGENCE,
  CREATE_ENTITY_DILIGENCE,
  DELETE_ENTITY_DILIGENCE,
} from "actions/types";
import { removeKey } from "utils.js";
import { arrayToObjectIndexedById } from "reducers/utils";

export const defaultState = {
  activeEntityDiligenceId: null,
  entityDiligences: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_ENTITY_DILIGENCE:
      return { ...state, activeEntityDiligenceId: action.payload };

    case FETCH_ENTITY_DILIGENCES:
      return { ...state, entityDiligences: arrayToObjectIndexedById(action.payload) };

    case EDIT_ENTITY_DILIGENCE:
    case CREATE_ENTITY_DILIGENCE:
      return {
        ...state,
        entityDiligences: {
          ...state.entityDiligences,
          [action.payload.id]: action.payload,
        },
      };

    case DELETE_ENTITY_DILIGENCE: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        entityDiligences: removeKey(state.entityDiligences, toDeleteId),
      };
      if (state.activeEntityDiligenceId === toDeleteId) {
        newState.activeEntityDiligenceId = null;
      }
      return newState;
    }

    default:
      return state;
  }
};

export const entityDiligencesSelector = state => Object.values(state.entityDiligences);

export const activeEntityDiligenceSelector = state => {
  const { activeEntityDiligenceId, entityDiligences } = state;

  if (activeEntityDiligenceId === null) {
    return null;
  }
  return entityDiligences[activeEntityDiligenceId];
};

export const entityDiligencesForActiveOwnerSelector = (state, activeOwnerId) => {
  const entityDiligences = Object.values(state.entityDiligences);
  return entityDiligences.filter(le => le.entity === activeOwnerId);
};
