import React from "react";
import { connect } from "react-redux";

import { MANAGE_ENTITY_MODAL } from "components/modals";
import { activeAccountSelector } from "reducers";
import FullPageModal from "../../components/modals/FullPageModal";
import { selectAccountCaseHierarchy } from "../../actions";
import ManageEntityModalForm from "./information/ManageEntityModalForm";

const ManageEntityModal = ({ entityId, accountType, ...props }) => {
  return (
    <FullPageModal modalType={MANAGE_ENTITY_MODAL} className={"wide"}>
      <ManageEntityModalForm accountType={accountType} entityId={entityId} />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    accountInfo: activeAccountSelector(state),
  };
};

export default connect(mapStateToProps, {
  selectAccountCaseHierarchy,
})(ManageEntityModal);
