import React from "react";
import { connect } from "react-redux";

import {
  activeCaseSelector,
  managementChoicesSelector,
  agentsChoicesSelector,
  insuredListSelector,
  lifeExpectanciesSelector,
  shouldSaveSelector,
} from "reducers";

import { percentageCell } from "components/table";
import { selectAccount, pricingSetup, clearSelectedAccount, clearSelectedAccountHierarchy } from "../../../actions";
import CaseForm from "./CaseForm";
import * as notifications from "../../../notifications";

const CaseInfo = ({
  caseInfo,
  insuredInfo,
  lifeExpectancyInfo,
  isActive,
  case_title,
  pricingSetup,
  auctionNotesFormSubmitRef,
  caseOverviewFormSubmitRef,
  caseTradingSummaryFormSubmitRef,
  closingDetailFormSubmitRef,
  ...props
}) => {
  if (!caseInfo) {
    return null;
  }

  React.useEffect(() => {
    if (caseInfo.submission_id) {
      let title = `LifeRoc | ${caseInfo.submission_id}`;
      if (document.title === "LifeRoc") {
        document.title = title;
      }
      if (caseInfo.hierarchy) {
        props.selectAccount(caseInfo.hierarchy.account_id);
      } else {
        props.clearSelectedAccount();
        props.clearSelectedAccountHierarchy();
      }
    }
  }, [caseInfo]);

  React.useEffect(() => {
    if (props.shouldSave) {
      caseOverviewFormSubmitRef.current.click();
      notifications.success("Case saved successfully");
    }
  }, [props.shouldSave]);

  const getAge = dateString => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const getInternalMM = insured_id => {
    let rate = "";
    for (let lifeExpectancy of lifeExpectancyInfo) {
      if (lifeExpectancy.insured === insured_id) {
        if (lifeExpectancy.adjusted_mortality_rating || lifeExpectancy.mortality_rating) {
          return percentageCell({
            value: lifeExpectancy.adjusted_mortality_rating || lifeExpectancy.mortality_rating,
          });
        }
      }
    }
    return rate;
  };

  let insured = {};
  let insured2 = {};

  if (insuredInfo.length > 0) {
    insured = {
      name: `${insuredInfo[0].last_name}, ${insuredInfo[0].first_name}`,
      gender: insuredInfo[0].gender,
      age: getAge(insuredInfo[0].date_of_birth),
      mm: getInternalMM(insuredInfo[0].id),
    };
    if (insuredInfo.length === 2) {
      insured2 = {
        name: `${insuredInfo[1].last_name}, ${insuredInfo[1].first_name}`,
        gender: insuredInfo[1].gender,
        age: getAge(insuredInfo[1].date_of_birth),
        mm: getInternalMM(insuredInfo[1].id),
      };
    }
  }

  return (
    <div
      className={`section-card shadowed ${isActive && "active"} case-section`}
      onClick={props.handleClick}
      style={{ marginTop: "5.5rem" }}
    >
      <CaseForm submitRef={caseOverviewFormSubmitRef} insured={insured} insured2={insured2} />
    </div>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const agentsChoices = agentsChoicesSelector(state);

  return {
    caseInfo: activeCaseSelector(state),
    insuredInfo: insuredListSelector(state),
    lifeExpectancyInfo: lifeExpectanciesSelector(state),
    processTypeChoices: choices.case_process_types,
    providerChoices: choices.case_providers,
    agencyChoices: choices.agencies,
    salesRepChoices: choices.case_sales_representatives,
    caseManagersChoices: agentsChoices.case_managers,
    accountChoices: choices.accounts,
    shouldSave: shouldSaveSelector(state),
  };
};

export default connect(mapStateToProps, {
  pricingSetup,
  selectAccount,
  clearSelectedAccount,
  clearSelectedAccountHierarchy,
})(CaseInfo);
