import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { EMAIL_TEMPLATE_MODAL } from "components/modals";
import { activeEmailTemplateSelector, activeEmailTemplateTypeSelector } from "reducers";
import EmailTemplateForm from "./EmailTemplateForm";
import { EMAIL_TEMPLATE_TYPE_NAMES } from "../constants";
import SingleFileDropZone from "components/SingleFileDropZone";
import { uploadEmailTemplateFile, deleteEmailTemplateFile } from "actions";

const EmailTemplateModal = ({ activeEmailTemplate, activeEmailTemplateType, ...props }) => {
  let activeEmailTemplateId = activeEmailTemplate?.id;
  const submitRef = React.useRef();
  let typeName = EMAIL_TEMPLATE_TYPE_NAMES[activeEmailTemplateType] || "";
  let title = activeEmailTemplateId ? `Edit ${typeName} Email Template` : `New ${typeName} Email Template`;

  return (
    <FullPageModal modalType={EMAIL_TEMPLATE_MODAL} title={title}>
      <EmailTemplateForm submitRef={submitRef} />
      {activeEmailTemplateId ? (
        <div>
          <SingleFileDropZone
            currentFile={activeEmailTemplate?.file || null}
            uploadFile={file => props.uploadEmailTemplateFile(activeEmailTemplateId, file)}
            deleteFile={() => props.deleteEmailTemplateFile(activeEmailTemplateId)}
            fileDownloadUrl={`/life_settlement/api/email_template/`}
          />
        </div>
      ) : null}
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    activeEmailTemplate: activeEmailTemplateSelector(state) || {},
    activeEmailTemplateType: activeEmailTemplateTypeSelector(state),
  };
};

export default connect(mapStateToProps, {
  uploadEmailTemplateFile,
  deleteEmailTemplateFile,
})(EmailTemplateModal);
