import React from "react";
import { FaTrash } from "react-icons/fa";

import IconLink from "../IconLink";
import { getViewMode } from "../../utils";

export default props => {
  const isViewMode = getViewMode();

  return (
    !isViewMode && (
      <IconLink Icon={FaTrash} {...props} tooltip="Delete" iconConfig={{ className: "table--action-icon btn--red" }} />
    )
  );
};
