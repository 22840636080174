import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { EditorField, SubmitButton } from "components/forms";
import { activeParameterSelector, shouldSaveSelector } from "reducers";
import { Form } from "react-bootstrap";
import { editParameter, unsetShouldSave, selectSynonym, showModal } from "actions";
import { partial } from "utils";
import * as notifications from "notifications";
import ParameterDetailOverviewForm from "./ParameterDetailOverviewForm";

const ParameterLicenseForm = ({ parameterInfo, ...props }) => {
  if (!parameterInfo) {
    // Still didn't fetch data
    return null;
  }

  const initialValues = {
    category: parameterInfo.category,
    sub_category: parameterInfo.sub_category,
    name: parameterInfo.name,
    code: parameterInfo.code,
    date_created: parameterInfo.date_created,
    last_edit_date: parameterInfo.last_edit_date,
    created_by_name: parameterInfo.created_by_name,
    last_edited_by_name: parameterInfo.last_edited_by_name,
    description: parameterInfo.description,
    aetiology: parameterInfo.aetiology,
    symptoms: parameterInfo.symptoms,
    treatment: parameterInfo.treatment,
    prognosis: parameterInfo.prognosis,
    epidemiology: parameterInfo.epidemiology,
    search_terms: parameterInfo.search_terms,
  };

  const submitRef = React.useRef();

  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);

        const method = partial(props.editParameter, parameterInfo.id);

        try {
          await method(values);
          notifications.success("Parameter saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Parameter");
        }
        props.unsetShouldSave();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit} className={"form shadowed"} {...props}>
            <ParameterDetailOverviewForm />
            <Form.Row>
              <EditorField label='Description <block type="description">' name="description" />
            </Form.Row>
            <Form.Row>
              <EditorField label='Aetiology <block type="aetiology">' name="aetiology" />
            </Form.Row>
            <Form.Row>
              <EditorField label='Signs & Symptoms <block type="signsandsymptoms">' name="symptoms" />
            </Form.Row>
            <Form.Row>
              <EditorField label='Treatment <block type="treatment">' name="treatment" />
            </Form.Row>
            <Form.Row>
              <EditorField label='Prognosis <block type="prognosis">' name="prognosis" />
            </Form.Row>
            <Form.Row>
              <EditorField label='Epidemiology <block type="epidemiology">' name="epidemiology" />
            </Form.Row>
            <Form.Row>
              <EditorField label="Search Terms" name="search_terms" />
            </Form.Row>
            <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    shouldSave: shouldSaveSelector(state),
    parameterInfo: activeParameterSelector(state),
  };
};

export default connect(mapStateToProps, {
  editParameter,
  unsetShouldSave,
  selectSynonym,
  showModal,
})(ParameterLicenseForm);
