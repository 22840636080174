import { moneyDisplay, parseFloatFromString } from "../../../../utils";

export const calcHighestOffer = (marketActivities_param = [], funderActivities_param = [], returnInt = false) => {
  const marketActivities = marketActivities_param.filter(
    activity => activity.provider && activity.gross_offer && (activity.type === "B" || activity.type === "BC"),
  );
  // get a list of funder activities of type Bid or Bid Contingent
  const funderActivities = funderActivities_param.filter(
    activity => activity.funder && activity.total_offer && (activity.type === "B" || activity.type === "BC"),
  );

  const usFormatter = new Intl.NumberFormat("en-US");
  let highestMarketBid, highestFunderBid;
  // get highest bid
  highestMarketBid = marketActivities.reduce(
    (a, b) => (parseFloatFromString(a.gross_offer) > parseFloatFromString(b.gross_offer) ? a : b),
    { gross_offer: 0 },
  );
  highestFunderBid = funderActivities.reduce(
    (a, b) => (parseFloatFromString(a.total_offer) > parseFloatFromString(b.total_offer) ? a : b),
    { total_offer: 0 },
  );
  var total_offer, gross_offer;
  try {
    total_offer = highestFunderBid.total_offer.replaceAll(",", "");
  } catch (error) {
    total_offer = highestFunderBid.total_offer;
  }
  try {
    gross_offer = highestMarketBid.gross_offer.replaceAll(",", "");
  } catch (error) {
    gross_offer = highestMarketBid.gross_offer;
  }
  if (total_offer >= gross_offer) {
    if (returnInt) {
      return parseFloatFromString(total_offer);
    } else {
      return moneyDisplay(usFormatter.format(total_offer));
    }
  } else if (total_offer < gross_offer) {
    if (returnInt) {
      return parseFloatFromString(gross_offer);
    } else {
      return moneyDisplay(usFormatter.format(gross_offer));
    }
  } else if (returnInt) {
    return 0;
  } else {
    return "";
  }
};

export const calcMarketHighestOffer = (marketActivities_param = [], returnInt = false) => {
  const marketActivities = marketActivities_param.filter(
    activity => activity.provider && activity.gross_offer && (activity.type === "B" || activity.type === "BC"),
  );
  const usFormatter = new Intl.NumberFormat("en-US");
  let highestMarketBid;
  // get highest bid
  highestMarketBid = marketActivities.reduce(
    (a, b) => (parseFloatFromString(a.gross_offer) > parseFloatFromString(b.gross_offer) ? a : b),
    { gross_offer: 0 },
  );
  var gross_offer;
  try {
    gross_offer = highestMarketBid.gross_offer.replaceAll(",", "");
  } catch (error) {
    gross_offer = highestMarketBid.gross_offer;
  }
  if (gross_offer) {
    if (returnInt) {
      return parseFloatFromString(gross_offer);
    } else {
      return moneyDisplay(usFormatter.format(gross_offer));
    }
  } else if (returnInt) {
    return 0;
  } else {
    return "";
  }
};

export const calcFunderHighestOffer = (funderActivities_param = [], returnInt = false) => {
  const funderActivities = funderActivities_param.filter(
    activity => activity.funder && activity.total_offer && (activity.type === "B" || activity.type === "BC"),
  );
  const usFormatter = new Intl.NumberFormat("en-US");
  let highestFunderBid;
  // get highest bid
  highestFunderBid = funderActivities.reduce(
    (a, b) => (parseFloatFromString(a.total_offer) > parseFloatFromString(b.total_offer) ? a : b),
    { total_offer: 0 },
  );
  var total_offer;
  try {
    total_offer = highestFunderBid.total_offer.replaceAll(",", "");
  } catch (error) {
    total_offer = highestFunderBid.total_offer;
  }
  if (total_offer) {
    if (returnInt) {
      return parseFloatFromString(total_offer);
    } else {
      return moneyDisplay(usFormatter.format(total_offer));
    }
  } else if (returnInt) {
    return 0;
  } else {
    return "";
  }
};

export const getHighestFunder = (funderActivities_param = []) => {
  const funderActivities = funderActivities_param.filter(
    activity => activity.funder && activity.total_offer && (activity.type === "B" || activity.type === "BC"),
  );
  return funderActivities.reduce(
    (a, b) => (parseFloatFromString(a.total_offer) > parseFloatFromString(b.total_offer) ? a : b),
    { total_offer: 0 },
  );
};
