import { connect } from "react-redux";

import { funderAuctionActivitiesSelector, fundersSelector } from "reducers";
import { arrayToObjectIndexedById } from "../../../../reducers/utils";
import { parseFloatFromString } from "../../../../utils";

const WinningFunderBidderValue = props => {
  const activities = props.auction_activities.filter(activity => activity.type === "B" || activity.type === "BC");
  if (activities.filter(activity => activity.funder && activity.total_offer).length > 0) {
    const funderActivitiesWithOffer = props.auction_activities.filter(
      activity => activity.funder && activity.total_offer,
    );
    const highestTotalOfferActivity = funderActivitiesWithOffer.reduce(
      (a, b) => (parseFloatFromString(a.total_offer) >= parseFloatFromString(b.total_offer) ? a : b),
      { total_offer: 0 },
    );
    let fundersById = arrayToObjectIndexedById(props.funders);
    if (!highestTotalOfferActivity) {
      return "";
    }
    let funderObj = fundersById[highestTotalOfferActivity.funder];
    if (funderObj) {
      return fundersById[highestTotalOfferActivity.funder].name;
    } else {
      return "";
    }
  }
  return "";
};

const mapStateToProps = state => {
  const auction_activities = funderAuctionActivitiesSelector(state).filter(
    activity => activity.type === "B" || activity.type === "BC",
  );

  return {
    funders: fundersSelector(state),
    auction_activities,
  };
};

export default connect(mapStateToProps)(WinningFunderBidderValue);
