import React from "react";
import { connect } from "react-redux";

import { fundersSelector, loadingFundersSelector } from "reducers";
import Table, { percentageCell } from "components/table";
import IconLink from "../../components/IconLink";
import { FaEdit, FaTrash } from "react-icons/fa";
import { DELETE_FUNDER_MODAL } from "../../components/modals";
import { showModal } from "../../actions";
import { moneyDisplayShort } from "../../utils";

const FundersListTable = ({ funders, fundersLoading, ...props }) => {
  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const caseId = row.original.id;
        return (
          <>
            <IconLink
              to={`/funders/${caseId}/overview/`}
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View / Edit"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            />
            <IconLink
              Icon={FaTrash}
              iconConfig={{ className: "table--action-icon red" }}
              tooltip="Delete Funder"
              onClick={() => props.showModal(DELETE_FUNDER_MODAL, { id: caseId })}
            />
          </>
        );
      },
    },
    {
      Header: "Funder Name",
      accessor: "name",
      className: "wrap-text",
    },
    {
      Header: "Funder Status",
      accessor: "buying_status",
    },
    {
      Header: "Min LE",
      accessor: "minimum_le",
    },
    {
      Header: "Max LE",
      accessor: "max_le",
    },
    {
      Header: "Min Age",
      accessor: "minimum_age",
    },
    {
      Header: "Max Age",
      accessor: "maximum_age",
    },
    {
      Header: "Min Face",
      accessor: "minimum_face_amount",
      Cell: ({ row }) => {
        if (row.original.minimum_face_amount) {
          return moneyDisplayShort(row.original.minimum_face_amount);
        } else {
          return "";
        }
      },
    },
    {
      Header: "Max Face",
      accessor: "maximum_face_amount",
      Cell: ({ row }) => {
        if (row.original.maximum_face_amount) {
          return moneyDisplayShort(row.original.maximum_face_amount);
        } else {
          return "";
        }
      },
    },
    {
      Header: "LE Vendors",
      accessor: "le_vendor",
      className: "wrap-text",
      Cell: ({ row }) => {
        if (row.original.le_vendor) {
          return row.original.le_vendor.split(";").join(", ");
        } else {
          return "";
        }
      },
    },
    {
      Header: "Required # of LEs",
      accessor: "market_les_required",
    },
    {
      Header: "RDB",
      accessor: "retained_death_benefit",
    },
    {
      Header: "VUL",
      accessor: "variable_policies",
    },
    {
      Header: "SUL",
      accessor: "survivorship",
    },
    {
      Header: "Internal Policy Loans",
      accessor: "loan_policies",
    },
    {
      Header: "Max BE",
      accessor: "max_be",
    },
    {
      Header: "Max PSM",
      accessor: "max_psm",
    },
    {
      Header: "Target IRR (Low End)",
      accessor: "target_irr_low",
      Cell: percentageCell,
    },
    {
      Header: "Target IRR (High End)",
      accessor: "target_irr_high",
      Cell: percentageCell,
    },
  ];

  let tableClass = "";
  if (funders.length > 1) {
    tableClass = "tableFixed";
  }

  return (
    <>
      <Table
        columns={columns}
        data={funders}
        sortBy={[{ id: "name", desc: false }]}
        defaultSort={true}
        showPaginationResults={true}
        initialPageSize={100}
        defaultPagination={true}
        emptyMessage={fundersLoading ? "Loading Funders data..." : "No data"}
        tableClass={tableClass}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    funders: fundersSelector(state),
    fundersLoading: loadingFundersSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
})(FundersListTable);
