import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";

import * as notifications from "../../../../notifications";
import { EditorField, FormCollapsible, DateInput, StateSelect, TextInput } from "components/forms";
import { createAccountLicense, editAccountLicense } from "actions";
import { activeAccountIdSelector, activeLicenseSelector, userChoicesSelector } from "reducers";
import { partial } from "utils";
import WideSaveButton from "components/buttons/WideSaveButton";
import SelectCheckboxes from "components/forms/SelectCheckboxes";

const LicenseForm = ({ activeLicense, ...props }) => {
  const insuranceOptions = [
    { value: "Life", label: "Life" },
    { value: "Health", label: "Health" },
    { value: "P&C", label: "P&C" },
    { value: "ADD", label: "ADD" },
    { value: "LSB", label: "LSB" },
    { value: "LSA", label: "LSA" },
    { value: "LSP", label: "LSP" },
    { value: "Annuity", label: "Annuity" },
    { value: "Annuity", label: "LTC" },
  ];
  const insurances = insuranceOptions.map(opt => opt.value);

  const professionalServicesOptions = [
    { value: "CPA", label: "CPA" },
    { value: "Law", label: "Law" },
  ];
  const services = professionalServicesOptions.map(opt => opt.value);

  const securitiesOptions = [
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "63", label: "63" },
    { value: "24", label: "24" },
    { value: "27", label: "27" },
  ];
  const securities = securitiesOptions.map(opt => opt.value);

  // Split lines_of_business into 3 different comma-separated variables
  let lines_of_business_1 = "";
  let lines_of_business_2 = "";
  let lines_of_business_3 = "";
  if (activeLicense.lines_of_business) {
    let linesSplit = activeLicense.lines_of_business.split(",");
    for (let line of linesSplit) {
      if (insurances.indexOf(line) !== -1) {
        lines_of_business_1 += line + ",";
      }
      if (services.indexOf(line) !== -1) {
        lines_of_business_2 += line + ",";
      }
      if (securities.indexOf(line) !== -1) {
        lines_of_business_3 += line + ",";
      }
    }
    lines_of_business_1 = lines_of_business_1.replace(/,*$/, "");
    lines_of_business_2 = lines_of_business_2.replace(/,*$/, "");
    lines_of_business_3 = lines_of_business_3.replace(/,*$/, "");
  }

  let [lines1, setLines1] = React.useState(lines_of_business_1);
  let [lines2, setLines2] = React.useState(lines_of_business_2);
  let [lines3, setLines3] = React.useState(lines_of_business_3);

  let initial_lines_of_business_1 = [];
  if (lines_of_business_1) {
    let lines_1_list = lines_of_business_1.split(",");
    for (let l of lines_1_list) {
      initial_lines_of_business_1.push({ value: l, label: l });
    }
  }
  const handleLines1Change = selectedOptions => {
    if (selectedOptions) {
      let opts = selectedOptions.map(o => o.value).join(",");
      setLines1(opts);
    } else {
      setLines1(undefined);
    }
  };

  let initial_lines_of_business_2 = [];
  if (lines_of_business_2) {
    let lines_2_list = lines_of_business_2.split(",");
    for (let l of lines_2_list) {
      initial_lines_of_business_2.push({ value: l, label: l });
    }
  }
  const handleLines2Change = selectedOptions => {
    if (selectedOptions) {
      setLines2(selectedOptions.map(o => o.value).join(","));
    } else {
      setLines2(undefined);
    }
  };

  let initial_lines_of_business_3 = [];
  if (lines_of_business_3) {
    let lines_3_list = lines_of_business_3.split(",");
    for (let l of lines_3_list) {
      initial_lines_of_business_3.push({ value: l, label: l });
    }
  }
  const handleLines3Change = selectedOptions => {
    if (selectedOptions) {
      setLines3(selectedOptions.map(o => o.value).join(","));
    } else {
      setLines3(undefined);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={activeLicense}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isEmpty(activeLicense)
          ? props.createAccountLicense
          : partial(props.editAccountLicense, activeLicense.id);

        let lines_of_business = "";
        if (lines1) {
          lines_of_business += lines1 + ",";
        }
        if (lines2) {
          lines_of_business += lines2 + ",";
        }
        if (lines3) {
          lines_of_business += lines3;
        }
        lines_of_business = lines_of_business.replace(/,*$/, "");

        values.lines_of_business = lines_of_business;
        values.account = props.activeAccountId;

        try {
          await method(values);
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing License");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="License Detail">
            <Form.Row>
              <StateSelect label="Jurisdiction" name="jurisdiction" placeholder="Select Case Jurisdiction" />
              <TextInput label="Number" name="number" />
            </Form.Row>
            <Form.Row>
              <Form.Label>
                <div className="form-label">
                  <span className="form-label__label" style={{ marginLeft: "5px" }}>
                    Lines of Business
                  </span>
                </div>
              </Form.Label>
            </Form.Row>
            <Form.Row>
              <SelectCheckboxes
                label="Insurance"
                placeholder={"Select Insurances"}
                name={"lines_of_business_1"}
                onChange={handleLines1Change}
                defaultValue={initial_lines_of_business_1}
                options={insuranceOptions}
              />
              <SelectCheckboxes
                label="Professional Services"
                placeholder={"Select Professional Services"}
                name={"lines_of_business_2"}
                onChange={handleLines2Change}
                defaultValue={initial_lines_of_business_2}
                options={professionalServicesOptions}
              />
              <SelectCheckboxes
                label="Securities"
                placeholder={"Select Securities"}
                name={"lines_of_business_3"}
                onChange={handleLines3Change}
                defaultValue={initial_lines_of_business_3}
                options={securitiesOptions}
              />
            </Form.Row>
            <Form.Row>
              <DateInput label="Issued Date" name="issued_date" md={4} />
              <DateInput label="Expiration Date" name="expiration_date" md={4} />
            </Form.Row>
            <Form.Row>
              <EditorField label="Notes" name="notes" />
            </Form.Row>
            <WideSaveButton submitRef={props.submitRef} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    activeAccountId: activeAccountIdSelector(state),
    activeLicense: activeLicenseSelector(state) || {},
    userChoices: userChoicesSelector(state),
  };
};

export default connect(mapStateToProps, {
  createAccountLicense,
  editAccountLicense,
})(LicenseForm);
