export const compareIgnoreCase = (a, b) => {
  let r1 = a;
  if (typeof r1 === "string") {
    r1 = r1?.toLowerCase();
  }
  let r2 = b;
  if (typeof r2 === "string") {
    r2 = r2?.toLowerCase();
  }
  if (r1 < r2) {
    return -1;
  }
  if (r1 > r2) {
    return 1;
  }
  return 0;
};
