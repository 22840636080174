import { connect } from "react-redux";

import { fundersForAuctionSelector, funderAuctionActivitiesSelector, marketAuctionActivitiesSelector } from "reducers";
import { moneyDisplay, parseFloatFromString } from "../../../../utils";
import { calculateDifference } from "./utils";

// This function validates if the current provider is liferoc (id=147), In that case
// It should calculate revenues with both Liferoc and Liferoc's Teriary Provider
export const filterMarketActivities = (marketActivities_param, provider) => {
  if (provider === 147) {
    const liferocProviderList = [147, 173];
    return marketActivities_param.filter(
      activity =>
        liferocProviderList.includes(activity.provider) &&
        activity.gross_offer &&
        (activity.type === "B" || activity.type === "BC"),
    );
  } else {
    return marketActivities_param.filter(
      activity =>
        activity.provider === provider && activity.gross_offer && (activity.type === "B" || activity.type === "BC"),
    );
  }
};

// returnInt (defaults to False) returns an Integer if true (only for calculate the total)
// otherwise returns a string, moneydisplay or empty
export const calcRevenueModelValue = (marketActivities_param = [], funderActivities_param = [], provider) => {
  // get a list of market activities of type Bid or Bid Contingent

  const marketActivities = filterMarketActivities(marketActivities_param, provider);
  // get a list of funder activities of type Bid or Bid Contingent
  const funderActivities = funderActivities_param.filter(
    activity => activity.funder && activity.total_offer && (activity.type === "B" || activity.type === "BC"),
  );
  // if there is at least a valid market and funder activity proceed, otherwise return empty string
  if (marketActivities.length > 0 && funderActivities.length > 0) {
    // get the market activity winning bid
    const marketActivityWinningBid = marketActivities_param.filter(
      activity =>
        activity.provider === provider &&
        activity.gross_offer &&
        (activity.type === "B" || activity.type === "BC") &&
        activity.market_winning_bid,
    );
    let highestMarketBid;
    // if there is a Winning bid amongst the market activities take it, otherwise get highest bid
    if (marketActivityWinningBid.length > 0) {
      highestMarketBid = marketActivityWinningBid[0];
    } else {
      highestMarketBid = marketActivities.reduce(
        (a, b) => (parseFloatFromString(a.gross_offer) > parseFloatFromString(b.gross_offer) ? a : b),
        { gross_offer: 0 },
      );
    }

    // get the funder activity winning bid
    const funderActivityWinningBid = funderActivities_param.filter(
      activity =>
        activity.funder &&
        activity.total_offer &&
        (activity.type === "B" || activity.type === "BC") &&
        activity.funder_winning_bid,
    );
    let highestFunderBid;
    // if there is a Winning bid amongst the funder activities take it, otherwise get highest bid
    if (funderActivityWinningBid.length > 0) {
      highestFunderBid = funderActivityWinningBid[0];
    } else {
      highestFunderBid = funderActivities.reduce(
        (a, b) => (parseFloatFromString(a.total_offer) > parseFloatFromString(b.total_offer) ? a : b),
        { total_offer: 0 },
      );
    }
    // calculate revenue as a difference between funder total_offer and market gross_offer
    if (highestFunderBid.total_offer && highestMarketBid.gross_offer) {
      return calculateDifference(highestFunderBid, highestMarketBid);
    }
  }
};

export const revenueValueMoneyDisplay = (marketActivities_param = [], funderActivities_param = [], provider) => {
  let value = calcRevenueModelValue(marketActivities_param, funderActivities_param, provider);
  if (value) {
    const usFormatter = new Intl.NumberFormat("en-US");
    return moneyDisplay(usFormatter.format(value));
  } else {
    return "";
  }
};

const RevenueModelValue = props =>
  revenueValueMoneyDisplay(props.market_auction_activities, props.funder_auction_activities, props.caseInfo.provider);

const mapStateToProps = state => {
  return {
    auctionFunders: fundersForAuctionSelector(state),
    funder_auction_activities: funderAuctionActivitiesSelector(state),
    market_auction_activities: marketAuctionActivitiesSelector(state),
  };
};

export default connect(mapStateToProps)(RevenueModelValue);
