import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { SYNONYM_MODAL } from "components/modals";
import SynonymForm from "./SynonymForm";

const SynonymModal = () => {
  return (
    <FullPageModal modalType={SYNONYM_MODAL} title="Synonym Detail" style={{ content: { overflow: "visible" } }}>
      <SynonymForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(SynonymModal);
