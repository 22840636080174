import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import RelatedLifeExpectanciesForm from "./RelatedLifeExpectanciesForm";
import { RELATED_LE_MODAL } from "components/modals";
import { relatedLeSelector } from "reducers";

const RelatedLifeExpectanciesModal = ({ related_life_expectancies, ...props }) => {
  return (
    <FullPageModal modalType={RELATED_LE_MODAL}>
      <RelatedLifeExpectanciesForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    related_life_expectancies: relatedLeSelector(state),
  };
};

export default connect(mapStateToProps, {})(RelatedLifeExpectanciesModal);
