import React from "react";
import { connect } from "react-redux";

import { activeFunderSelector } from "reducers";
import { Card } from "react-bootstrap";
import { Tab, TabGroup } from "../../components/Tabs";
import { downloadFile, setShouldSave } from "../../actions";
import TopHeaderTabs from "../../components/TopHeaderTabs";

const FunderDetailTopHeader = ({ ...props }) => {
  const path = `/funders`;
  const tabs = [
    { link: `${path}/`, label: "Funder List" },
    { link: `${path}-report/`, label: "Funder Activity Report" },
    { link: `${path}-profile/`, label: "Funder Buying Profile" },
  ];

  return <TopHeaderTabs tabs={tabs} />;
};

const mapStateToProps = (state, ownProps) => {
  let funderInfo = activeFunderSelector(state);
  let funderName = "";
  if (funderInfo) {
    funderName = funderInfo.name;
  }
  return {
    funderInfo,
    funderName,
  };
};

export default connect(mapStateToProps, {
  setShouldSave,
  downloadFile,
})(FunderDetailTopHeader);
