import { Formik } from "formik";
import * as Yup from "yup";
import * as notifications from "notifications.js";
import { TextInput, Select, SubmitButton, DateInput, Collapsible } from "components/forms";
import { Form } from "react-bootstrap";
import { activeAgencySelector, funderWinningBidSelector, funderAuctionActivitiesSelector } from "reducers";
import { connect } from "react-redux";
import { editCase, selectCaseFile, showModal } from "actions";
import React from "react";
import RenderPlacementRevenueValue from "./RenderPlacementRevenueValue";
import RenderTradingRevenueValue from "./RenderTradingRevenueValue";
import WinningMarketBidValue from "./WinningMarketBidValue";
import WinningMarketBidderValue from "./WinningMarketBidderValue";
import WinningFunderBidValue from "./WinningFunderBidValue";
import WinningFunderBidderValue from "./WinningFunderBidderValue";
import NumberOfFunderBids from "./NumberOfFunderBids";
import NumberOfBiddersField from "./NumberOfBiddersValue";
import NumberOfFunderBidders from "./NumberOfFunderBidders";
import NumberOfFundersSent from "./NumberOfFundersSent";
import { REVENUE_MODEL_CHOICES, biddingFormatChoices } from "constants.js";
import HighestBidValue from "./HighestBidValue";

const CaseTradingSummaryForm = ({ caseInfo, ...props }) => {
  if (!caseInfo) {
    // Still didn't fetch data
    return null;
  }

  const boldParagraphTitleStyle = {
    fontWeight: "bold",
    marginBottom: "0.2rem",
    paddingLeft: 15,
    backgroundColor: "#999999",
    color: "white",
    width: "100%",
    padding: "5px",
  };

  const valueStyle = {
    padding: "6px 0 0 10px",
    minHeight: "36px",
    border: "1px solid #cdc8c8",
    borderRadius: "3px",
    margin: "0!important",
    marginBottom: "0",
    backgroundColor: "#E3E2E2FF",
  };

  const formRowStyle = {
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
  };

  const formDivStyle = {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    width: "22%",
  };

  const getRevenueModel = value => {
    const revenue_model_data = REVENUE_MODEL_CHOICES.find(choice => choice.value === value);
    return revenue_model_data && revenue_model_data.label;
  };

  const { bidding_format } = caseInfo;
  // Revenue Model is driven by Winning Bid
  let revenue_model = "TBD";
  if (props.winningBid) {
    const revenue_model_value = props.winningBid.revenue_model;
    if (revenue_model_value) {
      revenue_model = getRevenueModel(revenue_model_value);
    }
  } else {
    // Look at Highest Bid
    let highestOffer = 0;
    for (let a of props.auctionActivities) {
      if (a.total_offer && parseFloat(a.total_offer) > highestOffer) {
        if (a.revenue_model) {
          highestOffer = parseFloat(a.total_offer);
          revenue_model = getRevenueModel(a.revenue_model);
        }
      }
    }
  }

  return (
    <Collapsible className={"tradingSummary"} title="Trading Summary">
      <Form.Row style={formRowStyle}>
        <div style={formDivStyle}>
          <p style={boldParagraphTitleStyle}>Bidding Format:</p>
          <Select name="bidding_format" placeholder="Select Bidding Format" options={biddingFormatChoices} isRequired />
        </div>
        <div style={formDivStyle}>
          <p style={boldParagraphTitleStyle}>Revenue Model:</p>
          <TextInput
            name="revenue_model"
            isRequired
            disabled
            customStyle={{ padding: 0 }}
            grayout={{ backgroundColor: "#E3E2E2FF" }}
          />
        </div>
        <div style={formDivStyle}>
          <p style={boldParagraphTitleStyle}>Placement Revenue:</p>
          <p style={valueStyle}>
            <RenderPlacementRevenueValue caseInfo={caseInfo} revenueModel={revenue_model} />
          </p>
        </div>
        <div style={formDivStyle}>
          <p style={boldParagraphTitleStyle}>Trading Revenue:</p>
          <p style={valueStyle}>
            <RenderTradingRevenueValue caseInfo={caseInfo} revenueModel={revenue_model} />
          </p>
        </div>
      </Form.Row>
      <Form.Row style={formRowStyle}>
        <div style={formDivStyle}>
          <p style={boldParagraphTitleStyle}>High Provider Bid:</p>
          <p style={valueStyle}>
            <WinningMarketBidValue caseInfo={props.caseInfo} />
          </p>
        </div>
        <div style={formDivStyle}>
          <p style={boldParagraphTitleStyle}>High Provider Bidder:</p>
          <p style={valueStyle}>
            <WinningMarketBidderValue caseInfo={props.caseInfo} />
          </p>
        </div>
        <div style={formDivStyle}>
          <p style={boldParagraphTitleStyle}>High Funder Bid:</p>
          <p style={valueStyle}>
            <WinningFunderBidValue />
          </p>
        </div>
        <div style={formDivStyle}>
          <p style={boldParagraphTitleStyle}>High Funder Bidder:</p>
          <p style={valueStyle}>
            <WinningFunderBidderValue />
          </p>
        </div>
      </Form.Row>
      <Form.Row style={formRowStyle}>
        <div style={formDivStyle}>
          <p style={boldParagraphTitleStyle}># of Funder Bids:</p>
          <p style={valueStyle}>
            <NumberOfFunderBids />
          </p>
        </div>
        <div style={formDivStyle}>
          <p style={boldParagraphTitleStyle}># of Active Funders:</p>
          <p style={valueStyle}>
            <NumberOfBiddersField />
          </p>
        </div>
        <div style={formDivStyle}>
          <p style={boldParagraphTitleStyle}># of Funders Who've Bid:</p>
          <p style={valueStyle}>
            <NumberOfFunderBidders />
          </p>
        </div>
        <div style={formDivStyle}>
          <p style={boldParagraphTitleStyle}># of Funders Sent:</p>
          <p style={valueStyle}>
            <NumberOfFundersSent />
          </p>
        </div>
      </Form.Row>
      <Form.Row style={formRowStyle}>
        <div style={formDivStyle}>
          <p style={boldParagraphTitleStyle}>Highest Bid:</p>
          <p style={valueStyle}>
            <HighestBidValue caseInfo={props.caseInfo} />
          </p>
        </div>
        <div style={formDivStyle}>
          <p style={boldParagraphTitleStyle}>Conversion Deadline:</p>
          <DateInput
            name="conversion_deadline"
            showIcon={false}
            style={{
              height: "calc(1.5em + 0.75rem)",
              padding: 0,
              color: "hsl(0,0%,60%)",
            }}
          />
        </div>
        <div style={formDivStyle}>
          <p style={boldParagraphTitleStyle}>Bidding Deadline:</p>
          <DateInput
            name="bidding_deadline"
            showIcon={false}
            style={{
              height: "calc(1.5em + 0.75rem)",
              padding: 0,
              color: "hsl(0,0%,60%)",
            }}
          />
        </div>
        {
          // adding this empty div with opacity 0 to keep row vertically aligned
        }
        <div style={{ opacity: 0, ...formDivStyle }}></div>
      </Form.Row>
    </Collapsible>
  );
};

const mapStateToProps = state => {
  const funderWinningBid = funderWinningBidSelector(state);
  const auctionActivities = funderAuctionActivitiesSelector(state);
  return {
    winningBid: funderWinningBid,
    auctionActivities,
    activeAgency: activeAgencySelector(state),
  };
};

export default connect(mapStateToProps, {
  editCase,
  selectCaseFile,
  showModal,
})(CaseTradingSummaryForm);
