import React, { useState } from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import { useField } from "formik";
import ReactSelect, { components } from "react-select";

import { getValidationClassName } from "./validation";
import Label from "./Label";
import FieldErrors from "./FieldErrors";

const InputOption = ({ getStyles, Icon, isDisabled, isFocused, isSelected, children, innerProps, ...rest }) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} style={{ marginRight: "5px" }} />
      {children}
    </components.Option>
  );
};

export const SelectCheckboxes = ({
  label,
  options = [],
  emptyValue = "",
  md = "",
  submitRef = undefined,
  ...props
}) => {
  const [field, meta] = useField(props);

  // Search value into possibly nested options (for now we search up to 1 nested level)
  const findOption = value => {
    let flattenedOpts = [];

    options.forEach(opt => {
      if (opt.options) {
        flattenedOpts = [...flattenedOpts, ...opt.options];
      } else {
        flattenedOpts.push(opt);
      }
    });
    return flattenedOpts.find(opt => opt.value === value);
  };

  let initialValue;
  if (field.value !== undefined && field.value !== emptyValue) {
    initialValue = findOption(field.value);
  } else {
    if (props.defaultValue !== undefined) {
      initialValue = findOption(props.defaultValue);
    } else {
      initialValue = emptyValue;
    }
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      minHeight: "36px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: state => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "36px",
    }),
  };

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md} padding0`}>
      {props.append ? props.append : null}
      <InputGroup style={{ flexWrap: "no-wrap!important" }}>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <div style={{ marginLeft: 1, marginRight: 3 }}>{label}</div>
            {props.icon}
          </InputGroup.Text>
        </InputGroup.Prepend>
        <div style={{ flexGrow: "1" }}>
          <ReactSelect
            {...props}
            maxMenuHeight={200}
            name={field.name}
            options={options}
            value={initialValue}
            onChange={props.onChange}
            className={`select ${getValidationClassName(meta)}`}
            isClearable
            menuPlacement="auto"
            styles={customStyles}
            isDisabled={props.disabled || false}
            ref={submitRef}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              Option: InputOption,
            }}
          />
        </div>
        <FieldErrors meta={meta} />
      </InputGroup>
    </Form.Group>
  );
};

export default SelectCheckboxes;
