export const SELECT_AGENCY_MODAL = "SELECT_AGENCY_MODAL";
export const LOADING_MODAL = "LOADING_MODAL";

export const DELETE_INSURED_MODAL = "DELETE_INSURED_MODAL";
export const DELETE_OWNER_INSURED_MODAL = "DELETE_OWNER_INSURED_MODAL";
export const DELETE_OWNER_PERSON_MODAL = "DELETE_OWNER_PERSON_MODAL";
export const DELETE_OWNER_ENTITY_MODAL = "DELETE_OWNER_ENTITY_MODAL";

export const DELETE_CASE_MODAL = "DELETE_CASE_MODAL";
export const COPY_CASE_MODAL = "COPY_CASE_MODAL";

export const DELETE_ACCOUNT_MODAL = "DELETE_ACCOUNT_MODAL";

export const BASIC_CREATE_ACCOUNT_MODAL = "BASIC_CREATE_ACCOUNT_MODAL";
export const CREATE_ACCOUNT_MODAL = "CREATE_ACCOUNT_MODAL";
export const MANAGE_ENTITY_MODAL = "MANAGE_ENTITY_MODAL";
export const ACCOUNT_TASK_MODAL = "ACCOUNT_TASK_MODAL";
export const DELETE_ACCOUNT_TASK_MODAL = "DELETE_ACCOUNT_TASK_MODAL";
export const ACCOUNT_CARRIER_APPOINTMENT_MODAL = "ACCOUNT_CARRIER_APPOINTMENT_MODAL";
export const DELETE_ACCOUNT_CARRIER_APPOINTMENT_MODAL = "DELETE_ACCOUNT_CARRIER_APPOINTMENT_MODAL";
export const ACCOUNT_LICENSE_MODAL = "ACCOUNT_LICENSE_MODAL";
export const DELETE_ACCOUNT_LICENSE_MODAL = "DELETE_ACCOUNT_LICENSE_MODAL";
export const ACCOUNT_AGREEMENT_MODAL = "ACCOUNT_AGREEMENT_MODAL";
export const DELETE_ACCOUNT_AGREEMENT_MODAL = "DELETE_ACCOUNT_AGREEMENT_MODAL";
export const ACCOUNT_HIERARCHY_MODAL = "ACCOUNT_HIERARCHY_MODAL";
export const DELETE_ACCOUNT_HIERARCHY_MODAL = "DELETE_ACCOUNT_HIERARCHY_MODAL";
export const ACCOUNT_CHANGE_ACTIVE_HIERARCHY_MODAL = "ACCOUNT_CHANGE_ACTIVE_HIERARCHY_MODAL";
export const ACCOUNT_WARNING_UPDATE_HIERARCHY_MODAL = "ACCOUNT_WARNING_UPDATE_HIERARCHY_MODAL";
export const ACCOUNT_AFFILIATION_MODAL = "ACCOUNT_AFFILIATION_MODAL";
export const DELETE_ACCOUNT_AFFILIATION_MODAL = "DELETE_ACCOUNT_AFFILIATION_MODAL";
export const ACCOUNT_HIERARCHY_SPLIT_MODAL = "ACCOUNT_HIERARCHY_SPLIT_MODAL";
export const DELETE_ACCOUNT_HIERARCHY_SPLIT_MODAL = "DELETE_ACCOUNT_HIERARCHY_SPLIT_MODAL";

export const LIFE_EXPECTANCY_MODAL = "LIFE_EXPECTANCY_MODAL";
export const DELETE_LIFE_EXPECTANCY_MODAL = "DELETE_LIFE_EXPECTANCY_MODAL";

export const DELETE_OWNER_SIGNER_MODAL = "DELETE_OWNER_SIGNER_MODAL";

export const AUCTION_ROUND_MODAL = "AUCTION_ROUND_MODAL";
export const DELETE_AUCTION_ROUND_MODAL = "DELETE_AUCTION_ROUND_MODAL";

export const AUCTION_FUNDER_ACTIVITY_MODAL = "AUCTION_FUNDER_ACTIVITY_MODAL";
export const AUCTION_MARKET_ACTIVITY_MODAL = "AUCTION_MARKET_ACTIVITY_MODAL";
export const DELETE_FUNDER_AUCTION_ACTIVITY_MODAL = "DELETE_FUNDER_AUCTION_ACTIVITY_MODAL";
export const DELETE_MARKET_AUCTION_ACTIVITY_MODAL = "DELETE_MARKET_AUCTION_ACTIVITY_MODAL";
export const DELETE_AUCTION_ACTIVITY_FILE_MODAL = "DELETE_AUCTION_ACTIVITY_FILE_MODAL";
export const CONFIRM_AUCTION_ACTIVITY_FILE_UPLOAD = "CONFIRM_AUCTION_ACTIVITY_FILE_UPLOAD";
export const AUCTION_ACTIVITY_CHANGE_WINNING_BID_MODAL = "AUCTION_ACTIVITY_CHANGE_WINNING_BID_MODAL";
export const SEND_MARKET_ACTIVITY_EMAIL_MODAL = "SEND_MARKET_ACTIVITY_EMAIL_MODAL";

export const DELETE_BENEFICIARY_MODAL = "DELETE_BENEFICIARY_MODAL";
export const DELETE_BENEFICIARY_SIGNER_MODAL = "DELETE_BENEFICIARY_SIGNER_MODAL";

export const CONFIRM_FILE_REPLACE_MODAL = "CONFIRM_FILE_REPLACE_MODAL";
export const CONFIRM_FOLLOW_UP_REPLACE_MODAL = "CONFIRM_FOLLOW_UP_REPLACE_MODAL";
export const CONFIRM_FILE_DELETE_MODAL = "CONFIRM_FILE_DELETE_MODAL";

export const SEND_FUNDER_EMAIL_MODAL = "SEND_FUNDER_EMAIL_MODAL";
export const SEND_MANAGER_EMAIL_MODAL = "SEND_MANAGER_EMAIL_MODAL";
export const SEND_PRICING_EMAIL_MODAL = "SEND_PRICING_TEAM_MODAL";

export const CASE_FILE_MODAL = "CASE_FILE_MODAL";
export const CASE_FILE_MODAL_LE = "CASE_FILE_MODAL_LE";
export const CASE_FILE_WARNING_MODAL = "CASE_FILE_WARNING_MODAL";
export const DELETE_CASE_FILE_MODAL = "DELETE_CASE_FILE_MODAL";
export const CASE_EMAIL_MODAL = "CASE_EMAIL_MODAL";
export const RELATED_LE_MODAL = "RELATED_LE_MODAL";
export const EMAIL_PREVIEW_MODAL = "EMAIL_PREVIEW_MODAL";
export const REDACT_MODAL = "REDACT_MODAL";

export const PRODUCT_MODAL = "PRODUCT_MODAL";
export const DELETE_PRODUCT_MODAL = "DELETE_PRODUCT_MODAL";
export const DELETE_PRODUCT_TEMPLATE_MODAL = "DELETE_PRODUCT_TEMPLATE_MODAL";

export const CARRIER_FILE_MODAL = "CARRIER_FILE_MODAL";
export const DELETE_CARRIER_FILE_MODAL = "DELETE_CARRIER_FILE";
export const DELETE_CARRIER_MODAL = "DELETE_CARRIER_MODAL";
export const DELETE_FUNDER_MODAL = "DELETE_FUNDER_MODAL";
export const DELETE_FUNDER_CONTACT_MODAL = "DELETE_FUNDER_CONTACT_MODAL";
export const DELETE_FUNDER_ENTITY_MODAL = "DELETE_FUNDER_ENTITY_MODAL";
export const SECURITY_INTERMEDIARY_MODAL = "SECURITY_INTERMEDIARY_MODAL";
export const DELETE_SECURITY_INTERMEDIARY_MODAL = "DELETE_SECURITY_INTERMEDIARY_MODAL";
export const TRADING_DOCUMENT_MODAL = "TRADING_DOCUMENT_MODAL";
export const DELETE_TRADING_DOCUMENT_MODAL = "DELETE_SECURITY_INTERMEDIARY_MODAL";

export const PRICING_DOCUMENTS_MODAL = "PRICING_DOCUMENTS_MODAL";
export const CASE_ACCOUNT_HIERARCHY_MODAL = "CASE_ACCOUNT_HIERARCHY_MODAL";

export const FIND_DOCTOR_MODAL = "FIND_DOCTOR_MODAL";
export const APS_ORDER_MODAL = "APS_ORDER_MODAL";

export const DELETE_LEAD_MODAL = "DELETE_LEAD_MODAL";
export const LEAD_TASK_MODAL = "LEAD_TASK_MODAL";
export const DELETE_LEAD_TASK_MODAL = "DELETE_LEAD_TASK_MODAL";
export const SELECT_ACCOUNT_FOR_ADVISOR_LEAD_MODAL = "SELECT_ACCOUNT_FOR_ADVISOR_LEAD_MODAL";
export const SEND_LEAD_EMAIL_MODAL = "SEND_LEAD_EMAIL_MODAL";
export const SEND_SALES_REP_LEAD_EMAIL_MODAL = "SEND_SALES_REP_LEAD_EMAIL_MODAL";

export const TASK_MODAL = "TASK_MODAL";

export const POLICY_REQUEST_MODAL = "POLICY_REQUEST_MODAL";
export const QUEST_HEALTH_REQUEST_MODAL = "QUEST_HEALTH_REQUEST_MODAL";

export const PARAMETER_MODAL = "PARAMETER_MODAL";
export const DELETE_PARAMETER_MODAL = "DELETE_PARAMETER_MODAL";

export const SYNONYM_MODAL = "SYNONYM_MODAL";
export const DELETE_SYNONYM_MODAL = "DELETE_SYNONYM_MODAL";
export const PARAMETER_SYNONYM_MODAL = "PARAMETER_SYNONYM_MODAL";
export const RELATED_PARAMETERS_MODAL = "RELATED_PARAMETERS_MODAL";
export const REMOVE_RELATED_PARAMETER_MODAL = "REMOVE_RELATED_PARAMETER_MODAL";

export const MORTALITY_ASSESSMENT_MODAL = "MORTALITY_ASSESSMENT_MODAL";
export const MORTALITY_ASSESSMENT_CREATION_MODAL = "MORTALITY_ASSESSMENT_CREATION_MODAL";
export const DELETE_MORTALITY_ASSESSMENT_MODAL = "DELETE_MORTALITY_ASSESSMENT_MODAL";

export const CASE_TASK_MODAL = "CASE_TASK_MODAL";
export const DELETE_CASE_TASK_MODAL = "DELETE_CASE_TASK_MODAL";

export const CLINICAL_RESEARCH_MODAL = "CLINICAL_RESEARCH_MODAL";
export const DELETE_CLINICAL_RESEARCH_MODAL = "DELETE_CLINICAL_RESEARCH_MODAL";

export const SEND_ACCOUNT_EMAIL_MODAL = "SEND_ACCOUNT_EMAIL_MODAL";

export const ADD_CONDITION_MODAL = "ADD_CONDITION_MODAL";
export const EDIT_CONDITION_LIST_MODAL = "EDIT_CONDITION_LIST_MODAL";
export const DELETE_CONDITION_LIST_MODAL = "DELETE_CONDITION_LIST_MODAL";

export const EMAIL_TEMPLATE_MODAL = "EMAIL_TEMPLATE_MODAL";
export const DELETE_EMAIL_TEMPLATE_MODAL = "DELETE_EMAIL_TEMPLATE_MODAL";

export const WINFLEX_ILLUSTRATION_MODAL = "WINFLEX_ILLUSTRATION_MODAL";

export const DELETE_STATE_MODAL = "DELETE_STATE_MODAL";
export const DELETE_STATE_BROKER_FILE_MODAL = "DELETE_STATE_BROKER_FILE_MODAL";
export const DELETE_STATE_PROVIDER_FILE_MODAL = "DELETE_STATE_PROVIDER_FILE_MODAL";
export const STATE_BROKER_FILE_MODAL = "STATE_BROKER_FILE_MODAL";
export const STATE_PROVIDER_FILE_MODAL = "STATE_PROVIDER_FILE_MODAL";
