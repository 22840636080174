import React from "react";
import { connect } from "react-redux";

import { selectAuctionActivity, fetchMarketAuctionActivities, showModal } from "actions";
import {
  activeAuctionActivitySelector,
  managementChoicesSelector,
  auctionIdSelector,
  activeCaseSelector,
  marketAuctionActivitiesSelector,
} from "reducers";
import {
  AUCTION_MARKET_ACTIVITY_MODAL,
  DELETE_MARKET_AUCTION_ACTIVITY_MODAL,
  SEND_MARKET_ACTIVITY_EMAIL_MODAL,
} from "components/modals";
import Table, { choiceCell, currencyCell, datetimeCell, sortDateCell } from "components/table";
import { FaCopy, FaEdit, FaEnvelope, FaEye, FaTrash } from "react-icons/fa";
import IconLink from "../../../../components/IconLink";
import { activityTypeChoices } from "../../../../constants";
import { getViewMode } from "../../../../utils";
import IconButton from "../../../../components/IconButton";

const MarketActivitiesTable = ({ fetchMarketAuctionActivities, auctionActivities, ...props }) => {
  React.useEffect(() => {
    if (props.auctionId !== undefined) {
      // Wait for the auction to be fetched (we need the id) before fetching the
      // activities
      fetchMarketAuctionActivities();
    }
  }, [fetchMarketAuctionActivities, props.auctionId]);
  const isViewMode = getViewMode();

  const columns = [
    {
      Header: "Action",
      className: "action",
      Cell: ({ row }) => {
        const activityId = row.original.id;
        const activity = auctionActivities.find(activity => activity.id === activityId);
        if (activity === undefined) {
          return <></>;
        }

        return (
          <>
            <IconButton
              Icon={isViewMode ? FaEye : FaEdit}
              tooltip={isViewMode ? "View" : "Edit"}
              iconConfig={{ className: "table--action-icon" }}
              onClick={() => {
                props.selectAuctionActivity(activityId);
                props.showModal(AUCTION_MARKET_ACTIVITY_MODAL);
              }}
            />
            {(activity.type === "B" || activity.type === "BC") && (
              <IconLink
                Icon={FaEnvelope}
                iconConfig={{ className: "table--action-icon" }}
                tooltip="Send by Email"
                onClick={() => {
                  props.selectAuctionActivity(activityId);
                  props.showModal(SEND_MARKET_ACTIVITY_EMAIL_MODAL);
                }}
              />
            )}
            {activity.file && (
              <IconLink
                Icon={FaCopy}
                iconConfig={{ className: "table--action-icon" }}
                tooltip="Download File"
                onClick={() => {
                  const url = `/auctions/api/market_activities/${activityId}/download_file/`;
                  props.downloadFile(url, activity.file.name);
                }}
              />
            )}
            {!isViewMode && (
              <IconLink
                Icon={FaTrash}
                iconConfig={{ className: "table--action-icon red" }}
                tooltip="Delete Market Activity"
                onClick={() => {
                  props.showModal(DELETE_MARKET_AUCTION_ACTIVITY_MODAL, {
                    id: activityId,
                  });
                }}
              />
            )}
          </>
        );
      },
    },
    {
      Header: "Round",
      accessor: "round_number",
    },
    {
      Header: "Provider",
      accessor: "provider",
      Cell: choiceCell(props.providerChoices),
    },
    {
      Header: "Funder Code",
      Cell: ({ row }) => {
        if (!row.original.funder_activity_id) {
          return "";
        }
        const paddedFunderId = String(row.original.funder_activity_id).padStart(3, "0");
        return `LR-FC${paddedFunderId}`;
      },
    },
    {
      Header: "Activity Date",
      accessor: "date",
      sortType: sortDateCell,
      Cell: datetimeCell,
    },
    {
      Header: "Activity",
      accessor: "type",
      Cell: choiceCell(activityTypeChoices),
    },
    {
      Header: "Gross Offer",
      accessor: "gross_offer",
      Cell: currencyCell,
    },
    {
      Header: "Conversion Compensation",
      accessor: "conversion_compensation",
      Cell: currencyCell,
    },
    {
      Header: "RDB Offer",
      accessor: "rdb_offer",
    },
    {
      Header: "Offer Expiration",
      accessor: "funder_offer_expiration_date",
    },
    {
      Header: "Winning bid",
      accessor: d => {
        return d.market_winning_bid ? "Yes" : "No";
      },
      Cell: ({ row }) => {
        return (
          <div className="text-wrap" style={{ textAlign: "center" }}>
            {row.original.market_winning_bid ? "Yes" : "No"}
          </div>
        );
      },
    },
    {
      Header: "Notes",
      accessor: "notes",
      Cell: ({ row }) => {
        return (
          <div className="text-wrap">
            {row.original.notes
              .replace(/(<([^>]+)>)/gi, " ")
              .replace("&amp;nbsp;", " ")
              .replace("&nbsp;", " ")}{" "}
          </div>
        );
      },
    },
  ];

  if (props.caseInfo && props.caseInfo.bidding_format !== "LPEX") {
    delete columns[3];
  }
  if (props.caseInfo && props.caseInfo.bidding_format === "TRADITIONAL") {
    delete columns[1];
  }
  return (
    <Table
      columns={columns}
      data={auctionActivities}
      defaultSort={true}
      paginated={false}
      emptyMessage="No records found"
      sortBy={[{ id: "date", desc: true }]}
      rowIsActive={row =>
        row.original && props.activeAuctionActivity && row.original.id === props.activeAuctionActivity.id
      }
    />
  );
};

const mapStateToProps = state => {
  const managementChoices = managementChoicesSelector(state);
  return {
    caseInfo: activeCaseSelector(state),
    auctionId: auctionIdSelector(state),
    activeAuctionActivity: activeAuctionActivitySelector(state),
    auctionActivities: marketAuctionActivitiesSelector(state),
    providerChoices: managementChoices.case_providers,
  };
};

export default connect(mapStateToProps, {
  fetchMarketAuctionActivities,
  selectAuctionActivity,
  showModal,
})(MarketActivitiesTable);
