import React, { useRef } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { Select, TextInput, FormFilter, SubmitSearchButton } from "components/forms";
import { fetchAccountCases, fetchLifeSettlementChoices, fetchManagerAgents } from "actions";
import { managementChoicesSelector } from "reducers";
import { caseStatusChoices, stageChoices } from "../../../constants";

const AccountCasesFiltersForm = ({ accountInfo, fetchLifeSettlementChoices, fetchManagerAgents, ...props }) => {
  if (!accountInfo) {
    // Still didn't fetch data
    return null;
  }
  React.useEffect(() => {
    fetchLifeSettlementChoices();
    fetchManagerAgents();
  }, [fetchLifeSettlementChoices]);

  const handleStatusChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedStatusValues = selectedOptions.map(o => o.value).join(",");
      setStatus(commaSeparatedStatusValues);
    } else {
      setStatus(undefined);
    }
  };

  const handleStageChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedValues = selectedOptions.map(o => o.value).join(",");
      setStage(commaSeparatedValues);
    } else {
      setStage(undefined);
    }
  };

  let status_initial = [];
  let [status, setStatus] = React.useState();
  let stage_initial = [];
  let [stage, setStage] = React.useState();

  return (
    <Formik
      initialValues={{}}
      onSubmit={async (values, { setSubmitting }) => {
        await props.fetchAccountCases({
          ...values,
          account: accountInfo.id,
          status: status,
          stage: stage,
        });
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, setFieldValue, setSubmitting }) => {
        const stageRef = useRef();

        return (
          <FormFilter title={"Case Search"} onSubmit={handleSubmit}>
            <Form.Row>
              <TextInput label="Last Name" name="last_name" />
              <TextInput label="First Name" name="first_name" />
              <Select
                label="Stage"
                name="stage"
                placeholder="Select Stage"
                isMulti={true}
                onChange={handleStageChange}
                options={stageChoices}
                defaultValue={stage_initial}
                submitRef={stageRef}
              />
              <Select
                label="Status"
                name="status"
                placeholder="Select Status"
                isMulti={true}
                onChange={handleStatusChange}
                defaultValue={status_initial}
                options={caseStatusChoices}
              />
            </Form.Row>
            <Form.Row>
              <Select
                label="Sales Rep"
                name="sales_representative"
                placeholder="Select Sales Rep"
                options={props.salesRepChoices.getChoices({ sortByLabel: true })}
              />
              <Select
                label="Sales Manager"
                name="sales_manager"
                placeholder="Select Sales Manager"
                options={props.salesRepChoices.getChoices({ sortByLabel: true })}
              />
              <Select
                label="Nat Acc Manager"
                name="national_account_manager"
                placeholder="Select National Acc Manager"
                options={props.salesRepChoices.getChoices({ sortByLabel: true })}
              />
              <Select
                label="Broker"
                name="broker"
                placeholder="Select Broker"
                options={props.accountChoices.getChoices()}
              />
            </Form.Row>
            <Form.Row>
              <Select
                label="Account Role"
                name="account_role"
                placeholder="Select Account Role"
                options={[
                  { value: "source", label: "Source" },
                  { value: "broker", label: "Broker" },
                  { value: "national account", label: "National Account" },
                ]}
                md={3}
              />
            </Form.Row>
            <SubmitSearchButton />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);

  return {
    carrierChoices: choices.carriers,
    accountChoices: choices.accounts,
    salesRepChoices: choices.case_sales_representatives,
  };
};

export default connect(mapStateToProps, {
  fetchAccountCases,
  fetchLifeSettlementChoices,
  fetchManagerAgents,
})(AccountCasesFiltersForm);
