import React from "react";
import { FaFile } from "react-icons/fa";

export default ({ onClick, children, className, ...props }) => {
  return (
    <button className={`btn btn--secondary ${className}`} onClick={onClick} {...props}>
      <FaFile />
      {children}
    </button>
  );
};
