import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { editLifeExpectancy, updateLifeExpectancy } from "actions";
import {
  BooleanSelect,
  DateInput,
  FormCollapsible,
  GenderSelect,
  SSNInput,
  Select,
  SubmitButton,
  TextInput,
  EditorField,
} from "components/forms";
import { lifeExpectancyChoicesSelector, extractedLifeExpectancyPDFdataSelector } from "reducers";
import { underwritingTypeChoices, VENDORS, certificateTypeVendorChoices } from "../../../../constants";
import * as notifications from "../../../../notifications";
import AgeAssessedField from "../AgeAssessedField";

const LSIForm = ({ lifeExpectancyData, ...props }) => {
  let {
    mean,
    median,
    mortality_rating,
    mortality_table_name,
    tobacco_use,
    certificate_type,
    first_name,
    middle_initial,
    last_name,
    age,
    gender,
    date_of_birth,
    social_security,
    medical_info,
    certificate_date,
    certificate_id,
    client,
    underwriting_type,
  } = lifeExpectancyData;

  if (mortality_rating) {
    mortality_rating = Number(mortality_rating).toFixed(3);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        mean,
        median,
        mortality_rating,
        mortality_table_name,
        tobacco_use,
        certificate_type,
        first_name,
        middle_initial,
        last_name,
        age,
        gender,
        date_of_birth,
        social_security,
        medical_info,
        certificate_date,
        certificate_id,
        client,
        underwriting_type,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          await props.updateLifeExpectancy(lifeExpectancyData.id, values);
          await props.editLifeExpectancy(lifeExpectancyData.id);
          notifications.success("LSI form saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error saving LSI form, please check form errors");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="LSI Life Expectancy">
            <Form.Row>
              <Select
                label="LE UW Type"
                name="underwriting_type" // ??
                placeholder="Select Underwriting Type"
                options={underwritingTypeChoices}
                isRequired
              />
            </Form.Row>
            <Form.Row>
              <TextInput label="Mean LE" name="mean" placeholder="Enter Mean LE" prepend="Months" />
              <TextInput label="Median LE" name="median" placeholder="Enter Median LE" prepend="Months" />
              <TextInput label="Mortality Rating" name="mortality_rating" placeholder="Enter %" append="%" />
            </Form.Row>
            <Form.Row>
              <Select
                label="Mortality Table"
                name="mortality_table_name"
                options={props.mortalityTableChoices.getChoices({
                  group: VENDORS.LSI,
                })}
              />
              <BooleanSelect label="Tobacco Use" name="tobacco_use" />
              <Select label="LE Cert Type" name="certificate_type" options={certificateTypeVendorChoices.LSI} />
            </Form.Row>
            <Form.Row>
              <TextInput label="First Name" name="first_name" isRequired />
              <TextInput label="Middle Initial" name="middle_initial" md={2} />
              <TextInput label="Last Name" name="last_name" isRequired />
            </Form.Row>
            <Form.Row>
              <GenderSelect isRequired />
              <DateInput label="Date of Birth" name="date_of_birth" isRequired />
              <AgeAssessedField {...values} />
            </Form.Row>
            <Form.Row>
              <SSNInput isRequired name="social_security" label="SSN" />
              <DateInput label="Med Res to Date" name="medical_info.med_res_to_date" isRequired />
              <DateInput label="Certificate Date" name="certificate_date" isRequired />
            </Form.Row>
            <Form.Row>
              <TextInput label="Certificate ID" name="certificate_id" md={4} />
              <TextInput label="LE Client" name="client" md={4} />
            </Form.Row>
            <Form.SectionTitle>General History</Form.SectionTitle>
            <Form.Row>
              <TextInput
                label="Primary Impairment Category"
                name="medical_info.primary_impairment_category"
                placeholder="Select Primary Impairment Category"
              />
              <TextInput label="ICD" name="medical_info.primary_impairment_icd" placeholder="ICD" md={2} />
            </Form.Row>
            <Form.Row>
              <EditorField label="Primary Impairment Notes" name="medical_info.primary_impairment_notes" />
            </Form.Row>
            <Form.Row>
              <TextInput
                label="Secondary Impairment Category"
                name="medical_info.secondary_impairment_category"
                placeholder="Select Secondary Impairment Category"
              />
              <TextInput label="ICD" name="medical_info.primary_impairment_icd" placeholder="ICD" md={2} />
            </Form.Row>
            <Form.Row>
              <EditorField label="Secondary Impairment Notes" name="medical_info.secondary_impairment_notes" />
            </Form.Row>
            <Form.Row>
              <EditorField label="PMH" name="medical_info.pmh" config={{ toolbar: [] }} />
            </Form.Row>
            <Form.Row>
              <EditorField label="SH" name="medical_info.sh" config={{ toolbar: [] }} />
            </Form.Row>
            <Form.Row>
              <EditorField label="FH" name="medical_info.fh" config={{ toolbar: [] }} />
            </Form.Row>
            <Form.Row>
              <EditorField label="RX" name="medical_info.rx" config={{ toolbar: [] }} />
            </Form.Row>
            <Form.Row>
              <EditorField label="Fitness & Frailty Factors" name="medical_info.fitness" config={{ toolbar: [] }} />
            </Form.Row>
            <Form.Row>
              <EditorField label="Build" name="medical_info.build" config={{ toolbar: [] }} />
            </Form.Row>
            <Form.Row>
              <EditorField label="Other Detail" name="medical_info.other_detail" />
            </Form.Row>
            <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state, ownProps) => {
  const extractedPDFdata = extractedLifeExpectancyPDFdataSelector(state);

  // Life expectancy data will be either the extracted data from the uploaded PDF
  // or the saved LifeExpectancy instance.
  const lifeExpectancyData = extractedPDFdata || ownProps.lifeExpectancy;

  // Make sure lifeExpectancyData has an id prop, because the extractedPDFdata won't
  // have one and we need it in the form.
  const { id } = ownProps.lifeExpectancy;
  lifeExpectancyData.id = id;

  const lifeExpectancyChoices = lifeExpectancyChoicesSelector(state);

  return {
    lifeExpectancyData,
    mortalityTableChoices: lifeExpectancyChoices.mortality_table_name,
  };
};

export default connect(mapStateToProps, { updateLifeExpectancy, editLifeExpectancy })(LSIForm);
