import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { PARAMETER_MODAL } from "components/modals";
import ParameterForm from "./ParameterForm";

const ParameterModal = ({ ...props }) => {
  return (
    <FullPageModal modalType={PARAMETER_MODAL}>
      <ParameterForm callback={props.callback} />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(ParameterModal);
