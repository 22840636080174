import React from "react";
import { connect } from "react-redux";

import { fundersSelector, loadingFundersSelector } from "reducers";
import Table, { dateCell } from "components/table";
import IconLink from "../../components/IconLink";
import { FaEdit, FaTrash } from "react-icons/fa";
import { DELETE_FUNDER_MODAL } from "../../components/modals";
import { clearSelectedFunder, showModal } from "../../actions";

const FundersListTable = ({ funders, fundersLoading, ...props }) => {
  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const caseId = row.original.id;
        return (
          <>
            <IconLink
              to={`/funders/${caseId}/overview/`}
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View / Edit"
              onClick={() => {
                props.clearSelectedFunder();
                window.scrollTo(0, 0);
              }}
            />
            <IconLink
              Icon={FaTrash}
              iconConfig={{ className: "table--action-icon red" }}
              tooltip="Delete Funder"
              onClick={() => props.showModal(DELETE_FUNDER_MODAL, { id: caseId })}
            />
          </>
        );
      },
    },
    {
      Header: "Funder Name",
      accessor: "name",
      className: "wrap-text",
    },
    {
      Header: "Funder Status",
      accessor: "buying_status",
    },
    {
      Header: "Win Bid Notification",
      accessor: "winning_bid_notification",
      Cell: ({ row }) => {
        return row.original.winning_bid_notification ? "Yes" : "No";
      },
    },

    {
      Header: "Current High Bid Data",
      accessor: "access_current_high_bid",
      Cell: ({ row }) => {
        return row.original.access_current_high_bid ? "Yes" : "No";
      },
    },
    {
      Header: "Phone Number",
      accessor: "phone",
      className: "padded-table-cells",
    },
    {
      Header: "Email",
      accessor: "email",
      className: "padded-table-cells",
    },
    {
      Header: "Website",
      accessor: "website",
      className: "padded-table-cells",
    },
    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "State",
      accessor: "state",
    },
    {
      Header: "Last Report Run Date",
      accessor: "last_case_status_date",
      Cell: dateCell,
    },
    {
      Header: "Last Submit Date",
      accessor: "last_submit_date",
      Cell: dateCell,
    },
    {
      Header: "Last Bid Date",
      accessor: "last_bid_date",
      Cell: dateCell,
    },
    {
      Header: "Last Close Date",
      accessor: "variable_policies",
      Cell: dateCell,
    },
    {
      Header: "Last Contact Date",
      accessor: "survivorship",
      Cell: dateCell,
    },
  ];
  let tableClass = "";
  if (funders.length > 1) {
    tableClass = "tableFixed";
  }

  return (
    <>
      <Table
        columns={columns}
        data={funders}
        sortBy={[{ id: "name", desc: false }]}
        defaultSort={true}
        showPaginationResults={true}
        initialPageSize={100}
        defaultPagination={true}
        emptyMessage={fundersLoading ? "Loading Funders data..." : "No data"}
        tableClass={tableClass}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    funders: fundersSelector(state),
    fundersLoading: loadingFundersSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  clearSelectedFunder,
})(FundersListTable);
