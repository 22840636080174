import { makeChoices } from "../../utils";

export const ACCOUNT_TYPES = [
  { value: 0, label: "Individual" },
  { value: 1, label: "Entity" },
];

export const ACCOUNT_STATUS = [
  { value: 5, label: "Actionable Account" },
  { value: 3, label: "Active Account" },
  { value: 2, label: "Lead Account" },
  { value: 7, label: "Low Quality Account" },
  { value: 6, label: "Pending Case Account" },
  { value: 4, label: "Qualified Account" },
  { value: 1, label: "Targeted Account" },
];

export const TASK_TYPES = [
  { value: 2, label: "Call" },
  { value: 1, label: "E-mail" },
  { value: 5, label: "Fax" },
  { value: 6, label: "Follow Up" },
  { value: 7, label: "In Person Meeting" },
  { value: 4, label: "Text" },
  { value: 8, label: "Video Conference" },
  { value: 3, label: "Voice Mail" },
];

export const TASK_STATUS = [
  { value: 1, label: "Pending" },
  { value: 2, label: "Completed" },
  { value: 3, label: "Waived" },
];

export let TASK_TYPES_MAP = {};
for (const p of TASK_TYPES) {
  TASK_TYPES_MAP[p.value] = p.label;
}

export let TASK_STATUS_MAP = {};
for (const p of TASK_STATUS) {
  TASK_STATUS_MAP[p.value] = p.label;
}

export let ACCOUNT_AGREEMENT_STATUS = [
  { value: "1", label: "Active" },
  { value: "2", label: "Inactive" },
  { value: "3", label: "Pending" },
];

export let ACCOUNT_AGREEMENT_STATUS_MAP = {};
for (const p of ACCOUNT_AGREEMENT_STATUS) {
  ACCOUNT_AGREEMENT_STATUS_MAP[p.value] = p.label;
}

export let ACCOUNT_AFFILIATION_STATUS = [
  { value: "1", label: "Active" },
  { value: "2", label: "Inactive" },
];

export let ACCOUNT_AFFILIATION_STATUS_MAP = {};
for (const p of ACCOUNT_AFFILIATION_STATUS) {
  ACCOUNT_AFFILIATION_STATUS_MAP[p.value] = p.label;
}

export const SOURCE_OF_ACCOUNTS = [
  { value: "116", label: "List - Aaron" },
  { value: "100", label: "List - Discovery Data" },
  { value: "109", label: "List - FFR Scrape" },
  { value: "111", label: "List - Highland Scrape" },
  { value: "120", label: "List - High Tower Scrape" },
  { value: "117", label: "List - Hughes" },
  { value: "101", label: "List - IMS" },
  { value: "118", label: "List - Manahan" },
  { value: "105", label: "List - Marz" },
  { value: "106", label: "List - McGinty" },
  { value: "114", label: "List - M Group Scrape" },
  { value: "115", label: "List - NBA Scrape" },
  { value: "103", label: "List - Norman" },
  { value: "108", label: "List - Lion Street" },
  { value: "113", label: "List - LinkedIn Scrape" },
  { value: "121", label: "List - LRA" },
  { value: "112", label: "List - LSB Scrape" },
  { value: "110", label: "List - Simplicity Scrape" },
  { value: "104", label: "List - Siriani" },
  { value: "102", label: "List - Tafaro" },
  { value: "107", label: "List - Vetter" },
  { value: "119", label: "List - Williams" },
  { value: "999", label: "Unknown" },
];

export const MARKETING_SOURCES = [
  { value: "100", label: "Cold Call" },
  { value: "110", label: "Conference" },
  { value: "104", label: "Direct Mail" },
  { value: "107", label: "Facebook.com" },
  { value: "105", label: "LinkedIn.com" },
  { value: "101", label: "Mass Email" },
  { value: "102", label: "Personal Email" },
  { value: "108", label: "Referral" },
  { value: "111", label: "Seminar" },
  { value: "999", label: "Unknown" },
  { value: "109", label: "Webinar" },
  { value: "103", label: "Website" },
  { value: "106", label: "X.com" },
];

export const YES_NO_PAST = [
  { value: "1", label: "Yes" },
  { value: "2", label: "No" },
  { value: "3", label: "Past" },
];

export const PRACTICE_TYPES = [
  { value: "9", label: "Accounting Firm" },
  { value: "5", label: "Bank" },
  { value: "2", label: "Broker Dealer" },
  { value: "3", label: "General Agency" },
  { value: "13", label: "IMO" },
  { value: "10", label: "Law Firm" },
  { value: "12", label: "Provider" },
  { value: "8", label: "Registered Investment Advisor" },
  { value: "1", label: "Retail Advisory" },
  { value: "11", label: "Settlement Broker" },
  { value: "6", label: "Trust Company" },
  { value: "7", label: "Wire House" },
];

export let PRACTICE_TYPES_MAP = {};
for (const p of PRACTICE_TYPES) {
  PRACTICE_TYPES_MAP[p.value] = p.label;
}

export const LINES_OF_BUSINESS = [
  { value: "1", label: "Accounting" },
  { value: "2", label: "Annuities" },
  { value: "3", label: "Banking" },
  { value: "4", label: "Disability Insurance" },
  { value: "5", label: "Health Insurance" },
  { value: "6", label: "Legal" },
  { value: "7", label: "Life Insurance" },
  { value: "8", label: "Life Settlements" },
  { value: "9", label: "Premium Finance" },
  { value: "10", label: "Property & Casualty" },
  { value: "11", label: "Registered Investment Advisor" },
  { value: "12", label: "Securities" },
  { value: "13", label: "Trust Services" },
];

export const AFFILIATION_PRACTICE_TYPES = [
  { value: "2", label: "Broker Dealer" },
  { value: "3", label: "General Agency" },
  { value: "13", label: "IMO" },
  { value: "12", label: "Provider" },
  { value: "8", label: "Registered Investment Advisor" },
  { value: "11", label: "Settlement Broker" },
];

export let AFFILIATION_PRACTICE_TYPES_MAP = {};
for (const p of AFFILIATION_PRACTICE_TYPES) {
  AFFILIATION_PRACTICE_TYPES_MAP[p.value] = p.label;
}

export const VALIDATION_STATUS_OPTIONS = makeChoices([
  "Validated -  By Human",
  "Validated - By Machine",
  "Unvalidated",
  "False Positive - Educational",
  "False Positive - Ruled Out",
  "False Positive",
  "Family History",
]);
