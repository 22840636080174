import React from "react";
import { useField, useFormikContext } from "formik";
import { Form, InputGroup, Col } from "react-bootstrap";

import Label from "./Label";
import createMaskedInput from "./createMaskedInput";
import { validateLength, getValidationClassName } from "./validation";
import FieldErrors from "./FieldErrors";
import { getViewMode } from "../../utils";

const SSN_LENGTH = 9;

export default ({ name = "social_security", md = "", label = "Social Security Number", ...props }) => {
  const [field, meta] = useField({ name, validate: validateLength(SSN_LENGTH, /-/g) });
  const { setFieldValue } = useFormikContext();
  const isViewMode = getViewMode();
  const isDisabled = props.disabled || isViewMode;

  const d = /\d/;
  const ssnMask = [d, d, d, "-", d, d, "-", d, d, d, d];

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      <InputGroup style={{ flexWrap: "no-wrap!important" }}>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <div style={{ marginLeft: 1, marginRight: 3 }}>{label}</div>
            {props.icon}
          </InputGroup.Text>
        </InputGroup.Prepend>
        <div style={{ flexGrow: "1" }}>
          {createMaskedInput({
            mask: ssnMask,
            field,
            setFieldValue,
            className: getValidationClassName(meta),
            disabled: isDisabled,
          })}
        </div>
        <FieldErrors meta={meta} />
      </InputGroup>
    </Form.Group>
  );
};
