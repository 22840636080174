import advisorhq from "apis/advisorhq";
import * as types from "actions/types";
import { activeAgencySelector, activeFunderSelector, activeTradingDocumentIdSelector } from "reducers";
import * as notifications from "../../notifications";
import { redirectTo } from "../../utils";

function GetSortOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return -1;
    } else if (a[prop] < b[prop]) {
      return 1;
    }
    return 0;
  };
}

export const selectFunderContact = id => {
  return { type: types.SELECT_FUNDER_CONTACT, payload: id };
};

export const clearSelectedFunder = () => async dispatch => {
  dispatch({ type: types.CLEAR_SELECTED_FUNDER });
};

export const selectFunderEntity = id => {
  return { type: types.SELECT_FUNDER_ENTITY, payload: id };
};

export const selectSecurityIntermediary = id => {
  return { type: types.SELECT_SECURITY_INTERMEDIARY, payload: id };
};

export const selectTradingDocument = id => {
  return { type: types.SELECT_TRADING_DOCUMENT, payload: id };
};

export const fetchFunders = filters => async (dispatch, getState) => {
  if (!filters) {
    filters = {};
  }
  if (!filters.name) {
    filters.name = "";
  }
  filters.agency = activeAgencySelector(getState()).id;
  const endpoint = `/life_settlement/api/funder/`;
  dispatch({ type: types.LOADING, payload: { funders: true } });
  dispatch({ type: types.FETCH_FUNDERS, payload: [] });
  const response = await advisorhq.get(endpoint, { params: filters });
  dispatch({ type: types.FETCH_FUNDERS, payload: response.data });
  dispatch({ type: types.LOADING, payload: { funders: false } });
};

export const fetchFundersActivityReport = filters => async (dispatch, getState) => {
  if (!filters) {
    filters = {};
  }
  if (!filters.name) {
    filters.name = "";
  }
  filters.agency = activeAgencySelector(getState()).id;
  const endpoint = `/life_settlement/api/funder_activity_report/`;
  dispatch({ type: types.LOADING, payload: { funders: true } });
  dispatch({ type: types.FETCH_FUNDER_ACTIVITY_REPORT, payload: [] });
  const response = await advisorhq.get(endpoint, { params: filters });
  dispatch({ type: types.FETCH_FUNDER_ACTIVITY_REPORT, payload: response.data.report });
  dispatch({
    type: types.FETCH_FUNDER_ACTIVITY_REPORT_TOTALS,
    payload: response.data.totals,
  });
  dispatch({ type: types.LOADING, payload: { funders: false } });
};

export const fetchFundersForClosing = () => async (dispatch, getState) => {
  const endpoint = `/life_settlement/api/funder_closing/`;
  dispatch({ type: types.LOADING, payload: { funders: true } });
  dispatch({ type: types.FETCH_FUNDERS, payload: [] });
  const response = await advisorhq.get(endpoint);
  dispatch({ type: types.FETCH_FUNDERS, payload: response.data });
  dispatch({ type: types.LOADING, payload: { funders: false } });
};

export const fetchFunderCaseFits = (activeFunderId, filters) => async (dispatch, getState) => {
  const agencyId = activeAgencySelector(getState()).id;
  const endpoint = `/life_settlement/api/funder_case_fits/${activeFunderId}/${agencyId}/`;
  dispatch({ type: types.LOADING, payload: { funder_case_fits: true } });
  dispatch({ type: types.FETCH_FUNDER_CASE_FITS, payload: [] });
  const response = await advisorhq.get(endpoint, { params: filters });
  dispatch({ type: types.FETCH_FUNDER_CASE_FITS, payload: response.data });
  dispatch({ type: types.LOADING, payload: { funder_case_fits: false } });
};

export const fetchFunderCases = (activeFunderId, filters) => async (dispatch, getState) => {
  const agencyId = activeAgencySelector(getState()).id;
  const endpoint = `/life_settlement/api/funder_cases/${activeFunderId}/${agencyId}/`;
  dispatch({ type: types.LOADING, payload: { funder_cases: true } });
  dispatch({ type: types.FETCH_FUNDER_CASES, payload: [] });
  const response = await advisorhq.get(endpoint, { params: filters });
  dispatch({ type: types.FETCH_FUNDER_CASES, payload: response.data });
  dispatch({ type: types.LOADING, payload: { funder_cases: false } });
};

export const fetchFunderContacts = activeFunderId => async (dispatch, getState) => {
  const endpoint = `/life_settlement/api/funder_contact/`;
  dispatch({ type: types.LOADING, payload: { funder_contacts: true } });
  const response = await advisorhq.get(endpoint, {
    params: { funder_id: activeFunderId },
  });
  dispatch({ type: types.FETCH_FUNDER_CONTACTS, payload: response.data });
  dispatch({ type: types.LOADING, payload: { funder_contacts: false } });
};

export const fetchFunderEntities = activeFunderId => async (dispatch, getState) => {
  const endpoint = `/life_settlement/api/funder_entity/`;
  dispatch({ type: types.LOADING, payload: { funder_entities: false } });
  const response = await advisorhq.get(endpoint, {
    params: { funder_id: activeFunderId },
  });
  dispatch({ type: types.FETCH_FUNDER_ENTITIES, payload: response.data });
  dispatch({ type: types.LOADING, payload: { funder_entities: false } });
};

export const fetchClosingFunderEntities = activeFunderId => async (dispatch, getState) => {
  const endpoint = `/life_settlement/api/funder_entity/`;
  const response = await advisorhq.get(endpoint, {
    params: { funder_id: activeFunderId },
  });
  dispatch({ type: types.FETCH_CLOSING_FUNDER_ENTITIES, payload: response.data });
};

export const fetchTradingFunderEntities = activeFunderId => async (dispatch, getState) => {
  const endpoint = `/life_settlement/api/funder_entity/`;
  const response = await advisorhq.get(endpoint, {
    params: { funder_id: activeFunderId },
  });
  dispatch({ type: types.FETCH_TRADING_FUNDER_ENTITIES, payload: response.data });
};

export const fetchFundersForAuction = auctionId => async dispatch => {
  dispatch({ type: types.LOADING, payload: { funders: true } });
  const response = await advisorhq.get(`/auctions/api/funders_for_auction/${auctionId}/`);
  const results = response.data ? response.data.sort(GetSortOrder("name")) : response.data;
  dispatch({ type: types.FETCH_FUNDERS_FOR_AUCTION, payload: results });
  dispatch({ type: types.LOADING, payload: { funders: false } });
};

export const getFundersForClosing = caseId => async dispatch => {
  const response = await advisorhq.get(`/auctions/api/funders_for_closing_notification/${caseId}/`);
  return response.data;
};

export const selectFunder = id => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_FUNDER, payload: Number(id) });
  dispatch({ type: types.LOADING, payload: { funders: true } });
  let agency = activeAgencySelector(getState()).id;
  const response = await advisorhq.get(`/life_settlement/api/funder/${id}/`, {
    params: { agency: agency },
  });
  dispatch({ type: types.FETCH_FUNDER, payload: response.data });
  dispatch({ type: types.LOADING, payload: { funders: false } });
  return response.data;
};

export const fetchFunder = id => async (dispatch, getState) => {
  // without selecting
  dispatch({ type: types.LOADING, payload: { funders: true } });
  let agency = activeAgencySelector(getState()).id;
  const response = await advisorhq.get(`/life_settlement/api/funder/${id}/`, {
    params: { agency: agency },
  });
  dispatch({ type: types.FETCH_FUNDER, payload: response.data });
  dispatch({ type: types.LOADING, payload: { funders: false } });
  return response.data;
};

export const editFunder = (id, newValues) => async dispatch => {
  const url = `/life_settlement/api/funder/${id}/`;
  const response = await advisorhq.patch(url, newValues);
  dispatch({ type: types.EDIT_FUNDER, payload: response.data });
};

export const editFunderContact = (id, newValues) => async dispatch => {
  const url = `/life_settlement/api/funder_contact/${id}/`;
  const response = await advisorhq.patch(url, newValues);
  dispatch({ type: types.EDIT_FUNDER_CONTACT, payload: response.data });
};

export const editFunderEntity = (id, newValues) => async dispatch => {
  const url = `/life_settlement/api/funder_entity/${id}/`;
  const response = await advisorhq.patch(url, newValues);
  dispatch({ type: types.EDIT_FUNDER_ENTITY, payload: response.data });
};

export const createSecuritiesIntermediary = values => async dispatch => {
  const url = `/life_settlement/api/securities_intermediary/`;
  const response = await advisorhq.post(url, values);
  dispatch({ type: types.CREATE_SECURITIES_INTERMEDIARY, payload: response.data });
};

export const createTradingDocument = values => async dispatch => {
  const url = `/life_settlement/api/trading_document/`;
  const response = await advisorhq.post(url, values);
  dispatch({ type: types.CREATE_TRADING_DOCUMENT, payload: response.data });
  dispatch({ type: types.SELECT_TRADING_DOCUMENT, payload: response.data.id });
};

export const editSecuritiesIntermediary = (id, newValues) => async dispatch => {
  const url = `/life_settlement/api/securities_intermediary/${id}/`;
  const response = await advisorhq.patch(url, newValues);
  dispatch({ type: types.EDIT_SECURITIES_INTERMEDIARY, payload: response.data });
};

export const editTradingDocument = (id, newValues) => async dispatch => {
  const url = `/life_settlement/api/trading_document/${id}/`;
  const response = await advisorhq.patch(url, newValues);
  dispatch({ type: types.EDIT_TRADING_DOCUMENT, payload: response.data });
};

export const createEmptyFunder = () => async (dispatch, getState) => {
  const url = "/life_settlement/api/funder/";

  try {
    const response = await advisorhq.post(url, {});
    dispatch({ type: types.CREATE_EMPTY_FUNDER, payload: response.data });
    notifications.success("A new Funder was created");
    window.scrollTo(0, 0);
    redirectTo(`/funders/${response.data.id}/overview/`);
  } catch (e) {
    notifications.error("Error creating Funder");
  }
};

export const createEmptyFunderContact = activeFunderId => async (dispatch, getState) => {
  const url = "/life_settlement/api/funder_contact/";
  console.log("activeFunderId", activeFunderId);
  const response = await advisorhq.post(url, {
    funder_id: activeFunderId,
  });

  const endpoint = `/life_settlement/api/funder_contact/`;
  const funderResponse = await advisorhq.get(endpoint, {
    params: { funder_id: activeFunderId },
  });
  dispatch({ type: types.FETCH_FUNDER_CONTACTS, payload: funderResponse.data });

  return response.data;
};

export const createEmptyFunderEntity = activeFunderId => async (dispatch, getState) => {
  const url = "/life_settlement/api/funder_entity/";
  const response = await advisorhq.post(url, {
    funder_id: activeFunderId,
  });

  const endpoint = `/life_settlement/api/funder_entity/`;
  const funderResponse = await advisorhq.get(endpoint, {
    params: { funder_id: activeFunderId },
  });
  dispatch({ type: types.FETCH_FUNDER_ENTITIES, payload: funderResponse.data });

  return response.data;
};

export const deleteFunder = id => async (dispatch, getState) => {
  await advisorhq.delete(`/life_settlement/api/funder/${id}/`);
  dispatch({ type: types.DELETE_FUNDER, payload: id });
};

export const deleteFunderContact = id => async (dispatch, getState) => {
  await advisorhq.delete(`/life_settlement/api/funder_contact/${id}/`);
  dispatch({ type: types.DELETE_FUNDER_CONTACT, payload: id });
};

export const deleteFunderEntity = id => async (dispatch, getState) => {
  await advisorhq.delete(`/life_settlement/api/funder_entity/${id}/`);
  dispatch({ type: types.DELETE_FUNDER_ENTITY, payload: id });
};

export const deleteSecurityIntermediary = id => async (dispatch, getState) => {
  await advisorhq.delete(`/life_settlement/api/securities_intermediary/${id}/`);
  dispatch({ type: types.DELETE_SECURITY_INTERMEDIARY, payload: id });

  let funderInfo = activeFunderSelector(getState());
  let activeFunderId = funderInfo.id;
  const endpoint = `/life_settlement/api/funder_entity/`;
  const response = await advisorhq.get(endpoint, {
    params: { funder_id: activeFunderId },
  });
  dispatch({ type: types.FETCH_FUNDER_ENTITIES, payload: response.data });
};

export const deleteTradingDocument = id => async (dispatch, getState) => {
  await advisorhq.delete(`/life_settlement/api/trading_document/${id}/`);
  dispatch({ type: types.DELETE_TRADING_DOCUMENT, payload: id });

  let funderInfo = activeFunderSelector(getState());
  let activeFunderId = funderInfo.id;
  const endpoint = `/life_settlement/api/funder_entity/`;
  const response = await advisorhq.get(endpoint, {
    params: { funder_id: activeFunderId },
  });
  dispatch({ type: types.FETCH_FUNDER_ENTITIES, payload: response.data });
};

export const uploadTradingDocument = file => async (dispatch, getState) => {
  notifications.success("Uploading Trading Document...", file);
  const state = getState();
  const activeTradingDocumentId = activeTradingDocumentIdSelector(state);
  const formData = new FormData();
  formData.append("id", activeTradingDocumentId);
  formData.append("file", file, file.name);

  try {
    await advisorhq.post(`/life_settlement/api/trading_document/${activeTradingDocumentId}/upload_file/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    let funderInfo = activeFunderSelector(getState());
    let activeFunderId = funderInfo.id;
    const endpoint = `/life_settlement/api/funder_entity/`;
    const activeFunderResponse = await advisorhq.get(endpoint, {
      params: { funder_id: activeFunderId },
    });
    dispatch({ type: types.FETCH_FUNDER_ENTITIES, payload: activeFunderResponse.data });

    notifications.success("Trading Document updated successfully");
  } catch (error) {
    notifications.error("Error uploading Trading Document");
    console.error(error);
  }
};

export const updateFunderFilters = filters => async (dispatch, getState) => {
  dispatch({ type: types.UPDATE_FUNDER_FILTERS, payload: filters });
};
