import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import PDFViewer from "components/pdf/PDFViewer";
import { activeMortalityAssessmentSelector } from "reducers";
import MortalityAssessmentSummaryForm from "../MortalityAssessmentSummaryForm";

const MortalityAssessmentViewer = ({ mortalityAssessmentInfo, ...props }) => {
  return (
    <div className={`section-card shadowed case-section`} style={{ marginTop: "5.5rem", marginBottom: 0 }}>
      <Card className={"case-info"}>
        <Card.Body>
          <MortalityAssessmentSummaryForm />
          {mortalityAssessmentInfo?.file ? (
            <PDFViewer fileUrl={mortalityAssessmentInfo.file} />
          ) : (
            <p>File Pending, refresh page later</p>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    mortalityAssessmentInfo: activeMortalityAssessmentSelector(state),
  };
};

export default connect(mapStateToProps, {})(MortalityAssessmentViewer);
