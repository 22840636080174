import React from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";

const Footer = props => {
  return (
    <>
      <Card
        style={{
          bottom: 0,
          marginTop: "auto",
          border: "1px solid black",
          borderRadius: "0px",
        }}
      >
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            backgroundColor: "black",
            padding: "0.8rem",
            width: "100%",
            zIndex: 25,
            border: "1px solid black",
            borderRadius: "0px",
          }}
        >
          <h5
            className="section-title navbar-title"
            style={{
              display: "flex",
              color: "white",
              alignItems: "center",
              marginBottom: 0,
              lineHeight: 1,
              justifyContent: "center",
              margin: "auto",
              fontSize: "0.8rem",
            }}
          >
            LifeRoc, LLC | Copy Right {props.currentYear} | All Rights Reserved | Trade Secret & Confidential
          </h5>
        </Card.Header>
      </Card>
    </>
  );
};

const mapStateToProps = state => {
  const currentYear = new Date().getFullYear();
  return {
    currentYear: currentYear,
  };
};

export default connect(mapStateToProps, {})(Footer);
