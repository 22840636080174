import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import { activeParameterSelector } from "../../../reducers";
import ParameterDetailClinicalForm from "./ParameterDetailClinicalForm";
import { RecordsTable } from "../../../components/table";
import { CLINICAL_RESEARCH_MODAL } from "../../../components/modals";
import ClinicalResearchFilesTable from "./clinicalResearch/ClinicalResearchFilesTable";
import { showModal, selectClinicalResearch } from "actions";

const ParameterDetailClinical = ({ parameterInfo, ...props }) => {
  return (
    <div className={`section-card shadowed case-section`} style={{ marginTop: "5.5rem" }}>
      <Card className={"case-info"}>
        <Card.Body>
          <ParameterDetailClinicalForm parameterInfo={parameterInfo} />
          <RecordsTable
            title="Clinical Research"
            createButtonLabel="Add Clinical Research"
            onCreateClick={() => {
              props.selectClinicalResearch(null);
              props.showModal(CLINICAL_RESEARCH_MODAL);
            }}
            Table={ClinicalResearchFilesTable}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    parameterInfo: activeParameterSelector(state),
  };
};

export default connect(mapStateToProps, {
  selectClinicalResearch,
  showModal,
})(ParameterDetailClinical);
