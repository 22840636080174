import React from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";

import {
  EditorField,
  FormCollapsible,
  SubmitButton,
  TextInput,
  DateTimeInput,
  Select,
  DateInput,
} from "components/forms";
import {
  activeCaseIdSelector,
  activeCaseTaskSelector,
  activeTabSelector,
  managementChoicesSelector,
  storedCaseRecordsFiltersSelector,
  storedRecordsFiltersSelector,
} from "reducers";
import * as notifications from "notifications";
import { createCaseTask, editCaseTask, fetchLifeSettlementCase, fetchRecords, hideModal } from "actions";
import { Form } from "react-bootstrap";
import { partial } from "utils";
import { TASK_STATUS } from "../../accounts/constants";
import { CASE_TASK_MODAL } from "../../../components/modals";
import { isEmpty } from "lodash";
import { addThreeBusinessDays } from "../../../reducers/utils";
import { FETCH_RECORDS_BY_TYPE_MAP, TaskTab } from "../../records/constants";
import { useLocation } from "react-router-dom";
import { URLS } from "../../../constants";
import { getTaskObjId } from "../../records/utils";

const CaseTaskForm = ({ record: activeCaseTask, ...props }) => {
  let due_date = isEmpty(activeCaseTask) ? props.defaultDueDate : activeCaseTask.due_date;

  let initialValues = {
    ...activeCaseTask,
    ...activeCaseTask?.task_obj,
    due_date,
  };

  const location = useLocation();
  const dispatch = useDispatch();
  const caseTaskId = getTaskObjId(activeCaseTask);
  let isSaved = caseTaskId;
  let title = isSaved ? "Edit Case Task" : "New Case Task";

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isSaved ? partial(props.editCaseTask, caseTaskId) : props.createCaseTask;
        try {
          await method(values);
          notifications.clearAll();
          notifications.success("Case Task saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Case Task");
        }
        // Refresh table by loading the case
        if (props.activeCaseId) {
          await props.fetchLifeSettlementCase(props.activeCaseId);
        }
        // Refresh tasks table - depends if we are in All Tasks or Case Tasks
        if (props.activeTab === "tasks" || props.activeTab === "case-tasks") {
          let type = TaskTab.ALL;
          let filterValues = props.recordsFilters;
          if (location.pathname.includes(URLS.CASE_TASKS)) {
            type = TaskTab.CASE;
            filterValues = props.caseRecordsFilters;
          }
          const fetch_action = FETCH_RECORDS_BY_TYPE_MAP[type];
          await props.fetchTasks({ ...filterValues }, fetch_action);
        }
        dispatch(hideModal(CASE_TASK_MODAL));
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <>
            <FormCollapsible onSubmit={handleSubmit} title={title}>
              {values.record_id ? (
                <Form.Row>
                  <TextInput label="Record ID" name="record_id" disabled />
                  <TextInput label="Record Name" name="record_name" disabled />
                </Form.Row>
              ) : null}
              <Form.Row>
                <Select label="Status" name="status" placeholder="Select Status" options={TASK_STATUS} />
                <Select label="Owner" name="owner" placeholder="Select Owner" options={props.salesRepChoices} />
                <DateInput label="Due Date" name="due_date" />
              </Form.Row>
              <Form.Row>
                <TextInput label="Created By" name="record_creator" disabled={true} />
                <TextInput label="Last Edited By" name="last_editor" disabled={true} />
                <DateTimeInput label="Last Edited Date" name="last_edit_date" disabled={true} />
              </Form.Row>
              <Form.Row>
                <EditorField label="Notes" name="notes" />
              </Form.Row>
              <SubmitButton />
            </FormCollapsible>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const activeCaseId = activeCaseIdSelector(state);
  const choices = managementChoicesSelector(state);
  const defaultDueDate = addThreeBusinessDays().toISOString().split("T")[0];

  return {
    activeCaseId,
    salesRepChoices: choices.case_sales_representatives.getChoices({ sortByLabel: true }),
    defaultDueDate,
    activeTab: activeTabSelector(state),
    recordsFilters: storedRecordsFiltersSelector(state),
    caseRecordsFilters: storedCaseRecordsFiltersSelector(state),
  };
};

export default connect(mapStateToProps, {
  createCaseTask,
  editCaseTask,
  fetchLifeSettlementCase,
  fetchTasks: fetchRecords,
})(CaseTaskForm);
