import React from "react";
import { connect } from "react-redux";

import { createEmptyCase, setActiveTab, initPagination, downloadFile } from "actions";
import CasesFilterForm from "./CasesFilterForm";
import { ButtonCreate } from "../../../components/buttons";
import CasesTable from "./CasesTable";
import moment from "moment";
import { FaDownload } from "react-icons/fa";
import { isAdmin } from "permissions";

const CasesPage = props => {
  const [activeSection, setActiveSection] = React.useState("cases");
  React.useEffect(() => {
    props.setActiveTab(activeSection);
    props.initPagination();
    setActiveSection(activeSection);
    document.title = "LifeRoc | Cases";
  }, []);

  const FileBuildingReportButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: 0 }}>
        <button
          className="btn btn--secondary navbar-save-button"
          onClick={() => {
            const today = moment().format("MM/DD/YYYY");
            const url = `/life_settlement/api/file_building_report/`;
            props.downloadFile(url, `File_Building_Cases_Report_(${today}).xlsx`);
          }}
          style={{
            width: "100%",
            height: "35px",
            marginLeft: "15px",
          }}
        >
          <FaDownload /> File Building Report
        </button>
      </div>
    );
  };

  return (
    <div>
      <CasesFilterForm />
      {props.hasAdminPermission && (
        <div style={{ display: "flex" }}>
          <ButtonCreate onClick={props.createEmptyCase} id="create-new-case">
            Submit New Case
          </ButtonCreate>
          <FileBuildingReportButton />
        </div>
      )}
      <CasesTable />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  createEmptyCase,
  setActiveTab,
  initPagination,
  downloadFile,
})(CasesPage);
