import { moneyDisplay, parseFloatFromString } from "../../../../utils";

export function parseAndFloor(value) {
  try {
    return Math.floor(parseInt(value.replaceAll(",", ""), 10));
  } catch (error) {
    return Math.floor(parseInt(value, 10));
  }
}

export function calculateDifference(highestFunderBid, highestMarketBid) {
  const total_offer = parseAndFloor(highestFunderBid.total_offer || "0");
  const gross_offer = parseAndFloor(highestMarketBid.gross_offer || "0");
  const fa_conversion_compensation = parseAndFloor(highestFunderBid.conversion_compensation || "0");
  const ma_conversion_compensation = parseAndFloor(highestMarketBid.conversion_compensation || "0");

  return total_offer + fa_conversion_compensation - gross_offer - ma_conversion_compensation;
}

export const calcWinningMarketBidValue = marketActivities => {
  if (marketActivities.length > 0) {
    marketActivities = marketActivities.filter(activity => activity.type === "B" || activity.type === "BC");
    const marketActivitiesWithOffer = marketActivities.filter(activity => activity.provider && activity.gross_offer);
    const highestGrossOfferActivity = marketActivitiesWithOffer.reduce(
      (a, b) => (parseFloatFromString(a.gross_offer) > parseFloatFromString(b.gross_offer) ? a : b),
      { gross_offer: 0 },
    );
    if (!highestGrossOfferActivity) {
      return "";
    }
    return highestGrossOfferActivity.gross_offer ? moneyDisplay(highestGrossOfferActivity.gross_offer) : "";
  }
  return "";
};

export const calcWinningFunderBidValue = funderActivities => {
  funderActivities = funderActivities.filter(activity => activity.type === "B" || activity.type === "BC");
  if (funderActivities.filter(activity => activity.funder && activity.total_offer).length > 0) {
    const funderActivitiesWithOffer = funderActivities.filter(activity => activity.funder && activity.total_offer);
    const highestTotalOfferActivity = funderActivitiesWithOffer.reduce(
      (a, b) => (parseFloatFromString(a.total_offer) >= parseFloatFromString(b.total_offer) ? a : b),
      { total_offer: 0 },
    );
    if (!highestTotalOfferActivity) {
      return "";
    }
    return moneyDisplay(highestTotalOfferActivity.total_offer);
  }
  return "";
};
