import React, { useState } from "react";

import { TableHeader } from "components/table";

// Table to a list of records, with a title and a create button
const MultiTab = ({ Component1, Component2, component1Props, component2Props, title1, title2, ...props }) => {
  const [tab, setTab] = useState(false);

  return (
    <div className="records-table">
      <TableHeader.Tabs>
        <TableHeader.Title style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
            <div
              className={tab ? "records-table-tabs" : "records-table-tabs active"}
              onClick={() => setTab(false)}
              key="funders-false"
              style={{ width: "100%", textAlign: "center" }}
            >
              {title1}
            </div>
            <div
              className={tab ? "records-table-tabs active" : "records-table-tabs"}
              onClick={() => setTab(true)}
              key="funders-true"
              style={{ width: "100%", textAlign: "center" }}
            >
              {title2}
            </div>
          </div>
        </TableHeader.Title>
      </TableHeader.Tabs>
      {tab ? <Component2 component2props /> : <Component1 component1props />}
    </div>
  );
};

export default MultiTab;
