import {
  SELECT_PERSON_DILIGENCE,
  FETCH_PERSON_DILIGENCES,
  EDIT_PERSON_DILIGENCE,
  CREATE_PERSON_DILIGENCE,
  DELETE_PERSON_DILIGENCE,
} from "actions/types";
import { removeKey } from "utils.js";
import { arrayToObjectIndexedById } from "reducers/utils";

export const defaultState = {
  activePersonDiligenceId: null,
  personDiligences: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_PERSON_DILIGENCE:
      return { ...state, activePersonDiligenceId: action.payload };

    case FETCH_PERSON_DILIGENCES:
      return { ...state, personDiligences: arrayToObjectIndexedById(action.payload) };

    case EDIT_PERSON_DILIGENCE:
    case CREATE_PERSON_DILIGENCE:
      return {
        ...state,
        personDiligences: {
          ...state.personDiligences,
          [action.payload.id]: action.payload,
        },
      };

    case DELETE_PERSON_DILIGENCE: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        personDiligences: removeKey(state.personDiligences, toDeleteId),
      };
      if (state.activePersonDiligenceId === toDeleteId) {
        newState.activePersonDiligenceId = null;
      }
      return newState;
    }

    default:
      return state;
  }
};

export const personDiligencesSelector = state => Object.values(state.personDiligences);

export const activePersonDiligenceSelector = state => {
  const { activePersonDiligenceId, personDiligences } = state;

  if (activePersonDiligenceId === null) {
    return null;
  }
  return personDiligences[activePersonDiligenceId];
};

export const personDiligencesForActiveOwnerSelector = (state, activeOwnerId) => {
  const personDiligences = Object.values(state.personDiligences);
  return personDiligences.filter(le => le.person === activeOwnerId);
};
