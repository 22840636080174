import React from "react";

export default ({ rows, emptyMessage, getTableBodyProps, prepareRow, rowIsActive }) => {
  if (rows.length === 0) {
    return (
      <tbody className="empty">
        <tr>
          <td colSpan="1000">{emptyMessage}</td>
        </tr>
      </tbody>
    );
  }
  return (
    <tbody {...getTableBodyProps()}>
      {rows.map(row => {
        prepareRow(row);
        const active = rowIsActive(row);
        return (
          <tr {...row.getRowProps()} key={row.id} className={(active && "active") || ""}>
            {row.cells.map(cell => {
              const { key, ...cellProps } = cell.getCellProps({ className: cell.column.className });
              return (
                <td {...cellProps} key={key}>
                  {cell.render("Cell")}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};
