import React from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";

import { hideModal } from "actions";

export default ({
  header,
  onConfirm,
  onCancel,
  children,
  modalType, // Which "modalType" is using this modal, necessary to close it.
}) => {
  const dispatch = useDispatch();

  return (
    <Modal show centered className="modal modal--confirm" onHide={() => dispatch(hideModal(modalType))}>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};
