import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import FullPageModal from "components/modals/FullPageModal";
import { CASE_FILE_MODAL } from "components/modals";
import { activeCaseSelector } from "reducers";
import { showModal } from "actions";
import CaseFileForm from "./CaseFileForm";
import { getViewMode } from "utils";

const CaseFileModal = ({ next, previous, ...props }) => {
  const history = useNavigate();
  const isViewMode = getViewMode();

  const onCloseCallback = () => {
    // Set the URL back to /files/
    if (history && props.caseInfo?.id) {
      let returnUrl = `/cases/${props.caseInfo.id}/files/`;
      if (isViewMode) {
        returnUrl += "?view";
      }
      history(returnUrl);
    }
  };

  return (
    <FullPageModal modalType={CASE_FILE_MODAL} onCloseCallback={onCloseCallback}>
      <CaseFileForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
})(CaseFileModal);
