import React from "react";
import { useDispatch } from "react-redux";

import ChooseFollowUpDateModal from "components/modals/ChooseFollowUpDateModal";
import { CONFIRM_FOLLOW_UP_REPLACE_MODAL } from "components/modals";
import { hideModal } from "actions";
import DatePicker from "react-datepicker";

export default props => {
  const dispatch = useDispatch();
  const [date, setDate] = React.useState(new Date());

  return (
    <ChooseFollowUpDateModal
      modalType={CONFIRM_FOLLOW_UP_REPLACE_MODAL}
      onConfirm={() => {
        props.onConfirm(date);
        dispatch(hideModal(CONFIRM_FOLLOW_UP_REPLACE_MODAL));
      }}
    >
      <div style={{ marginLeft: 125 }}>
        <DatePicker selected={date} onChange={date => setDate(date)} />
      </div>
    </ChooseFollowUpDateModal>
  );
};
