import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { FormCollapsible, FormFilter, Select, SubmitSearchButton, TextInput } from "components/forms";
import {
  createMortalityAssessmentCondition,
  fetchParameters,
  fetchPolicy,
  hideModal,
  selectMortalityAssessment,
} from "actions";
import { Form } from "react-bootstrap";
import Table, { choiceCell } from "components/table";
import { ADD_CONDITION_MODAL } from "components/modals";
import { PARAMETER_CATEGORY_CHOICES, PARAMETER_SUB_CATEGORY_CHOICES } from "../../../constants";
import { activeMortalityAssessmentSelector } from "../../../../../reducers";

const AddConditionForm = ({ ...props }) => {
  const [conditions, setConditions] = React.useState([]);

  const ConditionsForMortalityAssessmentTable = () => {
    if (!conditions) {
      return null;
    }

    const columns = [
      {
        Header: "Select",
        Cell: ({ row }) => {
          return (
            <button
              className={`btn btn--secondary`}
              onClick={async e => {
                e.preventDefault();
                props.hideModal(ADD_CONDITION_MODAL);
                await props.createMortalityAssessmentCondition({
                  assessment_id: props.mortalityAssessmentInfo.id,
                  condition_id: row.original.id,
                });
                props.selectMortalityAssessment(props.mortalityAssessmentInfo.id);
              }}
            >
              Select
            </button>
          );
        },
      },
      {
        Header: "Category",
        accessor: "category",
        Cell: choiceCell(PARAMETER_CATEGORY_CHOICES),
      },
      {
        Header: "Sub-Category",
        accessor: "sub_category",
        className: "wrap-text",
      },
      {
        Header: "Name",
        accessor: "name",
        className: "wrap-text",
      },
      {
        Header: "Code",
        accessor: "code",
        className: "wrap-text",
      },
    ];

    return (
      <Table
        columns={columns}
        data={conditions}
        defaultSort={true}
        showPaginationResults={false}
        defaultPagination={true}
        emptyMessage={"No results"}
      />
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        category: "CONDITION",
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        document.activeElement.blur(); // Remove focus from the currently focused element
        let data = await props.fetchParameters(values);
        setConditions(data.results);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title={"Condition Search"}>
            <Form.Row>
              <Select
                label="Health Parameter Category"
                name="category"
                placeholder="Select Category"
                options={PARAMETER_CATEGORY_CHOICES}
                disabled
              />
              <Select
                label="Health Parameter Sub-Category"
                name="sub_category"
                placeholder="Select Sub-Category"
                options={PARAMETER_SUB_CATEGORY_CHOICES}
              />
              <TextInput label="Health Parameter Name" name="name" />
              <TextInput label="Health Parameter Code" name="code" />
            </Form.Row>
            <Form.Row>
              <SubmitSearchButton />
            </Form.Row>
            <ConditionsForMortalityAssessmentTable />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    mortalityAssessmentInfo: activeMortalityAssessmentSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchPolicy,
  hideModal,
  fetchParameters,
  createMortalityAssessmentCondition,
  selectMortalityAssessment,
})(AddConditionForm);
