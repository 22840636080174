import React from "react";
import { connect } from "react-redux";

import { showModal, downloadFile, selectAccountAgreement } from "actions";
import { activeAccountSelector } from "reducers";
import { ACCOUNT_AGREEMENT_MODAL, DELETE_ACCOUNT_AGREEMENT_MODAL } from "components/modals";
import Table, { dateCell } from "components/table";
import IconLink from "../../../../components/IconLink";
import { FaDownload, FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { ACCOUNT_AGREEMENT_STATUS_MAP } from "../../constants";
import { IconLinkViewOrEdit, IconLinkDelete } from "components/icons";

const AgreementTable = ({ activeAccount, ...props }) => {
  if (!activeAccount) {
    // Still didn't fetch data
    return null;
  }

  const columns = [
    {
      Header: "Action",
      className: "action",
      Cell: ({ row }) => {
        const id = row.original.id;
        const file = row.original.url;
        return (
          <>
            <IconLinkViewOrEdit
              onClick={() => {
                props.selectAccountAgreement(row.original);
                props.showModal(ACCOUNT_AGREEMENT_MODAL);
              }}
            />
            <IconLinkDelete
              onClick={() => {
                props.showModal(DELETE_ACCOUNT_AGREEMENT_MODAL, { id });
              }}
            />
            {file ? (
              <>
                <IconLink
                  Icon={FaDownload}
                  iconConfig={{ className: "table--action-icon" }}
                  tooltip="Download"
                  onClick={() => {
                    const url = `/api/agreement/${id}/download_file/`;
                    props.downloadFile(url, row.original.name);
                  }}
                />
                <IconLink
                  Icon={FaEye}
                  iconConfig={{ className: "table--action-icon" }}
                  tooltip="Preview"
                  onClick={() => window.open(row.original.url, "_blank")}
                />
              </>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Version",
      accessor: "version",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const value = row.original.status;
        if (value !== null) {
          return ACCOUNT_AGREEMENT_STATUS_MAP[value] || "";
        } else {
          return "";
        }
      },
    },
    {
      Header: "Effective Date",
      accessor: "effective_date",
      Cell: dateCell,
    },
    {
      Header: "Termination Date",
      accessor: "termination_date",
      Cell: dateCell,
    },
  ];

  return (
    <Table
      columns={columns}
      data={props.agreements}
      defaultSort={true}
      paginated={false}
      emptyMessage="No Agreements found"
    />
  );
};

const mapStateToProps = state => {
  const activeAccount = activeAccountSelector(state);

  return {
    activeAccount,
    agreements: activeAccount ? activeAccount.agreements : [],
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectAccountAgreement,
  downloadFile,
})(AgreementTable);
