import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";

import { selectFunderContact, showModal } from "actions";
import { fullNameDisplay } from "../../utils";
import { ButtonDelete } from "components/buttons";
import SectionDetails from "../../components/SectionDetails";
import { DELETE_FUNDER_CONTACT_MODAL } from "../../components/modals";

const FunderContactInfo = ({ isActive, info, deleteHandler, deleteText, ...props }) => {
  if (!info) {
    return null;
  }

  return (
    <div
      className={`section-card shadowed ${isActive && "active"}`}
      onClick={() => {
        props.selectFunderContact(info.id);
      }}
      style={{ cursor: "pointer" }}
    >
      <Card>
        <Card.Header>
          <h5 className="section-title">{props.title || `Funder Contact (${props.idx})`}</h5>
        </Card.Header>
        <Card.Body>
          <SectionDetails
            details={{
              Name: fullNameDisplay(info.last_name, info.first_name),
              Title: info.title,
              "Office Phone": info.office_phone,
              "Mobile Phone": info.mobile_phone,
            }}
          />
        </Card.Body>
        <Card.Footer>
          <ButtonDelete
            text={deleteText}
            onClick={() => {
              props.showModal(DELETE_FUNDER_CONTACT_MODAL, info);
            }}
          />
        </Card.Footer>
      </Card>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps, {
  showModal,
  selectFunderContact,
})(FunderContactInfo);
