import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { TextInput, FormFilter, SubmitSearchButton, ClearFiltersButton } from "components/forms";
import { fetchStates } from "actions";

const StatesFilterForm = ({ fetchStates, ...props }) => {
  return (
    <Formik
      initialValues={{
        name: "",
      }}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        await fetchStates(values);
        setSubmitting(false);
      }}
      onReset={async (values, { setSubmitting }) => {
        await fetchStates();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, resetForm }) => {
        return (
          <FormFilter title={"State Search"} onSubmit={handleSubmit}>
            <Form.Row>
              <TextInput label="Name" name="name" />
              <TextInput label="State Code" name="code" />
            </Form.Row>
            <SubmitSearchButton />
            <ClearFiltersButton onClick={resetForm} />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

export default connect(null, {
  fetchStates,
})(StatesFilterForm);
