import { Formik } from "formik";
import { SubmitButton } from "../../components/forms";
import { Form } from "react-bootstrap";
import {
  pendingRecordsCountSelector,
  pastDueRecordsCountSelector,
  pendingCaseRecordsCountSelector,
  pastDueCaseRecordsCountSelector,
  pendingCaseRequirementRecordsCountSelector,
  pastDueCaseRequirementRecordsCountSelector,
  pendingAccountRecordsCountSelector,
  pastDueAccountRecordsCountSelector,
  pendingLeadRecordsCountSelector,
  pastDueLeadRecordsCountSelector,
} from "reducers";
import { connect } from "react-redux";
import { editCase, selectCaseFile, showModal } from "../../actions";
import React from "react";
import { DisplayValueWithTitle } from "../../components/forms/DisplayValueWithTitle";
import { TaskTab } from "./constants";

const RecordsSummaryForm = ({ type = TaskTab.ALL, caseInfo, ...props }) => {
  let pendingCount = 0;
  let pastDueCount = 0;
  switch (type) {
    case TaskTab.ALL:
      pendingCount = props.pendingRecordsCount;
      pastDueCount = props.pastDueRecordsCount;
      break;
    case TaskTab.CASE:
      pendingCount = props.pendingCaseRecordsCount;
      pastDueCount = props.pastDueCaseRecordsCount;
      break;
    case TaskTab.CASE_REQUIREMENT:
      pendingCount = props.pendingCaseRequirementRecordsCount;
      pastDueCount = props.pastDueCaseRequirementRecordsCount;
      break;
    case TaskTab.ACCOUNT:
      pendingCount = props.pendingAccountRecordsCount;
      pastDueCount = props.pastDueAccountRecordsCount;
      break;
    case TaskTab.LEAD:
      pendingCount = props.pendingLeadRecordsCount;
      pastDueCount = props.pastDueLeadRecordsCount;
      break;
  }
  return (
    <Formik enableReinitialize initialValues={{}} onSubmit={{}}>
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <>
            <Form onSubmit={handleSubmit}>
              <Form.Row style={{ padding: 0 }}>
                <DisplayValueWithTitle title={"Number of Pending Tasks"} value={pendingCount} md={3} />
                <DisplayValueWithTitle title={"Number of Past Due Tasks"} value={pastDueCount} md={3} />
              </Form.Row>
              <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    pendingRecordsCount: pendingRecordsCountSelector(state),
    pastDueRecordsCount: pastDueRecordsCountSelector(state),
    pendingCaseRecordsCount: pendingCaseRecordsCountSelector(state),
    pastDueCaseRecordsCount: pastDueCaseRecordsCountSelector(state),
    pendingCaseRequirementRecordsCount: pendingCaseRequirementRecordsCountSelector(state),
    pastDueCaseRequirementRecordsCount: pastDueCaseRequirementRecordsCountSelector(state),
    pendingAccountRecordsCount: pendingAccountRecordsCountSelector(state),
    pastDueAccountRecordsCount: pastDueAccountRecordsCountSelector(state),
    pendingLeadRecordsCount: pendingLeadRecordsCountSelector(state),
    pastDueLeadRecordsCount: pastDueLeadRecordsCountSelector(state),
  };
};

export default connect(mapStateToProps, {
  editCase,
  selectCaseFile,
  showModal,
})(RecordsSummaryForm);
