import Table, { dateCell } from "components/table";
import React from "react";
import { connect } from "react-redux";
import { activeMortalityAssessmentSelector } from "../../../../reducers";

const MortalityAssessmentCurveNumericTable = ({ mortalityAssessmentInfo, ...props }) => {
  const columns = [
    {
      Header: "Date",
      accessor: "date",
      Cell: dateCell,
    },
    {
      Header: "Age",
      accessor: "age",
    },
    {
      Header: "Month",
      accessor: "month",
    },
    {
      Header: "Duration",
      accessor: "duration",
    },
    {
      Header: "Mortality Rating",
      accessor: "mortality_multiplier",
    },
    {
      Header: "qX",
      accessor: "qx",
    },
    {
      Header: "BOM Lives",
      accessor: "bom_lives",
    },
    {
      Header: "Monthly Mortality",
      accessor: "monthly_mortality",
    },
    {
      Header: "Monthly Deaths",
      accessor: "monthly_deaths",
    },
    {
      Header: "EOM Lives",
      accessor: "eom_lives",
    },
  ];

  return (
    <Table
      columns={columns}
      data={mortalityAssessmentInfo?.curve_data || []}
      defaultSort={true}
      paginated={false}
      emptyMessage="No Data found"
    />
  );
};

const mapStateToProps = state => {
  return {
    mortalityAssessmentInfo: activeMortalityAssessmentSelector(state),
  };
};

export default connect(mapStateToProps, {})(MortalityAssessmentCurveNumericTable);
