import {
  SELECT_CLINICAL_RESEARCH,
  FETCH_CLINICAL_RESEARCH_LIST,
  EDIT_CLINICAL_RESEARCH,
  CREATE_CLINICAL_RESEARCH,
  DELETE_CLINICAL_RESEARCH,
  FETCH_CLINICAL_RESEARCH,
  LOADING,
} from "actions/types";
import { arrayToObjectIndexedById } from "reducers/utils";
import { removeKey } from "../../../utils";

export const defaultState = {
  clinicalResearch: {},
  activeClinicalResearchId: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_CLINICAL_RESEARCH:
      return { ...state, activeClinicalResearchId: action.payload };

    case FETCH_CLINICAL_RESEARCH: {
      return {
        ...state,
        clinicalResearch: {
          ...state.clinicalResearch,
          [action.payload.id]: action.payload,
        },
      };
    }

    case FETCH_CLINICAL_RESEARCH_LIST:
      console.log(FETCH_CLINICAL_RESEARCH_LIST, action.payload);
      return { ...state, clinicalResearch: arrayToObjectIndexedById(action.payload) };

    case EDIT_CLINICAL_RESEARCH:
    case CREATE_CLINICAL_RESEARCH: {
      return {
        ...state,
        clinicalResearch: {
          ...state.clinicalResearch,
          [action.payload.id]: action.payload,
        },
        activeClinicalResearchId: action.payload.id,
      };
    }

    case DELETE_CLINICAL_RESEARCH: {
      return {
        ...state,
        clinicalResearch: removeKey(state.clinicalResearch, action.payload),
      };
    }
    case LOADING:
      if (action.payload.clinicalResearch) {
        return {
          ...state,
          clinicalResearch: arrayToObjectIndexedById([]),
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export const activeClinicalResearchSelector = ({ clinicalResearch, activeClinicalResearchId }) => {
  if (activeClinicalResearchId) {
    return clinicalResearch[activeClinicalResearchId];
  }
};

export const clinicalResearchSelector = state => {
  return Object.values(state.clinicalResearch);
};
