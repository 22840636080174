import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import FullPageModal from "components/modals/FullPageModal";
import { APS_ORDER_MODAL } from "components/modals";
import {
  generateAPSOrderFiles,
  fetchInsured,
  fetchCaseFilesByCaseDocumentId,
  resetAPSOrderFiles,
  sendAPSOrderFax,
  hideModal,
} from "actions";
import {
  activeCaseFileSelector,
  activeOrderFilesSelector,
  loadingSelector,
  insuredListSelector,
  doctorSelector,
  activeCaseSelector,
} from "reducers";
import File from "components/File";
import { Form, Table } from "react-bootstrap";
import { BooleanSelect, DateInput, FormCollapsible, SSNInput, TextInput } from "components/forms";
import { FaArrowLeft, FaFax } from "react-icons/fa";

const APSOrderModal = ({ ...props }) => {
  let [sendAvailable, setSendAvailable] = React.useState(false);
  let [sendingFax, setSendingFax] = React.useState(false);
  let [step, setStep] = React.useState(1);

  const SendButton = () => {
    let message;
    let errorMessage = "Fix errors before Sending";
    let errorFaxNumberMessage = "Please set Fax Number";
    if (sendingFax) {
      message = (
        <>
          Sending... <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
        </>
      );
    } else if (props.activeOrderFiles?.fax_cover) {
      let faxNumber = "";
      let doctorInfo = {
        ...props.doctorInfo,
        ...props.activeCaseFile.doctor,
      };
      if (doctorInfo.fax) {
        faxNumber = `1-${doctorInfo.fax}`;
      }
      if (sendAvailable) {
        if (faxNumber) {
          message = (
            <>
              <FaFax />
              Send Fax ({faxNumber})
            </>
          );
        } else {
          message = errorFaxNumberMessage;
        }
      } else {
        message = errorMessage;
      }
    } else {
      message = (
        <>
          Generating... <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
        </>
      );
    }

    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            setStep(1);
          }}
          style={{
            width: "30%",
            marginBottom: "1rem",
          }}
        >
          <FaArrowLeft />
          Back
        </button>
        <button
          className="btn btn--secondary"
          onClick={async () => {
            setSendingFax(true);
            await props.sendAPSOrderFax(props.activeCaseFile.id);
            props.hideModal(APS_ORDER_MODAL);
          }}
          style={{
            width: "30%",
            marginBottom: "1rem",
          }}
          disabled={message === errorMessage || message === errorFaxNumberMessage}
        >
          {message}
        </button>
      </div>
    );
  };

  const ReviewInfoForm = () => {
    let caseFileCategory = props.activeCaseFile.category;
    let insuredData;
    if (caseFileCategory === "Insured") {
      insuredData = props.insuredList[0];
    } else if (caseFileCategory === "Insured 2") {
      insuredData = props.insuredList[1];
    }

    let initialValues = {
      ...insuredData,
      ...props.doctorInfo,
      ...props.activeCaseFile.doctor,
    };
    let { first_name, last_name, date_of_birth, social_security, active_POA, phone, fax } = initialValues;
    let description = props.activeCaseFile.description;

    return (
      <Formik
        enableReinitialize
        initialValues={{
          first_name,
          last_name,
          date_of_birth,
          social_security,
          description,
          active_POA,
          phone,
          fax,
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          console.log("onSubmit", values);
        }}
      >
        {({ handleSubmit, values }) => {
          const NextStepButton = () => {
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="btn btn--secondary"
                  onClick={async () => {
                    props.resetAPSOrderFiles();
                    setStep(2);
                    await props.generateAPSOrderFiles(props.activeCaseFile.id, props.caseInfo.id, values);
                    props.fetchInsured(props.caseInfo.id);
                    props.fetchCaseFilesByCaseDocumentId(props.activeCaseFile.id);
                  }}
                  style={{
                    width: "99%",
                    marginBottom: "1rem",
                  }}
                >
                  Next Step: Review APS Order
                </button>
              </div>
            );
          };

          return (
            <>
              <FormCollapsible title="Patient Information Review">
                <Form.Row>
                  <TextInput label="First Name" name="first_name" isRequired={true} />
                  <TextInput label="Last Name" name="last_name" isRequired={true} />
                </Form.Row>
                <Form.Row>
                  <DateInput label="Date of Birth" name="date_of_birth" isRequired={true} />
                  <SSNInput isRequired={true} />
                </Form.Row>
                <Form.Row>
                  <BooleanSelect
                    label="POA In Effect?"
                    name="active_POA"
                    placeholder="Select POA in effect"
                    md={6}
                    isRequired={true}
                  />
                </Form.Row>
              </FormCollapsible>
              <FormCollapsible title="Doctor Information Review">
                <Form.Row>
                  <TextInput label="Name" name="description" disabled />
                  <TextInput label="Phone Number" name="phone" isRequired={true} />
                  <TextInput label="Fax Number" name="fax" isRequired={true} />
                </Form.Row>
              </FormCollapsible>
              <NextStepButton />
            </>
          );
        }}
      </Formik>
    );
  };

  const FilesTable = () => {
    let faxCoverFile = "";
    let hipaaFile = "";
    let ipoaFile = "";
    let apsOrderFile = "";
    let sendEnabled = true;
    if (props.activeOrderFiles?.fax_cover) {
      faxCoverFile = <File style={{ marginBottom: 0 }} file={props.activeOrderFiles.fax_cover} />;

      hipaaFile = "";
      if (props.activeOrderFiles?.hipaa && props.activeOrderFiles?.hipaa.url) {
        hipaaFile = <File style={{ marginBottom: 0 }} file={props.activeOrderFiles.hipaa} />;
      } else {
        hipaaFile = <div dangerouslySetInnerHTML={{ __html: props.activeOrderFiles.hipaa.message }} />;
        sendEnabled = false;
      }

      ipoaFile = "";
      if (props.activeOrderFiles?.ipoa) {
        if (props.activeOrderFiles?.ipoa.url) {
          ipoaFile = <File style={{ marginBottom: 0 }} file={props.activeOrderFiles.ipoa} />;
        } else {
          ipoaFile = <div dangerouslySetInnerHTML={{ __html: props.activeOrderFiles.ipoa.message }} />;
          sendEnabled = sendEnabled && props.activeOrderFiles.ipoa.message.indexOf("Not Applicable") !== -1;
        }
      }

      if (props.activeOrderFiles?.aps_order) {
        apsOrderFile = <File style={{ marginBottom: 0 }} file={props.activeOrderFiles.aps_order} />;
      }

      setSendAvailable(sendEnabled);
    }
    return (
      <>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Type</th>
              <th>File</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Fax Cover</td>
              <td>{faxCoverFile}</td>
            </tr>
            <tr>
              <td>HIPAA</td>
              <td>{hipaaFile}</td>
            </tr>
            <tr>
              <td>IPOA</td>
              <td>{ipoaFile}</td>
            </tr>
          </tbody>
        </Table>
        {apsOrderFile ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{apsOrderFile}</div>
        ) : null}
        <br />
        <SendButton />
      </>
    );
  };

  return (
    <FullPageModal modalType={APS_ORDER_MODAL} title={"APS Order"}>
      {step === 1 && <ReviewInfoForm />}
      {step === 2 && <FilesTable />}
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
    activeCaseFile: activeCaseFileSelector(state),
    activeOrderFiles: activeOrderFilesSelector(state),
    insuredList: insuredListSelector(state),
    doctorInfo: doctorSelector(state),
    loading: loadingSelector(state),
  };
};

export default connect(mapStateToProps, {
  generateAPSOrderFiles,
  resetAPSOrderFiles,
  sendAPSOrderFax,
  fetchInsured,
  fetchCaseFilesByCaseDocumentId,
  hideModal,
})(APSOrderModal);
