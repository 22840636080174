import React from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import * as moment from "moment";

import { getValidationClassName } from "./validation";
import { DATE_FORMAT } from "constants.js";
import Label from "./Label";
import FieldErrors from "./FieldErrors";
import { FaCalendarAlt } from "react-icons/fa";
import { dateDisplay, getViewMode } from "../../utils";

export const toServerFormat = date => {
  if (date) return moment(date).format(DATE_FORMAT.SERVER.MOMENT);
  return null;
};

const YearInput = ({ label, md = "", name, isRequired, ...props }) => {
  const [field, meta] = useField({ name });
  const { setFieldValue } = useFormikContext();
  const isViewMode = getViewMode();
  const isDisabled = props.disabled || isViewMode;

  const validateClassName = getValidationClassName(meta);
  let selectedDate = null;
  if (field.value) {
    selectedDate = moment(field.value, DATE_FORMAT.SERVER.MOMENT).toDate();
    if (isNaN(selectedDate.getTime())) {
      // Try another format
      selectedDate = moment(field.value, "MM-DD-YYYY").toDate();
    }
  }

  const renderYearContent = year => {
    const tooltipText = `Year ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  return (
    <Form.Group as={Col} style={props.style} sm="12" className={`col-md${md && "-" + md}`}>
      <InputGroup style={{ flexWrap: "wrap" }}>
        <InputGroup.Prepend>
          <InputGroup.Text id="dollarSign">{label}</InputGroup.Text>
          <InputGroup.Text id="dollarSign">
            <FaCalendarAlt />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <div style={{ flexGrow: "1" }} className={validateClassName}>
          <input type="hidden" {...field} value={toServerFormat(field.value) || ""} />
          <DatePicker
            {...props}
            autoComplete="off"
            className={`form-control ${validateClassName}`}
            renderYearContent={renderYearContent}
            showYearPicker
            dropdownMode="select"
            preventOpenOnFocus={true}
            selected={selectedDate}
            onChange={val => {
              setFieldValue(field.name, toServerFormat(val));
            }}
            dateFormat={"yyyy"}
            disabled={isDisabled}
          />
        </div>
        <FieldErrors meta={meta} />
      </InputGroup>
    </Form.Group>
  );
};

export default YearInput;
