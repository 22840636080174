import React from "react";
import { connect } from "react-redux";

import FunderCasesTable from "./FunderCasesTable";
import FunderCasesFilterForm from "./FunderCasesFilterForm";

const FunderCasesPage = ({ ...props }) => {
  return (
    <div style={{ marginTop: 100 }}>
      <FunderCasesFilterForm />
      <FunderCasesTable />
    </div>
  );
};

export default connect(null, {})(FunderCasesPage);
