import React from "react";
import { connect } from "react-redux";
import { FaEdit, FaFileInvoiceDollar } from "react-icons/fa";

import { moneyDisplayShort } from "../../utils";
import { showModal } from "actions";
import IconLink from "components/IconLink";
import Table, { dateCell } from "components/table";
import { activeFunderCaseFitsSelector, activeFunderSelector, loadingFunderCaseFitsSelector } from "../../reducers";
import { FUNDER_STATUS_MAPPING } from "../../constants";

const FunderCasesFitTable = ({ funder, activeFunderCaseFits, ...props }) => {
  const face_amount_min = moneyDisplayShort(funder?.minimum_face_amount);
  const face_amount_max = moneyDisplayShort(funder?.maximum_face_amount);
  const insured_age_min = funder?.minimum_age;
  const insured_age_max = funder?.maximum_age;
  const life_expectancy_min = funder?.minimum_le;
  const life_expectancy_max = funder?.max_le;
  const accepted_le_vendors_list = funder?.le_vendors_list_display?.replaceAll(";", " / ");
  const number_of_market_les = funder?.market_les_required;
  const sul_value = funder?.survivorship;
  const vul_value = funder?.variable_policies;
  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const caseId = row.original.id;
        const driveFolderUrl = row.original.drive_pricing_folder_url;
        return (
          <>
            <IconLink
              to={`/cases/${caseId}/trading/`}
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="Manage Life Settlement Case"
              target="_blank"
              rel="noopener noreferrer"
            />
            {driveFolderUrl ? (
              <IconLink
                to={driveFolderUrl}
                Icon={FaFileInvoiceDollar}
                iconConfig={{ className: "table--action-icon" }}
                tooltip="View Pricing Folder"
                target="_blank"
                rel="noopener noreferrer"
              />
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Application ID",
      accessor: "submission_id",
    },
    {
      Header: "Case Status",
      accessor: "status_name",
      className: "wrap-text",
    },
    {
      Header: "Insured Names",
      accessor: "insured_names",
      className: "wrap-text",
    },
    {
      Header: "Carrier",
      accessor: "carrier_name",
      className: "wrap-text",
    },
    {
      Header: "Date Sent",
      accessor: "date_sent",
      className: "padded-table-cells",
      Cell: dateCell,
    },
    {
      Header: "Funder Status",
      accessor: "funder_status",
      Cell: ({ row }) => {
        if (row.original.funder_status) {
          return FUNDER_STATUS_MAPPING[row.original.funder_status];
        } else {
          return "";
        }
      },
    },
    {
      Header: "Last Activity Date",
      accessor: "last_activity_date",
      Cell: dateCell,
    },
    {
      Header: "Case Fit",
      accessor: "case_fit_value",
      Cell: ({ row }) => {
        if (row.original.case_fit_response.case_fit) {
          return <div style={{ color: "green" }}>Yes</div>;
        } else {
          return <div style={{ color: "red" }}>No</div>;
        }
      },
    },
    {
      Header: `Face Amount ${face_amount_min || 0} - ${face_amount_max || 90000000}`,
      accessor: "face_amount_as_number",
      Cell: ({ row }) => {
        const color = row.original.case_fit_response?.face_amount;
        if (row.original.face_amount) {
          const money_display = moneyDisplayShort(row.original.face_amount);
          return <div style={{ color: color }}>{money_display}</div>;
        } else {
          return "";
        }
      },
    },
    {
      Header: `Insured Age ${insured_age_min || 0} - ${insured_age_max || 200}`,
      accessor: "insured_age",
      Cell: ({ row }) => {
        const color = row.original.case_fit_response?.youngest_insured_age;
        if (row.original.insured_age) {
          return <div style={{ color: color }}>{row.original.insured_age}</div>;
        } else {
          return "";
        }
      },
    },
    {
      Header: `Life Expectancy ${life_expectancy_min || 0} - ${life_expectancy_max || 200}`,
      accessor: "blended_le",
      Cell: ({ row }) => {
        const color = row.original.case_fit_response?.blended_le;
        if (row.original.blended_le) {
          return <div style={{ color: color }}>{row.original.blended_le?.toFixed(2)}</div>;
        } else {
          return "";
        }
      },
    },
    {
      Header: `LE Vendors ${accepted_le_vendors_list}`,
      accessor: "le_vendors_list",
      className: "wrap-text",
    },
    {
      Header: `# of Market LEs ${number_of_market_les}`,
      accessor: "number_of_market_les",
      Cell: ({ row }) => {
        const color = row.original.case_fit_response?.market_les_required;
        if (row.original.number_of_market_les) {
          return <div style={{ color: color }}>{row.original.number_of_market_les}</div>;
        } else {
          return "";
        }
      },
    },
    {
      Header: `SUL ${sul_value}`,
      accessor: "sul",
      Cell: ({ row }) => {
        if (row.original.sul) {
          return <div style={{ color: "green" }}>Yes</div>;
        } else {
          return <div style={{ color: "red" }}>No</div>;
        }
      },
    },
    {
      Header: `VUL ${vul_value}`,
      accessor: "vul",
      Cell: ({ row }) => {
        if (row.original.sul) {
          return <div style={{ color: "green" }}>Yes</div>;
        } else {
          return <div style={{ color: "red" }}>No</div>;
        }
      },
    },
  ];

  return (
    <Table
      columns={columns}
      data={activeFunderCaseFits}
      emptyMessage={props.funderCasesLoading ? "Loading Funder Cases data..." : "No data"}
      defaultSort={true}
      defaultPagination={true}
      showPaginationResults={true}
      initialPageSize={100}
      tableClass={"funderCasesFixed"}
    />
  );
};

const mapStateToProps = state => {
  return {
    funder: activeFunderSelector(state),
    activeFunderCaseFits: activeFunderCaseFitsSelector(state),
    funderCaseFitsLoading: loadingFunderCaseFitsSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
})(FunderCasesFitTable);
