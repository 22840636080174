import { Formik } from "formik";
import { SubmitButton } from "../../components/forms";
import { Form } from "react-bootstrap";
import { fundersActivityReportTotalsSelector } from "reducers";
import { connect } from "react-redux";
import React from "react";
import { DisplayCurrencyValueWithTitle, DisplayValueWithTitle } from "../../components/forms/DisplayValueWithTitle";

const FunderActivityReportSummary = ({ totals, ...props }) => {
  const formRowStyle = {
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
  };

  return (
    <Formik enableReinitialize initialValues={{}} onSubmit={{}}>
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <>
            <Form onSubmit={handleSubmit}>
              <Form.Row style={formRowStyle}>
                <DisplayValueWithTitle title={"Pending Count"} value={totals.pending_count} md={6} />
                <DisplayCurrencyValueWithTitle title={"Pending Face"} value={totals.pending_face} md={6} />
              </Form.Row>
              <Form.Row style={formRowStyle}>
                <DisplayValueWithTitle title={"Bidding Count"} value={totals.bidding_count} md={6} />
                <DisplayCurrencyValueWithTitle title={"Bidding Face"} value={totals.bidding_face} md={6} />
              </Form.Row>
              <Form.Row style={formRowStyle}>
                <DisplayValueWithTitle title={"In Closing Count"} value={totals.closing_count} md={6} />
                <DisplayCurrencyValueWithTitle title={"In Closing Face"} value={totals.closing_face} md={6} />
              </Form.Row>
              <Form.Row style={formRowStyle}>
                <DisplayValueWithTitle title={"Complete Count"} value={totals.completed_count} md={6} />
                <DisplayCurrencyValueWithTitle title={"Complete Face"} value={totals.completed_face} md={6} />
              </Form.Row>
              <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const totals = fundersActivityReportTotalsSelector(state);

  return {
    totals,
  };
};

export default connect(mapStateToProps, {})(FunderActivityReportSummary);
