import { SELECT_CASE_TASK } from "actions/types";

export const defaultState = {
  activeCaseTask: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_CASE_TASK:
      return {
        ...state,
        activeCaseTask: action.payload,
      };

    default:
      return state;
  }
};

export const activeCaseTaskSelector = state => {
  return state.activeCaseTask;
};
