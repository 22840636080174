import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as Yup from "yup";

import { editInsuredInfo } from "actions";
import { activeInsuredSelector, insuredSelector } from "reducers";
import * as notifications from "../../../notifications";
import {
  TextInput,
  GenderSelect,
  BooleanSelect,
  Select,
  SSNInput,
  DateInput,
  FormCollapsible,
  SubmitButton,
} from "components/forms";
import { isAdmin } from "permissions";
import {
  INSURED_STATUS_DECEASED as DECEASED,
  MARITAL_STATUS_MARRIED as MARRIED,
  PERMISSIONS,
  MARITAL_STATUS_SEPARATED as SEPARATED,
  insuredStatusChoices,
  maritalStatusChoices,
} from "../../../constants";

const InsuredDemographicInfoForm = ({ insuredInfo, hasAdminPermission, ...props }) => {
  if (!insuredInfo) {
    return null;
  }

  const {
    first_name,
    middle_initial,
    last_name,
    suffix,
    gender,
    date_of_birth,
    social_security,
    insured_status,
    date_of_death,
    marital_status,
    spouse_name,
    active_POA,
    POA_name,
    bankruptcy,
  } = insuredInfo;
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          first_name,
          middle_initial,
          last_name,
          suffix,
          gender,
          date_of_birth,
          social_security,
          insured_status,
          date_of_death,
          marital_status,
          spouse_name,
          active_POA,
          POA_name,
          bankruptcy,
        }}
        validationSchema={Yup.object({
          date_of_death: Yup.date()
            .nullable()
            .when("insured_status", {
              is: `${DECEASED}`,
              then: schema => Yup.date().nullable(),
            }),
          spouse_name: Yup.string().when("marital_status", {
            is: marital_status => marital_status !== MARRIED && marital_status !== SEPARATED,
            then: schema => Yup.string().max(0, "Spouse name is only valid for Married and Separated Marital Status"),
          }),
          POA_name: Yup.string().when("active_POA", {
            is: "false",
            then: schema => Yup.string().max(0, "POA name is only valid when 'POA in Effect'"),
          }),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await props.editInsuredInfo(insuredInfo.id, values);
            notifications.success("Insured Information saved successfully");
          } catch (error) {
            setErrors(error.response.data);
            notifications.error("Could not save changes, please verify the form errors.");
          }
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, values }) => {
          return (
            <FormCollapsible
              onSubmit={handleSubmit}
              title="Demographic Information"
              id="demographic-form"
              disabled={props.disabled}
            >
              <Form.Row>
                <TextInput label="First Name" name="first_name" md={4} isRequired disabled={!hasAdminPermission} />
                <TextInput label="Middle Initial" name="middle_initial" md={2} disabled={!hasAdminPermission} />
                <TextInput label="Last Name" name="last_name" md={4} isRequired disabled={!hasAdminPermission} />
                <TextInput label="Suffix" name="suffix" md={2} disabled={!hasAdminPermission} />
              </Form.Row>
              <Form.Row>
                <GenderSelect isRequired disabled={!hasAdminPermission} />
                <DateInput label="Date of Birth" name="date_of_birth" isRequired disabled={!hasAdminPermission} />
                <SSNInput isRequired disabled={!hasAdminPermission} />
              </Form.Row>
              <Form.Row>
                <Select
                  label="Insured Status"
                  name="insured_status"
                  placeholder="Select Insured Status"
                  options={insuredStatusChoices}
                  md="4"
                  isRequired
                />
                <DateInput
                  label="Date of Death"
                  name="date_of_death"
                  md="4"
                  disabled={values.insured_status !== DECEASED || !hasAdminPermission}
                  isRequired={values.insured_status === DECEASED}
                />
              </Form.Row>
              <Form.Row>
                <Select
                  label="Marital Status"
                  name="marital_status"
                  placeholder="Select Marital Status"
                  options={maritalStatusChoices}
                  md="4"
                  isRequired
                />
                <TextInput
                  label="Spouse Name"
                  name="spouse_name"
                  isRequired={values.marital_status === MARRIED || values.marital_status === SEPARATED}
                  disabled={
                    (values.marital_status !== MARRIED && values.marital_status !== SEPARATED) || !hasAdminPermission
                  }
                />
              </Form.Row>
              <Form.Row>
                <BooleanSelect
                  label="POA In Effect?"
                  name="active_POA"
                  placeholder="Select POA in effect"
                  md={4}
                  isRequired
                />
                <TextInput
                  label="POA Name"
                  name="POA_name"
                  isRequired={values.active_POA === true}
                  disabled={values.active_POA !== true || !hasAdminPermission}
                />
                <BooleanSelect label="Bankruptcy?" name="bankruptcy" placeholder="Select choice" md={4} isRequired />
              </Form.Row>
              <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
            </FormCollapsible>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    insuredInfo: ownProps.insuredId ? insuredSelector(state, ownProps.insuredId) : activeInsuredSelector(state),
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  editInsuredInfo,
})(InsuredDemographicInfoForm);
