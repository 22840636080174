import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as Yup from "yup";

import { URLS } from "constants.js";
import { loginFirstStep, loginSecondStep, loginCancel, getRedirectUrl } from "actions";
import { loginInfoSelector, loadingLoginSelector } from "reducers";
import { TextInput } from "components/forms";
import { Link } from "react-router-dom";
import NumericInput from "./forms/NumericInput";
import logo from "logo.png";
import sslLogo from "logo-ssl-encrypted.png";

const LoginForm = props => {
  const displayNonFieldFormErrors = () => {
    const error = props.loginError || props.error;
    if (error && error !== "Error decoding signature." && error !== "Signature has expired.") {
      return <div className="error">{error}</div>;
    }
  };

  const renderTwoFactorActivationForm = () => {
    const divStyle = {
      display: "flex",
      justifyContent: "center",
    };

    const textStyle = {
      fontWeight: "bold",
      fontSize: "20px", // You can adjust the font size as needed
    };

    return (
      <>
        <div style={divStyle}>
          <div style={textStyle}>Need To Enable Two Factor Authentication</div>
        </div>
        <div className="reset-password-description">
          To start using a token generator, please use your smartphone to scan the QR code below.
          <br />
          For example, use <a href={"https://m.google.com/authenticator"}>Google Authenticator</a> or{" "}
          <a href={"https://www.authy.com/"}>Twilio Authy</a>.
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img src={props.qrImageUrl} alt="QR Code" style={{ height: 200, width: 200 }} />
        </div>
        <div className="reset-password-description">
          Then, enter the token generated by the app. <br />
          If it's invalid, you might have to reopen the app or wait until the code refreshes.
        </div>
      </>
    );
  };

  const renderTwoFactorActive = () => {
    return (
      <>
        <div className="reset-password-description">Please enter the token generated by your token generator.</div>
      </>
    );
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <img src={logo} alt="LifeRoc Analytics Logo" className="logo-top" />
        <h4 className="form-title shadowed">Welcome Back</h4>
        {props.loginFirstStepPassed ? (
          <>
            <Formik
              initialValues={{ auth_code: "" }}
              validationSchema={Yup.object({
                auth_code: Yup.string().required("Required"),
              })}
              onSubmit={async (formValues, { setSubmitting, setErrors }) => {
                try {
                  await props.loginSecondStep(formValues);
                } catch (error) {
                  setErrors(error.response.data);
                }
                setSubmitting(false);
              }}
            >
              {({ handleSubmit, values }) => (
                <Form onSubmit={handleSubmit} className="login-form shadowed">
                  {props.twoFactorActive ? renderTwoFactorActive() : renderTwoFactorActivationForm()}
                  <div className="input">
                    <NumericInput
                      label="Token"
                      value={values.auth_code || ""}
                      name="auth_code"
                      autoComplete="new-password"
                      autoFocus={true}
                    />
                  </div>
                  <button type="submit" disabled={props.loadingLogin} className="btn login-btn">
                    Login
                  </button>
                  <button type="button" onClick={props.loginCancel} className="reset-password">
                    Cancel
                  </button>
                </Form>
              )}
            </Formik>
            {displayNonFieldFormErrors()}
          </>
        ) : (
          <>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={Yup.object({
                email: Yup.string().email("Invalid email address").required("Required"),
                password: Yup.string().required("Required"),
              })}
              onSubmit={async (formValues, { setSubmitting, setErrors }) => {
                try {
                  await props.loginFirstStep(formValues);
                } catch (error) {
                  setErrors(error.response.data);
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              {formik => (
                <Form onSubmit={formik.handleSubmit} className="login-form shadowed">
                  <div className="input">
                    <TextInput label="User Name" name="email" type="email" autoComplete="on" autoFocus />
                  </div>
                  <div className="input">
                    <TextInput label="Password" name="password" type="password" />
                  </div>
                  <button type="submit" disabled={props.loadingLogin} className="btn login-btn">
                    Login
                  </button>
                  <Link to={URLS.RESET_PASSWORD} style={{ display: "flex", justifyContent: "center" }}>
                    <button type="button" className="reset-password">
                      Reset Password
                    </button>
                  </Link>
                </Form>
              )}
            </Formik>
            {displayNonFieldFormErrors()}
          </>
        )}
        <img src={sslLogo} alt="SSL Encrypted" className="ssl-logo-right" />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  let qrCodeURL = state.auth.qrCodeURL;
  const currentLocation = window.location;
  const loginInfo = loginInfoSelector(state);

  // Create an absolute URL using the current location
  const absoluteURL = new URL(qrCodeURL, currentLocation);

  // Get the absolute path
  let absolutePath = absoluteURL.href;
  if (absolutePath.includes("localhost:3000")) {
    absolutePath = absolutePath.replace("localhost:3000", "localhost:8000");
  }

  return {
    loadingLogin: loadingLoginSelector(state),
    qrImageUrl: absolutePath,
    ...loginInfo,
  };
};

export default connect(mapStateToProps, {
  loginFirstStep,
  loginSecondStep,
  loginCancel,
})(LoginForm);
