import React from "react";
import * as moment from "moment";
import { isEmpty } from "lodash";

import { DATE_FORMAT } from "constants.js";

export const all = (collection, func) => {
  return collection.reduce((acc, value) => {
    return acc && func(value);
  }, true);
};

export const any = (collection, func) => {
  return collection.reduce((acc, value) => {
    return acc || func(value);
  }, false);
};

export const partial = (fn, ...args) => {
  return fn.bind(null, ...args);
};

/**
 * Given a list of items, returns where elements are grouped
 * into sub-lists of size "size"
 *
 * @param  {Array} items: Items to partition
 * @param  {Number} size: Size of the partitions
 */
export const partition = (items, size) => {
  let output = [];
  for (let i = 0; i < items.length; i += size) {
    output[output.length] = items.slice(i, i + size);
  }
  return output;
};

export const calcAge = birthday => {
  if (birthday) {
    const now = moment();
    const born = moment(birthday);
    return now.diff(born, "years");
  }
};

export const getAge = dateString => {
  let today = new Date();
  let birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const calculateExactAge = birthDateString => {
  const today = new Date();
  const birthDate = new Date(birthDateString);

  let age = today.getFullYear() - birthDate.getFullYear();
  let monthDifference = today.getMonth() - birthDate.getMonth();
  let dayDifference = today.getDate() - birthDate.getDate();

  // Adjust age if the birth date hasn't occurred yet this year
  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
    monthDifference += 12; // Add 12 months to the month difference if the birthday hasn't occurred this year
  }

  // Calculate the decimal part of the age
  const monthsFraction = monthDifference / 12 + dayDifference / 30 / 12;

  // Return the age
  return age + monthsFraction;
};

export const fullNameDisplay = (...names) => {
  return names.filter(x => x !== "").join(", ");
};

export const percentageDisplay = (value, precision = 3) => {
  if (value) {
    const times100 = Number(value) * 100;
    return `${times100.toFixed(precision)} %`;
  }
};

export const removeKey = (obj, key) => {
  let { [key]: _, ...newObj } = obj;
  return newObj;
};

export const dateDisplay = (value, inFormat = DATE_FORMAT.SERVER.MOMENT, outFormat = DATE_FORMAT.DISPLAY.MOMENT) => {
  if (!isEmpty(value)) {
    return moment(value, inFormat).format(outFormat);
  }
};

/* Remove timezone info from a datetime formated as iso-8601 */
export const makeDateNaive = dateStr => dateStr.slice(0, 19);

export const datetimeDisplay = value => {
  if (!isEmpty(value)) {
    const m = moment(makeDateNaive(value));
    const date = m.format(DATE_FORMAT.DISPLAY.MOMENT);
    const time = m.format("LT");
    return `${date} @ ${time}`;
  }
};

export const moneyDisplay = value => {
  if (value || value === 0) {
    value = floatWithoutCommas(value);
    let formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formatter.format(value);
  }
};

export const currencyCellMoneyDisplay = value => {
  value = floatWithoutCommas(value);
  let number = Number(Number(value).toFixed(2));
  return `$ ${number.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })}`;
};

export const timeDisplay = duration => {
  if (!duration) return "";
  const minutes = Math.floor(duration / 60);
  const remainingSeconds = duration % 60;
  return minutes + ":" + (remainingSeconds < 10 ? "0" : "") + remainingSeconds;
};

export const revertMoneyDisplay = formattedValue => {
  // Remove currency symbols and commas
  return parseFloat(formattedValue.replace(/[^\d.-]/g, ""));
};

const lookup = [
  { value: 1, symbol: "" },
  { value: 1e3, symbol: "k" },
  { value: 1e6, symbol: "M" },
  { value: 1e9, symbol: "G" },
  { value: 1e12, symbol: "T" },
  { value: 1e15, symbol: "P" },
  { value: 1e18, symbol: "E" },
];
export const moneyDisplayShort = value => {
  if (typeof value !== "string") return "";
  value = value.replaceAll(",", "");
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let item = lookup
    .slice()
    .reverse()
    .find(item => value >= item.value);

  if (item) {
    let formattedValue = (value / item.value).toFixed(2).replace(rx, "$1");
    return "$" + formattedValue + item.symbol;
  }
  return "0";
};

export const floatWithoutCommas = value => {
  if (value !== undefined && value !== null) {
    return parseFloat(value.toString().replaceAll(",", ""));
  }
};

/**
 * Calculate Body Mass Index
 * @param  {Number} height: Height in inches
 * @param  {Number} weight: Weight in pounds
 */
export const calculateBMI = (height, weight, decimalDigits = 1) => {
  const heightInMeters = height * 0.0254;
  const weightInKg = weight * 0.45359237;
  return (weightInKg / heightInMeters ** 2).toFixed(decimalDigits);
};

export const scrollInto = elementId => {
  // Use setTimeout with 0 to wait for the call stack to be empty before executing this.
  // Sometimes the call stack might be doing stuff like rendering the element we want to scroll to,
  // which will error because the element does not yet exist.
  setTimeout(() => document.getElementById(elementId).scrollIntoView({ behavior: "smooth" }), 0);
};

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const removeIdx = (array, indexToRemove) => {
  return array.filter((_, i) => i !== indexToRemove);
};

export const booleanDisplay = value => {
  if (value === true) {
    return "Yes";
  } else if (value === false) {
    return "No";
  } else {
    return "----";
  }
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

// TODO: REWORK THIS
export const sortByObj = (inc_sort_by, sort_by, param) => {
  if (inc_sort_by === sort_by.id) {
    if (sort_by.desc === true) {
      return {
        id: inc_sort_by,
        desc: false,
      };
    } else {
      if (param === "lifeexpectancy") {
        return {
          id: "certificate_date",
          desc: true,
        };
      } else if (param === "cases") {
        return {
          id: "submission_date",
          desc: true,
        };
      } else if (param === "accounts") {
        return {
          id: "entity_name",
          desc: true,
        };
      } else if (param === "funderslist") {
        return {
          id: "name",
          desc: false,
        };
      } else if (param === "parameters") {
        return {
          id: "code",
          desc: true,
        };
      } else {
        return {
          id: "",
          desc: false,
        };
      }
    }
  } else {
    return {
      id: inc_sort_by,
      desc: true,
    };
  }
};

export const sortByParam = sort_by => {
  if (sort_by.id.length === 0) {
    return "";
  }
  if (sort_by.desc === true) {
    return "-" + sort_by.id;
  } else {
    return sort_by.id;
  }
};

export const sortByValue = choices => {
  return choices.sort((a, b) => a.value.localeCompare(b.value));
};

export const parseFloatFromString = value => {
  try {
    return parseFloat(value.replaceAll(",", "").replaceAll("$", ""));
  } catch (error) {
    return value;
  }
};

export const parenthesisWrap = value => {
  if (value) {
    return "(" + value + ")";
  }
  return value;
};

export const parse_date_for_last_activity = dateString => {
  const dateObject = new Date(dateString);
  if (dateObject > new Date(0)) {
    const month = dateObject.getMonth() + 1; // Adding 1 because month indexes start from 0
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();

    // Format the date string with leading zeros if necessary
    return (month < 10 ? "0" : "") + month + "/" + (day < 10 ? "0" : "") + day + "/" + year;
  } else {
    return "";
  }
};

export const olderThanSevenDays = dateString => {
  const differenceInMilliseconds = Math.abs(new Date() - new Date(dateString));
  // Calculate the number of milliseconds in 7 days
  const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
  // Compare the difference with 7 days
  return differenceInMilliseconds > sevenDaysInMilliseconds;
};

export const toTitleCase = str => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getLabel = (list, value) => {
  const choice = list.find(o => o.value === value);
  return choice && choice.label;
};

export const getValue = (list, label) => {
  const choice = list.find(o => o.label === label);
  return choice && choice.value;
};

export const findObjectByPropertyValue = (list, property, value) => {
  return list.find(item => item[property] === value);
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const commaNameToFirstLastName = name => {
  // Takes a string like "Last, First" and returns it as "First Last"
  const parts = name.split(","); // Split the name by comma
  if (parts.length === 2) {
    const lastName = parts[0].trim();
    const firstName = parts[1].trim();
    return `${firstName} ${lastName}`;
  }
  // Return the original name if it doesn't match the expected format
  return name;
};

export const titleCase = str => {
  return str.toLowerCase().replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
};

export const redirectTo = url => {
  console.log("redirectTo", url);
  window.location.href = url;
};

export const makeChoices = list => {
  let choices = [];
  for (const listElement of list) {
    choices.push({ label: listElement, value: listElement });
  }
  return choices;
};

export function prepareMultipleSelect(fieldName, choices, filters, separator = ",") {
  let result = [];
  if (filters[fieldName]) {
    let values = filters[fieldName].split(separator);
    for (let val of values) {
      let data = choices.find(e => e.value.toString() === val.toString());
      if (data) {
        result.push({ value: data.value, label: data.label });
      }
    }
  }

  // If the initialValue has no comma, it locks for changes.
  // Blank it so the defaultValue takes preference
  if (",".indexOf(filters[fieldName]) === -1) {
    filters[fieldName] = "";
  }

  return result;
}

export const getViewUrl = file => {
  // Handle view icon in local environment
  let viewUrl = file.url;
  let isLocalEnvironment = String(window.location.href).indexOf("localhost") !== -1;
  if (isLocalEnvironment && viewUrl.indexOf("amazonaws.com") === -1 && viewUrl.indexOf("localhost") === -1) {
    viewUrl = "http://localhost:8000" + viewUrl;
  }
  return viewUrl;
};

export const getViewMode = () => {
  return window.location.href.includes("?view");
};

export const includesAny = (value, array) => {
  return array.some(str => value.includes(str));
};
