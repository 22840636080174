import React from "react";
import { connect } from "react-redux";

import { clearSelectedParameter, setActiveTab, initPagination, showModal } from "actions";
import RiskxParametersFilterForm from "./ParametersFilterForm";
import RiskxParametersTable from "./ParametersTable";
import { ButtonCreate } from "../../../components/buttons";
import { PARAMETER_MODAL } from "../../../components/modals";
import RiskxPageHeader from "../RiskxPageHeader";

const ParametersPage = props => {
  const [activeSection, setActiveSection] = React.useState("parameters");
  React.useEffect(() => {
    props.setActiveTab(activeSection);
    props.initPagination();
    setActiveSection(activeSection);
    document.title = "LifeRoc | RiskX Health Parameters";
  }, []);

  return (
    <>
      <RiskxPageHeader />
      <div className={`section-card shadowed case-section`} style={{ marginTop: "1.5rem" }}>
        <RiskxParametersFilterForm />
        <ButtonCreate
          id="create-new-case"
          onClick={() => {
            props.clearSelectedParameter();
            props.showModal(PARAMETER_MODAL);
          }}
          key="create"
        >
          Add Health Parameter
        </ButtonCreate>
        <RiskxParametersTable />
      </div>
    </>
  );
};

export default connect(null, {
  clearSelectedParameter,
  setActiveTab,
  initPagination,
  showModal,
})(ParametersPage);
