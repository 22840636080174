import {
  SELECT_SIGNER_DILIGENCE,
  FETCH_SIGNER_DILIGENCES,
  EDIT_SIGNER_DILIGENCE,
  CREATE_SIGNER_DILIGENCE,
  DELETE_SIGNER_DILIGENCE,
} from "actions/types";
import { removeKey } from "utils.js";
import { arrayToObjectIndexedById } from "reducers/utils";
import { choicesWrapper } from "../../../options";

export const defaultState = {
  activeSignerDiligenceId: null,
  signerDiligences: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_SIGNER_DILIGENCE:
      return { ...state, activeSignerDiligenceId: action.payload };

    case FETCH_SIGNER_DILIGENCES:
      return { ...state, signerDiligences: arrayToObjectIndexedById(action.payload) };

    case EDIT_SIGNER_DILIGENCE:
    case CREATE_SIGNER_DILIGENCE:
      return {
        ...state,
        signerDiligences: {
          ...state.signerDiligences,
          [action.payload.id]: action.payload,
        },
      };

    case DELETE_SIGNER_DILIGENCE: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        signerDiligences: removeKey(state.signerDiligences, toDeleteId),
      };
      if (state.activeSignerDiligenceId === toDeleteId) {
        newState.activeSignerDiligenceId = null;
      }
      return newState;
    }

    default:
      return state;
  }
};

export const signerDiligencesSelector = state => Object.values(state.signerDiligences);

export const activeSignerDiligenceSelector = state => {
  const { activeSignerDiligenceId, signerDiligences } = state;

  if (activeSignerDiligenceId === null) {
    return null;
  }
  return signerDiligences[activeSignerDiligenceId];
};

export const signerDiligencesForActiveOwnerSelector = (state, activeOwnerId) => {
  const signerDiligences = Object.values(state.signerDiligences);
  return signerDiligences.filter(le => le.entity_id === activeOwnerId);
};

export const signerChoicesSelector = (state, activeOwnerId) => {
  const ownerSigners = Object.values(state.signers);
  let choices = [];
  if (!activeOwnerId) {
    for (let ownerSigner of ownerSigners) {
      choices.push({ value: ownerSigner.id, label: ownerSigner.full_legal_name });
    }
  } else {
    for (let ownerSigner of ownerSigners) {
      if (ownerSigner.entity_id === activeOwnerId) {
        choices.push({ value: ownerSigner.id, label: ownerSigner.full_legal_name });
      }
    }
  }
  return choicesWrapper(choices);
};
