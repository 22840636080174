import React from "react";
import { connect } from "react-redux";
import ActivityTable from "./ActivityTable";

const ActivityPage = () => {
  return (
    <>
      <ActivityTable />
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(ActivityPage);
