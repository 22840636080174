import React from "react";
import { connect } from "react-redux";

import { showModal, selectAccountHierarchy } from "actions";
import { activeAccountSelector, managementChoicesSelector } from "reducers";
import { ACCOUNT_HIERARCHY_MODAL, DELETE_ACCOUNT_HIERARCHY_MODAL } from "components/modals";
import Table, { dateCell } from "components/table";

import { IconLinkViewOrEdit, IconLinkDelete } from "components/icons";

const HierarchyTable = ({ activeAccount, ...props }) => {
  if (!activeAccount) {
    // Still didn't fetch data
    return null;
  }

  const columns = [
    {
      Header: "Action",
      className: "action",
      Cell: ({ row }) => {
        const id = row.original.id;
        return (
          <>
            <IconLinkViewOrEdit
              onClick={() => {
                props.selectAccountHierarchy(row.original);
                props.showModal(ACCOUNT_HIERARCHY_MODAL);
              }}
            />
            <IconLinkDelete
              onClick={() => {
                props.showModal(DELETE_ACCOUNT_HIERARCHY_MODAL, { id });
              }}
            />
          </>
        );
      },
    },
    {
      Header: "Sales Representative",
      accessor: "sales_representative_name",
      className: "wrap-text",
    },
    {
      Header: "Sales Manager",
      accessor: "sales_manager_name",
      className: "wrap-text",
    },
    {
      Header: "National Account Manager",
      accessor: "national_account_manager_name",
      className: "wrap-text",
    },
    {
      Header: "National Account",
      accessor: "national_account_name",
      className: "wrap-text",
    },
    {
      Header: "Hierarchy Status",
      accessor: "active",
      Cell: ({ row }) => {
        return <div className="text-wrap">{row.original.active ? "Active" : "Inactive"} </div>;
      },
    },
    {
      Header: "Create Date",
      accessor: "date_created",
      Cell: dateCell,
    },
    {
      Header: "End Date",
      accessor: "date_end",
      Cell: dateCell,
    },
    {
      Header: "Last Edit Date",
      accessor: "last_edit_date",
      Cell: dateCell,
    },
    {
      Header: "Created By",
      accessor: "created_by_name",
      className: "wrap-text",
    },
    {
      Header: "Last Edited By",
      accessor: "last_edited_by_name",
      className: "wrap-text",
    },
    {
      Header: "Notes",
      accessor: "notes",
      Cell: ({ row }) => {
        const value = row.original.notes;
        let ans = "";
        if (value) {
          ans = value
            .replace(/(<([^>]+)>)/gi, " ")
            .replace("&amp;nbsp;", " ")
            .replace("&nbsp;", " ");
        }
        return <div className="text-wrap">{ans}</div>;
      },
    },
  ];

  return (
    <Table
      columns={columns}
      data={props.hierarchies}
      defaultSort={true}
      paginated={false}
      emptyMessage="No Hierarchies found"
      sortBy={[{ id: "date_created", desc: false }]}
    />
  );
};

const mapStateToProps = state => {
  const activeAccount = activeAccountSelector(state);
  const choices = managementChoicesSelector(state);

  return {
    activeAccount,
    hierarchies: activeAccount ? activeAccount.hierarchies : [],
    salesRepChoices: choices.case_sales_representatives,
    nationalAccountChoices: choices.national_accounts,
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectAccountHierarchy,
})(HierarchyTable);
