import {
  LOGIN_FIRST_STEP_SUCCESSFUL,
  LOGIN_SECOND_STEP_SUCCESSFUL,
  AUTH_ERROR,
  LOGIN_ATTEMPT,
  LOGIN_CANCEL,
  LOGOUT,
  SELECT_AGENCY,
  SMS_ERROR,
} from "actions/types";

export const defaultAuth = {
  loginFirstStepPassed: false,
  isSignedIn: false,
  email: "",
  password: "",
  phoneNumber: "",
  twoFactorActive: false,
  qrCodeURL: "",
  isAuthenticating: true,
  loginFirstStepAttempted: false,
  error: null,
  agencies: [],
  activeAgency: null,
  activeAgencyRoles: [],
  user_id: 0,
};

export default (state = defaultAuth, action) => {
  switch (action.type) {
    case LOGIN_ATTEMPT:
      return {
        ...state,
        loginAttempted: true,
      };

    case LOGIN_CANCEL:
      return {
        ...state,
        loginFirstStepPassed: false,
        email: "",
        user_id: "",
        password: "",
        phoneNumber: "",
      };

    case LOGIN_FIRST_STEP_SUCCESSFUL:
      return {
        ...state,
        error: null,
        loginFirstStepPassed: true,
        email: action.payload.email,
        password: action.payload.password,
        twoFactorActive: action.payload.two_factor_active,
        qrCodeURL: action.payload.qr_code_url,
      };

    case LOGIN_SECOND_STEP_SUCCESSFUL:
      return {
        ...state,
        isSignedIn: true,
        error: null,
        isAuthenticating: false,
        password: "",
        email: action.payload.email,
        user_id: action.payload.user_id,
        user_name: action.payload.user_name,
        user_phone: action.payload.user_phone,
        user_fax: action.payload.user_fax,
        user_title: action.payload.user_title,
        user_license: action.payload.user_license,
        user_calendly_link: action.payload.user_calendly_link,
        agencies: action.payload.agencies,
        activeAgency: action.payload.active_agency,
        activeAgencyRoles: action.payload.active_agency_roles || [],
      };

    case AUTH_ERROR: {
      // Errors is an object, which values can be either a string (for invalid credentials)
      // or a list of errors. If it is a list, we only expect one error and thus take the
      // first one.
      const errors = Object.values(action.payload)[0];
      let error;
      if (typeof errors === "string") {
        error = errors;
      } else {
        error = errors[0];
      }
      // Default error: show empty message
      if (action.payload.detail === "Given token not valid for any token type") {
        error = "";
      }
      return {
        ...state,
        error,
        isSignedIn: false,
        loginFirstStepPassed: false,
        isAuthenticating: false,
      };
    }

    case SMS_ERROR: {
      const errors = Object.values(action.payload)[0];
      let error;
      if (typeof errors === "string") {
        error = errors;
      } else {
        error = errors[0];
      }
      return {
        ...state,
        error,
      };
    }

    case LOGOUT:
      return { ...defaultAuth };

    case SELECT_AGENCY:
      return {
        ...state,
        activeAgency: action.payload.active_agency,
        activeAgencyRoles: action.payload.active_agency_roles || [],
      };

    default:
      return state;
  }
};

// Selectors
export const agenciesSelector = state => state.agencies || [];

export const activeAgencySelector = state => state.activeAgency || {};

export const administratorEnabledSelector = state => state.activeAgency.role === "Administrator";

const rolesWithAuctionAccess = ["Administrator", "Auction Manager", "Closing Manager"];
export const auctionAccessEnabledSelector = state => {
  const activeAgency = state.activeAgency;
  return activeAgency && rolesWithAuctionAccess.includes(activeAgency.role);
};

export const loginInfoSelector = state => state;
