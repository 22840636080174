import React from "react";
import { connect } from "react-redux";

import { CASE_ACCOUNT_HIERARCHY_MODAL } from "components/modals";
import { activeCaseSelector } from "reducers";
import CaseAccountHierarchyForm from "./CaseAccountHierarchyForm";
import FullPageModal from "../../../components/modals/FullPageModal";
import { selectAccountCaseHierarchy } from "../../../actions";

const CaseAccountHierarchyModal = ({ ...props }) => {
  const submitRef = React.useRef();
  React.useEffect(() => {
    if (props.caseInfo.source) {
      props.selectAccountCaseHierarchy(props.caseInfo.source);
    }
  }, [props.caseInfo.id]);

  return (
    <FullPageModal modalType={CASE_ACCOUNT_HIERARCHY_MODAL} style={{ content: { overflow: "visible" } }}>
      <CaseAccountHierarchyForm submitRef={submitRef} />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
  };
};

export default connect(mapStateToProps, {
  selectAccountCaseHierarchy,
})(CaseAccountHierarchyModal);
