import {
  CREATE_HIPAA,
  EDIT_HIPAA,
  DELETE_HIPAA,
  FETCH_HIPAAS,
  SELECT_HIPAA,
  CLEAN_LIFE_SETTLEMENT_CASE_DATA,
} from "actions/types";
import { removeKey } from "utils.js";
import { arrayToObjectIndexedById } from "reducers/utils";

export const defaultState = {
  activeHipaaId: null,
  hipaas: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_HIPAA:
      return { ...state, activeHipaaId: action.payload };

    case FETCH_HIPAAS:
      return {
        ...state,
        hipaas: arrayToObjectIndexedById(action.payload),
      };

    case CREATE_HIPAA:
    case EDIT_HIPAA:
      return {
        ...state,
        hipaas: { ...state.hipaas, [action.payload.id]: action.payload },
      };

    case DELETE_HIPAA: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        hipaas: removeKey(state.hipaas, toDeleteId),
      };
      if (state.activeHipaaId === toDeleteId) {
        newState.activeHipaaId = null;
      }
      return newState;
    }

    case CLEAN_LIFE_SETTLEMENT_CASE_DATA:
      return { ...defaultState };

    default:
      return state;
  }
};

export const hipaasSelector = state => Object.values(state.hipaas);

export const activeHipaaSelector = ({ activeHipaaId, hipaas }) => {
  if (activeHipaaId === null) {
    return null;
  }
  return hipaas[activeHipaaId];
};

export const hipaasForActiveInsuredSelector = (state, activeInsuredId) => {
  const hipaas = Object.values(state.hipaas);
  return hipaas.filter(hipaa => hipaa.insured === activeInsuredId);
};
