import { makeChoices } from "../../utils";

export const transactionTypeChoices = [
  { value: "DIRECT PLACEMENT", label: "Direct Placement" },
  { value: "TERTIARY", label: "Tertiary" },
];
export const leVendorChoices = makeChoices([
  "Internal",
  "21st Services",
  "AVS Underwriting",
  "Clarity",
  "Fasano Associates",
  "ISC",
  "Longevity Services",
  "Predictive Resources",
  "Lapetus Solution",
  "Focus",
  "Convergence",
  "Polaris",
]);

export const ndaInPlaceChoices = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
  { value: 2, label: "Pending" },
];

export const underwritingMethodChoices = [
  { value: 1, label: "Primarily Internal" },
  { value: 2, label: "Secondary Internal" },
  { value: 3, label: "No Internal" },
];

export const predictorVendorChoices = [
  { value: "FASANO", label: "Fasano" },
  { value: "LSI", label: "LSI" },
];

// These are taken as a snapshot from the database
export const servicerChoicesLazy = [
  { label: "LifeRoc Services, LLC", value: 1 },
  { label: "Asset Servicing Group, LLC", value: 2 },
  { label: "Montgomery Servicing", value: 3 },
  { label: "ITM Twentyfirst, LLC", value: 4 },
  { label: "Lyric Services, LLC", value: 5 },
  { label: "Magna Servicing", value: 6 },
  { label: "NorthStar Life Services", value: 7 },
];
