import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { FormCollapsible, Select, SubmitButton, TextInput } from "components/forms";
import { createStateFile, editStateFile, uploadStateFile } from "actions";
import { Form } from "react-bootstrap";
import { activeStateProviderFileSelector } from "../../reducers";
import { partial } from "../../utils";
import * as notifications from "../../notifications";
import { documentStatusChoices, providerDocumentTypeChoices } from "./constants";
import { isAdmin } from "permissions";

const StateProviderFileForm = ({ activeStateProviderFile, hasAdminPermission, ...props }) => {
  const methodType = "PROVIDER";
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...activeStateProviderFile }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        let method = partial(props.createStateFile, methodType);
        if (activeStateProviderFile) {
          method = partial(props.editStateFile, activeStateProviderFile.id, methodType);
        }

        try {
          delete values.date_uploaded; // read-only
          // delete values.file; // File is saved through a different endpoint
          await method(values);
          notifications.success("State File saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing State File");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        let title = "New State File";
        if (activeStateProviderFile) {
          title = "Edit State File";
        }
        return (
          <>
            <FormCollapsible onSubmit={handleSubmit} title={title}>
              <Form.Row>
                <Select
                  label="Document Type"
                  name="provider_document_type"
                  placeholder="Enter Document Type"
                  options={providerDocumentTypeChoices}
                />
              </Form.Row>
              <Form.Row>
                <TextInput label="Title" name="title" placeholder="Enter Document Title" />
              </Form.Row>
              <Form.Row>
                <Select
                  label="Status"
                  name="status"
                  placeholder="Enter Document Status"
                  options={documentStatusChoices}
                />
              </Form.Row>
              <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
            </FormCollapsible>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    activeStateProviderFile: activeStateProviderFileSelector(state),
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  uploadStateFile,
  createStateFile,
  editStateFile,
})(StateProviderFileForm);
