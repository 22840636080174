import { FETCH_LIFE_SETTLEMENT_CHOICES } from "actions/types";
import { choicesWrapper, undefinedChoicesProxy } from "options.js";

export default (state = undefinedChoicesProxy, action) => {
  switch (action.type) {
    case FETCH_LIFE_SETTLEMENT_CHOICES: {
      let choices = state.choices || {};
      Object.entries(action.payload).forEach(([key, value]) => {
        choices[key] = choicesWrapper(value);
      });
      return { ...choices };
    }
    default:
      return state;
  }
};
