import advisorhq from "../apis/advisorhq";
import * as notifications from "../notifications";
import * as types from "./types";
import { EMAIL_TEMPLATE_MODAL } from "../components/modals";
import { hideModal } from "./modals";

const ENTITY_MAPPING = {
  emailTemplate: {
    endpoint: "/life_settlement/api/email_template/",
    name: "Email Template",
    modal: EMAIL_TEMPLATE_MODAL,
    types: {
      fetchList: types.FETCH_EMAIL_TEMPLATE_LIST,
      create: types.CREATE_EMAIL_TEMPLATE,
      edit: types.EDIT_EMAIL_TEMPLATE,
      delete: types.DELETE_EMAIL_TEMPLATE,
    },
  },
};

const getEntityConf = entityType => {
  const entityConf = ENTITY_MAPPING[entityType];
  if (entityConf === undefined) {
    throw new Error(`Configuration for entity type "${entityType}" is not defined in ENTITY_MAPPING`);
  }
  return entityConf;
};

export const fetchEntityList = entityType => async (dispatch, getState) => {
  const entityConf = getEntityConf(entityType);
  dispatch({ type: types.LOADING, payload: { [entityType]: true } });
  const response = await advisorhq.get(entityConf.endpoint);
  dispatch({ type: types.LOADING, payload: { [entityType]: false } });
  dispatch({ type: entityConf.types.fetchList, payload: response.data });
};

export const createEntity = (entityType, data, setErrors) => async dispatch => {
  const entityConf = getEntityConf(entityType);
  const endpoint = entityConf.endpoint;
  try {
    const response = await advisorhq.post(endpoint, data);
    dispatch({ type: entityConf.types.create, payload: response.data });
    notifications.clearAll();
    notifications.success(`A new ${entityConf.name} was created`);
    dispatch(hideModal(entityConf.modal));
    return response.data;
  } catch (error) {
    setErrors(error.response.data);
    notifications.clearAll();
    notifications.error(`Error creating ${entityConf.name}`);
  }
};

export const editEntity = (entityType, entityId, newValues, setErrors) => async dispatch => {
  const entityConf = getEntityConf(entityType);
  notifications.success("Saving...");
  dispatch({ type: types.LOADING, payload: { [entityType]: true } });
  const endpoint = `${entityConf.endpoint}${entityId}/`;
  try {
    const response = await advisorhq.patch(endpoint, newValues);
    dispatch({ type: entityConf.types.edit, payload: response.data });
    notifications.clearAll();
    notifications.success(`${entityConf.name} saved successfully`);
    dispatch(hideModal(entityConf.modal));
    return response.data;
  } catch (error) {
    setErrors(error.response.data);
    notifications.clearAll();
    notifications.error(`Error editing ${entityConf.name}`);
  } finally {
    dispatch({ type: types.LOADING, payload: { [entityType]: false } });
  }
};

export const deleteEntity = (entityType, entityId) => async dispatch => {
  const entityConf = getEntityConf(entityType);
  try {
    await advisorhq.delete(`${entityConf.endpoint}${entityId}/`);
    dispatch({ type: entityConf.types.delete, payload: entityId });
  } catch (e) {
    notifications.error(e.response.data.error);
  }
};

export const uploadFile = (entityType, entityId, file) => async (dispatch, getState) => {
  const entityConf = getEntityConf(entityType);
  const endpoint = `${entityConf.endpoint}${entityId}/upload_file/`;
  notifications.success(`Uploading ${entityConf.name} File...`);

  const formData = new FormData();
  formData.append("id", entityId);
  formData.append("file", file, file.name);

  try {
    const response = await advisorhq.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    notifications.clearAll();
    notifications.success(`${entityConf.name} File uploaded successfully`);
    dispatch({ type: entityConf.types.edit, payload: response.data });

    const response2 = await advisorhq.get(entityConf.endpoint);
    dispatch({ type: entityConf.types.fetchList, payload: response2.data });
  } catch (error) {
    console.log(error);
    notifications.clearAll();
    notifications.error(`Error uploading ${entityConf.name} File`);
  }
};

export const deleteFile = (entityType, entityId) => async (dispatch, getState) => {
  const entityConf = getEntityConf(entityType);
  const endpoint = `${entityConf.endpoint}${entityId}/delete_file/`;
  try {
    const response = await advisorhq.delete(endpoint);
    notifications.clearAll();
    notifications.success(`${entityConf.name} File deleted successfully`);
    dispatch({ type: entityConf.types.edit, payload: response.data });

    const response2 = await advisorhq.get(entityConf.endpoint);
    dispatch({ type: entityConf.types.fetchList, payload: response2.data });
  } catch (error) {
    console.log(error);
    notifications.clearAll();
    notifications.error(`Error deleting ${entityConf.name} File`);
  }
};
