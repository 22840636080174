import React from "react";
import { connect } from "react-redux";
import { ButtonNext, ButtonPrevious } from "../../components/buttons";
import { fetchPageAndSelectTask } from "../../actions";

const TaskModalArrows = ({ prevEntry, nextEntry, onclick, setLoading, ...props }) => {
  const next = Boolean(nextEntry);
  const previous = Boolean(prevEntry);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 0.5 + "rem",
      }}
    >
      <ButtonPrevious
        previous={!previous && props.canPreviousPage}
        onClick={() => {
          setLoading(true);
          props.fetchPageAndSelectTask(props.endpoint, "PREVIOUS", onclick);
          setTimeout(() => setLoading(false), 300);
        }}
      />
      <ButtonPrevious
        previous={previous}
        onClick={() => {
          setLoading(true);
          onclick(prevEntry);
          setTimeout(() => setLoading(false), 300);
        }}
      />
      {/* this p tag is created to make flexbox work better. justifyContent: "space-between", */}
      <p style={{ opacity: 0 }}> a </p>
      <ButtonNext
        next={next}
        onClick={() => {
          setLoading(true);
          onclick(nextEntry);
          setTimeout(() => setLoading(false), 300);
        }}
      />
      <ButtonNext
        next={!next && props.canNextPage}
        onClick={() => {
          setLoading(true);
          props.fetchPageAndSelectTask(props.endpoint, "NEXT", onclick);
          setTimeout(() => setLoading(false), 300);
        }}
      />
    </div>
  );
};

export default connect(null, { fetchPageAndSelectTask })(TaskModalArrows);
