import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { TRADING_DOCUMENT_MODAL } from "components/modals";
import TradingDocumentForm from "./TradingDocumentForm";
import { activeFunderEntitySelector, activeTradingDocumentIdSelector } from "../../reducers";
import SingleFileDropZone from "../../components/SingleFileDropZone";
import { uploadTradingDocument } from "../../actions";

const TradingDocumentModal = ({ ...props }) => {
  let tradingDocumentFileIsSaved = props.activeTradingDocumentId;
  const submitRef = React.useRef();

  const WideSaveButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="btn btn--secondary"
          onClick={() => {
            submitRef.current.click();
          }}
          style={{
            width: "99%",
            marginBottom: "1rem",
          }}
        >
          Save Changes
        </button>
      </div>
    );
  };

  return (
    <FullPageModal modalType={TRADING_DOCUMENT_MODAL}>
      <TradingDocumentForm submitRef={submitRef} />
      <WideSaveButton />
      {tradingDocumentFileIsSaved ? (
        <div>
          <SingleFileDropZone
            currentFile={
              props.tradingDocumentInfo && props.tradingDocumentInfo.file ? props.tradingDocumentInfo.file : null
            }
            uploadFile={file => {
              props.uploadTradingDocument(file);
            }}
            fileDownloadUrl={`/life_settlement/api/trading_document/`}
          />
        </div>
      ) : (
        <div style={{ marginBottom: 100 }} />
      )}
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  const activeTradingDocumentId = activeTradingDocumentIdSelector(state);
  const activeFunderEntity = activeFunderEntitySelector(state);
  let tradingDocumentInfo;
  if (activeTradingDocumentId && activeFunderEntity) {
    for (const trading_document of activeFunderEntity.trading_documents) {
      if (trading_document.id === activeTradingDocumentId) {
        tradingDocumentInfo = trading_document;
        break;
      }
    }
  }
  return {
    activeTradingDocumentId,
    tradingDocumentInfo,
  };
};

export default connect(mapStateToProps, {
  uploadTradingDocument,
})(TradingDocumentModal);
