import { getServerUrl } from "../../apis/advisorhq";

export const get_websocket_url = (response, mode = "", other_agency = 0) => {
  const task_id = response.data.celery_task_id;
  const case_id = response.data.pk;
  const agency_id = response.data.agency_id;
  const user_id = response.data.user_id;
  let server_host = getServerHost();
  let protocol = getWebSocketProtocol();
  let endpoint = "";
  if (mode === "pricing") {
    endpoint = "pricing-setup";
  } else if (mode === "pricing-qc") {
    endpoint = "pricing-qc";
  } else if (mode === "copy-insured") {
    endpoint = "copy-insured";
  } else if (mode === "case") {
    endpoint = "copy-case";
  } else if (mode === "data-tape") {
    endpoint = "data-tape";
  } else if (mode === "cg-file") {
    endpoint = "cg-file";
  } else if (mode === "pricing-and-data-tape") {
    endpoint = "pricing-and-data-tape";
  } else if (mode === "other-agency") {
    endpoint = "other-agency";
    return `${protocol}://${server_host}socket-server/${endpoint}/${task_id}/${case_id}/${agency_id}/${user_id}/${other_agency}`;
  } else if (mode === "ress-xml") {
    endpoint = "base-xml";
    return `${protocol}://${server_host}socket-server/${endpoint}/${task_id}/${case_id}/${agency_id}/ress`;
  } else if (mode === "sl-xml") {
    endpoint = "base-xml";
    return `${protocol}://${server_host}socket-server/${endpoint}/${task_id}/${case_id}/${agency_id}/sl`;
  } else if (mode === "broadriver-xml") {
    endpoint = "base-xml";
    return `${protocol}://${server_host}socket-server/${endpoint}/${task_id}/${case_id}/${agency_id}/broadriver`;
  }
  return `${protocol}://${server_host}socket-server/${endpoint}/${task_id}/${case_id}/${agency_id}/${user_id}`;
};

export const getServerHost = () => {
  let server_host = getServerUrl().split("://")[1];

  // If the last character is not a `/`, add it
  if (server_host[server_host.length - 1] !== "/") {
    server_host = server_host + "/";
  }
  return server_host;
};

export const getWebSocketProtocol = () => {
  let protocol = getServerUrl().split("://")[0];
  if (protocol === "https") {
    return "wss";
  } else {
    return "ws";
  }
};

export function createTaskObject(record) {
  // if nothing is passed, then re init the active obj with null
  if (!record) return null;
  // if task_obj is empty, then it is a base instance instead of a Record (ex: account)
  if (!record?.task_obj) return record;
  // if the record is passed make a obj with the base instance + record attributes
  const task_obj = record.task_obj;
  return {
    ...task_obj,
    created_by: record.record_creator,
    last_edited_by_name: record.last_editor,
    last_edit_date: record.last_edit_date,
    date_created: record.date_created,
  };
}
