import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import {
  BooleanSelect,
  Collapsible,
  DateInput,
  GenderSelect,
  RowSeparator,
  StateSelect,
  SubmitButton,
  TextInput,
  YearInput,
} from "components/forms";
import { shouldSaveSelector } from "reducers";
import { Form } from "react-bootstrap";
import { editAccount, unsetShouldSave } from "actions";
import { partial } from "utils";
import * as notifications from "notifications";

const AccountLicenseForm = ({ accountInfo, ...props }) => {
  if (!accountInfo) {
    // Still didn't fetch data
    return null;
  }

  const {
    tax_id_number,
    national_account,
    date_of_birth,
    type,
    national_producer_number,
    active_state_list,
    date_life_licensed,
    state_counter,
    state_counter_life,
    crd_number,
    gender,
    discovery_profile_url,
    other_street_address,
    other_city,
    other_state,
    other_zipcode,
  } = accountInfo;

  const submitRef = React.useRef();

  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        type,
        date_of_birth,
        tax_id_number,
        national_account,
        national_producer_number,
        active_state_list,
        date_life_licensed,
        state_counter,
        state_counter_life,
        crd_number,
        gender,
        discovery_profile_url,
        other_street_address,
        other_city,
        other_state,
        other_zipcode,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);

        const method = partial(props.editAccount, accountInfo.id);

        try {
          await method(values);
          notifications.success("Account saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Account");
        }
        props.unsetShouldSave();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit} className={"form shadowed"} {...props}>
            <Collapsible title="Detail">
              <Form.Row>
                <TextInput label="Tax ID Number" name="tax_id_number" md={3} />
                {values.type === 0 && <DateInput label="Date of Birth" name="date_of_birth" />}
                <TextInput label="National Producer Number" name="national_producer_number" />
                <BooleanSelect
                  label="National Account?"
                  name="national_account"
                  placeholder={"Select National Account"}
                  md={3}
                />
              </Form.Row>
              <Form.Row>
                <TextInput label="State Counter (LS)" name="state_counter" md={3} />
                <TextInput label="State Counter (Life)" name="state_counter_life" md={3} />
                <TextInput label="Active State List" name="active_state_list" md={3} />
                <YearInput
                  label="Year Life Licensed"
                  name="date_life_licensed"
                  placeholder="Select Year Life Licensed"
                />
              </Form.Row>
              <Form.Row>
                <TextInput label="CRD Number" name="crd_number" md={3} />
                <GenderSelect md={3} />
                <TextInput label="Discovery Data Profile URL" name="discovery_profile_url" md={6} />
              </Form.Row>
              <RowSeparator />
              <Form.Row>
                <TextInput label="Other Street Address" name="other_street_address" md={12} />
              </Form.Row>
              <Form.Row>
                <TextInput label="City" name="other_city" />
                <StateSelect label="State" name="other_state" placeholder="Select State" />
                <TextInput label="Zip Code" name="other_zipcode" />
              </Form.Row>
              <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
            </Collapsible>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    shouldSave: shouldSaveSelector(state),
  };
};

export default connect(mapStateToProps, {
  editAccount,
  unsetShouldSave,
})(AccountLicenseForm);
