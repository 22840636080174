/* Form with vendor-independent fields.
 * Corresponds to the first form in the mockups. */

import React from "react";
import { connect } from "react-redux";

import { editLifeExpectancy, updateLifeExpectancy, fetchLifeExpectancyChoices } from "actions";
import { activeInsuredSelector, activeLifeExpectancySelector } from "reducers";

import { TwentyFirstForm, TwentyFirstMortalityTable } from "./21st";
import { FasanoForm } from "./fasano";
import MortalityTable from "./MortalityTable";
import { PredictiveForm, PredictiveMortalityTable } from "./predictive";
import { LSIForm, LSIMortalityTable } from "./lsi";
import { ISCForm, ISCMortalityTable } from "./isc";
import { AVSForm } from "./avs";
import { LapetusForm } from "./lapetus";
import PolarisForm from "./polaris/Form";
import { GenericLEProviderForm } from "./generic";
import { VENDOR_IDS } from "../../../constants";

const LifeExpectancyForm = ({
  vendor,
  vendorSpecificFormSubmitRef,
  mortalityTableSubmitRef,
  fetchLifeExpectancyChoices,
  ...props
}) => {
  let lifeExpectancy = props.activeLifeExpectancy;

  React.useEffect(() => {
    fetchLifeExpectancyChoices();
  }, [fetchLifeExpectancyChoices]);

  if (vendor === VENDOR_IDS.TWENTY_FIRST) {
    return (
      <>
        <TwentyFirstForm lifeExpectancy={lifeExpectancy} submitRef={vendorSpecificFormSubmitRef} />
        <TwentyFirstMortalityTable submitRef={mortalityTableSubmitRef} />
      </>
    );
  } else if (vendor === VENDOR_IDS.FASANO) {
    const validateFasanoMortalityTable = rows => rows;
    return (
      <>
        <FasanoForm lifeExpectancy={lifeExpectancy} submitRef={vendorSpecificFormSubmitRef} />
        <MortalityTable submitRef={mortalityTableSubmitRef} validate={validateFasanoMortalityTable} />
      </>
    );
    // } else if (vendor === VENDOR_IDS.LIFEROC) {
    //   return (
    //     <>
    //       <LifeRocForm lifeExpectancy={lifeExpectancy} submitRef={vendorSpecificFormSubmitRef} />
    //     </>
    //   );
  } else if (vendor === VENDOR_IDS.PREDICTIVE) {
    return (
      <>
        <PredictiveForm lifeExpectancy={lifeExpectancy} submitRef={vendorSpecificFormSubmitRef} />
        <PredictiveMortalityTable submitRef={mortalityTableSubmitRef} />
      </>
    );
  } else if (vendor === VENDOR_IDS.LSI) {
    return (
      <>
        <LSIForm lifeExpectancy={lifeExpectancy} submitRef={vendorSpecificFormSubmitRef} />
        <LSIMortalityTable submitRef={mortalityTableSubmitRef} />
      </>
    );
  } else if (vendor === VENDOR_IDS.ISC) {
    return (
      <>
        <ISCForm lifeExpectancy={lifeExpectancy} submitRef={vendorSpecificFormSubmitRef} />
        <ISCMortalityTable submitRef={mortalityTableSubmitRef} />
      </>
    );
  } else if (vendor === VENDOR_IDS.AVS) {
    return (
      <>
        <AVSForm lifeExpectancy={lifeExpectancy} submitRef={vendorSpecificFormSubmitRef} />
      </>
    );
  } else if (vendor === VENDOR_IDS.LAPETUS) {
    const validateLapetusMortalityTable = rows => rows;
    return (
      <>
        <LapetusForm lifeExpectancy={lifeExpectancy} submitRef={vendorSpecificFormSubmitRef} />
        <MortalityTable submitRef={mortalityTableSubmitRef} validate={validateLapetusMortalityTable} />
      </>
    );
  } else if (vendor === VENDOR_IDS.POLARIS) {
    return (
      <>
        <PolarisForm lifeExpectancy={lifeExpectancy} submitRef={vendorSpecificFormSubmitRef} />
      </>
    );
  } else {
    return (
      <>
        <GenericLEProviderForm
          vendor={vendor}
          lifeExpectancy={lifeExpectancy}
          submitRef={vendorSpecificFormSubmitRef}
        />
      </>
    );
  }
};

const mapStateToProps = state => {
  return {
    activeInsured: activeInsuredSelector(state),
    activeLifeExpectancy: activeLifeExpectancySelector(state),
  };
};

export default connect(mapStateToProps, {
  updateLifeExpectancy,
  editLifeExpectancy,
  fetchLifeExpectancyChoices,
})(LifeExpectancyForm);
