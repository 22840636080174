import {
  FETCH_LIFE_SETTLEMENT_CASES,
  DELETE_LIFE_SETTLEMENT_CASE,
  LOADING,
  FETCH_CASE_PDF,
  STORE_MAILING_DATA,
  SHOULD_SAVE,
  CLEAN_LIFE_SETTLEMENT_SEARCH_DATA,
  CLEAR_CASES_DATA,
} from "actions/types";
import { arrayToObjectIndexedById } from "reducers/utils";

export const defaultState = {
  cases: {},
  shouldSave: false,
  selectedCase: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_CASES_DATA:
      return defaultState;
    case FETCH_LIFE_SETTLEMENT_CASES:
      return {
        ...state,
        cases: arrayToObjectIndexedById(action.payload),
      };
    case CLEAN_LIFE_SETTLEMENT_SEARCH_DATA:
      return {
        ...state,
      };
    case FETCH_CASE_PDF:
      return {
        ...state,
        casePdf: action.payload,
      };
    case STORE_MAILING_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_LIFE_SETTLEMENT_CASE:
      delete state[action.payload.id];
      return state;
    case LOADING:
      if (action.payload.cases) {
        return {
          ...state,
          cases: arrayToObjectIndexedById([]),
        };
      } else {
        return state;
      }
    case SHOULD_SAVE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const casesSelector = state => {
  // Return case management cases ordered by decreasing id
  let cases = Object.values(state.cases);
  return cases.sort((a, b) => Number(b.id) - Number(a.id));
};

export const shouldSaveSelector = state => state.shouldSave;
