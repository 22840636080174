import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { SEND_LEAD_EMAIL_MODAL } from "components/modals";
import SendLeadEmailForm from "./SendLeadEmailForm";

const SendLeadEmailModal = () => {
  return (
    <FullPageModal modalType={SEND_LEAD_EMAIL_MODAL} title={`Send Lead Email Template`}>
      <SendLeadEmailForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(SendLeadEmailModal);
