import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import { activeParameterSelector } from "../../../reducers";
import ParameterDetailBackgroundForm from "./ParameterDetailBackgroundForm";

const ParameterDetailBackground = ({ parameterInfo, ...props }) => {
  return (
    <div className={`section-card shadowed case-section`} style={{ marginTop: "5.5rem" }}>
      <Card className={"case-info"}>
        <Card.Body>
          <ParameterDetailBackgroundForm parameterInfo={parameterInfo} />
        </Card.Body>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    parameterInfo: activeParameterSelector(state),
  };
};

export default connect(mapStateToProps, {})(ParameterDetailBackground);
