import React from "react";
import { useField, useFormikContext } from "formik";
import { Form, InputGroup, Col } from "react-bootstrap";

import Label from "./Label";
import createMaskedInput from "./createMaskedInput";
import { validateLength, getValidationClassName } from "./validation";
import FieldErrors from "./FieldErrors";
import { getViewMode } from "../../utils";

const TIN_LENGTH = 9;

export default ({ name = "tin_number", md = "", label = "TIN Number", ...props }) => {
  const [field, meta] = useField({ name, validate: validateLength(TIN_LENGTH) });
  const { setFieldValue } = useFormikContext();
  const isViewMode = getViewMode();
  const isDisabled = props.disabled || isViewMode;

  const d = /\d/;
  const tinMask = [d, d, "-", d, d, d, d, d, d, d];

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <div style={{ marginLeft: 1, marginRight: 3 }}>{label}</div>
            {props.icon}
          </InputGroup.Text>
        </InputGroup.Prepend>
        {createMaskedInput({
          mask: tinMask,
          field,
          setFieldValue,
          className: getValidationClassName(meta),
          disabled: isDisabled,
        })}
        <FieldErrors meta={meta} />
      </InputGroup>
    </Form.Group>
  );
};
