import React from "react";
import { connect } from "react-redux";

import { dateDisplay } from "../../../utils";
import { fetchLifeExpectancies, selectLifeExpectancy, deleteLifeExpectancy, showModal, downloadFile } from "actions";
import {
  activeInsuredSelector,
  activeLifeExpectancySelector,
  lifeExpectanciesForActiveInsuredSelector,
} from "reducers";
import Table, { dateCell, choiceCell, percentageCell } from "components/table";
import { vendorChoices } from "../../../constants";

const LifeExpectancyTable = ({ fetchLifeExpectancies, lifeExpectancies, ...props }) => {
  React.useEffect(() => {
    fetchLifeExpectancies();
  }, [fetchLifeExpectancies]);

  // Only show the life expectancy table when there is an active insured
  if (!props.activeInsured) {
    return null;
  }

  const columns = [
    {
      Header: "Vendor",
      accessor: "vendor",
      Cell: choiceCell(vendorChoices),
    },
    {
      Header: "LE Client",
      accessor: "client",
    },
    {
      Header: "Cert. Date",
      accessor: "certificate_date",
      Cell: dateCell,
    },
    {
      Header: "Mean LE",
      accessor: "mean",
    },
    {
      Header: "Median LE",
      accessor: "median",
    },
    {
      Header: "MM",
      Cell: ({ row }) => {
        const lifeExpectancy = lifeExpectancies.find(le => le.id === row.original.id);
        return percentageCell({
          value: lifeExpectancy.adjusted_mortality_rating || lifeExpectancy.mortality_rating || "",
        });
      },
    },
    {
      Header: "Tobacco",
      accessor: "tobacco_use",
      Cell: ({ row }) => {
        return row.original.tobacco_use ? "Yes" : "No";
      },
    },
    {
      Header: "Rec. Range",
      Cell: ({ row }) => {
        const lifeExpectancy = lifeExpectancies.find(le => le.id === row.original.id);
        if (lifeExpectancy.vendor === "LSI") {
          const from = dateDisplay(lifeExpectancy.medical_info && lifeExpectancy.medical_info.med_res_to_date) || "";
          return `${from || "Empty"}`;
        }
        const { records_date_from, records_date_to } = lifeExpectancy;
        const from = dateDisplay(records_date_from) || "";
        const to = dateDisplay(records_date_to) || "";
        if (from || to) {
          return `${from || "Empty"} - ${to || "Empty"}`;
        }
        return "";
      },
    },
  ];

  return (
    <Table
      columns={columns}
      data={lifeExpectancies}
      paginated={false}
      emptyMessage="No records found"
      rowIsActive={row => props.activeLifeExpectancy && row.original.id === props.activeLifeExpectancy.id}
    />
  );
};

const mapStateToProps = state => {
  return {
    activeInsured: activeInsuredSelector(state),
    activeLifeExpectancy: activeLifeExpectancySelector(state),
    lifeExpectancies: lifeExpectanciesForActiveInsuredSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchLifeExpectancies,
  selectLifeExpectancy,
  showModal,
  downloadFile,
  deleteLifeExpectancy,
})(LifeExpectancyTable);
