import React from "react";
import { connect } from "react-redux";
import { clearSelectedParameter, copyParameter, selectParameter, showModal } from "actions";
import { activeAgencySelector, parametersSelector } from "reducers";
import Table, { choiceCell, dateCell } from "components/table";
import IconLink from "components/IconLink";
import { FaCopy } from "react-icons/fa";
import { DELETE_PARAMETER_MODAL } from "components/modals";
import { isAdmin } from "permissions";
import { loadingParametersSelector } from "../../../reducers/life_settlement/loading";
import { PARAMETER_CATEGORY_CHOICES } from "../constants";
import { CellViewEditDelete } from "components/icons";

const ParametersTable = ({ parameters, parametersLoading, activeAgency, ...props }) => {
  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const parameterId = row.original.id;
        return (
          <>
            <CellViewEditDelete
              to={`/riskx-parameters/${parameterId}/background/`}
              id={parameterId}
              deleteModal={DELETE_PARAMETER_MODAL}
              clearAction={clearSelectedParameter}
              selectAction={selectParameter}
            />
            <IconLink
              Icon={FaCopy}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="Copy Parameter"
              onClick={() => props.copyParameter(row.original.id)}
            />
          </>
        );
      },
    },
    {
      Header: "Health Parameter Category",
      accessor: "category",
      Cell: choiceCell(PARAMETER_CATEGORY_CHOICES),
    },
    {
      Header: "Health Parameter Sub-Category",
      accessor: "sub_category",
    },
    {
      Header: "Health Parameter Name",
      accessor: "name",
    },
    {
      Header: "Related Conditions",
      Cell: ({ row }) => {
        let values = "";
        row.original.related_conditions.forEach(cond => {
          values = values.concat(cond.name);
        });
        return values;
      },
    },
    {
      Header: "Related Profiles",
      Cell: ({ row }) => {
        let values = "";
        row.original.related_profiles.forEach(profile => {
          values = values.concat(profile.name);
        });
        return values;
      },
    },
    {
      Header: "Related Treatments",
      Cell: ({ row }) => {
        let values = "";
        row.original.related_treatments.forEach(treatment => {
          values = values.concat(treatment.name);
        });
        return values;
      },
    },
    {
      Header: "Related Tests",
      Cell: ({ row }) => {
        let values = "";
        row.original.related_tests.forEach(test => {
          values = values.concat(test.name);
        });
        return values;
      },
    },
    {
      Header: "Synonym Soft Matches",
      accessor: "soft_synonyms_name",
      className: "wrap-text",
    },
    {
      Header: "Synonym Hard Matches",
      accessor: "hard_synonyms_name",
      className: "wrap-text",
    },
    {
      Header: "Related ICD 10 CM Codes",
      accessor: "icd_10_codes",
    },
    {
      Header: "Health Parameter Code",
      accessor: "code",
    },
    {
      Header: "Last Edited By",
      accessor: "last_edited_by_name",
      className: "wrap-text",
    },
    {
      Header: "Date Last Updated",
      accessor: "last_edit_date",
      Cell: dateCell,
    },
  ];

  return (
    <Table
      columns={columns}
      data={parameters}
      emptyMessage={parametersLoading ? "Loading Parameters data..." : "Select a Parameter"}
      showPaginationResults={true}
      initialPageSize={100}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    parameters: parametersSelector(state),
    activeAgency: activeAgencySelector(state),
    parametersLoading: loadingParametersSelector(state),
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectParameter,
  clearSelectedParameter,
  copyParameter,
})(ParametersTable);
