import React from "react";
import { connect } from "react-redux";

import { activeAgencySelector, auctionIdSelector, loadingFundersSelector, totalTradingCasesSelector } from "reducers";
import Table, { choiceCell, currencyCell, dateCell, idCell } from "components/table";

import { moneyDisplay, olderThanSevenDays } from "../../utils";
import IconLink from "../../components/IconLink";
import { FaEdit } from "react-icons/fa";
import { caseRevenueModelTypeChoices, caseStatusChoices, marketTypeChoices } from "../../constants";

const TradingDashboard = ({ totalTradingCases, fundersLoading, ...props }) => {
  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const caseId = row.original.id;
        return (
          <>
            <IconLink
              to={`/cases/${caseId}/trading/`}
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="Manage Life Settlement Case"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            />
          </>
        );
      },
    },
    {
      Header: "Application ID",
      accessor: "id",
      Cell: idCell,
    },
    {
      Header: "Status",
      Cell: choiceCell(caseStatusChoices),
      accessor: "status",
      className: "wrap-text",
    },
    {
      Header: "Insured Names",
      accessor: "insured_names",
      className: "wrap-text",
    },
    {
      Header: "Carrier",
      accessor: "policy.carrier.name",
      className: "wrap-text",
    },
    {
      Header: "Face Amount",
      accessor: "policy.face_amount",
      Cell: currencyCell,
    },
    {
      Header: "Product Type",
      accessor: "product_type",
      className: "wrap-text",
    },
    {
      Header: "Sales Rep",
      accessor: "hierarchy.sales_representative.name",
      className: "wrap-text",
    },
    {
      Header: "Highest Funder Name",
      accessor: "highest_funder_name",
      name: "highest_funder_name",
      Cell: ({ row }) => {
        if (row.original.highest_funder_name) {
          return (
            <div className="text-wrap">
              <a href={`/funders/${row.original.highest_funder_id}/overview`} target="_blank" rel="noreferrer">
                {row.original.highest_funder_name}
              </a>
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      Header: "Highest Funder Offer",
      accessor: "highest_funder_offer",
      name: "highest_funder_offer",
      Cell: ({ row }) => {
        return <div className="text-wrap">{moneyDisplay(row.original.highest_funder_offer)}</div>;
      },
    },
    {
      Header: "Our High Offer",
      accessor: "our_high_offer",
      name: "our_high_offer",
      Cell: ({ row }) => {
        return <div className="text-wrap">{moneyDisplay(row.original.our_high_offer)}</div>;
      },
    },
    {
      Header: "Highest Market Offer",
      accessor: "highest_market_offer",
      name: "highest_market_offer",
      Cell: ({ row }) => {
        return <div className="text-wrap">{moneyDisplay(row.original.highest_market_offer)}</div>;
      },
    },
    {
      Header: "Market Type",
      accessor: "market_type",
      className: "wrap-text",
      Cell: choiceCell(marketTypeChoices),
    },
    {
      Header: "Pending Funders",
      accessor: "number_of_pending_funders",
      className: "wrap-text",
    },
    {
      Header: "Submitted Funders",
      accessor: "number_of_submitted_funders",
      className: "wrap-text",
    },
    {
      Header: "Auction Start Date",
      accessor: "auction.start_date",
      Cell: dateCell,
      className: "wrap-text",
    },
    {
      Header: "Revenue Model",
      accessor: "revenue_model",
      className: "wrap-text",
      Cell: choiceCell(caseRevenueModelTypeChoices),
    },
    {
      Header: "Our Revenue",
      accessor: "our_revenue",
      name: "our_revenue",
      Cell: ({ row }) => {
        return <div className="text-wrap">{moneyDisplay(row.original.our_revenue)}</div>;
      },
    },
    {
      Header: "Last Funder Activity",
      accessor: "last_funder_activity",
      Cell: ({ row }) => {
        if (olderThanSevenDays(row.original.last_funder_activity)) {
          return (
            <div className="text-wrap" style={{ color: "red" }}>
              {row.original.last_funder_activity}
            </div>
          );
        } else {
          return <div className="text-wrap">{row.original.last_funder_activity}</div>;
        }
      },
    },
    {
      Header: "Last Market Activity",
      accessor: "last_market_activity",
      Cell: ({ row }) => {
        if (olderThanSevenDays(row.original.last_market_activity)) {
          return (
            <div className="text-wrap" style={{ color: "red" }}>
              {row.original.last_market_activity}
            </div>
          );
        } else {
          return <div className="text-wrap">{row.original.last_market_activity}</div>;
        }
      },
    },
    {
      Header: "Funder Winning Bid",
      accessor: "winning_bid",
      Cell: ({ row }) => {
        const has_f_winning_bid = row.original.auction.funderactivity_set.some(
          e => e.funder_winning_bid === true && (e.type === "B" || e.type === "BC"),
        )
          ? "Yes"
          : "No";
        return <div className="text-wrap">{has_f_winning_bid}</div>;
      },
    },
  ];

  return (
    <>
      <h4>Trading Cases</h4>
      <Table
        columns={columns}
        data={totalTradingCases}
        defaultSort={true}
        defaultPagination={true}
        emptyMessage={fundersLoading ? "Loading Funders data..." : "No data"}
        tableClass={"tableFixed"}
      />
    </>
  );
};

const mapStateToProps = state => {
  const totalTradingCases = totalTradingCasesSelector(state) || [];

  return {
    auctionId: auctionIdSelector(state),
    totalTradingCases,
    fundersLoading: loadingFundersSelector(state),
    activeAgency: activeAgencySelector(state),
  };
};

export default connect(mapStateToProps)(TradingDashboard);
