import React from "react";
import { connect } from "react-redux";

import { Select } from ".";

const EscrowAgentSelect = ({
  options,
  name = "escrow_agent",
  label = "Escrow Agent",
  placeholder = "Select Escrow Agent",
  required = false,
}) => <Select label={label} name={name} placeholder={placeholder} options={options} isRequired={required} />;

const mapStateToProps = state => {};

export default connect(mapStateToProps)(EscrowAgentSelect);
