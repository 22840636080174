import React from "react";
import { FaEdit, FaEye } from "react-icons/fa";
import { getViewMode } from "../../utils";
import IconLink from "../IconLink";

const IconLinkViewOrEdit = ({ to = "#", ...props }) => {
  const isViewMode = getViewMode();
  let Icon = isViewMode ? FaEye : FaEdit;
  const tooltip = isViewMode ? "View" : "Edit";
  const iconConfig = { className: "table--action-icon" };

  return <IconLink to={to} Icon={Icon} tooltip={tooltip} iconConfig={iconConfig} {...props} />;
};

export default IconLinkViewOrEdit;
