import React from "react";

import PolicyForm from "./PolicyForm";
import Loader from "components/Loader";
import { activeCaseSelector, loadingSelector, shouldSaveSelector } from "reducers";
import { connect } from "react-redux";
import { fetchPolicy, unsetShouldSave } from "actions";

const PolicyPage = props => {
  const policyDetailSubmitRef = React.useRef();

  React.useEffect(() => {
    if (props.shouldSave) {
      policyDetailSubmitRef.current.click();
      setTimeout(() => {
        unsetShouldSave();
      }, 200);
    }
  }, [props.shouldSave]);

  React.useEffect(() => {
    if (props.caseInfo.submission_id) {
      document.title = `LifeRoc | ${props.caseInfo.submission_id} | Policy`;
    }
    if (props.caseInfo.policy) {
      props.fetchPolicy(props.caseInfo.policy);
    }
  }, [props.caseInfo.id]);

  if (props.loading.error404) {
    return <></>;
  }

  return (
    <>
      <Loader isLoading={props.loading.policy} text={"Loading Policy data"} />
      <PolicyForm submitRef={policyDetailSubmitRef} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
    loading: loadingSelector(state),
    shouldSave: shouldSaveSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchPolicy,
})(PolicyPage);
