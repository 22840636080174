import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { FormCollapsible, Select, StateSelect, SubmitSearchButton, TextInput } from "components/forms";
import { fetchDoctors, hideModal, selectDoctorFromNPIData } from "actions";
import Table from "components/table";
import { FaArrowLeft, FaArrowRight, FaArrowUp } from "react-icons/fa";
import { FIND_DOCTOR_MODAL } from "components/modals";

const FindDoctorForm = ({ ...props }) => {
  const [doctors, setDoctors] = React.useState([]);
  const [page, setPage] = React.useState(1);

  const DoctorsTable = () => {
    if (!doctors) {
      return null;
    }

    const columns = [
      {
        Header: "Select",
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <button
              className={`btn btn--secondary`}
              onClick={e => {
                props.hideModal(FIND_DOCTOR_MODAL);
                props.selectDoctorFromNPIData(data);
              }}
            >
              Select
            </button>
          );
        },
      },
      {
        Header: "NPI",
        accessor: "number",
        className: "wrap-text",
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <a href={`https://npiregistry.cms.hhs.gov/provider-view/${data.number}`} target="_blank" rel="noreferrer">
              {data.number}
            </a>
          );
        },
      },
      {
        Header: "Name",
        accessor: "name",
        className: "wrap-text",
        Cell: ({ row }) => {
          const data = row.original;
          if (data.basic.organization_name) {
            return data.basic.organization_name;
          } else if (data.basic.first_name || data.basic.last_name) {
            return `${data.basic.first_name} ${data.basic.last_name}`;
          }
          return "";
        },
      },
      {
        Header: "Taxonomies",
        accessor: "taxonomies[0].desc",
        className: "wrap-text",
      },
      {
        Header: "Address",
        className: "wrap-text",
        Cell: ({ row }) => {
          const addresses = row.original.addresses;
          for (const address of addresses) {
            if (address.address_purpose === "LOCATION" && address.address_1) {
              return address.address_1;
            }
          }
          return "";
        },
      },
      {
        Header: "Phone",
        Cell: ({ row }) => {
          const addresses = row.original.addresses;
          for (const address of addresses) {
            if (address.address_purpose === "LOCATION" && address.telephone_number) {
              return address.telephone_number;
            }
          }
          return "";
        },
      },
      {
        Header: "Fax",
        Cell: ({ row }) => {
          const addresses = row.original.addresses;
          for (const address of addresses) {
            if (address.address_purpose === "LOCATION" && address.fax_number) {
              return address.fax_number;
            }
          }
          return "";
        },
      },
    ];

    let resultsString = `Results: ${page * 100 - 99} - ${page * 100}`;
    return (
      <>
        <div id="results">
          {doctors.length > 1 ? (
            <div style={{ fontSize: 16, marginBottom: 5, paddingTop: 10 }}>{resultsString}</div>
          ) : null}
        </div>
        <Table
          columns={columns}
          data={doctors}
          defaultSort={true}
          showPaginationResults={false}
          defaultPagination={true}
          emptyMessage={"No results"}
        />
      </>
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        document.activeElement.blur(); // Remove focus from the currently focused element
        let data = await props.fetchDoctors(values);
        setDoctors(data.results);
      }}
    >
      {({ handleSubmit, values }) => {
        const BackButton = () => {
          const [loading, setLoading] = React.useState(false);

          const handleClick = async () => {
            try {
              setLoading(true);
              if (doctors.length >= 100) {
                let data = await props.fetchDoctors({ skip: page * 100 - 100, ...values });
                setPage(page - 1);
                setDoctors(data.results);
                const inputGroupElement = document.querySelector("#results");
                if (inputGroupElement) {
                  inputGroupElement.focus();
                  inputGroupElement.scrollIntoView();
                }
              }
            } catch (error) {
              console.error("Error fetching doctors:", error);
            } finally {
              setLoading(false);
            }
          };

          if (doctors.length >= 100 && page > 1) {
            return (
              <button
                className={`btn btn--secondary`}
                style={{ backgroundColor: "blue" }}
                onClick={handleClick}
                disabled={loading}
              >
                <FaArrowLeft style={{ marginLeft: "5px" }} />
                {loading ? "Loading..." : "Back"}
              </button>
            );
          } else {
            return <button style={{ opacity: 0 }}>-</button>;
          }
        };
        const NewSearchButton = () => {
          if (!doctors.length) {
            return null;
          }
          return (
            <button
              className={`btn btn--secondary`}
              style={{ marginRight: "10px" }}
              onClick={() => {
                // To Scroll the Modal all the way up, we find the "number" input and set the browser's focus on it
                const inputGroupElement = document.querySelector('input[name="number"]');
                if (inputGroupElement) {
                  inputGroupElement.focus();
                }
              }}
            >
              <FaArrowUp style={{ marginLeft: "5px" }} />
              Start a New Search
            </button>
          );
        };
        const NextButton = () => {
          const [loading, setLoading] = React.useState(false);

          const handleClick = async () => {
            try {
              setLoading(true);
              if (doctors.length >= 100) {
                let data = await props.fetchDoctors({ skip: page * 100, ...values });
                setPage(page + 1);
                setDoctors(data.results);
                const inputGroupElement = document.querySelector("#results");
                if (inputGroupElement) {
                  inputGroupElement.focus();
                  inputGroupElement.scrollIntoView();
                }
              }
            } catch (error) {
              console.error("Error fetching doctors:", error);
            } finally {
              setLoading(false);
            }
          };

          if (doctors.length >= 100 && page < 21) {
            return (
              <button
                className={`btn btn--secondary`}
                style={{ backgroundColor: "blue" }}
                onClick={handleClick}
                disabled={loading}
              >
                {loading ? "Loading..." : "More"}
                <FaArrowRight style={{ marginLeft: "5px" }} />
              </button>
            );
          } else {
            return <button style={{ opacity: 0 }}>-</button>;
          }
        };

        return (
          <>
            <FormCollapsible onSubmit={handleSubmit} title="Search NPI Records">
              <Form.Row>
                <TextInput label="NPI Number" name="number" />
                <Select
                  label="NPI Type"
                  name="enumeration_type"
                  defaultValue={{ value: null, label: "Any" }}
                  options={[
                    { value: null, label: "Any" },
                    { value: "NPI-1", label: "Individual" },
                    { value: "NPI-2", label: "Organization" },
                  ]}
                />
                <TextInput label="Taxonomy Description" name="taxonomy_description" />
              </Form.Row>
              <Form.Row>
                <div style={{ padding: "0 5px", color: "gray" }}>for individuals</div>
              </Form.Row>
              <Form.Row>
                <TextInput label="Provider First Name" name="first_name" />
                <TextInput label="Provider Last Name" name="last_name" />
              </Form.Row>
              <Form.Row>
                <div style={{ padding: "0 5px", color: "gray" }}>for organizations</div>
              </Form.Row>
              <Form.Row>
                <TextInput label="Organization Name (LBN, DBA, Former LBN or Other Name)" name="organization_name" />
              </Form.Row>
              <Form.Row>
                <TextInput label="City" name="city" />
                <StateSelect label="State" name="state" />
                <TextInput label="Postal Code" name="postal_code" />
              </Form.Row>
              <Form.Row>
                <div style={{ padding: 5, fontSize: 14 }}>
                  <b>Note:</b> The NPI Registry limits searches to the first 2100 results. If you cannot what you are
                  looking for, please refine the search.
                </div>
              </Form.Row>
              <SubmitSearchButton />
            </FormCollapsible>
            <DoctorsTable />
            <Form.Row className="d-flex justify-content-between align-items-center">
              {doctors.length >= 100 ? (
                <>
                  <BackButton />
                  <NextButton />
                </>
              ) : null}
            </Form.Row>
            <Form.Row className="justify-content-center">
              <NewSearchButton />
            </Form.Row>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  fetchDoctors,
  hideModal,
  selectDoctorFromNPIData,
})(FindDoctorForm);
