/* Insured medical categories */
import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { FieldArray } from "formik";
import Select from "react-select";

import { fetchPredictiveMedicalConditionsSchema } from "actions";
import { predictiveMedicalConditionsSchemaSelector } from "reducers";
import MedicalCategory from "./MedicalCategory";
import { partial } from "../../../../utils";

const MedicalCategoryList = ({ medicalInfo, conditionsSchema, ...props }) => {
  React.useEffect(() => {
    props.fetchPredictiveMedicalConditionsSchema();
  }, []);

  return (
    <>
      <Form.SectionTitle>Medical Conditions</Form.SectionTitle>
      <FieldArray
        name="medical_info.conditions" // key name under which medical conditions are stored
        validateOnChange={false}
        render={arrayHelpers => (
          <>
            {medicalInfo &&
              medicalInfo.conditions &&
              medicalInfo.conditions.map((category, index, categories) => {
                return (
                  <MedicalCategory
                    category={category}
                    categoryIndex={index}
                    removeCategory={partial(arrayHelpers.remove, index)}
                  />
                );
              })}
            <Select
              className="new-condition"
              value={null} // reset on select
              menuPlacement="top"
              placeholder="Add Medical Category"
              options={conditionsSchema
                .filter(
                  condition =>
                    !medicalInfo ||
                    !medicalInfo.conditions ||
                    !medicalInfo.conditions.find(c => c.name === condition.name),
                )
                .map(category => {
                  return { label: category.name, value: category.name };
                })}
              onChange={option => {
                arrayHelpers.push({ name: option.label, conditions: [] });
              }}
            />
          </>
        )}
      />
    </>
  );
};

const mapStateToProps = state => {
  return { conditionsSchema: predictiveMedicalConditionsSchemaSelector(state) };
};

export default connect(mapStateToProps, { fetchPredictiveMedicalConditionsSchema })(MedicalCategoryList);
