import { FETCH_CASE_PDF } from "actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_CASE_PDF:
      return {
        ...state,
        casePdf: action.payload,
      };
    default:
      return state;
  }
};
