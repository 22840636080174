import React from "react";
import { connect } from "react-redux";
import { fetchStates, selectState, showModal } from "actions";
import { activeAgencySelector, statesSelector } from "reducers";
import Table from "components/table";
import { loadingStatesSelector } from "../../reducers/life_settlement/loading";
import IconLink from "components/IconLink";
import { FaEdit } from "react-icons/fa";
import { isAdmin } from "permissions";

const StatesTable = ({ states, statesLoading, activeAgency, ...props }) => {
  React.useEffect(() => {
    props.fetchStates();
  }, []);

  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const caseId = row.original.id;
        return props.hasAdminPermission ? (
          <IconLink
            to={`/states/${caseId}/broker/`}
            Icon={FaEdit}
            iconConfig={{ className: "table--action-icon" }}
            tooltip="View / Edit"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          />
        ) : (
          ""
        );
      },
    },
    {
      Header: "State Name",
      accessor: "name",
    },
    {
      Header: "State Code",
      accessor: "code",
    },
    {
      Header: "Provider Licensed",
      accessor: "provider_licensed",
    },
    {
      Header: "Broker License Search",
      accessor: "broker_state_license_search",
    },
    {
      Header: "Broker Compensation Disclosure",
      accessor: "broker_compensation_disclosure",
    },
    {
      Header: "Broker Reporting",
      accessor: "broker_reporting",
    },
    {
      Header: "Policy Insurance Minimum",
      accessor: "minimum_pricing",
    },
    {
      Header: "Recission Period",
      accessor: "recission_period",
    },
    {
      Header: "Payment Advance",
      accessor: "payment_advance",
    },
    {
      Header: "RDB",
      accessor: "rdb",
    },
  ];

  return (
    states && (
      <Table
        columns={columns}
        data={states}
        emptyMessage={statesLoading ? "Loading States data..." : "Select a State"}
        defaultSort={true}
        showPaginationResults={true}
        initialPageSize={100}
        defaultPagination={true}
        sortBy={[{ id: "name", desc: false }]}
      />
    )
  );
};

const mapStateToProps = (state, ownProps) => {
  const states = statesSelector(state);
  return {
    states: states,
    activeAgency: activeAgencySelector(state),
    statesLoading: loadingStatesSelector(state),
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  fetchStates,
  selectState,
  showModal,
})(StatesTable);
