import React from "react";
import { connect } from "react-redux";

import { activeCaseSelector, shouldSaveSelector } from "reducers";

import { pricingSetup, unsetShouldSave } from "actions";
import CaseTradingSummaryForm from "./form/CaseTradingSummaryForm";

const CaseTradingSummary = ({ caseInfo, ...props }) => {
  if (!caseInfo) {
    return null;
  }

  return <CaseTradingSummaryForm caseInfo={caseInfo} />;
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
    shouldSave: shouldSaveSelector(state),
  };
};

export default connect(mapStateToProps, {
  pricingSetup,
  unsetShouldSave,
})(CaseTradingSummary);
