import { getAge } from "../../../../utils";

export const prepareTags = insuredInfo => {
  let insured_names = "";
  let insured_ages = "";
  let insured_gender = "";
  if (insuredInfo.length === 1) {
    insured_names = `${insuredInfo[0].last_name}, ${insuredInfo[0].first_name}`;
    insured_ages = getAge(insuredInfo[0].date_of_birth);
    insured_gender = insuredInfo[0].gender;
  } else if (insuredInfo.length === 2) {
    insured_names = `${insuredInfo[0].last_name}, ${insuredInfo[0].first_name} & ${insuredInfo[1].last_name}, ${insuredInfo[1].first_name}`;
    insured_ages = `${getAge(insuredInfo[0].date_of_birth)} & ${getAge(insuredInfo[1].date_of_birth)} `;
    insured_gender = `${insuredInfo[0].gender} & ${insuredInfo[1].gender}`;
  }
  return { insured_names, insured_ages, insured_gender };
};
