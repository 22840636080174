import { FETCH_ORDER_FILES, LOADING, RESET_ORDER_FILES } from "../types";
import advisorhq from "../../apis/advisorhq";
import * as notifications from "../../notifications";

export const generateQuestHealthRequest = (caseDocumentId, caseId, values) => async dispatch => {
  return await advisorhq.post(`/life_settlement/api/quest_health_request/${caseDocumentId}/${caseId}/`, values);
};

export const resetQuestHealthRequest = () => async (dispatch, getState) => {
  dispatch({ type: RESET_ORDER_FILES });
};
