import React from "react";

const Control = ({ children, className = "", ...props }) => {
  return (
    <button className={`control ${className}`} {...props}>
      {children}
    </button>
  );
};

const PageNumber = ({ isActive = false, idx, ...props }) => {
  return (
    <Control className={isActive && "active"} {...props}>
      {idx}
    </Control>
  );
};

const PagesDisplay = ({ pageCount, pageIndex, gotoPage }) => {
  const deltaPages = 3; // how many to show forward/backwards from current page
  const pageNumbersToshow = [];

  if (pageIndex - deltaPages > 1) {
    pageNumbersToshow.push(
      <span key="ellipsis-1" className="ellipsis">
        ...
      </span>,
    );
  }

  let i = deltaPages;
  while (i > 0) {
    const page = pageIndex - i;
    if (page > 0) {
      pageNumbersToshow.push(
        <PageNumber key={page} idx={page} onClick={() => gotoPage(page - 1)} />,
      );
    }
    i--;
  }

  pageNumbersToshow.push(<PageNumber key={pageIndex} idx={pageIndex} isActive />);

  i = 1;
  while (i <= deltaPages && pageIndex + i <= pageCount) {
    const page = pageIndex + i;
    pageNumbersToshow.push(
      <PageNumber key={page} idx={page} onClick={() => gotoPage(page - 1)} />,
    );
    i++;
  }

  if (pageIndex + deltaPages < pageCount) {
    pageNumbersToshow.push(
      <span key="ellipsis-2" className="ellipsis">
        ...
      </span>,
    );
  }

  return pageNumbersToshow;
};

const PageSizeSelect = ({ pageSize, setPageSize }) => (
  <select
    value={pageSize}
    className="page-size"
    onChange={e => {
      setPageSize(Number(e.target.value));
    }}
  >
    {[5, 10, 50, 100].map(pageSize => (
      <option key={pageSize} value={pageSize}>
        Show {pageSize}
      </option>
    ))}
  </select>
);

export default ({
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
  setPageSize,
  gotoPage,
  pageSize,
  previousPage,
  nextPage,
  showPaginationResults,
}) => {
  if (pageCount === 0) return null;
  return (
    <div className="pagination">
      {pageCount > 1 && ( // only show pages when there are more than 1
        <>
          <Control onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </Control>
          <Control onClick={() => previousPage()} disabled={!canPreviousPage}>
            &larr; Prev
          </Control>
          <PagesDisplay
            pageCount={pageCount}
            pageIndex={pageIndex + 1}
            gotoPage={gotoPage}
          />
          <Control onClick={() => nextPage()} disabled={!canNextPage}>
            Next &rarr;
          </Control>
          <Control onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {">>"}
          </Control>
        </>
      )}
      {showPaginationResults ? (
        <PageSizeSelect pageSize={pageSize} setPageSize={setPageSize} />
      ) : null}
    </div>
  );
};
