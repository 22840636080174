import React from "react";
import { connect } from "react-redux";

import { CREATE_ACCOUNT_MODAL } from "components/modals";
import { activeAccountSelector } from "reducers";
import FullPageModal from "../../components/modals/FullPageModal";
import { selectAccountCaseHierarchy } from "../../actions";
import CreateAccountModalForm from "./information/CreateAccountModalForm";

const CreateAccountModal = ({ entityId, accountType, ...props }) => {
  return (
    <FullPageModal modalType={CREATE_ACCOUNT_MODAL} className={"wide"}>
      <CreateAccountModalForm accountType={accountType} entityId={entityId} />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    accountInfo: activeAccountSelector(state),
  };
};

export default connect(mapStateToProps, {
  selectAccountCaseHierarchy,
})(CreateAccountModal);
