import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";

import * as notifications from "../../../../notifications";
import {
  Select,
  DateTimeInput,
  EditorField,
  FormCollapsible,
  SubmitButton,
  TextInput,
  PercentageInput,
  CurrencyInput,
} from "components/forms";
import { createAuctionRound, editAuctionRound } from "actions";
import { activeAuctionRoundSelector, auctionIdSelector } from "reducers";
import { partial, getValue } from "../../../../utils";
import { spreadTypeChoices } from "../../../../constants";

const RoundForm = ({ auctionRound, ...props }) => {
  const renderSpreadRate = ({ spread_type }) => {
    if (!spread_type || spread_type === getValue(spreadTypeChoices, "Custom")) {
      return <CurrencyInput label="Spread Rate" name="spread_rate" disabled value={""} />;
    }
    if (spread_type === getValue(spreadTypeChoices, "Percentage")) {
      return <PercentageInput label="Spread Rate" name="spread_rate" />;
    }
    return <CurrencyInput label="Spread Rate" name="spread_rate" />;
  };

  const { number, bid_due_date, bid_start_date, min_increase, spread_type, spread_rate, notes } = auctionRound;
  const auction = props.auctionId;
  return (
    <Formik
      enableReinitialize
      initialValues={{
        number,
        bid_due_date,
        bid_start_date,
        spread_type,
        spread_rate,
        min_increase,
        notes,
        auction,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isEmpty(auctionRound)
          ? props.createAuctionRound
          : partial(props.editAuctionRound, auctionRound.id);

        try {
          await method(values);
          notifications.success("Round Detail saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing auction round");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Round Detail">
            <Form.Row>
              <TextInput label="Round #" name="number" placeholder="Enter round #" type="number" />
              <DateTimeInput label="Round Start Date" name="bid_start_date" isRequired />
              <DateTimeInput label="Round Due Date" name="bid_due_date" isRequired />
            </Form.Row>
            <Form.Row>
              <Select
                label="Spread Type"
                name="spread_type"
                placeholder="Select spread type"
                options={spreadTypeChoices}
                isRequired
                md={4}
              />
              {renderSpreadRate(values)}
              <CurrencyInput label="Minimum increase" name="min_increase" />
            </Form.Row>
            <Form.Row>
              <EditorField label="Notes" name="notes" />
            </Form.Row>
            <SubmitButton />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    auctionRound: activeAuctionRoundSelector(state) || {},
    auctionId: auctionIdSelector(state),
  };
};

export default connect(mapStateToProps, {
  createAuctionRound,
  editAuctionRound,
})(RoundForm);
