import Table from "components/table";
import React from "react";
import { activeMortalityAssessmentSelector } from "reducers";
import { connect } from "react-redux";

const HealthcareProvidersTable = ({ mortalityAssessmentInfo, ...props }) => {
  if (!mortalityAssessmentInfo) {
    // Still didn't fetch data
    return null;
  }

  const columns = [
    {
      Header: "Provider Name",
      accessor: "description",
    },
    {
      Header: "File Name",
      accessor: "pages",
    },
    {
      Header: "Provider Type",
      accessor: "file_size",
    },
  ];

  return (
    <Table
      columns={columns}
      data={mortalityAssessmentInfo.healthcare_providers || []}
      defaultSort={true}
      paginated={false}
      emptyMessage="No Healthcare Providers found"
    />
  );
};

const mapStateToProps = state => {
  return {
    mortalityAssessmentInfo: activeMortalityAssessmentSelector(state),
  };
};

export default connect(mapStateToProps, {})(HealthcareProvidersTable);
