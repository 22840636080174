import React from "react";
import { connect } from "react-redux";
import IconLink from "../IconLink";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { isAdmin } from "permissions";
import { showModal } from "actions";
import IconButton from "../IconButton";

/**
 Reusable component for rendering action icons (View, Edit, Delete) in React Table cells.
 The Edit & Delete icons are conditionally displayed based on user permissions.
 **/
const CellViewEditDelete = ({ to, id, deleteModal, selectAction, clearAction, ...props }) => {
  const iconConfig = { className: "table--action-icon" };

  return (
    <>
      <IconLink
        to={`${to}?view`}
        Icon={FaEye}
        iconConfig={iconConfig}
        tooltip="View Read-Only"
        onClick={() => {
          clearAction && clearAction();
          window.scrollTo(0, 0);
        }}
      />
      {(props.hasAdminPermission || props.editPermission) && (
        <IconLink
          to={to}
          Icon={FaEdit}
          iconConfig={iconConfig}
          tooltip="Edit"
          onClick={() => {
            clearAction && clearAction();
            window.scrollTo(0, 0);
          }}
        />
      )}
      {props.hasAdminPermission && (
        <IconButton
          Icon={FaTrash}
          iconConfig={{ className: "table--action-icon btn--red" }}
          tooltip="Delete"
          onClick={() => {
            selectAction && selectAction(id);
            props.showModal(deleteModal, { id });
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
})(CellViewEditDelete);
