import React from "react";
import { connect } from "react-redux";

import { hideModal } from "actions";
import { activeAccountIdSelector } from "reducers";
import { ACCOUNT_WARNING_UPDATE_HIERARCHY_MODAL } from "components/modals";
import ConfirmationModal from "components/modals/ConfirmationModal";

const WarningHierarchyModal = ({ values, method, ...props }) => {
  return (
    <ConfirmationModal
      modalType={ACCOUNT_WARNING_UPDATE_HIERARCHY_MODAL}
      onConfirm={async () => {
        values.confirmed = true;
        await method(values);
        props.hideModal(ACCOUNT_WARNING_UPDATE_HIERARCHY_MODAL);
      }}
    >
      There are cases using this Hierarchy - modifying it will apply the changes to all cases. Are you sure?
    </ConfirmationModal>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeAccountId: activeAccountIdSelector(state),
  };
};

export default connect(mapStateToProps, {
  hideModal,
})(WarningHierarchyModal);
