import React from "react";
import { connect } from "react-redux";
import { setActiveEmailTemplate, sendLeadEmail } from "actions";
import { emailTemplateSelector, leadsSelector, loginInfoSelector, selectedLeadsSelector } from "reducers";
import { EMAIL_TEMPLATE_TYPES } from "../../admin/constants";
import { SendEmailForm } from "components/forms";

const SendLeadEmailForm = props => {
  const getSelectedEmails = () => {
    if (props.selectedLeads) {
      let result = "";
      for (let id of props.selectedLeads) {
        let lead = props.leads[id];
        if (lead) {
          const leadName = `${lead.first_name || ""} ${lead.last_name || ""}`;
          result += leadName + " <" + (lead.email || "") + ">; ";
        }
      }
      return result;
    } else {
      return "";
    }
  };

  return (
    <SendEmailForm
      onSubmitAction={props.sendLeadEmail}
      emailTemplates={props.emailTemplates}
      getSelectedEmails={getSelectedEmails}
      loginInfo={props.loginInfo}
      selectedIds={props.selectedLeads}
      templateType="Leads"
      title="Send Lead Email"
    />
  );
};

const mapStateToProps = state => {
  const emailTemplates = emailTemplateSelector(state).filter(
    template => template.type === EMAIL_TEMPLATE_TYPES.LEAD && !template.name.includes("Lead Notification"),
  );

  return {
    loginInfo: loginInfoSelector(state),
    leads: leadsSelector(state),
    selectedLeads: selectedLeadsSelector(state),
    emailTemplates,
  };
};

export default connect(mapStateToProps, {
  setActiveEmailTemplate,
  sendLeadEmail,
})(SendLeadEmailForm);
