import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { editLifeExpectancy, updateLifeExpectancy } from "actions";
import { DateInput, FormCollapsible, GenderSelect, SubmitButton, TextInput, Select } from "components/forms";
import * as notifications from "../../../../notifications";
import AgeAssessedField from "../AgeAssessedField";
import { underwritingTypeChoices } from "../../../../constants";

const GenericLEProviderForm = ({ lifeExpectancyData, vendor, ...props }) => {
  let {
    first_name,
    last_name,
    gender,
    medical_info,
    date_of_birth,
    certificate_date,
    age,
    median,
    mortality_rating,
    underwriting_type,
  } = lifeExpectancyData;

  if (mortality_rating) {
    mortality_rating = Number(mortality_rating).toFixed(3);
  }

  const smokingOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
    { label: "Former Smoker", value: "Former Smoker" },
  ];
  if (!vendor) {
    vendor = "(No Vendor) ";
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        first_name,
        last_name,
        gender,
        medical_info,
        date_of_birth,
        certificate_date,
        age,
        median,
        mortality_rating,
        underwriting_type,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          await props.updateLifeExpectancy(lifeExpectancyData.id, values);
          await props.editLifeExpectancy(lifeExpectancyData.id);
          notifications.success(`${vendor} form saved successfully`);
        } catch (error) {
          setErrors(error.response.data);
          notifications.error(`Error saving ${vendor} form, please check form errors`);
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title={`${vendor} Life Expectancy`}>
            <Form.Row>
              <Select
                label="LE UW Type"
                name="underwriting_type" // ??
                placeholder="Select Underwriting Type"
                options={underwritingTypeChoices}
                isRequired
              />
            </Form.Row>
            <Form.Row>
              <TextInput label="First Name" name="first_name" isRequired />
              <TextInput label="Last Name" name="last_name" isRequired />
            </Form.Row>
            <Form.Row>
              <GenderSelect isRequired />
              <Select label="Smoking" name="medical_info.smoking" options={smokingOptions} />
            </Form.Row>
            <Form.Row>
              <DateInput label="Date of Birth" name="date_of_birth" isRequired />
              <DateInput label="Evaluation Date" name="certificate_date" isRequired />
              <AgeAssessedField label="Evaluation Age" {...values} />
            </Form.Row>
            <Form.Row>
              <TextInput label="Median LE" name="median" placeholder="Enter Median LE" prepend="Months" />
              <TextInput label="Mortality Multiplier" name="mortality_rating" placeholder="Enter %" append="%" />
            </Form.Row>
            <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state, ownProps) => {
  const lifeExpectancyData = ownProps.lifeExpectancy;
  const { id } = ownProps.lifeExpectancy;
  lifeExpectancyData.id = id;

  return {
    lifeExpectancyData,
  };
};

export default connect(mapStateToProps, { updateLifeExpectancy, editLifeExpectancy })(GenericLEProviderForm);
