import React from "react";
import { connect } from "react-redux";

import { showModal, selectAccountAffiliation } from "actions";
import { accountEntityChoicesSelector, activeAccountSelector } from "reducers";
import { ACCOUNT_AFFILIATION_MODAL, DELETE_ACCOUNT_AFFILIATION_MODAL } from "components/modals";
import Table, { choiceCell, notesCell } from "components/table";

import { ACCOUNT_AFFILIATION_STATUS_MAP, AFFILIATION_PRACTICE_TYPES_MAP } from "../../constants";
import IconLinkViewOrEdit from "components/icons/IconLinkViewOrEdit";
import IconLinkDelete from "components/icons/IconLinkDelete";

const AccountAffiliationTable = ({ activeAccount, ...props }) => {
  if (!activeAccount || !props.affiliations) {
    // Still didn't fetch data
    return null;
  }

  const columns = [
    {
      Header: "Action",
      className: "action",
      Cell: ({ row }) => {
        const id = row.original.id;
        return (
          <>
            <IconLinkViewOrEdit
              onClick={() => {
                props.selectAccountAffiliation(row.original);
                props.showModal(ACCOUNT_AFFILIATION_MODAL);
              }}
            />
            <IconLinkDelete
              onClick={() => {
                props.showModal(DELETE_ACCOUNT_AFFILIATION_MODAL, {
                  id: id,
                });
              }}
            />
          </>
        );
      },
    },
    {
      Header: "Entity Name",
      accessor: "entity",
      Cell: choiceCell(props.entityChoices),
    },
    {
      Header: "Practice Type",
      accessor: "practice_type",
      Cell: ({ row }) => {
        const type_value = row.original.practice_type;
        if (type_value !== null) {
          return AFFILIATION_PRACTICE_TYPES_MAP[type_value] || "";
        } else {
          return "";
        }
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const status_value = row.original.status;
        if (status_value !== null) {
          return ACCOUNT_AFFILIATION_STATUS_MAP[status_value] || "";
        } else {
          return "";
        }
      },
    },
    {
      Header: "Notes",
      accessor: "notes",
      Cell: notesCell,
    },
  ];

  return (
    <Table
      columns={columns}
      data={props.affiliations}
      defaultSort={true}
      paginated={false}
      emptyMessage="No Affiliations found"
      darker={true}
    />
  );
};

const mapStateToProps = state => {
  const activeAccount = activeAccountSelector(state);
  const accountEntityChoices = accountEntityChoicesSelector(state);
  let entityChoices = [];
  for (let acc of accountEntityChoices) {
    if (acc.practice_type) {
      entityChoices.push(acc);
    }
  }

  return {
    activeAccount,
    affiliations: activeAccount ? activeAccount.affiliations : [],
    entityChoices,
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectAccountAffiliation,
})(AccountAffiliationTable);
