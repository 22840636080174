import * as types from "actions/types";
import { removeKey } from "utils.js";
import { arrayToObjectIndexedById } from "../../utils";

export const defaultState = {
  persons: {},
  entities: {},
  insured: {},
  signers: {},
  activeOwner: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SELECT_OWNER:
      return { ...state, activeOwner: action.payload };

    case types.FETCH_OWNER_ENTITIES:
      return {
        ...state,
        entities: arrayToObjectIndexedById(action.payload),
      };

    case types.EDIT_OWNER_ENTITY:
    case types.CREATE_OWNER_ENTITY:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload,
        },
      };

    case types.DELETE_OWNER_ENTITY:
      return {
        ...state,
        entities: removeKey(state.entities, action.payload),
        signers: removeSignersOfEntity(state.signers, action.payload),
      };

    case types.FETCH_OWNER_PERSONS:
      return {
        ...state,
        persons: arrayToObjectIndexedById(action.payload),
      };

    case types.EDIT_OWNER_PERSON:
    case types.CREATE_OWNER_PERSON:
      return {
        ...state,
        persons: {
          ...state.persons,
          [action.payload.id]: action.payload,
        },
      };

    case types.DELETE_OWNER_PERSON:
      return {
        ...state,
        persons: removeKey(state.persons, action.payload),
      };

    case types.FETCH_OWNER_INSUREDS:
      return {
        ...state,
        insured: arrayToObjectIndexedById(action.payload),
      };

    case types.EDIT_OWNER_INSURED:
    case types.CREATE_OWNER_INSURED:
      return {
        ...state,
        insured: {
          ...state.insured,
          [action.payload.id]: action.payload,
        },
      };

    case types.DELETE_OWNER_INSURED:
      return {
        ...state,
        insured: removeKey(state.insured, action.payload),
      };

    case types.CREATE_OWNER_SIGNER:
      return {
        ...state,
        signers: {
          ...state.signers,
          [action.payload.id]: action.payload,
        },
      };

    case types.FETCH_OWNER_SIGNERS:
    case types.EDIT_OWNER_SIGNER:
      return {
        ...state,
        signers: { ...state.signers, ...arrayToObjectIndexedById(action.payload) },
      };

    case types.DELETE_OWNER_SIGNER:
      return {
        ...state,
        signers: removeKey(state.signers, action.payload),
      };

    case types.CLEAN_LIFE_SETTLEMENT_CASE_DATA:
      return { ...defaultState };

    default:
      return state;
  }
};

// Selectors
export const ownerListSelector = state => {
  return ownerPersonsSelector(state).concat(
    ownerEntitiesSelector(state),
    ownerInsuredListSelector(state),
  );
};

export const activeOwnerSelector = state => {
  const { activeOwner } = state;
  if (activeOwner === null) {
    return null;
  } else if (activeOwner.type === "undefined") {
    return { type: "undefined" };
  } else if (activeOwner.type === "entity") {
    return { ...ownerEntitySelector(state, activeOwner.id), type: "entity" };
  } else if (activeOwner.type === "person") {
    return { ...ownerPersonSelector(state, activeOwner.id), type: "person" };
  } else {
    return { ...ownerInsuredSelector(state, activeOwner.id), type: activeOwner.id };
  }
};

// single owners selectors
export const ownerPersonSelector = (state, id) => state.persons[id] || {};

export const ownerEntitySelector = (state, id) => state.entities[id] || {};

export const ownerInsuredSelector = (state, id) => state.insured[id] || {};

// multiple owners selectors

export const ownerInsuredListSelector = state => Object.values(state.insured);

export const ownerPersonsSelector = state => Object.values(state.persons);

export const ownerEntitiesSelector = state => Object.values(state.entities);

export const ownerSignersSelector = (state, entity_id) =>
  Object.values(state.signers).filter(signer => signer.entity_id === entity_id);

export const activeOwnerIdSelector = state => state.activeOwner && state.activeOwner.id;

export const numberOfSignersSelector = (state, entity_id) =>
  ownerSignersSelector(state, entity_id).length;

// Other
const removeSignersOfEntity = (signers, entity_id) => {
  signers = Object.values(signers).filter(signer => signer.entity !== entity_id);
  return arrayToObjectIndexedById(signers);
};
