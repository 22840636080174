import {
  CLEAN_LIFE_SETTLEMENT_CASE_DATA,

  // insured
  FETCH_INSURED,
  EDIT_INSURED_INFO,
  CREATE_EMPTY_INSURED_INFO,
  DELETE_INSURED_INFO,
  SELECT_INSURED,
} from "actions/types";
import { removeKey } from "utils.js";
import { arrayToObjectIndexedById } from "reducers/utils";

export const defaultState = {
  insured: {},
  activeInsuredId: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_INSURED:
      return { ...state, activeInsuredId: action.payload };

    case FETCH_INSURED:
      return { ...state, insured: arrayToObjectIndexedById(action.payload) };

    case EDIT_INSURED_INFO:
    case CREATE_EMPTY_INSURED_INFO:
      return {
        ...state,
        insured: {
          ...state.insured,
          [action.payload.id]: action.payload,
        },
      };

    case DELETE_INSURED_INFO:
      return {
        ...state,
        insured: removeKey(state.insured, action.payload),
      };

    case CLEAN_LIFE_SETTLEMENT_CASE_DATA:
      return { ...defaultState };

    default:
      return state;
  }
};

export const insuredListSelector = state => Object.values(state.insured);

export const insuredSelector = (state, id) => state.insured[id];

export const activeInsuredSelector = ({ activeInsuredId, insured }) => {
  if (activeInsuredId !== null) {
    return insured[activeInsuredId];
  }
  return null;
};

export const activeInsuredIdSelector = state => state.activeInsuredId;
