import React from "react";
import { connect } from "react-redux";

import { activeCarrierSelector } from "reducers";

import { pricingSetup } from "../../actions";
import CarrierFilesTable from "./CarrierFilesTable";

const CarrierFiles = ({ carrierInfo, ...props }) => {
  React.useEffect(() => {
    if (carrierInfo && carrierInfo.name) {
      document.title = `LifeRoc | ${carrierInfo.name} | Carrier Files`;
    }
  }, [carrierInfo]);

  return (
    <div style={{ marginTop: 100 }}>
      <CarrierFilesTable carrierInfo={carrierInfo} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    carrierInfo: activeCarrierSelector(state),
  };
};

export default connect(mapStateToProps, { pricingSetup })(CarrierFiles);
