import React from "react";
import { FaPlus } from "react-icons/fa";

export default ({ onClick, children, className, disabled, ...props }) => {
  return (
    <button className={`btn btn--secondary ${className}`} onClick={onClick} disabled={disabled} {...props}>
      <FaPlus />
      {children}
    </button>
  );
};
