import React from "react";

import FormCollapsible from "./FormCollapsible";

export default ({ onSubmit, children, title = "Filters" }) => {
  return (
    <FormCollapsible onSubmit={onSubmit} title={title}>
      {children}
    </FormCollapsible>
  );
};
