import React from "react";

import Table from "components/table";
import { FaDownload, FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { CARRIER_FILE_MODAL, DELETE_CARRIER_FILE_MODAL } from "components/modals";
import { connect } from "react-redux";
import { downloadFile, showModal } from "actions";
import IconLink from "../../components/IconLink";
import { selectCarrierFile } from "reducers/life_settlement/management/carriers";
import { isAdmin } from "permissions";

const CarrierFilesTable = ({ carrierInfo, hasAdminPermission, ...props }) => {
  if (!carrierInfo) {
    return null;
  }

  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const carrierFileId = row.original.id;
        const file = row.original.url;
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                props.selectCarrierFile(row.original);
                props.showModal(CARRIER_FILE_MODAL, {
                  id: carrierFileId,
                });
              }}
            />
            {hasAdminPermission && (
              <IconLink
                Icon={FaTrash}
                iconConfig={{ className: "table--action-icon btn--red" }}
                tooltip="Delete"
                onClick={() => {
                  props.showModal(DELETE_CARRIER_FILE_MODAL, {
                    id: carrierFileId,
                  });
                }}
              />
            )}
            {file ? (
              <>
                <IconLink
                  Icon={FaDownload}
                  iconConfig={{ className: "table--action-icon" }}
                  tooltip="Download"
                  onClick={() => {
                    const url = `/life_settlement/api/carrier_file/${carrierFileId}/download_file/`;
                    props.downloadFile(url, row.original.name);
                  }}
                />
                <IconLink
                  Icon={FaEye}
                  iconConfig={{ className: "table--action-icon" }}
                  tooltip="Preview"
                  onClick={() => window.open(row.original.url, "_blank")}
                />
              </>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Form Description",
      accessor: "form_description",
    },
    {
      Header: "File Type",
      accessor: "file_type",
    },
    {
      Header: "File Size",
      accessor: "size",
    },
    {
      Header: "Date Uploaded",
      accessor: "date_uploaded",
    },
  ];

  return (
    <Table columns={columns} data={carrierInfo.files} showPaginationResults={false} emptyMessage={"No Carrier Files"} />
  );
};

const mapStateToProps = state => {
  return {
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  downloadFile,
  selectCarrierFile,
})(CarrierFilesTable);
