import { connect } from "react-redux";

import { activeCaseSelector, marketAuctionActivitiesSelector } from "reducers";
import { calcWinningMarketBidValue } from "./utils";

const WinningMarketBidValue = props => calcWinningMarketBidValue(props.auction_activities);

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
    auction_activities: marketAuctionActivitiesSelector(state),
  };
};

export default connect(mapStateToProps)(WinningMarketBidValue);
