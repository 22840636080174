import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { SECURITY_INTERMEDIARY_MODAL } from "components/modals";
import SecurityIntermediaryForm from "./SecurityIntermediaryForm";

const SecurityIntermediaryModal = ({ ...props }) => {
  return (
    <FullPageModal modalType={SECURITY_INTERMEDIARY_MODAL}>
      <SecurityIntermediaryForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(SecurityIntermediaryModal);
