import React from "react";
import { Form } from "react-bootstrap";

import MedicalConditionsList from "./MedicalConditionsList";

// Main component
const MedicalCategory = ({ category, categoryIndex, removeCategory }) => {
  return (
    <Form.Row key={categoryIndex} className="tf-condition">
      <MedicalCategoryTitle category={category} removeFunc={removeCategory} />
      <MedicalConditionsList category={category} categoryIndex={categoryIndex} />
    </Form.Row>
  );
};

const MedicalCategoryTitle = ({ category, removeFunc }) => (
  <div className="tf-condition__info">
    <div className="tf-condition__info__name">
      <RemoveButton removeFunc={removeFunc} />
      {category.name}
    </div>
  </div>
);

export const RemoveButton = ({ removeFunc }) => (
  <button type="button" onClick={removeFunc} className="remove-item" title="Remove">
    -
  </button>
);

export default MedicalCategory;
