import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { EditorField, Select, SubmitButton, TextInput } from "components/forms";
import { activeStateSelector, shouldSaveSelector } from "reducers";
import { Form } from "react-bootstrap";
import { editState, unsetShouldSave, selectSynonym, showModal } from "actions";
import { partial } from "utils";
import * as notifications from "notifications";
import BooleanSelect from "../../components/forms/BooleanSelect";
import {
  brokerBondingEOChoices,
  brokerCeChoices,
  brokerCompensationChoices,
  brokerLifeLicensedChoices,
  brokerNotLifeLicensedChoices,
  brokerPlanOfOperationChoices,
  YesNoNaTbdChoices,
} from "./constants";

const StateDetailBrokerForm = ({ stateInfo, ...props }) => {
  if (!stateInfo) {
    // Still didn't fetch data
    return null;
  }

  const initialValues = {
    broker_state_website: stateInfo.broker_state_website,
    broker_state_license_search: stateInfo.broker_state_license_search,
    licensing_exemptions: stateInfo.licensing_exemptions,
    broker_ce: stateInfo.broker_ce,
    broker_forms_approval: stateInfo.broker_forms_approval,
    broker_anti_fraud_plan: stateInfo.broker_anti_fraud_plan,
    broker_reporting: stateInfo.broker_reporting,
    broker_compensation_disclosure: stateInfo.broker_compensation_disclosure,
    broker_seller_contract: stateInfo.broker_seller_contract,
    broker_notes: stateInfo.broker_notes,
    broker_plan_of_operation: stateInfo.broker_plan_of_operation,
    broker_bonding_eo: stateInfo.broker_bonding_eo,
    broker_licensing_timing_life_licensed: stateInfo.broker_licensing_timing_life_licensed,
    broker_licensing_timing_not_life_licensed: stateInfo.broker_licensing_timing_not_life_licensed,
  };

  const submitRef = React.useRef();

  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);

        const method = partial(props.editState, stateInfo.id);

        try {
          await method(values);
          notifications.success("State saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing State");
        }
        props.unsetShouldSave();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit} className={"form shadowed"} {...props}>
            <Form.Row>
              <TextInput label="Broker State Website" name="broker_state_website" md={6} />
              <TextInput label="Broker State License Search" name="broker_state_license_search" md={6} />
            </Form.Row>
            <Form.Row>
              <Select
                label="Attorney, CPA, CFA, Licensing Exemptions"
                name="licensing_exemptions"
                options={YesNoNaTbdChoices}
                md={3}
              />
              <Select label="Broker CE" name="broker_ce" options={brokerCeChoices} md={3} />
              <Select label="Broker Forms Approval" name="broker_forms_approval" options={YesNoNaTbdChoices} md={3} />
              <Select label="Broker Anti-Fraud Plan" name="broker_anti_fraud_plan" options={YesNoNaTbdChoices} md={3} />
            </Form.Row>
            <Form.Row>
              <Select label="Broker Reporting" name="broker_reporting" options={YesNoNaTbdChoices} md={3} />
              <Select label="Broker Bonding / E&O" name="broker_bonding_eo" options={brokerBondingEOChoices} md={3} />
              <Select label="Broker-Seller Contract" name="broker_seller_contract" options={YesNoNaTbdChoices} md={3} />
              <Select
                label="Broker Plan of Operation"
                name="broker_plan_of_operation"
                options={brokerPlanOfOperationChoices}
              />
            </Form.Row>
            <Form.Row>
              <Select
                label="Broker Compensation Disclosure"
                name="broker_compensation_disclosure"
                options={brokerCompensationChoices}
                md={6}
              />
            </Form.Row>
            <Form.Row>
              <Select
                label="Broker Licensing Timing: If Broker Is Life Licensed"
                name="broker_licensing_timing_life_licensed"
                options={brokerLifeLicensedChoices}
              />
              <Select
                label="Broker Licensing Timing: If Broker Is Not Life Licensed"
                name="broker_licensing_timing_not_life_licensed"
                options={brokerNotLifeLicensedChoices}
              />
            </Form.Row>
            <Form.Row>
              <EditorField label="Broker Licensing & Compliance" name="broker_notes" />
            </Form.Row>
            <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    shouldSave: shouldSaveSelector(state),
    stateInfo: activeStateSelector(state),
  };
};

export default connect(mapStateToProps, {
  editState,
  unsetShouldSave,
  selectSynonym,
  showModal,
})(StateDetailBrokerForm);
