import React from "react";
import { connect } from "react-redux";

import { BASIC_CREATE_ACCOUNT_MODAL } from "components/modals";
import { activeAccountSelector } from "reducers";
import FullPageModal from "../../components/modals/FullPageModal";
import { selectAccountCaseHierarchy } from "../../actions";
import BasicCreateAccountModalForm from "./information/BasicCreateAccountModalForm";

const BasicCreateAccountModal = ({ entityId, accountType, ...props }) => {
  return (
    <FullPageModal modalType={BASIC_CREATE_ACCOUNT_MODAL}>
      <BasicCreateAccountModalForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    accountInfo: activeAccountSelector(state),
  };
};

export default connect(mapStateToProps, {
  selectAccountCaseHierarchy,
})(BasicCreateAccountModal);
