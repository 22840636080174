import React from "react";
import { connect } from "react-redux";

import { DateTimeInput, Select, TextInput } from "components/forms";
import { activeParameterSelector, shouldSaveSelector } from "reducers";
import { Form } from "react-bootstrap";
import { editParameter, unsetShouldSave } from "actions";
import {
  PARAMETER_CATEGORY_CHOICES,
  PARAMETER_SUB_CATEGORY_CHOICES,
  PARAMETER_SUB_CATEGORY_CHOICES__CONDITIONS,
  PARAMETER_SUB_CATEGORY_CHOICES__PROFILES,
  PARAMETER_SUB_CATEGORY_CHOICES__TREATMENTS,
} from "../constants";

const ParameterDetailOverviewForm = ({ parameterInfo, ...props }) => {
  if (!parameterInfo) {
    // Still didn't fetch data
    return null;
  }
  let [parameterSubChoices, setParameterChoices] = React.useState(PARAMETER_SUB_CATEGORY_CHOICES);

  return (
    <>
      <Form.Row>
        <Select
          label="Health Param Category"
          name="category"
          placeholder="Select Category"
          options={PARAMETER_CATEGORY_CHOICES}
          onChangeCallback={option => {
            if (option?.value === "CONDITION") {
              setParameterChoices(PARAMETER_SUB_CATEGORY_CHOICES__CONDITIONS);
            } else if (option?.value === "PROFILE") {
              setParameterChoices(PARAMETER_SUB_CATEGORY_CHOICES__PROFILES);
            } else if (option?.value === "TREATMENT") {
              setParameterChoices(PARAMETER_SUB_CATEGORY_CHOICES__TREATMENTS);
            } else {
              setParameterChoices(PARAMETER_SUB_CATEGORY_CHOICES);
            }
          }}
        />
        <Select
          label="Health Param Sub-Category"
          name="sub_category"
          placeholder="Select Sub-Category"
          options={parameterSubChoices}
        />
        <TextInput label="Health Param Name" name="name" />
        <TextInput label="Health Param Code" name="code" disabled />
      </Form.Row>
      <Form.Row>
        <DateTimeInput label="Create Date" name="date_created" disabled />
        <DateTimeInput label="Last Edit Date" name="last_edit_date" disabled />
        <TextInput label="Created by" name="created_by_name" disabled />
        <TextInput label="Last Edited By" name="last_edited_by_name" disabled />
      </Form.Row>
      <hr />
    </>
  );
};

const mapStateToProps = state => {
  return {
    shouldSave: shouldSaveSelector(state),
    parameterInfo: activeParameterSelector(state),
  };
};

export default connect(mapStateToProps, {
  editParameter,
  unsetShouldSave,
})(ParameterDetailOverviewForm);
