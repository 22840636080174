import * as types from "actions/types";
import advisorhq from "apis/advisorhq";
import { activeAgencySelector } from "reducers";

export const fetchManagerAgents = () => async (dispatch, getState) => {
  const agencyId = activeAgencySelector(getState()).id;
  if (!agencyId) {
    return null;
  }
  const response = await advisorhq.get(`/api/agents/${agencyId}/`);
  dispatch({ type: types.FETCH_AGENTS_CHOICES, payload: response.data });
};
