import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { Form, Col } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import Label from "./Label";
import FieldErrors from "./FieldErrors";

const components = {
  DropdownIndicator: null,
};

const createOption = label => ({
  label,
  value: label,
});

export default function MultiSelectTextInput({ label, options = [], emptyValue = "", md = "", ...props }) {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]);

  const handleKeyDown = event => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue(prev => [...prev, createOption(inputValue)]);
        setFieldValue(field.name, [...value.map(option => option.value), inputValue]);
        setInputValue("");
        event.preventDefault();
    }
  };

  const handleChange = newValue => {
    setFieldValue(
      field.name,
      newValue.map(option => option.value),
    );
    return setValue(newValue);
  };

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      {label && <Label label={label} required={props.isRequired} />}
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={newValue => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder="Type and press enter..."
        value={value}
      />
      <FieldErrors meta={meta} />
    </Form.Group>
  );
}
