import React from "react";
import { connect } from "react-redux";

import { URLS } from "../../constants";
import TopHeaderTabs from "../../components/TopHeaderTabs";

const AdminPageHeader = ({ ...props }) => {
  const tabs = [
    { link: URLS.ADMIN_EMAILS, label: "Emails" },
    { link: URLS.ADMIN_USERS, label: "Users" },
  ];

  return <TopHeaderTabs tabs={tabs} />;
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps, {})(AdminPageHeader);
