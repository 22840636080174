import React from "react";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";

import { showModal } from "actions";
import { CONFIRM_FILE_REPLACE_MODAL } from "components/modals";
import File from "./File";
import * as notifications from "notifications.js";

const DropZone = ({ uploadFile, currentFile, accept, ...props }) => {
  let message = props.message || "Drag and drop file or click to select files";
  const onDrop = React.useCallback(acceptedFiles => {
    const droppedFile = acceptedFiles[0];
    if (!droppedFile) {
      notifications.error(`Invalid file! Accepted formats: ${accept}`);
    }

    const isEmail = droppedFile.name.endsWith("msg");
    if (isEmail) {
      props.onEmailDrop(droppedFile);
    } else {
      if (currentFile !== null) {
        props.showModal(CONFIRM_FILE_REPLACE_MODAL, {
          onConfirm: () => uploadFile(droppedFile),
        });
      } else {
        if (droppedFile.size > 300 * 1024 * 1024) {
          notifications.error(`File size must be below 300 MB`);
        } else {
          uploadFile(droppedFile);
        }
      }
    }
  });

  let options = {
    onDrop,
    maxFiles: 1,
  };
  if (accept) {
    options.accept = accept;
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone(options);

  const getClassNames = () => {
    let className = "dropzone";
    if (isDragActive) {
      className += " active";
    }
    return className;
  };

  const renderMainComponent = () => {
    return (
      <div {...getRootProps({ className: getClassNames() })}>
        <input {...getInputProps()} />
        <div>{message}</div>
      </div>
    );
  };

  return (
    <section className="container">
      {renderMainComponent()}
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <File
          file={currentFile}
          uploadFile={uploadFile}
          deleteFile={props.deleteFile}
          fileDownloadUrl={props.fileDownloadUrl}
        />
      </div>
    </section>
  );
};

export default connect(null, { showModal })(DropZone);
