import React from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as notifications from "notifications";

import { LOADING_MODAL, QUEST_HEALTH_REQUEST_MODAL } from "components/modals";
import {
  fetchInsured,
  hideModal,
  fetchPolicy,
  fetchCaseFilesByCaseDocumentId,
  generateQuestHealthRequest,
  showModal,
} from "actions";
import {
  activeCaseFileSelector,
  loadingSelector,
  insuredListSelector,
  activeCaseSelector,
  policySelector,
} from "reducers";
import { Form } from "react-bootstrap";
import {
  FormCollapsible,
  TextInput,
  GenderSelect,
  DateInput,
  StateSelect,
  PhoneInput,
  SubmitButton,
} from "components/forms";

const QuestHealthRequestForm = ({ ...props }) => {
  React.useEffect(() => {
    if (props.caseInfo.policy) {
      props.fetchPolicy(props.caseInfo.policy);
    }
  }, [props.caseInfo.id]);

  let activeInsured;
  if (props.activeCaseFile.category === "Insured 2") {
    activeInsured = props.insuredList[1];
  } else {
    activeInsured = props.insuredList[0];
  }
  let initialValues = {
    policy_number: props.policy?.policy_number,
    last_name: activeInsured.last_name,
    first_name: activeInsured.first_name,
    gender: activeInsured.gender,
    birth_date: activeInsured.date_of_birth,
    city: activeInsured.city,
    state: activeInsured.state,
    street_address: activeInsured.street_address,
    zip: activeInsured.zipcode,
    phone: activeInsured.home_phone,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, setErrors, setFieldError }) => {
        setSubmitting(true);
        try {
          props.showModal(LOADING_MODAL, { message: "Connecting with ExamOne, this might take a few seconds..." });
          await props.generateQuestHealthRequest(props.activeCaseFile.id, props.caseInfo.id, values);
          notifications.success("Quest Health Request started - we'll email you when its ready");
          props.hideModal(QUEST_HEALTH_REQUEST_MODAL);
          props.fetchInsured(props.caseInfo.id);
          props.fetchPolicy(props.caseInfo.policy);
          props.fetchCaseFilesByCaseDocumentId(props.activeCaseFile.id);
        } catch (error) {
          notifications.error(`Error Generating Quest Health Request`);
          setErrors(error.response.data);
        } finally {
          props.hideModal(LOADING_MODAL);
        }
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Information Review">
            <Form.Row>
              <TextInput label="Policy Number" name="policy_number" isRequired />
            </Form.Row>
            <Form.Row>
              <TextInput label="Last Name" name="last_name" isRequired />
              <TextInput label="First Name" name="first_name" isRequired />
              <GenderSelect isRequired />
            </Form.Row>
            <Form.Row>
              <DateInput label="Date of Birth" name="birth_date" isRequired />
              <TextInput label="City" name="city" />
              <TextInput label="Street Address" name="street_address" />
            </Form.Row>
            <Form.Row>
              <PhoneInput label="Phone" name="phone" isRequired />
              <StateSelect />
              <TextInput label="Zip Code" name="zip" />
            </Form.Row>
            <SubmitButton
              defaultText="Start Request"
              submittingText="Connecting..."
              style={{
                width: "93%",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
    policy: policySelector(state),
    activeCaseFile: activeCaseFileSelector(state),
    insuredList: insuredListSelector(state),
    loading: loadingSelector(state),
  };
};

export default connect(mapStateToProps, {
  generateQuestHealthRequest,
  fetchPolicy,
  fetchInsured,
  fetchCaseFilesByCaseDocumentId,
  showModal,
  hideModal,
})(QuestHealthRequestForm);
