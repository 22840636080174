import React from "react";
import { connect } from "react-redux";

import { selectLead, clearSelectedLead } from "actions";
import { compose } from "redux";
import withRouter from "../withRouter";
import LeadsTopHeader from "./LeadsTopHeader";
import { Outlet } from "react-router-dom";

/* Data component: fetch relevant data for the current lead */
const LeadsSetup = ({ leadId, ...props }) => {
  const fetchData = () => {
    props.selectLead(leadId);
  };

  React.useEffect(() => {
    props.clearSelectedLead();
    fetchData();
  }, []);

  return (
    <div>
      <LeadsTopHeader />
      <Outlet />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const leadId = ownProps.params.id;

  return {
    leadId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    selectLead,
    clearSelectedLead,
  }),
)(LeadsSetup);
