import React from "react";

import PlacementRevenueValue from "./PlacementRevenueValue";
import { REVENUE_MODEL_CHOICES } from "../../../../constants";

const RenderPlacementRevenueValue = props => {
  if (props.caseInfo) {
    if (
      props.revenueModel === REVENUE_MODEL_CHOICES[1].label ||
      props.revenueModel === REVENUE_MODEL_CHOICES[2].label
    ) {
      return <PlacementRevenueValue caseInfo={props.caseInfo} />;
    } else {
      return "---";
    }
  }
  return "---";
};

export default RenderPlacementRevenueValue;
