import { connect } from "react-redux";

import { funderAuctionActivitiesSelector, marketAuctionActivitiesSelector } from "reducers";
import { moneyDisplay, revertMoneyDisplay } from "../../../../utils";
import { calcWinningFunderBidValue, calcWinningMarketBidValue } from "./utils";

const HighestBidValue = props => {
  const funderBidValue = revertMoneyDisplay(calcWinningFunderBidValue(props.funder_auction_activities));
  const marketBidValue = revertMoneyDisplay(calcWinningMarketBidValue(props.market_auction_activities));
  return moneyDisplay(Math.max(funderBidValue || 0, marketBidValue || 0));
};

const mapStateToProps = state => {
  return {
    funder_auction_activities: funderAuctionActivitiesSelector(state).filter(
      activity => activity.type === "B" || activity.type === "BC",
    ),
    market_auction_activities: marketAuctionActivitiesSelector(state),
  };
};

export default connect(mapStateToProps)(HighestBidValue);
