import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { CLINICAL_RESEARCH_MODAL } from "components/modals";
import ClinicalResearchForm from "./ClinicalResearchForm";

const ClinicalResearchModal = ({ ...props }) => {
  return (
    <FullPageModal modalType={CLINICAL_RESEARCH_MODAL}>
      <ClinicalResearchForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(ClinicalResearchModal);
