import React from "react";
import { connect } from "react-redux";

import { activeFunderAuctionActivitySelector } from "reducers";
import FullPageModal from "components/modals/FullPageModal";
import { AUCTION_FUNDER_ACTIVITY_MODAL } from "components/modals";
import FunderActivityForm from "./FunderActivityForm";
import { deleteFunderAuctionActivityFile, uploadFunderAuctionActivityFile } from "actions";

const FunderActivityModal = ({ funderId = null, ...props }) => {
  let title = "Funder Activity Detail";
  if (!props.auctionActivity.id) {
    title = "New Funder Activity Detail";
  }
  return (
    <FullPageModal modalType={AUCTION_FUNDER_ACTIVITY_MODAL} title={title}>
      <FunderActivityForm funderId={funderId} />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    auctionActivity: activeFunderAuctionActivitySelector(state) || {},
  };
};

export default connect(mapStateToProps, {
  uploadFunderAuctionActivityFile,
  deleteFunderAuctionActivityFile,
})(FunderActivityModal);
