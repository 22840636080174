import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { ACCOUNT_HIERARCHY_SPLIT_MODAL } from "components/modals";
import { activeSplitSelector } from "reducers";
import HierarchySplitForm from "./HierarchySplitForm";

const HierarchySplitModal = ({ activeSplit, ...props }) => {
  let isSaved = activeSplit && activeSplit.id;
  const submitRef = React.useRef();
  let title = isSaved ? "Edit Split" : "New Split";

  return (
    <FullPageModal modalType={ACCOUNT_HIERARCHY_SPLIT_MODAL} title={title} style={{ content: { overflow: "visible" } }}>
      <HierarchySplitForm submitRef={submitRef} />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    activeSplit: activeSplitSelector(state) || {},
  };
};

export default connect(mapStateToProps, {})(HierarchySplitModal);
