import * as types from "../types";
import advisorhq from "../../apis/advisorhq";
import * as notifications from "../../notifications";
import {
  activeAccountIdSelector,
  activeAgreementSelector,
  activeLeadIdSelector,
  activeLicenseSelector,
  sortBySelector,
} from "../../reducers";
import { DELETE_ACCOUNT, SET_ACCOUNTS_PAGINATION_DATA } from "../types";
import {
  ACCOUNT_HIERARCHY_SPLIT_MODAL,
  BASIC_CREATE_ACCOUNT_MODAL,
  CREATE_ACCOUNT_MODAL,
  MANAGE_ENTITY_MODAL,
  SEND_ACCOUNT_EMAIL_MODAL,
} from "../../components/modals";
import { hideModal } from "../modals";
import { redirectTo, sortByParam } from "../../utils";

export const selectAccount = id => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_ACCOUNT, payload: id });
  const response = await advisorhq.get("/api/account/" + id + "/");
  dispatch({ type: types.FETCH_ACCOUNT, payload: response.data });
};

export const fetchAccount = id => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { account: true } });
  const response = await advisorhq.get("/api/account/" + id + "/");
  dispatch({ type: types.FETCH_ACCOUNT, payload: response.data });
  dispatch({ type: types.LOADING, payload: { account: false } });
};

export const clearAccountsList = () => async dispatch => {
  dispatch({ type: types.FETCH_ACCOUNTS, payload: [] });
};

export const clearSelectedAccount = () => async dispatch => {
  dispatch({ type: types.CLEAR_SELECTED_ACCOUNT });
};

export const clearSelectedAccountHierarchy = () => async dispatch => {
  dispatch({ type: types.CLEAR_ACCOUNT_HIERARCHY });
};

export const selectManageEntity = id => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_MANAGE_ENTITY, payload: id });
  const response = await advisorhq.get("/api/account/" + id + "/");
  dispatch({ type: types.FETCH_ACCOUNT, payload: response.data });
};

export const selectAccountCaseHierarchy = id => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { accountHierarchies: true } });
  dispatch({ type: types.SELECT_ACCOUNT, payload: id });
  const response = await advisorhq.get("/api/account/" + id + "/");
  dispatch({ type: types.FETCH_ACCOUNT, payload: response.data });
  dispatch({ type: types.LOADING, payload: { accountHierarchies: false } });
};

export const selectAccountAffiliation = data => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_ACCOUNT_AFFILIATION, payload: data });
};

export const selectAccountCarrierAppointment = data => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_ACCOUNT_CARRIER_APPOINTMENT, payload: data });
};

export const selectAccountLicense = data => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_ACCOUNT_LICENSE, payload: data });
};

export const selectAccountAgreement = data => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_ACCOUNT_AGREEMENT, payload: data });
};

export const selectAccountHierarchy = data => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_ACCOUNT_HIERARCHY, payload: data });
};

export const selectSplit = data => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_SPLIT, payload: data });
};

export const createAccountTask = values => async (dispatch, getState) => {
  const url = "/api/account_task/";
  try {
    const response = await advisorhq.post(url, values);
    dispatch({ type: types.CREATE_ACCOUNT_TASK, payload: response.data });
    notifications.success("A new Task was created");

    const activeAccountId = activeAccountIdSelector(getState());
    const activeAccountResponse = await advisorhq.get("/api/account/" + activeAccountId + "/");
    dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
  } catch (e) {
    notifications.error("Error creating Task");
  }
};

export const editAccountTask = (taskId, newValues, row) => async (dispatch, getState) => {
  notifications.success("Saving...");
  dispatch({ type: types.LOADING, payload: { tasks: true } });
  const endpoint = `/api/account_task/${taskId}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  dispatch({ type: types.EDIT_ACCOUNT_TASK, payload: response.data });

  const activeAccountId = activeAccountIdSelector(getState());
  if (activeAccountId) {
    const activeAccountResponse = await advisorhq.get("/api/account/" + activeAccountId + "/");
    dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
  }
};

export const createAccountCarrierAppointment = values => async (dispatch, getState) => {
  const url = "/api/carrier_appointment/";
  try {
    const response = await advisorhq.post(url, values);
    dispatch({ type: types.CREATE_ACCOUNT_CARRIER_APPOINTMENT, payload: response.data });
    notifications.success("A new Carrier Appointment was created");

    const activeAccountId = activeAccountIdSelector(getState());
    const activeAccountResponse = await advisorhq.get("/api/account/" + activeAccountId + "/");
    dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
  } catch (e) {
    notifications.error("Error creating Carrier Appointment");
  }
};

export const editAccountCarrierAppointment = (id, newValues, row) => async (dispatch, getState) => {
  notifications.success("Saving...");
  dispatch({ type: types.LOADING, payload: { tasks: true } });
  const endpoint = `/api/carrier_appointment/${id}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  notifications.clearAll();
  notifications.success("Carrier Appointment saved successfully");
  dispatch({ type: types.EDIT_ACCOUNT_CARRIER_APPOINTMENT, payload: response.data });

  const activeAccountId = activeAccountIdSelector(getState());
  const activeAccountResponse = await advisorhq.get("/api/account/" + activeAccountId + "/");
  dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
};

export const createAccountLicense = values => async (dispatch, getState) => {
  const url = "/api/license/";
  try {
    const response = await advisorhq.post(url, values);
    dispatch({ type: types.CREATE_ACCOUNT_LICENSE, payload: response.data });
    notifications.success("A new License was created");

    const activeAccountId = activeAccountIdSelector(getState());
    const activeAccountResponse = await advisorhq.get("/api/account/" + activeAccountId + "/");
    dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
  } catch (e) {
    notifications.error("Error creating License");
  }
};

export const editAccountLicense = (id, newValues, row) => async (dispatch, getState) => {
  notifications.success("Saving...");
  dispatch({ type: types.LOADING, payload: { tasks: true } });
  const endpoint = `/api/license/${id}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  notifications.success("License saved successfully");
  dispatch({ type: types.EDIT_ACCOUNT_LICENSE, payload: response.data });

  const activeAccountId = activeAccountIdSelector(getState());
  const activeAccountResponse = await advisorhq.get("/api/account/" + activeAccountId + "/");
  dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
};

export const uploadLicenseFile = file => async (dispatch, getState) => {
  notifications.success("Uploading License File...", file);
  const state = getState();
  const activeAccountLicense = activeLicenseSelector(state);
  const formData = new FormData();
  formData.append("id", activeAccountLicense.id);
  formData.append("file", file, file.name);

  try {
    const response = await advisorhq.post(`/api/upload_license_file/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({ type: types.EDIT_ACCOUNT_LICENSE, payload: response.data });

    const activeAccountId = activeAccountIdSelector(getState());
    const activeAccountResponse = await advisorhq.get("/api/account/" + activeAccountId + "/");
    dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
    notifications.success("License File updated successfully");
  } catch (error) {
    notifications.error("Error creating new License File");
    console.error(error);
  }
};

export const createAccountAgreement = values => async (dispatch, getState) => {
  const url = "/api/agreement/";
  try {
    const response = await advisorhq.post(url, values);
    dispatch({ type: types.CREATE_ACCOUNT_AGREEMENT, payload: response.data });
    notifications.success("A new Agreement was created");

    const activeAccountId = activeAccountIdSelector(getState());
    const activeAccountResponse = await advisorhq.get("/api/account/" + activeAccountId + "/");
    dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
  } catch (e) {
    notifications.error("Error creating Agreement");
  }
};

export const editAccountAgreement = (id, newValues, row) => async (dispatch, getState) => {
  notifications.success("Saving...");
  dispatch({ type: types.LOADING, payload: { tasks: true } });
  const endpoint = `/api/agreement/${id}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  notifications.success("Agreement saved successfully");
  dispatch({ type: types.EDIT_ACCOUNT_AGREEMENT, payload: response.data });

  const activeAccountId = activeAccountIdSelector(getState());
  const activeAccountResponse = await advisorhq.get("/api/account/" + activeAccountId + "/");
  dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
};

export const uploadAgreementFile = file => async (dispatch, getState) => {
  notifications.success("Uploading Agreement File...", file);
  const state = getState();
  const activeAccountAgreement = activeAgreementSelector(state);
  const formData = new FormData();
  formData.append("id", activeAccountAgreement.id);
  formData.append("file", file, file.name);

  try {
    const response = await advisorhq.post(`/api/upload_agreement_file/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({ type: types.EDIT_ACCOUNT_AGREEMENT, payload: response.data });

    const activeAccountId = activeAccountIdSelector(getState());
    const activeAccountResponse = await advisorhq.get("/api/account/" + activeAccountId + "/");
    dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
    notifications.success("Agreement File updated successfully");
  } catch (error) {
    notifications.error("Error creating new Agreement file");
    console.error(error);
  }
};

export const getAccountHierarchy = id => async dispatch => {
  const url = `/api/hierarchy/${id}`;
  const response = await advisorhq.get(url);
  dispatch({ type: types.SELECT_ACCOUNT_HIERARCHY, payload: response.data });
};

export const createAccountHierarchy = values => async (dispatch, getState) => {
  const url = "/api/hierarchy/";
  const response = await advisorhq.post(url, values);
  dispatch({ type: types.CREATE_ACCOUNT_HIERARCHY, payload: response.data });
  notifications.success("A new Hierarchy was created");

  const activeAccountId = activeAccountIdSelector(getState());
  const activeAccountResponse = await advisorhq.get("/api/account/" + activeAccountId + "/");
  dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
};

export const editAccountHierarchy = (id, newValues, row) => async (dispatch, getState) => {
  notifications.success("Saving...");
  dispatch({ type: types.LOADING, payload: { tasks: true } });
  const endpoint = `/api/hierarchy/${id}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  notifications.success("Hierarchy saved successfully");
  dispatch({ type: types.EDIT_ACCOUNT_HIERARCHY, payload: response.data });

  const activeAccountId = activeAccountIdSelector(getState());
  const activeAccountResponse = await advisorhq.get("/api/account/" + activeAccountId + "/");
  dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
};

export const editAccountHierarchyAsASplit = (newValues, row) => async (dispatch, getState) => {
  const endpoint = `/api/hierarchy/${newValues.id}/`;
  const response = await advisorhq.patch(endpoint, newValues);

  // Update current hierarchy with its splits
  dispatch({ type: types.EDIT_ACCOUNT_HIERARCHY, payload: response.data });

  // Update account data (hierarchy list)
  const activeAccountId = activeAccountIdSelector(getState());
  const activeAccountResponse = await advisorhq.get("/api/account/" + activeAccountId + "/");
  dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
  notifications.success("Split saved successfully");
};

export const createAccountHierarchySplit = values => async (dispatch, getState) => {
  const url = "/api/split/";
  await advisorhq.post(url, values);

  // Update current hierarchy with its splits
  const hierarchy_url = `/api/hierarchy/${values.hierarchyId}/`;
  const response = await advisorhq.get(hierarchy_url);
  dispatch({ type: types.SELECT_ACCOUNT_HIERARCHY, payload: response.data });

  // Update account data (hierarchy list)
  const activeAccountId = activeAccountIdSelector(getState());
  const activeAccountResponse = await advisorhq.get("/api/account/" + activeAccountId + "/");
  dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
  notifications.success("A new Split was created");
  dispatch(hideModal(ACCOUNT_HIERARCHY_SPLIT_MODAL));
};

export const editAccountHierarchySplit = (splitId, newValues, c, d) => async (dispatch, getState) => {
  const url = `/api/split/${splitId}/`;
  await advisorhq.patch(url, newValues);

  // Update current hierarchy with its splits
  const hierarchy_url = `/api/hierarchy/${newValues.hierarchyId}/`;
  const response = await advisorhq.get(hierarchy_url);
  dispatch({ type: types.SELECT_ACCOUNT_HIERARCHY, payload: response.data });

  // Update account data (hierarchy list)
  const activeAccountId = activeAccountIdSelector(getState());
  const activeAccountResponse = await advisorhq.get("/api/account/" + activeAccountId + "/");
  dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
  notifications.success("Split saved successfully");
};

export const createAccountAffiliation = values => async (dispatch, getState) => {
  const url = "/api/affiliation/";
  try {
    const response = await advisorhq.post(url, values);
    dispatch({ type: types.CREATE_ACCOUNT_AFFILIATION, payload: response.data });
    notifications.success("A new Affiliation was created");

    const activeAccountId = activeAccountIdSelector(getState());
    const activeAccountResponse = await advisorhq.get("/api/account/" + activeAccountId + "/");
    dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
  } catch (e) {
    notifications.error("Error creating Affiliation");
  }
};

export const editAccountAffiliation = (id, newValues, row) => async (dispatch, getState) => {
  notifications.success("Saving...");
  dispatch({ type: types.LOADING, payload: { tasks: true } });
  const endpoint = `/api/affiliation/${id}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  notifications.success("Affiliation saved successfully");
  dispatch({ type: types.EDIT_ACCOUNT_AFFILIATION, payload: response.data });

  const activeAccountId = activeAccountIdSelector(getState());
  const activeAccountResponse = await advisorhq.get("/api/account/" + activeAccountId + "/");
  dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
};

export const deleteAccountTask = id => async (dispatch, getState) => {
  try {
    await advisorhq.delete(`/api/account_task/${id}/`);
  } catch (e) {
    notifications.error(e.response.data.error);
  }
  const activeAccountId = activeAccountIdSelector(getState());
  const activeAccountResponse = await advisorhq.get(`/api/account/${activeAccountId}/`);
  dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
};

export const deleteAccountCarrierAppointment = id => async (dispatch, getState) => {
  try {
    await advisorhq.delete(`/api/carrier_appointment/${id}/`);
  } catch (e) {
    notifications.error(e.response.data.error);
  }
  const activeAccountId = activeAccountIdSelector(getState());
  const activeAccountResponse = await advisorhq.get(`/api/account/${activeAccountId}/`);
  dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
};

export const deleteAccountLicense = id => async (dispatch, getState) => {
  try {
    await advisorhq.delete(`/api/license/${id}/`);
  } catch (e) {
    notifications.error(e.response.data.error);
  }
  const activeAccountId = activeAccountIdSelector(getState());
  const activeAccountResponse = await advisorhq.get(`/api/account/${activeAccountId}/`);
  dispatch({ type: types.FETCH_ACCOUNT, payload: activeAccountResponse.data });
};

export const deleteAccountAgreement = id => async (dispatch, getState) => {
  try {
    await advisorhq.delete(`/api/agreement/${id}/`);
  } catch (e) {
    notifications.error(e.response.data.error);
  }
  const activeAccountId = activeAccountIdSelector(getState());
  const response = await advisorhq.get(`/api/account/${activeAccountId}/`);
  dispatch({ type: types.FETCH_ACCOUNT, payload: response.data });
};

export const deleteAccountHierarchy = id => async (dispatch, getState) => {
  try {
    await advisorhq.delete(`/api/hierarchy/${id}/`);
  } catch (e) {
    notifications.error(e.response.data.error);
  }
  const activeAccountId = activeAccountIdSelector(getState());
  const response = await advisorhq.get(`/api/account/${activeAccountId}/`);
  dispatch({ type: types.FETCH_ACCOUNT, payload: response.data });
};

export const deleteAccountHierarchySplit = (id, hierarchyId) => async (dispatch, getState) => {
  try {
    await advisorhq.delete(`/api/split/${id}/`);
  } catch (e) {
    notifications.error(e.response.data.error);
  }
  // Update Hierarchy
  const hierarchy_url = `/api/hierarchy/${hierarchyId}/`;
  const response = await advisorhq.get(hierarchy_url);
  dispatch({ type: types.SELECT_ACCOUNT_HIERARCHY, payload: response.data });

  // Update account data (hierarchy list)
  const activeAccountId = activeAccountIdSelector(getState());
  const accountResponse = await advisorhq.get(`/api/account/${activeAccountId}/`);
  dispatch({ type: types.FETCH_ACCOUNT, payload: accountResponse.data });
};

export const deleteAccountAffiliation = id => async (dispatch, getState) => {
  try {
    await advisorhq.delete(`/api/affiliation/${id}/`);
  } catch (e) {
    notifications.error(e.response.data.error);
  }
  const activeAccountId = activeAccountIdSelector(getState());
  const response = await advisorhq.get(`/api/account/${activeAccountId}/`);
  dispatch({ type: types.FETCH_ACCOUNT, payload: response.data });
};

export const fetchAccounts = filters => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { accounts: true } });
  const sort_by = sortBySelector(getState());
  const sort_by_value = sortByParam(sort_by);
  const response = await advisorhq.get("/api/account/", {
    params: {
      ...filters,
      page: 1,
      sort_by: sort_by_value,
    },
  });
  let { results, ...paginateData } = response.data;
  paginateData = { ...paginateData, page: 1 };
  dispatch({ type: SET_ACCOUNTS_PAGINATION_DATA, payload: paginateData });
  dispatch({ type: types.FETCH_ACCOUNTS, payload: results });
  dispatch({ type: types.LOADING, payload: { accounts: false } });
};

export const createBasicAccount = payload => async (dispatch, getState) => {
  const url = "/api/account/";
  try {
    const response = await advisorhq.post(url, payload);
    fetchAccountEntityChoices();
    dispatch(hideModal(BASIC_CREATE_ACCOUNT_MODAL));
    dispatch({ type: types.CREATE_ACCOUNT, payload: response.data });
    window.scrollTo(0, 0);
    redirectTo(`/accounts/${response.data.id}/information/`);
  } catch (e) {
    notifications.error("Error creating Account");
  }
};

export const createAccount = payload => async (dispatch, getState) => {
  const url = "/api/account/";
  try {
    await advisorhq.post(url, payload);
    fetchAccountEntityChoices();
    dispatch(hideModal(CREATE_ACCOUNT_MODAL));
  } catch (e) {
    notifications.error("Error creating Team Member");
  }
};

export const createEmptyAccount = () => async (dispatch, getState) => {
  const url = "/api/account/";
  try {
    const response = await advisorhq.post(url, {});
    dispatch({ type: types.CREATE_ACCOUNT, payload: response.data });
    notifications.success("A new Account was created");
    window.scrollTo(0, 0);
    redirectTo(`/accounts/${response.data.id}/information/`);
  } catch (e) {
    notifications.error("Error creating Account");
  }
};

export const createEmptyEntityAccountForAccount = accountId => async (dispatch, getState) => {
  const url = "/api/account/";
  try {
    const response = await advisorhq.post(url, {
      type: 1,
    });
    dispatch({ type: types.CREATE_ACCOUNT, payload: response.data });
    notifications.success("A new Account was created");

    window.scrollTo(0, 0);
    redirectTo(`/accounts/${response.data.id}/information/`);

    // Associate the new account to the previously selected account
    const endpoint = `/api/account/${accountId}/`;
    await advisorhq.patch(endpoint, { associated_entity: response.data.id });
  } catch (e) {
    notifications.error("Error creating Account");
  }
};

export const editAccount = (accountId, newValues, row) => async (dispatch, getState) => {
  notifications.success("Saving...");
  const endpoint = `/api/account/${accountId}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  dispatch({ type: types.EDIT_ACCOUNT, payload: response.data });
};

export const editAccountWithoutUpdating = (accountId, newValues) => async dispatch => {
  notifications.success("Saving...");
  const endpoint = `/api/account/${accountId}/`;
  await advisorhq.patch(endpoint, newValues);
  const response = await advisorhq.get("/api/entity_choices/");
  dispatch({ type: types.FETCH_ACCOUNT_ENTITY_CHOICES, payload: response.data });
  dispatch(hideModal(MANAGE_ENTITY_MODAL));
};

export const deleteAccount = id => async (dispatch, getState) => {
  try {
    await advisorhq.delete(`/api/account/${id}/`);
    notifications.success("Account deleted successfully");
  } catch (e) {
    notifications.error(e.response.data.error);
  }
  dispatch({ type: DELETE_ACCOUNT, payload: { id } });

  // re-fetch
  dispatch({ type: types.LOADING, payload: { accounts: true } });
  const response = await advisorhq.get("/api/account/", {
    params: {},
  });
  let { results } = response.data;
  dispatch({ type: types.FETCH_ACCOUNTS, payload: results });
  dispatch({ type: types.LOADING, payload: { accounts: false } });
};

export const updateAccountFilters = filters => async (dispatch, getState) => {
  dispatch({ type: types.STORE_ACCOUNTS_FILTERS_DATA, payload: filters });
};

export const fetchAccountEntityChoices = () => async (dispatch, getState) => {
  const response = await advisorhq.get("/api/entity_choices/");
  dispatch({ type: types.FETCH_ACCOUNT_ENTITY_CHOICES, payload: response.data });
};

export const fetchEngagementActivities = filters => async (dispatch, getState) => {
  const activeAccountId = activeAccountIdSelector(getState());
  dispatch({ type: types.LOADING, payload: { engagementActivities: true } });
  const response = await advisorhq.get("/leads/api/engagementactivity/" + activeAccountId + "/");
  dispatch({ type: types.FETCH_ENGAGEMENT_ACTIVITIES, payload: response.data });
  dispatch({ type: types.LOADING, payload: { engagementActivities: false } });
};

export const fetchLeadEngagementActivities = lead_id => async (dispatch, getState) => {
  const activeLeadId = activeLeadIdSelector(getState());
  const response = await advisorhq.get("/leads/api/lead-engagementactivity/" + activeLeadId + "/");
  dispatch({ type: types.FETCH_ENGAGEMENT_ACTIVITIES, payload: response.data });
};

export const sendAccountEmail = values => async (dispatch, getState) => {
  const formData = new FormData();

  for (let key in values) {
    formData.append(key, values[key]);
  }

  if (values.files) {
    for (let i = 0; i < values.files.length; i++) {
      formData.append("files[]", values.files[i], values.files[i].name);
    }
  }

  // sends email
  try {
    await advisorhq.post(`/api/send_account_email_template/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    return error.response;
  }

  // Close the modal
  dispatch(hideModal(SEND_ACCOUNT_EMAIL_MODAL));
};
