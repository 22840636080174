import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { CASE_FILE_MODAL_LE } from "components/modals";
import CaseFileBaseForm from "./CaseFileBaseForm";
import {
  activeCaseFileSelector,
  activeLifeExpectancySelector,
  lifeExpectanciesSelector,
  currentLETableIndexSelector,
} from "reducers";
import SingleFileDropZone from "components/SingleFileDropZone";
import {
  deleteCaseFile,
  deleteCaseFileFile,
  deleteLifeExpectancyFile,
  editLifeExpectancy,
  fetchLifeExpectanciesWithCurrentPage,
  uploadCaseFile,
  selectCaseFile,
  fetchCaseFilesByCaseDocumentId,
  fetchInsured,
  setCurrentLETableIndex,
} from "actions";
import LifeExpectancyForm from "../lifeExpectancies/LifeExpectancyForm";
import { ButtonNext, ButtonPrevious } from "components/buttons";
import { isAdmin } from "../../../permissions";

const CaseFileModal = ({
  activeCaseFile,
  activeLifeExpectancy,
  lifeexpectancies_cases,
  currentLETableIndex,
  setCurrentLETableIndex,
  nextLE,
  previousLE,
  hasAdminPermission,
  ...props
}) => {
  let caseFileIsSaved = activeCaseFile && activeCaseFile.id;
  const generalFormSubmitRef = React.useRef();
  const vendorSpecificFormSubmitRef = React.useRef();
  const mortalityTableSubmitRef = React.useRef();

  const WideSaveButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="btn btn--secondary"
          onClick={() => {
            generalFormSubmitRef.current.click();
            vendorSpecificFormSubmitRef.current && vendorSpecificFormSubmitRef.current.click();
            mortalityTableSubmitRef.current && mortalityTableSubmitRef.current.click();
            setTimeout(() => {
              props.fetchLifeExpectanciesWithCurrentPage();
            }, 500);
          }}
          style={{
            width: "99%",
            marginBottom: "1rem",
          }}
        >
          Save Changes
        </button>
      </div>
    );
  };

  return hasAdminPermission ? (
    <FullPageModal
      modalType={CASE_FILE_MODAL_LE}
      selectCaseFile={props.selectCaseFile}
      fetchCaseFilesByCaseDocumentId={props.fetchCaseFilesByCaseDocumentId}
      fetchInsured={props.fetchInsured}
      lifeexpectancies_cases={lifeexpectancies_cases}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 0.5 + "rem",
        }}
      >
        <ButtonPrevious
          previous={previousLE}
          onClick={() => {
            props.fetchInsured(lifeexpectancies_cases[previousLE].case);
            props.fetchCaseFilesByCaseDocumentId(lifeexpectancies_cases[previousLE].case_document);
            props.selectCaseFile(lifeexpectancies_cases[previousLE].case_document);
            setCurrentLETableIndex(previousLE);
          }}
        />
        {/* this p tag is created to make flexbox work better. justifyContent: "space-between", */}
        <p
          style={{
            opacity: 0,
          }}
        >
          a
        </p>
        <ButtonNext
          next={nextLE}
          onClick={() => {
            props.fetchInsured(lifeexpectancies_cases[nextLE].case);
            props.fetchCaseFilesByCaseDocumentId(lifeexpectancies_cases[nextLE].case_document);
            props.selectCaseFile(lifeexpectancies_cases[nextLE].case_document);
            setCurrentLETableIndex(nextLE);
          }}
        />
      </div>
      <CaseFileBaseForm submitRef={generalFormSubmitRef} />
      {activeLifeExpectancy ? (
        <>
          <WideSaveButton />
          <LifeExpectancyForm
            vendor={activeCaseFile.fr_id}
            lifeExpectancy={activeLifeExpectancy}
            vendorSpecificFormSubmitRef={vendorSpecificFormSubmitRef}
            mortalityTableSubmitRef={mortalityTableSubmitRef}
          />
        </>
      ) : null}

      <WideSaveButton />
      {caseFileIsSaved ? (
        <div>
          <SingleFileDropZone
            currentFile={activeCaseFile.file}
            uploadFile={props.uploadCaseFile}
            deleteFile={() => props.deleteCaseFileFile(activeCaseFile.id)}
            fileDownloadUrl={`/life_settlement/api/case_file/${activeCaseFile.id}/download_file/`}
          />
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 0.5 + "rem",
        }}
      >
        {previousLE !== false ? (
          <ButtonPrevious
            onClick={() => {
              props.fetchInsured(lifeexpectancies_cases[previousLE].case);
              props.fetchCaseFilesByCaseDocumentId(lifeexpectancies_cases[previousLE].case_document);
              props.selectCaseFile(lifeexpectancies_cases[previousLE].case_document);
              setCurrentLETableIndex(previousLE);
            }}
          />
        ) : (
          ""
        )}
        {/* this p tag is created to make flexbox work better. justifyContent: "space-between", */}
        <p
          style={{
            opacity: 0,
          }}
        >
          a
        </p>
        {nextLE ? (
          <ButtonNext
            onClick={() => {
              props.fetchInsured(lifeexpectancies_cases[nextLE].case);
              props.fetchCaseFilesByCaseDocumentId(lifeexpectancies_cases[nextLE].case_document);
              props.selectCaseFile(lifeexpectancies_cases[nextLE].case_document);
              setCurrentLETableIndex(nextLE);
            }}
          />
        ) : (
          ""
        )}
      </div>
    </FullPageModal>
  ) : null;
};

const mapStateToProps = state => {
  const currentLETableIndex = currentLETableIndexSelector(state);
  const lifeexpectancies_cases = lifeExpectanciesSelector(state);
  let nextLE, previousLE;

  if (currentLETableIndex < lifeexpectancies_cases.length - 1) {
    nextLE = currentLETableIndex + 1;
  } else {
    nextLE = false;
  }
  if (currentLETableIndex > 0 && lifeexpectancies_cases.length > 1) {
    previousLE = currentLETableIndex - 1;
  } else {
    previousLE = false;
  }
  return {
    activeCaseFile: activeCaseFileSelector(state),
    activeLifeExpectancy: activeLifeExpectancySelector(state),
    lifeexpectancies_cases: lifeexpectancies_cases,
    currentLETableIndex: currentLETableIndex,
    nextLE: nextLE,
    previousLE: previousLE,
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  uploadCaseFile,
  deleteCaseFile,
  deleteCaseFileFile,
  deleteLifeExpectancyFile,
  editLifeExpectancy,
  fetchLifeExpectanciesWithCurrentPage,
  selectCaseFile,
  fetchCaseFilesByCaseDocumentId,
  fetchInsured,
  setCurrentLETableIndex,
})(CaseFileModal);
