import React from "react";
import { connect } from "react-redux";

import { selectAuctionActivity, fetchAuctionActivities, showModal, fetchActivityLog } from "actions";
import { activeCaseSelector, activityLogSelector, loadingSelector } from "reducers";
import Table, { datetimeCell, sortDateCell } from "components/table";
import IconLink from "../../../components/IconLink";
import { FaEye } from "react-icons/fa";
import { EMAIL_PREVIEW_MODAL } from "../../../components/modals";

const ActivityTable = ({ ...props }) => {
  React.useEffect(() => {
    if (props.caseInfo.submission_id) {
      document.title = `LifeRoc | ${props.caseInfo.submission_id} | Activity Log`;
    }
    if (props.caseInfo.id) {
      props.fetchActivityLog(props.caseInfo.id);
    }
  }, [props.caseInfo.id]);

  if (props.loading.error404) {
    return <></>;
  }

  const columns = [
    {
      Header: "Action",
      accessor: "action",
    },
    {
      Header: "Date",
      accessor: "date",
      sortType: sortDateCell,
      Cell: datetimeCell,
    },
    {
      Header: "User",
      accessor: "user",
    },
    {
      Header: "Recipients",
      accessor: "recipients",
    },
    {
      Header: "View Email",
      Cell: ({ row }) => {
        return row.original.email_body ? (
          <>
            <IconLink
              Icon={FaEye}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="Preview"
              onClick={() => {
                return props.showModal(EMAIL_PREVIEW_MODAL, {
                  emailBody: row.original.email_body,
                });
              }}
            />
          </>
        ) : (
          <></>
        );
      },
    },
    {
      Header: "Attachments",
      Cell: ({ row }) => {
        const attachments = row.original.attachments;
        return (
          <>
            {attachments.map((o, i) => (
              <>
                <a style={{ color: "black" }} href={o} target={"_blank"} rel={"noreferrer"}>
                  Attachment {i + 1}
                </a>
                <br />
              </>
            ))}
          </>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      data={props.activityLog}
      defaultSort={true}
      paginated={false}
      emptyMessage={props.loading.activityLog ? "Loading..." : "No records found"}
      sortBy={[{ id: "date", desc: true }]}
    />
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
    activityLog: activityLogSelector(state) || [],
    loading: loadingSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchAuctionActivities,
  fetchActivityLog,
  selectAuctionActivity,
  showModal,
})(ActivityTable);
