import React from "react";

export default submitRef => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <button
        className="btn btn--secondary"
        onClick={() => {
          submitRef.current && submitRef.current.click();
        }}
        style={{
          width: "99%",
          marginBottom: "1rem",
        }}
      >
        Save Changes
      </button>
    </div>
  );
};
