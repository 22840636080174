import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";

import { activeCaseSelector } from "reducers";

import TradingSummaryForm from "./TradingSummaryForm";

const TradingSummary = ({ caseInfo, tradingSummaryFormSubmitRef, ...props }) => {
  if (!caseInfo) {
    return null;
  }

  return (
    <div className={`section-card shadowed case-section`} onClick={props.handleClick}>
      <Card>
        <Card.Header>
          <h5 className="section-title">TRADING SUMMARY</h5>
        </Card.Header>
        <Card.Body>
          <TradingSummaryForm submitRef={tradingSummaryFormSubmitRef} caseInfo={caseInfo} />
        </Card.Body>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
  };
};

export default connect(mapStateToProps, {})(TradingSummary);
