export const validateLength =
  (length, regex = "") =>
  value => {
    if (value !== undefined && value !== null) {
      const cleanedValueLength = value.replace(regex, "").length;
      if (value !== "" && cleanedValueLength !== length) {
        return "Incomplete value";
      }
    }
    return undefined;
  };

/* Helper functions to see whether a field is valid or invalid looking
 * at the meta object provided by formik's useField */

export const isValid = meta => meta.touched && !meta.error;

export const isInvalid = meta => meta.error;

export const getValidationClassName = meta => {
  if (isInvalid(meta)) {
    return "is-invalid";
  }
};
