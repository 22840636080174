import React from "react";
import { Form } from "react-bootstrap";

import { isInvalid } from "./validation";

const FieldErrors = ({ meta }) => {
  return (
    <Form.Control.Feedback type="invalid">
      {isInvalid(meta) && meta.error}
    </Form.Control.Feedback>
  );
};

export default FieldErrors;
