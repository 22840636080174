import {
  FETCH_LIFE_EXPECTANCIES,
  EDIT_LIFE_EXPECTANCY,
  CREATE_LIFE_EXPECTANCY,
  FETCH_LIFE_EXPECTANCY_CHOICES,
  FETCH_ALL_LIFE_EXPECTANCIES,
  SELECT_LIFE_EXPECTANCY,
  EXTRACT_LIFE_EXPECTANCY_PDF_DATA,
  DELETE_LIFE_EXPECTANCY,
  CLEAN_LIFE_SETTLEMENT_CASE_DATA,
  FETCH_21ST_MEDICAL_CONDITIONS_SCHEMA,
  FETCH_PREDICTIVE_MEDICAL_CONDITIONS_SCHEMA,
  STORE_RELATED_LE,
  SET_LE_TABLE_INDEX,
  SELECT_CASE_FILE,
  LOADING,
} from "actions/types";
import { choicesWrapper, undefinedChoicesProxy } from "options.js";
import { removeKey } from "utils.js";
import { arrayToObjectIndexedById } from "reducers/utils";

export const defaultState = {
  lifeExpectancies: {},
  activeLifeExpectancyId: null,
  extractedPDFdata: null,
  choices: undefinedChoicesProxy,
  twentyFirstMedicalConditionsSchema: [],
  predictiveMedicalConditionsSchema: [],
  relatedLe: [],
  currentTableLEIndex: 0,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_LIFE_EXPECTANCY:
      return {
        ...state,
        activeLifeExpectancyId: action.payload,
        extractedPDFdata: null, // reset on select
      };

    case SELECT_CASE_FILE:
      return {
        ...state,
        extractedPDFdata: null, // reset on select
      };

    case FETCH_ALL_LIFE_EXPECTANCIES:
      return {
        ...state,
        lifeExpectancies: arrayToObjectIndexedById(action.payload),
      };
    case STORE_RELATED_LE:
      return {
        ...state,
        relatedLe: action.payload,
      };
    case FETCH_LIFE_EXPECTANCIES:
      return {
        ...state,
        lifeExpectancies: arrayToObjectIndexedById(action.payload.results),
      };

    case LOADING:
      if (action.payload.le) {
        return {
          ...state,
          lifeExpectancies: arrayToObjectIndexedById([]),
        };
      } else {
        return state;
      }

    case CREATE_LIFE_EXPECTANCY:
    case EDIT_LIFE_EXPECTANCY:
      return {
        ...state,
        lifeExpectancies: {
          ...state.lifeExpectancies,
          [action.payload.id]: {
            ...state.lifeExpectancies[action.payload.id],
            ...action.payload,
          },
        },
        activeLifeExpectancyId: action.payload.id,
      };

    case DELETE_LIFE_EXPECTANCY: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        lifeExpectancies: removeKey(state.lifeExpectancies, toDeleteId),
      };
      if (state.activeLifeExpectancyId === toDeleteId) {
        newState.activeLifeExpectancyId = null;
      }
      return newState;
    }

    case SET_LE_TABLE_INDEX:
      return { ...state, currentTableLEIndex: action.payload };

    case FETCH_LIFE_EXPECTANCY_CHOICES: {
      let choices = {};
      Object.entries(action.payload).forEach(([key, value]) => {
        choices[key] = choicesWrapper(value);
      });
      return { ...state, choices };
    }

    case EXTRACT_LIFE_EXPECTANCY_PDF_DATA:
      return { ...state, extractedPDFdata: action.payload };

    case FETCH_21ST_MEDICAL_CONDITIONS_SCHEMA:
      return { ...state, twentyFirstMedicalConditionsSchema: action.payload };

    case FETCH_PREDICTIVE_MEDICAL_CONDITIONS_SCHEMA:
      return { ...state, predictiveMedicalConditionsSchema: action.payload };

    case CLEAN_LIFE_SETTLEMENT_CASE_DATA:
      return {
        ...defaultState,
        choices: state.choices,
      };

    default:
      return state;
  }
};

// Selectors
export const choicesSelector = state => state.choices;

// export const lifeExpectanciesSelector = state => Object.values(state.lifeExpectancies);

export const lifeExpectanciesSelector = state => {
  if (state.lifeExpectancies) {
    return Object.values(state.lifeExpectancies);
  } else {
    return [];
  }
};

export const activeLifeExpectancyIdSelector = state => state.activeLifeExpectancyId;

export const activeLifeExpectancySelector = (activeCaseFile, { lifeExpectancies }) => {
  if (activeCaseFile && activeCaseFile.id) {
    return Object.values(lifeExpectancies).find(le => le.case_document === activeCaseFile.id);
  } else {
    return null;
  }
};

export const lifeExpectanciesForActiveInsuredSelector = (state, activeInsuredId) => {
  const lifeExpectanciesList = Object.values(state.lifeExpectancies);
  return lifeExpectanciesList.filter(le => le.insured === activeInsuredId);
};

export const extractedPDFdataSelector = state => state.extractedPDFdata;

export const twentyFirstMedicalConditionsSchemaSelector = state => state.twentyFirstMedicalConditionsSchema;

export const predictiveMedicalConditionsSchemaSelector = state => state.predictiveMedicalConditionsSchema;

export const relatedLeSelector = state => state.relatedLe;

export const currentLETableIndexSelector = state => state.currentTableLEIndex;
