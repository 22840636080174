import { VENDORS } from "../../../constants";
import { parenthesisWrap } from "../../../utils";

let CATEGORY_MAP = {
  Insured: {
    General: [
      { name: "HIPAA Auth", id: "HIPAA" },
      { name: "LifeRoc HIPAA Auth", id: "HIPAALR" },
      { name: "Information Sheet", id: "IIS" },
      { name: "MISC", id: "IMISC" },
    ],
    APS: [
      { name: "Enter Doctor Information", type: "input", id: "MR" },
      { name: "Quest HealthPIQ", id: "QHPIQ" },
      { name: "EHR", type: "input", id: "EHR" },
      { name: "EHR (EIS)", type: "input", id: "EHRE" },
      { name: "EHR (HAPI)", type: "input", id: "EHRH" },
    ],
    LE: [
      { name: "Await LE Detail", type: "input", id: "LE" },
      { name: "LifeRoc Mortality Assessment", type: "input", id: "LRLE" },
      { name: "21st", value: VENDORS.TWENTY_FIRST, id: "21ST" },
      { name: "AVS", value: VENDORS.AVS, id: "AVS" },
      { name: "Clarity", value: VENDORS.CLARITY, id: "CLAR" },
      { name: "Convergence", value: VENDORS.CONVERGENCE, id: "CONV" },
      { name: "Fasano", value: VENDORS.FASANO, id: "FAS" },
      { name: "Focus", value: VENDORS.FOCUS, id: "FOCUS" },
      { name: "ISC", value: VENDORS.ISC, id: "ISC" },
      { name: "Lapetus", value: VENDORS.LAPETUS, id: "LAP" },
      { name: "LSI", value: VENDORS.LSI, id: "LSI" },
      { name: "Polaris", value: VENDORS.POLARIS, id: "POLAR" },
      { name: "Predictive", value: VENDORS.PREDICTIVE, id: "PRED" },
    ],
    ID: [
      { name: "Death Certificate", id: "IDC" },
      { name: "Driver's License / State ID / Passport", id: "IPID" },
      { name: "POA Docs", id: "IPOA" },
      { name: "Same Name Cert", id: "ISNC" },
      { name: "SS Card / Medicare Card / IRS-1040", id: "ISSNV" },
      { name: "Utility Bill", id: "IUB" },
    ],
    Diligence: [
      { name: "Insured Closing Interview (Transcript)", id: "ICIT" },
      { name: "Insured Closing Interview (Recording)", id: "ICIR" },
      { name: "LexisNexus / Tracers", id: "IBGC" },
    ],
    Servicing: [{ name: "Insured Check In", id: "SVICI" }],
  },
  Owner: {
    General: [
      { name: "Information Sheet", id: "OIS" },
      { name: "LifeRoc Policy Info Release", id: "PIRLR" },
      { name: "MISC", id: "OMISC" },
      { name: "Policy Info Release", id: "PIR" },
    ],
    ID: [
      { name: "Driver's License / State ID / Passport", id: "OPID" },
      { name: "IRS-W9", id: "OW9" },
      { name: "POA Docs", id: "OPOA" },
      { name: "Same Name Certification", id: "OSNC" },
      { name: "SS Card / Medicare Card / IRS-1040", id: "OSSNV" },
      { name: "Utility Bill", id: "OUB" },
    ],
    Diligence: [
      { name: "Bankruptcy Documentation", id: "OBKD" },
      { name: "Divorce  Documentation", id: "ODD" },
      { name: "LexisNexus / Tracers", id: "OBGC" },
      { name: "Lien Documentation", id: "OLD" },
      { name: "Owner Closing Interview (Transcript)", id: "OCIT" },
      { name: "Owner Closing Interview (Recording)", id: "OCIR" },
    ],
    Entity: [
      { name: "Authorization to Sell", id: "EATS" },
      { name: "Bylaws", id: "EBYL" },
      { name: "Certificate of Formation", id: "ECOF" },
      { name: "Operating Agreement", id: "EOA" },
      { name: "Partnership Agreement", id: "EPA" },
      { name: "Trust", id: "ETC" },
      { name: "Trust Review/Memo", id: "ETM" },
      { name: "Trust Certification", id: "ETCRT" },
      { name: "Carrier Trust Certification", id: "ETCCRT" },
    ],
  },
  Beneficiary: {
    ID: [
      { name: "Driver's License / State ID / Passport", id: "BPID" },
      { name: "POA Docs", id: "BPOA" },
      { name: "Same Name Cert", id: "BSNC" },
    ],
    Entity: [
      { name: "Bylaws", id: "BEBYL" },
      { name: "Operating Agreement", id: "BEOA" },
      { name: "Trust", id: "BETC" },
    ],
  },
  Policy: {
    Illustration: [
      { name: "Inforce", id: "ILLUS" },
      { name: "Conversion", id: "ILLUSC" },
      { name: "Conversion Signed", id: "ILLUSCS" },
    ],
    EOC: [
      { name: "Application (Copy)", id: "APPC" },
      { name: "Application (Conversion)", id: "APPCC" },
      { name: "Term Policy & App", id: "TPAAC" },
      { name: "Certificate of Insurance", id: "COI" },
      { name: "Policy & Application (Copy)", id: "PAAC" },
      { name: "Policy & Application (Duplicate)", id: "PAAD" },
      { name: "Policy & Application (Original)", id: "PAAO" },
      { name: "Policy & App (E-Original)", id: "PAAEO" },
      { name: "Policy (Copy)", id: "POLC" },
      { name: "Policy (Duplicate)", id: "POLD" },
      { name: "Policy (Original)", id: "POLO" },
      { name: "Policy (E-Original)", id: "POLEO" },
      { name: "Policy (Specimen)", id: "POLS" },
      { name: "Premium Due", id: "POLPD" },
      { name: "Amendment", id: "POLAM" },
      { name: "Delivery Receipt", id: "POLDR" },
      { name: "Secondary Contact Form", id: "POLSCF" },
      { name: "EFT Form", id: "POLEFT" },
      { name: "Accelerated Death Benefit Disclosure", id: "POLADBD" },
      { name: "Agent Report", id: "POLART" },
      { name: "IUL Disclosure", id: "POLIULD" },
      { name: "IUL Account Allocation", id: "POLIULAA" },
    ],
    Statement: [
      { name: "Annual", id: "SA" },
      { name: "Monthly", id: "SM" },
      { name: "Of Values", id: "SOV" },
      { name: "Premium History", id: "SPHX" },
      { name: "Quarterly", id: "SQ" },
    ],
    VOC: [
      { name: "Call Recording", id: "VOCCR" },
      { name: "Call Transcript", id: "VOCCT" },
      { name: "Carrier", id: "VOCC" },
      { name: "NAIC", id: "VOCNAIC" },
    ],
    COT: [
      { name: "MISC", id: "COT" },
      { name: "Carrier Change Confirmation", id: "CCC" },
      { name: "Collateral Assigment", id: "PCA" },
      { name: "Release of Collateral Assigment", id: "PRCA" },
      { name: "Change Forms (Client Signed)", id: "CFCS" },
      { name: "Change Forms (Duly Executed)", id: "CFDE" },
      { name: "Entitlement Order", id: "EO" },
    ],
    Notice: [
      { name: "COI Increase", id: "CNCOI" },
      { name: "Grace", id: "CNGRC" },
      { name: "Lapse", id: "CNLAP" },
      { name: "Premium", id: "CNPREM" },
      { name: "Confirmation of Death", id: "CNCOD" },
    ],
  },
  Primary: {
    Licensing: [
      { name: "Agent Carrier Appointment (Conversion)", id: "ACA" },
      { name: "Agent State Licensing (Conversion)", id: "ASL" },
    ],
  },
  Secondary: {
    Licensing: [
      { name: "Broker License", id: "BL" },
      { name: "Broker Set Up", id: "BSU" },
      { name: "Broker Payment Instructions Verification", id: "BPIV" },
    ],
    General: [
      { name: "Await Review", id: "IAR" },
      { name: "Await Pricing Review", id: "SAPW" },
      { name: "Await Underwriting Review", id: "SAUW" },
      { name: "Gathering Offers", id: "SGA" },
      { name: "Policy Valuation Report", id: "PWR" },
      { name: "Single Case Agreement", id: "SCA" },
      { name: "Await Offer Acceptance", id: "AOA" },
    ],
    Closing: [
      { name: "Offer Letter (Broker)", id: "SBOL" },
      { name: "Offer Letter (Seller)", id: "SSOL" },
      { name: "Offer Letter (Broker Bid Summary)", id: "SBS" },
      { name: "Contract Request Form", id: "SCRF" },
      { name: "Provider License", id: "PL" },
      { name: "Contracts - Execution Ready", id: "PCRFE" },
      { name: "Contracts - Duly Executed", id: "PCDE" },
      { name: "Annuity Carrier Application(s)", id: "ACAP" },
      { name: "Anti-Fraud Sign Off", id: "AFSO" },
      { name: "Anti-Fraud First Notice", id: "AFFN" },
      { name: "Anti-Fraud Second Notice", id: "AFSN" },
      { name: "Anti-Fraud State Report", id: "AFSR" },
      { name: "Pricing Sign Off", id: "SPSO" },
      { name: "Provider Diligence Sign Off", id: "SPDSO" },
      { name: "Funder Policy Sale Agreement", id: "SPSA" },
      { name: "Funder Escrow Agreement", id: "SEA" },
      { name: "Funder Diligence Sign Off", id: "SFDSO" },
      { name: "Entitlement Order", id: "SEO" },
      { name: "Escrow Funding Confirmation", id: "SEFC" },
      { name: "Required Notice - Carrier", id: "RNC" },
      { name: "Required Notice - Insured", id: "RNI" },
      { name: "Required Notice - Owner", id: "RNO" },
      { name: "Escrow Disbursement Authorization", id: "SEDA" },
      { name: "Escrow Disbursement Instructions", id: "SEDI" },
      { name: "Escrow Confirmation of Seller Payment", id: "COSP" },
      { name: "Escrow Confirmation of Broker Payment", id: "COBP" },
      { name: "Escrow Confirmation of Provider Payment", id: "COPP" },
      { name: "Escrow Confirmation of Carrier Payment", id: "COCP" },
      { name: "Rescission Period Ends", id: "RPE" },
      { name: "Policy Assignment / Bill of Sale", id: "STOT" },
      { name: "Waiting Period", id: "SWP" },
      { name: "Send Originals to Funder", id: "SOTF" },
      { name: "Funder Disclosures", id: "SFD" },
      { name: "Minimum Pricing Guidelines", id: "SMP" },
      { name: "NJ Writing Agent Review", id: "SNJWA" },
      { name: "Broker Dealer For VUL Broker Comp", id: "SBD" },
    ],
  },
  Tertiary: {
    General: [
      { name: "Await Review", id: "TAR" },
      { name: "Gathering Offers", id: "TGA" },
    ],
    Closing: [
      { name: "Offer Letter", id: "TO" },
      { name: "Diligence Sign Off", id: "TDSO" },
      { name: "Pricing Sign Off", id: "TPSO" },
      { name: "Funder Policy Sale Agreement", id: "TPSA" },
      { name: "Funder Escrow Agreement", id: "TEA" },
      { name: "Entitlement Order (Credit)", id: "EOCR" },
      { name: "Entitlement Order (Debit)", id: "EOD" },
      { name: "Entitlement Order (Tri-Party)", id: "EOTP" },
      { name: "Entitlement Order", id: "TEO" },
      { name: "Escrow Funding Confirmation", id: "TEFC" },
      { name: "Escrow Disbursement Authorization", id: "TDA" },
      { name: "Escrow Disbursement Instructions", id: "TEDI" },
      { name: "Escrow Seller Payment Confirmation", id: "TCOSP" },
      { name: "Escrow Carrier Payment Confirmation", id: "TCOCP" },
      { name: "Policy Assignment / Bill of Sale", id: "TTOT" },
      { name: "Send Originals to Funder", id: "TOTF" },
      { name: "Funder Diligence Sign Off", id: "TFDSO" },
      { name: "Funder Purchase Order (Initial)", id: "POI" },
      { name: "Funder Purchase Order (Final)", id: "POF" },
      { name: "Carrier Change Forms", id: "TCCF" },
      { name: "Carrier Change Confirmation", id: "TCCC" },
    ],
  },
};
CATEGORY_MAP["Insured 2"] = CATEGORY_MAP.Insured;

export const FRID_CHOICES = [];
for (const classGroup in CATEGORY_MAP) {
  for (const subclassGroup in CATEGORY_MAP[classGroup]) {
    for (const elem in CATEGORY_MAP[classGroup][subclassGroup]) {
      if (CATEGORY_MAP[classGroup][subclassGroup][elem]) {
        FRID_CHOICES.push({
          value: CATEGORY_MAP[classGroup][subclassGroup][elem].id,
          label:
            CATEGORY_MAP[classGroup][subclassGroup][elem].name +
            " " +
            parenthesisWrap(CATEGORY_MAP[classGroup][subclassGroup][elem].id),
        });
      }
    }
  }
}

const STATUS_CASE_TABLE_PENDING = 1;
export const STATUS_CASE_TABLE_OUTSTANDING = 2;
const STATUS_CASE_TABLE_NOT_YET_REQUESTED = 20;

export const NOTES_MAP = {
  QHPIQ: "We need our vendor approved LifeRoc HIPAA (GPIP03 20210921) signed. *E-Signatures are not accepted.",
  ISSNV:
    "*Required for all Insureds. Acceptable documentation shall include a clear copy of one of the following documents so long as the document contains the individuals Social Security Number: (1) Social Secuirty Card, (2) MediCare Card. (3) Signed IRS-1040 (finacial detail redactions are ok).",
  OSSNV:
    "*Only required for Individual/Person Owners. Acceptable documentation may include a clear copy of one of the following documents so long as the document contains the individuals Social Security Number: (1) Social Secuirty Card, (2) MediCare Card. (3) Signed IRS-1040 (finacial detail redactions are ok).",
  CFCS: "*Please hold the Change Forms. Only send them to LifeRoc once you have received confirmation of Escrow Funding.",
  SPDSO: "General & State Specific Checklists.",
  RPE: "*Set Follow Up Date to Recission End Date.",
  HIPAALR: "Quest Compliant.",
  SWP:
    "None:<br>" +
    "2 Year: 21 of the regulated states<br>" +
    "4 Year: Minnesota<br>" +
    "5 Year: DE, IA, OH, OR, ND, NE, NH, NV, WI, WV, VT",
  SFD: "Jurisdiction: Nevada; Kentucky; Others??",
  SMP: "Jurisdiction: Illinois; Others?",
  SBD: "*Please note that for VUL policies we need a Broker Dealer to pay all Broker compensation to. If no compensation is being paid this requirement can be waived.",
};

export const FRID_MAP = {
  // [status, owner, responsible party]
  BPID: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  BPOA: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  BSNC: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  BEBYL: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  BEOA: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  BETC: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  IAR: [STATUS_CASE_TABLE_PENDING, "Pricing Manager", "Provider"],
  HIPAA: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  HIPAALR: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  IIS: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  IMISC: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  MR: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  QHPIQ: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  EHR: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  EHRE: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  EHRH: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  LE: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  LRLE: [STATUS_CASE_TABLE_OUTSTANDING, "Underwriting Manager", "Provider"],
  "21st": [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  AVS: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  CLAR: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  CONV: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  FAS: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  FOCUS: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  ISC: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  LSI: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  LAP: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  POLAR: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  PRED: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  IDC: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  IPID: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  IPOA: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  ISNC: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  ISSNV: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  IUB: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  IBGC: [STATUS_CASE_TABLE_PENDING, "Closing Manager", "Provider"],
  ICIT: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  ICIR: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  SVICI: [STATUS_CASE_TABLE_OUTSTANDING, "Servicing Manager", "Servicer"],
  OIS: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  OMISC: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  PIR: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  PIRLR: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  OPID: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  OW9: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  OPOA: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  OSNC: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  OSSNV: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  OUB: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  OCIT: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  OCIR: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  OBKD: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  ODD: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  OBGC: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  OLD: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  EATS: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  EBYL: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  ECOF: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  EOA: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  EPA: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  ETC: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  ETM: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  ETCRT: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  ETCCRT: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  ILLUS: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  ILLUSC: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  ILLUSCS: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  APPC: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  APPD: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  APPO: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  APPCC: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  TPAAC: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  COI: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  PAAC: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  PAAD: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  PAAO: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  PAAEO: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  POLC: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  POLD: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  POLO: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  POLEO: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  POLS: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  POLPD: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  POLAM: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  POLDR: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  POLSCF: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  POLEFT: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  POLADBD: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  POLART: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  POLIULD: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  POLIULAA: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  SA: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  SM: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  SOV: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  SPHX: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  SQ: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Source"],
  VOCCR: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  VOCCT: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  VOCC: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  VOCNAIC: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  COT: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  CCC: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Provider"],
  PCA: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  PRCA: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Provider"],
  CFCS: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Source"],
  CFDE: [STATUS_CASE_TABLE_PENDING, "Closing Manager", "Funder"],
  EO: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Funder"],
  CNCOI: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  CNGRC: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  CNLAP: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  CNPREM: [STATUS_CASE_TABLE_OUTSTANDING, "Case Manager", "Provider"],
  CNCOD: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  ACA: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Broker"],
  ASL: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Broker"],
  BL: [STATUS_CASE_TABLE_PENDING, "Closing Manager", "Broker"],
  BSU: [STATUS_CASE_TABLE_PENDING, "Closing Manager", "Broker"],
  BPIV: [STATUS_CASE_TABLE_PENDING, "Closing Manager", "Broker"],
  SAPW: [STATUS_CASE_TABLE_PENDING, "Pricing Manager", "Provider"],
  SAUW: [STATUS_CASE_TABLE_PENDING, "Underwriting Manager", "Provider"],
  SGA: [STATUS_CASE_TABLE_PENDING, "Trading Manager", "Provider"],
  PWR: [STATUS_CASE_TABLE_PENDING, "Pricing Manager", "Provider"],
  SCA: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  AOA: [STATUS_CASE_TABLE_OUTSTANDING, "Trading Manager", "Source"],
  SBOL: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Broker"],
  SSOL: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  SBS: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  SCRF: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Broker"],
  PL: [STATUS_CASE_TABLE_PENDING, "Closing Manager", "Provider"],
  PCRFE: [STATUS_CASE_TABLE_PENDING, "Closing Manager", "Provider"],
  PCDE: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Broker"],
  ACAP: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
  AFSO: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  AFFN: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  AFSN: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  AFSR: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  SPSO: [STATUS_CASE_TABLE_PENDING, "Closing Manager", "Provider"],
  SPDSO: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  SEFC: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Funder"],
  RNC: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Provider"],
  RNI: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Provider"],
  RNO: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Provider"],
  SEDI: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Provider"],
  RPE: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Provider"],
  COSP: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Escrow Agent"],
  COBP: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Escrow Agent"],
  COPP: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Escrow Agent"],
  COCP: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Escrow Agent"],
  TAR: [STATUS_CASE_TABLE_PENDING, "Trading Manager", "Provider"],
  TGA: [STATUS_CASE_TABLE_PENDING, "Trading Manager", "Provider"],
  TO: [STATUS_CASE_TABLE_OUTSTANDING, "Trading Manager", "Provider"],
  TPSO: [STATUS_CASE_TABLE_OUTSTANDING, "Trading Manager", "Provider"],
  TDSO: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  SFDSO: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Funder"],
  TFDSO: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Funder"],
  POI: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Funder"],
  POF: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Funder"],
  SPSA: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Funder"],
  TPSA: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Funder"],
  SEA: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Funder"],
  TEA: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Funder"],
  SEO: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Funder"],
  EOCR: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  EOD: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  EOTP: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  TEFC: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Funder"],
  SEDA: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Funder"],
  TDA: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Funder"],
  TEDI: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Provider"],
  TCOSP: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Provider"],
  TCOCP: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Provider"],
  STOT: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Provider"],
  TTOT: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Provider"],
  SOTF: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Provider"],
  TCCF: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Provider"],
  TCCC: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Provider"],
  TOTF: [STATUS_CASE_TABLE_NOT_YET_REQUESTED, "Closing Manager", "Provider"],
  SWP: [STATUS_CASE_TABLE_PENDING, "Closing Manager", "Provider"],
  SFD: [STATUS_CASE_TABLE_PENDING, "Closing Manager", "Funder"],
  SMP: [STATUS_CASE_TABLE_PENDING, "Closing Manager", "Provider"],
  SNJWA: [STATUS_CASE_TABLE_PENDING, "Closing Manager", "Provider"],
  SBD: [STATUS_CASE_TABLE_OUTSTANDING, "Closing Manager", "Source"],
};

export const STATUS = {
  PENDING: 1,
  OUTSTANDING: 2,
  RECEIVED_NGO: 10,
  RECEIVED_PENDING_REVIEW: 11,
  NOT_YET_REQUESTED: 20,
  NOT_REQUIRED: 30,
  RECEIVED_IGO: 31,
  WAIVED: 32,
};

export const STATUS_COLORS = {
  1: "red",
  2: "red",
  10: "orange",
  11: "orange",
  20: "black",
  30: "green",
  31: "green",
  32: "green",
};

export default CATEGORY_MAP;
export { CATEGORY_MAP as CATEGORY_MAP_LIST };
