import React from "react";
import { connect } from "react-redux";

import { funderAuctionActivitiesSelector } from "reducers";

const NumberOfFundersSent = props => {
  const [numberOfBids, setNumberOfBids] = React.useState(0);

  React.useEffect(() => {
    if (props.auction_activities) {
      const bids = props.auction_activities.filter(activity => activity.funder && activity.type === "S");
      const funders = [];
      bids.map(obj => funders.push(obj.funder));
      const uniqueFunders = [...new Set(funders)];
      setNumberOfBids(uniqueFunders.length);
    } else {
      setNumberOfBids(0);
    }
  }, [props.auction_activities]);

  return numberOfBids;
};

const mapStateToProps = state => {
  return {
    auction_activities: funderAuctionActivitiesSelector(state),
  };
};

export default connect(mapStateToProps)(NumberOfFundersSent);
