import React from "react";
import { connect } from "react-redux";

const Footer = ({ footerGroups, ...props }) => {
  return (
    <tfoot className={"react-table-footer"}>
      {footerGroups.map(footerGroup => (
        <tr {...footerGroup.getFooterGroupProps()}>
          {footerGroup.headers.map(column => (
            <td {...column.getFooterProps}>{column.render("Footer")}</td>
          ))}
        </tr>
      ))}
    </tfoot>
  );
};

export default connect(null, {})(Footer);
