import React, { useRef } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { TextInput, FormFilter, SubmitSearchButton, ClearFiltersButton, Select, BooleanSelect } from "components/forms";
import { fetchFunders, updateFunderFilters } from "actions";
import { funderFiltersSelector } from "../../reducers";
import { buyingStatusFilterChoices } from "../../constants";
import { leVendorChoices, ndaInPlaceChoices } from "./constants";
import { makeChoices } from "../../utils";

const FundersListFilterForm = ({ fetchFunders, ...props }) => {
  const buyingStatusRef = useRef();
  const ndaInPlaceRef = useRef();
  const winningBidRef = useRef();

  // TODO: Fix multi-select filter not loading visually after going back to Funders

  return (
    <Formik
      initialValues={{
        ...props.currentFilters,
      }}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        props.updateFunderFilters({
          ...values,
          form: "list",
        });
        // Handle defaultValue dict
        if (values.buying_status && values.buying_status.value === "Active") {
          delete values.buying_status;
        }
        await fetchFunders({ ...values, form: "list" });
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, resetForm }) => {
        const resetCallback = () => {
          props.updateFunderFilters({});
          resetForm();
          fetchFunders();
          buyingStatusRef.current.select?.clearValue();
          ndaInPlaceRef.current.select?.clearValue();
          winningBidRef.current.select?.clearValue();
        };

        return (
          <FormFilter title={"Funder Search"} onSubmit={handleSubmit}>
            <Form.Row>
              <TextInput label="Funder Name" name="name" md={4} />
              <Select
                label="Funder Status"
                name="buying_status"
                placeholder={"Select Buying Status"}
                options={props.buyingStatusFilterChoices}
                defaultValue={{ value: "Active", label: "Active" }}
                submitRef={buyingStatusRef}
                md={4}
              />
            </Form.Row>
            <Form.Row>
              <Select
                label="Funder NDA In Place?"
                name="nda_in_place"
                placeholder={"Select NDA in Place"}
                options={props.ndaInPlaceChoices}
                submitRef={ndaInPlaceRef}
                md={4}
              />
              <BooleanSelect
                label="Show Winning Bid Notification?"
                name="winning_bid_notification"
                placeholder={"Select Yes/No"}
                submitRef={winningBidRef}
                md={4}
              />
            </Form.Row>
            <Form.Row>
              <TextInput label="Notes" name="notes" md={3} />
            </Form.Row>
            <SubmitSearchButton />
            <ClearFiltersButton onClick={resetCallback} />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const currentFilters = funderFiltersSelector(state);

  return {
    buyingStatusFilterChoices,
    ndaInPlaceChoices,
    leVendorChoices,
    currentFilters,
  };
};

export default connect(mapStateToProps, {
  fetchFunders,
  updateFunderFilters,
})(FundersListFilterForm);
