import React, { useRef } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";

import { TextInput, DateInput, CurrencyInput, EscrowAgentSelect, Select } from "components/forms";
import {
  editCase,
  fetchAuction,
  fetchClosingFunderEntities,
  fetchFunderAuctionActivities,
  fetchMarketAuctionActivities,
  fetchTradingFunderEntities,
  fetchFundersForAuction,
  selectFunder,
} from "actions";
import {
  activeCaseSelector,
  fundersForAuctionSelector,
  managementChoicesSelector,
  funderWinningBidSelector,
  marketWinningBidSelector,
  auctionAccessEnabledSelector,
  auctionIdSelector,
  activeClosingFunderEntitiesSelector,
  activeTradingFunderEntitiesSelector,
  activeAgencySelector,
  fundersSelector,
} from "reducers";
import { arrayToObjectIndexedById } from "reducers/utils";
import { dateDisplay, floatWithoutCommas, getLabel, moneyDisplay, parseFloatFromString } from "utils";
import Collapsible from "../../../components/Collapsible";
import useDeepCompareEffect from "use-deep-compare-effect";
import HTMLReadOnlyText from "../../../components/forms/HTMLReadOnlyText";
import { premiumPaymentCategoryChoices } from "../../../constants";

const CaseClosingDetailForm = ({ caseInfo, values, formikRef, ...props }) => {
  const [closingFunder, setClosingFunder] = React.useState("");
  const [tradingFunder, setTradingFunder] = React.useState("");
  const [totalOffer, setTotalOffer] = React.useState("");
  const [LRSpread, setLRSpread] = React.useState("");
  const [grossOffer, setGrossOffer] = React.useState("");
  const [purchasePrice, setPurchasePrice] = React.useState("");
  const [RDB, setRDB] = React.useState("");
  const [RDBOffer, setRDBOffer] = React.useState("");
  const [premiumPaymentCategory, setPremiumPaymentCategory] = React.useState("");
  const [paymentDescription, setPaymentDescription] = React.useState("");
  const [premiumApportionment, setPremiumApportionment] = React.useState("");
  const [apportionmentDescription, setApportionmentDescription] = React.useState("");
  const [marketRDB, setMarketRDB] = React.useState("");
  const [marketRDBOffer, setMarketRDBOffer] = React.useState("");
  const [marketPremiumPaymentCategory, setMarketPremiumPaymentCategory] = React.useState("");
  const [marketPaymentDescription, setMarketPaymentDescription] = React.useState("");
  const [marketPremiumApportionment, setMarketPremiumApportionment] = React.useState("");
  const [marketApportionmentDescription, setMarketApportionmentDescription] = React.useState("");
  const [funderOfferExpirationDate, setFunderOfferExpirationDate] = React.useState("");
  const [closingNotes, setClosingNotes] = React.useState("");
  const [tradingNotes, setTradingNotes] = React.useState("");
  const [revenueModel, setRevenueModel] = React.useState("");
  const [closingEscrowChoices, setClosingEscrowChoices] = React.useState([]);
  const [tradingEscrowChoices, setTradingEscrowChoices] = React.useState([]);

  // Security Intermediary Choices are modified when the Closing/Trading Funder is selected
  const [closingSecurityIntermediaryChoices, setClosingSecurityIntermediaryChoices] = React.useState(
    props.closingSecurityIntermediaryChoices,
  );
  const [tradingSecurityIntermediaryChoices, setTradingSecurityIntermediaryChoices] = React.useState(
    props.tradingSecurityIntermediaryChoices,
  );

  React.useEffect(() => {
    if (props.auctionAccessEnabled) {
      if (props.auctionId !== undefined) {
        // Fetch funders
        props.fetchFundersForAuction(props.auctionId);

        // Wait for the auction to be fetched (we need the id) before fetching the activities
        props.fetchMarketAuctionActivities();
        props.fetchFunderAuctionActivities();
      }
    }
  }, [props.fetchMarketAuctionActivities, props.fetchFunderAuctionActivities, props.auctionId]);

  const updateClosingFunderDetails = funderData => {
    setClosingFunder(funderData.name);
    setClosingNotes(funderData.notes);
  };

  const updateTradingFunderDetails = funderData => {
    setTradingFunder(funderData.name);
    setTradingNotes(funderData.notes);
  };

  React.useEffect(() => {
    if (props.marketWinningBid && props.marketWinningBid.gross_offer) {
      // use moneyDisplay to get $,",","." , then remove $
      setGrossOffer(moneyDisplay(props.marketWinningBid.gross_offer).replace("$", ""));
      setPurchasePrice(props.marketWinningBid.gross_offer);
      setMarketRDB(props.marketWinningBid.rdb ? "Yes" : "No");
      setMarketRDBOffer(props.marketWinningBid.rdb_offer);
      setMarketPremiumPaymentCategory(
        getLabel(premiumPaymentCategoryChoices, props.marketWinningBid.premium_payment_category),
      );
      setMarketPaymentDescription(props.marketWinningBid.payment_description);
      setMarketPremiumApportionment(props.marketWinningBid.premium_apportionment ? "Yes" : "No");
      setMarketApportionmentDescription(props.marketWinningBid.apportionment_description);
    }

    if (props.winningBid && props.winningBid.total_offer) {
      let total_offer = props.winningBid.total_offer;
      let gross_offer = props.marketWinningBid && props.marketWinningBid.gross_offer;
      setTotalOffer(total_offer);
      if (total_offer && gross_offer) {
        let lr_spread = parseFloatFromString(total_offer) - parseFloatFromString(gross_offer);
        setLRSpread(moneyDisplay(lr_spread).replace("$", ""));
      } else {
        setLRSpread(props.winningBid.lr_spread);
      }
      setRDB(props.winningBid.rdb ? "Yes" : "No");
      setRDBOffer(props.winningBid.rdb_offer);
      setPremiumPaymentCategory(getLabel(premiumPaymentCategoryChoices, props.winningBid.premium_payment_category));
      setPaymentDescription(props.winningBid.payment_description);
      setPremiumApportionment(props.winningBid.premium_apportionment ? "Yes" : "No");
      setApportionmentDescription(props.winningBid.apportionment_description);
      setFunderOfferExpirationDate(dateDisplay(props.winningBid.funder_offer_expiration_date));

      let fundersById = arrayToObjectIndexedById(props.auctionFunders);
      let funderData = fundersById[props.winningBid.funder];

      // If the Winning Bid Revenue Model is Trading, we have both a Closing and a Trading Funder
      // Closing Funder is taken from the Balance Sheet Funder of the Agency
      // Trading Funder is taken from the Winning Bid Funder
      if (props.winningBid.revenue_model === "TRADING" || props.winningBid.revenue_model === "TRADING_BLIND") {
        setRevenueModel("Trading");

        let balanceSheetFunderId = props.activeAgency.balance_sheet_funder;
        if (balanceSheetFunderId) {
          const balanceSheetFunder = props.funders.find(f => f.id === balanceSheetFunderId);
          if (balanceSheetFunder) {
            setClosingFunder(balanceSheetFunder.name);
            setClosingNotes(balanceSheetFunder.notes);
            props.fetchClosingFunderEntities(balanceSheetFunderId);
          }
        }

        if (funderData) {
          updateTradingFunderDetails(funderData);

          // Fetch
          let activeFunderId = funderData.id;
          props.fetchTradingFunderEntities(activeFunderId);
        }
      } else if (props.winningBid.revenue_model === "DIRECT") {
        setRevenueModel("Direct Placement");

        if (funderData) {
          updateClosingFunderDetails(funderData);
        }

        // Fetch
        let activeFunderId = props.winningBid.funder;
        props.fetchClosingFunderEntities(activeFunderId);
      }
    }
  }, [props.winningBid, props.funders.length, props.auctionFunders, props.activeAgency]);

  useDeepCompareEffect(() => {
    if (closingFunder && closing_funding_entity) {
      let selectedFunderEntity = props.activeClosingFunderEntities.find(x => x.id === closing_funding_entity);
      if (selectedFunderEntity) {
        updateFunderEntityFields(selectedFunderEntity, "closing");
        setClosingEscrowChoices(getEscrowAgentChoices(selectedFunderEntity.id, props.activeClosingFunderEntities));
        let selectedSecurityIntermediaries = selectedFunderEntity.securities_intermediaries;
        setClosingSecurityIntermediaryChoices(
          selectedSecurityIntermediaries.map(x => {
            return { value: x.id, label: x.intermediary_name };
          }),
        );
      }
    }
  }, [props.activeClosingFunderEntities]);

  useDeepCompareEffect(() => {
    if (tradingFunder && trading_funding_entity) {
      let selectedFunderEntity = props.activeTradingFunderEntities.find(x => x.id === trading_funding_entity);
      if (selectedFunderEntity) {
        updateFunderEntityFields(selectedFunderEntity, "trading");
        setTradingEscrowChoices(getEscrowAgentChoices(selectedFunderEntity.id, props.activeTradingFunderEntities));
        let selectedSecurityIntermediaries = selectedFunderEntity.securities_intermediaries;
        setTradingSecurityIntermediaryChoices(
          selectedSecurityIntermediaries.map(x => {
            return { value: x.id, label: x.intermediary_name };
          }),
        );
      }
    }
  }, [props.activeTradingFunderEntities]);

  if (!caseInfo) {
    // Still didn't fetch data
    return null;
  }

  const { broker_comp, closing_funding_entity, trading_funding_entity } = caseInfo;

  const getEscrowAgentChoices = (funderId, funderEntities) => {
    let filteredEscrowAgents = [];

    // Filter Escrow Agents by the selected Funder Entity
    if (funderId && funderEntities) {
      let funderData = funderEntities.find(f => f.id === funderId);
      if (funderData) {
        let availableEscrowAgentIDs = funderData.escrow_agents.split(",");
        for (let choice of props.allEscrowAgentChoices) {
          if (availableEscrowAgentIDs.indexOf(String(choice.value)) !== -1) {
            filteredEscrowAgents.push(choice);
          }
        }
      }
    }

    // If the saved Escrow Agent is not in the choices of the Closing Funder, still show it as an option
    if (caseInfo.escrow_agent) {
      if (!filteredEscrowAgents.find(e => e.value === caseInfo.escrow_agent)) {
        let defaultEscrowAgent = props.allEscrowAgentChoices.find(e => e.value === caseInfo.escrow_agent);
        if (defaultEscrowAgent) {
          filteredEscrowAgents.push({
            label: defaultEscrowAgent.label,
            value: defaultEscrowAgent.value,
          });
        } else {
          console.log(
            "could not find defaultEscrowAgent",
            caseInfo.escrow_agent,
            "in choices:",
            props.allEscrowAgentChoices,
          );
        }
      }
    }

    return filteredEscrowAgents;
  };

  let purchasePriceMinusBrokerComp = null;
  if (purchasePrice) {
    if (broker_comp) {
      purchasePriceMinusBrokerComp = moneyDisplay(
        floatWithoutCommas(purchasePrice) - floatWithoutCommas(broker_comp),
      ).replace("$", "");
    } else {
      purchasePriceMinusBrokerComp = moneyDisplay(floatWithoutCommas(purchasePrice)).replace("$", "");
    }
  }

  const updateFunderEntityFields = (selectedFunderEntity, prefix) => {
    formikRef.current.setFieldValue(
      `${prefix}_approved_trading_docs`,
      selectedFunderEntity.approved_trading_document ? "Yes" : "No",
    );
    formikRef.current.setFieldValue(
      `${prefix}_docusign_accepted`,
      selectedFunderEntity.docusign_accepted ? "Yes" : "No",
    );
    formikRef.current.setFieldValue(
      `${prefix}_le_reps_and_warranties`,
      selectedFunderEntity.le_reps_and_warranties ? "Yes" : "No",
    );
    formikRef.current.setFieldValue(`${prefix}_funder_entity_notes`, selectedFunderEntity.notes || "");
  };

  const handleClosingFundingEntityChange = e => {
    if (!e) {
      // Blank fields
      formikRef.current.setFieldValue("closing_funding_entity", undefined);
      formikRef.current.setFieldValue("securities_intermediary", undefined);
      formikRef.current.setFieldValue("escrow_agent", undefined);
      formikRef.current.setFieldValue("closing_approved_trading_docs", "");
      formikRef.current.setFieldValue("closing_docusign_accepted", "");
      formikRef.current.setFieldValue("closing_le_reps_and_warranties", "");
      formikRef.current.setFieldValue("closing_funder_entity_notes", "");
      return;
    }

    formikRef.current.setFieldValue("closing_funding_entity", e.value);
    formikRef.current.setFieldValue("securities_intermediary", undefined);
    let selectedFunderEntity = props.activeClosingFunderEntities.find(x => x.id === e.value);
    let selectedSecurityIntermediaries = selectedFunderEntity.securities_intermediaries;
    setClosingSecurityIntermediaryChoices(
      selectedSecurityIntermediaries.map(x => {
        return { value: x.id, label: x.intermediary_name };
      }),
    );
    updateFunderEntityFields(selectedFunderEntity, "closing");
    setClosingEscrowChoices(getEscrowAgentChoices(e.value, props.activeClosingFunderEntities));
  };

  const handleTradingFundingEntityChange = e => {
    if (!e) {
      // Blank fields
      formikRef.current.setFieldValue("trading_funding_entity", undefined);
      formikRef.current.setFieldValue("trading_securities_intermediary", undefined);
      formikRef.current.setFieldValue("trading_escrow_agent", undefined);
      formikRef.current.setFieldValue("trading_approved_trading_docs", "");
      formikRef.current.setFieldValue("trading_docusign_accepted", "");
      formikRef.current.setFieldValue("trading_le_reps_and_warranties", "");
      formikRef.current.setFieldValue("trading_funder_entity_notes", "");
      return;
    }

    formikRef.current.setFieldValue("trading_funding_entity", e.value);
    formikRef.current.setFieldValue("trading_securities_intermediary", undefined);
    let selectedFunderEntity = props.activeTradingFunderEntities.find(x => x.id === e.value);
    let selectedSecurityIntermediaries = selectedFunderEntity.securities_intermediaries;
    setTradingSecurityIntermediaryChoices(
      selectedSecurityIntermediaries.map(x => {
        return { value: x.id, label: x.intermediary_name };
      }),
    );
    updateFunderEntityFields(selectedFunderEntity, "trading");
    setTradingEscrowChoices(getEscrowAgentChoices(e.value, props.activeTradingFunderEntities));
  };

  return (
    <>
      <Collapsible title="Closing Offer Details">
        <Form.Row>
          <DateInput label="Offer Expiration Date" value={funderOfferExpirationDate} name="_" disabled />
          <CurrencyInput label="LR Spread" value={LRSpread} name="lr_spread" disabled />
          <CurrencyInput label="Broker Comp" name="broker_comp" />
          <TextInput label="Revenue Model" value={revenueModel} name="_" disabled />
        </Form.Row>
        <Form.Row>
          <CurrencyInput label="Purchase Price" value={purchasePriceMinusBrokerComp} name="_" disabled md={3} />
          <CurrencyInput label="Total Offer" value={totalOffer} name="_" disabled md={3} />
          <CurrencyInput label="Gross Offer" value={grossOffer} name="_" disabled md={3} />
        </Form.Row>
        <Form.Row>
          <TextInput label="Funder Premium Payment Category" name="_" value={premiumPaymentCategory} disabled md={3} />
          <TextInput label="Funder PPC Description" name="_" value={paymentDescription} disabled />
          <TextInput
            label="Market Premium Payment Category"
            name="_"
            value={marketPremiumPaymentCategory}
            disabled
            md={3}
          />
          <TextInput label="Market PPC Description" name="_" value={marketPaymentDescription} disabled />
        </Form.Row>
        <Form.Row>
          <TextInput label="Funder RDB" value={RDB} name="_" disabled md={3} />
          <TextInput label="Funder RDB Description" value={RDBOffer} name="_" disabled />
          <TextInput label="Market RDB" value={marketRDB} name="_" disabled md={3} />
          <TextInput label="Market RDB Description" value={marketRDBOffer} name="_" disabled />
        </Form.Row>
        <Form.Row>
          <TextInput label="Funder Premium Apportionment" value={premiumApportionment} name="_" disabled md={3} />
          <TextInput label="Funder PA Description" value={apportionmentDescription} name="_" disabled />
          <TextInput label="Market Premium Apportionment" value={marketPremiumApportionment} name="_" disabled md={3} />
          <TextInput label="Market PA Description" value={marketApportionmentDescription} name="_" disabled />
        </Form.Row>
      </Collapsible>
      <Collapsible title="Closing Dates">
        <Form.Row>
          <DateInput label="Seller Signed" name="seller_signed_date" />
          <DateInput label="Duly Executed" name="duly_executed_date" />
          <DateInput label="Escrow Funding" name="escrow_fund_date" />
          <DateInput label="Title Change" name="title_change_date" />
        </Form.Row>
        <Form.Row>
          <DateInput label="Title Change Received" name="title_change_received_date" />
          <DateInput label="Seller Escrow Release" name="escrow_release_date" />
          <DateInput label="Provider Escrow Release" name="provider_release_date" />
          <DateInput label="Rescission Expiration" name="rescission_end_date" />
        </Form.Row>
        <Form.Row>
          <DateInput label="Broker Escrow Release" name="broker_escrow_release_date" md={3} />
        </Form.Row>
      </Collapsible>
      <Collapsible title="Closing Funder Details">
        <Form.Row>
          <TextInput label="Closing Funder" value={closingFunder} name="_" disabled />
          <Select
            label="Closing Funding Entity"
            name="closing_funding_entity"
            placeholder="Select Closing Funding Entity"
            options={props.closingFunderEntityChoices}
            onChange={handleClosingFundingEntityChange}
            md={3}
          />
          <Select
            label="Securities Intermediary"
            name="securities_intermediary"
            placeholder="Select Securities Intermediary"
            options={closingSecurityIntermediaryChoices}
            disabled={!values.closing_funding_entity}
            md={3}
          />
          <TextInput label="Titling Instructions" name="_" value={"TBD"} disabled />
        </Form.Row>
        <Form.Row>
          <TextInput label="Approved Trading Docs" name="closing_approved_trading_docs" disabled />
          <TextInput label="DocuSign Accepted" name="closing_docusign_accepted" disabled />
          <EscrowAgentSelect options={closingEscrowChoices} />
          <TextInput label="LE Reps & Warranties" name="closing_le_reps_and_warranties" disabled />
        </Form.Row>
        <Form.Row>
          <HTMLReadOnlyText label="Closing Funder Notes" value={closingNotes} />
        </Form.Row>
        <Form.Row>
          <HTMLReadOnlyText label="Closing Funder Entity Notes" value={values.closing_funder_entity_notes} />
        </Form.Row>
      </Collapsible>
      {revenueModel === "Trading" && (
        <Collapsible title="Trading Funder Details">
          <Form.Row>
            <TextInput label="Trading Funder" value={tradingFunder} name="_" disabled />
            <Select
              label="Trading Funding Entity"
              name="trading_funding_entity"
              placeholder="Select Trading Funding Entity"
              options={props.tradingFunderEntityChoices}
              onChange={handleTradingFundingEntityChange}
              md={3}
            />
            <Select
              label="Securities Intermediary"
              name="trading_securities_intermediary"
              placeholder="Select Securities Intermediary"
              options={tradingSecurityIntermediaryChoices}
              disabled={!values.trading_funding_entity}
              md={3}
            />
            <TextInput label="Titling Instructions" name="_" value={"TBD"} disabled />
          </Form.Row>
          <Form.Row>
            <TextInput label="Approved Trading Docs" name="trading_approved_trading_docs" disabled />
            <TextInput label="DocuSign Accepted" name="trading_docusign_accepted" disabled />
            <EscrowAgentSelect name={"trading_escrow_agent"} options={tradingEscrowChoices} />
            <TextInput label="LE Reps & Warranties" name="trading_le_reps_and_warranties" disabled />
          </Form.Row>
          <Form.Row>
            <HTMLReadOnlyText label="Trading Funder Notes" value={tradingNotes} />
          </Form.Row>
          <Form.Row>
            <HTMLReadOnlyText label="Trading Funder Entity Notes" value={values.trading_funder_entity_notes} />
          </Form.Row>
        </Collapsible>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const funderWinningBid = funderWinningBidSelector(state);
  const marketWinningBid = marketWinningBidSelector(state);
  const activeCase = activeCaseSelector(state);

  // Closing Funder section
  let activeClosingFunderEntities = activeClosingFunderEntitiesSelector(state);
  let closingFunderEntityChoices = [];
  let closingSecurityIntermediaryChoices = [];
  for (let f of activeClosingFunderEntities) {
    closingFunderEntityChoices.push({ value: f.id, label: f.name });
    for (let s of f.securities_intermediaries) {
      closingSecurityIntermediaryChoices.push({
        value: s.id,
        label: s.intermediary_name,
      });
    }
  }

  // Trading Funder section
  let activeTradingFunderEntities = activeTradingFunderEntitiesSelector(state);
  let tradingFunderEntityChoices = [];
  let tradingSecurityIntermediaryChoices = [];
  for (let f of activeTradingFunderEntities) {
    tradingFunderEntityChoices.push({ value: f.id, label: f.name });
    for (let s of f.securities_intermediaries) {
      tradingSecurityIntermediaryChoices.push({
        value: s.id,
        label: s.intermediary_name,
      });
    }
  }

  return {
    caseInfo: activeCase,
    processTypeChoices: choices.case_process_types,
    providerChoices: choices.case_providers,
    agencyChoices: choices.agencies,
    salesRepChoices: choices.case_sales_representatives,
    winningBid: funderWinningBid,
    auctionFunders: fundersForAuctionSelector(state),
    marketWinningBid: marketWinningBid,
    auctionAccessEnabled: auctionAccessEnabledSelector(state),
    auctionId: auctionIdSelector(state),
    activeClosingFunderEntities,
    activeTradingFunderEntities,
    closingFunderEntityChoices,
    closingSecurityIntermediaryChoices,
    tradingFunderEntityChoices,
    tradingSecurityIntermediaryChoices,
    allEscrowAgentChoices: choices.escrow_agents.getChoices(),
    activeAgency: activeAgencySelector(state),
    funders: fundersSelector(state),
  };
};

export default connect(mapStateToProps, {
  editCase,
  fetchAuction,
  fetchMarketAuctionActivities,
  fetchFunderAuctionActivities,
  fetchClosingFunderEntities,
  fetchTradingFunderEntities,
  fetchFundersForAuction,
  selectFunder,
})(CaseClosingDetailForm);
