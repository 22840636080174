import {
  CREATE_EMAIL_TEMPLATE,
  EDIT_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  FETCH_EMAIL_TEMPLATE_LIST,
  SET_EMAIL_TEMPLATE,
  SET_EMAIL_TEMPLATE_TYPE,
} from "actions/types";
import { arrayToObjectIndexedById } from "../../utils";
import { removeKey } from "../../../utils";

export const defaultState = {
  emailTemplates: {},
  activeEmailTemplate: null,
  activeEmailTemplateType: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_EMAIL_TEMPLATE_LIST: {
      return {
        ...state,
        emailTemplates: arrayToObjectIndexedById(action.payload),
      };
    }
    case SET_EMAIL_TEMPLATE:
      return {
        ...state,
        activeEmailTemplate: action.payload,
      };
    case SET_EMAIL_TEMPLATE_TYPE:
      return {
        ...state,
        activeEmailTemplateType: action.payload,
      };
    case CREATE_EMAIL_TEMPLATE:
    case EDIT_EMAIL_TEMPLATE:
      return {
        ...state,
        emailTemplates: {
          ...state.emailTemplates,
          [action.payload.id]: action.payload,
        },
        activeEmailTemplate: action.payload,
      };
    case DELETE_EMAIL_TEMPLATE: {
      return {
        ...state,
        emailTemplates: removeKey(state.emailTemplates, action.payload),
      };
    }
    default:
      return state;
  }
};

export const emailTemplateSelector = state => Object.values(state.emailTemplates);
export const activeEmailTemplateSelector = state => state.activeEmailTemplate;
export const activeEmailTemplateTypeSelector = state => state.activeEmailTemplateType;
