import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";

import * as notifications from "notifications";
import { Select, EditorField, FormCollapsible, SubmitButton } from "components/forms";
import { createAccountAffiliation, editAccountAffiliation } from "actions";
import { activeAccountIdSelector, activeAffiliationSelector, accountEntityChoicesSelector } from "reducers";
import { partial } from "utils";
import { ACCOUNT_AFFILIATION_STATUS, AFFILIATION_PRACTICE_TYPES } from "../../constants";

const AccountAffiliationForm = ({ activeAffiliation, ...props }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...activeAffiliation,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isEmpty(activeAffiliation)
          ? props.createAccountAffiliation
          : partial(props.editAccountAffiliation, activeAffiliation.id);

        values.account = props.activeAccountId;

        try {
          await method(values);
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Affiliation");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Affiliation Detail">
            <Form.Row>
              <Select
                label="Practice Type"
                name="practice_type"
                placeholder="Select Practice Type"
                options={AFFILIATION_PRACTICE_TYPES}
              />
              <Select
                label="Entity Name"
                name="entity"
                placeholder={!values.practice_type ? "(Select Practice Type first)" : "Select Entity Name"}
                options={
                  values.practice_type
                    ? props.entityChoices.filter(choice => choice.practice_type === parseInt(values.practice_type))
                    : []
                }
                disabled={!values.practice_type}
              />
              <Select
                label="Affiliation Status"
                name="status"
                placeholder="Select Affiliation Status"
                options={ACCOUNT_AFFILIATION_STATUS}
              />
            </Form.Row>
            <Form.Row>
              <EditorField label="Notes" name="notes" />
            </Form.Row>
            <SubmitButton />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const accountEntityChoices = accountEntityChoicesSelector(state);
  let entityChoices = [];
  for (let acc of accountEntityChoices) {
    if (acc.practice_type) {
      entityChoices.push(acc);
    }
  }
  return {
    activeAccountId: activeAccountIdSelector(state),
    activeAffiliation: activeAffiliationSelector(state),
    entityChoices,
  };
};

export default connect(mapStateToProps, {
  createAccountAffiliation,
  editAccountAffiliation,
})(AccountAffiliationForm);
