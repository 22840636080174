import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { CASE_FILE_MODAL, REDACT_MODAL } from "components/modals";
import {
  accountsSelector,
  activeCaseSelector,
  caseFilesSelector,
  insuredListSelector,
  ownerEntitiesSelector,
  ownerPersonsSelector,
  policySelector,
} from "reducers";
import { arrayToObjectIndexedById } from "reducers/utils";
import Table from "components/table";
import IconLink from "components/IconLink";
import { FaDownload, FaEdit, FaEye, FaGlasses, FaLowVision } from "react-icons/fa";
import {
  downloadFile,
  fetchPolicy,
  fetchInsured,
  hideModal,
  redactFiles,
  fetchCaseFilesByCaseDocumentId,
  selectCaseFile,
  showModal,
  fetchAccount,
} from "actions";
import { isRedactableCaseFile } from "../utils";
import { FormCollapsible, SubmitButton, TextInput, DateInput } from "components/forms";
import { Form } from "react-bootstrap";
import { Formik } from "formik";

const RedactModal = ({ ...props }) => {
  let [filesToRedact, setFilesToRedact] = React.useState([]);
  const [partialRedactionChecked, setPartialRedactionChecked] = React.useState(true);
  const [fullRedactionChecked, setFullRedactionChecked] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);

  const handlePartialRedactionChange = () => {
    setPartialRedactionChecked(!partialRedactionChecked);
  };

  const handleFullRedactionChange = () => {
    setFullRedactionChecked(!fullRedactionChecked);
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    selectAllFiles(!selectAll);
  };

  const submitRef = React.useRef();

  const selectAllFiles = redact_bool => {
    let _files = [];
    for (const caseFile of props.caseFiles) {
      if (isRedactableCaseFile(caseFile)) {
        // If the file is null, dont redact it
        if (caseFile.file === null) {
          caseFile.redact_file = false;
        } else {
          caseFile.redact_file = redact_bool;
        }
        _files.push(caseFile);
      }
    }
    setFilesToRedact(_files);
  };

  React.useEffect(() => {
    selectAllFiles(true);
  }, [props.caseFiles]);

  React.useEffect(() => {
    // Need to load the Policy for showing potential data to redact
    props.fetchPolicy(props.caseInfo.policy);
    props.fetchInsured(props.caseInfo.id);
    if (props.caseInfo.broker) {
      props.fetchAccount(props.caseInfo.broker);
    }
    if (props.caseInfo.hierarchy) {
      props.fetchAccount(props.caseInfo.hierarchy.account_id);
      if (props.caseInfo.hierarchy.national_account_id) {
        props.fetchAccount(props.caseInfo.hierarchy.national_account_id);
      }
    }
  }, [props.caseInfo]);

  const StartButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="btn btn--secondary"
          onClick={() => submitRef.current && submitRef.current.click()}
          disabled={isButtonDisabled}
          style={{
            width: "90%",
            marginBottom: "1rem",
          }}
        >
          Start Redaction
        </button>
      </div>
    );
  };

  const updateFilesToRedact = (data, checked) => {
    let result = filesToRedact.slice(); // shallow copy
    for (const i in result) {
      const fileToRedact = result[i];
      if (fileToRedact.id === data.id) {
        result[i].redact_file = checked;
        break;
      }
    }
    setFilesToRedact(result);
  };

  const columns = [
    {
      Header: () => (
        <div style={{ marginLeft: "10px" }}>
          <input
            type="checkbox"
            style={{
              display: "block",
              marginBottom: "5px",
              width: "50%",
              transform: "scale(1.3)",
              cursor: "pointer",
            }}
            checked={selectAll}
            onChange={handleSelectAllChange}
          />
        </div>
      ),
      accessor: "selected",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <input
            type="checkbox"
            checked={row.original.redact_file}
            onClick={e => {
              updateFilesToRedact(row.original, e.target.checked);
            }}
            disabled={row.original.file === null}
            style={{
              display: "block",
              margin: "0",
              width: "80%",
              transform: "scale(1.3)",
              cursor: "pointer",
            }}
          />
        );
      },
    },
    {
      Header: "File",
      Cell: ({ row }) => {
        const objectId = row.original.id;
        const file = row.original.file;
        const filesUrl = `/cases/${row.original.case}/files/`;
        if (!file) {
          return null;
        }

        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              to={filesUrl}
              onClick={() => {
                props.hideModal(REDACT_MODAL);
                props.fetchCaseFilesByCaseDocumentId(objectId);
                props.selectCaseFile(row);
                props.showModal(CASE_FILE_MODAL);
              }}
            />
            <IconLink
              Icon={FaDownload}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="Download"
              to={filesUrl}
              onClick={() => {
                const url = `/life_settlement/api/case_file/${objectId}/download_file/`;
                props.downloadFile(url, file.name);
              }}
            />
            <IconLink
              Icon={FaEye}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="Preview"
              to={filesUrl}
              onClick={() => window.open(file.url, "_blank")}
            />
            {row.original.full_redacted_file ? (
              <IconLink
                Icon={FaGlasses}
                iconConfig={{ className: "table--action-icon" }}
                tooltip="View Full Redacted File"
                to={filesUrl}
                onClick={() => window.open(row.original.full_redacted_file.url, "_blank")}
              />
            ) : null}
            {row.original.partial_redacted_file ? (
              <IconLink
                Icon={FaLowVision}
                iconConfig={{ className: "table--action-icon" }}
                tooltip="View Partial Redacted File"
                to={filesUrl}
                onClick={() => window.open(row.original.partial_redacted_file.url, "_blank")}
              />
            ) : null}
          </>
        );
      },
    },
    {
      Header: "FR ID",
      accessor: "fr_id",
      disableSortBy: true,
    },
    {
      Header: "Category",
      accessor: "category",
      disableSortBy: true,
    },
    {
      Header: "Sub Category",
      accessor: "sub_category",
      disableSortBy: true,
    },
    {
      Header: "Description",
      accessor: "description",
      disableSortBy: true,
    },
    {
      Header: "Size",
      Cell: ({ row }) => {
        const file = row.original.file;
        if (!file) {
          return null;
        }
        return file.size;
      },
    },
  ];

  const renderOptions = () => {
    const checkboxStyle = {
      margin: "10px",
      transform: "scale(1.3)",
      cursor: "pointer",
    };

    return (
      <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <label style={{ marginLeft: "20px" }}>
          <input
            type="checkbox"
            style={checkboxStyle}
            checked={fullRedactionChecked}
            onChange={handleFullRedactionChange}
          />
          Full Redaction
        </label>
        <label>
          <input
            type="checkbox"
            style={checkboxStyle}
            checked={partialRedactionChecked}
            onChange={handlePartialRedactionChange}
          />
          Partial Redaction
        </label>
      </div>
    );
  };

  const setInsuredFields = values => {
    for (let nInsured = 1; nInsured <= props.insuredList.length; nInsured++) {
      let insured = props.insuredList[nInsured - 1];
      values[`Insured_${nInsured}_First_Name`] = insured.first_name;
      values[`Insured_${nInsured}_Last_Name`] = insured.last_name;
      values[`Insured_${nInsured}_Social_Security_Number`] = insured.social_security;
      values[`Insured_${nInsured}_Date_of_Birth`] = insured.date_of_birth;
    }
  };

  const renderInsureds = () => {
    let componentsArray = [];
    for (let nInsured = 1; nInsured <= props.insuredList.length; nInsured++) {
      componentsArray.push(
        <React.Fragment key={nInsured}>
          <FormCollapsible title={`Insured ${nInsured}`}>
            <Form.Row>
              <TextInput label="First Name" name={`Insured_${nInsured}_First_Name`} />
              <TextInput label="Last Name" name={`Insured_${nInsured}_Last_Name`} />
            </Form.Row>
            <Form.Row>
              <DateInput label="Date of Birth" name={`Insured_${nInsured}_Date_of_Birth`} md={6} />
            </Form.Row>
          </FormCollapsible>
        </React.Fragment>,
      );
    }

    return <>{componentsArray}</>;
  };

  const setOwnerFields = values => {
    for (let nOwner = 1; nOwner <= props.owners.length; nOwner++) {
      let owner = props.owners[nOwner - 1];
      if (owner.owner_type === "Person") {
        values[`Owner_${nOwner}_First_Name`] = owner.first_name;
        values[`Owner_${nOwner}_Last_Name`] = owner.last_name;
        values[`Owner_${nOwner}_Social_Security_Number`] = owner.social_security;
        values[`Owner_${nOwner}_Date_of_Birth`] = owner.date_of_birth;
      } else {
        values[`Owner_${nOwner}_Entity_Name`] = owner.entity_name;
        values[`Owner_${nOwner}_TIN`] = owner.tin_number;
        values[`Owner_${nOwner}_Formation_Date`] = owner.formation_date;
      }
    }
  };

  const renderOwners = () => {
    let componentsArray = [];
    for (let nOwner = 1; nOwner <= props.owners.length; nOwner++) {
      let owner = props.owners[nOwner - 1];
      componentsArray.push(
        <React.Fragment key={nOwner}>
          <FormCollapsible title={`Owner ${nOwner} (${owner.owner_type})`}>
            {owner.owner_type === "Person" ? (
              <>
                <Form.Row>
                  <TextInput label="First Name" name={`Owner_${nOwner}_First_Name`} />
                  <TextInput label="Last Name" name={`Owner_${nOwner}_Last_Name`} />
                </Form.Row>
                <Form.Row>
                  <DateInput label="Date of Birth" name={`Owner_${nOwner}_Date_of_Birth`} md={6} />
                </Form.Row>
              </>
            ) : (
              <>
                <Form.Row>
                  <TextInput label="Entity Name" name={`Owner_${nOwner}_Entity_Name`} />
                </Form.Row>
                <Form.Row>
                  <DateInput label="Formation Date" name={`Owner_${nOwner}_Formation_Date`} md={6} />
                </Form.Row>
              </>
            )}
          </FormCollapsible>
        </React.Fragment>,
      );
    }
    return <>{componentsArray}</>;
  };

  const setAccountFields = values => {
    if (props.hierarchy) {
      values.Hierarchy_First_Name = props.hierarchy.first_name;
      values.Hierarchy_Last_Name = props.hierarchy.last_name;
      values.Hierarchy_Entity_Name = props.hierarchy.entity_name;
    }
    if (props.broker) {
      values.Broker_First_Name = props.broker.first_name;
      values.Broker_Last_Name = props.broker.last_name;
      values.Broker_Entity_Name = props.broker.entity_name;
    }
    if (props.national_account) {
      values.NationalAccount_First_Name = props.national_account.first_name;
      values.NationalAccount_Last_Name = props.national_account.last_name;
      values.NationalAccount_Entity_Name = props.national_account.entity_name;
    }
  };

  const renderAccountByType = (accountData, key) => {
    if (accountData.type === 0) {
      return (
        <>
          <Form.Row>
            <TextInput label="First Name" name={`${key}_First_Name`} disabled />
            <TextInput label="Last Name" name={`${key}_Last_Name`} disabled />
          </Form.Row>
        </>
      );
    } else {
      return (
        <>
          <Form.Row>
            <TextInput label="Entity Name" name={`${key}_Entity_Name`} disabled />
          </Form.Row>
        </>
      );
    }
  };

  const renderAccounts = () => {
    // Individuals have First & Last Name
    // Entities have Entity Name
    return (
      <>
        {props.hierarchy ? (
          <FormCollapsible title={`Account Hierarchy`}>
            {renderAccountByType(props.hierarchy, "Hierarchy")}
          </FormCollapsible>
        ) : null}
        {props.broker ? (
          <FormCollapsible title={`Broker`}>{renderAccountByType(props.broker, "Broker")}</FormCollapsible>
        ) : null}
        {props.national_account ? (
          <FormCollapsible title={`National Account`}>
            {renderAccountByType(props.national_account, "NationalAccount")}
          </FormCollapsible>
        ) : null}
      </>
    );
  };

  const renderRedactionFields = () => {
    let initialValues = {
      Policy_Number: props.policy.policy_number,
      Original_Term_Policy_Number: props.policy.original_term_policy_number,
    };
    setInsuredFields(initialValues);
    setOwnerFields(initialValues);
    setAccountFields(initialValues);

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(false);
          try {
            setIsButtonDisabled(true);
            const selectedFilesToRedact = filesToRedact.filter(f => f.redact_file === true);
            const processes = { full: fullRedactionChecked, partial: partialRedactionChecked };
            await props.redactFiles(selectedFilesToRedact, processes, values);
            props.hideModal(REDACT_MODAL);
          } catch (error) {
            // Handle errors if needed
          } finally {
            // Reset the button to be enabled regardless of success or failure
            setIsButtonDisabled(false);
          }
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <FormCollapsible title={"Policy"}>
                <Form.Row>
                  <TextInput label="Policy Number" name="Policy_Number" md={4} />
                  <TextInput label="Original Term Policy Number" name="Original_Term_Policy_Number" md={4} />
                </Form.Row>
              </FormCollapsible>
              {renderInsureds()}
              {renderOwners()}
              {renderAccounts()}
              <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
            </Form>
          );
        }}
      </Formik>
    );
  };

  const renderFiles = () => {
    return (
      <Table
        columns={columns}
        data={filesToRedact}
        showPaginationResults={false}
        defaultSort={true}
        defaultPagination={true}
        sortBy={[
          { id: "category", desc: false },
          { id: "sub_category", desc: false },
          { id: "description", desc: false },
        ]}
        emptyMessage={"No Valid Files"}
      />
    );
  };

  return (
    <FullPageModal modalType={REDACT_MODAL} title={"Review Redaction Options"}>
      {renderRedactionFields()}
      {renderFiles()}
      {renderOptions()}
      <StartButton />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  let caseInfo = activeCaseSelector(state);
  let caseFiles = caseFilesSelector(state);

  // Logic to filter an specific case file when requested directly from the CaseFileModal
  const pathname = window.location.pathname;
  const regexPattern = /^\/cases\/(\d+)\/files\/(\d+)\/?$/;
  const isInSpecificFile = regexPattern.test(pathname);
  if (isInSpecificFile) {
    const match = pathname.match(regexPattern);
    const fileId = match[2];
    if (fileId) {
      caseFiles = caseFiles.filter(f => f.id === Number(fileId));
    }
  }

  let policy = policySelector(state);

  // Only relevant owners (not Insured, as they are covered by another section)
  const op = ownerPersonsSelector(state);
  const oe = ownerEntitiesSelector(state);

  let accounts = accountsSelector(state);
  let hierarchyId = caseInfo.hierarchy.account_id;
  let brokerId = caseInfo.broker;
  let national_accountId = caseInfo.hierarchy.national_account_id;
  let hierarchy = accounts[hierarchyId];
  let broker = accounts[brokerId];
  let national_account = accounts[national_accountId];
  console.log("hierarchy", hierarchy);
  console.log("broker", broker);
  console.log("national_account", national_account);

  return {
    caseFiles,
    caseFilesById: arrayToObjectIndexedById(Object.values(caseFilesSelector(state))),
    caseInfo,
    insuredList: insuredListSelector(state),
    owners: [...op, ...oe],
    policy,
    hierarchy,
    broker,
    national_account,
  };
};

export default connect(mapStateToProps, {
  downloadFile,
  redactFiles,
  hideModal,
  fetchPolicy,
  fetchInsured,
  fetchCaseFilesByCaseDocumentId,
  fetchAccount,
  selectCaseFile,
  showModal,
})(RedactModal);
