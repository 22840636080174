import React from "react";
import { connect, useDispatch } from "react-redux";

import { createEmptyLead, setActiveTab, initPagination, showModal, fetchEmailTemplates } from "actions";
import LeadsFilterForm from "./LeadsFilterForm";
import LeadsTable from "./LeadsTable";
import ButtonCreate from "../../components/buttons/ButtonCreate";
import { SET_EMAIL_TEMPLATE } from "../../actions/types";
import { SEND_LEAD_EMAIL_MODAL } from "../../components/modals";
import { ButtonSendTo } from "../../components/buttons";
import { selectedLeadsSelector } from "../../reducers";

const LeadsPage = props => {
  const [activeSection, setActiveSection] = React.useState("leads");
  const dispatch = useDispatch();

  React.useEffect(() => {
    props.setActiveTab(activeSection);
    props.initPagination();
    props.fetchEmailTemplates();
    setActiveSection(activeSection);
    document.title = "LifeRoc | Leads";
  }, []);

  return (
    <div>
      <LeadsFilterForm />
      <div style={{ marginBottom: "1rem" }}>
        <ButtonCreate onClick={props.createEmptyLead} key="create">
          Add Lead
        </ButtonCreate>
        <ButtonSendTo
          onClick={() => {
            dispatch({
              type: SET_EMAIL_TEMPLATE,
              payload: "Lead Notification",
            });
            props.showModal(SEND_LEAD_EMAIL_MODAL);
          }}
          key="create"
          style={{ marginLeft: "1rem" }}
          disabled={props.selectedLeads.length === 0}
        >
          {props.selectedLeads.length === 0 ? "Select Leads to Send" : `Send to Leads (${props.selectedLeads.length})`}
        </ButtonSendTo>
      </div>
      <LeadsTable />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    selectedLeads: selectedLeadsSelector(state),
  };
};

export default connect(mapStateToProps, {
  setActiveTab,
  initPagination,
  createEmptyLead,
  showModal,
  fetchEmailTemplates,
})(LeadsPage);
