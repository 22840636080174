import React from "react";
import { connect } from "react-redux";

import { fundersActivityReportSelector, loadingFundersSelector } from "reducers";
import Table, { currencyCell } from "components/table";
import IconLink from "../../components/IconLink";
import { FaEdit, FaTrash } from "react-icons/fa";
import { DELETE_FUNDER_MODAL } from "../../components/modals";
import { showModal } from "../../actions";

const FundersListTable = ({ funders, fundersLoading, ...props }) => {
  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const caseId = row.original.id;
        return (
          <>
            <IconLink
              to={`/funders/${caseId}/overview/`}
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View / Edit"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            />
            <IconLink
              Icon={FaTrash}
              iconConfig={{ className: "table--action-icon red" }}
              tooltip="Delete Funder"
              onClick={() => props.showModal(DELETE_FUNDER_MODAL, { id: caseId })}
            />
          </>
        );
      },
    },
    {
      Header: "Funder Name",
      accessor: "name",
      className: "padded-table-cells wrap-text",
    },
    {
      Header: "Funder Status",
      accessor: "buying_status",
      className: "padded-table-cells wrap-text",
    },
    {
      Header: "Pending Count",
      accessor: "pending_count",
      className: "padded-table-cells",
    },
    {
      Header: "Pending Face",
      accessor: "pending_fa",
      Cell: currencyCell,
      className: "padded-table-cells",
    },
    {
      Header: "Bidding Count",
      accessor: "bidding_count",
      className: "wrap-text-padded",
    },
    {
      Header: "Bidding Face",
      accessor: "bidding_fa",
      Cell: currencyCell,
      className: "padded-table-cells",
    },
    {
      Header: "In Closing Count",
      accessor: "closing_count",
      className: "padded-table-cells",
    },
    {
      Header: "In Closing Face",
      accessor: "closing_fa",
      Cell: currencyCell,
      className: "padded-table-cells",
    },
    {
      Header: "Completed Count",
      accessor: "completed_count",
      className: "padded-table-cells",
    },
    {
      Header: "Completed Face",
      accessor: "completed_fa",
      Cell: currencyCell,
      className: "padded-table-cells",
    },
  ];

  let tableClass = "";
  if (funders.length > 1) {
    tableClass = "tableFixed";
  }

  return (
    <>
      <Table
        columns={columns}
        data={funders}
        sortBy={[{ id: "name", desc: false }]}
        defaultSort={true}
        showPaginationResults={true}
        initialPageSize={100}
        defaultPagination={true}
        emptyMessage={fundersLoading ? "Loading Funders data..." : "No data"}
        tableClass={tableClass}
      />
    </>
  );
};

const mapStateToProps = state => {
  const funders = fundersActivityReportSelector(state);
  return {
    funders,
    fundersLoading: loadingFundersSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
})(FundersListTable);
