import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { ACCOUNT_AGREEMENT_MODAL } from "components/modals";
import AgreementForm from "./AgreementForm";
import SingleFileDropZone from "components/SingleFileDropZone";
import { activeAgreementSelector } from "reducers";
import { uploadAgreementFile } from "actions";

const AgreementModal = ({ activeAgreement, ...props }) => {
  let isSaved = activeAgreement && activeAgreement.id;
  const submitRef = React.useRef();
  let title = isSaved ? "Edit Agreement" : "New Agreement";

  return (
    <FullPageModal modalType={ACCOUNT_AGREEMENT_MODAL} title={title} style={{ content: { overflow: "visible" } }}>
      <AgreementForm submitRef={submitRef} />
      {isSaved ? (
        <div>
          <SingleFileDropZone
            currentFile={activeAgreement.name ? activeAgreement : null}
            uploadFile={file => {
              props.uploadAgreementFile(file);
            }}
            fileDownloadUrl={`/api/agreement/`}
          />
        </div>
      ) : null}
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    activeAgreement: activeAgreementSelector(state) || {},
  };
};

export default connect(mapStateToProps, {
  uploadAgreementFile,
})(AgreementModal);
