import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { FormCollapsible, Select, SubmitButton, TextInput } from "components/forms";
import { availableEntitiesSelector, shouldSaveSelector } from "reducers";
import { Form } from "react-bootstrap";
import { isAdmin } from "permissions";
import { createBasicAccount, createEmptyEntityAccountForAccount, unsetShouldSave } from "actions";
import * as notifications from "../../../notifications";
import { ACCOUNT_TYPES } from "../constants";
import * as Yup from "yup";

const BasicCreateAccountModalForm = ({
  accountType,
  entityId,
  activeHierarchySalesRepName,
  availableEntitiesForUser,
  hasAdminPermission,
  ...props
}) => {
  const validationSchema = Yup.object().shape({
    type: Yup.number().required(),
    first_name: Yup.string().when("type", {
      is: 0,
      then: schema => Yup.string().required("First name is required"),
      otherwise: schema => Yup.string(),
    }),
    last_name: Yup.string().when("type", {
      is: 0,
      then: schema => Yup.string().required("Last name is required"),
      otherwise: schema => Yup.string(),
    }),
    entity_name: Yup.string().when("type", {
      is: 1,
      then: schema => Yup.string().required("Entity name is required"),
      otherwise: schema => Yup.string(),
    }),
  });
  return (
    <Formik
      enableReinitialize
      initialValues={{
        associated_entity: entityId,
        type: accountType,
        availableEntitiesForUser,
      }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          console.log(props.createBasicAccount, values);
          await props.createBasicAccount(values);
          notifications.success("Account saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Account");
        }
        props.unsetShouldSave();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title={"New Account"}>
            <Form.Row style={{ padding: "0" }}>
              <Select
                label="Account Type"
                name="type"
                placeholder="Select Account Type"
                options={ACCOUNT_TYPES}
                md={4}
              />
              {values.type === 0 ? (
                <>
                  <TextInput label="First Name" name="first_name" />
                  <TextInput label="Last Name" name="last_name" />
                </>
              ) : (
                <TextInput label="Entity Name" name="entity_name" />
              )}
            </Form.Row>
            <SubmitButton />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const availableEntitiesForUser = availableEntitiesSelector(state);
  return {
    shouldSave: shouldSaveSelector(state),
    hasAdminPermission: isAdmin(state),
    availableEntitiesForUser,
  };
};

export default connect(mapStateToProps, {
  createBasicAccount,
  unsetShouldSave,
  createEmptyEntityAccountForAccount,
})(BasicCreateAccountModalForm);
