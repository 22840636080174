import {
  SELECT_SYNONYM,
  FETCH_SYNONYMS,
  EDIT_SYNONYM,
  CREATE_SYNONYM,
  DELETE_SYNONYM,
  FETCH_PARAMETER_SYNONYMS,
  EDIT_PARAMETER_SYNONYM,
  CREATE_PARAMETER_SYNONYM,
  DELETE_PARAMETER_SYNONYM,
  FETCH_SYNONYM,
  LOADING,
} from "actions/types";
import { arrayToObjectIndexedById } from "reducers/utils";
import { removeKey } from "../../../utils";

export const defaultState = {
  parameterSynonyms: {},
  synonyms: {},
  activeSynonymId: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_SYNONYM:
      return { ...state, activeSynonymId: action.payload };

    case FETCH_SYNONYM: {
      return {
        ...state,
        synonyms: {
          ...state.synonyms,
          [action.payload.id]: action.payload,
        },
      };
    }

    case FETCH_SYNONYMS:
      return { ...state, synonyms: arrayToObjectIndexedById(action.payload) };

    case FETCH_PARAMETER_SYNONYMS:
      return { ...state, parameterSynonyms: arrayToObjectIndexedById(action.payload) };

    case EDIT_SYNONYM:
    case CREATE_SYNONYM: {
      return {
        ...state,
        synonyms: {
          ...state.synonyms,
          [action.payload.id]: action.payload,
        },
        activeSynonymId: action.payload.id,
      };
    }

    case EDIT_PARAMETER_SYNONYM:
    case CREATE_PARAMETER_SYNONYM: {
      return {
        ...state,
        parameterSynonyms: {
          ...state.parameterSynonyms,
          [action.payload.id]: action.payload,
        },
        activeSynonymId: action.payload.id,
      };
    }

    case DELETE_SYNONYM: {
      return {
        ...state,
        synonyms: removeKey(state.synonyms, action.payload),
      };
    }

    case DELETE_PARAMETER_SYNONYM: {
      return {
        ...state,
        parameterSynonyms: removeKey(state.parameterSynonyms, action.payload),
      };
    }
    case LOADING:
      if (action.payload.synonyms) {
        return {
          ...state,
          synonyms: arrayToObjectIndexedById([]),
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export const activeSynonymSelector = ({ synonyms, activeSynonymId }) => {
  if (activeSynonymId) {
    return synonyms[activeSynonymId];
  }
};

export const activeParameterSynonymSelector = ({ parameterSynonyms, activeSynonymId }) => {
  if (activeSynonymId) {
    return parameterSynonyms[activeSynonymId];
  }
};

export const synonymsSelector = state => {
  return Object.values(state.synonyms);
};

export const parameterSynonymsSelector = state => {
  // TODO: FIX received state is state instead of state.lifeSettlement.management.synonyms
  return Object.values(state.lifeSettlement.management.synonyms.parameterSynonyms);
};
