import * as types from "../types";
import advisorhq from "../../apis/advisorhq";
import * as notifications from "../../notifications";
import { activeStateSelector, activeStateProviderFileSelector, activeStateBrokerFileSelector } from "../../reducers";
import { redirectTo } from "../../utils";

export const selectState = id => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_STATE, payload: id });
  const response = await advisorhq.get("/api/state/" + id + "/");
  dispatch({ type: types.FETCH_STATE, payload: response.data });
};

export const clearSelectedState = () => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_STATE, payload: null });
};

export const fetchStates = filters => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { states: true } });
  const response = await advisorhq.get("/api/state/", {
    params: {
      ...filters,
    },
  });
  dispatch({ type: types.FETCH_STATES, payload: response.data });
  dispatch({ type: types.LOADING, payload: { states: false } });
};

export const createState = data => async (dispatch, getState) => {
  const endpoint = `/api/state/`;
  const response = await advisorhq.post(endpoint, data);
  dispatch({ type: types.CREATE_STATE, payload: response.data });
  notifications.success("A new State was created");
  return response.data;
};

export const editState = (stateId, newValues, row) => async (dispatch, getState) => {
  notifications.success("Saving...");
  dispatch({ type: types.LOADING, payload: { states: true } });
  const endpoint = `/api/state/${stateId}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  dispatch({ type: types.EDIT_STATE, payload: response.data });
  dispatch({ type: types.LOADING, payload: { states: false } });
};

export const createEmptyState = () => async (dispatch, getState) => {
  const url = "/api/state/";
  try {
    const response = await advisorhq.post(url, {});
    dispatch({ type: types.CREATE_EMPTY_STATE, payload: response.data });
    notifications.success("A new State was created");
    window.scrollTo(0, 0);
    redirectTo(`/states/${response.data.id}/overview/`);
  } catch (e) {
    notifications.error("Error creating State");
  }
};

export const deleteState = id => async (dispatch, getState) => {
  await advisorhq.delete(`/api/state/${id}/`);
  dispatch({ type: types.DELETE_STATE, payload: id });
};

export const uploadStateFile = (file, type) => async (dispatch, getState) => {
  let endpoint;
  let actionType;
  let activeStateFile;
  const state = getState();
  if (type === "BROKER") {
    endpoint = `/api/upload_state_broker_file/`;
    actionType = types.EDIT_STATE_BROKER_FILE;
    activeStateFile = activeStateBrokerFileSelector(state);
  } else if (type === "PROVIDER") {
    endpoint = `/api/upload_state_provider_file/`;
    actionType = types.EDIT_STATE_PROVIDER_FILE;
    activeStateFile = activeStateProviderFileSelector(state);
  } else {
    return null;
  }

  notifications.success("Uploading State File...", file);
  const formData = new FormData();
  formData.append("id", activeStateFile.id);
  formData.append("file", file, file.name);

  try {
    const response = await advisorhq.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({ type: actionType, payload: response.data });

    const activeState = activeStateSelector(state);
    let stateId = activeState && activeState.id;

    // update state files
    const getStateResponse = await advisorhq.get("/api/state/" + stateId + "/");
    dispatch({ type: types.FETCH_STATE, payload: getStateResponse.data });

    notifications.success("State File updated successfully");
  } catch (error) {
    notifications.error("Error creating new State File");
  }
};

export const deleteStateFile = (id, type) => async (dispatch, getState) => {
  let endpoint;
  if (type === "BROKER") {
    endpoint = `/api/state_broker_file/${id}/`;
  } else if (type === "PROVIDER") {
    endpoint = `/api/state_provider_file/${id}/`;
  } else {
    return null;
  }
  await advisorhq.delete(endpoint);

  // update state files
  const activeState = activeStateSelector(getState());
  let stateId = activeState && activeState.id;
  const stateResponse = await advisorhq.get("/api/state/" + stateId + "/");
  dispatch({ type: types.FETCH_STATE, payload: stateResponse.data });
};

export const createStateFile = (type, data) => async (dispatch, getState) => {
  let endpoint;
  let actionType;
  if (type === "BROKER") {
    endpoint = `/api/state_broker_file/`;
    actionType = types.CREATE_STATE_BROKER_FILE;
  } else if (type === "PROVIDER") {
    endpoint = `/api/state_provider_file/`;
    actionType = types.CREATE_STATE_PROVIDER_FILE;
  } else {
    return null;
  }
  const activeState = activeStateSelector(getState());
  data.state_id = activeState.id;
  const response = await advisorhq.post(endpoint, data);
  dispatch({ type: actionType, payload: response.data });
  notifications.success("A new State File was created");

  // update state files
  let stateId = response.data.state_id;
  const stateResponse = await advisorhq.get("/api/state/" + stateId + "/");
  dispatch({ type: types.FETCH_STATE, payload: stateResponse.data });
};

export const editStateFile = (stateFileId, type, newValues) => async (dispatch, getState) => {
  notifications.success("Saving...");
  let endpoint;
  if (type === "BROKER") {
    endpoint = `/api/state_broker_file/${stateFileId}/`;
  } else if (type === "PROVIDER") {
    endpoint = `/api/state_provider_file/${stateFileId}/`;
  } else {
    return null;
  }
  await advisorhq.patch(endpoint, newValues);

  // update state files
  const activeState = activeStateSelector(getState());
  let stateId = activeState && activeState.id;
  const stateResponse = await advisorhq.get("/api/state/" + stateId + "/");
  dispatch({ type: types.FETCH_STATE, payload: stateResponse.data });
};

export const selectStateBrokerFile = data => ({
  type: types.SELECT_STATE_BROKER_FILE,
  payload: data,
});

export const selectStateProviderFile = data => ({
  type: types.SELECT_STATE_PROVIDER_FILE,
  payload: data,
});
