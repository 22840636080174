import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { RELATED_PARAMETERS_MODAL } from "components/modals";
import RelatedParametersForm from "./RelatedParameterForm";

const RelatedParametersModal = () => {
  return (
    <FullPageModal
      modalType={RELATED_PARAMETERS_MODAL}
      title="Add Related Parameter"
      style={{ content: { overflow: "visible" } }}
    >
      <RelatedParametersForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(RelatedParametersModal);
