import React from "react";
import { connect } from "react-redux";

import CaseFiles from "./CaseFiles";
import { activeCaseSelector, caseFilesSelector } from "reducers";
import { fetchCaseFiles, showModal, selectCaseFile } from "actions";
import { useParams } from "react-router-dom";
import { findObjectByPropertyValue } from "../../../utils";
import { CASE_FILE_MODAL } from "../../../components/modals";

const CaseFilesPage = props => {
  const { documentId } = useParams();
  const showModalCalledRef = React.useRef(false);

  React.useEffect(() => {
    // Check if caseFiles has changed
    if (documentId && props.caseFiles?.length && !showModalCalledRef.current) {
      let data = findObjectByPropertyValue(props.caseFiles, "id", parseInt(documentId));
      if (data) {
        props.showModal(CASE_FILE_MODAL);
        props.selectCaseFile(data);

        // Update the ref to indicate that showModal has been called
        showModalCalledRef.current = true;
      }
    }
  }, [props.caseFiles?.length]);

  return (
    <>
      <CaseFiles />
    </>
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
    caseFiles: caseFilesSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchCaseFiles,
  showModal,
  selectCaseFile,
})(CaseFilesPage);
