import React from "react";
import { connect } from "react-redux";

import {
  CASE_FILE_WARNING_MODAL,
  POLICY_REQUEST_MODAL,
  QUEST_HEALTH_REQUEST_MODAL,
  WINFLEX_ILLUSTRATION_MODAL,
} from "components/modals";
import CaseFileBaseForm from "./CaseFileBaseForm";
import {
  activeCaseFileSelector,
  activeCaseSelector,
  activeLifeExpectancySelector,
  insuredListSelector,
  ownerListSelector,
  caseFilesCategorySelector,
  caseFilesDescriptionSelector,
  storedCaseRequirementRecordsFiltersSelector,
  storedRecordsFiltersSelector,
  activeTabSelector,
  activeModalsSelector,
  doctorSelector,
} from "reducers";

import {
  deleteCaseFile,
  deleteCaseFileFile,
  deleteLifeExpectancyFile,
  editLifeExpectancy,
  fetchLifeExpectancies,
  uploadCaseFile,
  showModal,
  uploadRedactedFile,
  resetPolicyRequestFiles,
} from "actions";

import { isAdmin, isReadMode } from "permissions";
import LifeExpectancyForm from "../lifeExpectancies/LifeExpectancyForm";
import { isRedactableCaseFile, validateState } from "./utils";
import * as notifications from "../../../notifications";
import DoctorDetailForm from "./doctors/DoctorDetailForm";
import RedactedFileDropZone from "components/RedactedFileDropZone";
import SingleFileDropZone from "components/SingleFileDropZone";
import { MdSend } from "react-icons/md";
import File from "../../../components/File";

const CaseFileForm = ({ activeCaseFile, activeLifeExpectancy, hasAdminPermission, onlyBaseForm = false, ...props }) => {
  let caseFileIsSaved = activeCaseFile && activeCaseFile.id;
  const ref = React.useRef();
  const generalFormSubmitRef = React.useRef();
  const vendorSpecificFormSubmitRef = React.useRef();
  const mortalityTableSubmitRef = React.useRef();
  const doctorSubmitRef = React.useRef();

  const WideSaveButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="btn btn--secondary"
          onClick={() => {
            let errs = [];
            if (
              props.description === "CLARITY" ||
              props.description === "CONVERGENCE" ||
              props.description === "FOCUS"
            ) {
              errs = validateState(props.ownerList, props.insuredList, props.jurisdiction, props.category);
            }
            if (errs.length > 0) {
              if (props.activeCase) {
                if (props.activeCase.market_type === "TERTIARY") {
                  props.showModal(CASE_FILE_WARNING_MODAL, {
                    generalFormSubmitRef,
                    vendorSpecificFormSubmitRef,
                    mortalityTableSubmitRef,
                    errs,
                  });
                  return null;
                }
              }
              notifications.error(errs[0]);
              if (errs.length > 1) {
                notifications.error(errs[1]);
              }
              if (errs.length > 2) {
                notifications.error(errs[2]);
              }
              return null;
            }
            generalFormSubmitRef.current.click();
            vendorSpecificFormSubmitRef.current && vendorSpecificFormSubmitRef.current.click();
            mortalityTableSubmitRef.current && mortalityTableSubmitRef.current.click();
            doctorSubmitRef.current && doctorSubmitRef.current.click();
            setTimeout(() => {
              props.fetchLifeExpectancies();
            }, 500);
          }}
          style={{
            width: "99%",
            marginBottom: "1rem",
          }}
        >
          Save Changes
        </button>
      </div>
    );
  };

  function renderSecondaryForms() {
    if (hasAdminPermission) {
      let isMedicalRecord = activeCaseFile?.fr_id === "MR";
      if (props.activeCase && props.activeCase.id && caseFileIsSaved && isMedicalRecord) {
        return (
          <>
            <DoctorDetailForm submitRef={doctorSubmitRef} />
          </>
        );
      }

      let isQuestHealth = activeCaseFile?.fr_id === "QHPIQ";
      if (props.activeCase && props.activeCase.id && caseFileIsSaved && isQuestHealth) {
        return (
          <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
            <button
              className={`btn btn--secondary`}
              onClick={() => {
                props.showModal(QUEST_HEALTH_REQUEST_MODAL);
              }}
            >
              <MdSend />
              Start Quest Health Request
            </button>
          </div>
        );
      }

      const { activeCase, showModal, resetPolicyRequestFiles } = props;

      const isIllustration = activeCaseFile?.fr_id.includes("ILLUS");
      const isInforcePolicy =
        activeCaseFile &&
        (activeCaseFile.sub_category === "Illustration" || ["SA", "PAAC", "POLC"].includes(activeCaseFile.fr_id));

      const showWinFlexButton = activeCase?.id && caseFileIsSaved && isIllustration;
      const showPolicyRequestButton = activeCase?.id && caseFileIsSaved && isInforcePolicy;

      if (showWinFlexButton || showPolicyRequestButton) {
        return (
          <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
            {showWinFlexButton && (
              <button
                className="btn btn--secondary"
                style={{ "margin-right": "20px" }}
                onClick={() => showModal(WINFLEX_ILLUSTRATION_MODAL)}
              >
                <MdSend />
                Start WinFlex Illustration
              </button>
            )}

            {showPolicyRequestButton && (
              <button
                className="btn btn--secondary"
                onClick={() => {
                  showModal(POLICY_REQUEST_MODAL);
                  resetPolicyRequestFiles();
                }}
              >
                <MdSend />
                Start Policy Request
              </button>
            )}
          </div>
        );
      }

      if (activeLifeExpectancy) {
        return (
          <>
            <WideSaveButton ref={ref} />
            <LifeExpectancyForm
              vendor={activeCaseFile.fr_id}
              lifeExpectancy={activeLifeExpectancy}
              vendorSpecificFormSubmitRef={vendorSpecificFormSubmitRef}
              mortalityTableSubmitRef={mortalityTableSubmitRef}
            />
          </>
        );
      }
    }
  }

  function renderTimeFaxed() {
    if (activeCaseFile?.order_file) {
      let titleBoxStyle = {
        border: "1px solid #ced4da",
        padding: "10px",
        height: "91px",
        width: "150px",
        display: "flex",
        alignItems: "center",
        fontWeight: "bold",
      };
      let timeFaxed = "None";
      if (activeCaseFile.time_faxed) {
        const date = new Date(activeCaseFile.time_faxed);
        timeFaxed = date.toLocaleTimeString([], {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
      }

      return (
        <div style={{ padding: "0 15px" }}>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <div style={titleBoxStyle}>
              <div>{"Fax Order File:"}</div>
            </div>
            <div>
              <File file={activeCaseFile.order_file} fileDownloadUrl={activeCaseFile.order_file.url} />
            </div>
          </div>
          <b>Time Faxed:</b> {timeFaxed}
        </div>
      );
    }
  }

  function renderFileDropZone() {
    if (caseFileIsSaved && hasAdminPermission) {
      let DropZoneComponent;
      if (isRedactableCaseFile(activeCaseFile)) {
        DropZoneComponent = RedactedFileDropZone;
      } else {
        DropZoneComponent = SingleFileDropZone;
      }
      return (
        <div>
          <DropZoneComponent
            activeCaseFile={activeCaseFile}
            currentFile={activeCaseFile.file}
            uploadFile={props.uploadCaseFile}
            deleteFile={() => props.deleteCaseFileFile(activeCaseFile.id)}
            fileDownloadUrl={`/life_settlement/api/case_file/${activeCaseFile.id}/download_file/`}
            uploadRedactedFile={props.uploadRedactedFile}
          />
        </div>
      );
    }
  }

  return (
    <div>
      <CaseFileBaseForm submitRef={generalFormSubmitRef} ref={ref} />
      {!onlyBaseForm && renderSecondaryForms()}
      {hasAdminPermission && <WideSaveButton />}
      {!onlyBaseForm && renderFileDropZone()}
      {!onlyBaseForm && renderTimeFaxed()}
    </div>
  );
};

const mapStateToProps = state => {
  const activeCase = activeCaseSelector(state) || {};
  const caseFile = activeCaseFileSelector(state) || {};
  const hasAdminPermission = isAdmin(state);

  return {
    caseInfo: activeCaseSelector(state),
    caseFile,
    activeCaseFile: activeCaseFileSelector(state),
    activeLifeExpectancy: activeLifeExpectancySelector(state),
    category: caseFilesCategorySelector(state),
    description: caseFilesDescriptionSelector(state),
    insuredList: insuredListSelector(state),
    ownerList: ownerListSelector(state),
    jurisdiction: activeCase && activeCase.jurisdiction,
    activeCase,
    hasAdminPermission,
    readMode: isReadMode(state),
    doctorInfo: doctorSelector(state),
    activeModals: activeModalsSelector(state),
    activeTab: activeTabSelector(state),
    recordsFilters: storedRecordsFiltersSelector(state),
    caseRequirementRecordsFilters: storedCaseRequirementRecordsFiltersSelector(state),
  };
};

export default connect(mapStateToProps, {
  uploadCaseFile,
  uploadRedactedFile,
  deleteCaseFile,
  deleteCaseFileFile,
  deleteLifeExpectancyFile,
  editLifeExpectancy,
  fetchLifeExpectancies,
  showModal,
  resetPolicyRequestFiles,
})(CaseFileForm);
