import React from "react";
import { connect } from "react-redux";

import { URLS } from "../../constants";
import TopHeaderTabs from "../../components/TopHeaderTabs";

const RiskxPageHeader = ({ ...props }) => {
  const tabs = [
    { link: URLS.LIFE_EXPECTANCY, label: "LE Search" },
    { link: URLS.LE_PREDICTOR, label: "LE Predictor" },
    { link: URLS.PARAMETERS, label: "Health Parameters" },
    { link: URLS.SYNONYMS, label: "Synonyms" },
    { link: URLS.MORTALITY_ASSESSMENTS, label: "Mortality Assessments" },
  ];

  return <TopHeaderTabs tabs={tabs} />;
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps, {})(RiskxPageHeader);
