import React from "react";
import { useDispatch } from "react-redux";

import CopyCaseForm from "./CopyCaseForm";
import { COPY_CASE_MODAL } from "../../../components/modals";
import { hideModal } from "actions";
import SimpleModal from "../../../components/modals/SimpleModal";

// Case
export const CopyCaseModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <SimpleModal
      header={`Copy Case / Insured`}
      modalType={COPY_CASE_MODAL}
      onConfirm={() => {
        dispatch(hideModal(COPY_CASE_MODAL));
      }}
    >
      <CopyCaseForm id={id} />
    </SimpleModal>
  );
};

export default CopyCaseModal;
