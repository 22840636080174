/* Render a single medical factor */

import React from "react";
import { Col, Form } from "react-bootstrap";
import { FastField } from "formik";
import ReactSelect from "react-select";

import { RemoveButton } from "./MedicalCondition.js";

const BMI = "Body Mass Index (BMI)";

const MedicalFactor = props => {
  if (props.factor.name === BMI) {
    return <BMI_Factor {...props} />;
  }
  return <DefaultFactor {...props} />;
};

const DefaultFactor = ({
  factor,
  removeFunc,
  conditionIndex,
  factorIndex,
  notesPath = null, // "Path" to reach the notes
}) => {
  if (notesPath === null) {
    notesPath = `medical_info.conditions[${conditionIndex}].factors[${factorIndex}].notes`;
  }
  return (
    <Form.Row className="factor">
      <Col md={7}>
        <RemoveButton removeFunc={removeFunc} />
        <span className="factor__name">
          {factor.name} {factor.code && `(${factor.code})`}
        </span>
      </Col>
      <Col>
        <FastField as="input" name={notesPath} placeholder="Notes" className="factor__notes" />
      </Col>
    </Form.Row>
  );
};

const BMI_Factor = ({ factor, removeFunc, conditionIndex, factorIndex }) => {
  return (
    <Form.Row className="factor">
      <Col md={5}>
        <RemoveButton removeFunc={removeFunc} />
        <span className="factor__name">
          {factor.name} {factor.code && `(${factor.code})`}
        </span>
      </Col>
      <Col md={2}>
        <FastField
          as="input"
          type="number"
          step="0.1"
          name={`medical_info[${conditionIndex}].factors[${factorIndex}].value`}
          placeholder="BMI Value"
        />
      </Col>
      <Col>
        <FastField
          as="input"
          name={`medical_info[${conditionIndex}].factors[${factorIndex}].notes`}
          placeholder="Notes"
          className="factor__notes"
        />
      </Col>
    </Form.Row>
  );
};

export const AddFactorSelect = ({ factors, onChange }) => {
  // Don't show the select when there aren't factors available
  if (factors.length === 0) {
    return null;
  }

  return (
    <ReactSelect
      className="new-factor"
      placeholder="Add Medical Factor"
      value={null} // reset value on select
      menuPlacement="auto"
      options={factors.map(factor => {
        let label = factor.name;
        if (factor.code) {
          label += ` (${factor.code})`;
        }
        return {
          label,
          value: factor.name,
          code: factor.code,
        };
      })}
      onChange={onChange}
    />
  );
};

export const AddFamilyMedicalHistoryFactorSelect = ({ groups, onChange }) => {
  // Don't show the select when there aren't factors available
  if (groups.length === 0) {
    return null;
  }

  return (
    <ReactSelect
      className="new-factor"
      placeholder="Add Medical Factor"
      value={null} // reset value on select
      menuPlacement="auto"
      options={groups.map(group => {
        return {
          label: group.name,
          options: group.options.map(factor => {
            let label = factor.name;
            if (factor.code) {
              label += ` (${factor.code})`;
            }
            return {
              label,
              value: factor.name,
              code: factor.code,
              group: group.name,
            };
          }),
        };
      })}
      onChange={onChange}
    />
  );
};

export default MedicalFactor;
