import {
  SELECT_INSURED_DILIGENCE,
  FETCH_INSURED_DILIGENCES,
  EDIT_INSURED_DILIGENCE,
  CREATE_INSURED_DILIGENCE,
  DELETE_INSURED_DILIGENCE,
} from "actions/types";
import { removeKey } from "utils.js";
import { arrayToObjectIndexedById } from "reducers/utils";

export const defaultState = {
  activeInsuredDiligenceId: null,
  insuredDiligences: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_INSURED_DILIGENCE:
      return { ...state, activeInsuredDiligenceId: action.payload };

    case FETCH_INSURED_DILIGENCES:
      return { ...state, insuredDiligences: arrayToObjectIndexedById(action.payload) };

    case EDIT_INSURED_DILIGENCE:
    case CREATE_INSURED_DILIGENCE:
      return {
        ...state,
        insuredDiligences: {
          ...state.insuredDiligences,
          [action.payload.id]: action.payload,
        },
      };

    case DELETE_INSURED_DILIGENCE: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        insuredDiligences: removeKey(state.insuredDiligences, toDeleteId),
      };
      if (state.activeInsuredDiligenceId === toDeleteId) {
        newState.activeInsuredDiligenceId = null;
      }
      return newState;
    }

    default:
      return state;
  }
};

export const insuredDiligencesSelector = state =>
  Object.values(state.insuredDiligences);

export const activeInsuredDiligenceSelector = state => {
  const { activeInsuredDiligenceId, insuredDiligences } = state;

  if (activeInsuredDiligenceId === null) {
    return null;
  }
  return insuredDiligences[activeInsuredDiligenceId];
};

export const insuredDiligencesForActiveInsuredSelector = (state, activeInsuredId) => {
  const insuredDiligences = Object.values(state.insuredDiligences);
  return insuredDiligences.filter(le => le.insured === activeInsuredId);
};
