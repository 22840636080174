import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { editFunderContact, editInsuredInfo, fetchFunderContacts, unsetShouldSave } from "actions";
import {
  activeFunderContactIdSelector,
  activeFunderContactsSelector,
  activeFunderSelector,
  shouldSaveSelector,
} from "reducers";
import { TextInput, FormCollapsible, SubmitButton, EditorField } from "components/forms";
import * as notifications from "../../notifications";

const FunderContactForm = ({ funderContactInfo, ...props }) => {
  if (!funderContactInfo) {
    return null;
  }

  const submitRef = React.useRef();

  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={funderContactInfo}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await props.editFunderContact(props.activeFunderContactId, values);
            await props.fetchFunderContacts(props.funderInfo.id);
            notifications.success("Funder Contact saved successfully");
          } catch (error) {
            setErrors(error.response.data);
            notifications.error("Error editing Funder Contact");
          }
          props.unsetShouldSave();
        }}
      >
        {({ handleSubmit, values }) => {
          return (
            <FormCollapsible
              onSubmit={handleSubmit}
              title="Contact Information"
              id="contact-form"
              disabled={props.disabled}
            >
              <Form.Row>
                <TextInput label="First Name" name="first_name" md={3} />
                <TextInput label="Last Name" name="last_name" md={3} />
                <TextInput label="Title" name="title" md={3} />
                <TextInput label="Nickname" name="nickname" md={3} />
              </Form.Row>
              <Form.Row>
                <TextInput label="Office Phone" name="office_phone" md={3} />
                <TextInput label="Mobile Phone" name="mobile_phone" md={3} />
                <TextInput label="Email" name="email" lowercase={true} md={3} />
                <TextInput label="Secondary Email" name="secondary_email" lowercase={true} md={3} />
              </Form.Row>
              <Form.Row style={{ marginLeft: 5, marginRight: 5 }}>
                <EditorField label="Notes" name="notes" />
              </Form.Row>
              <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
            </FormCollapsible>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const activeFunderContactId = activeFunderContactIdSelector(state);
  const activeFunderContacts = activeFunderContactsSelector(state);
  let funderContactInfo;
  for (const funderContactInfoElement of activeFunderContacts) {
    if (funderContactInfoElement.id === activeFunderContactId) {
      funderContactInfo = funderContactInfoElement;
    }
  }
  return {
    funderInfo: activeFunderSelector(state),
    activeFunderContactId,
    funderContactInfo,
    shouldSave: shouldSaveSelector(state),
  };
};

export default connect(mapStateToProps, {
  editInsuredInfo,
  editFunderContact,
  unsetShouldSave,
  fetchFunderContacts,
})(FunderContactForm);
