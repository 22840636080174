import React from "react";

import Table from "components/table";
import { activeProductSelector } from "reducers";
import { connect } from "react-redux";

const ProductRelatedCasesTable = ({ product }) => {
  if (!product.related_cases) {
    return null;
  }

  const columns = [
    {
      Header: "Related Case Link",
      Cell: ({ row }) => {
        return <a href={`/cases/${row.original.id}/policy/`}>{row.original.name}</a>;
      },
    },
    {
      Header: "Gender",
      accessor: "gender",
      className: "wrap-text",
    },
    {
      Header: "Insured Age",
      accessor: "insured_age",
      className: "wrap-text",
    },
    {
      Header: "Rate Class",
      accessor: "rate_class",
      className: "wrap-text",
    },
    {
      Header: "Case Status",
      accessor: "status_name",
      className: "wrap-text",
    },
  ];

  return (
    <Table
      columns={columns}
      data={product.related_cases}
      defaultSort={true}
      showPaginationResults={false}
      defaultPagination={true}
      emptyMessage={"No Related Cases"}
    />
  );
};

const mapStateToProps = state => {
  const currentProduct = activeProductSelector(state) || {};
  console.log("currentProduct", currentProduct);

  return {
    product: currentProduct,
  };
};

export default connect(mapStateToProps, {})(ProductRelatedCasesTable);
