import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { QUEST_HEALTH_REQUEST_MODAL } from "components/modals";
import QuestHealthRequestForm from "./QuestHealthRequestForm";

const QuestHealthModal = ({ ...props }) => {
  return (
    <FullPageModal modalType={QUEST_HEALTH_REQUEST_MODAL} title={"Quest Health Request"}>
      <QuestHealthRequestForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(QuestHealthModal);
