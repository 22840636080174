import React from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import * as moment from "moment-timezone";

import { getValidationClassName } from "./validation";
import { DATETIME_FORMAT } from "constants.js";
import Label from "./Label";
import FieldErrors from "./FieldErrors";
import FieldHelpText from "./FieldHelpText";
import { makeDateNaive, moneyDisplay } from "utils.js";

import { calculateGrossOffer } from "../../actions";
import { connect } from "react-redux";

const toServerFormat = date => {
  if (date) {
    const dateStr = moment(date).format(DATETIME_FORMAT.SERVER.MOMENT);
    return makeDateNaive(dateStr);
  }
  return null;
};

/* Component that represents DateTime inputs.
 *
 * Internally has two fields:
 *  A hidden field that holds the value to be posted, and a presentational
 *  field using react-datepicker package.
 *
 * The posted datetimes will be "naive": we remove the tz info part
 * from the iso-8601 string representation and post just the date and time.
 * The server will assume the timezone is "America/New_York" and save the datetime
 * using that timezone. Therefore we ensure a consistent timezone for the posted dates
 * from the client.
 */
const LPEXFADateTimeInput = ({
  label,
  md = "",
  pickerDateFormat = DATETIME_FORMAT.DISPLAY.PICKER,
  name,
  isRequired,
  ...props
}) => {
  const [field, meta] = useField({ name });
  const { setFieldValue } = useFormikContext();

  const validateClassName = getValidationClassName(meta);

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      <Label label={label} required={isRequired} />
      <input type="hidden" {...field} value={toServerFormat(field.value) || ""} />
      <InputGroup>
        <DatePicker
          {...props}
          autoComplete="off"
          className={`form-control ${validateClassName}`}
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          showTimeInput
          selected={(field.value && moment(makeDateNaive(field.value), DATETIME_FORMAT.SERVER.MOMENT).toDate()) || null}
          onChange={async e => {
            setFieldValue(field.name, toServerFormat(e));
            const response = await props.calculateGrossOffer(toServerFormat(e), props.total_offer);
            setFieldValue("display_lr_spread", moneyDisplay(response.data.display_lr_spread));
            if (response.data.display_gross_offer === "There are no available rounds on this date") {
              setFieldValue("display_gross_offer", response.data.display_gross_offer);
            } else {
              setFieldValue("display_gross_offer", moneyDisplay(response.data.display_gross_offer));
            }
          }}
          dateFormat={pickerDateFormat}
        />
        <FieldErrors meta={meta} />
      </InputGroup>
      <FieldHelpText>Please enter time in EST timezone</FieldHelpText>
    </Form.Group>
  );
};

export default connect(null, {
  calculateGrossOffer,
})(LPEXFADateTimeInput);
