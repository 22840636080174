import React from "react";
import { connect } from "react-redux";

import {
  editFunderAuctionActivity,
  editMarketAuctionActivity,
  createFunderAuctionActivity,
  createMarketAuctionActivity,
  hideModal,
} from "actions";
import { marketWinningBidSelector, funderWinningBidSelector } from "reducers";
import { AUCTION_ACTIVITY_CHANGE_WINNING_BID_MODAL } from "components/modals";
import { partial } from "../../../../utils";
import * as notifications from "../../../../notifications";
import ConfirmationModal from "components/modals/ConfirmationModal";

const ChangeWinningBidModal = ({ activityValues, funderWinningBidId, marketWinningBidId, ...props }) => {
  var validatedCurrentWinningBidId = funderWinningBidId;
  if (activityValues && activityValues.market_winning_bid && !activityValues.funder_winning_bid) {
    validatedCurrentWinningBidId = marketWinningBidId;
  }

  let method = "";
  if (activityValues.mode === "funder") {
    method = activityValues.id
      ? partial(props.editFunderAuctionActivity, activityValues.id)
      : props.createFunderAuctionActivity;
  } else if (activityValues.mode === "market") {
    method = activityValues.id
      ? partial(props.editMarketAuctionActivity, activityValues.id)
      : props.createMarketAuctionActivity;
  }

  return (
    <ConfirmationModal
      modalType={AUCTION_ACTIVITY_CHANGE_WINNING_BID_MODAL}
      onConfirm={async () => {
        // When confirmed, mark the old winning bid as winning_bid=false, and
        // send the edit/create to the api.
        // date is being passed because it is required by the serializer
        const payload = {
          funder_winning_bid: false,
          market_winning_bid: false,
        };
        if (activityValues.mode === "funder") {
          payload.date = props.funderWinningBid.date;
          await props.editFunderAuctionActivity(validatedCurrentWinningBidId, payload);
        } else if (activityValues.mode === "market") {
          payload.date = props.marketWinningBid.date;
          await props.editMarketAuctionActivity(validatedCurrentWinningBidId, payload);
        }
        await method(activityValues);
        notifications.success("Activity Detail saved successfully");
        notifications.success("Winning Bid updated successfully");
        props.hideModal(AUCTION_ACTIVITY_CHANGE_WINNING_BID_MODAL);
      }}
      onCancel={() => {
        // When canceled, just save the form values changing winning_bid to false
        method({
          ...activityValues,
          funder_winning_bid: false,
          market_winning_bid: false,
        });
        notifications.success("Activity Detail saved successfully (Winning Bid saved as 'No')");
      }}
    >
      A Winning Bid activity already exists for this auction. Do you want to mark this one as the Winning Bid instead?
    </ConfirmationModal>
  );
};

const mapStateToProps = (state, ownProps) => {
  const funderWinningBid = funderWinningBidSelector(state);
  const marketWinningBid = marketWinningBidSelector(state);
  return {
    funderWinningBidId: funderWinningBid ? funderWinningBid.id : null,
    marketWinningBidId: marketWinningBid ? marketWinningBid.id : null,
    funderWinningBid,
    marketWinningBid,
  };
};

export default connect(mapStateToProps, {
  editFunderAuctionActivity,
  editMarketAuctionActivity,
  createFunderAuctionActivity,
  createMarketAuctionActivity,
  hideModal,
})(ChangeWinningBidModal);
