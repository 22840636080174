import React from "react";
import { moneyDisplay } from "../../utils";

const boldParagraphTitleStyle = {
  fontWeight: "bold",
  marginBottom: "0.2rem",
  paddingLeft: 15,
  backgroundColor: "#999999",
  color: "white",
  width: "100%",
  padding: "5px",
};

const valueStyle = {
  padding: "8px 0 0 10px",
  minHeight: "36px",
  border: "1px solid #cdc8c8",
  borderRadius: "3px",
  margin: "0!important",
  marginBottom: "0",
};

export const DisplayValueWithTitle = ({ title, value, md = "", ...props }) => {
  return (
    <div className={`col-md${md && "-" + md} padding0`}>
      <p style={boldParagraphTitleStyle}>{title}:</p>
      <p style={valueStyle}>{value}</p>
    </div>
  );
};

export const DisplayCurrencyValueWithTitle = ({ title, value, md = "", ...props }) => {
  return (
    <div className={`col-md${md && "-" + md} padding0`}>
      <p style={boldParagraphTitleStyle}>{title}:</p>
      <p style={valueStyle}>{moneyDisplay(value)}</p>
    </div>
  );
};
