import React from "react";

const SectionDetails = ({ details = {} }) => {
  return (
    <dl>
      {Object.entries(details).map(([key, val], idx) => {
        return (
          <div className="detail" key={key}>
            <dt key={`dt-${idx}`}>{key}</dt>
            <dd key={`dd-${idx}`}>{val || "-----"}</dd>
          </div>
        );
      })}
    </dl>
  );
};
export default SectionDetails;
