import React from "react";
import { connect } from "react-redux";

import { showModal, selectAccountAffiliation } from "actions";
import { activeAccountSelector } from "reducers";
import Table from "components/table";

const TeamMemberTable = ({ activeAccount, ...props }) => {
  if (!activeAccount || !props.teamMembers) {
    // Still didn't fetch data
    return null;
  }

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }) => {
        if (row.original.name) {
          return (
            <div className="text-wrap">
              <a href={`/accounts/${row.original.id}/information/`} target="_blank" rel="noreferrer">
                {row.original.name}
              </a>
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Work Phone",
      accessor: "work_phone",
    },
    {
      Header: "Cell Phone",
      accessor: "cell_phone",
    },
    {
      Header: "Email Address",
      accessor: "email",
    },
  ];

  return (
    <Table
      columns={columns}
      data={props.teamMembers}
      defaultSort={true}
      paginated={false}
      darker={true}
      emptyMessage="No Team Members found"
    />
  );
};

const mapStateToProps = state => {
  const activeAccount = activeAccountSelector(state);

  return {
    activeAccount,
    teamMembers: activeAccount ? activeAccount.team_members : [],
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectAccountAffiliation,
})(TeamMemberTable);
