import {
  SELECT_PARAMETER,
  FETCH_PARAMETERS,
  EDIT_PARAMETER,
  CREATE_PARAMETER,
  DELETE_PARAMETER,
  FETCH_PARAMETER,
  LOADING,
} from "actions/types";
import { arrayToObjectIndexedById } from "reducers/utils";
import { removeKey } from "../../../utils";

export const defaultState = {
  parameters: {},
  activeParameterId: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_PARAMETER:
      return { ...state, activeParameterId: action.payload };

    case FETCH_PARAMETER: {
      return {
        ...state,
        parameters: {
          ...state.parameters,
          [action.payload.id]: action.payload,
        },
      };
    }

    case FETCH_PARAMETERS:
      return { ...state, parameters: arrayToObjectIndexedById(action.payload) };

    case EDIT_PARAMETER:
    case CREATE_PARAMETER: {
      return {
        ...state,
        parameters: {
          ...state.parameters,
          [action.payload.id]: action.payload,
        },
        activeParameterId: action.payload.id,
      };
    }

    case DELETE_PARAMETER: {
      return {
        ...state,
        parameters: removeKey(state.parameters, action.payload),
      };
    }
    case LOADING:
      if (action.payload.parameters) {
        return {
          ...state,
          parameters: arrayToObjectIndexedById([]),
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export const activeParameterSelector = ({ parameters, activeParameterId }) => {
  if (activeParameterId) {
    return parameters[activeParameterId];
  }
};

export const activeParameterIdSelector = state => state.activeParameterId;

export const parametersSelector = state => {
  return Object.values(state.parameters);
};
