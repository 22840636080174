import { FETCH_AGENTS_CHOICES } from "actions/types";
import { choicesWrapper, undefinedChoicesProxy } from "options.js";

export default (state = undefinedChoicesProxy, action) => {
  switch (action.type) {
    case FETCH_AGENTS_CHOICES: {
      let choices = {};
      Object.entries(action.payload).forEach(([key, value]) => {
        let inverted_name = value.map(e => {
          return {
            value: e.value,
            label: e.label,
            email: e.email,
            phone: e.phone,
          };
        });
        choices[key] = choicesWrapper(inverted_name);
      });
      return { ...choices };
    }
    default:
      return state;
  }
};

export const userChoicesSelector = state => state.users;
