import React from "react";
import { Form } from "react-bootstrap";

import Collapsible from "components/Collapsible";

const renderDisabledFields = children => {
  return React.Children.map(children, child => {
    if (!child.props) {
      return child;
    }

    if (child.props.children) {
      return React.cloneElement(child, {
        children: renderDisabledFields(child.props.children),
        disabled: true,
        isDisabled: true,
      });
    }

    return React.cloneElement(child, { disabled: true, isDisabled: true });
  });
};

export default ({ title, children, onSubmit, className = "", ...props }) => {
  let fields = props.disabled ? renderDisabledFields(children) : children;

  return (
    <Form onSubmit={onSubmit} className={className + " form shadowed"} {...props}>
      <Collapsible title={title}>{fields}</Collapsible>
    </Form>
  );
};
