import { sortBy } from "lodash";

/* Helper function to wrap a list of choices, letting us
 * lookup choices by value or by label.
 *
 * @param { array } choices: List of objects with "value"
 * and "label" keys.
 */
export const choicesWrapper = (choices = []) => {
  return {
    getChoices({ group, sortByLabel = false } = {}) {
      let selectedChoices = group ? choices[group] : choices;
      if (sortByLabel) {
        return sortBy(selectedChoices, choice => choice.label);
      }
      return selectedChoices;
    },
    getLabel(value, group) {
      const choice = this.getChoices({ group }).find(o => o.value === value);
      return choice && choice.label;
    },
    getValue(label, group) {
      const choice = this.getChoices({ group }).find(o => o.label === label);
      return choice && choice.value;
    },
  };
};

const _undefinedHandler = {
  get(target, name) {
    return {
      getChoices(group) {
        return [];
      },
      getLabel: value => "",
      getValue: label => "",
    };
  },
};
// Because most choices are (async-ly) retrieved from the server,
// at first the choices will be undefined. This is a helper function
// to avoid checking for undefined everywhere.
//
// Assumes choices are stored in an object with each key being the choices name,
// and the value the actual list of choices. For example:
//   const choices = {
//      gender: genderChoices
//   }
//
// When defining a redux store key to hold choices, initialize the choices
// to undefinedChoicesProxy, and then each expected choice can be used in the
// components without having to check for undefined.
//
// MUST have extra care with key names: a spell error will not raise any error.
//
// Example:
//  const choices = undefinedChoicesProxy;
//  choices.FOO.choices  -> returns [], although FOO does not even exist
//  choices.BAR.getValue("bar") -> returns ""
export const undefinedChoicesProxy = new Proxy({}, _undefinedHandler);

export const genderChoices = choicesWrapper([
  { value: "F", label: "Female" },
  { value: "M", label: "Male" },
]);

export const numberChoices = n => {
  let choices = [];
  for (let i = 1; i <= n; i++) {
    choices.push({ value: i, label: i });
  }
  return choices;
};

export const usStatesChoices = choicesWrapper([
  { value: "AK", label: "Alaska" },
  { value: "AL", label: "Alabama" },
  { value: "AR", label: "Arkansas" },
  { value: "AZ", label: "Arizona" },
  { value: "BC", label: "British Columbia" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DC", label: "District of Columbia" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "IA", label: "Iowa" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "XX", label: "International" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "MA", label: "Massachusetts" },
  { value: "MD", label: "Maryland" },
  { value: "ME", label: "Maine" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MO", label: "Missouri" },
  { value: "MS", label: "Mississippi" },
  { value: "MT", label: "Montana" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "NE", label: "Nebraska" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NV", label: "Nevada" },
  { value: "NY", label: "New York" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "ON", label: "Ontario" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "PR", label: "Puerto Rico" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "3R", label: "Tertiary" },
  { value: "US", label: "United States" },
  { value: "UK", label: "Unknown" },
  { value: "UT", label: "Utah" },
  { value: "VA", label: "Virginia" },
  { value: "VT", label: "Vermont" },
  { value: "WA", label: "Washington" },
  { value: "WI", label: "Wisconsin" },
  { value: "WV", label: "West Virginia" },
  { value: "WY", label: "Wyoming" },
]);

export const funderEntityUsStatesChoices = choicesWrapper([
  { value: "AK", label: "Alaska" },
  { value: "AL", label: "Alabama" },
  { value: "AR", label: "Arkansas" },
  { value: "AZ", label: "Arizona" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DC", label: "District of Columbia" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "IA", label: "Iowa" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "XX", label: "International" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "MA", label: "Massachusetts" },
  { value: "MD", label: "Maryland" },
  { value: "ME", label: "Maine" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MO", label: "Missouri" },
  { value: "MS", label: "Mississippi" },
  { value: "MT", label: "Montana" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "NE", label: "Nebraska" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NV", label: "Nevada" },
  { value: "NY", label: "New York" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "PR", label: "Puerto Rico" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "3R", label: "Tertiary" },
  { value: "US", label: "United States" },
  { value: "UK", label: "Unknown" },
  { value: "UT", label: "Utah" },
  { value: "VA", label: "Virginia" },
  { value: "VT", label: "Vermont" },
  { value: "WA", label: "Washington" },
  { value: "WI", label: "Wisconsin" },
  { value: "WV", label: "West Virginia" },
  { value: "WY", label: "Wyoming" },
]);

export const countriesChoices = [
  { value: "US", label: "United States" },
  { value: "BM", label: "Bermuda" },
  { value: "CA", label: "Canada" },
  { value: "KY", label: "Cayman Islands" },
  { value: "DK", label: "Denmark" },
  { value: "FR", label: "France" },
  { value: "DE", label: "Germany" },
  { value: "IE", label: "Ireland" },
  { value: "IL", label: "Israel" },
  { value: "LI", label: "Lichtenstein" },
  { value: "LU", label: "Luxembourg" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "BS", label: "The Bahamas" },
  { value: "GB", label: "United Kingdom" },
];

export const symbolChoices = choicesWrapper([
  { value: "", label: "None" },
  { value: "+", label: "+" },
  { value: "++", label: "++" },
  { value: "-", label: "-" },
]);
