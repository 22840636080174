import React from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import { useField, useFormikContext } from "formik";

import { isInvalid } from "./validation";
import Label from "./Label";
import FieldErrors from "./FieldErrors";
import FieldHelpText from "./FieldHelpText";
import { FaRegCopy } from "react-icons/all";
import * as notifications from "notifications.js";

const HyperLinkText = ({ label, md = "", helpText, isRequired = false, inline = true, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  if (!props.autoComplete) {
    props.autoComplete = "off";
  }

  const copyToClipboard = e => {
    e.preventDefault();
    const el = document.createElement("textarea");
    let copyText = document.getElementsByName(field.name)[0];
    el.value = copyText.value;
    document.body.appendChild(el);
    el.select();
    el.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy"); /* Copy the text inside the text field */
    notifications.success("Copied to Clipboard!");
    document.body.removeChild(el);
  };

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      {label && !inline ? <Label label={label} required={isRequired} /> : null}
      <a href={props.url} target="_blank" rel="noreferrer">
        <InputGroup>
          {label && inline ? (
            <InputGroup.Prepend>
              <InputGroup.Text>
                <div style={{ marginRight: 3 }}>{label}</div>
              </InputGroup.Text>
            </InputGroup.Prepend>
          ) : null}
          <Form.Control
            style={{
              cursor: "pointer",
              backgroundColor: "#e9ecef",
              pointerEvents: "none",
            }}
            {...field}
            {...props}
            onChange={e => {
              let value = e.target.value;
              setFieldValue(field.name, value);
            }}
            id={field.name}
            isInvalid={isInvalid(meta)}
          />
          {props.copy && (
            <InputGroup.Append onClick={e => copyToClipboard(e)}>
              <InputGroup.Text>
                <FaRegCopy />
              </InputGroup.Text>
            </InputGroup.Append>
          )}
          <FieldErrors meta={meta} />
        </InputGroup>
      </a>
      {helpText && <FieldHelpText>{helpText}</FieldHelpText>}
    </Form.Group>
  );
};

export default HyperLinkText;
