import React from "react";
import { useDispatch } from "react-redux";

import ConfirmationModal from "components/modals/ConfirmationModal";
import { CONFIRM_FILE_REPLACE_MODAL } from "components/modals";
import { hideModal } from "actions";

export default props => {
  const dispatch = useDispatch();

  return (
    <ConfirmationModal
      modalType={CONFIRM_FILE_REPLACE_MODAL}
      onConfirm={() => {
        props.onConfirm();
        dispatch(hideModal(CONFIRM_FILE_REPLACE_MODAL));
      }}
    >
      Uploading a new file will override the current uploaded file. Do you want to
      continue?
    </ConfirmationModal>
  );
};
