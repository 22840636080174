import React from "react";
import { connect } from "react-redux";

import { createEmptyFunder, setActiveTab, initPagination } from "actions";
import FundersFilterForm from "./FundersListFilterForm";
import FundersTable from "./FundersListTable";
import ButtonCreate from "../../components/buttons/ButtonCreate";
import FunderPageHeader from "./FunderPageHeader";

const FundersListPage = props => {
  const [activeSection, setActiveSection] = React.useState("funders");
  React.useEffect(() => {
    props.setActiveTab(activeSection);
    props.initPagination();
    setActiveSection(activeSection);
    document.title = "LifeRoc | Funders";
  }, []);

  return (
    <>
      <FunderPageHeader />
      <div className={`section-card shadowed case-section`} style={{ marginTop: "1.5rem" }}>
        <FundersFilterForm />
        <ButtonCreate id="create-new-case" onClick={props.createEmptyFunder} key="create">
          Add Funder
        </ButtonCreate>
        <FundersTable />
      </div>
    </>
  );
};

export default connect(null, {
  createEmptyFunder,
  setActiveTab,
  initPagination,
})(FundersListPage);
