import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { TextInput, FormFilter, SubmitSearchButton, ClearFiltersButton } from "components/forms";
import { fetchCarriers } from "actions";

const CarriersFilterForm = ({ fetchCarriers, ...props }) => {
  return (
    <Formik
      initialValues={{
        name: "",
      }}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        await fetchCarriers(values);
        setSubmitting(false);
      }}
      onReset={async (values, { setSubmitting }) => {
        await fetchCarriers();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, resetForm }) => {
        return (
          <FormFilter title={"Carrier Search"} onSubmit={handleSubmit}>
            <Form.Row>
              <TextInput label="Name" name="name" />
              <TextInput label="Short Name" name="short_name" />
            </Form.Row>
            <SubmitSearchButton />
            <ClearFiltersButton onClick={resetForm} />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

export default connect(null, {
  fetchCarriers,
})(CarriersFilterForm);
