import React from "react";

import MortalityTable from "../MortalityTable.js";

// Columns indexes
const [YEAR, PROBABILITY] = [0, 1];

// Helper object to manage rows
const Row = {
  values: [],

  get year() {
    return this._get(YEAR);
  },

  get age() {
    return this._get(PROBABILITY);
  },

  addError(idx, error) {
    const col = this.values[idx];
    if (!col.errors) {
      col.errors = [error];
    } else {
      const found = col.errors.find(e => e.code === error.code);
      if (!found) {
        col.errors.push(error);
      }
    }
  },

  removeError(idx, error) {
    const col = this.values[idx];
    if (col.errors) {
      col.errors = col.errors.filter(err => err.code !== error.code);
    }
  },

  createEmpty(year) {
    const values = [{ value: year }, { value: "" }];
    return createRow(values);
  },

  _get(idx) {
    return Number(this.values[idx].value);
  },
};

// Helper func to create row objects using Row as prototype
const createRow = values => {
  return Object.create(Row, { values: { value: values } });
};

// Validate mortality table data
const validate = values => {
  if (!values || values.length === 0) {
    return values;
  }

  const title = values[0];
  let rows = values.slice(1);

  // Create Row objects
  rows = rows.map(rowValues => createRow(rowValues));

  // Years must be consecutive, from 1 to max-year. Fill with blanks those missing rows.
  const years = new Set(rows.map(row => row.year));
  const maxYear = Math.max(...years);
  const missingRows = [];
  for (let year = 1; year < maxYear; year++) {
    if (!years.has(year)) {
      missingRows.push(Row.createEmpty(year));
    }
  }
  rows = [...rows, ...missingRows].sort((r1, r2) => r1.year - r2.year);

  // convert back Row objects to vanilla objects
  const validated = rows.map(row => row.values);

  return [title, ...validated];
};

export default ({ submitRef }) => <MortalityTable validate={validate} submitRef={submitRef} />;
