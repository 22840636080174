import React from "react";

import { Form } from "react-bootstrap";

// Display help text for form fields. Add a negative margin to decrease the space
// between form rows when a field has this help text.
const FieldHelpText = ({ children }) => {
  return <Form.Text muted>{children}</Form.Text>;
};

export default FieldHelpText;
