import React from "react";
import { Form, InputGroup, Col } from "react-bootstrap";
import { useField, useFormikContext } from "formik";

import { isInvalid } from "./validation";
import Label from "./Label";
import FieldErrors from "./FieldErrors";
import { getViewMode } from "../../utils";

const CurrencyInput = ({ label, allowDecimal = true, decimalLimit = 2, md = "", ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const isViewMode = getViewMode();
  const isDisabled = props.disabled || isViewMode;

  function mask(value) {
    value = value.replace(",", "").replace(/[^\d.-]/g, "");
    let result = value;
    if (value.length === 1) {
      result = value * 0.01;
    } else if (value.includes(".")) {
      let res = value.substring(value.indexOf(".") + 1);
      if (res.length > 2) {
        result = value * 10;
      }
    }
    if (result) {
      result = Number(result);
    }
    return result.toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <div style={{ marginLeft: 1, marginRight: 3 }}>{label}</div>
          </InputGroup.Text>
          <InputGroup.Text id="dollarSign">$</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          {...field}
          {...props}
          disabled={isDisabled}
          onChange={e => {
            let maskedValue = mask(e.target.value);
            setFieldValue(field.name, maskedValue);
          }}
          isInvalid={isInvalid(meta)}
        />
        <FieldErrors meta={meta} />
      </InputGroup>
    </Form.Group>
  );
};

export default CurrencyInput;
