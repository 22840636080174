import React from "react";
import { connect } from "react-redux";
import { Table as BootstrapTable } from "react-bootstrap";

import { ownerSignersSelector } from "reducers";

const OwnerEntityInfo = ({ signers }) => {
  if (signers.length === 0) {
    return "No signers found";
  }

  const displayPOA = signer => {
    if (signer.active_POA === null) {
      return "";
    }
    return signer.active_POA ? "Yes" : "No";
  };

  return (
    <BootstrapTable>
      <thead>
        <tr>
          <th>Signer Name</th>
          <th>Signer Title</th>
          <th>POA in Effect</th>
          <th>POA Name</th>
        </tr>
      </thead>
      <tbody>
        {signers.map(signer => {
          return (
            <tr>
              <td>{signer.full_legal_name}</td>
              <td>{signer.title}</td>
              <td>{displayPOA(signer)}</td>
              <td>{signer.POA_name}</td>
            </tr>
          );
        })}
      </tbody>
    </BootstrapTable>
  );
};

const mapStateToProps = (state, { ownerEntityId }) => {
  return {
    signers: ownerSignersSelector(state, ownerEntityId),
  };
};

export default connect(mapStateToProps)(OwnerEntityInfo);
