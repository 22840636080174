/* Insured medical conditions */
import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { FieldArray } from "formik";
import Select from "react-select";

import { partial } from "../../../../utils";
import { fetch21stMedicalConditionsSchema } from "actions";
import { twentyFirstMedicalConditionsSchemaSelector } from "reducers";
import MedicalCondition from "./MedicalCondition";

const MedicalConditionsList = ({ medicalInfo, conditionsSchema, ...props }) => {
  React.useEffect(() => {
    props.fetch21stMedicalConditionsSchema();
  }, []);

  return (
    <>
      <Form.SectionTitle>Conditions & Factors</Form.SectionTitle>
      <FieldArray
        name="medical_info.conditions" // key name under which medical conditions are stored
        validateOnChange={false}
        render={arrayHelpers => (
          <>
            {medicalInfo &&
              medicalInfo.conditions &&
              medicalInfo.conditions.map((condition, index, conditions) => (
                <MedicalCondition
                  condition={condition}
                  conditionIndex={index}
                  removeCondition={partial(arrayHelpers.remove, index)}
                />
              ))}
            <Select
              className="new-condition"
              value={null} // reset on select
              menuPlacement="top"
              placeholder="Add Medical Condition"
              options={conditionsSchema
                .filter(condition => !medicalInfo || !medicalInfo.conditions?.find(c => c.name === condition.name))
                .map(condition => {
                  return { label: condition.name, value: condition.name };
                })}
              onChange={option => {
                arrayHelpers.push({ name: option.label, top: false, factors: [] });
              }}
            />
          </>
        )}
      />
    </>
  );
};

const mapStateToProps = state => {
  return { conditionsSchema: twentyFirstMedicalConditionsSchemaSelector(state) };
};

export default connect(mapStateToProps, { fetch21stMedicalConditionsSchema })(MedicalConditionsList);
