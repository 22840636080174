import { FETCH_ORDER_FILES, LOADING, RESET_ORDER_FILES } from "../types";
import advisorhq from "../../apis/advisorhq";
import * as notifications from "../../notifications";

export const generateAPSOrderFiles = (caseDocumentId, caseId, values) => async dispatch => {
  dispatch({ type: LOADING, payload: { apsOrderFiles: true } });
  try {
    const response = await advisorhq.post(
      `/life_settlement/api/generate_aps_order/${caseDocumentId}/${caseId}/`,
      values,
    );
    dispatch({ type: FETCH_ORDER_FILES, payload: response.data });
  } catch (e) {
    notifications.error(`Error Generating APS Order: ${e.response.data.error}`);
  } finally {
    dispatch({ type: LOADING, payload: { apsOrderFiles: false } });
  }
};

export const resetAPSOrderFiles = () => async (dispatch, getState) => {
  dispatch({ type: RESET_ORDER_FILES });
};

export const sendAPSOrderFax = caseDocumentId => async dispatch => {
  dispatch({ type: LOADING, payload: { fax: true } });
  try {
    let response = await advisorhq.post(`/life_settlement/api/send_aps_order_fax/${caseDocumentId}/`);
    notifications.success(`APS Order Fax Sent successfully`);
    return response;
  } catch (e) {
    notifications.error(`Error Generating APS Order: ${e.response.data.error}`);
  } finally {
    dispatch({ type: LOADING, payload: { fax: false } });
  }
};
