import React from "react";
import { getViewMode } from "../../utils";
import { connect } from "react-redux";
import { setShouldSave } from "actions";

const ButtonSaveChanges = props => {
  const isViewMode = getViewMode();
  if (isViewMode) return;

  return (
    <div style={{ display: "flex", justifyContent: "center", margin: 0 }}>
      <button
        className="btn btn--secondary navbar-save-button"
        onClick={async () => {
          await props.setShouldSave();
        }}
        style={{
          width: "100%",
        }}
      >
        Save Changes
      </button>
    </div>
  );
};

export default connect(null, {
  setShouldSave,
})(ButtonSaveChanges);
