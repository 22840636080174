import React from "react";
import { connect } from "react-redux";
import { IconContext } from "react-icons";
import { HiDownload } from "react-icons/hi";
import { BiShow } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";

import { CONFIRM_FILE_DELETE_MODAL } from "components/modals";
import { showModal, downloadFile } from "actions";

import IconButton from "components/IconButton";
import FileIcon from "./FileIcon";
import { getViewUrl } from "../utils";

const File = ({ file, ...props }) => {
  if (file === null) {
    return null;
  }

  let viewUrl = getViewUrl(file);
  return (
    <div className="file-display" style={{ ...props.style }}>
      <div className="file-icon">
        <IconContext.Provider value={{ size: "2rem" }}>
          <FileIcon fileName={file.name} />
        </IconContext.Provider>
      </div>
      <div className="file-details">
        <div className="file-name">{file.name}</div>
        <div className="size-and-actions">
          <small className="text-muted">{file.size}</small>
          <div className="action-icons">
            {props.fileDownloadUrl ? (
              <div className="icon">
                <IconButton
                  Icon={HiDownload}
                  onClick={() => props.downloadFile(props.fileDownloadUrl, file.name)}
                  iconConfig={{ size: "1.2rem" }}
                  title="Download File"
                />
              </div>
            ) : null}
            <div className="icon">
              <IconButton
                Icon={BiShow}
                onClick={() => window.open(viewUrl, "_blank")}
                iconConfig={{ size: "1.2rem" }}
                title="View File"
              />
            </div>
            {props.deleteFile ? (
              <div className="icon">
                <IconButton
                  Icon={AiOutlineDelete}
                  onClick={() =>
                    props.showModal(CONFIRM_FILE_DELETE_MODAL, {
                      onConfirm: () => props.deleteFile(),
                    })
                  }
                  iconConfig={{ size: "1.2rem" }}
                  title="Delete File"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { showModal, downloadFile })(File);
