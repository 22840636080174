import React, { useEffect } from "react";
import { connect } from "react-redux";

import { activeMarketAuctionActivitySelector } from "reducers";
import FullPageModal from "components/modals/FullPageModal";
import { SEND_MARKET_ACTIVITY_EMAIL_MODAL } from "components/modals";
import SendMarketActivityEmailForm from "./SendMarketActivityEmailForm";
import { deleteAuctionActivityFile, generateMarketActivityFiles } from "actions";

const SendMarketActivityEmailModal = ({ activeMarketAuctionActivity, ...props }) => {
  useEffect(() => {
    if (activeMarketAuctionActivity) {
      props.generateMarketActivityFiles(activeMarketAuctionActivity, 1);
    }
  }, [activeMarketAuctionActivity?.id]);

  return (
    <FullPageModal modalType={SEND_MARKET_ACTIVITY_EMAIL_MODAL} title="Send Market Activity Email">
      <SendMarketActivityEmailForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    activeMarketAuctionActivity: activeMarketAuctionActivitySelector(state),
  };
};

export default connect(mapStateToProps, {
  deleteAuctionActivityFile,
  generateMarketActivityFiles,
})(SendMarketActivityEmailModal);
