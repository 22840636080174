import {
  DELETE_INSURED_INFO,
  EDIT_INSURED_INFO,
  FETCH_INSURED,
  FETCH_LIFE_SETTLEMENT_CASE,
  LOADING,
  SELECT_INSURED,
} from "actions/types";
import * as notifications from "notifications.js";
import advisorhq from "apis/advisorhq";
import { ValidationError } from "exceptions.js";
import { activeCaseIdSelector, insuredListSelector } from "reducers";

export const selectInsured = id => {
  return { type: SELECT_INSURED, payload: id };
};

export const fetchInsured = caseId => async dispatch => {
  const endpoint = `/life_settlement/api/insured_information/`;
  const response = await advisorhq.get(endpoint, { params: { case: caseId } });
  dispatch({ type: FETCH_INSURED, payload: response.data });
  dispatch({ type: LOADING, payload: { insured: false } });
};

export const editInsuredInfo = (insuredInfoId, newValues) => async dispatch => {
  const endpoint = `/life_settlement/api/insured_information/${insuredInfoId}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  dispatch({ type: EDIT_INSURED_INFO, payload: response.data });
};

export const deleteInsuredInfo = insuredInfoId => async (dispatch, getState) => {
  const endpoint = `/life_settlement/api/insured_information/${insuredInfoId}/`;
  await advisorhq.delete(endpoint);
  dispatch(selectInsured(null)); // reset selected section
  dispatch({ type: DELETE_INSURED_INFO, payload: insuredInfoId });
  notifications.success("Insured information deleted successfully");

  // Re-fetch case
  const activeCaseId = activeCaseIdSelector(getState());
  const response = await advisorhq.get(`/life_settlement/api/cases/v2/${activeCaseId}/`);
  dispatch({ type: FETCH_LIFE_SETTLEMENT_CASE, payload: response.data });
};

/* Validate that there is "space" to decrease "number_of_insured_lives".
 *
 * For example: If the policy has "number_of_insured_lives" set to 2,
 * and the case has already 2 created insured, if the user tries to
 * decrease the "number_of_insured_lives" to 1, we raise an error asking
 * the user manually delete an insured before changing this value.
 * Doing this we try to keep data consistent.
 *
 * The validation is done in an action to access redux store.
 */
export const validateNumberOfInsured = value => (dispatch, getState) => {
  const state = getState();
  const createdInsured = insuredListSelector(state).length;

  let errorMsg;
  if (value === null && createdInsured !== 0) {
    errorMsg = "Value must be defined";
  } else if (createdInsured > value) {
    errorMsg = `
      There are currently ${createdInsured} insured created, please delete
      ${createdInsured - value} insured before updating this value.`;
  }

  if (errorMsg) {
    throw new ValidationError(errorMsg, "number_of_insured_lives");
  }
};
