import { CLEAN_RECORDS_PAGINATION_DATA, FETCH_RECORDS, LOADING } from "actions/types";
import advisorhq from "apis/advisorhq";
import * as types from "../types";
import { activeAgencySelector, currentPageCaseSelector, pageSizeSelector, sortBySelector } from "../../reducers";
import { sortByParam } from "../../utils";
import {
  ENDPOINTS_TO_ACTION_TYPES_MAPPING,
  ENDPOINTS_TO_PAGINATION_ACTION_TYPES_MAPPING,
  ENDPOINTS_TO_URL_MAPPING,
  getEndpointByFetchActionType,
} from "../pagination/constants";
import { TASK_TYPES } from "../../pages/accounts/constants";
import { TaskTab } from "../../pages/records/constants";
import { TASK_TYPE_TO_SELECT_TASK_ACTION_MAPPING } from "../../pages/records/utils";

export const cleanRecords = () => async dispatch => {
  dispatch({ type: FETCH_RECORDS, payload: [] });
  dispatch({ type: CLEAN_RECORDS_PAGINATION_DATA });
};

export const fetchRecords = (filters, fetch_action) => async (dispatch, getState) => {
  const endpoint = getEndpointByFetchActionType(fetch_action);
  const store_pagination_action = ENDPOINTS_TO_PAGINATION_ACTION_TYPES_MAPPING[endpoint];
  const activeAgency = activeAgencySelector(getState());
  const pageSize = pageSizeSelector(getState());
  dispatch({ type: LOADING, payload: { records: true } });
  const page = currentPageCaseSelector(getState()) || 1;
  const sort_by = sortBySelector(getState());
  const sort_by_value = sortByParam(sort_by);
  const params = {
    agency: activeAgency.id,
    page: page,
    page_size: pageSize,
    sort_by: sort_by_value,
    ...filters,
  };

  const response = await advisorhq.get(ENDPOINTS_TO_URL_MAPPING.TASKS, {
    params,
  });

  let data = [];
  let { results, ...paginateData } = response.data;
  data = results;
  paginateData = { ...paginateData, page };
  dispatch({ type: store_pagination_action, payload: paginateData });
  dispatch({ type: ENDPOINTS_TO_ACTION_TYPES_MAPPING[endpoint], payload: data });
  dispatch({ type: LOADING, payload: { records: false } });
};

export const selectActiveRecordId = id => async dispatch => {
  dispatch({ type: types.SELECT_RECORD_ID, payload: id });
};

export const selectActiveRecordType = record_type => async dispatch => {
  dispatch({ type: types.SELECT_ACTIVE_RECORD_TYPE, payload: record_type });
};

export const selectActiveTask = record => async dispatch => {
  const fn = TASK_TYPE_TO_SELECT_TASK_ACTION_MAPPING[record.type];
  await dispatch(fn(record));
};

export const storeRecordsFiltersData = (filters, store_filters_action) => async (dispatch, getState) => {
  dispatch({ type: store_filters_action, payload: filters });
};
