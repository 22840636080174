import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";

import * as notifications from "../../../../notifications";
import {
  EditorField,
  FormCollapsible,
  DateInput,
  TextInput,
  Select,
  DateTimeInput,
  ActiveInactiveBooleanSelect,
} from "components/forms";
import { createAccountHierarchy, editAccountHierarchy, showModal } from "actions";
import {
  activeAccountIdSelector,
  activeHierarchySelector,
  managementChoicesSelector,
  userChoicesSelector,
} from "reducers";
import { partial } from "utils";
import WideSaveButton from "components/buttons/WideSaveButton";
import {
  ACCOUNT_CHANGE_ACTIVE_HIERARCHY_MODAL,
  ACCOUNT_WARNING_UPDATE_HIERARCHY_MODAL,
} from "../../../../components/modals";

const HierarchyForm = ({ activeHierarchy, choices, ...props }) => {
  if (!choices) {
    return null;
  }
  const isSaved = activeHierarchy && activeHierarchy.id;

  let initialActiveValue = activeHierarchy?.active ?? true;

  return (
    <Formik
      enableReinitialize
      initialValues={{ active: initialActiveValue, ...activeHierarchy }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isEmpty(activeHierarchy)
          ? props.createAccountHierarchy
          : partial(props.editAccountHierarchy, activeHierarchy.id);

        values.account_id = props.activeAccountId;
        if (isSaved) {
          delete values.split;
          delete values.lead;
        }
        try {
          await method(values);
        } catch (error) {
          if (error.response.data.unique_active_hierarchy) {
            props.showModal(ACCOUNT_CHANGE_ACTIVE_HIERARCHY_MODAL, {
              hierarchyValues: { ...values, id: activeHierarchy.id },
            });
          } else if (error.response.data.update_hierarchy_warning) {
            props.showModal(ACCOUNT_WARNING_UPDATE_HIERARCHY_MODAL, {
              values: { ...values, id: activeHierarchy.id },
              method,
            });
          } else {
            setErrors(error.response.data);
            notifications.error("Error editing Hierarchy");
          }
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Hierarchy Detail">
            {!isSaved && (
              <Form.Row style={{ padding: "0" }}>
                <Select
                  label="Sales Representative"
                  name="sales_representative"
                  placeholder="Select Sales Rep"
                  options={props.salesRepChoices.getChoices({ sortByLabel: true })}
                />
                <Select
                  label="Sales Manager"
                  name="sales_manager"
                  placeholder="Select Sales Manager"
                  options={props.salesRepChoices.getChoices({ sortByLabel: true })}
                />
                <Select
                  label="Nat Acc Manager"
                  name="national_account_manager"
                  placeholder="Select Nat. Acc. Manager"
                  options={props.salesRepChoices.getChoices({ sortByLabel: true })}
                />
                <Select
                  label="National Account"
                  name="national_account"
                  placeholder="Select National Account"
                  options={props.nationalAccountChoices.getChoices({ sortByLabel: true })}
                />
              </Form.Row>
            )}
            <Form.Row style={{ padding: "0" }}>
              <ActiveInactiveBooleanSelect
                label="Hierarchy Status"
                name="active"
                placeholder="Select Hierarchy Status"
                defaultValue={{ value: true, label: "Active" }}
              />
              <DateInput label="End Date" name="date_end" />
            </Form.Row>
            <Form.Row style={{ padding: "0" }}>
              <DateTimeInput label="Create Date" name="date_created" disabled />
              <DateTimeInput label="Last Edit Date" name="last_edit_date" disabled />
              <TextInput label="Created by" name="created_by_name" disabled />
              <TextInput label="Last Edited By" name="last_edited_by_name" disabled />
            </Form.Row>
            <Form.Row style={{ padding: "0" }}>
              <EditorField label="Notes" name="notes" />
            </Form.Row>
            <WideSaveButton submitRef={props.submitRef} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  return {
    choices,
    activeAccountId: activeAccountIdSelector(state),
    activeHierarchy: activeHierarchySelector(state) || {},
    userChoices: userChoicesSelector(state),
    salesRepChoices: choices.case_sales_representatives,
    nationalAccountChoices: choices.national_accounts,
  };
};

export default connect(mapStateToProps, {
  createAccountHierarchy,
  editAccountHierarchy,
  showModal,
})(HierarchyForm);
