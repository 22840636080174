import SearchPopover from "./SearchPopover";
import { MoreActionsPopover } from "@react-pdf-viewer/toolbar";
import React from "react";
import { MinimalButton, Position, RotateDirection, Tooltip } from "@react-pdf-viewer/core";
import { FaFileAlt, FaRedo, FaUndo } from "react-icons/all";

export const renderToolbar = (searchPluginInstance, rotatePluginInstance) => {
  const { Search } = searchPluginInstance;
  const { RotatePage, RotateBackwardButton, RotateForwardButton } = rotatePluginInstance;

  return Toolbar => (
    <Toolbar>
      {slots => {
        const {
          CurrentPageInput,
          CurrentPageLabel,
          Download,
          EnterFullScreen,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Print,
          Zoom,
          ZoomIn,
          ZoomOut,
          Rotate,
        } = slots;
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              width: "100%",
            }}
          >
            <Search>{renderSearchProps => <SearchPopover renderSearchProps={renderSearchProps} />}</Search>
            <div style={{ padding: "0px 2px" }}>
              <GoToPreviousPage />
            </div>
            <div style={{ padding: "0px 2px", width: "4rem" }}>
              <CurrentPageInput />
            </div>
            <div style={{ padding: "0px 2px" }}>
              / <NumberOfPages />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <GoToNextPage />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomOut />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Zoom />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <ZoomIn />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Rotate direction={RotateDirection.Forward}>
                {props => <RotateForwardButton onClick={props.onClick} />}
              </Rotate>
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Rotate direction={RotateDirection.Backward}>
                {props => <RotateBackwardButton onClick={props.onClick} />}
              </Rotate>
            </div>
            <CurrentPageLabel>
              {props => (
                <>
                  <RotatePage>
                    {rotateProps => (
                      <Tooltip
                        position={Position.BottomCenter}
                        target={
                          <MinimalButton
                            onClick={() => rotateProps.onRotatePage(props.currentPage, RotateDirection.Forward)}
                          >
                            <FaRedo />
                            <FaFileAlt />
                          </MinimalButton>
                        }
                        content={() => "Rotate current page clockwise"}
                        offset={{ left: 0, top: 8 }}
                      />
                    )}
                  </RotatePage>
                  <RotatePage>
                    {rotateProps => (
                      <Tooltip
                        position={Position.BottomCenter}
                        target={
                          <MinimalButton
                            onClick={() => rotateProps.onRotatePage(props.currentPage, RotateDirection.Backward)}
                          >
                            <FaUndo />
                            <FaFileAlt />
                          </MinimalButton>
                        }
                        content={() => "Rotate current page counterclockwise"}
                        offset={{ left: 0, top: 8 }}
                      />
                    )}
                  </RotatePage>
                </>
              )}
            </CurrentPageLabel>
            <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
              <EnterFullScreen />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Download />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <Print />
            </div>
            <div style={{ padding: "0px 2px" }}>
              <MoreActionsPopover toolbarSlot={slots} />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );
};
