import React from "react";
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from "react-icons/ti";

export default ({ column }) => {
  let Icon;
  if (!column.canSort) Icon = "";
  else if (!column.isSorted) Icon = <TiArrowUnsorted />;
  else if (column.isSortedDesc) Icon = <TiArrowSortedDown />;
  else Icon = <TiArrowSortedUp />;
  return <span className="sort-indicator">{Icon}</span>;
};
