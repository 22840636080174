import React from "react";

import { ProgressBar, ScrollMode, SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { highlightPlugin, MessageIcon } from "@react-pdf-viewer/highlight";
import { searchPlugin } from "@react-pdf-viewer/search";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { bookmarkPlugin } from "@react-pdf-viewer/bookmark";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

import { renderToolbar } from "./renderToolbar";
import { rotatePlugin } from "@react-pdf-viewer/rotate";
import { highlightTarget, highlightContent, highlights, getSidebarNotes } from "./highlightNotes";

const PDFViewer = ({ fileUrl }) => {
  const searchPluginInstance = searchPlugin();
  const rotatePluginInstance = rotatePlugin();
  const zoomPluginInstance = zoomPlugin();
  const bookmarkPluginInstance = bookmarkPlugin();

  const [message, setMessage] = React.useState("");
  const [notes, setNotes] = React.useState([]);
  const notesContainerRef = React.useRef(null);
  let noteId = notes.length;

  const noteEles = new Map();
  React.useEffect(() => {
    return () => {
      noteEles.clear();
    };
  }, []);

  const [currentDoc, setCurrentDoc] = React.useState(null);

  const handleDocumentLoad = e => {
    const { activateTab } = defaultLayoutPluginInstance;
    activateTab(1);
    setCurrentDoc(e.doc);
    if (currentDoc && currentDoc !== e.doc) {
      // User opens new document
      setNotes([]);
    }
  };

  const jumpToNote = note => {
    activateTab(3);
    const notesContainer = notesContainerRef.current;
    if (noteEles.has(note.id) && notesContainer) {
      notesContainer.scrollTop = noteEles.get(note.id).getBoundingClientRect().top;
    }
  };

  const renderHighlightTarget = highlightTarget;
  const renderHighlightContent = highlightContent(message, noteId, setNotes, notes, setMessage);
  const renderHighlights = highlights(notes, jumpToNote);

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlightContent,
    renderHighlights,
  });

  const { jumpToHighlightArea } = highlightPluginInstance;
  const sidebarNotes = getSidebarNotes(notesContainerRef, notes, jumpToHighlightArea, noteEles);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: renderToolbar(searchPluginInstance, rotatePluginInstance),
    sidebarTabs: defaultTabs => {
      defaultTabs = defaultTabs.filter(tab => tab.title !== "Attachment");
      return defaultTabs.concat({
        content: sidebarNotes,
        icon: <MessageIcon />,
        title: "Comments",
      });
    },
  });

  const { activateTab } = defaultLayoutPluginInstance;

  return (
    <div style={{ height: "77vh", overflow: "auto" }}>
      <Viewer
        fileUrl={fileUrl}
        plugins={[
          defaultLayoutPluginInstance,
          highlightPluginInstance,
          searchPluginInstance,
          zoomPluginInstance,
          bookmarkPluginInstance,
          rotatePluginInstance,
        ]}
        onDocumentLoad={handleDocumentLoad}
        defaultScale={SpecialZoomLevel.PageFit}
        renderLoader={percentages => (
          <div style={{ width: "240px" }}>
            <ProgressBar progress={Math.round(percentages)} />
          </div>
        )}
        theme="dark"
        scrollMode={ScrollMode.Wrapped}
      />
    </div>
  );
};

export default PDFViewer;
