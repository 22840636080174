import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { EditorField, FormCollapsible, Select, StateSelect, SubmitButton, TextInput } from "components/forms";
import { shouldSaveSelector } from "reducers";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";
import { partial } from "../../utils";
import { isAdmin } from "permissions";
import { createCarrier, editCarrier, unsetShouldSave } from "actions";
import * as notifications from "../../notifications";
import {
  IRREVOCABLE_BENEFICIARY_HANDLING_CHOICES,
  POLICY_AUTH_HANDLING_CHOICES,
  POS_SUPPLEMENTARY_CONTACT_CHOICES,
} from "../../constants";

const CarrierProductForm = ({ carrierInfo, hasAdminPermission, ...props }) => {
  if (!carrierInfo) {
    // Still didn't fetch data
    return null;
  }

  const submitRef = React.useRef();

  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        product_detail_notes: carrierInfo.product_detail_notes,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);

        const method =
          isEmpty(carrierInfo) || carrierInfo.id === undefined
            ? props.createCarrier
            : partial(props.editCarrier, carrierInfo.id);

        try {
          await method(values);
          notifications.success("Carrier saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Carrier");
        }
        props.unsetShouldSave();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <>
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <EditorField
                  label="Carrier Product Detail Notes"
                  name="product_detail_notes"
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
            </Form>
            <br />
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    shouldSave: shouldSaveSelector(state),
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  createCarrier,
  editCarrier,
  unsetShouldSave,
})(CarrierProductForm);
