import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as Yup from "yup";

import { FormCollapsible, HyperLinkTextToLEModal } from "components/forms";
import { relatedLeSelector } from "reducers";

const RelatedLifeExpectanciesForm = ({ related_life_expectancies, ...props }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      validationSchema={Yup.object({})}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <FormCollapsible title="Related Life Expectancies">
            {related_life_expectancies
              ? related_life_expectancies.length > 0 && (
                  <>
                    {related_life_expectancies.map((obj, index) => {
                      const { subject, case_id, le_id } = obj;
                      return (
                        <Form.Row key={index}>
                          <HyperLinkTextToLEModal
                            key={index}
                            caseId={case_id}
                            caseDocId={le_id}
                            label={false}
                            value={subject}
                            name={case_id}
                            url={`/cases/${case_id}`}
                            disabled
                          />
                        </Form.Row>
                      );
                    })}
                  </>
                )
              : ""}
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    related_life_expectancies: relatedLeSelector(state),
  };
};

export default connect(mapStateToProps, {})(RelatedLifeExpectanciesForm);
