/* Helper method to create masked inputs, using some sensible defaults.
 * Important: Assumes hold value is a NUMERIC value represented as string. */

import React from "react";

import MaskedInput from "react-text-mask";

const PLACEHOLDER_CHAR = "#";

// When clicking on the input, automatically select the remaining chars
// to complete
const onFocus = ({ target }) => {
  target.focus();
  target.setSelectionRange(0, target.value.length);
};

const onlyDigits = /\D/g;

export default ({
  mask,
  showMask = true,
  guide = true,
  field,
  setFieldValue,
  className,
  cleanValueRegex = onlyDigits,
  useRound = false,
  ...props
}) => {
  const onChange = e => {
    const cleanedValue = e.target.value.replace(cleanValueRegex, "");
    if (useRound) {
      const newValue = Number(cleanedValue).toFixed(2);
      setFieldValue(field.name, newValue);
    } else {
      setFieldValue(field.name, cleanedValue);
    }
  };

  return (
    <MaskedInput
      mask={mask}
      showMask={showMask}
      guide={guide}
      {...field}
      placeholderChar={PLACEHOLDER_CHAR}
      defaultValue={field.value}
      onFocus={onFocus}
      onChange={onChange}
      className={`form-control ${className}`}
      autoComplete="off"
      {...props}
    />
  );
};
