import {
  FETCH_POLICY,
  EDIT_POLICY,
  CLEAN_LIFE_SETTLEMENT_CASE_DATA,
} from "actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_POLICY:
    case EDIT_POLICY:
      return { ...state, ...action.payload };

    case CLEAN_LIFE_SETTLEMENT_CASE_DATA:
      return {};

    default:
      return state;
  }
};

export const policySelector = state => state;
