import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { editLifeExpectancy, updateLifeExpectancy } from "actions";
import {
  DateInput,
  FormCollapsible,
  GenderSelect,
  SubmitButton,
  TextInput,
  Select,
  EditorField,
} from "components/forms";
import * as notifications from "../../../../notifications";
import AgeAssessedField from "../AgeAssessedField";
import { extractedLifeExpectancyPDFdataSelector } from "../../../../reducers";
import BooleanSelect from "../../../../components/forms/BooleanSelect";
import { underwritingTypeChoices } from "../../../../constants";

const PolarisForm = ({ lifeExpectancyData, ...props }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={lifeExpectancyData}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          await props.updateLifeExpectancy(lifeExpectancyData.id, values);
          await props.editLifeExpectancy(lifeExpectancyData.id);
          notifications.success(`Polaris form saved successfully`);
        } catch (error) {
          setErrors(error.response.data);
          notifications.error(`Error saving Polaris form, please check form errors`);
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title={`Polaris Life Expectancy`}>
            <Form.Row>
              <Select
                label="LE UW Type"
                name="underwriting_type" // ??
                placeholder="Select Underwriting Type"
                options={underwritingTypeChoices}
              />
            </Form.Row>
            <Form.Row>
              <TextInput label="First Name" name="first_name" />
              <TextInput label="Last Name" name="last_name" />
            </Form.Row>
            <Form.Row>
              <GenderSelect />
              <BooleanSelect label="Smoking" name="tobacco_use" />
            </Form.Row>
            <Form.Row>
              <DateInput label="Date of Birth" name="date_of_birth" />
              <TextInput label="SSN last 4 digits" name="social_security_last_4_digits" />
            </Form.Row>
            <Form.Row>
              <DateInput label="Certificate Date" name="certificate_date" />
              <AgeAssessedField label="Evaluation Age" {...values} />
              <TextInput label="Certificate ID" name="certificate_id" />
            </Form.Row>
            <Form.Row>
              <TextInput label="Mean LE" name="mean" placeholder="Enter Mean LE" prepend="Months" />
              <TextInput label="Mortality" name="mortality_rating" placeholder="Enter %" append="%" />
              <TextInput label="Client" name="client" />
            </Form.Row>
            <Form.Row>
              <TextInput label="Primary Impairment" name="medical_info.primary_impairment_category" />
              <TextInput label="Table" name="medical_info.table" />
            </Form.Row>
            <Form.Row>
              <EditorField label="Notes" name="medical_info.notes" />
            </Form.Row>
            <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state, ownProps) => {
  const extractedPDFdata = extractedLifeExpectancyPDFdataSelector(state);

  // Life expectancy data will be either the extracted data from the uploaded PDF
  // or the saved LifeExpectancy instance.
  const lifeExpectancyData = extractedPDFdata || ownProps.lifeExpectancy;

  // Make sure lifeExpectancyData has an id prop, because the extractedPDFdata won't
  // have one and we need it in the form.
  const { id } = ownProps.lifeExpectancy;
  lifeExpectancyData.id = id;

  return {
    lifeExpectancyData,
  };
};

export default connect(mapStateToProps, { updateLifeExpectancy, editLifeExpectancy })(PolarisForm);
