import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";

import * as notifications from "../../../../notifications";
import { Select, EditorField, FormCollapsible, DateInput } from "components/forms";
import { createAccountAgreement, editAccountAgreement } from "actions";
import { activeAccountIdSelector, activeAgreementSelector, userChoicesSelector } from "reducers";
import { partial } from "../../../../utils";
import { ACCOUNT_AGREEMENT_STATUS } from "../../constants";
import WideSaveButton from "components/buttons/WideSaveButton";

const AgreementForm = ({ activeAgreement, ...props }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={activeAgreement}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isEmpty(activeAgreement)
          ? props.createAccountAgreement
          : partial(props.editAccountAgreement, activeAgreement.id);

        values.account = props.activeAccountId;

        try {
          await method(values);
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Agreement");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Sourcing Agreement Detail">
            <Form.Row>
              <Select
                label="Version"
                name="version"
                placeholder="Select Version"
                options={[{ value: "v-2022-03-01", label: "v-2022-03-01" }]}
              />
              <Select label="Status" name="status" placeholder="Select Status" options={ACCOUNT_AGREEMENT_STATUS} />
              <DateInput label="Effective Date" name="effective_date" />
              <DateInput label="Termination Date" name="termination_date" />
            </Form.Row>
            <Form.Row>
              <EditorField label="Notes" name="notes" />
            </Form.Row>
            <WideSaveButton submitRef={props.submitRef} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    activeAccountId: activeAccountIdSelector(state),
    activeAgreement: activeAgreementSelector(state) || {},
    userChoices: userChoicesSelector(state),
  };
};

export default connect(mapStateToProps, {
  createAccountAgreement,
  editAccountAgreement,
})(AgreementForm);
