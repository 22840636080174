import React from "react";
import { connect } from "react-redux";

import { showModal, downloadFile, selectAccountLicense } from "actions";
import { activeAccountSelector } from "reducers";
import { ACCOUNT_LICENSE_MODAL, DELETE_ACCOUNT_LICENSE_MODAL } from "components/modals";
import Table, { dateCell } from "components/table";
import IconLink from "../../../../components/IconLink";
import { FaDownload, FaEye } from "react-icons/fa";
import { IconLinkViewOrEdit, IconLinkDelete } from "components/icons";

const LicenseTable = ({ activeAccount, ...props }) => {
  if (!activeAccount) {
    // Still didn't fetch data
    return null;
  }

  const columns = [
    {
      Header: "Action",
      className: "action",
      Cell: ({ row }) => {
        const id = row.original.id;
        const file = row.original.url;
        return (
          <>
            <IconLinkViewOrEdit
              onClick={() => {
                props.selectAccountLicense(row.original);
                props.showModal(ACCOUNT_LICENSE_MODAL);
              }}
            />
            <IconLinkDelete
              onClick={() => {
                props.showModal(DELETE_ACCOUNT_LICENSE_MODAL, { id });
              }}
            />
            {file ? (
              <>
                <IconLink
                  Icon={FaDownload}
                  iconConfig={{ className: "table--action-icon" }}
                  tooltip="Download"
                  onClick={() => {
                    const url = `/api/license/${id}/download_file/`;
                    props.downloadFile(url, row.original.name);
                  }}
                />
                <IconLink
                  Icon={FaEye}
                  iconConfig={{ className: "table--action-icon" }}
                  tooltip="Preview"
                  onClick={() => window.open(row.original.url, "_blank")}
                />
              </>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Jurisdiction",
      accessor: "jurisdiction",
    },
    {
      Header: "Lines of Business",
      accessor: "lines_of_business",
    },
    {
      Header: "Number",
      accessor: "number",
    },
    {
      Header: "Issued Date",
      accessor: "issued_date",
      Cell: dateCell,
    },
    {
      Header: "Expiration Date",
      accessor: "expiration_date",
      Cell: dateCell,
    },
  ];

  return (
    <Table
      columns={columns}
      data={props.licenses}
      defaultSort={true}
      paginated={false}
      emptyMessage="No Licenses found"
    />
  );
};

const mapStateToProps = state => {
  const activeAccount = activeAccountSelector(state);

  return {
    activeAccount,
    licenses: activeAccount ? activeAccount.licenses : [],
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectAccountLicense,
  downloadFile,
})(LicenseTable);
