import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import StateDetailBrokerForm from "./StateDetailBrokerForm";
import { activeStateSelector } from "../../reducers";
import { selectStateBrokerFile, setActiveTab } from "actions";
import StateFilesTable from "./StateBrokerFilesTable";
import { STATE_BROKER_FILE_MODAL } from "../../components/modals";
import AddStateFileButton from "../../components/AddStateFilesButton";

const StateDetailBroker = ({ stateInfo, ...props }) => {
  React.useEffect(() => {
    props.setActiveTab("state-detail-broker");
  }, []);
  return (
    <div className={`section-card shadowed case-section`} style={{ marginTop: "5.5rem" }}>
      <Card className={"case-info"}>
        <Card.Body>
          <StateDetailBrokerForm stateInfo={stateInfo} />
          <AddStateFileButton
            modal={STATE_BROKER_FILE_MODAL}
            selectFileFn={props.selectStateBrokerFile}
            fileTypeName="Broker"
          />
          <StateFilesTable stateInfo={stateInfo} type="BROKER" />
        </Card.Body>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    stateInfo: activeStateSelector(state),
  };
};

export default connect(mapStateToProps, { setActiveTab, selectStateBrokerFile })(StateDetailBroker);
