import React from "react";
import { connect } from "react-redux";

import { setActiveTab, initPagination, clearSelectedSynonym, showModal, clearSelectedParameter } from "actions";
import RiskxSynonymsFilterForm from "./RiskxSynonymsFilterForm";
import RiskxSynonymsTable from "./RiskxSynonymsTable";
import { ButtonCreate } from "../../../components/buttons";
import { SYNONYM_MODAL } from "../../../components/modals";
import RiskxPageHeader from "../RiskxPageHeader";

const RiskxSynonymsPage = props => {
  const [activeSection, setActiveSection] = React.useState("riskx-synonyms");
  React.useEffect(() => {
    props.setActiveTab(activeSection);
    props.initPagination();
    setActiveSection(activeSection);
    document.title = "LifeRoc | RiskX Synonyms";
  }, []);

  React.useEffect(() => {
    props.clearSelectedParameter();
    props.clearSelectedSynonym();
  }, []);

  return (
    <>
      <RiskxPageHeader />
      <div className={`section-card shadowed case-section`} style={{ marginTop: "1.5rem" }}>
        <RiskxSynonymsFilterForm />
        <ButtonCreate
          id="create-new-case"
          onClick={() => {
            props.clearSelectedSynonym();
            props.showModal(SYNONYM_MODAL);
          }}
          key="create"
        >
          Add Synonym
        </ButtonCreate>
        <RiskxSynonymsTable />
      </div>
    </>
  );
};

export default connect(null, {
  setActiveTab,
  clearSelectedSynonym,
  initPagination,
  showModal,
  clearSelectedParameter,
})(RiskxSynonymsPage);
