import React, { useState } from "react";

import { ButtonCreate } from "components/buttons";
import { TableHeader } from "components/table";

// Table to a list of records, with a title and a create button
const RecordsTableTabs = ({ Table1, Table2, table2props, title1, title2, onCreateClick, ...props }) => {
  const createButton = (
    <ButtonCreate onClick={onCreateClick} key="create">
      Create New
    </ButtonCreate>
  );
  const renderActionButtons = funders => {
    if (funders) {
      const actions = props.actionButtons2 ? props.actionButtons2 : [createButton];
      return <TableHeader.Actions>{actions.map(a => a)}</TableHeader.Actions>;
    } else {
      const actions = props.actionButtons1 ? props.actionButtons1 : [createButton];
      return <TableHeader.Actions>{actions.map(a => a)}</TableHeader.Actions>;
    }
  };

  const [funders, setFunders] = useState(false);

  return (
    <div className="records-table">
      <TableHeader.Tabs>
        <TableHeader.Title>
          <div style={{ display: "flex" }}>
            <div
              className={funders ? "records-table-tabs" : "records-table-tabs active"}
              onClick={() => setFunders(false)}
              key="funders-false"
            >
              Funder Activity
            </div>
            <div
              className={funders ? "records-table-tabs active" : "records-table-tabs"}
              onClick={() => setFunders(true)}
              key="funders-true"
            >
              Funders List
            </div>
          </div>
        </TableHeader.Title>
        {renderActionButtons(funders)}
      </TableHeader.Tabs>
      {funders ? <Table2 table2props /> : <Table1 />}
    </div>
  );
};

export default RecordsTableTabs;
