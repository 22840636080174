import React from "react";

import { Icon, MinimalButton, Position, Tooltip } from "@react-pdf-viewer/core";
import { NextIcon, PreviousIcon } from "@react-pdf-viewer/search";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

const SearchPopover = ({ renderSearchProps }) => {
  const [readyToSearch, setReadyToSearch] = React.useState(false);

  return (
    <>
      <div
        style={{
          backgroundColor: "#2A2A2A", // Dark background
          display: "flex",
          padding: "0 2px",
        }}
      >
        <input
          style={{
            border: "none",
            padding: "6px",
            width: "200px",
            backgroundColor: "#3A3A3A", // Input background
            color: "#FFFFFF", // Input text color
          }}
          placeholder="Enter to search"
          type="text"
          value={renderSearchProps.keyword}
          onChange={e => {
            setReadyToSearch(false);
            renderSearchProps.setKeyword(e.target.value);
          }}
          onKeyDown={e => {
            if (e.keyCode === 13 && renderSearchProps.keyword) {
              setReadyToSearch(true);
              renderSearchProps.search();
            }
          }}
        />
        <Tooltip
          position={Position.BottomCenter}
          target={
            <button
              style={{
                background: "none", // Transparent background
                border: "none",
                borderBottom: `2px solid ${renderSearchProps.matchCase ? "lightblue" : "transparent"}`,
                height: "100%",
                padding: "0 2px",
                color: "#FFFFFF", // Button text color
              }}
              onClick={() => renderSearchProps.changeMatchCase(!renderSearchProps.matchCase)}
            >
              <Icon>
                <path d="M15.979,21.725,9.453,2.612a.5.5,0,0,0-.946,0L2,21.725" />
                <path d="M4.383 14.725L13.59 14.725" />
                <path d="M0.5 21.725L3.52 21.725" />
                <path d="M14.479 21.725L17.5 21.725" />
                <path d="M22.5,21.725,18.377,9.647a.5.5,0,0,0-.946,0l-1.888,5.543" />
                <path d="M16.92 16.725L20.794 16.725" />
                <path d="M21.516 21.725L23.5 21.725" />
              </Icon>
            </button>
          }
          content={() => "Match case"}
          offset={{ left: 0, top: 8 }}
        />
        <Tooltip
          position={Position.BottomCenter}
          target={
            <button
              style={{
                background: "none", // Transparent background
                border: "none",
                borderBottom: `2px solid ${renderSearchProps.wholeWords ? "lightblue" : "transparent"}`,
                height: "100%",
                padding: "0 2px",
                color: "#FFFFFF", // Button text color
              }}
              onClick={() => renderSearchProps.changeWholeWords(!renderSearchProps.wholeWords)}
            >
              <Icon>
                <path d="M0.500 7.498 L23.500 7.498 L23.500 16.498 L0.500 16.498 Z" />
                <path d="M3.5 9.498L3.5 14.498" />
              </Icon>
            </button>
          }
          content={() => "Match whole word"}
          offset={{ left: 0, top: 8 }}
        />
      </div>
      {readyToSearch && renderSearchProps.keyword && renderSearchProps.numberOfMatches === 0 && (
        <div style={{ padding: "0 8px", color: "#FFFFFF" }}>Not found</div>
      )}
      {readyToSearch && renderSearchProps.keyword && renderSearchProps.numberOfMatches > 0 && (
        <div style={{ padding: "0 8px", color: "#FFFFFF" }}>
          {renderSearchProps.currentMatch} of {renderSearchProps.numberOfMatches}
        </div>
      )}
      <div style={{ padding: "0 2px" }}>
        <Tooltip
          position={Position.BottomCenter}
          target={
            <MinimalButton onClick={renderSearchProps.jumpToPreviousMatch}>
              <PreviousIcon />
            </MinimalButton>
          }
          content={() => "Previous match"}
          offset={{ left: 0, top: 8 }}
        />
      </div>
      <div style={{ padding: "0 2px" }}>
        <Tooltip
          position={Position.BottomCenter}
          target={
            <MinimalButton onClick={renderSearchProps.jumpToNextMatch}>
              <NextIcon />
            </MinimalButton>
          }
          content={() => "Next match"}
          offset={{ left: 0, top: 8 }}
        />
      </div>
    </>
  );
};

export default SearchPopover;
