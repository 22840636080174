import { isEmpty } from "lodash";
import {
  FETCH_BENEFICIARIES,
  EDIT_BENEFICIARY,
  CREATE_BENEFICIARY,
  DELETE_BENEFICIARY,
  CREATE_BENEFICIARY_SIGNER,
  DELETE_BENEFICIARY_SIGNER,
} from "actions/types";
import { removeKey } from "utils.js";
import { arrayToObjectIndexedById } from "reducers/utils";
import { BENEFICIARY_TYPES } from "actions/life_settlement/beneficiaries";

export const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_BENEFICIARIES: {
      const beneficiaries = {};
      action.payload.forEach(r => {
        const [beneficiaryType, values] = Object.entries(r)[0];
        beneficiaries[beneficiaryType] = arrayToObjectIndexedById(values);
      });
      return beneficiaries;
    }
    case EDIT_BENEFICIARY:
    case CREATE_BENEFICIARY: {
      const { beneficiaryType, ...values } = action.payload;
      const id = values.id;
      return {
        ...state,
        [beneficiaryType]: {
          ...state[beneficiaryType],
          [id]: values,
        },
      };
    }

    case DELETE_BENEFICIARY: {
      const { beneficiaryType, id } = action.payload;
      return {
        ...state,
        [beneficiaryType]: removeKey(state[beneficiaryType], id),
      };
    }

    case CREATE_BENEFICIARY_SIGNER: {
      const { entity_id, id } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [entity_id]: {
            ...state.entity[entity_id],
            signers: {
              ...state.entity[entity_id].signers,
              [id]: action.payload,
            },
          },
        },
      };
    }

    case DELETE_BENEFICIARY_SIGNER: {
      const { entity_id, id } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [entity_id]: {
            ...state.entity[entity_id],
            signers: Object.values(state.entity[entity_id].signers).filter(
              signer => signer.id !== id,
            ),
          },
        },
      };
    }

    default:
      return state;
  }
};

export const beneficiariesSelector = state => {
  if (isEmpty(state)) {
    return [];
  }

  let beneficiariesList = [];
  BENEFICIARY_TYPES.forEach(beneficiaryType => {
    const beneficiariesOfType = Object.values(state[beneficiaryType]).map(b => ({
      ...b,
      beneficiaryType,
    }));
    beneficiariesList = [...beneficiariesList, ...beneficiariesOfType];
  });
  return beneficiariesList;
};

export const beneficiaryEntitiesSelector = state => {
  if (isEmpty(state)) {
    return [];
  }

  return Object.values(state.entity);
};

export const numberOfBeneficiariesSelector = state =>
  beneficiariesSelector(state).length;
