import React from "react";
import { useField, useFormikContext } from "formik";
import { Form, InputGroup, Col } from "react-bootstrap";

import Label from "./Label";
import createMaskedInput from "./createMaskedInput";
import { validateLength, getValidationClassName } from "./validation";
import FieldErrors from "./FieldErrors";

const LENGTH = 4;

export default ({ name, label, md = "", ...props }) => {
  const [field, meta] = useField({ name, validate: validateLength(LENGTH) });
  const { setFieldValue } = useFormikContext();

  const d = /\d/;
  const mask = ["X", "X", "X", "-", "X", "X", "-", d, d, d, d];

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      <Label label={label} required={props.isRequired} />
      <InputGroup>
        {createMaskedInput({
          mask,
          field,
          setFieldValue,
          className: getValidationClassName(meta),
        })}
        <FieldErrors meta={meta} />
      </InputGroup>
    </Form.Group>
  );
};
