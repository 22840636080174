import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { WINFLEX_ILLUSTRATION_MODAL } from "components/modals";
import WinFlexForm from "./WinFlexForm";
import { activeCaseSelector } from "../../../reducers";
import { fetchWinFlexChoices, fetchPolicy } from "../../../actions";

const WinFlexModal = props => {
  React.useEffect(() => {
    // Required for WinFlex details
    props.fetchWinFlexChoices();
    props.fetchPolicy(props.caseInfo.policy);
  }, [props.caseInfo?.id]);

  return (
    <FullPageModal modalType={WINFLEX_ILLUSTRATION_MODAL} title={`WinFlex Illustration Request`}>
      <WinFlexForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchWinFlexChoices,
  fetchPolicy,
})(WinFlexModal);
