import * as types from "../types";
import { activeProductSelector, activeCaseIdSelector, activeCaseSelector } from "reducers";
import advisorhq from "../../apis/advisorhq";
import * as notifications from "../../notifications";
import { FETCH_LIFE_SETTLEMENT_CHOICES, FETCH_POLICY } from "../types";
import { useNavigate } from "react-router-dom";

export const selectProduct = id => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_PRODUCT, payload: id });
  const response = await advisorhq.get("/life_settlement/api/product/" + id + "/");
  dispatch({ type: types.FETCH_PRODUCT, payload: response.data });
  return response.data;
};

export const clearSelectedProduct = () => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_PRODUCT, payload: null });
};

export const fetchProducts = filters => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { products: true } });
  const response = await advisorhq.get("/life_settlement/api/product/", {
    params: {
      ...filters,
    },
  });
  dispatch({ type: types.FETCH_PRODUCTS, payload: response.data });
  dispatch({ type: types.LOADING, payload: { products: false } });
};

export const createProduct = (data, callback) => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { products: true } });
  const endpoint = `/life_settlement/api/product/`;
  data.case = activeCaseIdSelector(getState());
  let newCarrier = data.carrier_id;
  const response = await advisorhq.post(endpoint, data);
  dispatch({ type: types.CREATE_PRODUCT, payload: response.data });
  notifications.success("A new Product was created");

  // If this was a Product Creation from the Policy Detail section, set the Product as well
  if (window.location.href.indexOf("policy") !== -1 && callback) {
    callback(response.data);

    // Fetch Product Choices to refresh the data on background
    const response3 = await advisorhq.get(`/life_settlement/api/product_choices/${newCarrier}/`);
    dispatch({ type: types.FETCH_PRODUCT_CHOICES, payload: response3.data });
  } else {
    const history = useNavigate();
    history(`/products/${response.data.id}`);
  }

  dispatch({ type: types.LOADING, payload: { products: false } });
  return response.data;
};

export const editProduct = (productId, newValues, row) => async (dispatch, getState) => {
  if (productId) {
    dispatch({ type: types.LOADING, payload: { products: true } });
    const endpoint = `/life_settlement/api/product/${productId}/`;
    const response = await advisorhq.patch(endpoint, newValues);
    dispatch({ type: types.EDIT_PRODUCT, payload: response.data });
    dispatch({ type: types.LOADING, payload: { products: false } });
    notifications.success("Product Updated!");
    return response.data;
  } else {
    // Attempting to edit with empty id will create it instead
    dispatch({ type: types.LOADING, payload: { products: true } });
    const endpoint = `/life_settlement/api/case_file/`;
    let data = {
      ...row,
      ...newValues,
      case: activeCaseIdSelector(getState()),
    };
    const response = await advisorhq.post(endpoint, data);
    dispatch({ type: types.CREATE_PRODUCT, payload: response.data });
    dispatch({ type: types.LOADING, payload: { products: false } });
    notifications.success("Product Created!");
    return response.data;
  }
};

export const uploadProductTemplate = file => async (dispatch, getState) => {
  notifications.success("Uploading Product Template...");
  const activeProduct = activeProductSelector(getState());
  let productId = activeProduct && activeProduct.id;
  const formData = new FormData();
  formData.append("product_id", productId);
  formData.append("file", file, file.name);

  try {
    await advisorhq.post(`/life_settlement/api/upload_product_template/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    // update product template choices
    const choicesResponse = await advisorhq.get("/life_settlement/api/choices/");
    dispatch({ type: FETCH_LIFE_SETTLEMENT_CHOICES, payload: choicesResponse.data });

    const productResponse = await advisorhq.get("/life_settlement/api/product/" + productId + "/");
    dispatch({ type: types.FETCH_PRODUCT, payload: productResponse.data });

    notifications.success("A new Product Template was created");
  } catch (error) {
    notifications.error("Error creating new Product Template");
    console.error(error);
  }
};

export const deleteProductTemplate = id => async (dispatch, getState) => {
  console.log("deleteProductTemplate", id);
  await advisorhq.delete(`/life_settlement/api/product_template/${id}/`);

  const activeProduct = activeProductSelector(getState());
  let productId = activeProduct.id;
  const productResponse = await advisorhq.get("/life_settlement/api/product/" + productId + "/");
  dispatch({ type: types.FETCH_PRODUCT, payload: productResponse.data });
};

export const deleteProduct = id => async (dispatch, getState) => {
  try {
    notifications.success("Deleting Product...");
    await advisorhq.delete(`/life_settlement/api/product/${id}/`);
    notifications.clearAll();
    notifications.success("Product Deleted successfully!");
  } catch (e) {
    notifications.error(e.response.data.error);
  }
  const response = await advisorhq.get("/life_settlement/api/product/", {});
  dispatch({ type: types.FETCH_PRODUCTS, payload: response.data });
};

export const fetchProductChoices = carrier_id => async (dispatch, getState) => {
  try {
    console.log("fetchProductChoices", carrier_id);
    const response = await advisorhq.get(`/life_settlement/api/product_choices/${carrier_id}/`);
    dispatch({ type: types.FETCH_PRODUCT_CHOICES, payload: response.data });
  } catch (e) {
    console.error(e.response);
    notifications.error(e.response.data.error);
  }
};
