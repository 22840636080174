import * as types from "actions/types";
import { arrayToObjectIndexedById } from "reducers/utils";
import { removeKey } from "utils.js";

export const defaultState = {
  billingNotices: {},
  activeBillingNoticeId: null,
  chainOfTitles: {},
  activeChainOfTitleId: null,
  illustrations: {},
  activeIllustrationId: null,
  policyAndApplications: {},
  activePolicyAndApplicationId: null,
  statements: {},
  activeStatementId: null,
  verificationsOfCoverage: {},
  activeVerificationOfCoverageId: null,
  secondaryMarketClosings: {},
  activeSecondaryMarketClosingId: null,
  tertiaryMarketClosings: {},
  activeTertiaryMarketClosingId: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_BILLING_NOTICES:
      return {
        ...state,
        billingNotices: arrayToObjectIndexedById(action.payload),
      };

    case types.EDIT_BILLING_NOTICE:
    case types.CREATE_BILLING_NOTICE:
      return {
        ...state,
        billingNotices: {
          ...state.billingNotices,
          [action.payload.id]: action.payload,
        },
      };

    case types.SELECT_BILLING_NOTICE:
      return {
        ...state,
        activeBillingNoticeId: action.payload,
      };

    case types.DELETE_BILLING_NOTICE: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        billingNotices: removeKey(state.billingNotices, toDeleteId),
      };
      if (state.activeBillingNoticeId === toDeleteId) {
        newState.activeBillingNoticeId = null;
      }
      return newState;
    }

    case types.FETCH_CHAIN_OF_TITLES: {
      return {
        ...state,
        chainOfTitles: arrayToObjectIndexedById(action.payload),
      };
    }

    case types.EDIT_CHAIN_OF_TITLE:
    case types.CREATE_CHAIN_OF_TITLE:
      return {
        ...state,
        chainOfTitles: {
          ...state.chainOfTitles,
          [action.payload.id]: action.payload,
        },
      };

    case types.SELECT_CHAIN_OF_TITLE:
      return {
        ...state,
        activeChainOfTitleId: action.payload,
      };

    case types.DELETE_CHAIN_OF_TITLE: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        chainOfTitles: removeKey(state.chainOfTitles, toDeleteId),
      };
      if (state.activeChainOfTitleId === toDeleteId) {
        newState.activeChainOfTitleId = null;
      }
      return newState;
    }

    case types.FETCH_ILLUSTRATIONS: {
      return {
        ...state,
        illustrations: arrayToObjectIndexedById(action.payload),
      };
    }

    case types.EDIT_ILLUSTRATION:
    case types.CREATE_ILLUSTRATION:
      return {
        ...state,
        illustrations: {
          ...state.illustrations,
          [action.payload.id]: action.payload,
        },
      };

    case types.SELECT_ILLUSTRATION:
      return {
        ...state,
        activeIllustrationId: action.payload,
      };

    case types.DELETE_ILLUSTRATION: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        illustrations: removeKey(state.illustrations, toDeleteId),
      };
      if (state.activeIllustrationId === toDeleteId) {
        newState.activeIllustrationId = null;
      }
      return newState;
    }

    case types.FETCH_POLICY_AND_APPLICATIONS: {
      return {
        ...state,
        policyAndApplications: arrayToObjectIndexedById(action.payload),
      };
    }

    case types.EDIT_POLICY_AND_APPLICATION:
    case types.CREATE_POLICY_AND_APPLICATION:
      return {
        ...state,
        policyAndApplications: {
          ...state.policyAndApplications,
          [action.payload.id]: action.payload,
        },
      };

    case types.SELECT_POLICY_AND_APPLICATION:
      return {
        ...state,
        activePolicyAndApplicationId: action.payload,
      };

    case types.DELETE_POLICY_AND_APPLICATION: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        policyAndApplications: removeKey(state.policyAndApplications, toDeleteId),
      };
      if (state.activePolicyAndApplicationId === toDeleteId) {
        newState.activePolicyAndApplicationId = null;
      }
      return newState;
    }

    case types.FETCH_STATEMENTS: {
      return {
        ...state,
        statements: arrayToObjectIndexedById(action.payload),
      };
    }

    case types.EDIT_STATEMENT:
    case types.CREATE_STATEMENT:
      return {
        ...state,
        statements: {
          ...state.statements,
          [action.payload.id]: action.payload,
        },
      };

    case types.SELECT_STATEMENT:
      return {
        ...state,
        activeStatementId: action.payload,
      };

    case types.DELETE_STATEMENT: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        statements: removeKey(state.statements, toDeleteId),
      };
      if (state.activeStatementId === toDeleteId) {
        newState.activeStatementId = null;
      }
      return newState;
    }

    case types.FETCH_VERIFICATIONS_OF_COVERAGE: {
      return {
        ...state,
        verificationsOfCoverage: arrayToObjectIndexedById(action.payload),
      };
    }

    case types.EDIT_VERIFICATION_OF_COVERAGE:
    case types.CREATE_VERIFICATION_OF_COVERAGE:
      return {
        ...state,
        verificationsOfCoverage: {
          ...state.verificationsOfCoverage,
          [action.payload.id]: action.payload,
        },
      };

    case types.SELECT_VERIFICATION_OF_COVERAGE:
      return {
        ...state,
        activeVerificationOfCoverageId: action.payload,
      };

    case types.DELETE_VERIFICATION_OF_COVERAGE: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        verificationsOfCoverage: removeKey(state.verificationsOfCoverage, toDeleteId),
      };
      if (state.activeVerificationOfCoverageId === toDeleteId) {
        newState.activeVerificationOfCoverageId = null;
      }
      return newState;
    }

    case types.FETCH_SECONDARY_MARKET_CLOSINGS: {
      return {
        ...state,
        secondaryMarketClosings: arrayToObjectIndexedById(action.payload),
      };
    }

    case types.EDIT_SECONDARY_MARKET_CLOSING:
    case types.CREATE_SECONDARY_MARKET_CLOSING:
      return {
        ...state,
        secondaryMarketClosings: {
          ...state.secondaryMarketClosings,
          [action.payload.id]: action.payload,
        },
      };

    case types.SELECT_SECONDARY_MARKET_CLOSING:
      return {
        ...state,
        activeSecondaryMarketClosingId: action.payload,
      };

    case types.DELETE_SECONDARY_MARKET_CLOSING: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        secondaryMarketClosings: removeKey(state.secondaryMarketClosings, toDeleteId),
      };
      if (state.activeSecondaryMarketClosingId === toDeleteId) {
        newState.activeSecondaryMarketClosingId = null;
      }
      return newState;
    }

    case types.FETCH_TERTIARY_MARKET_CLOSINGS: {
      return {
        ...state,
        tertiaryMarketClosings: arrayToObjectIndexedById(action.payload),
      };
    }

    case types.EDIT_TERTIARY_MARKET_CLOSING:
    case types.CREATE_TERTIARY_MARKET_CLOSING:
      return {
        ...state,
        tertiaryMarketClosings: {
          ...state.tertiaryMarketClosings,
          [action.payload.id]: action.payload,
        },
      };

    case types.SELECT_TERTIARY_MARKET_CLOSING:
      return {
        ...state,
        activeTertiaryMarketClosingId: action.payload,
      };

    case types.DELETE_TERTIARY_MARKET_CLOSING: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        tertiaryMarketClosings: removeKey(state.tertiaryMarketClosings, toDeleteId),
      };
      if (state.activeTertiaryMarketClosingId === toDeleteId) {
        newState.activeTertiaryMarketClosingId = null;
      }
      return newState;
    }

    case types.CLEAN_LIFE_SETTLEMENT_CASE_DATA:
      return { ...defaultState };

    default:
      return state;
  }
};

export const activeBillingNoticeSelector = state => {
  const { activeBillingNoticeId, billingNotices } = state;
  if (activeBillingNoticeId === null) {
    return null;
  }
  return billingNotices[activeBillingNoticeId];
};

export const billingNoticesSelector = state => Object.values(state.billingNotices);

export const activeChainOfTitleSelector = state => {
  const { activeChainOfTitleId, chainOfTitles } = state;
  if (activeChainOfTitleId === null) {
    return null;
  }
  return chainOfTitles[activeChainOfTitleId];
};

export const chainOfTitlesSelector = state => Object.values(state.chainOfTitles);

export const activeIllustrationSelector = state => {
  const { activeIllustrationId, illustrations } = state;
  if (activeIllustrationId === null) {
    return null;
  }
  return illustrations[activeIllustrationId];
};

export const illustrationsSelector = state => Object.values(state.illustrations);

export const activePolicyAndApplicationSelector = state => {
  const { activePolicyAndApplicationId, policyAndApplications } = state;
  if (activePolicyAndApplicationId === null) {
    return null;
  }
  return policyAndApplications[activePolicyAndApplicationId];
};

export const policyAndApplicationsSelector = state => Object.values(state.policyAndApplications);

export const activeStatementSelector = state => {
  const { activeStatementId, statements } = state;
  if (activeStatementId === null) {
    return null;
  }
  return statements[activeStatementId];
};

export const statementsSelector = state => Object.values(state.statements);

export const activeVerificationOfCoverageSelector = state => {
  const { activeVerificationOfCoverageId, verificationsOfCoverage } = state;
  if (activeVerificationOfCoverageId === null) {
    return null;
  }
  return verificationsOfCoverage[activeVerificationOfCoverageId];
};

export const verificationsOfCoverageSelector = state => Object.values(state.verificationsOfCoverage);

export const activeSecondaryMarketClosingSelector = state => {
  const { activeSecondaryMarketClosingId, secondaryMarketClosings } = state;
  if (activeSecondaryMarketClosingId === null) {
    return null;
  }
  return secondaryMarketClosings[activeSecondaryMarketClosingId];
};

export const secondaryMarketClosingsSelector = state => Object.values(state.secondaryMarketClosings);

export const activeTertiaryMarketClosingSelector = state => {
  const { activeTertiaryMarketClosingId, tertiaryMarketClosings } = state;
  if (activeTertiaryMarketClosingId === null) {
    return null;
  }
  return tertiaryMarketClosings[activeTertiaryMarketClosingId];
};

export const tertiaryMarketClosingsSelector = state => Object.values(state.tertiaryMarketClosings);
