import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";

import { showModal } from "actions";
import { insuredSelector, managementChoicesSelector } from "reducers";
import { genderChoices } from "../../../options";
import { fullNameDisplay, calcAge, getLabel, getViewMode } from "../../../utils";
import { isAdmin, isReadMode } from "permissions";
import { DELETE_INSURED_MODAL } from "components/modals";
import { ButtonDelete } from "components/buttons";
import SectionDetails from "../../../components/SectionDetails";
import { insuredStatusChoices } from "../../../constants";

const InsuredInfo = ({ isActive, insuredInfo, deleteHandler, deleteText, hasAdminPermission, ...props }) => {
  if (!insuredInfo) {
    return null;
  }
  const isViewMode = getViewMode();

  return (
    <div
      className={`section-card shadowed ${isActive && "active"}`}
      onClick={props.onClick}
      // Show cursor pointer only when an onClick function was given
      style={props.onClick && { cursor: "pointer" }}
    >
      <Card>
        <Card.Header>
          <h5 className="section-title">{props.title || `Insured (${props.idx})`}</h5>
        </Card.Header>
        <Card.Body>
          <SectionDetails
            details={{
              Name: fullNameDisplay(insuredInfo.last_name, insuredInfo.first_name),
              Age: calcAge(insuredInfo.date_of_birth),
              "Approximate LE": "",
              Gender: genderChoices.getLabel(insuredInfo.gender),
              "Insured Status": getLabel(insuredStatusChoices, insuredInfo.insured_status),
              "Resident State": insuredInfo.state,
            }}
          />
        </Card.Body>
        <Card.Footer>
          {!props.readMode && (
            <ButtonDelete
              text={deleteText}
              onClick={deleteHandler || (() => props.showModal(DELETE_INSURED_MODAL, insuredInfo))}
            />
          )}
        </Card.Footer>
      </Card>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const choices = managementChoicesSelector(state);
  return {
    insuredInfo: insuredSelector(state, ownProps.insuredId),
    maritalStatusChoices: choices.marital_status,
    hasAdminPermission: isAdmin(state),
    readMode: isReadMode(state),
  };
};

export default connect(mapStateToProps, { showModal })(InsuredInfo);
