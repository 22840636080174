import {
  EDIT_LIFE_SETTLEMENT_CASE,
  FETCH_RECORDS,
  FETCH_CASE_RECORDS,
  FETCH_CASE_REQUIREMENT_RECORDS,
  FETCH_ACCOUNT_RECORDS,
  FETCH_LEAD_RECORDS,
  SELECT_RECORD_ID,
  SELECT_ACTIVE_RECORD_TYPE,
} from "actions/types";
import { arrayToMapIndexedById } from "reducers/utils";

export const defaultState = {
  records: [],
  case_records: [],
  case_requirement_records: [],
  account_records: [],
  lead_records: [],
  activeRecordId: null,
  activeRecordType: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_ACTIVE_RECORD_TYPE:
      return {
        ...state,
        activeRecordType: action.payload,
      };
    case FETCH_RECORDS:
      return {
        ...state,
        records: arrayToMapIndexedById(action.payload),
      };
    case FETCH_CASE_RECORDS:
      return {
        ...state,
        case_records: arrayToMapIndexedById(action.payload),
      };
    case FETCH_CASE_REQUIREMENT_RECORDS:
      return {
        ...state,
        case_requirement_records: arrayToMapIndexedById(action.payload),
      };
    case FETCH_ACCOUNT_RECORDS:
      return {
        ...state,
        account_records: arrayToMapIndexedById(action.payload),
      };
    case FETCH_LEAD_RECORDS:
      return {
        ...state,
        lead_records: arrayToMapIndexedById(action.payload),
      };

    case SELECT_RECORD_ID:
      return { ...state, activeRecordId: action.payload };

    case EDIT_LIFE_SETTLEMENT_CASE: {
      if (action.payload.status) {
        for (let record of state.records) {
          if (record.case_id === action.payload.id) {
            record.status = action.payload.status;
            record.status_name = action.payload.status_name;
          }
        }
      }
      return state;
    }
    default:
      return state;
  }
};

// Selectors
export const recordsSelector = state => {
  if (state.records) {
    try {
      return Array.from(state.records.values());
    } catch (e) {
      return [];
    }
  } else {
    return [];
  }
};

export const caseRecordsSelector = state => {
  if (state.case_records) {
    return Array.from(state.case_records.values());
  } else {
    return [];
  }
};

export const caseRequirementRecordsSelector = state => {
  if (state.case_requirement_records) {
    return Array.from(state.case_requirement_records.values());
  } else {
    return [];
  }
};

export const accountRecordsSelector = state => {
  if (state.account_records) {
    return Array.from(state.account_records.values());
  } else {
    return [];
  }
};

export const leadRecordsSelector = state => {
  if (state.lead_records) {
    return Array.from(state.lead_records.values());
  } else {
    return [];
  }
};

export const activeRecordTypeSelector = state => {
  return state.activeRecordType;
};

export const tabBasedRecordsSelector = (state, activeTab) => {
  if (activeTab === "tasks") {
    return recordsSelector(state);
  } else if (activeTab === "case-tasks") {
    return caseRecordsSelector(state);
  } else if (activeTab === "case-requirement-tasks") {
    return caseRequirementRecordsSelector(state);
  } else if (activeTab === "account-tasks") {
    return accountRecordsSelector(state);
  } else if (activeTab === "lead-tasks") {
    return leadRecordsSelector(state);
  } else {
    return [];
  }
};

export const activeRecordSelector = (state, activeTab) => {
  if (activeTab === "tasks") {
    return state.records.get(state.activeRecordId);
  } else if (activeTab === "case-tasks") {
    return state.case_records.get(state.activeRecordId);
  } else if (activeTab === "case-requirement-tasks") {
    return state.case_requirement_records.get(state.activeRecordId);
  } else if (activeTab === "account-tasks") {
    return state.account_records.get(state.activeRecordId);
  } else if (activeTab === "lead-tasks") {
    return state.lead_records.get(state.activeRecordId);
  } else {
    return {};
  }
};
