import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { FormCollapsible } from "components/forms";
import { createProduct, editProduct, fetchPolicy, uploadProductTemplate } from "actions";
import SingleFileDropZone from "components/SingleFileDropZone";
import ProductTemplatesTable from "./ProductTemplatesTable";
import { activeProductSelector } from "reducers";

const ProductTemplateForm = ({ ...props }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Product Templates" className={"marginTop0"}>
            <div style={{ marginTop: "-30px" }}>
              <ProductTemplatesTable currentProduct={props.currentProduct} />
            </div>
            <SingleFileDropZone
              currentFile={null}
              uploadFile={props.uploadProductTemplate}
              fileDownloadUrl={`/life_settlement/api/product/`}
              message={"Select file to create a new Product Template"}
            />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  let currentProduct = activeProductSelector(state);
  return {
    currentProduct: currentProduct,
  };
};

export default connect(mapStateToProps, {
  createProduct,
  editProduct,
  fetchPolicy,
  uploadProductTemplate,
})(ProductTemplateForm);
