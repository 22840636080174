import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { ADD_CONDITION_MODAL } from "components/modals";
import AddConditionForm from "./AddConditionForm";

const AddConditionModal = ({ ...props }) => {
  return (
    <FullPageModal modalType={ADD_CONDITION_MODAL}>
      <AddConditionForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(AddConditionModal);
