import * as types from "actions/types";
import * as notifications from "notifications.js";
import advisorhq from "apis/advisorhq";
import { LOADING } from "actions/types";

export const selectOwner = data => {
  return { type: types.SELECT_OWNER, payload: data };
};

export const fetchOwners = caseId => async dispatch => {
  try {
    const [ownerEntities, ownerPersons, ownerInsureds, ownerSigners] =
      await Promise.all([
        advisorhq.get(`/life_settlement/api/owner_entities_information/`, {
          params: { case: caseId },
        }),
        advisorhq.get(`/life_settlement/api/owner_persons_information/`, {
          params: { case: caseId },
        }),
        advisorhq.get(`/life_settlement/api/owner_insured_information/`, {
          params: { case: caseId },
        }),
        advisorhq.get(`/life_settlement/api/owner_signers/`, {
          params: { entity__case: caseId },
        }),
      ]);
    dispatch({ type: types.FETCH_OWNER_ENTITIES, payload: ownerEntities.data });
    dispatch({ type: types.FETCH_OWNER_PERSONS, payload: ownerPersons.data });
    dispatch({ type: types.FETCH_OWNER_INSUREDS, payload: ownerInsureds.data });
    dispatch({ type: types.FETCH_OWNER_SIGNERS, payload: ownerSigners.data });
    dispatch({ type: LOADING, payload: { owners: false } });
  } catch (error) {
    console.error("Error in fetchOwners:", error);
  }
};

export const fetchOwnerSignersForOwner = entity_id => async dispatch => {
  const response = await advisorhq.get(`/life_settlement/api/owner_signers/`, {
    params: { entity: entity_id },
  });
  dispatch({ type: types.FETCH_OWNER_SIGNERS, payload: response.data });
};

export const createOwnerEntity =
  (
    caseId,
    ownership_percentage = null,
    ownership_start_date = null,
    ownership_end_date = null,
  ) =>
  async dispatch => {
    const ownerInfo = {
      case: caseId,
      ownership_percentage,
      ownership_start_date,
      ownership_end_date,
    };

    // Create the owner
    const endpoint = `/life_settlement/api/owner_entities_information/`;
    const response = await advisorhq.post(endpoint, ownerInfo);
    dispatch({ type: types.CREATE_OWNER_ENTITY, payload: response.data });
    notifications.success("A new Owner record was created");

    // Select the recently created owner
    dispatch(selectOwner({ type: "entity", id: response.data.id }));
  };

export const createOwnerInsured =
  (
    insuredId,
    ownership_percentage = null,
    ownership_start_date = null,
    ownership_end_date = null,
  ) =>
  async dispatch => {
    const ownerInfo = {
      insured: insuredId,
      ownership_percentage,
      ownership_start_date,
      ownership_end_date,
    };

    // Create the owner
    const endpoint = `/life_settlement/api/owner_insured_information/`;
    const response = await advisorhq.post(endpoint, ownerInfo);
    dispatch({ type: types.CREATE_OWNER_INSURED, payload: response.data });
    notifications.success("A new Owner record was created");

    const { id } = response.data;

    // Select the recently created owner
    dispatch(selectOwner({ type: id, id }));
  };

export const createOwnerPerson =
  (
    caseId,
    ownership_percentage = null,
    ownership_start_date = null,
    ownership_end_date = null,
  ) =>
  async dispatch => {
    const ownerInfo = {
      case: caseId,
      ownership_percentage,
      ownership_start_date,
      ownership_end_date,
    };

    // Create the owner
    const endpoint = `/life_settlement/api/owner_persons_information/`;
    const response = await advisorhq.post(endpoint, ownerInfo);
    dispatch({ type: types.CREATE_OWNER_PERSON, payload: response.data });
    notifications.success("A new Owner record was created");

    // Select the recently created owner in the Management page
    dispatch(selectOwner({ type: "person", id: response.data.id }));
  };

export const createSigner = ownerId => async dispatch => {
  const signerInfo = { entity_id: ownerId };
  const endpoint = `/life_settlement/api/owner_signers/`;
  const response = await advisorhq.post(endpoint, signerInfo);
  dispatch({ type: types.CREATE_OWNER_SIGNER, payload: response.data });
  notifications.success("A new Signer record was created");
};

export const changeOwnerType =
  (ownerInfo, newOwnerType, ownership_percentage) => async dispatch => {
    if (newOwnerType === "person") {
      await dispatch(deleteOwnerEntity(ownerInfo.id));
      await dispatch(createOwnerPerson(ownerInfo.case, ownership_percentage));
    } else if (newOwnerType === "entity") {
      await dispatch(deleteOwnerPerson(ownerInfo.id));
      await dispatch(createOwnerEntity(ownerInfo.case, ownership_percentage));
    }
  };

export const deleteOwnerEntity = ownerId => async dispatch => {
  const endpoint = `/life_settlement/api/owner_entities_information/${ownerId}/`;
  await advisorhq.delete(endpoint);
  dispatch(selectOwner(null)); // reset selected owner
  dispatch({ type: types.DELETE_OWNER_ENTITY, payload: ownerId });
  notifications.success("Owner entity deleted successfully");
};

export const deleteOwnerPerson = ownerId => async dispatch => {
  const endpoint = `/life_settlement/api/owner_persons_information/${ownerId}/`;
  await advisorhq.delete(endpoint);
  dispatch(selectOwner(null)); // reset selected owner
  dispatch({ type: types.DELETE_OWNER_PERSON, payload: ownerId });
  notifications.success("Owner person deleted successfully");
};

export const deleteOwnerInsured = ownerId => async dispatch => {
  const endpoint = `/life_settlement/api/owner_insured_information/${ownerId}/`;
  await advisorhq.delete(endpoint);
  dispatch(selectOwner(null)); // reset selected owner
  dispatch({ type: types.DELETE_OWNER_INSURED, payload: ownerId });
  notifications.success("Owner deleted successfully");
};

export const deleteOwnerSigner = signerId => async dispatch => {
  const endpoint = `/life_settlement/api/owner_signers/${signerId}/`;
  await advisorhq.delete(endpoint);
  dispatch({ type: types.DELETE_OWNER_SIGNER, payload: signerId });
  notifications.success("Signer deleted successfully");
};

export const editOwnerEntityInfo =
  (ownerId, newValues) => async (dispatch, getState) => {
    const endpoint = `/life_settlement/api/owner_entities_information/${ownerId}/`;
    const response = await advisorhq.patch(endpoint, newValues);
    dispatch({ type: types.EDIT_OWNER_ENTITY, payload: response.data });
  };

export const editOwnerPersonInfo = (ownerId, newValues) => async dispatch => {
  const endpoint = `/life_settlement/api/owner_persons_information/${ownerId}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  dispatch({ type: types.EDIT_OWNER_PERSON, payload: response.data });
  notifications.success("Owner Person updated successfully");
};

export const editOwnerInsured = (ownerId, newValues) => async dispatch => {
  const endpoint = `/life_settlement/api/owner_insured_information/${ownerId}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  dispatch({ type: types.EDIT_OWNER_INSURED, payload: response.data });
  notifications.success("Owner updated successfully");
};
