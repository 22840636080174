import React from "react";
import { connect } from "react-redux";

import { activeFunderSelector } from "reducers";
import { FaArrowLeft, FaDownload } from "react-icons/fa";
import { URLS } from "../../constants";
import { Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, TabGroup } from "../../components/Tabs";
import { downloadFile, setShouldSave } from "../../actions";
import moment from "moment";

const FunderDetailTopHeader = ({ ...props }) => {
  const { id } = useParams();
  const path = `/funders/${id}`;
  const history = useNavigate();

  const SaveButton = saveFn => {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: 0 }}>
        <button
          className="btn btn--secondary navbar-save-button"
          onClick={async () => {
            await props.setShouldSave();
          }}
          style={{
            width: "100%",
          }}
        >
          Save Changes
        </button>
      </div>
    );
  };

  const BackToFundersButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: 0 }}>
        <button
          className="btn btn--secondary navbar-save-button"
          onClick={() => history(URLS.FUNDERS)}
          style={{
            width: "100%",
          }}
        >
          <FaArrowLeft /> Back to Funders
        </button>
      </div>
    );
  };

  const DownloadCaseStatusButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: 0 }}>
        <button
          className="btn btn--secondary navbar-save-button"
          onClick={() => {
            const today = moment().format("MM/DD/YYYY");
            const url = `/life_settlement/api/funder/${props.funderInfo.id}/download_case_status/`;
            props.downloadFile(url, `LifeRoc_-_${props.funderName}_Funder_Pipeline_Report_(${today}).xlsx`);
          }}
          style={{
            width: "100%",
          }}
        >
          <FaDownload /> Download Pipeline Report
        </button>
      </div>
    );
  };

  const h3style = {
    width: "100%",
    top: "7.2rem",
    left: 0,
    zIndex: 1,
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "4rem",
        left: "0rem",
        width: "100%",
        zIndex: 25,
      }}
    >
      <Card style={{ border: "1px solid black", borderRadius: "0px" }}>
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            backgroundColor: "black",
          }}
        >
          <h5
            className="section-title navbar-title"
            style={{
              color: "white",
              alignItems: "center",
              marginBottom: 0,
              lineHeight: 2,
              width: "30%",
            }}
          >
            Funder: {props.funderName}
          </h5>
          <div style={{ display: "flex" }}>
            <BackToFundersButton />
            <DownloadCaseStatusButton />
            <SaveButton saveFn={props.saveFn} />
          </div>
        </Card.Header>
      </Card>
      <h3 className={"header tertiary"} style={h3style}>
        <TabGroup
          style={{
            width: "100%",
          }}
        >
          <Tab link={`${path}/overview/`}>Funder Overview</Tab>
          <Tab link={`${path}/contacts/`}>Funder Contacts</Tab>
          <Tab link={`${path}/entities/`}>Funder Entities</Tab>
          <Tab link={`${path}/cases/`}>Funder Cases</Tab>
          <Tab link={`${path}/case-fits/`}>Funder Case Fits</Tab>
        </TabGroup>
      </h3>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  let funderInfo = activeFunderSelector(state);
  let funderName = "";
  if (funderInfo) {
    funderName = funderInfo.name;
  }
  return {
    funderInfo,
    funderName,
  };
};

export default connect(mapStateToProps, {
  setShouldSave,
  downloadFile,
})(FunderDetailTopHeader);
