import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { TextInput, FormFilter, SubmitSearchButton, ClearFiltersButton, StateSelect } from "components/forms";
import { fetchAccounts, fetchLifeSettlementChoices, updateAccountFilters } from "actions";
import { managementChoicesSelector } from "reducers";

const SelectAccountForAdvisorLeadFilterForm = ({ fetchAccounts, fetchLifeSettlementChoices, ...props }) => {
  React.useEffect(() => {
    fetchLifeSettlementChoices();
  }, []);

  return (
    <Formik
      initialValues={{}}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const filters = {
          ...values,
        };
        props.updateAccountFilters(filters);
        await fetchAccounts(filters);
        setSubmitting(false);
      }}
      onReset={async (values, { setSubmitting }) => {
        props.updateAccountFilters({});
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, resetForm }) => {
        const resetCallback = () => {
          props.updateAccountFilters({});
          resetForm();
          fetchAccounts();
        };

        return (
          <FormFilter title={"Accounts Search"} onSubmit={handleSubmit}>
            <Form.Row>
              <TextInput label="Last Name" name="last_name" />
              <TextInput label="First Name" name="first_name" />
              <TextInput label="Emails" name="email" />
            </Form.Row>
            <Form.Row>
              <TextInput label="Phone" name="phone" />
              <TextInput label="City" name="city" />
              <StateSelect label="State" name="state" placeholder="Select State" />
            </Form.Row>
            <SubmitSearchButton />
            <ClearFiltersButton onClick={resetCallback} />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);

  return {
    salesRepChoices: choices.case_sales_representatives,
    nationalAccountChoices: choices.national_accounts,
  };
};

export default connect(mapStateToProps, {
  fetchAccounts,
  updateAccountFilters,
  fetchLifeSettlementChoices,
})(SelectAccountForAdvisorLeadFilterForm);
