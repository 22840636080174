import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { FormCollapsible, Select, SubmitButton, TextInput } from "components/forms";
import { createStateFile, editStateFile } from "actions";
import { Form } from "react-bootstrap";
import { activeStateBrokerFileSelector } from "../../reducers";
import { partial } from "../../utils";
import * as notifications from "../../notifications";
import { isAdmin } from "permissions";
import { brokerDocumentTypeChoices, documentStatusChoices } from "./constants";

const StateBrokerFileForm = ({ activeStateBrokerFile, hasAdminPermission, ...props }) => {
  const methodType = "BROKER";
  return (
    <Formik
      enableReinitialize
      initialValues={{ ...activeStateBrokerFile }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        let method = partial(props.createStateFile, methodType);
        if (activeStateBrokerFile) {
          method = partial(props.editStateFile, activeStateBrokerFile.id, methodType);
        }

        try {
          delete values.date_uploaded; // read-only
          // delete values.file; // File is saved through a different endpoint
          await method(values);
          notifications.success("State File saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing State File");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        let title = "New State File";
        if (activeStateBrokerFile) {
          title = "Edit State File";
        }
        return (
          <>
            <FormCollapsible onSubmit={handleSubmit} title={title}>
              <Form.Row>
                <Select
                  label="Document Type"
                  name="broker_document_type"
                  placeholder="Enter Document Type"
                  options={brokerDocumentTypeChoices}
                />
              </Form.Row>
              <Form.Row>
                <TextInput label="Title" name="title" placeholder="Enter Document Title" />
              </Form.Row>
              <Form.Row>
                <Select
                  label="Status"
                  name="status"
                  placeholder="Enter Document Status"
                  options={documentStatusChoices}
                />
              </Form.Row>
              <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
            </FormCollapsible>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    activeStateBrokerFile: activeStateBrokerFileSelector(state),
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  createStateFile,
  editStateFile,
})(StateBrokerFileForm);
