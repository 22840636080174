import React, { useEffect } from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { CASE_EMAIL_MODAL } from "components/modals";
import CaseEmailForm from "./CaseEmailForm";
import { activeCaseFileSelector, activeCaseSelector, activeLifeExpectancySelector, casePdfUrlSelector } from "reducers";
import {
  deleteCaseFile,
  deleteCaseFileFile,
  deleteLifeExpectancyFile,
  editLifeExpectancy,
  fetchLifeExpectancies,
  uploadCaseFile,
  generateCasePdf,
} from "actions";
import { formatBytes } from "utils";

const CaseEmailModal = ({ activeCaseFile, activeLifeExpectancy, generateCasePdf, casePdfUrl, ...props }) => {
  // this is used to ...
  useEffect(() => {
    (async () => {
      await generateCasePdf();
    })();
  }, []);
  let currentFile = {
    name: props.caseInfo
      ? `${props.caseInfo.submission_id}-CASE-REPORT.pdf` || "liferoccase-CASE-REPORT.pdf"
      : "liferoccase-CASE-REPORT.pdf",
    size: casePdfUrl ? formatBytes(casePdfUrl.size) : formatBytes(0),
    url: casePdfUrl ? casePdfUrl.url : "",
  };
  const generalFormSubmitRef = React.useRef();

  return (
    <FullPageModal modalType={CASE_EMAIL_MODAL}>
      <CaseEmailForm submitRef={generalFormSubmitRef} currentFile={currentFile} />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
    activeCaseFile: activeCaseFileSelector(state),
    activeLifeExpectancy: activeLifeExpectancySelector(state),
    casePdfUrl: casePdfUrlSelector(state),
  };
};

export default connect(mapStateToProps, {
  uploadCaseFile,
  deleteCaseFile,
  deleteCaseFileFile,
  deleteLifeExpectancyFile,
  editLifeExpectancy,
  fetchLifeExpectancies,
  generateCasePdf,
})(CaseEmailModal);
