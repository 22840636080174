import React from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import * as moment from "moment";

import { getValidationClassName } from "./validation";
import { DATE_FORMAT } from "constants.js";
import Label from "./Label";
import FieldErrors from "./FieldErrors";
import { FaCalendarAlt } from "react-icons/fa";
import { getViewMode } from "../../utils";

export const toServerFormat = date => {
  if (date) return moment(date).format(DATE_FORMAT.SERVER.MOMENT);
  return null;
};

/* Component that represents Date inputs.
 * Internally it stores two fields: one hidden field that will
 * hold the date using "DATE_SERVER_FORMAT", and another (visible)
 * field to choose dates. Each time a date is chosen, the value will
 * be formatted to DATE_SERVER_FORMAT and saved in the hidden input. */
const DateInput = ({ label, md = "", pickerDateFormat = DATE_FORMAT.DISPLAY.PICKER, name, isRequired, ...props }) => {
  const [field, meta] = useField({ name });
  const { setFieldValue } = useFormikContext();
  const isViewMode = getViewMode();
  const isDisabled = props.disabled || isViewMode;

  const validateClassName = getValidationClassName(meta);
  let selectedDate = null;
  if (field.value) {
    selectedDate = moment(field.value, DATE_FORMAT.SERVER.MOMENT).toDate();
    if (isNaN(selectedDate.getTime())) {
      // Try another format
      selectedDate = moment(field.value, "MM-DD-YYYY").toDate();
    }
  }
  // const calcWidth = 100 - (label.length * 1 + 35) + "%";

  return (
    <Form.Group as={Col} style={props.style} sm="12" className={`col-md${md && "-" + md}`}>
      <InputGroup style={{ flexWrap: "nowrap" }}>
        <InputGroup.Prepend>
          <InputGroup.Text style={{ textOverflow: "ellipsis" }}>{label}</InputGroup.Text>
          <InputGroup.Text id="dollarSign">
            <FaCalendarAlt />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <div style={{ flexGrow: 1 }} className={validateClassName}>
          <input type="hidden" {...field} value={toServerFormat(field.value) || ""} />
          <DatePicker
            {...props}
            autoComplete="off"
            className={`form-control ${validateClassName}`}
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            preventOpenOnFocus={true}
            selected={selectedDate}
            onChange={val => {
              setFieldValue(field.name, toServerFormat(val));
            }}
            dateFormat={pickerDateFormat}
            disabled={isDisabled}
          />
        </div>
        <FieldErrors meta={meta} />
      </InputGroup>
    </Form.Group>
  );
};

export default DateInput;
