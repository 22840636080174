import React from "react";
import { Select, TextInput } from "components/forms";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import { FaEdit, FaPlus } from "react-icons/fa";
import { PRODUCT_MODAL } from "components/modals";
import { connect } from "react-redux";
import { clearSelectedProduct, selectProduct, showModal } from "actions";
import { POLICY_TYPES, PRODUCT_TYPES } from "../../../constants";
import { getViewMode } from "../../../utils";
import { isAdmin } from "permissions";

const CarrierProductSelect = ({ hasAdminPermission, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const isViewMode = getViewMode();

  const productCallback = product => {
    setFieldValue("carrier", product.carrier_id);
    setFieldValue("product", product.id);
    setFieldValue("product_type", product.product_type);
    setFieldValue("product_number_of_insured_lives", product.number_of_insured_lives);
    setFieldValue("product_form_number", product.form_number);
    setFieldValue("product_policy_type", product.policy_type);
    setFieldValue("product_max_coi_age", product.max_coi_age);
    setFieldValue("product_max_db_age", product.max_db_age);
    setFieldValue("product_age_basis", product.age_basis);
  };

  return (
    <>
      <Form.Row>
        <Select
          label="Carrier"
          name="carrier"
          placeholder="Select Carrier"
          options={props.carrierChoices.getChoices()}
          onChange={option => {
            let value;
            value = option === null ? "" : option.value;
            setFieldValue("carrier", value);
            setFieldValue("product", "");
            props.onCarrierChange(value);
          }}
          disabled={!hasAdminPermission}
          md={3}
        />
        <Select
          label="Product Name"
          name="product"
          placeholder="Select a Product"
          disabled={!hasAdminPermission}
          options={props.productChoices}
          md={3}
          onChange={async option => {
            let value;
            value = option === null ? "" : option.value;
            setFieldValue("product", value);
            let fieldsLoading = [
              "product_type",
              "product_type",
              "product_number_of_insured_lives",
              "product_form_number",
              "product_policy_type",
              "product_policy_type",
              "product_max_coi_age",
              "product_max_db_age",
              "product_age_basis",
            ];
            for (let field of fieldsLoading) {
              setFieldValue(field, "Loading...");
            }
            let product = await props.selectProduct(value);
            if (product) {
              setFieldValue("carrier", product.carrier_id);
              if (product.product_type && PRODUCT_TYPES[product.product_type]) {
                setFieldValue("product_type", PRODUCT_TYPES[product.product_type]);
              } else {
                setFieldValue("product_type", product.product_type || "");
              }
              setFieldValue("product_number_of_insured_lives", product.number_of_insured_lives);
              setFieldValue("product_form_number", product.form_number);
              if (product.policy_type && POLICY_TYPES[product.policy_type]) {
                setFieldValue("product_policy_type", POLICY_TYPES[product.policy_type]);
              } else {
                setFieldValue("product_policy_type", product.policy_type || "");
              }
              setFieldValue("product_max_coi_age", product.max_coi_age || "");
              setFieldValue("product_max_db_age", product.max_db_age || "");
              let age_basis = "";
              if (product.age_basis === "NEAR") {
                age_basis = "Age Nearest";
              } else if (product.age_basis === "LAST") {
                age_basis = "Age Last";
              }
              setFieldValue("product_age_basis", age_basis);
            }
          }}
        />
        <div style={{ display: "flex", flexWrap: "nowrap" }} className={"col-md-3"}>
          {hasAdminPermission && !isViewMode && (
            <>
              <button
                className={`btn btn-sm btn--secondary`}
                style={{
                  marginLeft: 5,
                  fontSize: 12,
                  padding: "2px 5px",
                }}
                type="button"
                onClick={e => {
                  props.clearSelectedProduct();
                  props.showModal(PRODUCT_MODAL, {
                    callback: productCallback,
                  });
                }}
                {...props}
              >
                <FaPlus />
                Add
              </button>
              <button
                className={`btn btn-sm btn--secondary`}
                style={{ fontSize: 12, padding: "2px 5px", marginLeft: "5px" }}
                type="button"
                onClick={e => {
                  props.selectProduct(props.values.product);
                  props.showModal(PRODUCT_MODAL, {
                    callback: productCallback,
                  });
                }}
                {...props}
              >
                <FaEdit />
                Manage Product
              </button>
            </>
          )}
          <TextInput
            label="Insured Lives"
            name="product_number_of_insured_lives"
            style={{ marginRight: "0px" }}
            disabled
          />
        </div>
        <TextInput label="Product Type" name="product_type" md={3} disabled />
      </Form.Row>
      <Form.Row>
        <TextInput label="Form Number" name="product_form_number" disabled />
        <TextInput label="Policy Type" name="product_policy_type" disabled />
        <TextInput label="Max COI Age" name="product_max_coi_age" disabled />
        <TextInput label="Max DB Age" name="product_max_db_age" disabled />
        <TextInput label="Age Basis" name="product_age_basis" disabled />
      </Form.Row>
    </>
  );
};

const mapStateToProps = state => {
  return {
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  selectProduct,
  clearSelectedProduct,
  showModal,
})(CarrierProductSelect);
