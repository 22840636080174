import React from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import * as moment from "moment-timezone";

import { getValidationClassName } from "./validation";
import { DATETIME_FORMAT } from "constants.js";
import Label from "./Label";
import FieldErrors from "./FieldErrors";
import FieldHelpText from "./FieldHelpText";
import { makeDateNaive } from "utils.js";

const toServerFormat = date => {
  if (date) {
    const dateStr = moment(date).format(DATETIME_FORMAT.SERVER.MOMENT);
    return makeDateNaive(dateStr);
  }
  return null;
};

/* Component that represents DateTime inputs.
 *
 * Internally has two fields:
 *  A hidden field that holds the value to be posted, and a presentational
 *  field using react-datepicker package.
 *
 * The posted datetimes will be "naive": we remove the tz info part
 * from the iso-8601 string representation and post just the date and time.
 * The server will assume the timezone is "America/New_York" and save the datetime
 * using that timezone. Therefore we ensure a consistent timezone for the posted dates
 * from the client.
 */
const DateTimeInputWithNowAsDefault = ({
                         label,
                         md = "",
                         pickerDateFormat = DATETIME_FORMAT.DISPLAY.PICKER,
                         name,
                         isRequired,
                         ...props
                       }) => {
  const [field, meta] = useField({ name });
  const { setFieldValue } = useFormikContext();

  const validateClassName = getValidationClassName(meta);

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      <Label label={label} required={isRequired} />
      <input type="hidden" {...field} value={toServerFormat(field.value) || ""} />
      <InputGroup>
        <DatePicker
          {...props}
          autoComplete="off"
          className={`form-control ${validateClassName}`}
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          showTimeInput
          selected={
            (field.value &&
              moment(
                makeDateNaive(field.value),
                DATETIME_FORMAT.SERVER.MOMENT,
              ).toDate()) ||
            moment(
              makeDateNaive(moment().toISOString()),
              DATETIME_FORMAT.SERVER.MOMENT,
            ).toDate()
          }
          onChange={val => {
            setFieldValue(field.name, toServerFormat(val));
          }}
          dateFormat={pickerDateFormat}
        />
        <FieldErrors meta={meta} />
      </InputGroup>
      <FieldHelpText>Please enter time in EST timezone</FieldHelpText>
    </Form.Group>
  );
};

export default DateTimeInputWithNowAsDefault;
