import React from "react";

import { ButtonCreate } from "components/buttons";
import { TableHeader } from "components/table";
import { getViewMode } from "../../utils";

// Table to a list of records, with a title and a create button
const RecordsTable = ({
  Table,
  title,
  onCreateClick,
  createButtonLabel = "Create New",
  buttonDisabled = false,
  darker = false,
  ...props
}) => {
  const isViewMode = getViewMode();
  const createButton =
    createButtonLabel && !isViewMode ? (
      <ButtonCreate onClick={onCreateClick} disabled={buttonDisabled} key="create">
        {createButtonLabel}
      </ButtonCreate>
    ) : null;
  const renderActionButtons = () => {
    let actions = props.actionButtons ? props.actionButtons : [createButton];
    return <TableHeader.Actions>{actions.map(a => a)}</TableHeader.Actions>;
  };

  return (
    <div className="records-table">
      <TableHeader darker={darker}>
        <TableHeader.Title>{title}</TableHeader.Title>
        {renderActionButtons()}
      </TableHeader>
      <Table darker={darker} />
    </div>
  );
};

export default RecordsTable;
