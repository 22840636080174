import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import {
  BooleanSelect,
  Collapsible,
  DateInput,
  DateTimeInput,
  EditorField,
  Select,
  StateSelect,
  TextInput,
} from "components/forms";
import { availableEntitiesSelector, shouldSaveSelector, manageEntitySelector } from "reducers";
import { Form } from "react-bootstrap";
import { partial } from "../../../utils";
import { isAdmin } from "permissions";
import { editAccountWithoutUpdating, createEmptyEntityAccountForAccount, unsetShouldSave } from "actions";
import * as notifications from "../../../notifications";
import {
  ACCOUNT_TYPES,
  LINES_OF_BUSINESS,
  MARKETING_SOURCES,
  PRACTICE_TYPES,
  SOURCE_OF_ACCOUNTS,
  YES_NO_PAST,
} from "../constants";

const AccountForm = ({
  accountInfo,
  activeHierarchySalesRepName,
  availableEntitiesForUser,
  hasAdminPermission,
  ...props
}) => {
  if (!accountInfo) {
    // Didn't receive the account yet.
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...accountInfo,
        availableEntitiesForUser,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        console.log("<<<<<<<<<");
        const method = partial(props.editAccountWithoutUpdating, accountInfo.id);
        try {
          await method(values);
          notifications.success("Account saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Account");
        }
        props.unsetShouldSave();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit} className={"form shadowed"} {...props}>
            <Collapsible title="Detail">
              <Form.Row>
                <Select
                  label="Account Type"
                  name="type"
                  placeholder="Select Account Type"
                  options={ACCOUNT_TYPES}
                  md={4}
                  disabled={true}
                />
                <TextInput label="Account Name" name="source_name" disabled />
                {values.type === 0 ? (
                  <Select
                    label="Entity Name"
                    name="associated_entity"
                    placeholder="Select Entity"
                    options={availableEntitiesForUser}
                    disabled={true}
                  />
                ) : (
                  <TextInput label="Entity Name" name="entity_name" />
                )}
              </Form.Row>
              <Form.Row>
                <TextInput label="Account ID" name="system_id" disabled />
                <TextInput label="National Producer Number" name="national_producer_number" disabled />
                <TextInput label="Sales Representative" name="activeHierarchySalesRepName" disabled />
              </Form.Row>
              {values.type === 0 ? (
                <>
                  <Form.Row>
                    <TextInput label="First Name" name="first_name" />
                    <TextInput label="Middle Name" name="middle_name" />
                    <TextInput label="Last Name" name="last_name" />
                  </Form.Row>
                  <Form.Row>
                    <TextInput label="Nick Name" name="nick_name" />
                    <TextInput label="Title" name="title" />
                    <DateInput label="Date of Birth" name="date_of_birth" disabled />
                  </Form.Row>
                </>
              ) : null}
              <Form.Row>
                <TextInput label="Work Phone" name="work_phone" />
                <TextInput label="Cell Phone" name="cell_phone" />
                <TextInput label="Other Phone" name="other_phone" />
              </Form.Row>
              <Form.Row>
                <TextInput label="Work Extension" name="work_extension" />
                <TextInput label="Fax Number" name="fax" />
                <TextInput label="Website" name="website" />
              </Form.Row>
              <Form.Row>
                <TextInput label="Work Email" lowercase={true} name="work_email" />
                <TextInput label="Other Email" lowercase={true} name="other_email" />
                <TextInput label="Case Status Email" lowercase={true} name="email" />
              </Form.Row>
              <Form.Row>
                <TextInput label="Street Address" name="street_address" md={12} />
              </Form.Row>
              <Form.Row>
                <TextInput label="City" name="city" />
                <StateSelect label="State" name="state" placeholder="Select State" />
                <TextInput label="Zip Code" name="zipcode" />
              </Form.Row>
              <Form.Row>
                <BooleanSelect label="Email Opt Out" name="email_opt_out" md={4} />
                <TextInput label="LinkedIn" name="linkedin" />
                <TextInput label="Study Groups" name="study_groups" />
              </Form.Row>
              <Form.Row>
                <EditorField label="Notes" name="notes" />
              </Form.Row>
            </Collapsible>
            <Collapsible title="Qualitative Insight">
              <Form.Row>
                <Select
                  label="Data Source"
                  name="source_of_account"
                  placeholder="Select Data Source"
                  options={SOURCE_OF_ACCOUNTS}
                  disabled={!hasAdminPermission}
                />
                <Select
                  label="Marketing Source"
                  name="marketing_source"
                  placeholder="Select Marketing Source"
                  options={MARKETING_SOURCES}
                />
                <DateInput
                  label="Date Life Licensed"
                  name="date_life_licensed"
                  placeholder="Select Date Life Licensed"
                />
              </Form.Row>
              <Form.Row>
                <TextInput
                  label="Last Marketing Engagement"
                  name="last_marketing_engagement"
                  placeholder="TBD"
                  disabled
                />
                <TextInput label="Last Case Submit" name="last_case_submit" disabled />
                <TextInput label="Last Case Paid" name="last_case_paid" disabled />
              </Form.Row>
              <Form.Row>
                <DateInput label="Date Account Created" name="date_created" disabled />
                <DateTimeInput label="Date Account Last Modified" name="last_edit_date" disabled />
                <TextInput label="Last Modified By" name="last_edited_by_name" disabled />
              </Form.Row>
            </Collapsible>
            <Collapsible title="Practice Profile">
              <Form.Row>
                <Select
                  label="Practice Type"
                  name="practice_type"
                  placeholder="Select Practice Type"
                  options={PRACTICE_TYPES}
                />
                <Select
                  label="Main Line of Business"
                  name="main_line_of_business"
                  placeholder="Select Main Line of Business"
                  options={LINES_OF_BUSINESS}
                />
                <Select label="Accounting" name="accounting" placeholder="Select Accounting" options={YES_NO_PAST} />
                <Select label="Annuities" name="annuities" placeholder="Select Annuities" options={YES_NO_PAST} />
              </Form.Row>
              <Form.Row>
                <Select label="Banking" name="banking" placeholder="Select Banking" options={YES_NO_PAST} />
                <Select
                  label="Disability Insurance"
                  name="disability_insurance"
                  placeholder="Select Disability Insurance"
                  options={YES_NO_PAST}
                />
                <Select
                  label="Health Insurance"
                  name="health_insurance"
                  placeholder="Select Health Insurance"
                  options={YES_NO_PAST}
                />
                <Select label="Legal" name="legal" placeholder="Select Legal" options={YES_NO_PAST} />
              </Form.Row>
              <Form.Row>
                <Select
                  label="Life Insurance"
                  name="life_insurance"
                  placeholder="Select Life Insurance"
                  options={YES_NO_PAST}
                />
                <Select
                  label="Life Settlements"
                  name="life_settlements"
                  placeholder="Select Life Settlements"
                  options={YES_NO_PAST}
                />
                <Select
                  label="Premium Finance"
                  name="premium_finance"
                  placeholder="Select Premium Finance"
                  options={YES_NO_PAST}
                />
                <Select label="Medicare" name="medicare" placeholder="Select Medicare" options={YES_NO_PAST} />
              </Form.Row>
              <Form.Row>
                <Select
                  label="Property and Casualty"
                  name="property_and_casualty"
                  placeholder="Select Property and Casualty"
                  options={YES_NO_PAST}
                />
                <Select
                  label="Registered Investment Advice"
                  name="registered_investment_advice"
                  placeholder="Select Registered Investment Advice"
                  options={YES_NO_PAST}
                  md={3}
                />
                <Select
                  label="Securities"
                  name="securities"
                  placeholder="Select Securities"
                  options={YES_NO_PAST}
                  md={3}
                />
                <Select
                  label="Trust Services"
                  name="trust_services"
                  placeholder="Select Trust Services"
                  options={YES_NO_PAST}
                  md={3}
                />
              </Form.Row>
            </Collapsible>
            <Collapsible title="Designations">
              <Form.Row>
                <Select label="CFP" name="CFP" placeholder="Select CFP" options={YES_NO_PAST} />
                <Select label="CHFC" name="CHFC" placeholder="Select CHFC" options={YES_NO_PAST} />
                <Select label="CLU" name="CLU" placeholder="Select CLU" options={YES_NO_PAST} />
              </Form.Row>
              <Form.Row>
                <Select label="LUTCF" name="LUTCF" placeholder="Select LUTCF" options={YES_NO_PAST} md={4} />
                <Select label="MDRT" name="MDRT" placeholder="Select MDRT" options={YES_NO_PAST} md={4} />
              </Form.Row>
            </Collapsible>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="btn btn--secondary"
                type="submit"
                style={{
                  width: "99%",
                  marginBottom: "1rem",
                }}
              >
                Save Changes
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const availableEntitiesForUser = availableEntitiesSelector(state);
  return {
    shouldSave: shouldSaveSelector(state),
    hasAdminPermission: isAdmin(state),
    availableEntitiesForUser,
    accountInfo: manageEntitySelector(state),
  };
};

export default connect(mapStateToProps, {
  editAccountWithoutUpdating,
  unsetShouldSave,
  createEmptyEntityAccountForAccount,
})(AccountForm);
