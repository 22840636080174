import React from "react";

import MortalityTable from "../MortalityTable.js";

// Columns indexes
const [YEAR, LIVES, DEATHS, ACC_DEATHS] = [0, 1, 2, 3];

// Helper object to manage rows
const Row = {
  values: [],

  get year() {
    return this._get(YEAR);
  },

  get lives() {
    return this._get(LIVES);
  },

  get deaths() {
    return this._get(DEATHS);
  },

  get accDeaths() {
    return this._get(ACC_DEATHS);
  },

  addError(idx, error) {
    const col = this.values[idx];
    if (!col.errors) {
      col.errors = [error];
    } else {
      const found = col.errors.find(e => e.code === error.code);
      if (!found) {
        col.errors.push(error);
      }
    }
  },

  removeError(idx, error) {
    const col = this.values[idx];
    if (col.errors) {
      col.errors = col.errors.filter(err => err.code !== error.code);
    }
  },

  createEmpty(year) {
    const values = [{ value: year }, { value: "" }, { value: "" }, { value: "" }];
    return createRow(values);
  },

  _get(idx) {
    return Number(this.values[idx].value);
  },
};

// Helper func to create row objects using Row as prototype
const createRow = values => {
  return Object.create(Row, { values: { value: values } });
};

// Validate mortality table data
const validate = values => {
  if (!values || values.length === 0) {
    return values;
  }

  const title = values[0];
  let rows = values.slice(1);

  // All first rows are the same:
  //  Year   Lives    Deaths  Accumulated Deaths
  //  0      1000     0       0
  //
  // If the first number is not 0, append the
  const expectedFirstRow = [{ value: 0 }, { value: 1000 }, { value: 0 }, { value: 0 }];
  const firstRow = rows[0];
  const firstYear = firstRow[YEAR].value;

  if (firstYear !== 0) {
    // If the first does not start at year 0, insert the expected row
    rows.splice(0, 0, expectedFirstRow);
  } else {
    // Else just replace the row with the expected one
    rows[0] = expectedFirstRow;
  }

  // Create Row objects
  rows = rows.map(rowValues => createRow(rowValues));

  // Years must be consecutive, from 0 to max-year. Fill with blanks those missing rows.
  const years = new Set(rows.map(row => row.year));
  const maxYear = Math.max(...years);
  const missingRows = [];
  for (let year = 0; year < maxYear; year++) {
    if (!years.has(year)) {
      missingRows.push(Row.createEmpty(year));
    }
  }
  rows = [...rows, ...missingRows].sort((r1, r2) => r1.year - r2.year);

  // Validate that "Lives" + "Accumulated Deaths" = 1000 for every row
  rows.forEach(row => {
    const err = {
      code: 1,
      Content: () => <span>"Lives" + "Accumulated Deaths" must equal 1000</span>,
    };
    if (row.lives && row.accDeaths && row.lives + row.accDeaths !== 1000) {
      row.addError(LIVES, err);
      row.addError(ACC_DEATHS, err);
    } else {
      row.removeError(LIVES, err);
      row.removeError(ACC_DEATHS, err);
    }
  });

  // Validate that "Accumulated Deaths (i-1) + Deaths (i) = Accumulated Deaths (i)"
  rows.forEach((row, i, allRows) => {
    if (i === 0) {
      return;
    }

    const prevRow = allRows[i - 1];
    const prevAccDeaths = prevRow.accDeaths;

    const err = {
      code: 2,
      Content: () => (
        <span>
          "Accumulated Deaths<sub>{prevRow.year}</sub>" + "Deaths<sub>{row.year}</sub>" must equal "Accumulated Deaths
          <sub>{row.year}</sub> " ({prevAccDeaths} + {row.deaths} ≠ {row.accDeaths})
        </span>
      ),
    };

    if (prevAccDeaths && row.deaths && row.accDeaths) {
      if (row.accDeaths !== row.deaths + prevAccDeaths) {
        row.addError(DEATHS, err);
        row.addError(ACC_DEATHS, err);
        prevRow.addError(ACC_DEATHS, err);
      } else {
        row.removeError(DEATHS, err);
        row.removeError(ACC_DEATHS, err);
        prevRow.removeError(ACC_DEATHS, err);
      }
    }
  });

  // convert back Row objects to vanilla objects
  const validated = rows.map(row => row.values);

  return [title, ...validated];
};

export default ({ submitRef }) => <MortalityTable validate={validate} submitRef={submitRef} />;
