import React from "react";

import { TextInput } from "components/forms";
import moment from "moment";

const AgeAssessedField = ({ date_of_birth, certificate_date, label, md = 4 }) => {
  return (
    <TextInput
      label={label || "Age Assessed"}
      name="age"
      disabled
      md={md}
      value={date_of_birth && certificate_date ? moment(certificate_date).diff(date_of_birth, "years") : null}
    />
  );
};

export default AgeAssessedField;
