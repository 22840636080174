import React from "react";
import { getLabel, getValue } from "utils.js";

function autoFocusAndSelect(input) {
  if (input) {
    input.focus();
    input.select();
  }
}

export const DateEditor = ({ row, column, onRowChange, onClose }) => {
  return (
    <input
      type="date"
      className={"rdg-text-editor"}
      ref={autoFocusAndSelect}
      value={row[column.key]}
      onChange={event => {
        onRowChange({ ...row, [column.key]: event.target.value });
      }}
      onBlur={() => {
        onClose(true);
      }}
      style={{ height: "97%", width: "100%" }}
    />
  );
};

export const DropdownEditor =
  ({ fieldName, choices, editCallback }) =>
  ({ row, column, onRowChange, onClose }) => {
    let selectedValue = row[fieldName];
    let selectedLabel = getLabel(choices, selectedValue);
    return (
      <select
        autoFocus
        style={{ width: "100%", height: "100%" }}
        value={selectedLabel}
        className={"rdg-text-editor"}
        onChange={event => {
          let value = getValue(choices, event.target.value);
          let data = {};
          data[fieldName] = value;
          editCallback(row.id, data, row);
        }}
      >
        {choices.map(choice => (
          <option key={choice.label}>{choice.label}</option>
        ))}
      </select>
    );
  };
