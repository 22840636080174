import React from "react";
import { connect } from "react-redux";

import { hideModal, editAccountHierarchy, createAccountHierarchy } from "actions";
import { activeAccountIdSelector, activeHierarchySelector, activeAccountSelector } from "reducers";
import { ACCOUNT_CHANGE_ACTIVE_HIERARCHY_MODAL } from "components/modals";
import { partial } from "../../../../utils";
import * as notifications from "../../../../notifications";
import ConfirmationModal from "components/modals/ConfirmationModal";

const ChangeActiveHierarchyModal = ({ hierarchyValues, activeHierarchyId, currentActiveHierarchyId, ...props }) => {
  const method = hierarchyValues.id
    ? partial(props.editAccountHierarchy, hierarchyValues.id)
    : props.createAccountHierarchy;
  hierarchyValues.account = props.activeAccountId;

  return (
    <ConfirmationModal
      modalType={ACCOUNT_CHANGE_ACTIVE_HIERARCHY_MODAL}
      onConfirm={async () => {
        // this edits the currently active hierarchy and makes it inactive
        await props.editAccountHierarchy(currentActiveHierarchyId, { active: false });
        // this edits or creates the hierarchy from the form
        await method(hierarchyValues);
        props.hideModal(ACCOUNT_CHANGE_ACTIVE_HIERARCHY_MODAL);
      }}
      onCancel={() => {
        // When canceled, just save the form values changing active to false
        method({ ...hierarchyValues, active: false });
        notifications.success("Hierarchy saved successfully (Active Hierarchy saved as 'No')");
      }}
    >
      An active hierarchy already exists for this account. Do you want to mark this one as the active hierarchy instead?
    </ConfirmationModal>
  );
};

const mapStateToProps = (state, ownProps) => {
  const activeHierarchy = activeHierarchySelector(state);
  const activeAccount = activeAccountSelector(state);
  const currentActiveHierarchyId = activeAccount?.current_active_hierarchy;
  return {
    activeAccountId: activeAccountIdSelector(state),
    currentActiveHierarchyId,
    activeHierarchy,
  };
};

export default connect(mapStateToProps, {
  editAccountHierarchy,
  createAccountHierarchy,
  hideModal,
})(ChangeActiveHierarchyModal);
