import React from "react";
import { connect } from "react-redux";
import { FaEdit, FaUser } from "react-icons/fa";

import {
  fetchAllLifeExpectancies,
  selectCaseFile,
  showModal,
  editCase,
  selectLifeSettlementCase,
  fetchCaseFilesByCaseDocumentId,
  fetchInsured,
  storeRelatedLe,
  setCurrentLETableIndex,
} from "actions";
import {
  activeAgencySelector,
  lifeExpectanciesSelector,
  managementChoicesSelector,
  sortBySelector,
  loadingLeSelector,
  administratorEnabledSelector,
} from "reducers";
import IconLink from "components/IconLink";
import Table, { dateCell, sortNumberCell } from "components/table";
import { CASE_FILE_MODAL_LE, RELATED_LE_MODAL } from "components/modals";

const LifeExpectancyTable = ({
  lifeexpectancies_cases,
  showModal,
  selectLifeSettlementCase,
  fetchInsured,
  fetchCaseFilesByCaseDocumentId,
  selectCaseFile,
  activeAgency,
  storeRelatedLe,
  setCurrentLETableIndex,
  loadingLe,
  ...props
}) => {
  // Fetch life expectancies cases when the active agency changes.
  React.useEffect(() => {
    // props.fetchAllLifeExpectancies();
  }, [activeAgency.id]);

  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const caseDocId = row.original.case_document;
        const caseId = row.original.case;
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                fetchInsured(caseId);
                fetchCaseFilesByCaseDocumentId(caseDocId);
                selectCaseFile(caseDocId);
                setCurrentLETableIndex(row.index);
                showModal(CASE_FILE_MODAL_LE);
              }}
            />
            {row.original.related_life_expectancies && row.original.related_life_expectancies.length > 0 && (
              <IconLink
                Icon={FaUser}
                iconConfig={{ className: "table--action-icon" }}
                tooltip="Related life expectancies"
                onClick={() => {
                  storeRelatedLe(row.original.related_life_expectancies);
                  showModal(RELATED_LE_MODAL);
                }}
              />
            )}
          </>
        );
      },
    },
    {
      Header: "Insured Name",
      accessor: "insured_names",
      className: "wrap-text",
    },
    {
      Header: "Gender",
      accessor: "gender",
      className: "wrap-text",
    },
    {
      Header: "DOB",
      accessor: "date_of_birth",
      Cell: dateCell,
    },
    {
      Header: "Age",
      accessor: "insured_age",
      sortType: sortNumberCell,
      className: "wrap-text",
    },
    {
      Header: "LE Provider",
      accessor: "vendor",
      className: "wrap-text",
    },
    {
      Header: "LE Cert Date",
      accessor: "certificate_date",
      className: "wrap-text",
    },
    {
      Header: "LE Age",
      accessor: "le_age",
      sortType: sortNumberCell,
      className: "wrap-text",
    },
    {
      Header: "Table",
      accessor: "mortality_table_name",
      className: "wrap-text",
    },
    {
      Header: "Primary Impairment",
      accessor: "medical_info",
      Cell: ({ row }) => {
        let primary_impairment = "";
        if (row.original.medical_info) {
          if (row.original.medical_info.primary_impairment_category) {
            primary_impairment = row.original.medical_info.primary_impairment_category;
          }
        }
        return <div className="text-wrap">{primary_impairment} </div>;
      },
    },
    {
      Header: "Underwriting type",
      accessor: "underwriting_type",
      className: "wrap-text",
    },
    {
      Header: "Tobacco Rating",
      accessor: "tobacco_use",
      Cell: ({ row }) => {
        return <div className="text-wrap">{row.original.tobacco_use ? "Yes" : "No"} </div>;
      },
    },
    {
      Header: "Mortality Rating",
      accessor: "mortality_rating",
      Cell: ({ row }) => {
        let mortality_rating = "";
        if (row.original.mortality_rating) {
          mortality_rating = `${parseFloat(row.original.mortality_rating).toFixed(2)} %`;
        }
        return <div className="text-wrap">{mortality_rating}</div>;
      },
    },
    {
      Header: "Life Expectancy Mean",
      accessor: "mean",
      className: "wrap-text",
    },
    {
      Header: "Life Expectancy Median",
      accessor: "median",
      className: "wrap-text",
    },
    {
      Header: "Face Amount",
      accessor: "face_amount",
      className: "wrap-text",
    },
    {
      Header: "Case Status",
      accessor: "case_status",
      className: "wrap-text",
    },
  ];

  return (
    <Table
      columns={columns}
      data={lifeexpectancies_cases}
      emptyMessage={loadingLe ? "Loading data..." : "No data"}
      // this initial page size does not set the initial page size
      // but it sets the maximum page size which is not modified
      // then the page size is selected from the dropdown in the pagination
      initialPageSize={100}
    />
  );
};

const mapStateToProps = state => {
  function compare_lname(a, b) {
    const sortByParam = sortBySelector(state);
    let up, down;
    if (sortByParam && a && b) {
      if (sortByParam.desc) {
        up = -1;
        down = 1;
      } else {
        up = 1;
        down = -1;
      }
      if (!a[sortByParam.id]) {
        return down;
      }
      if (!b[sortByParam.id]) {
        return up;
      }
      if (a[sortByParam.id] < b[sortByParam.id]) {
        return down;
      }
      if (a[sortByParam.id] > b[sortByParam.id]) {
        return up;
      }
      return 0;
    }
  }

  let sortedLe = lifeExpectanciesSelector(state);
  sortedLe.sort(compare_lname);
  const choices = managementChoicesSelector(state);

  return {
    lifeexpectancies_cases: sortedLe,
    loadingLe: loadingLeSelector(state),
    activeAgency: activeAgencySelector(state),
    administratorEnabled: administratorEnabledSelector(state),
    accountChoices: choices.accounts,
    salesRepChoices: choices.case_sales_representatives,
  };
};

export default connect(mapStateToProps, {
  fetchAllLifeExpectancies,
  showModal,
  editCase,
  selectCaseFile,
  fetchCaseFilesByCaseDocumentId,
  selectLifeSettlementCase,
  fetchInsured,
  storeRelatedLe,
  setCurrentLETableIndex,
})(LifeExpectancyTable);
