import React from "react";
import { Form } from "react-bootstrap";
import IconLink from "../IconLink";
import { FaQuestionCircle } from "react-icons/all";

const Label = ({ label, required = false, tooltip = false }) => {
  if (label) {
    return (
      <Form.Label>
        <div className="form-label">
          <span className="form-label__label">{label}</span>
          {tooltip && (
            <IconLink
              Icon={FaQuestionCircle}
              iconConfig={{ className: "table--action-icon-tooltip" }}
              tooltip={tooltip}
            />
          )}
          {required && (
            <span className="form-label__required">
              <strong>*</strong>
            </span>
          )}
        </div>
      </Form.Label>
    );
  } else {
    return <div></div>;
  }
};

export default Label;
