import React from "react";
import { connect } from "react-redux";

import { selectCarrier } from "actions";
import { Outlet } from "react-router-dom";
import CarrierTopHeader from "./CarrierTopHeader";
import { compose } from "redux";
import withRouter from "../withRouter";

/* Data component: fetch relevant data for the current carrier */
const CarrierSetup = ({ carrierId, ...props }) => {
  const fetchData = () => {
    props.selectCarrier(carrierId);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <CarrierTopHeader />
      <Outlet />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const carrierId = ownProps.params.id;

  return {
    carrierId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    selectCarrier,
  }),
)(CarrierSetup);
