import React from "react";
import { connect } from "react-redux";

import { createEmptyCase, setActiveTab, initPagination, fetchLifeSettlementCases } from "actions";
import TradingFilterForm from "./TradingFilterForm";
import TradingDashboard from "./TradingDashboard";
import TradingSummary from "./TradingSummary";

const TradingPage = props => {
  const [activeSection, setActiveSection] = React.useState("funders");
  React.useEffect(() => {
    props.setActiveTab(activeSection);
    props.initPagination();
    props.fetchLifeSettlementCases();
    setActiveSection(activeSection);
    document.title = "LifeRoc | Trading";
  }, []);

  return (
    <div>
      <TradingFilterForm />
      <TradingSummary />
      <TradingDashboard />
    </div>
  );
};

export default connect(null, {
  createEmptyCase,
  setActiveTab,
  initPagination,
  fetchLifeSettlementCases,
})(TradingPage);
