import React from "react";
import { connect } from "react-redux";

import FunderCasesFitTable from "./FunderCasesFitTable";
import FunderCasesFitFilterForm from "./FunderCasesFitFilterForm";

const FunderCasesFitPage = ({ ...props }) => {
  return (
    <div style={{ marginTop: 100 }}>
      <FunderCasesFitFilterForm />
      <FunderCasesFitTable />
    </div>
  );
};

export default connect(null, {})(FunderCasesFitPage);
