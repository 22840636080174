import React from "react";
import { connect } from "react-redux";

import { fundersForAuctionSelector } from "reducers";

const NumberOfBiddersValue = props => {
  const [numberOfBidders, setNumberOfBidders] = React.useState(0);

  React.useEffect(() => {
    const bidFunders = props.auctionFunders.filter(
      f =>
        f.verbose_status.includes("Bid (") ||
        f.verbose_status.includes("Bid Contingent (") ||
        f.verbose_status.includes("Pending"),
    );
    setNumberOfBidders(bidFunders.length);
  }, [props.auctionFunders]);

  return numberOfBidders;
};

const mapStateToProps = state => {
  return {
    auctionFunders: fundersForAuctionSelector(state),
  };
};

export default connect(mapStateToProps)(NumberOfBiddersValue);
