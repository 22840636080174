import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import {
  Select,
  TextInput,
  FormFilter,
  SubmitSearchButton,
  ClearFiltersButton,
  DateInput,
  StateSelect,
} from "components/forms";
import { fetchProducts, fetchLifeSettlementChoices } from "actions";
import { managementChoicesSelector } from "reducers";
import { usStatesChoices } from "../../options";
import { policyTypeChoices, productTypeChoices } from "../../constants";

const ProductDatabaseFilterForm = ({ ...props }) => {
  React.useEffect(() => {
    props.fetchLifeSettlementChoices();
  }, [props.fetchLifeSettlementChoices]);

  let [issue_states, setIssueStates] = React.useState();
  let issue_states_initial = [];
  let stateChoices = usStatesChoices.getChoices();
  if (issue_states) {
    let issue_states_list = issue_states.split(",");
    for (let state_code of issue_states_list) {
      let stateData = stateChoices.find(e => e.value === state_code);
      issue_states_initial.push({ value: stateData.value, label: stateData.label });
    }
  }

  const handleStateChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedValues = selectedOptions.map(o => o.value).join(",");
      setIssueStates(commaSeparatedValues);
    } else {
      setIssueStates(undefined);
    }
  };

  return (
    <Formik
      initialValues={{
        name: "",
        carrier: "",
        form_number: "",
        product_id: "",
        product_type: "",
        number_of_insured_lives: "",
        policy_type: "",
        max_db_age: "",
        issue_date_start: "",
        issue_date_end: "",
        issue_states: "",
        max_coi_age: "",
        age_basis: "",
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await props.fetchProducts({ ...values, issue_states: issue_states });
        setSubmitting(false);
      }}
      onReset={async (values, { setSubmitting }) => {
        await props.fetchProducts();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, resetForm }) => {
        return (
          <FormFilter onSubmit={handleSubmit} title={"Product Search"}>
            <Form.Row>
              <TextInput label="Product Name" name="name" />
              <Select
                label="Carrier"
                name="carrier"
                placeholder="Select Carrier"
                options={props.carrierChoices.getChoices()}
              />
              <TextInput label="Form Number" name="form_number" />
              <TextInput label="Product ID" name="product_id" />
            </Form.Row>
            <Form.Row>
              <Select
                label="Product Type"
                name="product_type"
                placeholder="Select Product"
                options={productTypeChoices}
              />
              <Select
                label="Insured Lives"
                name="number_of_insured_lives"
                placeholder="Select Insured Lives"
                emptyValue={null}
                options={[
                  { value: 1, label: "Single" },
                  { value: 2, label: "Joint Survivor" },
                ]}
              />
              <Select
                label="Policy Type"
                name="policy_type"
                placeholder="Select Policy Type"
                options={policyTypeChoices}
              />
              <TextInput label="Max DB Age" name="max_db_age" type="number" />
            </Form.Row>
            <Form.Row>
              <DateInput label="Starting Issue Date" name="issue_date_start" />
              <DateInput label="Ending Issued Date" name="issue_date_end" />
              <StateSelect
                label="Issue States"
                name="issue_states"
                placeholder="Select States"
                isMulti={true}
                onChange={handleStateChange}
                defaultValue={issue_states_initial}
              />
              <TextInput label="Max CoI Age" name="max_coi_age" type="number" />
            </Form.Row>
            <Form.Row>
              <Select
                label="Age Basis"
                name="age_basis"
                placeholder="Select Age Basis"
                options={[
                  { value: "NEAR", label: "Age Nearest" },
                  { value: "LAST", label: "Age Last" },
                ]}
                md={3}
              />
              <Select
                label="Has Product Template"
                name="product_template"
                placeholder="Select Yes/No"
                options={[
                  { value: 1, label: "Yes" },
                  { value: 0, label: "No" },
                ]}
                md={3}
              />
              <Select
                label="Audited"
                name="audited"
                placeholder="Select Yes/No"
                options={[
                  { value: 1, label: "Yes" },
                  { value: 0, label: "No" },
                ]}
                md={3}
              />
            </Form.Row>
            <SubmitSearchButton />
            <ClearFiltersButton onClick={resetForm} />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  return {
    carrierChoices: choices.carriers,
  };
};

export default connect(mapStateToProps, {
  fetchProducts,
  fetchLifeSettlementChoices,
})(ProductDatabaseFilterForm);
