import React from "react";
import { connect } from "react-redux";
import { clearSelectedSynonym, fetchSynonymsByParameter, selectSynonym, showModal } from "actions";
import { activeAgencySelector, activeParameterIdSelector, synonymsSelector } from "reducers";
import Table from "components/table";
import IconLink from "components/IconLink";
import { FaEdit, FaTrash } from "react-icons/fa";
import { DELETE_SYNONYM_MODAL, SYNONYM_MODAL } from "components/modals";
import { isAdmin } from "permissions";
import { loadingSynonymsSelector } from "../../../reducers/life_settlement/loading";

const SynonymsTable = ({ synonyms, synonymsLoading, activeAgency, ...props }) => {
  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const id = row.original.id;
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                props.selectSynonym(id);
                props.showModal(SYNONYM_MODAL);
              }}
            />
            {props.hasAdminPermission && (
              <IconLink
                Icon={FaTrash}
                iconConfig={{ className: "table--action-icon red" }}
                tooltip="Delete"
                onClick={() => {
                  props.selectSynonym(row.original.id);
                  props.showModal(DELETE_SYNONYM_MODAL, { id: row.original.id });
                }}
              />
            )}
          </>
        );
      },
    },
    {
      Header: "Synonyms",
      accessor: "name",
    },
    {
      Header: "Firm Matches",
      accessor: "hard_parameters_name",
    },
    {
      Header: "Soft Parameter Matches",
      accessor: "soft_parameters_name",
    },
  ];

  return (
    <Table
      columns={columns}
      data={synonyms}
      emptyMessage={synonymsLoading ? "Loading Synonyms data..." : "Select a Synonym"}
      showPaginationResults={true}
      defaultSort={true}
      paginated={false}
      initialPageSize={100}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeParameterId: activeParameterIdSelector(state),
    synonyms: synonymsSelector(state),
    activeAgency: activeAgencySelector(state),
    synonymsLoading: loadingSynonymsSelector(state),
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectSynonym,
  clearSelectedSynonym,
  fetchSynonymsByParameter,
})(SynonymsTable);
