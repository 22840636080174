import {
  FETCH_ACCOUNT_RECORDS,
  FETCH_CASE_RECORDS,
  FETCH_CASE_REQUIREMENT_RECORDS,
  FETCH_LEAD_RECORDS,
  FETCH_RECORDS,
  SET_RECORDS_PAGINATION_DATA,
  STORE_ACCOUNT_RECORDS_FILTERS_DATA,
  STORE_ACCOUNT_RECORDS_PAGINATION_DATA,
  STORE_CASE_RECORDS_FILTERS_DATA,
  STORE_CASE_RECORDS_PAGINATION_DATA,
  STORE_CASE_REQUIREMENT_RECORDS_FILTERS_DATA,
  STORE_CASE_REQUIREMENT_RECORDS_PAGINATION_DATA,
  STORE_LEAD_RECORDS_FILTERS_DATA,
  STORE_LEAD_RECORDS_PAGINATION_DATA,
  STORE_RECORDS_FILTERS_DATA,
} from "../../actions/types";

export const TaskTab = {
  ALL: 0,
  CASE: 4,
  CASE_REQUIREMENT: 1,
  ACCOUNT: 2,
  LEAD: 3,
};

export const ACTIVE_SECTION_TO_TASK_TAB_MAP = {
  TASKS: 0,
  CASE_TASKS: 4,
  CASE_REQUIREMENT_TASKS: 1,
  ACCOUNT_TASKS: 2,
  LEAD_TASKS: 3,
};

export const STORE_RECORDS_FILTER_BY_TYPE_MAP = {
  0: STORE_RECORDS_FILTERS_DATA,
  1: STORE_CASE_REQUIREMENT_RECORDS_FILTERS_DATA,
  2: STORE_ACCOUNT_RECORDS_FILTERS_DATA,
  3: STORE_LEAD_RECORDS_FILTERS_DATA,
  4: STORE_CASE_RECORDS_FILTERS_DATA,
};

export const FETCH_RECORDS_BY_TYPE_MAP = {
  0: FETCH_RECORDS,
  1: FETCH_CASE_REQUIREMENT_RECORDS,
  2: FETCH_ACCOUNT_RECORDS,
  3: FETCH_LEAD_RECORDS,
  4: FETCH_CASE_RECORDS,
};
