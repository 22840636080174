import React from "react";
import Table, { dateCell } from "../../components/table";
import IconLink from "../../components/IconLink";
import { FaEdit, FaTrash } from "react-icons/fa";
import { connect } from "react-redux";
import { activeFunderEntitySelector } from "../../reducers";
import { DELETE_SECURITY_INTERMEDIARY_MODAL, SECURITY_INTERMEDIARY_MODAL } from "../../components/modals";
import { selectSecurityIntermediary, showModal } from "actions";

const SecurityIntermediaryTable = ({ ...props }) => {
  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                props.selectSecurityIntermediary(row.original.id);
                props.showModal(SECURITY_INTERMEDIARY_MODAL);
              }}
            />
            <IconLink
              Icon={FaTrash}
              iconConfig={{ className: "table--action-icon btn--red" }}
              tooltip="Delete"
              onClick={() => {
                props.showModal(DELETE_SECURITY_INTERMEDIARY_MODAL, {
                  id: row.original.id,
                });
              }}
            />
          </>
        );
      },
    },
    {
      Header: "Securities Intermediary",
      accessor: "intermediary_name",
    },
    {
      Header: "SI Account Name",
      accessor: "si_account_name",
    },
    {
      Header: "SACA Date",
      accessor: "saca_date",
      Cell: dateCell,
    },
    {
      Header: "SI Account Number",
      accessor: "si_account_number",
    },
    {
      Header: "Policy Account Number",
      accessor: "policy_account_number",
    },
    {
      Header: "Securities Intermediary Email",
      accessor: "email",
    },
  ];

  return (
    <Table
      columns={columns}
      data={props.funderEntityInfo ? props.funderEntityInfo.securities_intermediaries : []}
      paginated={false}
      emptyMessage="No records found"
    />
  );
};

const mapStateToProps = state => {
  return {
    funderEntityInfo: activeFunderEntitySelector(state),
  };
};

export default connect(mapStateToProps, {
  selectSecurityIntermediary,
  showModal,
})(SecurityIntermediaryTable);
