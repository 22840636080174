import React from "react";
import { useFormikContext } from "formik";

import SpinLoader from "../SpinLoader";
import { getViewMode } from "../../utils";

const SubmitButton = ({ defaultText = "Save", submittingText = "Saving...", ...props }) => {
  const { isSubmitting } = useFormikContext();
  const isViewMode = getViewMode();
  if (isViewMode) {
    return;
  }

  if (props.innerRef) {
    props.ref = props.innerRef;
  }

  let text;
  if (isSubmitting) text = submittingText;
  else text = defaultText;

  return (
    <button className="btn btn--secondary" type="submit" disabled={isSubmitting} {...props}>
      {isSubmitting && <SpinLoader />}
      {text}
    </button>
  );
};

export default SubmitButton;
