import React from "react";
import { connect } from "react-redux";
import ProductForm from "./ProductForm";
import { Card } from "react-bootstrap";
import ProductTemplateForm from "./ProductTemplateForm";
import ProductRelatedCasesTable from "./ProductRelatedCasesTable";

const ProductDetailPage = ({ ...props }) => {
  return (
    <div className={`section-card shadowed case-section`} style={{ marginTop: "3.5rem" }}>
      <Card className={"case-info"}>
        <Card.Body>
          <ProductForm />
          <ProductTemplateForm />
          <ProductRelatedCasesTable />
        </Card.Body>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(ProductDetailPage);
