import React from "react";
import { connect, useDispatch } from "react-redux";

import { setActiveTab, initPagination, showModal, clearSelectedAccount, fetchEmailTemplates } from "actions";
import AccountsFilterForm from "./AccountsFilterForm";
import AccountsTable from "./AccountsTable";
import ButtonCreate from "../../components/buttons/ButtonCreate";
import { ButtonSendTo } from "../../components/buttons";
import { SET_EMAIL_TEMPLATE } from "../../actions/types";
import { BASIC_CREATE_ACCOUNT_MODAL, SEND_ACCOUNT_EMAIL_MODAL } from "../../components/modals";
import { selectedAccountsSelector } from "../../reducers";

const AccountsPage = props => {
  const dispatch = useDispatch();
  const [activeSection, setActiveSection] = React.useState("accounts");

  React.useEffect(() => {
    props.setActiveTab(activeSection);
    props.initPagination();
    props.fetchEmailTemplates();
    setActiveSection(activeSection);
    document.title = "LifeRoc | Accounts";
  }, []);

  return (
    <div>
      <AccountsFilterForm />
      <div style={{ marginBottom: "1rem" }}>
        <ButtonCreate
          onClick={() => {
            props.clearSelectedAccount();
            props.showModal(BASIC_CREATE_ACCOUNT_MODAL);
          }}
          key="create"
        >
          Add Account
        </ButtonCreate>
        <ButtonSendTo
          onClick={() => {
            dispatch({
              type: SET_EMAIL_TEMPLATE,
              payload: "Account Notification",
            });
            props.showModal(SEND_ACCOUNT_EMAIL_MODAL);
          }}
          key="create"
          style={{ marginLeft: "1rem" }}
          disabled={props.selectedAccounts.length === 0}
        >
          {props.selectedAccounts.length === 0
            ? "Select Accounts to Send"
            : `Send to Accounts (${props.selectedAccounts.length})`}
        </ButtonSendTo>
      </div>
      <AccountsTable />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    selectedAccounts: selectedAccountsSelector(state),
  };
};

export default connect(mapStateToProps, {
  setActiveTab,
  initPagination,
  clearSelectedAccount,
  showModal,
  fetchEmailTemplates,
})(AccountsPage);
