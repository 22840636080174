import React from "react";
import { connect } from "react-redux";

import { activeFunderSelector } from "reducers";

import { pricingSetup } from "../../actions";
import { Card } from "react-bootstrap";
import FunderForm from "./FunderForm";

const FunderOverview = ({ funderInfo, ...props }) => {
  React.useEffect(() => {
    if (funderInfo && funderInfo.name) {
      document.title = `LifeRoc | ${funderInfo.name} | Funder Overview`;
    }
  }, [funderInfo]);

  return (
    <div className={`section-card shadowed case-section`} style={{ marginTop: "5.5rem" }}>
      <Card className={"case-info"}>
        <Card.Body>
          <FunderForm funderInfo={funderInfo} />
        </Card.Body>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    funderInfo: activeFunderSelector(state),
  };
};

export default connect(mapStateToProps, { pricingSetup })(FunderOverview);
