import React from "react";
import { TextInput } from "components/forms";

const RequestRow = ({ label, onChange, ...props }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", minWidth: "300px", marginLeft: "10px" }}>
      <label style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <b>
          <div style={props.labelStyle}>{label}</div>
        </b>
        <TextInput as="textarea" rows={props.rows} name={props.name} customStyle={{ width: "900px" }} />
      </label>
    </div>
  );
};

export default RequestRow;
