import { connect } from "react-redux";
import { showModal } from "../actions";
import { Navigate } from "react-router-dom";
import { URLS } from "../constants";
import React from "react";

export const PublicRoute = ({ children, auth, ...props }) => {
  const { isSignedIn } = auth;

  if (isSignedIn) {
    return <Navigate to={URLS.TASKS} />;
  } else {
    return children;
  }
};

const mapStateToProps = state => {
  return { auth: state.auth };
};

export default connect(mapStateToProps, { showModal })(PublicRoute);
