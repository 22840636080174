import React, { forwardRef, useImperativeHandle, useState } from "react";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";

import { showModal } from "actions";
import FileLocal from "./FileLocal";

const DropZone = forwardRef((props, ref) => {
  const { setFieldValue } = props;
  const [myFiles, setMyFiles] = useState([]);
  const [fileNamesAdded, setFileNamesAdded] = useState([]);

  const addFileFromURL = async (url, filename) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], filename, { type: blob.type });

      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);

      const fileInput = document.querySelector('input[type="file"]');
      fileInput.files = dataTransfer.files;

      const event = new Event("drop", { bubbles: true });
      Object.defineProperty(event, "dataTransfer", { value: dataTransfer });

      fileInput.dispatchEvent(event);

      setMyFiles(prevFiles => {
        const allFiles = [...prevFiles, file];
        setFieldValue("files", allFiles);
        return allFiles;
      });
    } catch (error) {
      console.error("Error fetching file from URL:", error);
    }
  };

  React.useEffect(() => {
    const loadInitialFiles = async () => {
      if (props.initialFiles) {
        for (let f of props.initialFiles) {
          if (f?.name && !fileNamesAdded.includes(f.name)) {
            setFileNamesAdded(prevState => [...prevState, f.name]);
            await addFileFromURL(f.url, f.name);
          }
        }
      }
    };

    loadInitialFiles();
  }, [fileNamesAdded]);

  const onDrop = React.useCallback(
    acceptedFiles => {
      const allFiles = [...myFiles, ...acceptedFiles];
      setMyFiles(allFiles);
      setFieldValue("files", allFiles);
    },
    [myFiles],
  );

  const { isDragActive, getRootProps, getInputProps } = useDropzone({ onDrop });

  const getClassNames = () => {
    let className = "dropzone";
    if (isDragActive) {
      className += " active";
    }
    return className;
  };

  const removeFile = file => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const removeAll = () => {
    setMyFiles([]);
    setFileNamesAdded([]);
  };

  useImperativeHandle(ref, () => ({
    removeAll: removeAll,
  }));

  const files = myFiles.map(file => <FileLocal file={file} deleteFile={removeFile} />);

  return (
    <section className="container">
      <div {...getRootProps({ className: getClassNames() })}>
        <input {...getInputProps()} />
        <div>Drag and drop file or click to select files</div>
      </div>
      <div style={{ display: "flex", flexFlow: "wrap" }}>{files}</div>
      {files.length > 0 && (
        <button className={"btn"} onClick={removeAll}>
          Remove All
        </button>
      )}
    </section>
  );
});

export default connect(null, { showModal }, null, { forwardRef: true })(DropZone);
