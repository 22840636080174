import React from "react";
import { connect } from "react-redux";

import { createEmptyCase, setActiveTab, initPagination } from "actions";
import LifeExpectancyFilterForm from "./LifeExpectancyFilterForm";
import LifeExpectancyTable from "./LifeExpectancyTable";
import RiskxPageHeader from "../RiskxPageHeader";

const LifeExpectancyPage = props => {
  const [activeSection, setActiveSection] = React.useState("lifeexpectancy");
  React.useEffect(() => {
    props.setActiveTab(activeSection);
    props.initPagination();
    setActiveSection(activeSection);
    document.title = "LifeRoc | LE Search Engine";
  }, []);

  return (
    <>
      <RiskxPageHeader />
      <div className={`section-card shadowed case-section`} style={{ marginTop: "1.5rem" }}>
        <LifeExpectancyFilterForm />
        <LifeExpectancyTable />
      </div>
    </>
  );
};

export default connect(null, {
  createEmptyCase,
  setActiveTab,
  initPagination,
})(LifeExpectancyPage);
