import React from "react";
import { setLocale } from "yup";
import { Form } from "react-bootstrap";

// Set default error messages for Yup validation
setLocale({
  mixed: {
    required: "Required",
  },
});

Form.SectionTitle = ({ children }) => <div className="form-section-title">{children}</div>;

export { default as BooleanSelect } from "./BooleanSelect";
export { default as ActiveInactiveBooleanSelect } from "./ActiveInactiveBooleanSelect";
export { default as EditorField } from "./EditorField";
export { default as ClearFiltersButton } from "./ClearFiltersButton";
export { default as CurrencyInput } from "./CurrencyInput";
export { default as DateInput } from "./DateInput";
export { default as DateTimeInput } from "./DateTimeInput";
export { default as LPEXFADateTimeInput } from "./LPEXFADateTimeInput";
export { default as FormCollapsible } from "./FormCollapsible";
export { default as Collapsible } from "./Collapsible";
export { default as FormFilter } from "./FormFilter";
export { default as FormTitle } from "./FormTitle";
export { default as GenderSelect } from "./GenderSelect";
export { default as PercentageInput } from "./PercentageInput";
export { default as PercentageIntegerInput } from "./PercentageIntegerInput";
export { default as PhoneInput } from "./PhoneInput";
export { default as SSNInput } from "./SSNInput";
export { default as SSNLast4DigitsInput } from "./SSNLast4DigitsInput";
export { default as Select } from "./selects/Select";
export { default as SelectCreatable } from "./selects/SelectCreatable";
export { default as StateSelect } from "./StateSelect";
export { default as SubmitButton } from "./SubmitButton";
export { default as SubmitSearchButton } from "./SubmitSearchButton";
export { default as TIN_Input } from "./TIN_Input";
export { default as TextInput } from "./TextInput";
export { default as HyperLinkText } from "./HyperLinkText";
export { default as HyperLinkTextToLEModal } from "./HyperLinkTextToLEModal";
export { default as PricingFolderLink } from "./PricingFolderLink";
export { default as EscrowAgentSelect } from "./EscrowAgentSelect";
export { default as FormRowTitle } from "./FormRowTitle";
export { default as SelectCheckboxes } from "./SelectCheckboxes";
export { default as Checkbox } from "../../pages/cases/caseFiles/policy/RequestRow";
export { default as RowSeparator } from "./RowSeparator";
export { default as YearInput } from "./YearInput";
export { default as SendEmailForm } from "./SendEmailForm";
