import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { FormCollapsible, FormFilter, SubmitSearchButton, TextInput } from "components/forms";
import {
  createMortalityAssessment,
  editMortalityAssessment,
  fetchPolicy,
  findCasesForMortalityAssessment,
  hideModal,
} from "actions";
import { Form } from "react-bootstrap";
import Table from "components/table";
import { MORTALITY_ASSESSMENT_CREATION_MODAL } from "components/modals";

const MortalityAssessmentCreationForm = ({ ...props }) => {
  const [cases, setCases] = React.useState([]);

  const CasesForMortalityAssessmentTable = () => {
    if (!cases) {
      return null;
    }

    const columns = [
      {
        Header: "Select",
        Cell: ({ row }) => {
          return (
            <button
              className={`btn btn--secondary`}
              onClick={e => {
                e.preventDefault();
                console.log("click", row.original);
                props.hideModal(MORTALITY_ASSESSMENT_CREATION_MODAL);
                props.createMortalityAssessment(row.original);
              }}
            >
              Select
            </button>
          );
        },
      },
      {
        Header: "Case ID",
        accessor: "id",
        className: "wrap-text",
      },
      {
        Header: "First Name",
        accessor: "first_name",
        className: "wrap-text",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        className: "wrap-text",
      },
      {
        Header: "Policy Number",
        accessor: "policy_number",
        className: "wrap-text",
      },
    ];

    return (
      <Table
        columns={columns}
        data={cases}
        defaultSort={true}
        showPaginationResults={false}
        defaultPagination={true}
        emptyMessage={"No results"}
      />
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        document.activeElement.blur(); // Remove focus from the currently focused element
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        const handleClick = async () => {
          let data = await props.findCasesForMortalityAssessment(values);
          setCases(data.results);
        };
        return (
          <>
            <FormCollapsible title={"New Mortality Assessment"}>
              <FormFilter title={"Case Search"}>
                <Form.Row>
                  <TextInput label="Case ID" name="id" />
                  <TextInput label="Last Name" name="last_name" />
                  <TextInput label="First Name" name="first_name" />
                  <TextInput label="Policy Number" name="policy_number" />
                </Form.Row>
                <Form.Row>
                  <button className="btn btn--secondary" type="button" onClick={handleClick}>
                    Search
                  </button>
                </Form.Row>
              </FormFilter>
              <CasesForMortalityAssessmentTable />
            </FormCollapsible>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  createMortalityAssessment,
  editMortalityAssessment,
  fetchPolicy,
  findCasesForMortalityAssessment,
  hideModal,
})(MortalityAssessmentCreationForm);
