import {
  CREATE_EMPTY_FUNDER,
  DELETE_FUNDER,
  FETCH_FUNDER,
  FETCH_FUNDER_CASES,
  FETCH_FUNDER_CASE_FITS,
  FETCH_FUNDERS,
  SELECT_FUNDER,
  FETCH_FUNDER_CONTACTS,
  FETCH_FUNDER_ENTITIES,
  SELECT_FUNDER_CONTACT,
  SELECT_FUNDER_ENTITY,
  DELETE_FUNDER_CONTACT,
  DELETE_FUNDER_ENTITY,
  SELECT_SECURITY_INTERMEDIARY,
  FETCH_CLOSING_FUNDER_ENTITIES,
  FETCH_TRADING_FUNDER_ENTITIES,
  EDIT_FUNDER,
  SELECT_TRADING_DOCUMENT,
  UPDATE_FUNDER_FILTERS,
  FETCH_FUNDER_ACTIVITY_REPORT,
  FETCH_FUNDER_ACTIVITY_REPORT_TOTALS,
  CLEAR_SELECTED_FUNDER,
} from "actions/types";
import { arrayToObjectIndexedById } from "../utils";
import { removeKey } from "../../utils";

export const defaultState = {
  funders: {},
  fundersActivityReport: {},
  fundersActivityReportTotals: {
    pending_count: 0,
    pending_face: 0,
    pending_revenue: 0,
    bidding_count: 0,
    bidding_face: 0,
    bidding_revenue: 0,
    closing_count: 0,
    closing_face: 0,
    closing_revenue: 0,
    completed_count: 0,
    completed_face: 0,
    completed_revenue: 0,
  },
  activeFunderId: null,
  activeFunderCaseFits: [],
  activeFunderCases: [],
  activeFunderContacts: [],
  activeFunderContactId: null,
  activeFunderEntities: [],
  activeClosingFunderEntities: [],
  activeTradingFunderEntities: [],
  activeFunderEntityId: null,
  activeSecurityIntermediaryId: null,
  activeTradingDocumentId: null,
  filters: {
    buying_status: "Active",
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_FUNDER:
      return { ...state, activeFunderId: action.payload };

    case CLEAR_SELECTED_FUNDER:
      return { ...state, activeFunderId: defaultState.activeFunderId };

    case SELECT_FUNDER_CONTACT:
      return { ...state, activeFunderContactId: action.payload };

    case SELECT_FUNDER_ENTITY:
      return { ...state, activeFunderEntityId: action.payload };

    case SELECT_SECURITY_INTERMEDIARY:
      return { ...state, activeSecurityIntermediaryId: action.payload };

    case SELECT_TRADING_DOCUMENT:
      return { ...state, activeTradingDocumentId: action.payload };

    case CREATE_EMPTY_FUNDER:
    case FETCH_FUNDER:
    case EDIT_FUNDER: {
      return {
        ...state,
        funders: {
          ...state.funders,
          [action.payload.id]: action.payload,
        },
        activeFunderId: action.payload.id,
      };
    }

    case UPDATE_FUNDER_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    case FETCH_FUNDERS:
      return { ...state, funders: arrayToObjectIndexedById(action.payload) };

    case FETCH_FUNDER_ACTIVITY_REPORT_TOTALS:
      return {
        ...state,
        fundersActivityReportTotals: action.payload,
      };

    case FETCH_FUNDER_ACTIVITY_REPORT:
      return {
        ...state,
        fundersActivityReport: arrayToObjectIndexedById(action.payload),
      };

    case FETCH_FUNDER_CASE_FITS: {
      return { ...state, activeFunderCaseFits: action.payload };
    }

    case FETCH_FUNDER_CASES: {
      return { ...state, activeFunderCases: action.payload };
    }

    case FETCH_FUNDER_CONTACTS: {
      return { ...state, activeFunderContacts: action.payload };
    }

    case FETCH_FUNDER_ENTITIES: {
      return { ...state, activeFunderEntities: action.payload };
    }

    case FETCH_CLOSING_FUNDER_ENTITIES: {
      return { ...state, activeClosingFunderEntities: action.payload };
    }

    case FETCH_TRADING_FUNDER_ENTITIES: {
      return { ...state, activeTradingFunderEntities: action.payload };
    }

    case DELETE_FUNDER: {
      return {
        ...state,
        funders: removeKey(state.funders, action.payload),
      };
    }

    case DELETE_FUNDER_CONTACT: {
      let activeFunderContacts = state.activeFunderContacts;
      for (const index in activeFunderContacts) {
        let funder = activeFunderContacts[index];
        if (funder.id === state.activeFunderContactId) {
          activeFunderContacts.splice(index, 1);
          break;
        }
      }
      return {
        ...state,
        activeFunderContacts: activeFunderContacts,
      };
    }

    case DELETE_FUNDER_ENTITY: {
      let activeFunderEntities = state.activeFunderEntities;
      for (const index in activeFunderEntities) {
        let funder = activeFunderEntities[index];
        if (funder.id === state.activeFunderEntityId) {
          activeFunderEntities.splice(index, 1);
          break;
        }
      }
      return {
        ...state,
        activeFunderEntities: activeFunderEntities,
      };
    }

    default:
      return state;
  }
};

export const fundersSelector = state => Object.values(state.funders);

export const fundersActivityReportSelector = state => Object.values(state.fundersActivityReport);

export const fundersActivityReportTotalsSelector = state => state.fundersActivityReportTotals;

export const activeFunderCasesSelector = state => Object.values(state.activeFunderCases);

export const activeFunderCaseFitsSelector = state => Object.values(state.activeFunderCaseFits);

export const activeFunderContactsSelector = state => Object.values(state.activeFunderContacts);

export const activeFunderEntitiesSelector = state => Object.values(state.activeFunderEntities);

export const activeClosingFunderEntitiesSelector = state => Object.values(state.activeClosingFunderEntities);

export const activeTradingFunderEntitiesSelector = state => Object.values(state.activeTradingFunderEntities);

export const activeFunderSelector = ({ funders, activeFunderId }) => {
  if (activeFunderId) {
    return funders[activeFunderId];
  }
};

export const activeFunderContactIdSelector = state => state.activeFunderContactId;

export const activeFunderEntityIdSelector = state => state.activeFunderEntityId;

export const activeFunderEntitySelector = state => {
  let funderEntityInfo;
  const activeFunderEntities = activeFunderEntitiesSelector(state);
  for (const funderEntityInfoElement of activeFunderEntities) {
    if (funderEntityInfoElement.id === state.activeFunderEntityId) {
      funderEntityInfo = funderEntityInfoElement;
    }
  }
  return funderEntityInfo;
};

export const activeSecurityIntermediaryIdSelector = state => state.activeSecurityIntermediaryId;

export const activeTradingDocumentIdSelector = state => state.activeTradingDocumentId;

export const funderFiltersSelector = state => state.filters;
