import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { Select, FormCollapsible, SubmitButton } from "components/forms";
import { createRelatedParameter, fetchParametersChoices } from "actions";
import { activeParameterIdSelector, userChoicesSelector } from "reducers";
import {
  PARAMETER_CATEGORY_CHOICES,
  PARAMETER_SUB_CATEGORY_CHOICES,
  PARAMETER_SUB_CATEGORY_CHOICES__CONDITIONS,
  PARAMETER_SUB_CATEGORY_CHOICES__PROFILES,
  PARAMETER_SUB_CATEGORY_CHOICES__TREATMENTS,
} from "../constants";

const RelatedParameterForm = ({ activeParameterId, createRelatedParameter, fetchParametersChoices, ...props }) => {
  const [parametersChoices, setParameterChoices] = React.useState([]);
  const [parameterSubChoices, setParameterSubChoices] = React.useState([]);
  const [filteredParametersChoices, setFilteredParameterChoices] = React.useState([]);

  if (parametersChoices === []) {
    return null;
  }

  const filteredParameterChoices = (category, subCategory) =>
    parametersChoices.filter(p => {
      return p.category.toLowerCase() === category.toLowerCase() && p.sub_category === subCategory;
    });

  React.useEffect(() => {
    fetchParametersChoices(setParameterChoices);
  }, []);

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{}}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        createRelatedParameter(activeParameterId, values.name);
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit}>
            <Form.Row>
              <Select
                label="Health Parameter Category"
                name="category"
                placeholder="Select Category"
                options={PARAMETER_CATEGORY_CHOICES}
                onChangeCallback={option => {
                  if (option?.value === "CONDITION") {
                    setParameterSubChoices(PARAMETER_SUB_CATEGORY_CHOICES__CONDITIONS);
                  } else if (option?.value === "PROFILE") {
                    setParameterSubChoices(PARAMETER_SUB_CATEGORY_CHOICES__PROFILES);
                  } else if (option?.value === "TREATMENT") {
                    setParameterSubChoices(PARAMETER_SUB_CATEGORY_CHOICES__TREATMENTS);
                  } else {
                    setParameterSubChoices(PARAMETER_SUB_CATEGORY_CHOICES);
                  }
                }}
              />
              <Select
                label="Health Parameter Sub-Category"
                name="sub_category"
                placeholder="Select Sub-Category"
                options={parameterSubChoices}
                onChangeCallback={option => {
                  if (option?.value) {
                    setFilteredParameterChoices(filteredParameterChoices(values.category, option.value));
                  } else {
                    setFilteredParameterChoices([]);
                  }
                }}
              />
              <Select
                label="Health Parameter Name"
                name="name"
                placeholder="Select Parameter Name"
                options={filteredParametersChoices}
              />
            </Form.Row>
            <SubmitButton />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    activeParameterId: activeParameterIdSelector(state),
    userChoices: userChoicesSelector(state),
    userId: state.auth.user_id,
  };
};

export default connect(mapStateToProps, {
  fetchParametersChoices,
  createRelatedParameter,
})(RelatedParameterForm);
