import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { ACCOUNT_AFFILIATION_MODAL } from "components/modals";
import AccountAffiliationForm from "./AccountAffiliationForm";

const AccountAffiliationModal = () => {
  return (
    <FullPageModal
      modalType={ACCOUNT_AFFILIATION_MODAL}
      title="Affiliation Detail"
      style={{ content: { overflow: "visible" } }}
    >
      <AccountAffiliationForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(AccountAffiliationModal);
