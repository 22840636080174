import React from "react";
import { connect } from "react-redux";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { selectAuctionRound, fetchAuctionRounds, showModal } from "actions";
import { activeAuctionRoundSelector, auctionRoundsSelector, auctionIdSelector, activeCaseSelector } from "reducers";
import { AUCTION_ROUND_MODAL, DELETE_AUCTION_ROUND_MODAL } from "components/modals";
import Table, { choiceCell, percentageCell, currencyCell, datetimeCell } from "components/table";
import { getValue } from "../../../../utils";
import { spreadTypeChoices } from "../../../../constants";

const RoundsTable = ({ fetchAuctionRounds, auctionRounds, auctionId, ...props }) => {
  React.useEffect(() => {
    if (auctionId !== undefined) {
      // Wait for the auction to be fetched (we need the id) before fetching the
      // rounds
      fetchAuctionRounds();
    }
  }, [fetchAuctionRounds, auctionId]);
  const columns = [
    {
      Header: "Action",
      className: "action",
      Cell: ({ row }) => {
        const roundId = row.original.id;

        return (
          <DropdownButton title="Select" size="sm" variant="success">
            <Dropdown.Item
              onClick={() => {
                props.selectAuctionRound(roundId);
                props.showModal(AUCTION_ROUND_MODAL);
              }}
            >
              View/Edit
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                props.showModal(DELETE_AUCTION_ROUND_MODAL, {
                  id: roundId,
                });
              }}
            >
              Delete
            </Dropdown.Item>
          </DropdownButton>
        );
      },
    },
    {
      Header: "Round",
      accessor: "number",
    },
    {
      Header: "Round Start Date",
      accessor: "bid_start_date",
      Cell: datetimeCell,
    },
    {
      Header: "Round End Date",
      accessor: "bid_due_date",
      Cell: datetimeCell,
    },
    {
      Header: "Min increase",
      accessor: "min_increase",
    },
    {
      Header: "Spread Type",
      accessor: "spread_type",
      Cell: choiceCell(spreadTypeChoices),
    },
    {
      Header: "Spread Rate",
      accessor: "spread_rate",
      Cell: ({ row, value }) => {
        const spreadType = row.original.spread_type;
        if (!spreadType) {
          return "";
        }
        if (spreadType === getValue(spreadTypeChoices, "Percentage")) {
          return percentageCell({ value: value * 100 });
        }
        return currencyCell({ value });
      },
    },
    {
      Header: "Notes",
      accessor: "notes",
      Cell: ({ row }) => {
        return (
          <div className="text-wrap">
            {row.original.notes
              .replace(/(<([^>]+)>)/gi, " ")
              .replace("&amp;nbsp;", " ")
              .replace("&nbsp;", " ")}{" "}
          </div>
        );
      },
    },
  ];

  // remove "Spread Type" and "Spread Rate" columns if Bidding Format is not LPEX
  if (props.caseInfo.bidding_format !== "LPEX") {
    delete columns[6];
    delete columns[5];
  }

  return (
    <Table
      columns={columns}
      data={auctionRounds}
      defaultSort={true}
      paginated={false}
      emptyMessage="No records found"
      rowIsActive={row => props.activeAuctionRound !== null && row.original.id === props.activeAuctionRound.id}
    />
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
    auctionId: auctionIdSelector(state),
    activeAuctionRound: activeAuctionRoundSelector(state),
    auctionRounds: auctionRoundsSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchAuctionRounds,
  selectAuctionRound,
  showModal,
})(RoundsTable);
