import React from "react";
import { connect } from "react-redux";
import Table from "components/table";
import {
  activeAccountSelector,
  activeCaseIdSelector,
  activeCaseSelector,
  loadingAccountHierarchiesSelector,
} from "reducers";
import { editCaseHierarchy, selectAccountHierarchy, showModal } from "actions";

const CaseAccountHierarchyTable = ({ ...props }) => {
  const columns = [
    {
      Header: "Sales Representative",
      accessor: "sales_representative_name",
      className: "wrap-text",
    },
    {
      Header: "Sales Manager",
      accessor: "sales_manager_name",
      className: "wrap-text",
    },
    {
      Header: "National Account Manager",
      accessor: "national_account_manager_name",
      className: "wrap-text",
    },
    {
      Header: "National Account",
      accessor: "national_account_name",
      className: "wrap-text",
    },
    {
      Header: "Active",
      accessor: "active",
      Cell: ({ row }) => {
        return <div className="text-wrap">{row.original.active ? "Yes" : "No"} </div>;
      },
    },
    {
      Header: "Select Hierarchy",
      Cell: ({ row }) => {
        let checked = false;
        if (props.caseInfo.hierarchy) {
          checked = row.original.id === props.caseInfo.hierarchy.id;
        }
        return (
          <input
            type="checkbox"
            checked={checked}
            onClick={e => {
              props.editCaseHierarchy(props.activeCaseId, {
                hierarchy: row.original.id,
              });
            }}
          />
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      data={props.hierarchies}
      showPaginationResults={false}
      defaultSort={true}
      defaultPagination={true}
      emptyMessage={props.hierarchiesLoading ? "Loading Hierarchies..." : "No data"}
    />
  );
};

const mapStateToProps = state => {
  const activeAccount = activeAccountSelector(state);
  let hierarchies = [];
  if (activeAccount && activeAccount.hierarchies) {
    hierarchies = activeAccount.hierarchies;
  }

  return {
    activeCaseId: activeCaseIdSelector(state),
    caseInfo: activeCaseSelector(state),
    activeAccount,
    hierarchies,
    hierarchiesLoading: loadingAccountHierarchiesSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectAccountHierarchy,
  editCaseHierarchy,
})(CaseAccountHierarchyTable);
