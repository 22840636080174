import React from "react";
import { MdSend } from "react-icons/md";

export default ({ onClick, children, className, ...props }) => {
  return (
    <button className={`btn btn--secondary ${className}`} onClick={onClick} {...props}>
      <MdSend />
      {children}
    </button>
  );
};
