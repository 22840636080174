import advisorhq from "apis/advisorhq";
import { FETCH_MARKET_ACTIVITY_FILES, LOADING } from "actions/types";

export const generateMarketActivityFiles = (activeMarketActivity, type) => async dispatch => {
  dispatch({ type: LOADING, payload: { marketActivityFiles: true } });
  const data = await advisorhq.get(`/auctions/api/market_activity_files/${activeMarketActivity.id}`, {
    params: { type },
  });
  dispatch({ type: FETCH_MARKET_ACTIVITY_FILES, payload: data.data });
  dispatch({ type: LOADING, payload: { marketActivityFiles: false } });
};

export const updateMarketActivityFiles = (activeMarketActivity, data) => async dispatch => {
  dispatch({ type: FETCH_MARKET_ACTIVITY_FILES, payload: data });
};
