import { SHOW_MODAL, HIDE_MODAL } from "actions/types";
import { removeKey } from "utils.js";

export default (state = {}, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return { ...state, [action.payload.modalType]: action.payload };
    case HIDE_MODAL:
      return removeKey(state, action.payload);
    default:
      return state;
  }
};

export const activeModalsSelector = state => Object.values(state);
