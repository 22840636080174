import { FETCH_POLICY, EDIT_POLICY, LOADING } from "actions/types";
import advisorhq from "apis/advisorhq";
import * as notifications from "notifications.js";

export const fetchPolicy = policyId => async dispatch => {
  const endpoint = `/policies/api/policies/${policyId}/`;
  const response = await advisorhq.get(endpoint);
  dispatch({ type: FETCH_POLICY, payload: response.data });
  dispatch({ type: LOADING, payload: { policy: false } });
};

export const editPolicy = (policyId, newValues) => async (dispatch, getState) => {
  const endpoint = `/policies/api/policies/${policyId}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  await dispatch({ type: EDIT_POLICY, payload: response.data });
  notifications.success("Policy edited successfully");
};
