import React from "react";
import { connect } from "react-redux";

import { activeAuctionRoundSelector } from "reducers";
import FullPageModal from "components/modals/FullPageModal";
import { AUCTION_ROUND_MODAL } from "components/modals";
import AuctionRoundForm from "./RoundForm";

const RoundModal = ({ activeAuctionRound }) => {
  return (
    <FullPageModal modalType={AUCTION_ROUND_MODAL} title="Round Detail" style={{ content: { overflow: "visible" } }}>
      <AuctionRoundForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    activeAuctionRound: activeAuctionRoundSelector(state),
  };
};

export default connect(mapStateToProps)(RoundModal);
