import React from "react";
import { connect } from "react-redux";
import { Col, Form } from "react-bootstrap";

import {
  activeCaseSelector,
  managementChoicesSelector,
  agentsChoicesSelector,
  insuredListSelector,
  lifeExpectanciesSelector,
} from "reducers";
import { pricingSetup, showModal } from "../../../actions";
import { Collapsible, HyperLinkText } from "../../../components/forms";

const RelatedCasesAndDriveLinksForm = ({
  caseInfo,
  insuredInfo,
  lifeExpectancyInfo,
  isActive,
  case_title,
  pricingSetup,
  auctionNotesFormSubmitRef,
  caseOverviewFormSubmitRef,
  caseTradingSummaryFormSubmitRef,
  ...props
}) => {
  if (!caseInfo) {
    return null;
  }

  function renderRelatedCases() {
    if (caseInfo.related_cases && caseInfo.related_cases.length > 0) {
      const groupedCases = [];
      for (let i = 0; i < caseInfo.related_cases.length; i += 2) {
        groupedCases.push(
          <Form.Row key={`row-${i}`} style={{ padding: 0 }}>
            {caseInfo.related_cases.slice(i, i + 2).map((obj, index, array) => (
              <Form.Group as={Col} key={`group-${i}-${index}`} className={`col-md-6`}>
                <HyperLinkText value={obj[0]} url={`/cases/${obj[1]}`} name={obj[1]} copy />
              </Form.Group>
            ))}
          </Form.Row>,
        );
      }
      return (
        <Collapsible
          title={`Related Cases (${caseInfo.related_cases.length})`}
          initiallyHidden={true}
          style={{ marginBottom: 0 }}
        >
          {groupedCases}
        </Collapsible>
      );
    } else {
      return null;
    }
  }

  function renderFirstRow() {
    return (
      <Form.Row>
        <HyperLinkText
          label={"Case Folder"}
          value={caseInfo.drive_folder_url || "---"}
          url={caseInfo.drive_folder_url}
          name={"drive_folder_url"}
          inline={true}
          copy
        />
        <HyperLinkText
          label={"Pricing Folder"}
          value={caseInfo.drive_pricing_folder_url || "---"}
          url={caseInfo.drive_pricing_folder_url}
          name={"drive_pricing_folder_url"}
          inline={true}
          copy
        />
      </Form.Row>
    );
  }

  function renderSecondRow() {
    return (
      <Form.Row>
        <HyperLinkText
          label={"Funder DD Review Folder"}
          value={caseInfo.drive_dd_review_folder_url || "---"}
          url={caseInfo.drive_dd_review_folder_url}
          name={"drive_dd_review_folder_url"}
          inline={true}
          copy
        />
        <HyperLinkText
          label={"Funder Initial Review Folder"}
          value={caseInfo.drive_initial_review_folder_url || "---"}
          url={caseInfo.drive_initial_review_folder_url}
          name={"drive_initial_review_folder_url"}
          inline={true}
          copy
        />
      </Form.Row>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      {renderFirstRow()}
      {renderSecondRow()}
      {renderRelatedCases()}
    </div>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const agentsChoices = agentsChoicesSelector(state);

  return {
    caseInfo: activeCaseSelector(state),
    insuredInfo: insuredListSelector(state),
    lifeExpectancyInfo: lifeExpectanciesSelector(state),
    processTypeChoices: choices.case_process_types,
    providerChoices: choices.case_providers,
    agencyChoices: choices.agencies,
    salesRepChoices: choices.case_sales_representatives,
    caseManagersChoices: agentsChoices.case_managers,
    accountChoices: choices.accounts,
  };
};

export default connect(mapStateToProps, {
  pricingSetup,
  showModal,
})(RelatedCasesAndDriveLinksForm);
