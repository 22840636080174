import Table from "components/table";
import React from "react";
import { activeMortalityAssessmentSelector } from "reducers";
import { connect } from "react-redux";
import { FaCopy, FaEdit, FaEye, FaTrash } from "react-icons/fa";
import IconLink from "../../../components/IconLink";
import { LOADING } from "../../../actions/types";
import { COPY_CASE_MODAL, DELETE_CASE_MODAL } from "../../../components/modals";
import { formatBytes } from "../../../utils";

const FileInputsTable = ({ mortalityAssessmentInfo, ...props }) => {
  if (!mortalityAssessmentInfo) {
    // Still didn't fetch data
    return null;
  }

  let totalCount = 0;
  let addedPages = 0;
  let addedFileSize = 0;
  if (mortalityAssessmentInfo.file_inputs) {
    totalCount = mortalityAssessmentInfo.file_inputs.length;
    addedPages = mortalityAssessmentInfo.file_inputs.reduce((acc, fileInput) => {
      return acc + fileInput.pages;
    }, 0);
    const addedFileSizeBytes = mortalityAssessmentInfo.file_inputs.reduce((acc, fileInput) => {
      return acc + fileInput.file_size_bytes;
    }, 0);
    addedFileSize = formatBytes(addedFileSizeBytes, 0);
  }
  let addedFileCount = `TOTAL: ${totalCount}`;

  const columns = [
    {
      Header: "Description",
      Footer: addedFileCount,
      accessor: "description",
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <>
            {row.original.file ? (
              <IconLink
                Icon={FaEye}
                iconConfig={{ className: "table--action-icon" }}
                tooltip="Preview"
                onClick={() => window.open(row.original.file, "_blank")}
              />
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Pages",
      Footer: addedPages,
      accessor: "pages",
    },
    {
      Header: "File Size",
      Footer: addedFileSize,
      accessor: "file_size",
      Cell: ({ row }) => {
        return <>{formatBytes(row.original.file_size_bytes)}</>;
      },
    },
    {
      Header: "Document Date",
      accessor: "document_date",
    },
  ];

  return (
    <Table
      columns={columns}
      data={mortalityAssessmentInfo.file_inputs || []}
      defaultSort={true}
      paginated={false}
      showFooter={true}
      emptyMessage="No File Inputs found"
    />
  );
};

const mapStateToProps = state => {
  return {
    mortalityAssessmentInfo: activeMortalityAssessmentSelector(state),
  };
};

export default connect(mapStateToProps, {})(FileInputsTable);
