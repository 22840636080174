import {
  CREATE_BENEFICIARY,
  DELETE_BENEFICIARY,
  EDIT_BENEFICIARY,
  FETCH_BENEFICIARIES,
  LOADING,
} from "actions/types";
import advisorhq from "apis/advisorhq";
import * as types from "../types";
import * as notifications from "../../notifications";

export const OWNER_ENTITY = "owner_entity";
export const OWNER_PERSON = "owner_person";
export const ENTITY = "entity";
export const PERSON = "person";

export const BENEFICIARY_TYPES = [OWNER_ENTITY, OWNER_PERSON, ENTITY, PERSON];

export const BENEFICIARIES_URLS = {
  [OWNER_ENTITY]: "/life_settlement/api/owner_entity_beneficiaries/",
  [OWNER_PERSON]: "/life_settlement/api/owner_person_beneficiaries/",
  [ENTITY]: "/life_settlement/api/entity_beneficiaries/",
  [PERSON]: "/life_settlement/api/person_beneficiaries/",
};

export const fetchBeneficiaries = caseId => async dispatch => {
  // Helper func to add the beneficiary type to the response data
  const _fetchBeneficiaries = async (beneficiaryType, caseId) => {
    const endpoint = BENEFICIARIES_URLS[beneficiaryType];
    const response = await advisorhq.get(endpoint, { params: { case: caseId } });
    return { [beneficiaryType]: response.data };
  };

  const beneficiaries = await Promise.all(
    BENEFICIARY_TYPES.map(beneficiaryType =>
      _fetchBeneficiaries(beneficiaryType, caseId),
    ),
  );

  dispatch({ type: FETCH_BENEFICIARIES, payload: beneficiaries });
  dispatch({ type: LOADING, payload: { beneficiaries: false } });
};

export const editBeneficiary = (beneficiaryType, id, values) => async dispatch => {
  const endpoint = BENEFICIARIES_URLS[beneficiaryType] + id + "/";
  const response = await advisorhq.patch(endpoint, values);
  dispatch({ type: EDIT_BENEFICIARY, payload: { ...response.data, beneficiaryType } });
};

export const createBeneficiary = (beneficiaryType, values) => async dispatch => {
  const endpoint = BENEFICIARIES_URLS[beneficiaryType];
  const response = await advisorhq.post(endpoint, values);
  dispatch({
    type: CREATE_BENEFICIARY,
    payload: { ...response.data, beneficiaryType },
  });
};

export const deleteBeneficiary = (beneficiaryType, id) => async dispatch => {
  const endpoint = BENEFICIARIES_URLS[beneficiaryType] + id + "/";
  await advisorhq.delete(endpoint);
  dispatch({ type: DELETE_BENEFICIARY, payload: { id, beneficiaryType } });
};

export const createBeneficiarySigner = beneficiaryId => async dispatch => {
  const signerInfo = { entity_id: beneficiaryId };
  const endpoint = `/life_settlement/api/beneficiary_signers/`;
  const response = await advisorhq.post(endpoint, signerInfo);
  dispatch({ type: types.CREATE_BENEFICIARY_SIGNER, payload: response.data });
  notifications.success("A new Signer record was created");
};

export const deleteBeneficiarySigner = (id, entity_id) => async dispatch => {
  const endpoint = `/life_settlement/api/beneficiary_signers/${id}/`;
  await advisorhq.delete(endpoint);
  dispatch({ type: types.DELETE_BENEFICIARY_SIGNER, payload: { id, entity_id } });
  notifications.success("Signer deleted successfully");
};
