import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { CARRIER_FILE_MODAL } from "components/modals";
import CarrierFileForm from "./CarrierFileForm";
import SingleFileDropZone from "../../components/SingleFileDropZone";
import { fetchCarriers, uploadCarrierFile } from "../../actions";
import { activeCarrierFileSelector } from "../../reducers";
import { isAdmin } from "permissions";

const CarrierFileModal = ({ activeCarrierFile, hasAdminPermission, ...props }) => {
  let carrierFileIsSaved = activeCarrierFile && activeCarrierFile.id;
  const submitRef = React.useRef();

  const WideSaveButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="btn btn--secondary"
          onClick={() => {
            submitRef.current.click();
          }}
          style={{
            width: "99%",
            marginBottom: "1rem",
          }}
        >
          Save Changes
        </button>
      </div>
    );
  };

  return (
    <FullPageModal modalType={CARRIER_FILE_MODAL}>
      <CarrierFileForm submitRef={submitRef} />
      {hasAdminPermission && <WideSaveButton />}
      {carrierFileIsSaved && hasAdminPermission ? (
        <div>
          <SingleFileDropZone
            currentFile={activeCarrierFile.name ? activeCarrierFile : null}
            uploadFile={file => {
              console.log("uploadFile", file);
              props.uploadCarrierFile(file);
            }}
            fileDownloadUrl={`/life_settlement/api/carrier/`}
          />
        </div>
      ) : null}
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    activeCarrierFile: activeCarrierFileSelector(state),
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  uploadCarrierFile,
  fetchCarriers,
})(CarrierFileModal);
