import React, { useRef } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import {
  TextInput,
  FormFilter,
  SubmitSearchButton,
  ClearFiltersButton,
  Select,
  PercentageInput,
  CurrencyInput,
} from "components/forms";
import { fetchFunders, updateFunderFilters } from "actions";
import { funderFiltersSelector } from "../../reducers";
import { buyingStatusFilterChoices, loanPolicyChoices, rdbChoices, sulChoices, vulChoices } from "../../constants";
import { leVendorChoices } from "./constants";
import { makeChoices } from "../../utils";

const FundersListFilterForm = ({ fetchFunders, ...props }) => {
  let [le_vendor, setLeVendor] = React.useState();
  let initial_le_vendor = [];
  if (le_vendor) {
    let le_vendor_list = le_vendor.split(",");
    for (let le of le_vendor_list) {
      initial_le_vendor.push({ value: le, label: le });
    }
  }

  const handleLEVendorChange = selectedOptions => {
    if (selectedOptions) {
      setLeVendor(selectedOptions.map(o => o.value).join(","));
    } else {
      setLeVendor(undefined);
    }
  };
  const buyingStatusRef = useRef();
  const leVendorRef = useRef();

  // TODO: Fix multi-select filter not loading visually after going back to Funders

  return (
    <Formik
      initialValues={{
        ...props.currentFilters,
      }}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        props.updateFunderFilters({
          ...values,
          form: "profile",
        });
        // Handle defaultValue dict
        if (values.buying_status && values.buying_status.value === "Active") {
          delete values.buying_status;
        }
        await fetchFunders({ ...values, le_vendor: le_vendor, form: "profile" });
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, resetForm }) => {
        const resetCallback = () => {
          props.updateFunderFilters({});
          resetForm();
          fetchFunders();
          buyingStatusRef.current.select?.clearValue();
          leVendorRef.current.select?.clearValue();
        };

        return (
          <FormFilter title={"Funder Search"} onSubmit={handleSubmit}>
            <Form.Row>
              <TextInput label="Funder Name" name="name" />
              <Select
                label="Funder Status"
                name="buying_status"
                placeholder={"Select Buying Status"}
                options={props.buyingStatusFilterChoices}
                defaultValue={{ value: "Active", label: "Active" }}
                submitRef={buyingStatusRef}
              />
            </Form.Row>
            <Form.Row>
              <TextInput label="Min LE" name="minimum_le" />
              <TextInput label="Max LE" name="max_le" />
              <TextInput label="Min Age" name="minimum_age" />
              <TextInput label="Max Age" name="maximum_age" />
            </Form.Row>
            <Form.Row>
              <CurrencyInput label="Min Face" name="minimum_face_amount" />
              <CurrencyInput label="Max Face" name="maximum_face_amount" />
              <Select
                label="LE Vendors"
                name="le_vendor"
                placeholder={"Select LE Vendor"}
                options={props.leVendorChoices}
                isMulti={true}
                onChange={handleLEVendorChange}
                submitRef={leVendorRef}
                defaultValue={le_vendor}
              />
              <TextInput label="Required # of LEs" name="market_les_required" />
            </Form.Row>
            <Form.Row>
              <Select label="RDB" name="retained_death_benefit" placeholder="Select RDB" options={props.rdbChoices} />
              <Select label="VUL" name="variable_policies" placeholder="Select VUL" options={props.vulChoices} />
              <Select label="SUL" name="survivorship" placeholder="Select SUL" options={props.sulChoices} />
              <Select
                label="Internal Policy Loans"
                name="loan_policies"
                placeholder="Select Policy Loans"
                options={props.loanPolicyChoices}
              />
            </Form.Row>
            <Form.Row>
              <PercentageInput label="Max BE" name="max_be" />
              <PercentageInput label="Max PSM" name="max_psm" />
              <TextInput label="Target IRR (Low End)" name="target_irr_low" />
              <TextInput label="Target IRR (High End)" name="target_irr_high" />
            </Form.Row>
            <SubmitSearchButton />
            <ClearFiltersButton onClick={resetCallback} />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const currentFilters = funderFiltersSelector(state);

  return {
    buyingStatusFilterChoices,
    leVendorChoices,
    currentFilters,
    rdbChoices,
    sulChoices,
    vulChoices,
    loanPolicyChoices,
  };
};

export default connect(mapStateToProps, {
  fetchFunders,
  updateFunderFilters,
})(FundersListFilterForm);
