import React from "react";
import { connect } from "react-redux";

import { createEmptyFunder, setActiveTab, initPagination } from "actions";
import FunderBuyingProfileTable from "./FunderBuyingProfileTable";
import FunderBuyingProfileFilterForm from "./FunderBuyingProfileFilterForm";
import FunderPageHeader from "./FunderPageHeader";

const FunderBuyingProfilePage = props => {
  const [activeSection, setActiveSection] = React.useState("funders");
  React.useEffect(() => {
    props.setActiveTab(activeSection);
    props.initPagination();
    setActiveSection(activeSection);
    document.title = "LifeRoc | Funders";
  }, []);

  return (
    <>
      <FunderPageHeader />
      <div className={`section-card shadowed case-section`} style={{ marginTop: "1.5rem" }}>
        <FunderBuyingProfileFilterForm />
        <FunderBuyingProfileTable />
      </div>
    </>
  );
};

export default connect(null, {
  createEmptyFunder,
  setActiveTab,
  initPagination,
})(FunderBuyingProfilePage);
