import {
  SELECT_MEDICAL_RECORD,
  FETCH_MEDICAL_RECORDS,
  CREATE_MEDICAL_RECORD,
  EDIT_MEDICAL_RECORD,
  DELETE_MEDICAL_RECORD,
  CLEAN_LIFE_SETTLEMENT_CASE_DATA,
} from "actions/types";
import { removeKey } from "utils.js";
import { arrayToObjectIndexedById } from "reducers/utils";

export const defaultState = {
  activeMedicalRecordId: null,
  medicalRecords: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_MEDICAL_RECORD:
      return { ...state, activeMedicalRecordId: action.payload };

    case FETCH_MEDICAL_RECORDS:
      return {
        ...state,
        medicalRecords: arrayToObjectIndexedById(action.payload),
      };

    case CREATE_MEDICAL_RECORD:
    case EDIT_MEDICAL_RECORD:
      return {
        ...state,
        medicalRecords: {
          ...state.medicalRecords,
          [action.payload.id]: action.payload,
        },
      };

    case DELETE_MEDICAL_RECORD: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        medicalRecords: removeKey(state.medicalRecords, toDeleteId),
      };
      if (state.activeMedicalRecordId === toDeleteId) {
        newState.activeMedicalRecordId = null;
      }
      return newState;
    }

    case CLEAN_LIFE_SETTLEMENT_CASE_DATA:
      return { ...defaultState };

    default:
      return state;
  }
};

export const medicalRecordsSelector = state => Object.values(state.medicalRecords);

export const activeMedicalRecordSelector = state => {
  const { activeMedicalRecordId, medicalRecords } = state;

  if (activeMedicalRecordId === null) {
    return null;
  }
  return medicalRecords[activeMedicalRecordId];
};

export const medicalRecordsForActiveInsuredSelector = (state, activeInsuredId) => {
  const medicalRecords = Object.values(state.medicalRecords);
  return medicalRecords.filter(mr => mr.insured === activeInsuredId);
};
