import React from "react";

import { Select } from ".";
import { genderChoices } from "options.js";

export default props => {
  return (
    <Select
      placeholder="Select Gender"
      label="Gender"
      name="gender"
      options={genderChoices.getChoices()}
      disabled={props.disabled}
      {...props}
    />
  );
};
