import React from "react";
import { connect } from "react-redux";
import {
  clearSelectedAccount,
  clearAccountsList,
  selectAccountForAdvisorLead,
  fetchAccounts,
  selectAccount,
  showModal,
  hideModal,
  fetchLeadTasks,
} from "actions";
import {
  activeAgencySelector,
  accountsSelector,
  loadingAccountsSelector,
  selectedAccountsSelector,
  activeLeadSelector,
} from "reducers";
import Table, { datetimeCell } from "components/table";
import { isAdmin } from "permissions";
import { PRACTICE_TYPES_MAP } from "../accounts/constants";
import { SELECT_ACCOUNT_FOR_ADVISOR_LEAD_MODAL } from "../../components/modals";

const SelectAccountForAdvisorLeadTable = ({ accounts, accountsLoading, leadInfo, ...props }) => {
  React.useEffect(() => {
    props.clearAccountsList();
  }, []);

  const columns = [
    {
      Header: "Account Name",
      accessor: "source_name",
    },
    {
      Header: "Entity Name",
      accessor: "entity_name",
    },
    {
      Header: "Account Type",
      accessor: "type",
      Cell: ({ row }) => {
        const type = row.original.type;
        if (type === 0) {
          return "Individual";
        } else if (type === 1) {
          return "Entity";
        }
      },
    },
    {
      Header: "Practice Type",
      accessor: "practice_type",
      Cell: ({ row }) => {
        const type = row.original.practice_type;
        if (type) {
          return PRACTICE_TYPES_MAP[type] || row.original.practice_type;
        } else {
          return "";
        }
      },
    },
    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "State",
      accessor: "state",
    },
    {
      Header: "Work Phone",
      accessor: "work_phone",
    },
    {
      Header: "Cell Phone",
      accessor: "cell_phone",
    },
    {
      Header: "Other Phone",
      accessor: "other_phone",
    },
    {
      Header: "Work Email",
      accessor: "work_email",
    },
    {
      Header: "Sales Rep",
      accessor: "sales_rep_name",
    },
    {
      Header: "National Account",
      accessor: "active_national_account",
    },
    {
      Header: "Date Last Modified",
      accessor: "last_edit_date",
      Cell: datetimeCell,
    },
    {
      Header: "Select Account",
      Cell: ({ row }) => {
        let checked = false;
        if (leadInfo.account_id) {
          checked = row.original.id === leadInfo.account_id;
        }
        return (
          <input
            type="checkbox"
            checked={checked}
            onClick={async e => {
              await props.selectAccountForAdvisorLead(leadInfo.id, { account_id: row.original.id });
              await props.fetchLeadTasks(leadInfo.id);
              props.hideModal(SELECT_ACCOUNT_FOR_ADVISOR_LEAD_MODAL);
            }}
          />
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      data={accounts}
      emptyMessage={accountsLoading ? "Loading Accounts data..." : "No data"}
      initialPageSize={100}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const accounts = Object.values(accountsSelector(state)) || [];

  return {
    accounts: accounts,
    activeAgency: activeAgencySelector(state),
    accountsLoading: loadingAccountsSelector(state),
    selectedAccounts: selectedAccountsSelector(state),
    hasAdminPermission: isAdmin(state),
    leadInfo: activeLeadSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchAccounts,
  selectAccount,
  showModal,
  hideModal,
  clearSelectedAccount,
  selectAccountForAdvisorLead,
  clearAccountsList,
  fetchLeadTasks,
})(SelectAccountForAdvisorLeadTable);
