import React from "react";
import { connect } from "react-redux";

import { selectProduct, clearSelectedProduct, fetchLifeSettlementChoices } from "actions";
import { Outlet } from "react-router-dom";
import ProductTopHeader from "./ProductTopHeader";
import { compose } from "redux";
import withRouter from "../withRouter";

/* Data component: fetch relevant data for the current product */
const ProductsSetup = ({ productId, ...props }) => {
  const fetchData = () => {
    props.selectProduct(productId);
    props.fetchLifeSettlementChoices();
  };

  React.useEffect(() => {
    props.clearSelectedProduct();
    fetchData();
  }, []);

  return (
    <div>
      <ProductTopHeader />
      <Outlet />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const productId = ownProps.params.id;

  return {
    productId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    selectProduct,
    clearSelectedProduct,
    fetchLifeSettlementChoices,
  }),
)(ProductsSetup);
