import React from "react";
import { connect } from "react-redux";

import { TabHeader, TableHeader } from "components/table";
import {
  showModal,
  selectCaseFile,
  secondaryGenerate,
  selectDoctor,
  fetchCaseFiles,
  fetchBeneficiaries,
  fetchInsured,
  fetchOwners,
  fetchLifeExpectancyChoices,
} from "actions";
import { ButtonCreate, ButtonFile } from "components/buttons";
import { CASE_FILE_MODAL, REDACT_MODAL } from "components/modals";
import { activeCaseSelector, loadingSelector } from "reducers";
import CaseFilesTable from "./CaseFilesTable";
import { isAdmin, isReadMode } from "permissions";

const CaseFiles = props => {
  const createButton = (
    <ButtonCreate
      onClick={() => {
        props.selectCaseFile(null);
        props.selectDoctor(null);
        props.showModal(CASE_FILE_MODAL);
      }}
      key="create"
    >
      Create New
    </ButtonCreate>
  );

  React.useEffect(() => {
    if (props.caseInfo.submission_id) {
      document.title = `LifeRoc | ${props.caseInfo.submission_id} | Files`;
    }
  }, [props.caseInfo.id]);

  const generateButton = (
    <ButtonCreate
      onClick={() => {
        props.secondaryGenerate();
      }}
      key="generate"
    >
      Secondary Generate
    </ButtonCreate>
  );

  const redactButton = (
    <ButtonFile
      onClick={() => {
        props.showModal(REDACT_MODAL);
      }}
      key="redact"
    >
      Redact
    </ButtonFile>
  );

  const renderActionButtons = () => {
    let actions = props.actionButtons ? props.actionButtons : [generateButton, redactButton, createButton];
    return <TableHeader.Actions>{actions.map(a => a)}</TableHeader.Actions>;
  };

  if (props.loading.error404) {
    return <></>;
  }

  return (
    <div>
      <div className="records-table">
        <TabHeader>
          <TabHeader.Title>Files & Requirements</TabHeader.Title>
          {!props.readMode && renderActionButtons()}
        </TabHeader>
        <CaseFilesTable />
      </div>
      <br />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
    loading: loadingSelector(state),
    hasAdminPermission: isAdmin(state),
    readMode: isReadMode(state),
  };
};

export default connect(mapStateToProps, {
  selectCaseFile,
  selectDoctor,
  showModal,
  secondaryGenerate,
  fetchCaseFiles,
  fetchInsured,
  fetchOwners,
  fetchBeneficiaries,
  fetchLifeExpectancyChoices,
})(CaseFiles);
