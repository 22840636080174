import React from "react";
import { connect } from "react-redux";
import CaseTasksTable from "./CaseTasksTable";
import { RecordsTable } from "components/table";
import { CASE_TASK_MODAL } from "components/modals";
import { selectCaseTask, showModal } from "actions";
import { getViewMode } from "../../../utils";

const CaseTasksPage = props => {
  const isViewMode = getViewMode();
  return (
    <>
      <RecordsTable
        title="Case Tasks"
        createButtonLabel={isViewMode ? "" : "Add Task"}
        onCreateClick={() => {
          props.selectCaseTask(null);
          props.showModal(CASE_TASK_MODAL);
        }}
        Table={CaseTasksTable}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  selectCaseTask,
  showModal,
})(CaseTasksPage);
