import React from "react";
import { connect } from "react-redux";

import { fetchFundersForClosing } from "actions";
import { activeCaseSelector } from "reducers";

const ClosingPage = props => {
  React.useEffect(() => {
    if (props.caseInfo.submission_id) {
      document.title = `LifeRoc | ${props.caseInfo.submission_id} | Closing`;
    }
    props.fetchFundersForClosing();
  }, [props.caseInfo.id]);

  return <></>;
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchFundersForClosing,
})(ClosingPage);
