import React from "react";
import { connect } from "react-redux";

import { selectState } from "actions";
import { Outlet } from "react-router-dom";
import StateTopHeader from "./StateTopHeader";
import { compose } from "redux";
import withRouter from "../withRouter";

/* Data component: fetch relevant data for the current state */
const StateSetup = ({ stateId, ...props }) => {
  const fetchData = () => {
    props.selectState(stateId);
  };

  React.useEffect(() => {
    fetchData();
    document.title = "LifeRoc | States";
  }, []);

  return (
    <div>
      <StateTopHeader />
      <Outlet />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const stateId = ownProps.params.id;

  return {
    stateId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    selectState,
  }),
)(StateSetup);
