import React from "react";
import { connect } from "react-redux";

import {
  fetchCaseFiles,
  fetchInsured,
  fetchOwners,
  fetchBeneficiaries,
  fetchLifeExpectancyChoices,
  fetchCaseFilesByCaseDocumentId,
  clearActiveCaseFile,
} from "actions";
import { activeCaseSelector } from "reducers";
import CaseFilesPage from "./CaseFilesPage";
import { useLocation } from "react-router-dom";

/* Data component: fetch relevant data for the current case */
const CaseFilesSetup = ({ funderId, ...props }) => {
  const pathname = useLocation().pathname;
  const documentId = pathname.split("files/")[1].replace("/", "");

  React.useEffect(() => {
    props.clearActiveCaseFile();
    if (documentId) {
      props.fetchCaseFilesByCaseDocumentId(documentId);
    }
  }, []);

  React.useEffect(() => {
    if (props.caseInfo.id) {
      props.fetchCaseFiles();
      props.fetchInsured(props.caseInfo.id);
      props.fetchOwners(props.caseInfo.id);
      props.fetchBeneficiaries(props.caseInfo.id);
      props.fetchLifeExpectancyChoices();
      if (documentId) {
        props.fetchCaseFilesByCaseDocumentId(documentId);
      }
    }
  }, [props.caseInfo.id]);

  return <CaseFilesPage />;
};

const mapStateToProps = (state, ownProps) => {
  return {
    caseInfo: activeCaseSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchCaseFiles,
  fetchInsured,
  fetchOwners,
  fetchBeneficiaries,
  fetchLifeExpectancyChoices,
  fetchCaseFilesByCaseDocumentId,
  clearActiveCaseFile,
})(CaseFilesSetup);
