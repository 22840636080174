import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { PRODUCT_MODAL } from "components/modals";
import ProductFormPopup from "./ProductFormPopup";
import ProductRelatedCasesTable from "./ProductRelatedCasesTable";

const CreateProductModal = ({ ...props }) => {
  return (
    <FullPageModal modalType={PRODUCT_MODAL}>
      <ProductFormPopup callback={props.callback} />
      <ProductRelatedCasesTable />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(CreateProductModal);
