import React from "react";
import SortCaret from "./SortCaret";
import { connect } from "react-redux";
import { applyOrderBy } from "actions";

const Header = ({ headerGroups, ...props }) => {
  return (
    <thead>
      {headerGroups.map(headerGroup => {
        const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
        return (
          <tr {...headerGroupProps} key={headerGroupKey}>
            {headerGroup.headers.map(column => {
              const { key: columnKey, ...columnProps } = column.getHeaderProps(column.getSortByToggleProps());
              return (
                <th {...columnProps} key={columnKey} onClick={async () => await props.applyOrderBy(column.id)}>
                  {column.render("Header")}
                  <SortCaret column={column} />
                </th>
              );
            })}
          </tr>
        );
      })}
    </thead>
  );
};

export default connect(null, { applyOrderBy })(Header);
