import React from "react";
import { PulseLoader } from "react-spinners";

const Loader = ({ isLoading, text }) => {
  return (
    <section style={{ display: "flex", alignItems: "center", height: 45 }}>
      {isLoading && (
        <>
          <span style={{ whiteSpace: "nowrap" }}>{text}</span>
          <PulseLoader color={"#ffffff"} cssOverride={{ marginLeft: "10px" }} />
        </>
      )}
    </section>
  );
};

export default Loader;
