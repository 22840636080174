import { Formik } from "formik";
import { SubmitButton } from "../../components/forms";
import { Form } from "react-bootstrap";
import {
  activeAgencySelector,
  fundersSelector,
  funderWinningBidSelector,
  funderAuctionActivitiesSelector,
  totalTradingCasesSelector,
  tradeCasesSelector,
  tradeBlindCasesSelector,
  dpCasesSelector,
  tbdCasesSelector,
} from "reducers";
import { connect } from "react-redux";
import React from "react";
import { REVENUE_MODEL_CHOICES } from "../../constants";
import { DisplayValueWithTitle } from "../../components/forms/DisplayValueWithTitle";
import {
  calculateTotalFaceAmount,
  calculateTotalOurRevenue,
  sumOurHighOffer,
} from "../cases/auction/form/CalculateTotals";

const CaseTradingSummaryForm = ({ caseInfo, ...props }) => {
  if (!caseInfo) {
    // Still didn't fetch data
    return null;
  }

  const formRowStyle = {
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
  };

  const getRevenueModel = value => {
    const revenue_model_data = REVENUE_MODEL_CHOICES.find(choice => choice.value === value);
    return revenue_model_data && revenue_model_data.label;
  };

  const { bidding_format } = caseInfo;
  // Revenue Model is driven by Winning Bid
  let revenue_model = "TBD";
  if (props.winningBid) {
    const revenue_model_value = props.winningBid.revenue_model;
    if (revenue_model_value) {
      revenue_model = getRevenueModel(revenue_model_value);
    }
  } else {
    // Look at Highest Bid
    let highestOffer = 0;
    for (let a of props.auctionActivities) {
      if (a.total_offer && parseFloat(a.total_offer) > highestOffer) {
        if (a.revenue_model) {
          highestOffer = parseFloat(a.total_offer);
          revenue_model = getRevenueModel(a.revenue_model);
        }
      }
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        revenue_model,
        bidding_format,
      }}
      onSubmit={{}}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <>
            <Form onSubmit={handleSubmit}>
              <Form.Row style={formRowStyle}>
                <DisplayValueWithTitle title={"Total Case Count"} value={props.totalCasesCount} md={3} />
                <DisplayValueWithTitle title={"Total Face Amount"} value={props.totalFaceAmount} md={3} />
                <DisplayValueWithTitle title={"Total Revenue"} value={props.totalOurRevenue} md={3} />
                <DisplayValueWithTitle title={"Total Offers"} value={props.totalOffers} md={3} />
              </Form.Row>
              <Form.Row style={formRowStyle}>
                <DisplayValueWithTitle title={"Trading - Pre-Booked Case Count"} value={props.tradeCasesCount} />
                <DisplayValueWithTitle title={"Trading - Pre-Booked Face Amount"} value={props.tradingFaceAmount} />
                <DisplayValueWithTitle title={"Trading - Pre-Booked Revenue"} value={props.tradingOurRevenue} md={3} />
                <DisplayValueWithTitle title={"Trading - Pre-Booked Offers"} value={props.tradingOffers} md={3} />
              </Form.Row>
              <Form.Row style={formRowStyle}>
                <DisplayValueWithTitle title={"Trading - Blind Case Count"} value={props.tradeBlindCasesCount} />
                <DisplayValueWithTitle title={"Trading - Blind Case Face Amount"} value={props.tradeBlindFaceAmount} />
                <DisplayValueWithTitle
                  title={"Trading - Blind Case Revenue"}
                  value={props.tradeBlindOurRevenue}
                  md={3}
                />
                <DisplayValueWithTitle
                  title={"Trading - Blind Case Trading Offers"}
                  value={props.tradeBlindOffers}
                  md={3}
                />
              </Form.Row>
              <Form.Row style={formRowStyle}>
                <DisplayValueWithTitle title={"Direct Placement Case Count"} value={props.dpCasesCount} md={3} />
                <DisplayValueWithTitle title={"Direct Placement Face Amount"} value={props.dpFaceAmount} md={3} />
                <DisplayValueWithTitle title={"Direct Placement Revenue"} value={props.dpOurRevenue} md={3} />
                <DisplayValueWithTitle title={"Direct Placement Offers"} value={props.dpOffers} md={3} />
              </Form.Row>
              <Form.Row style={formRowStyle}>
                <DisplayValueWithTitle title={"TBD Case Count"} value={props.tbdCasesCount || ""} md={3} />
                <DisplayValueWithTitle title={"TBD Face Amount"} value={props.tbdFaceAmount} md={3} />
                <DisplayValueWithTitle title={"TBD Revenue"} value={props.tbdOurRevenue} md={3} />
                <DisplayValueWithTitle title={"TBD Offers"} value={props.tbdOffers} md={3} />
              </Form.Row>
              <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const activeAgency = activeAgencySelector(state);
  const funderWinningBid = funderWinningBidSelector(state);
  const auctionActivities = funderAuctionActivitiesSelector(state);
  const totalTradingCases = totalTradingCasesSelector(state) || [];
  const dpCases = dpCasesSelector(state) || [];
  const tbdCases = tbdCasesSelector(state) || [];
  const totalCasesCount = totalTradingCases.length;

  const dpCasesCount = dpCases.length;
  const tbdCasesCount = tbdCases.length;
  const totalFaceAmount = calculateTotalFaceAmount(totalTradingCases);
  const dpFaceAmount = calculateTotalFaceAmount(dpCases);
  const tbdFaceAmount = calculateTotalFaceAmount(tbdCases);
  const totalOurRevenue = calculateTotalOurRevenue(totalTradingCases, activeAgency.id);
  const dpOurRevenue = calculateTotalOurRevenue(dpCases, activeAgency.id);
  const tbdOurRevenue = calculateTotalOurRevenue(tbdCases, activeAgency.id);
  const totalOffers = sumOurHighOffer(totalTradingCases);
  const dpOffers = sumOurHighOffer(dpCases);
  const tbdOffers = sumOurHighOffer(tbdCases);

  // Trading - Pre Booked Cases
  const tradeCases = tradeCasesSelector(state) || [];
  const tradeCasesCount = tradeCases.length;
  const tradingFaceAmount = calculateTotalFaceAmount(tradeCases);
  const tradingOurRevenue = calculateTotalOurRevenue(tradeCases, activeAgency.id);
  const tradingOffers = sumOurHighOffer(tradeCases);

  // Trading - Blind Cases
  const tradeBlindCases = tradeBlindCasesSelector(state) || [];
  const tradeBlindCasesCount = tradeBlindCases.length;
  const tradeBlindFaceAmount = calculateTotalFaceAmount(tradeBlindCases);
  const tradeBlindOurRevenue = calculateTotalOurRevenue(tradeBlindCases, activeAgency.id);
  const tradeBlindOffers = sumOurHighOffer(tradeBlindCases);

  return {
    winningBid: funderWinningBid,
    funders: fundersSelector(state),
    auctionActivities,
    totalTradingCases,
    activeAgency,
    tradeCasesCount,
    tradeBlindCasesCount,
    totalCasesCount,
    dpCasesCount,
    tbdCasesCount,
    totalFaceAmount,
    tradingFaceAmount,
    dpFaceAmount,
    tbdFaceAmount,
    totalOurRevenue,
    tradingOurRevenue,
    dpOurRevenue,
    tbdOurRevenue,
    totalOffers,
    tradingOffers,
    dpOffers,
    tbdOffers,
    tradeBlindFaceAmount,
    tradeBlindOurRevenue,
    tradeBlindOffers,
  };
};

export default connect(mapStateToProps, {})(CaseTradingSummaryForm);
