import React, { useState } from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import * as moment from "moment";

import { getValidationClassName } from "./validation";
import { DATE_FORMAT } from "constants.js";
import Label from "./Label";
import FieldErrors from "./FieldErrors";
import { FaCalendarAlt } from "react-icons/fa";

export const toServerFormat = date => {
  if (date) return moment(date).format(DATE_FORMAT.SERVER.MOMENT);
  return null;
};

/* Component that represents Date inputs.
 * Internally it stores two fields: one hidden field that will
 * hold the date using "DATE_SERVER_FORMAT", and another (visible)
 * field to choose dates. Each time a date is chosen, the value will
 * be formatted to DATE_SERVER_FORMAT and saved in the hidden input. */
const DateInput = ({
  label,
  md = "",
  pickerDateFormat = DATE_FORMAT.DISPLAY.PICKER,
  name,
  isRequired,
  showIcon = true,
  ...props
}) => {
  const [field, meta] = useField({ name });
  const [present, setPresent] = useState(false);
  const { setFieldValue } = useFormikContext();

  const validateClassName = getValidationClassName(meta);
  let selectedDate = null;
  if (field.value) {
    selectedDate = moment(field.value, DATE_FORMAT.SERVER.MOMENT).toDate();
    if (isNaN(selectedDate.getTime())) {
      // Try another format
      selectedDate = moment(field.value, "MM-DD-YYYY").toDate();
    }
  }

  React.useEffect(() => {
    const bool = Boolean(selectedDate);
    setPresent(!bool);
  }, [selectedDate]);

  return (
    <Form.Group as={Col} style={props.style} sm="12" className={`col-md${md && "-" + md}`}>
      <Label label={label} required={isRequired} />
      <InputGroup style={{ flexWrap: "wrap" }}>
        {showIcon ? (
          <InputGroup.Prepend>
            <InputGroup.Text id="dollarSign">
              <FaCalendarAlt />
            </InputGroup.Text>
          </InputGroup.Prepend>
        ) : null}
        <div style={{ flexGrow: "1" }} className={validateClassName}>
          <input type="hidden" {...field} value={toServerFormat(field.value) || ""} />
          <DatePicker
            {...props}
            autoComplete="off"
            className={`form-control ${validateClassName}`}
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            preventOpenOnFocus={true}
            selected={selectedDate}
            onChange={val => {
              setFieldValue(field.name, toServerFormat(val));
            }}
            dateFormat={pickerDateFormat}
            disabled={present || props.disabled}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row", textAlign: "center" }}>
          <p style={{ margin: "5px" }}> Present </p>
          <input
            style={{ marginLeft: "1rem" }}
            type="checkbox"
            checked={present}
            onClick={e => {
              if (e.target.checked) {
                setFieldValue("ownership_end_date", null);
                setPresent(true);
              } else {
                setPresent(false);
              }
            }}
            disabled={props.disabled}
          />
        </div>

        <FieldErrors meta={meta} />
      </InputGroup>
    </Form.Group>
  );
};

export default DateInput;
