import {
  SELECT_STATE,
  FETCH_STATES,
  EDIT_STATE,
  CREATE_STATE,
  DELETE_STATE,
  FETCH_STATE,
  SELECT_STATE_PROVIDER_FILE,
  SELECT_STATE_BROKER_FILE,
  EDIT_STATE_BROKER_FILE,
  LOADING,
  CREATE_STATE_BROKER_FILE,
  EDIT_STATE_PROVIDER_FILE,
  CREATE_STATE_PROVIDER_FILE,
} from "actions/types";
import { arrayToObjectIndexedById } from "reducers/utils";
import { removeKey } from "../../../utils";
import * as types from "../../../actions/types";

export const defaultState = {
  states: {},
  activeStateId: null,
  activeStateBrokerFile: null,
  activeStateProviderFile: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_STATE:
      return { ...state, activeStateId: action.payload };

    case FETCH_STATE: {
      return {
        ...state,
        states: {
          ...state.states,
          [action.payload.id]: action.payload,
        },
      };
    }

    case FETCH_STATES:
      return { ...state, states: arrayToObjectIndexedById(action.payload) };

    case EDIT_STATE:
    case CREATE_STATE: {
      return {
        ...state,
        states: {
          ...state.states,
          [action.payload.id]: action.payload,
        },
        activeStateId: action.payload.id,
      };
    }

    case DELETE_STATE: {
      return {
        ...state,
        states: removeKey(state.states, action.payload),
      };
    }

    case SELECT_STATE_BROKER_FILE: {
      return {
        ...state,
        activeStateBrokerFile: action.payload,
      };
    }

    case SELECT_STATE_PROVIDER_FILE: {
      return {
        ...state,
        activeStateProviderFile: action.payload,
      };
    }

    case EDIT_STATE_BROKER_FILE:
    case CREATE_STATE_BROKER_FILE: {
      let newState = state;
      newState.activeStateBrokerFile = {
        ...state.activeStateBrokerFile,
        ...action.payload,
      };
      return newState;
    }

    case EDIT_STATE_PROVIDER_FILE:
    case CREATE_STATE_PROVIDER_FILE: {
      let newState = state;
      newState.activeStateProviderFile = {
        ...state.activeStateProviderFile,
        ...action.payload,
      };
      return newState;
    }

    case LOADING:
      if (action.payload.states) {
        return {
          ...state,
          states: {},
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export const activeStateSelector = ({ states, activeStateId }) => {
  if (activeStateId) {
    return states[activeStateId];
  }
};

export const statesSelector = state => {
  return Object.values(state.states);
};

/** State Files */

export const selectStateBrokerFile = data => ({
  type: types.SELECT_STATE_BROKER_FILE,
  payload: data,
});

export const selectStateProviderFile = data => ({
  type: types.SELECT_STATE_PROVIDER_FILE,
  payload: data,
});

export const activeStateBrokerFileSelector = state => {
  return state.activeStateBrokerFile;
};

export const activeStateProviderFileSelector = state => {
  return state.activeStateProviderFile;
};
