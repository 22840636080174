import { FETCH_ACTIVITY_LOG } from "actions/types";

export const defaultState = {
  activityLog: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ACTIVITY_LOG:
      return { ...state, activityLog: action.payload };
    default:
      return state;
  }
};

// Selectors
export const activityLogSelector = state => state.activityLog;
