import React from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import CreatableSelect from "react-select/creatable";

import { getValidationClassName } from "../validation";
import Label from "../Label";
import FieldErrors from "../FieldErrors";
import { getCustomStyles } from "./customStyles";
import { getViewMode } from "../../../utils";

const SelectCreatable = ({ label, options = [], emptyValue = "", md = "", ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  // Search value into possibly nested options (for now we search up to 1 nested level)
  const findOption = value => {
    let flattenedOpts = [];

    options.forEach(opt => {
      if (opt.options) {
        flattenedOpts = [...flattenedOpts, ...opt.options];
      } else {
        flattenedOpts.push(opt);
      }
    });
    return flattenedOpts.find(opt => opt.value === value);
  };

  let initialValue;
  if (field.value !== emptyValue) {
    initialValue = findOption(field.value);
  } else {
    if (props.defaultValue !== undefined) {
      initialValue = findOption(props.defaultValue);
    } else {
      initialValue = emptyValue;
    }
  }

  const defaultOnChange = option => {
    let value;
    if (option === null) {
      value = props.defaultValue !== undefined ? props.defaultValue : emptyValue;
    } else {
      value = option.value;
    }
    setFieldValue(field.name, value);
  };

  const isViewMode = getViewMode();
  const isDisabled = props.disabled || isViewMode;
  const customStyles = getCustomStyles(isDisabled);

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      {props.buttons ? props.buttons : null}
      <InputGroup style={{ flexWrap: "no-wrap!important" }}>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <div style={{ marginLeft: 1, marginRight: 3 }}>{label}</div>
          </InputGroup.Text>
        </InputGroup.Prepend>
        <div style={{ flexGrow: "1" }}>
          <CreatableSelect
            {...props}
            maxMenuHeight={200}
            name={field.name}
            options={options}
            value={initialValue}
            onChange={props.onChange || defaultOnChange}
            className={`select ${getValidationClassName(meta)}`}
            isClearable
            menuPlacement="auto"
            formatCreateLabel={inputValue => inputValue}
            styles={customStyles}
            isDisabled={isViewMode}
          />
        </div>
        <FieldErrors meta={meta} />
      </InputGroup>
    </Form.Group>
  );
};

export default SelectCreatable;
