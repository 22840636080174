import React from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import { useDispatch, connect } from "react-redux";
import { useField, useFormikContext } from "formik";

import { isInvalid } from "./validation";
import Label from "./Label";
import FieldErrors from "./FieldErrors";
import FieldHelpText from "./FieldHelpText";
import { CASE_FILE_MODAL_LE, RELATED_LE_MODAL } from "components/modals";

import { selectCaseFile, hideModal, showModal, fetchCaseFilesByCaseDocumentId, fetchInsured } from "actions";

const TextInput = ({ label, md = "", helpText, isRequired = false, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const dispatch = useDispatch();

  if (!props.autoComplete) {
    props.autoComplete = "off";
  }

  return (
    <Form.Group
      fetchInsured={props.fetchInsured}
      fetchCaseFilesByCaseDocumentId={props.fetchCaseFilesByCaseDocumentId}
      selectCaseFile={props.selectCaseFile}
      as={Col}
      sm="12"
      className={`col-md${md && "-" + md}`}
    >
      {label && <Label label={label} required={isRequired} />}
      <a
        href={props.url}
        onClick={event => {
          event.preventDefault();
          dispatch(hideModal(RELATED_LE_MODAL));
          props.fetchInsured(props.caseId);
          props.fetchCaseFilesByCaseDocumentId(props.caseDocId);
          props.selectCaseFile(props.caseDocId);
          dispatch(showModal(CASE_FILE_MODAL_LE));
        }}
        target="_blank"
        rel="noreferrer"
      >
        <InputGroup>
          <Form.Control
            style={{ cursor: "pointer", color: "blue" }}
            {...field}
            {...props}
            onChange={e => {
              let value = e.target.value;
              setFieldValue(field.name, value);
            }}
            id={field.name}
            isInvalid={isInvalid(meta)}
          />
          <FieldErrors meta={meta} />
        </InputGroup>
      </a>
      {helpText && <FieldHelpText>{helpText}</FieldHelpText>}
    </Form.Group>
  );
};

export default connect(null, {
  fetchInsured,
  fetchCaseFilesByCaseDocumentId,
  selectCaseFile,
})(TextInput);
