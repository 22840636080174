import React from "react";
import { connect } from "react-redux";

import { SELECT_ACCOUNT_FOR_ADVISOR_LEAD_MODAL } from "components/modals";
import FullPageModal from "../../components/modals/FullPageModal";
import SelectAccountForAdvisorLeadFilterForm from "./SelectAccountForAdvisorLeadFilterForm";
import SelectAccountForAdvisorLeadTable from "./SelectAccountForAdvisorLeadTable";

const SelectAccountForAdvisorLeadModal = () => {
  return (
    <FullPageModal modalType={SELECT_ACCOUNT_FOR_ADVISOR_LEAD_MODAL} className={"wide"}>
      <SelectAccountForAdvisorLeadFilterForm />
      <SelectAccountForAdvisorLeadTable />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    // accountInfo: activeAccountSelector(state),
  };
};

export default connect(mapStateToProps, {})(SelectAccountForAdvisorLeadModal);
