import React from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { selectInsured, unsetShouldSave, fetchInsured } from "actions";
import {
  activeCaseIdSelector,
  insuredListSelector,
  activeInsuredSelector,
  loadingInsuredSelector,
  shouldSaveSelector,
  activeCaseSelector,
  loadingSelector,
} from "reducers";
import InsuredInfo from "./InsuredInfo";
import { ClipLoader } from "react-spinners";
import InsuredForm from "./InsuredForm";

const InsuredPage = ({ caseId, insuredList, activeInsured, insuredLoading, ...props }) => {
  const insuredFormSubmitRef = React.useRef();

  React.useEffect(() => {
    if (props.shouldSave) {
      insuredFormSubmitRef.current && insuredFormSubmitRef.current.click();
      setTimeout(() => {
        unsetShouldSave();
      }, 300);
    }
  }, [props.shouldSave]);

  React.useEffect(() => {
    if (props.caseInfo.submission_id) {
      document.title = `LifeRoc | ${props.caseInfo.submission_id} | Insured`;
    }
    if (caseId) {
      props.fetchInsured(caseId);
    }
  }, [caseId]);

  const renderNoInsuredMessage = () => {
    return (
      <p>
        No insured were created yet. Set the <strong>Insured Lives</strong> in the{" "}
        <Link to={`/cases/${caseId}/policy/`}>Policy</Link> first to create new insured for this case.
      </p>
    );
  };

  const renderInsuredLoading = () => {
    return (
      <div>
        Loading Insured data
        <ClipLoader size={50} css={{ marginLeft: "10px" }} />
      </div>
    );
  };

  const renderInsuredDetails = () => {
    return (
      <section id="insured-details" className="scroll-spied">
        {insuredList.map((insured, idx) => {
          return (
            <InsuredInfo
              key={insured.id}
              idx={idx + 1}
              insuredId={insured.id}
              onClick={() => props.selectInsured(insured.id)}
              isActive={activeInsured && activeInsured.id === insured.id}
            />
          );
        })}
      </section>
    );
  };

  if (props.loading.error404) {
    return <></>;
  }

  if (insuredLoading) {
    return renderInsuredLoading();
  } else if (isEmpty(insuredList)) {
    return renderNoInsuredMessage();
  }

  return (
    <>
      {renderInsuredDetails()}
      <InsuredForm submitRef={insuredFormSubmitRef} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
    caseId: activeCaseIdSelector(state),
    insuredList: insuredListSelector(state),
    loading: loadingSelector(state),
    insuredLoading: loadingInsuredSelector(state),
    activeInsured: activeInsuredSelector(state),
    shouldSave: shouldSaveSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchInsured,
  selectInsured,
})(InsuredPage);
