import React from "react";
import { Navigate } from "react-router-dom";
import { URLS } from "../constants";
import { connect } from "react-redux";
import { showModal } from "../actions";
import { SELECT_AGENCY_MODAL } from "./modals";

/*
 * 1) When the user is authenticating don't show anything (return null).
 * 2) When the user is not authenticating and not authenticated, redirect to login page.
 * 3) When the user hasn't selected an agency, show the SELECT_AGENCY_MODAL
 * 4) When the user has authenticated and selected an agency, show the component
 */
export const PrivateRoute = ({ children, auth, ...props }) => {
  const { isAuthenticating, isSignedIn, activeAgency } = auth;

  if (isAuthenticating) {
    return null;
  }

  if (!isSignedIn) {
    return <Navigate to={URLS.LOGIN} />;
  }

  if (activeAgency === null) {
    props.showModal(SELECT_AGENCY_MODAL);
    return null;
  }

  return children;
};

const mapStateToProps = state => {
  return { auth: state.auth };
};

export default connect(mapStateToProps, { showModal })(PrivateRoute);
