import React from "react";
import { connect } from "react-redux";

import { createEmptyCase, setActiveTab, initPagination } from "actions";
import LifeExpectancyPredictorForm from "./LifeExpectancyPredictorForm";
import LifeExpectancyPredictorTable from "./LifeExpectancyPredictorTable";
import RiskxPageHeader from "../RiskxPageHeader";

const LifeExpectancyPredictorPage = props => {
  const [activeSection, setActiveSection] = React.useState("lifeexpectancy");
  React.useEffect(() => {
    props.setActiveTab(activeSection);
    props.initPagination();
    setActiveSection(activeSection);
    document.title = "LifeRoc | LE Predictor";
  }, []);

  return (
    <>
      <RiskxPageHeader />
      <div className={`section-card shadowed case-section`} style={{ marginTop: "1.5rem" }}>
        <LifeExpectancyPredictorForm />
        <LifeExpectancyPredictorTable />
      </div>
    </>
  );
};

export default connect(null, {
  createEmptyCase,
  setActiveTab,
  initPagination,
})(LifeExpectancyPredictorPage);
