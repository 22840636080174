import React from "react";
import { connect } from "react-redux";
import { useTable, useSortBy, usePagination } from "react-table";
import { Table as BootstrapTable } from "react-bootstrap";

import Pagination from "../../../../components/table/Pagination";
import DefaultPagination from "../../../../components/table/DefaultPagination";
import Body from "../../../../components/table/Body";
import FundersListTableHeader from "./FundersListTableHeader";

import {
  loadingCasesSelector,
  sortBySelector,
  initialPageResultsSelector,
  activeTabSelector,
  caseCountSelector,
  leCountSelector,
} from "reducers";

const FundersListTable = ({
  columns,
  data,
  paginated = true,
  emptyMessage = "No data",
  rowIsActive = row => false,
  initialPageSize,
  sortBy,
  resultsCount,
  initialPageResults,
  casesLoading,
  sortByValue,
  defaultSort = false,
  defaultPagination = false,
  showPaginationResults = true,
}) => {
  const tableProps = {
    columns,
    data,
    emptyMessage,
    rowIsActive,
    initialPageSize,
    sortBy,
    resultsCount,
    initialPageResults,
    casesLoading,
    sortByValue,
    defaultSort,
    defaultPagination,
    showPaginationResults,
  };

  if (paginated) {
    return <TablePaginated {...tableProps} />;
  }

  return <TableNotPaginated {...tableProps} />;
};

const TablePaginated = ({
  columns,
  data,
  emptyMessage,
  rowIsActive,
  initialPageSize = 10,
  resultsCount,
  initialPageResults,
  casesLoading,
  sortByValue, // Redux selector
  sortBy, // Prop from parent
  defaultSort,
  defaultPagination,
  showPaginationResults,
}) => {
  let initialSortBy;
  if (sortBy) {
    initialSortBy = sortBy;
  } else {
    initialSortBy = React.useMemo(() => [{ id: sortByValue.id, desc: sortByValue.desc }], [sortByValue]);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { pageIndex, pageSize },
    pageCount,
    page,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 100, sortBy: initialSortBy },
    },
    useSortBy,
    usePagination,
  );

  const renderResults = () => {
    if (showPaginationResults && casesLoading && resultsCount && !defaultPagination) {
      return <p>showing results {initialResultDisplayed(data)}</p>;
    }
  };

  const initialResultDisplayed = data => {
    return (
      parseInt(initialPageResults + 1) + " - " + parseInt(initialPageResults + data.length) + " of " + resultsCount
    );
  };

  return (
    <>
      <div className="table-container" style={{ width: "100%" }}>
        <BootstrapTable {...getTableProps({ className: "shadowed fundersListFixed" })}>
          <FundersListTableHeader headerGroups={headerGroups} />
          <Body
            rows={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            rowIsActive={rowIsActive}
            emptyMessage={emptyMessage}
          />
        </BootstrapTable>
      </div>
      {!casesLoading && defaultPagination && (
        <DefaultPagination
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          setPageSize={setPageSize}
          gotoPage={gotoPage}
          pageSize={pageSize}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      )}
      {!casesLoading && !defaultPagination && (
        <Pagination
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          setPageSize={setPageSize}
          gotoPage={gotoPage}
          pageSize={pageSize}
          previousPage={previousPage}
          nextPage={nextPage}
          showPaginationResults={showPaginationResults}
        />
      )}
      {renderResults()}
    </>
  );
};

const TableNotPaginated = ({ columns, data, emptyMessage, rowIsActive, defaultSort, defaultPagination, sortBy }) => {
  let useTableData = { columns, data };
  if (sortBy) {
    useTableData.initialState = { sortBy };
  }
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(useTableData, useSortBy);

  return (
    <div className="table-container">
      <BootstrapTable {...getTableProps({ className: "shadowed" })}>
        <FundersListTableHeader headerGroups={headerGroups} />
        <Body
          rows={rows}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          rowIsActive={rowIsActive}
          emptyMessage={emptyMessage}
        />
      </BootstrapTable>
    </div>
  );
};

const mapStateToProps = state => {
  const tab = activeTabSelector(state);
  let resultsCount = 0;
  if (tab === "lifeexpectancy") {
    resultsCount = leCountSelector(state);
  } else if (tab === "cases") {
    resultsCount = caseCountSelector(state);
  }
  return {
    casesLoading: loadingCasesSelector(state),
    sortByValue: sortBySelector(state),
    resultsCount: resultsCount,
    initialPageResults: initialPageResultsSelector(state),
  };
};

export default connect(mapStateToProps, {})(FundersListTable);
