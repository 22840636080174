import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import {
  BooleanSelect,
  Collapsible,
  DateInput,
  DateTimeInput,
  HyperLinkText,
  RowSeparator,
  Select,
  SSNInput,
  SubmitButton,
  TextInput,
} from "components/forms";
import { Form } from "react-bootstrap";
import { activeMortalityAssessmentSelector, customCurvesSelector, shouldSaveSelector } from "reducers";
import GenderSelect from "../../../components/forms/GenderSelect";
import { partial } from "../../../utils";
import * as notifications from "notifications";
import { editMortalityAssessment, generateCustomCurve, selectMortalityAssessment, unsetShouldSave } from "actions";
import { MinimalButton } from "@react-pdf-viewer/core";
import { ButtonConfirm } from "../../../components/buttons";
import { FaTable } from "react-icons/all";

const MortalityAssessmentSummaryForm = ({ mortalityAssessmentInfo, ...props }) => {
  if (!mortalityAssessmentInfo) {
    return null;
  }

  const submitRef = React.useRef();
  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  const initialValues = {
    first_name: mortalityAssessmentInfo.first_name,
    last_name: mortalityAssessmentInfo.last_name,
    gender: mortalityAssessmentInfo.gender,
    date_of_birth: mortalityAssessmentInfo.date_of_birth,
    social_security: mortalityAssessmentInfo.social_security,
    case_submission_id: mortalityAssessmentInfo.case_submission_id,
    ma_id: mortalityAssessmentInfo.ma_id,
    start_date: mortalityAssessmentInfo.start_date,
    end_date: mortalityAssessmentInfo.end_date,
    date: mortalityAssessmentInfo.date,
    age: `${Number(mortalityAssessmentInfo.age).toFixed(2)}`,
    curve_tobacco_use: mortalityAssessmentInfo.curve_tobacco_use,
    curve_base_table: mortalityAssessmentInfo.curve_base_table,
    curve_mortality_multiplier: mortalityAssessmentInfo.curve_mortality_multiplier,
    curve_mortality_improvement: mortalityAssessmentInfo.curve_mortality_improvement,
    mortality_wear_off: mortalityAssessmentInfo.mortality_wear_off,
    custom_adjustment: mortalityAssessmentInfo.custom_adjustment,
    vbt_mean: mortalityAssessmentInfo.vbt_mean,
    mean_months: mortalityAssessmentInfo.mean_months,
    median_months: mortalityAssessmentInfo.median_months,
    date_created: mortalityAssessmentInfo.date_created,
    created_by_name: mortalityAssessmentInfo.created_by_name,
    last_edit_date: mortalityAssessmentInfo.last_edit_date,
    last_edited_by_name: mortalityAssessmentInfo.last_edited_by_name,
  };

  const mortalityTableOptions = [];
  for (const customCurve of props.customCurves) {
    if (initialValues.gender && customCurve.gender !== initialValues.gender) {
      continue;
    }
    if (initialValues.curve_tobacco_use !== undefined && customCurve.tobacco_use !== initialValues.curve_tobacco_use) {
      continue;
    }
    mortalityTableOptions.push({
      value: customCurve.version,
      label: `${customCurve.publisher} - ${customCurve.version}`,
    });
  }
  console.log("mortalityTableOptions", mortalityTableOptions);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        const method = partial(props.editMortalityAssessment, mortalityAssessmentInfo.id);

        try {
          await method(values);
          notifications.success("Mortality Assessment saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Mortality Assessment");
        }
        props.unsetShouldSave();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        const generateCurve = async () => {
          await props.generateCustomCurve(mortalityAssessmentInfo.id, values);
          props.selectMortalityAssessment(mortalityAssessmentInfo?.id);
        };

        return (
          <Form onSubmit={handleSubmit} {...props}>
            <Collapsible title="Overview" style={{ marginBottom: 10, paddingBottom: 5 }}>
              <Form.Row>
                <TextInput label="Insured Name" name="first_name" md={3} disabled />
                <TextInput label="Last Name" name="last_name" md={3} disabled />
                <GenderSelect disabled />
                <DateInput label="Date of Birth" name="date_of_birth" disabled />
                <SSNInput label="SSN" disabled />
              </Form.Row>
              <Form.Row>
                <HyperLinkText
                  label="Case ID"
                  name="case_submission_id"
                  url={`/cases/${mortalityAssessmentInfo.case_id}/policy/`}
                  copy
                />
                <TextInput label="MA ID" name="ma_id" disabled />
                <TextInput label="MR Start Date" name="start_date" placeholder="Will pull from CGPT Output" disabled />
                <TextInput label="MR End Date" name="end_date" placeholder="Will pull from CGPT Output" disabled />
              </Form.Row>
              <Form.Row>
                <DateInput label="MA Date" name="date" md={3} />
                <TextInput label="MA Age" name="age" disabled md={3} />
                <BooleanSelect label="Tobacco Status" name="curve_tobacco_use" md={3} />
              </Form.Row>
              <Form.Row>
                <Select label="Mortality Table" name="curve_base_table" options={mortalityTableOptions} />
                <TextInput label="Mortality MM" name="curve_mortality_multiplier" placeholder="Enter %" append="%" />
                <TextInput
                  label="Mortality Improvement"
                  name="curve_mortality_improvement"
                  placeholder="Enter %"
                  append="%"
                />
                <TextInput label="Mortality Wear Off" name="mortality_wear_off" placeholder="Enter %" append="%" />
              </Form.Row>
              <Form.Row>
                <BooleanSelect label="Custom Adjustment" name="custom_adjustment" />
                <TextInput label="VBT Back Solve" name="vbt_mean" append="%" disabled />
                <TextInput label="Mean Months" name="mean_months" disabled />
                <TextInput label="Median Months" name="median_months" disabled />
              </Form.Row>
              <Form.Row>
                <DateTimeInput label="Date Created" name="date_created" disabled />
                <TextInput label="Created By" name="created_by_name" disabled />
                <DateTimeInput label="Last Edit Date" name="last_edit_date" disabled />
                <TextInput label="Last Edited By" name="last_edited_by_name" disabled />
              </Form.Row>
              {window.location.pathname.includes("curve") && (
                <>
                  <RowSeparator />
                  <Form.Row>
                    <ButtonConfirm onClick={generateCurve} type="button">
                      <FaTable />
                      Generate Curve
                    </ButtonConfirm>
                  </Form.Row>
                </>
              )}
              <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
            </Collapsible>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    shouldSave: shouldSaveSelector(state),
    mortalityAssessmentInfo: activeMortalityAssessmentSelector(state),
    customCurves: customCurvesSelector(state),
  };
};

export default connect(mapStateToProps, {
  editMortalityAssessment,
  unsetShouldSave,
  generateCustomCurve,
  selectMortalityAssessment,
})(MortalityAssessmentSummaryForm);
