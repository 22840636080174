import * as types from "actions/types";
import advisorhq from "apis/advisorhq";
import { fetchFundersForAuction } from "./funders";
import { fetchMarketAuctionActivities } from "./auctions";
import { hideModal } from "../modals";
import {
  SEND_FUNDER_EMAIL_MODAL,
  SEND_LEAD_EMAIL_MODAL,
  SEND_MARKET_ACTIVITY_EMAIL_MODAL,
} from "../../components/modals";
import * as generic from "../generic";

/* ******************* OLD FUNCTIONS - TODO: REVIEW ************************************ */

export const sendFunderEmail = (values, auctionId) => async (dispatch, getState) => {
  const formData = new FormData();

  for (let key in values) {
    formData.append(key, values[key]);
  }

  if (values.files) {
    for (let i = 0; i < values.files.length; i++) {
      formData.append("files[]", values.files[i], values.files[i].name);
    }
  }

  // sends email
  let response;
  try {
    response = await advisorhq.post(`/auctions/api/send_funder_email_template/${auctionId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    return error.response;
  }

  // Re-fetch funders with status to have them automatically updated.
  dispatch(fetchFundersForAuction(auctionId));

  // Clear selected funders & close the modal
  dispatch(hideModal(SEND_FUNDER_EMAIL_MODAL));
  dispatch({ type: types.DESELECT_ALL_FUNDERS });
  return response;
};

export const sendMarketActivityEmail = (values, auctionId) => async (dispatch, getState) => {
  const formData = new FormData();

  for (let key in values) {
    formData.append(key, values[key]);
  }

  if (values.files) {
    for (let i = 0; i < values.files.length; i++) {
      formData.append("files[]", values.files[i], values.files[i].name);
    }
  }

  // sends email
  try {
    await advisorhq.post(`/auctions/api/send_market_activity_email/${auctionId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    return error.response.data;
  }

  // blank in the selected auction activity & close the modal
  dispatch({ type: types.SELECT_AUCTION_ACTIVITY, payload: null });
  dispatch(hideModal(SEND_MARKET_ACTIVITY_EMAIL_MODAL));

  // Re-fetch funders with status to have them automatically updated.
  dispatch(fetchMarketAuctionActivities());
};

export const sendEmailNotification = values => async (dispatch, getState) => {
  try {
    await advisorhq.post(`/life_settlement/api/send_mail_notification/`, values);
  } catch (error) {
    return error.response;
  }
};

export const sendLeadEmail = values => async (dispatch, getState) => {
  const formData = new FormData();

  for (let key in values) {
    formData.append(key, values[key]);
  }

  if (values.files) {
    for (let i = 0; i < values.files.length; i++) {
      formData.append("files[]", values.files[i], values.files[i].name);
    }
  }

  // sends email
  try {
    await advisorhq.post(`/api/send_lead_email_template/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    return error.response;
  }

  // Clear selected accounts & close the modal
  dispatch(hideModal(SEND_LEAD_EMAIL_MODAL));
  dispatch({ type: types.DESELECT_ALL_LEAD_LIST });
};

export const setActiveEmailTemplate = name => async (dispatch, getState) => {
  dispatch({ type: types.SET_EMAIL_TEMPLATE, payload: name });
};

export const setActiveEmailTemplateType = type => async (dispatch, getState) => {
  dispatch({ type: types.SET_EMAIL_TEMPLATE_TYPE, payload: type });
};

/* ******************************************************* */

export const createEmailTemplate = (...props) => generic.createEntity("emailTemplate", ...props);
export const editEmailTemplate = (...props) => generic.editEntity("emailTemplate", ...props);
export const fetchEmailTemplates = () => generic.fetchEntityList("emailTemplate");
export const deleteEmailTemplate = (...props) => generic.deleteEntity("emailTemplate", ...props);
export const uploadEmailTemplateFile = (...props) => generic.uploadFile("emailTemplate", ...props);
export const deleteEmailTemplateFile = (...props) => generic.deleteFile("emailTemplate", ...props);
