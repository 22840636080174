import { FETCH_CASE_PDF, STORE_MAILING_DATA } from "../types.js";
import { activeCaseIdSelector } from "reducers";
import advisorhq from "apis/advisorhq";
import { LOADING } from "actions/types";

export const generateCasePdf = () => async (dispatch, getState) => {
  // pass in current case id as param
  const activeCaseId = activeCaseIdSelector(getState());
  // const agencyId = activeAgencySelector(getState()).id;
  dispatch({ type: LOADING, payload: { caseStatus: true } });
  const mailing_data = await advisorhq.get(
    `/life_settlement/pdf_view_mailing_data/` + activeCaseId + "/",
  );
  dispatch({ type: STORE_MAILING_DATA, payload: mailing_data.data });
  const response = await advisorhq.get(`/life_settlement/pdf/` + activeCaseId + "/");
  dispatch({ type: FETCH_CASE_PDF, payload: response.data });
  dispatch({ type: LOADING, payload: { caseStatus: false } });
};
