import React from "react";
import { connect } from "react-redux";

import { selectAuctionActivity, fetchFunderAuctionActivities, showModal } from "actions";
import {
  activeAuctionActivitySelector,
  managementChoicesSelector,
  auctionIdSelector,
  funderAuctionActivitiesSelector,
  funderSearchStringSelector,
} from "reducers";
import { AUCTION_FUNDER_ACTIVITY_MODAL, DELETE_FUNDER_AUCTION_ACTIVITY_MODAL } from "components/modals";
import Table, { choiceCell, currencyCell, datetimeCell, sortDateCell } from "components/table";
import { activityTypeChoices, REVENUE_MODEL_CHOICES } from "../../../../constants";
import IconLink from "../../../../components/IconLink";
import { FaDownload, FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { getViewMode } from "../../../../utils";
import IconButton from "../../../../components/IconButton";

const FunderActivitiesTable = ({ fetchFunderAuctionActivities, auctionActivities, funderSearchString, ...props }) => {
  const [shownActivities, setShownActivities] = React.useState([]);

  React.useEffect(() => {
    if (props.auctionId !== undefined) {
      // Wait for the auction to be fetched (we need the id) before fetching the
      // activities
      fetchFunderAuctionActivities();
    }
  }, [fetchFunderAuctionActivities, props.auctionId]);

  // Update shown funders when funders list changes
  React.useEffect(() => {
    setShownActivities(auctionActivities);
  }, [JSON.stringify(auctionActivities)]);

  // Filter funders
  React.useEffect(() => {
    let ret = auctionActivities;
    if (funderSearchString !== null) {
      ret = auctionActivities.filter(activity => {
        let funderName = props.funderChoices.getLabel(activity.funder) || "";
        return funderName.toLowerCase().includes(funderSearchString.toLowerCase());
      });
    }
    setShownActivities(ret);
  }, [funderSearchString, auctionActivities]);
  const isViewMode = getViewMode();

  const columns = [
    {
      Header: "Action",
      className: "action",
      Cell: ({ row }) => {
        const activityId = row.original.id;
        const activity = auctionActivities.find(activity => activity.id === activityId);
        if (activity === undefined) {
          return <></>;
        }

        return (
          <>
            <IconButton
              Icon={isViewMode ? FaEye : FaEdit}
              tooltip={isViewMode ? "View" : "Edit"}
              iconConfig={{ className: "table--action-icon" }}
              onClick={() => {
                props.selectAuctionActivity(activityId);
                props.showModal(AUCTION_FUNDER_ACTIVITY_MODAL);
              }}
            />
            {activity.file && (
              <IconLink
                Icon={FaDownload}
                iconConfig={{ className: "table--action-icon" }}
                tooltip="Download File"
                onClick={() => {
                  const url = `/auctions/api/market_activities/${activityId}/download_file/`;
                  props.downloadFile(url, activity.file.name);
                }}
              />
            )}
            {activity.file && (
              <IconLink
                IIcon={FaEye}
                iconConfig={{ className: "table--action-icon" }}
                tooltip="Preview"
                onClick={() => window.open(activity.file.url, "_blank")}
              />
            )}
            {!isViewMode && (
              <IconLink
                Icon={FaTrash}
                iconConfig={{ className: "table--action-icon red" }}
                tooltip="Delete Funder Activity"
                onClick={() => {
                  props.showModal(DELETE_FUNDER_AUCTION_ACTIVITY_MODAL, {
                    id: activityId,
                  });
                }}
              />
            )}
          </>
        );
      },
    },
    {
      Header: "Funder",
      accessor: "funder",
      className: "padded-table-cells",
      Cell: ({ row }) => {
        const funder_label = props.funderChoices.getLabel(row.original.funder) || "";
        if (funder_label) {
          return (
            <a href={`/funders/${row.original.funder}/overview/`} target="_blank" rel="noreferrer">
              {funder_label}
            </a>
          );
        } else {
          return "";
        }
      },
    },
    {
      Header: "Funder Code",
      Cell: ({ row }) => {
        if (!row.original.funder) {
          return "";
        }
        const paddedFunderId = String(row.original.funder).padStart(3, "0");
        return `LR-FC${paddedFunderId}`;
      },
    },
    {
      Header: "Activity",
      accessor: "type",
      Cell: choiceCell(activityTypeChoices),
      className: "padded-table-cells",
    },
    {
      Header: "Activity Date",
      accessor: "date",
      sortType: sortDateCell,
      Cell: datetimeCell,
      className: "padded-table-cells",
    },
    {
      Header: "Total Offer",
      accessor: "total_offer",
      className: "padded-table-cells",
      Cell: currencyCell,
    },
    {
      Header: "Conversion Compensation",
      accessor: "conversion_compensation",
      className: "padded-table-cells",
      Cell: currencyCell,
    },
    {
      Header: "Revenue Model",
      accessor: "revenue_model",
      className: "padded-table-cells",
      Cell: ({ row }) => {
        const revenue_model_data = REVENUE_MODEL_CHOICES.find(choice => choice.value === row.original.revenue_model);
        return revenue_model_data ? revenue_model_data.label : "";
      },
    },
    {
      Header: "RDB Offer",
      accessor: "rdb_offer",
      className: "padded-table-cells",
    },
    {
      Header: "Offer Expiration",
      accessor: "funder_offer_expiration_date",
      className: "padded-table-cells",
    },
    {
      Header: "Winning Bid",
      accessor: d => {
        return d.funder_winning_bid ? "Yes" : "No";
      },
      Cell: ({ row }) => {
        return (
          <div className="text-wrap">
            <p style={{ textAlign: "center", marginBottom: 0 }}>{row.original.funder_winning_bid ? "Yes" : "No"} </p>
          </div>
        );
      },
    },
    {
      Header: "Funder Activity Notes",
      accessor: "notes",
      className: "padded-table-cells",
      Cell: ({ row }) => {
        return (
          <div className="text-wrap">
            {row.original.notes
              .replace(/(<([^>]+)>)/gi, " ")
              .replaceAll("&amp", " ")
              .replaceAll("&nbsp;", " ")}{" "}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      data={shownActivities}
      defaultSort={true}
      paginated={false}
      emptyMessage="No records found"
      sortBy={[{ id: "date", desc: true }]}
      rowIsActive={row =>
        row.original && props.activeAuctionActivity && row.original.id === props.activeAuctionActivity.id
      }
      tableClass={"tableFixedNotPaginated"}
    />
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const funderAuctionActivities = funderAuctionActivitiesSelector(state);

  return {
    auctionId: auctionIdSelector(state),
    activeAuctionActivity: activeAuctionActivitySelector(state),
    auctionActivities: funderAuctionActivities,
    funderChoices: choices.funders,
    providerChoices: choices.case_providers,
    funderSearchString: funderSearchStringSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchFunderAuctionActivities,
  selectAuctionActivity,
  showModal,
})(FunderActivitiesTable);
