import React from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import ReactSelect from "react-select";

import { SELECT_AGENCY_MODAL } from ".";
import { ButtonCancel, ButtonConfirm } from "components/buttons";
import { hideModal, selectAgency, showModal } from "actions";
import { activeAgencySelector, agenciesSelector } from "reducers";

const SelectAgencyModal = props => {
  const [ddAgency, ddSelectAgency] = React.useState(null);

  return (
    <Modal
      show
      centered
      backdrop="static" // don't hide the modal when clicking outside
      className="modal modal--confirm"
      onHide={() => props.hideModal(SELECT_AGENCY_MODAL)}
    >
      <Modal.Header>
        <h4 className="modal__header">Select Agency</h4>
      </Modal.Header>
      <Modal.Body>
        <ReactSelect
          options={props.agencies.map(agency => ({
            label: agency.name,
            value: agency.id,
          }))}
          onChange={option => ddSelectAgency({ id: option.value, name: option.label })}
        />
      </Modal.Body>
      <Modal.Footer className="modal__footer">
        <ButtonConfirm
          onClick={() => {
            props.selectAgency(ddAgency);
            props.hideModal(SELECT_AGENCY_MODAL);
          }}
        >
          Confirm
        </ButtonConfirm>

        {/* Only allow to cancel agency selection when there is no active agency already.
            Otherwise force the user to select an agency */}
        {props.activeAgency !== null && (
          <ButtonCancel
            onClick={() => {
              props.hideModal(SELECT_AGENCY_MODAL);
            }}
          >
            Cancel
          </ButtonCancel>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = state => {
  return {
    activeAgency: activeAgencySelector(state),
    agencies: agenciesSelector(state),
  };
};

export default connect(mapStateToProps, { hideModal, selectAgency, showModal })(
  SelectAgencyModal,
);
