import React from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import FullPageModal from "components/modals/FullPageModal";
import { EMAIL_PREVIEW_MODAL } from "components/modals";
import { EditorField, FormCollapsible } from "components/forms";
import { Form } from "react-bootstrap";

const EmailPreviewForm = emailBody => {
  console.log("emailBody", emailBody);
  return (
    <Formik
      enableReinitialize
      initialValues={emailBody}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <Form.Row>
            <EditorField name="emailBody" disabled />
          </Form.Row>
        );
      }}
    </Formik>
  );
};

const EmailPreviewModal = ({ emailBody, ...props }) => {
  return (
    <FullPageModal modalType={EMAIL_PREVIEW_MODAL}>
      <FormCollapsible title="Sent Email">
        <EmailPreviewForm emailBody={emailBody} />
      </FormCollapsible>
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(EmailPreviewModal);
