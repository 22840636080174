import React from "react";
import { connect } from "react-redux";
import { activeAccountSelector } from "reducers";
import { Card } from "react-bootstrap";
import { fetchLifeSettlementChoices, fetchManagerAgents, selectAccountTask, showModal } from "actions";
import AccountCasesTable from "./AccountCasesTable";
import AccountCasesFiltersForm from "./AccountCasesFiltersForm";

const AccountCasesPage = ({ accountInfo, ...props }) => {
  React.useEffect(() => {
    props.fetchManagerAgents();
    props.fetchLifeSettlementChoices();
    if (accountInfo && accountInfo.name) {
      document.title = `LifeRoc | ${accountInfo.name} | Account Cases`;
    }
  }, [accountInfo]);

  return (
    <div className={`section-card shadowed case-section`} style={{ marginTop: "5.5rem" }}>
      <Card className={"case-info"}>
        <Card.Body>
          <AccountCasesFiltersForm accountInfo={accountInfo} />
          <AccountCasesTable />
        </Card.Body>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    accountInfo: activeAccountSelector(state),
  };
};

export default connect(mapStateToProps, {
  selectAccountTask,
  fetchManagerAgents,
  fetchLifeSettlementChoices,
  showModal,
})(AccountCasesPage);
