import {
  FETCH_ACCOUNT_RECORDS,
  FETCH_ACCOUNTS,
  FETCH_ALL_LIFE_EXPECTANCIES,
  FETCH_CASE_RECORDS,
  FETCH_CASE_REQUIREMENT_RECORDS,
  FETCH_LEAD_RECORDS,
  FETCH_LEADS,
  FETCH_LIFE_SETTLEMENT_CASES,
  FETCH_PARAMETERS,
  FETCH_RECORDS,
  SET_ACCOUNTS_PAGINATION_DATA,
  SET_CASES_PAGINATION_DATA,
  SET_LEADS_PAGINATION_DATA,
  SET_LES_PAGINATION_DATA,
  SET_PARAMETERS_PAGINATION_DATA,
  SET_RECORDS_PAGINATION_DATA,
  STORE_ACCOUNT_RECORDS_PAGINATION_DATA,
  STORE_CASE_RECORDS_PAGINATION_DATA,
  STORE_CASE_REQUIREMENT_RECORDS_PAGINATION_DATA,
  STORE_LEAD_RECORDS_PAGINATION_DATA,
} from "../types";
import {
  accountsCountSelector,
  caseCountSelector,
  currentPageAccountsSelector,
  currentPageCaseSelector,
  currentPageLeSelector,
  currentPageRecordsSelector,
  getPagesAccountsCount,
  getPagesCaseCount,
  getPagesLeCount,
  getPagesRecordsCount,
  leCountSelector,
  nextPageAccountsSelector,
  nextPageCaseSelector,
  nextPageLeSelector,
  nextPageRecordsSelector,
  previousPageAccountsSelector,
  previousPageCaseSelector,
  previousPageLeSelector,
  previousPageRecordsSelector,
  recordsCountSelector,
  storedAccountsFiltersSelector,
  storedCasesFiltersSelector,
  storedLeFiltersSelector,
  storedRecordsFiltersSelector,
  leadsCountSelector,
  currentPageLeadsSelector,
  getPagesLeadsCount,
  nextPageLeadsSelector,
  previousPageLeadsSelector,
  storedLeadsFiltersSelector,
  storedParametersFiltersSelector,
  nextPageParametersSelector,
  previousPageParametersSelector,
  currentPageParametersSelector,
  parametersCountSelector,
  getPagesParametersCount,
  storedCaseRecordsFiltersSelector,
  storedCaseRequirementRecordsFiltersSelector,
  storedAccountRecordsFiltersSelector,
  storedLeadRecordsFiltersSelector,
  nextPageCaseRecordsSelector,
  previousPageCaseRecordsSelector,
  currentPageCaseRecordsSelector,
  caseRecordsCountSelector,
  getPagesCaseRecordsCount,
  nextPageCaseRequirementRecordsSelector,
  previousPageCaseRequirementRecordsSelector,
  currentPageCaseRequirementRecordsSelector,
  caseRequirementRecordsCountSelector,
  getPagesCaseRequirementRecordsCount,
  nextPageAccountRecordsSelector,
  previousPageAccountRecordsSelector,
  currentPageAccountRecordsSelector,
  accountRecordsCountSelector,
  getPagesAccountRecordsCount,
  nextPageLeadRecordsSelector,
  previousPageLeadRecordsSelector,
  currentPageLeadRecordsSelector,
  leadRecordsCountSelector,
  getPagesLeadRecordsCount,
} from "../../reducers";
import { ACTIVE_SECTION_TO_TASK_TAB_MAP } from "../../pages/records/constants";

export const ENDPOINTS_TO_URL_MAPPING = {
  CASES: "/life_settlement/api/cases/v2/",
  LIFE_EXPECTANCY: "/underwriting/api/life_expectancies/",
  TASKS: "/life_settlement/api/tasks/",
  CASE_TASKS: "/life_settlement/api/tasks/",
  CASE_REQUIREMENT_TASKS: "/life_settlement/api/tasks/",
  ACCOUNT_TASKS: "/life_settlement/api/tasks/",
  LEAD_TASKS: "/life_settlement/api/tasks/",
  ACCOUNTS: "/api/account/",
  LEADS: "/leads/api/lead/",
  PARAMETERS: "/api/parameter/",
};

export const ENDPOINTS_TO_ACTION_TYPES_MAPPING = {
  CASES: FETCH_LIFE_SETTLEMENT_CASES,
  LIFE_EXPECTANCY: FETCH_ALL_LIFE_EXPECTANCIES,
  TASKS: FETCH_RECORDS,
  CASE_TASKS: FETCH_CASE_RECORDS,
  CASE_REQUIREMENT_TASKS: FETCH_CASE_REQUIREMENT_RECORDS,
  ACCOUNT_TASKS: FETCH_ACCOUNT_RECORDS,
  LEAD_TASKS: FETCH_LEAD_RECORDS,
  ACCOUNTS: FETCH_ACCOUNTS,
  LEADS: FETCH_LEADS,
  PARAMETERS: FETCH_PARAMETERS,
};

export const ENDPOINTS_TO_PAGINATION_ACTION_TYPES_MAPPING = {
  CASES: SET_CASES_PAGINATION_DATA,
  LIFE_EXPECTANCY: SET_LES_PAGINATION_DATA,
  TASKS: SET_RECORDS_PAGINATION_DATA,
  CASE_TASKS: STORE_CASE_RECORDS_PAGINATION_DATA,
  CASE_REQUIREMENT_TASKS: STORE_CASE_REQUIREMENT_RECORDS_PAGINATION_DATA,
  ACCOUNT_TASKS: STORE_ACCOUNT_RECORDS_PAGINATION_DATA,
  LEAD_TASKS: STORE_LEAD_RECORDS_PAGINATION_DATA,
  ACCOUNTS: SET_ACCOUNTS_PAGINATION_DATA,
  LEADS: SET_LEADS_PAGINATION_DATA,
  PARAMETERS: SET_PARAMETERS_PAGINATION_DATA,
};

export const ENDPOINTS_TO_LOADING_VALUE_MAPPING = {
  CASES: "cases",
  LIFE_EXPECTANCY: "le",
  TASKS: "tasks",
  CASE_TASKS: "case-tasks",
  CASE_REQUIREMENT_TASKS: "case-requirement-tasks",
  ACCOUNT_TASKS: "account-tasks",
  LEAD_TASKS: "lead-tasks",
  ACCOUNTS: "accounts",
  LEADS: "leads",
  PARAMETERS: "parameters",
};

export const ENDPOINTS_TO_FILTERS_SELECTOR_MAPPING = {
  CASES: storedCasesFiltersSelector,
  LIFE_EXPECTANCY: storedLeFiltersSelector,
  TASKS: storedRecordsFiltersSelector,
  CASE_TASKS: storedCaseRecordsFiltersSelector,
  CASE_REQUIREMENT_TASKS: storedCaseRequirementRecordsFiltersSelector,
  ACCOUNT_TASKS: storedAccountRecordsFiltersSelector,
  LEAD_TASKS: storedLeadRecordsFiltersSelector,
  ACCOUNTS: storedAccountsFiltersSelector,
  LEADS: storedLeadsFiltersSelector,
  PARAMETERS: storedParametersFiltersSelector,
};

export const ENDPOINTS_TO_ACTIVE_SECTIONS_MAPPING = {
  CASES: "cases",
  LIFE_EXPECTANCY: "lifeexpectancy",
  TASKS: "tasks",
  CASE_TASKS: "case-tasks",
  CASE_REQUIREMENT_TASKS: "case-requirement-tasks",
  ACCOUNT_TASKS: "account-tasks",
  LEAD_TASKS: "lead-tasks",
  ACCOUNTS: "accounts",
  LEADS: "leads",
  PARAMETERS: "parameters",
};

// DETERMINES THE STARTING POINT X - Y OF Z RESULTS IN PAGINATION
export const ENDPOINTS_TO_INITIAL_PAGE_RESULTS_SELECTOR = {
  CASES: 1,
  LIFE_EXPECTANCY: 2,
  TASKS: "tasks",
  ACCOUNTS: "accounts",
};

export const ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING = {
  CASES: {
    NEXT: nextPageCaseSelector,
    PREVIOUS: previousPageCaseSelector,
    CURRENT: currentPageCaseSelector,
    COUNT: caseCountSelector,
    PAGES: getPagesCaseCount,
  },
  LIFE_EXPECTANCY: {
    NEXT: nextPageLeSelector,
    PREVIOUS: previousPageLeSelector,
    CURRENT: currentPageLeSelector,
    COUNT: leCountSelector,
    PAGES: getPagesLeCount,
  },
  TASKS: {
    NEXT: nextPageRecordsSelector,
    PREVIOUS: previousPageRecordsSelector,
    CURRENT: currentPageRecordsSelector,
    COUNT: recordsCountSelector,
    PAGES: getPagesRecordsCount,
  },
  CASE_TASKS: {
    NEXT: nextPageCaseRecordsSelector,
    PREVIOUS: previousPageCaseRecordsSelector,
    CURRENT: currentPageCaseRecordsSelector,
    COUNT: caseRecordsCountSelector,
    PAGES: getPagesCaseRecordsCount,
  },
  CASE_REQUIREMENT_TASKS: {
    NEXT: nextPageCaseRequirementRecordsSelector,
    PREVIOUS: previousPageCaseRequirementRecordsSelector,
    CURRENT: currentPageCaseRequirementRecordsSelector,
    COUNT: caseRequirementRecordsCountSelector,
    PAGES: getPagesCaseRequirementRecordsCount,
  },
  ACCOUNT_TASKS: {
    NEXT: nextPageAccountRecordsSelector,
    PREVIOUS: previousPageAccountRecordsSelector,
    CURRENT: currentPageAccountRecordsSelector,
    COUNT: accountRecordsCountSelector,
    PAGES: getPagesAccountRecordsCount,
  },
  LEAD_TASKS: {
    NEXT: nextPageLeadRecordsSelector,
    PREVIOUS: previousPageLeadRecordsSelector,
    CURRENT: currentPageLeadRecordsSelector,
    COUNT: leadRecordsCountSelector,
    PAGES: getPagesLeadRecordsCount,
  },
  ACCOUNTS: {
    NEXT: nextPageAccountsSelector,
    PREVIOUS: previousPageAccountsSelector,
    CURRENT: currentPageAccountsSelector,
    COUNT: accountsCountSelector,
    PAGES: getPagesAccountsCount,
  },
  LEADS: {
    NEXT: nextPageLeadsSelector,
    PREVIOUS: previousPageLeadsSelector,
    CURRENT: currentPageLeadsSelector,
    COUNT: leadsCountSelector,
    PAGES: getPagesLeadsCount,
  },
  PARAMETERS: {
    NEXT: nextPageParametersSelector,
    PREVIOUS: previousPageParametersSelector,
    CURRENT: currentPageParametersSelector,
    COUNT: parametersCountSelector,
    PAGES: getPagesParametersCount,
  },
};

export function getEndpointByFetchActionType(value) {
  for (const key in ENDPOINTS_TO_ACTION_TYPES_MAPPING) {
    if (ENDPOINTS_TO_ACTION_TYPES_MAPPING[key] === value) {
      return key;
    }
  }
  return undefined;
}

export function getActiveSectionByTaskType(value) {
  for (const key in ACTIVE_SECTION_TO_TASK_TAB_MAP) {
    if (ACTIVE_SECTION_TO_TASK_TAB_MAP[key] === value) {
      return key;
    }
  }
  return undefined;
}
