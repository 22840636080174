import React, { useRef } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";
import * as Yup from "yup";

import { Select, FormCollapsible, SubmitButton, TextInput } from "components/forms";
import {
  createParameterSynonym,
  createSynonym,
  editParameterSynonym,
  editSynonym,
  fetchParametersChoices,
  selectSynonym,
} from "actions";
import { activeParameterIdSelector, activeSynonymSelector, userChoicesSelector } from "reducers";
import { partial } from "../../../utils";
import MultiSelectTextInput from "components/forms/MultiSelectTextInput";

const SynonymForm = ({ activeParameterId, activeSynonym, fetchParametersChoices, ...props }) => {
  const [parametersChoices, setParameterChoices] = React.useState([]);

  if (parametersChoices === []) {
    return null;
  }

  React.useEffect(() => {
    fetchParametersChoices(setParameterChoices);
  }, []);

  const handleSoftParameterChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedValues = selectedOptions.map(o => o.value).join(",");
      setSoftParameters(commaSeparatedValues);
    } else {
      setSoftParameters([]);
    }
  };

  const handleHardParameterChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedValues = selectedOptions.map(o => o.value).join(",");
      setHardParameters(commaSeparatedValues);
    } else {
      setHardParameters([]);
    }
  };

  let soft_parameters_initial = [];
  let hard_parameters_initial = [];

  if (activeSynonym && activeSynonym.soft_parameters) {
    let parameters_list = activeSynonym && activeSynonym.soft_parameters;
    for (let parameter_id of parameters_list) {
      let paramChoice = parametersChoices.find(e => e.value === parameter_id);
      if (paramChoice) {
        soft_parameters_initial.push({ value: paramChoice.value, label: paramChoice.label });
      }
    }
  }

  if (activeSynonym && activeSynonym.hard_parameters) {
    let parameters_list = activeSynonym && activeSynonym.hard_parameters;
    for (let parameter_id of parameters_list) {
      let paramChoice = parametersChoices.find(e => e.value === parameter_id);
      if (paramChoice) {
        hard_parameters_initial.push({ value: paramChoice.value, label: paramChoice.label });
      }
    }
  }

  let [softParameters, setSoftParameters] = React.useState();
  let [hardParameters, setHardParameters] = React.useState();

  // This condition avoids eager render with empty parameters_initial
  if (activeSynonym && activeSynonym.soft_parameters && soft_parameters_initial.length === 0) {
    return null;
  }
  if (activeSynonym && activeSynonym.hard_parameters && hard_parameters_initial.length === 0) {
    return null;
  }

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        name: activeSynonym?.name,
        softParameters,
        hardParameters,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        let method;
        if (activeParameterId) {
          method = isEmpty(activeSynonym)
            ? props.createParameterSynonym
            : partial(props.editParameterSynonym, activeSynonym.id);
        } else {
          method = isEmpty(activeSynonym) ? props.createSynonym : partial(props.editSynonym, activeSynonym.id);
        }
        try {
          await method({
            ...values,
            softParameters,
            hardParameters,
          });
        } catch (error) {
          setErrors(error.response.data);
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Synonym Detail">
            <Form.Row style={{ padding: 0 }}>
              {isEmpty(activeSynonym) ? (
                <MultiSelectTextInput label="Name" name="name" />
              ) : (
                <TextInput label="Name" name="name" />
              )}
              <Select
                label="Soft Parameter"
                name="soft_parameters"
                options={parametersChoices}
                isMulti={true}
                onChange={handleSoftParameterChange}
                defaultValue={soft_parameters_initial}
              />
              <Select
                label="Hard Parameters"
                name="hard_parameters"
                options={parametersChoices}
                isMulti={true}
                onChange={handleHardParameterChange}
                defaultValue={hard_parameters_initial}
              />
            </Form.Row>
            <SubmitButton />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    activeSynonym: activeSynonymSelector(state),
    activeParameterId: activeParameterIdSelector(state),
    userChoices: userChoicesSelector(state),
    userId: state.auth.user_id,
  };
};

export default connect(mapStateToProps, {
  createSynonym,
  editSynonym,
  createParameterSynonym,
  editParameterSynonym,
  selectSynonym,
  fetchParametersChoices,
})(SynonymForm);
