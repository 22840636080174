import { moneyDisplay } from "../../../../utils";
import { calcRevenueModelValue } from "./PlacementRevenueValue";

export const calculateTotalFaceAmount = tradingCases => {
  if (tradingCases && tradingCases.length > 0) {
    let total_face_amount;
    // reduce function will accumulate the values while adding each iterated item
    // in this case it will just add the face amount starting from 0
    total_face_amount = tradingCases
      .reduce(function (a, b) {
        return a + b.policy.face_amount;
      }, 0)
      .toString();
    if (total_face_amount) {
      return moneyDisplay(total_face_amount);
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const sumOurHighOffer = tradingCases => {
  if (tradingCases && tradingCases.length > 0) {
    let total_our_high_offer;
    // reduce function will accumulate the values while adding each iterated item
    // in this case it will just add the face amount starting from 0
    total_our_high_offer = tradingCases
      .reduce(function (a, b) {
        return a + b.our_high_offer;
      }, 0)
      .toString();
    if (total_our_high_offer) {
      return moneyDisplay(total_our_high_offer);
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const calculateTotalOurRevenue = (tradingCases, provider) => {
  if (tradingCases && tradingCases.length > 0 && provider) {
    let total_our_revenue;
    // reduce function will accumulate the values while adding each iterated item
    // in this case it will just add the "our revenue" starting from 0
    // calculating the "our revenue" for each iterated item using calcOurRevenue
    // a is the accumulated value and b is the iterated value
    total_our_revenue = tradingCases
      .reduce(function (a, b) {
        // before adding the next case revenue, validate if it returns a value
        const revenue_model_value = calcRevenueModelValue(
          b.auction.marketactivity_set,
          b.auction.funderactivity_set,
          provider,
          true,
        );
        if (revenue_model_value) {
          return a + revenue_model_value;
        } else {
          return a;
        }
      }, 0)
      .toString();
    if (total_our_revenue) {
      return moneyDisplay(total_our_revenue);
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const calculateTotalOurOffer = (tradingCases, provider) => {
  if (tradingCases && tradingCases.length > 0 && provider) {
    let total_our_revenue;
    // reduce function will accumulate the values while adding each iterated item
    // in this case it will just add the "our revenue" starting from 0
    // calculating the "our revenue" for each iterated item using calcOurRevenue
    // a is the accumulated value and b is the iterated value
    total_our_revenue = tradingCases
      .reduce(function (a, b) {
        return a + calcRevenueModelValue(b.auction.marketactivity_set, b.auction.funderactivity_set, provider, true);
      }, 0)
      .toString();
    if (total_our_revenue) {
      return moneyDisplay(total_our_revenue);
    } else {
      return "";
    }
  } else {
    return "";
  }
};
