export const validateState = (ownerList, insuredList, jurisdiction, category) => {
  const errorsList = [];
  for (const key in ownerList) {
    if (!(ownerList[key].domicile_state || ownerList[key].state)) {
      errorsList.push("Owner needs a State");
    }
    if (
      ownerList[key].domicile_state === "FL" ||
      ownerList[key].domicile_state === "TX" ||
      ownerList[key].state === "FL" ||
      ownerList[key].state === "TX"
    ) {
      errorsList.push("Owner's state can't be neither Florida or Texas");
    }
  }
  let insured = [];
  if (category === "Insured") {
    insured = insuredList[0];
  } else if (category === "Insured 2") {
    insured = insuredList[1];
  }
  if (!insured.state) {
    errorsList.push("Selected insured needs a State");
  }
  if (insured.state === "FL" || insured.state === "TX") {
    errorsList.push("Selected insured's state can't be neither Florida or Texas");
  }

  if (!jurisdiction) {
    errorsList.push("Case needs a jurisdiction");
  }
  if (jurisdiction === "FL" || jurisdiction === "TX") {
    errorsList.push("This LE Vendor is not approved in this state");
  }
  return errorsList;
};

export const isRedactableCaseFile = caseFile => {
  /*
    Redact all Case Documents with Sub Category: APS, LE
    Redact all Case Documents with FRID: HIPAA
    Redact all Case Documents with Category: Policy
  */
  const redactAPS = caseFile.sub_category === "APS";
  const redactLE = caseFile.sub_category === "LE";
  const redactHIPAA = caseFile.fr_id === "HIPAA" || caseFile.fr_id === "HIPAALR";
  const redactPolicy = caseFile.category === "Policy";
  return redactAPS || redactLE || redactHIPAA || redactPolicy;
};
