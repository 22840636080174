import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { ACCOUNT_HIERARCHY_MODAL, ACCOUNT_HIERARCHY_SPLIT_MODAL } from "components/modals";
import { activeHierarchySelector } from "reducers";
import HierarchyForm from "./HierarchyForm";
import { RecordsTable } from "../../../../components/table";
import HierarchySplitsTable from "./HierarchySplitsTable";
import { selectSplit, showModal } from "../../../../actions";

const HierarchyModal = ({ activeHierarchy, ...props }) => {
  let isSaved = activeHierarchy && activeHierarchy.id;
  const buttonDisabled = activeHierarchy && activeHierarchy.splits && activeHierarchy.splits.length > 1;
  const submitRef = React.useRef();
  let title = isSaved ? "Edit Hierarchy" : "New Hierarchy";

  return (
    <FullPageModal modalType={ACCOUNT_HIERARCHY_MODAL} title={title}>
      <HierarchyForm submitRef={submitRef} />
      <RecordsTable
        title="Hierarchy Splits"
        createButtonLabel="Add Split"
        onCreateClick={() => {
          props.selectSplit(null);
          props.showModal(ACCOUNT_HIERARCHY_SPLIT_MODAL);
        }}
        buttonDisabled={buttonDisabled}
        Table={HierarchySplitsTable}
      />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    activeHierarchy: activeHierarchySelector(state) || {},
  };
};

export default connect(mapStateToProps, {
  selectSplit,
  showModal,
})(HierarchyModal);
