import React, { useRef } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import {
  TextInput,
  FormFilter,
  SubmitSearchButton,
  ClearFiltersButton,
  Select,
  DateInput,
  GenderSelect,
  SSNInput,
  BooleanSelect,
} from "components/forms";
import { fetchManagerAgents, fetchMortalityAssessments, storeMortalityAssessmentsFiltersData } from "actions";
import { userChoicesSelector } from "../../../reducers";
import PercentageInput from "../../../components/forms/PercentageInput";

const MortalityAssessmentFilterForm = ({ fetchMortalityAssessments, ...props }) => {
  React.useEffect(() => {
    props.fetchManagerAgents();
  }, []);

  return (
    <Formik
      initialValues={{
        keyword_search_type: "OR",
      }}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        const filters = {
          ...values,
        };
        props.storeMortalityAssessmentsFiltersData(filters);
        await fetchMortalityAssessments(filters);
        setSubmitting(false);
      }}
      onReset={async (values, { setSubmitting }) => {
        props.storeMortalityAssessmentsFiltersData({});
        await fetchMortalityAssessments();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, resetForm }) => {
        return (
          <FormFilter title={"Mortality Assessment Search"} onSubmit={handleSubmit}>
            <Form.Row>
              <TextInput label="Last Name" name="last_name" />
              <TextInput label="First Name" name="first_name" />
              <GenderSelect />
              <SSNInput />
            </Form.Row>
            <Form.Row>
              <DateInput label="DOB Low" name="dob_low" />
              <DateInput label="DOB High" name="dob_high" />
              <TextInput label="MA Age Low" type="number" name="age_low" />
              <TextInput label="MA Age High" type="number" name="age_high" />
            </Form.Row>
            <Form.Row>
              <PercentageInput label="LE MM % Low" name="le_mm_low" />
              <PercentageInput label="LE MM % High" name="le_mm_low" />
              <TextInput label="Mean LE Low" type="number" name="mean_le_low" />
              <TextInput label="Mean LE High" type="number" name="mean_le_high" />
            </Form.Row>
            <Form.Row>
              <TextInput label="Case ID" name="id" />
              <TextInput label="Related ICD 10 Codes" name="related_icd_10_codes" />
              <TextInput
                label="Text Keyword Search"
                name="keyword_search"
                tooltip='Use "word1 word2" for exact search, * at the beginning as wildcard'
                md={3}
              />
              <Select
                label="Text Keyword Search Type"
                name="keyword_search_type"
                options={[
                  { value: "OR", label: "OR" },
                  { value: "AND", label: "AND" },
                ]}
                md={3}
              />
            </Form.Row>
            <Form.Row>
              <Select
                label="Parameter Category"
                name="parameter_category"
                options={[
                  { value: "Condition", label: "Condition" },
                  { value: "Profile", label: "Profile" },
                  { value: "Treatment", label: "Treatment" },
                  { value: "Test", label: "Test" },
                ]}
              />
              <Select label="Parameter Sub Category" name="parameter_sub_category" options={[]} disabled />
              <TextInput label="Parameter Name" name="parameter_name" />
              <BooleanSelect label="Tobacco Use" name="tobacco_use" />
            </Form.Row>
            <SubmitSearchButton />
            <ClearFiltersButton onClick={resetForm} />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const userChoices = userChoicesSelector(state).getChoices({ sortByLabel: true });
  const searchSectionOptions = [
    { value: "aetiology", label: "Aetiology" },
    { value: "assessment_information", label: "Assessment Information" },
    { value: "clinical_research", label: "Clinical Research" },
    { value: "description", label: "Description" },
    { value: "diagnosis", label: "Diagnosis" },
    { value: "epidemiology", label: "Epidemiology" },
    { value: "favorable_considerations", label: "Favorable Considerations" },
    { value: "prognosis", label: "Prognosis" },
    { value: "risk_assessment", label: "Risk Assessment" },
    { value: "search_terms", label: "Search Terms" },
    { value: "severity", label: "Severity" },
    { value: "symptoms", label: "Signs & Symptoms" },
    { value: "treatment", label: "Treatment" },
    { value: "unfavorable_considerations", label: "Unfavorable Considerations" },
  ];

  return {
    userChoices,
    searchSectionOptions,
  };
};

export default connect(mapStateToProps, {
  fetchMortalityAssessments,
  storeMortalityAssessmentsFiltersData,
  fetchManagerAgents,
})(MortalityAssessmentFilterForm);
