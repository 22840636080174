import { combineReducers } from "redux";

import cases from "./cases";
import selectedCase from "./selectedCase";
import pagination from "./pagination";
import management from "./management";
import funders from "./funders";
import loading from "./loading";
import filters from "./filters";
import other from "./other";

export default combineReducers({
  cases,
  selectedCase,
  pagination,
  management,
  funders,
  loading,
  filters,
  other,
});
