import React from "react";
import { connect } from "react-redux";

import { createEmptyFunderContact, fetchFunderContacts, selectFunderContact } from "../../actions";
import {
  activeFunderContactIdSelector,
  activeFunderContactsSelector,
  activeFunderSelector,
  loadingFunderContactsSelector,
} from "../../reducers";
import FunderContactInfo from "./FunderContactInfo";
import { ButtonCreate } from "../../components/buttons";
import FunderContactForm from "./FunderContactForm";
import { ClipLoader } from "react-spinners";

const FunderContactsPage = ({ funderInfo, ...props }) => {
  React.useEffect(() => {
    if (funderInfo && funderInfo.id) {
      props.fetchFunderContacts(funderInfo.id);
    }
  }, [funderInfo]);

  React.useEffect(() => {
    if (funderInfo && funderInfo.name) {
      document.title = `LifeRoc | ${funderInfo.name} | Funder Contacts`;
    }
  }, [funderInfo]);

  const renderLoading = () => {
    return (
      <div>
        Loading Funder Contacts
        <ClipLoader size={50} css={{ marginLeft: "10px" }} />
      </div>
    );
  };

  const renderFunderContacts = () => {
    return (
      <section id="insured-details" className="scroll-spied">
        {props.funderContacts.map((funderContact, idx) => {
          return (
            <FunderContactInfo
              key={funderContact.id}
              idx={idx + 1}
              info={funderContact}
              isActive={props.activeFunderContactId === funderContact.id}
            />
          );
        })}
      </section>
    );
  };

  return (
    <div style={{ marginTop: 100 }}>
      {props.funderContactsLoading ? renderLoading() : null}
      {renderFunderContacts()}
      <ButtonCreate
        onClick={() => {
          props.createEmptyFunderContact(funderInfo.id);
        }}
        style={{ marginBottom: "2rem" }}
      >
        Add Contact
      </ButtonCreate>
      <FunderContactForm />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    funderInfo: activeFunderSelector(state),
    funderContacts: activeFunderContactsSelector(state),
    activeFunderContactId: activeFunderContactIdSelector(state),
    funderContactsLoading: loadingFunderContactsSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchFunderContacts,
  selectFunderContact,
  createEmptyFunderContact,
})(FunderContactsPage);
