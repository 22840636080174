import React from "react";
import { connect } from "react-redux";

import { setActiveTab, initPagination, showModal } from "actions";
import { ButtonCreate } from "../../../components/buttons";
import MortalityAssessmentFilterForm from "./MortalityAssessmentFilterForm";
import MortalityAssessmentTable from "./MortalityAssessmentTable";
import { MORTALITY_ASSESSMENT_CREATION_MODAL } from "components/modals";
import RiskxPageHeader from "../RiskxPageHeader";

const MortalityAssessmentsPage = props => {
  const [activeSection, setActiveSection] = React.useState("mortality-assessment");
  React.useEffect(() => {
    props.setActiveTab(activeSection);
    props.initPagination();
    setActiveSection(activeSection);
    document.title = "LifeRoc | Mortality Assessments";
  }, []);

  return (
    <>
      <RiskxPageHeader />
      <div className={`section-card shadowed case-section`} style={{ marginTop: "1.5rem" }}>
        <MortalityAssessmentFilterForm />
        <ButtonCreate
          id="create-new-case"
          onClick={() => {
            // props.clearSelectedParameter();
            props.showModal(MORTALITY_ASSESSMENT_CREATION_MODAL);
          }}
          key="create"
        >
          Create Mortality Assessment
        </ButtonCreate>
        <MortalityAssessmentTable />
      </div>
    </>
  );
};

export default connect(null, {
  setActiveTab,
  initPagination,
  showModal,
})(MortalityAssessmentsPage);
