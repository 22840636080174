import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as Yup from "yup";

import { TextInput, PhoneInput, StateSelect, FormCollapsible, SubmitButton } from "components/forms";
import * as notifications from "notifications";
import { editInsuredInfo } from "actions";
import { activeInsuredSelector, insuredSelector } from "reducers";

const InsuredForm = ({ insuredInfo, ...props }) => {
  if (!insuredInfo) {
    return null;
  }
  const { mobile_phone, home_phone, office_phone, state, city, street_address, zipcode, email } = insuredInfo;
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          mobile_phone,
          home_phone,
          office_phone,
          state,
          city,
          street_address,
          zipcode,
          email,
        }}
        validationSchema={Yup.object({
          email: Yup.string().email("Invalid email address").nullable(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await props.editInsuredInfo(insuredInfo.id, values);
            notifications.success("Contact information saved successfully");
          } catch (error) {
            notifications.error("Could not save changes, please verify the form errors.");
            setErrors(error.response.data);
          }
          setSubmitting(false);
        }}
      >
        {formik => {
          return (
            <FormCollapsible
              title="Contact Information"
              onSubmit={formik.handleSubmit}
              className="form shadowed"
              id="contact-information"
              disabled={props.disabled}
            >
              <Form.Row>
                <PhoneInput label="Mobile Phone" name="mobile_phone" isRequired />
                <PhoneInput label="Home Phone" name="home_phone" isRequired />
                <PhoneInput label="Office Phone" name="office_phone" isRequired />
              </Form.Row>
              <Form.Row>
                <TextInput label="Street Address" name="street_address" isRequired />
              </Form.Row>
              <Form.Row>
                <TextInput label="City" name="city" isRequired />
                <StateSelect isRequired />
                <TextInput label="Zip Code" name="zipcode" isRequired />
              </Form.Row>
              <Form.Row>
                <TextInput label="Email Address" name="email" isRequired />
              </Form.Row>
              <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
            </FormCollapsible>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    insuredInfo: ownProps.insuredId ? insuredSelector(state, ownProps.insuredId) : activeInsuredSelector(state),
  };
};

export default connect(mapStateToProps, {
  editInsuredInfo,
})(InsuredForm);
