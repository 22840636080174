import React from "react";
import { connect, useDispatch } from "react-redux";

import { dateCell } from "components/table";
import * as types from "actions/types";
import { fetchFundersForAuction, showModal, selectAuctionActivity, fetchInsured } from "actions";
import {
  auctionIdSelector,
  funderSearchStringSelector,
  fundersForAuctionSelector,
  selectedFundersSelector,
  fundersSortBySelector,
  loadingFundersSelector,
  activeCaseIdSelector,
} from "reducers";
import FundersListTable from "./fundersList/FundersListTable";
import { getViewMode, moneyDisplayShort } from "../../../utils";
import IconLink from "../../../components/IconLink";
import { FaPlusCircle } from "react-icons/fa";
import { AUCTION_FUNDER_ACTIVITY_MODAL } from "../../../components/modals";
import IconButton from "../../../components/IconButton";

const FundersAuctionTable = ({
  auctionId,
  funders,
  fetchFundersForAuction,
  selectedFunders,
  funderSearchString,
  ...props
}) => {
  const dispatch = useDispatch();
  const [shownFunders, setShownFunders] = React.useState([]);

  // Fetch funders
  React.useEffect(() => {
    if (auctionId) {
      fetchFundersForAuction(auctionId);
    }
  }, [auctionId]);

  // Fetch insured - needed for popup
  React.useEffect(() => {
    if (props.activeCaseId) {
      props.fetchInsured(props.activeCaseId);
    }
  }, [props.activeCaseId]);

  // Update shown funders when funders list changes
  React.useEffect(() => {
    setShownFunders(funders);
  }, [JSON.stringify(funders)]);

  // Filter funders
  React.useEffect(() => {
    let ret = funders;
    if (funderSearchString !== null) {
      ret = funders.filter(funder => funder.name.toLowerCase().includes(funderSearchString.toLowerCase()));
    }
    setShownFunders(ret);
  }, [funderSearchString, funders]);
  const isViewMode = getViewMode();

  const columns = [
    {
      Header: "Select",
      // this enables to sort by selected items
      accessor: d => {
        return selectedFunders.indexOf(d.id) !== -1 ? "True" : "False";
      },
      Cell: ({ row }) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <input
              type="checkbox"
              style={{
                "margin-left": "6px",
                width: "17px",
                cursor: "pointer",
              }}
              checked={selectedFunders.indexOf(row.original.id) !== -1}
              onClick={e => {
                if (e.target.checked) {
                  dispatch({
                    type: types.SELECT_FUNDER,
                    payload: { id: row.original.id },
                  });
                } else {
                  dispatch({
                    type: types.DESELECT_FUNDER,
                    payload: { id: row.original.id },
                  });
                }
              }}
            />
            {!isViewMode && (
              <IconButton
                Icon={FaPlusCircle}
                iconConfig={{ className: "table--action-icon btn--green" }}
                tooltip="Create Activity for Funder"
                onClick={() => {
                  props.selectAuctionActivity(null);
                  props.showModal(AUCTION_FUNDER_ACTIVITY_MODAL, {
                    funderId: row.original.id,
                  });
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      Header: "Funder Name",
      accessor: "name",
      Cell: ({ row }) => {
        if (row.original.name) {
          return (
            <div className="text-wrap">
              <a href={`/funders/${row.original.id}/overview`} target="_blank" rel="noreferrer">
                {row.original.name}
              </a>
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      Header: "Funder Code",
      accessor: "id",
      Cell: ({ row }) => {
        const paddedFunderId = String(row.original.id).padStart(3, "0");
        return `LR-FC${paddedFunderId}`;
      },
    },
    {
      Header: "Case Fit",
      accessor: "case_fit",
      Cell: ({ row }) => {
        if (row.original.case_fit_response.case_fit) {
          return <div style={{ color: "green" }}>Yes</div>;
        } else {
          return <div style={{ color: "red" }}>No</div>;
        }
      },
    },
    {
      Header: "Date Sent",
      accessor: "date_sent",
      className: "padded-table-cells",
      Cell: dateCell,
    },
    {
      Header: "Last Activity Date",
      accessor: "last_activity_date",
      Cell: dateCell,
    },
    {
      Header: "Status",
      accessor: "custom_order",
      className: "padded-table-cells",
      Cell: ({ row }) => {
        return row.original.verbose_status;
      },
    },
    {
      Header: "Min Age",
      accessor: "min_age",
      Cell: ({ row }) => {
        const color = row.original.case_fit_response?.min_age;
        if (row.original.min_age) {
          return <div style={{ color: color }}>{row.original.min_age}</div>;
        } else {
          return "";
        }
      },
    },
    {
      Header: "Max Age",
      accessor: "max_age",
      Cell: ({ row }) => {
        const color = row.original.case_fit_response?.max_age;
        if (row.original.max_age) {
          return <div style={{ color: color }}>{row.original.max_age}</div>;
        } else {
          return "";
        }
      },
    },
    {
      Header: "Min LE",
      accessor: "min_le",
      Cell: ({ row }) => {
        const color = row.original.case_fit_response?.min_le;
        if (row.original.min_le) {
          return <div style={{ color: color }}>{row.original.min_le}</div>;
        } else {
          return "";
        }
      },
    },
    {
      Header: "Max LE",
      accessor: "max_le",
      Cell: ({ row }) => {
        const color = row.original.case_fit_response?.max_le;
        if (row.original.max_le) {
          return <div style={{ color: color }}>{row.original.max_le}</div>;
        } else {
          return "";
        }
      },
    },
    {
      Header: "Min Face",
      accessor: "min_face",
      Cell: ({ row }) => {
        const color = row.original.case_fit_response?.min_face;
        if (row.original.min_face) {
          const money_display = moneyDisplayShort(row.original.min_face);
          return <div style={{ color: color }}>{money_display}</div>;
        } else {
          return "";
        }
      },
    },
    {
      Header: "Max Face",
      accessor: "max_face",
      Cell: ({ row }) => {
        const color = row.original.case_fit_response?.max_face;
        if (row.original.max_face) {
          const money_display = moneyDisplayShort(row.original.max_face);
          return <div style={{ color: color }}>{money_display}</div>;
        } else {
          return "";
        }
      },
    },
    {
      Header: "SUL",
      accessor: "sul",
      Cell: ({ row }) => {
        const color = row.original.case_fit_response?.sul;
        if (row.original.sul) {
          return <div style={{ color: color }}>{row.original.sul}</div>;
        } else {
          return "";
        }
      },
    },
    {
      Header: "RDB",
      accessor: "rdb",
    },
    {
      Header: "VUL",
      accessor: "vul",
      Cell: ({ row }) => {
        const color = row.original.case_fit_response?.vul;
        if (row.original.vul) {
          return <div style={{ color: color }}>{row.original.vul}</div>;
        } else {
          return "";
        }
      },
    },
    {
      Header: "Market LEs",
      accessor: "market_les_required",
      Cell: ({ row }) => {
        const color = row.original.case_fit_response?.market_les_required;
        if (row.original.market_les_required) {
          return <div style={{ color: color }}>{row.original.market_les_required}</div>;
        } else {
          return "";
        }
      },
    },
    {
      Header: "LE Vendors",
      accessor: "le_vendors",
      className: "padded-table-cells",
      Cell: ({ row }) => {
        const color = row.original.case_fit_response?.le_vendors;
        if (row.original.le_vendors) {
          return <div style={{ color: color }}>{row.original.le_vendors}</div>;
        } else {
          return "";
        }
      },
    },
  ];

  return (
    <FundersListTable
      columns={columns}
      data={shownFunders}
      defaultSort={true}
      defaultPagination={true}
      sortBy={props.sortByValue}
      initialPageSize={100}
      emptyMessage={props.fundersLoading ? "Loading data..." : "No data"}
    />
  );
};

const mapStateToProps = state => {
  const auctionId = auctionIdSelector(state);
  const funders = fundersForAuctionSelector(state);
  const sortByValue = [fundersSortBySelector(state)] || [{ id: "name", desc: false }];

  return {
    funders,
    sortByValue,
    auctionId,
    selectedFunders: selectedFundersSelector(state),
    fundersLoading: loadingFundersSelector(state),
    funderSearchString: funderSearchStringSelector(state),
    activeCaseId: activeCaseIdSelector(state),
  };
};

export default connect(mapStateToProps, {
  selectAuctionActivity,
  fetchFundersForAuction,
  fetchInsured,
  showModal,
})(FundersAuctionTable);
