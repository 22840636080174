import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { CASE_FILE_WARNING_MODAL } from "components/modals";
import {
  activeCaseFileSelector,
  activeCaseSelector,
  activeLifeExpectancySelector,
  insuredListSelector,
  ownerListSelector,
  caseFilesCategorySelector,
  caseFilesDescriptionSelector,
} from "reducers";
import {
  deleteCaseFile,
  deleteCaseFileFile,
  deleteLifeExpectancyFile,
  editLifeExpectancy,
  fetchLifeExpectancies,
  uploadCaseFile,
  showModal,
  hideModal,
} from "actions";

const CaseFileWarningModal = ({ activeCaseFile, activeLifeExpectancy, ...props }) => {
  const generalFormSubmitRef = props.generalFormSubmitRef;
  const vendorSpecificFormSubmitRef = props.vendorSpecificFormSubmitRef;
  const mortalityTableSubmitRef = props.mortalityTableSubmitRef;

  const WideSaveButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="btn btn--tertiary"
          onClick={() => {
            props.hideModal(CASE_FILE_WARNING_MODAL);
            generalFormSubmitRef.current.click();
            vendorSpecificFormSubmitRef.current && vendorSpecificFormSubmitRef.current.click();
            mortalityTableSubmitRef.current && mortalityTableSubmitRef.current.click();
            setTimeout(() => {
              props.fetchLifeExpectancies();
            }, 500);
          }}
          style={{
            width: "99%",
            marginBottom: "1rem",
          }}
        >
          Save Changes
        </button>
      </div>
    );
  };

  return (
    <FullPageModal
      modalType={CASE_FILE_WARNING_MODAL}
      title="Case File Saving Warning"
      style={{ content: { overflow: "visible" } }}
    >
      <h6>These are the failed validations while trying to save this case file</h6>
      <ul style={{ margin: "2rem" }}>
        {props.errs.map((e, index) => (
          <li key={index}>{e}</li>
        ))}
      </ul>

      <h6>After being warned, as the market type for this case is Tertiary, you can save it anyways</h6>
      <WideSaveButton />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  const activeCase = activeCaseSelector(state) || {};

  return {
    activeCaseFile: activeCaseFileSelector(state),
    activeLifeExpectancy: activeLifeExpectancySelector(state),
    category: caseFilesCategorySelector(state),
    description: caseFilesDescriptionSelector(state),
    insuredList: insuredListSelector(state),
    ownerList: ownerListSelector(state),
    jurisdiction: activeCase && activeCase.jurisdiction,
    activeCase,
  };
};

export default connect(mapStateToProps, {
  uploadCaseFile,
  deleteCaseFile,
  deleteCaseFileFile,
  deleteLifeExpectancyFile,
  editLifeExpectancy,
  fetchLifeExpectancies,
  showModal,
  hideModal,
})(CaseFileWarningModal);
