import {
  FETCH_LIFE_SETTLEMENT_CASE,
  EDIT_LIFE_SETTLEMENT_CASE,
  CREATE_EMPTY_LIFE_SETTLEMENT_CASE,
  CLEAN_LIFE_SETTLEMENT_CASE_DATA,
} from "actions/types";

export const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case EDIT_LIFE_SETTLEMENT_CASE:
    case CREATE_EMPTY_LIFE_SETTLEMENT_CASE:
    case FETCH_LIFE_SETTLEMENT_CASE:
      return {
        ...state[action.payload.id],
        ...action.payload,
      };
    case CLEAN_LIFE_SETTLEMENT_CASE_DATA:
      return {};
    default:
      return state;
  }
};
