import React from "react";
import { Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
// styles and 3rd-party components setup
import Modal from "react-modal";
import "react-datepicker/dist/react-datepicker.css";
import "react-data-grid/lib/styles.css";

import "css/style.css";

import { getCaseManagementEnabledAgencies } from "actions";
import { URLS } from "constants.js";
import { ToastContainer } from "react-toastify";
import { Worker } from "@react-pdf-viewer/core";

import ModalRoot from "./modals/ModalRoot";
import LoginForm from "./LoginForm";
import ResetPasswordForm from "./ResetPasswordForm";
import SetPasswordForm from "./SetPasswordForm";
import Navbar from "./Navbar";
import Footer from "./Footer";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import AccountsPage from "../pages/accounts/AccountsPage";
import AccountInformationPage from "../pages/accounts/information/AccountInformationPage";
import AccountCasesPage from "../pages/accounts/cases/AccountCasesPage";
import AccountLicensingPage from "../pages/accounts/licensing_and_contracts/AccountLicensingPage";
import AccountsSetup from "../pages/accounts/AccountsSetup";

import AuctionPage from "../pages/cases/auction/AuctionPage";
import BeneficiariesPage from "../pages/cases/beneficiaries/BeneficiariesPage";
import CaseFilesPage from "../pages/cases/caseFiles/CaseFilesPage";
import CaseFilesSetup from "../pages/cases/caseFiles/CaseFilesSetup";
import CaseSetup from "../pages/cases/CaseSetup";
import CaseTasksPage from "../pages/cases/caseTasks/CaseTasksPage";
import CasesPage from "../pages/cases/list/CasesPage";
import ClosingPage from "../pages/cases/closing/ClosingPage";
import ActivityPage from "../pages/cases/activityLog/ActivityPage";
import InsuredPage from "../pages/cases/insured/InsuredPage";
import OwnersPage from "../pages/cases/owners/OwnersPage";
import PolicyPage from "../pages/cases/policy/PolicyPage";

import CarriersPage from "../pages/carrier/CarriersPage";
import CarrierFiles from "../pages/carrier/CarrierFiles";
import CarrierOverview from "../pages/carrier/CarrierOverview";
import CarrierSetup from "../pages/carrier/CarrierSetup";
import CarrierProductDetail from "../pages/carrier/CarrierProductDetail";

import LeadDetail from "../pages/leads/LeadDetail";
import LeadsPage from "../pages/leads/LeadsPage";
import LeadsSetup from "../pages/leads/LeadsSetup";

import FundersListPage from "../pages/funders/FundersListPage";
import FunderActivityReportPage from "../pages/funders/FunderActivityReportPage";
import FunderBuyingProfilePage from "../pages/funders/FunderBuyingProfilePage";
import FunderCasesFitPage from "../pages/funders/FunderCasesFitPage";
import FunderCasesPage from "../pages/funders/FunderCasesPage";
import FunderContactsPage from "../pages/funders/FunderContactsPage";
import FunderEntitiesPage from "../pages/funders/FunderEntitiesPage";
import FunderOverview from "../pages/funders/FunderOverview";
import FunderSetup from "../pages/funders/FunderSetup";

import LifeExpectancyPage from "../pages/riskx/lifeExpectancy/LifeExpectancyPage";
import LifeExpectancyPredictorPage from "../pages/riskx/lifeExpectancy/LifeExpectancyPredictorPage";

import MortalityAssessmentsPage from "../pages/riskx/mortalityAssessments/MortalityAssessmentsPage";
import MortalityAssessmentCurve from "../pages/riskx/mortalityAssessments/curves/MortalityAssessmentCurve";
import MortalityAssessmentSetup from "../pages/riskx/mortalityAssessments/MortalityAssessmentSetup";
import MortalityAssessmentSummary from "../pages/riskx/mortalityAssessments/summary/MortalityAssessmentSummary";

import ParameterDetailBackground from "../pages/riskx/parameters/ParameterDetailBackground";
import ParameterDetailClinical from "../pages/riskx/parameters/ParameterDetailClinical";
import ParameterDetailRating from "../pages/riskx/parameters/ParameterDetailRating";
import ParameterDetailRisk from "../pages/riskx/parameters/ParameterDetailRisk";
import ParameterSetup from "../pages/riskx/parameters/ParameterSetup";
import RiskxParametersPage from "../pages/riskx/parameters/ParametersPage";
import RiskxSynonymsPage from "../pages/riskx/synonyms/RiskxSynonymsPage";

import ProductDatabasePage from "../pages/productDatabase/ProductDatabasePage";
import ProductSetup from "../pages/productDatabase/ProductSetup";
import ProductDetailPage from "../pages/productDatabase/ProductDetailPage";

import RecordsPage from "../pages/records/RecordsPage";

import TradingPage from "../pages/trading/TradingPage";

import { loginInfoSelector } from "../reducers";
import { redirectTo } from "../utils";
import { isAdmin } from "permissions";
import ParameterDetailRelations from "../pages/riskx/parameters/ParameterDetailRelations";
import MortalityAssessmentViewer from "../pages/riskx/mortalityAssessments/viewer/MortalityAssessmentViewer";
import AdminEmailsPage from "../pages/admin/AdminEmailsPage";
import AdminUsersPage from "../pages/admin/AdminUsersPage";
import AdminTemplatesPage from "../pages/admin/AdminTemplatesPage";
import { TaskTab } from "../pages/records/constants";
import StatesPage from "../pages/states/StatesPage";
import StateDetailBroker from "../pages/states/StateDetailBroker";
import StateDetailProvider from "../pages/states/StateDetailProvider";
import StateSetup from "../pages/states/StateSetup";

// Configure the react-modal component root element
Modal.setAppElement("#root");

const App = props => {
  React.useEffect(() => {
    props.getCaseManagementEnabledAgencies();

    // Redirect to "/cases" by default
    if (window.location.pathname === "/") {
      redirectTo(URLS.TASKS);
    }
  }, []);

  // Helpers for the 3 types of routes: public, private and private admin-only
  const publicRoute = (path, Component) => {
    return (
      <Route
        path={path}
        element={
          <PublicRoute>
            <Component />
          </PublicRoute>
        }
      />
    );
  };

  const privateRoute = (path, Component) => {
    return (
      <Route
        path={path}
        element={
          <PrivateRoute>
            <Component />
          </PrivateRoute>
        }
      />
    );
  };

  const privateRouteWithProps = ({ path, component: Component, ...props }) => {
    return (
      <Route
        path={path}
        element={
          <PrivateRoute>
            <Component {...props} />
          </PrivateRoute>
        }
      />
    );
  };

  const privateAdminRoute = (path, Component) => {
    if (!props.hasAdminPermission) {
      return null;
    } else {
      return privateRoute(path, Component);
    }
  };

  return (
    <div className="app">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js" />
      <ToastContainer />
      <ModalRoot />
      {props.isSignedIn ? <Navbar /> : null}
      <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <div className={props.isSignedIn ? "container-fluid" : "container-fluid login"}>
          <Routes>
            {publicRoute(URLS.LOGIN + "*", LoginForm)}
            <Route path={URLS.RESET_PASSWORD} element={<ResetPasswordForm />} />
            <Route path={URLS.NEW_PASSWORD + "/:uid/:reset_password_token"} element={<SetPasswordForm />} />
            <Route path={URLS.SET_PASSWORD + "/:uid/:reset_password_token"} element={<SetPasswordForm />} />

            {/* Admin Routes */}
            {privateAdminRoute(URLS.ADMIN_EMAILS, AdminEmailsPage)}
            {privateAdminRoute(URLS.ADMIN_TEMPLATES, AdminTemplatesPage)}
            {privateAdminRoute(URLS.ADMIN_USERS, AdminUsersPage)}

            {/* My Tasks Routes */}
            {privateRouteWithProps({ path: URLS.TASKS, component: RecordsPage, type: TaskTab.ALL })}
            {privateRouteWithProps({ path: URLS.CASE_TASKS, component: RecordsPage, type: TaskTab.CASE })}
            {privateRouteWithProps({
              path: URLS.CASE_REQUIREMENT_TASKS,
              component: RecordsPage,
              type: TaskTab.CASE_REQUIREMENT,
            })}
            {privateRouteWithProps({ path: URLS.ACCOUNT_TASKS, component: RecordsPage, type: TaskTab.ACCOUNT })}
            {privateRouteWithProps({ path: URLS.LEAD_TASKS, component: RecordsPage, type: TaskTab.LEAD })}
            {privateRoute(URLS.CASES, CasesPage)}
            {privateAdminRoute(URLS.TRADING, TradingPage)}

            {/* Products */}
            {privateAdminRoute(URLS.PRODUCTS, ProductDatabasePage)}
            <Route
              path={`${URLS.PRODUCTS}:id/`}
              element={
                <PrivateRoute>
                  <ProductSetup />
                </PrivateRoute>
              }
            >
              {privateRoute("detail/", ProductDetailPage)}
            </Route>

            {/* Case Routes */}
            <Route
              path={"/cases/:id/"}
              element={
                <PrivateRoute>
                  <CaseSetup />
                </PrivateRoute>
              }
            >
              {privateRoute("policy/", PolicyPage)}
              {privateRoute("insured/", InsuredPage)}
              {privateRoute("owners/", OwnersPage)}
              {privateRoute("beneficiaries/", BeneficiariesPage)}
              {privateAdminRoute("trading/", AuctionPage)}
              {privateAdminRoute("closing/", ClosingPage)}
              <Route
                path={"files/"}
                element={
                  <PrivateRoute>
                    <CaseFilesSetup />
                  </PrivateRoute>
                }
              >
                {privateRoute(":documentId/", CaseFilesPage)}
              </Route>
              {privateRoute("tasks/", CaseTasksPage)}
              {privateAdminRoute("activity/", ActivityPage)}
            </Route>
            {/* Account Routes */}
            {privateRoute(URLS.ACCOUNTS, AccountsPage)}
            <Route
              path={"/accounts/:id/"}
              element={
                <PrivateRoute>
                  <AccountsSetup />
                </PrivateRoute>
              }
            >
              {privateRoute("information/", AccountInformationPage)}
              {privateRoute("cases/", AccountCasesPage)}
              {privateAdminRoute("licensing/", AccountLicensingPage)}
            </Route>
            {/* Carrier Routes */}
            {privateRoute(URLS.CARRIERS, CarriersPage)}
            <Route
              path={"/carriers/:id/"}
              element={
                <PrivateRoute>
                  <CarrierSetup />
                </PrivateRoute>
              }
            >
              {privateRoute("overview/", CarrierOverview)}
              {privateRoute("files/", CarrierFiles)}
              {privateRoute("product/", CarrierProductDetail)}
            </Route>
            {/* Funder Routes */}
            {privateAdminRoute(URLS.FUNDERS, FundersListPage)}
            {privateAdminRoute(URLS.FUNDER_REPORT, FunderActivityReportPage)}
            {privateAdminRoute(URLS.FUNDER_PROFILE, FunderBuyingProfilePage)}
            <Route
              path={"/funders/:id/"}
              element={
                <PrivateRoute>
                  <FunderSetup />
                </PrivateRoute>
              }
            >
              {privateAdminRoute("/funders/:id/overview/", FunderOverview)}
              {privateAdminRoute("/funders/:id/cases/", FunderCasesPage)}
              {privateAdminRoute("/funders/:id/contacts", FunderContactsPage)}
              {privateAdminRoute("/funders/:id/entities", FunderEntitiesPage)}
              {privateAdminRoute("/funders/:id/case-fits/", FunderCasesFitPage)}
            </Route>
            {/* Leads Routes */}
            {privateRoute(URLS.LEADS, LeadsPage)}
            <Route
              path={`${URLS.LEADS}:id/`}
              element={
                <PrivateRoute>
                  <LeadsSetup />
                </PrivateRoute>
              }
            >
              {privateRoute("overview/", LeadDetail)}
            </Route>

            {/* RiskX Routes */}
            {privateAdminRoute(URLS.PARAMETERS, RiskxParametersPage)}
            {privateAdminRoute(URLS.MORTALITY_ASSESSMENTS, MortalityAssessmentsPage)}
            {privateAdminRoute(URLS.SYNONYMS, RiskxSynonymsPage)}
            {privateAdminRoute(URLS.LIFE_EXPECTANCY, LifeExpectancyPage)}
            {privateAdminRoute(URLS.LE_PREDICTOR, LifeExpectancyPredictorPage)}

            {/* RiskX Parameter Routes */}
            <Route
              path={`${URLS.PARAMETERS}:id/`}
              element={
                <PrivateRoute>
                  <ParameterSetup />
                </PrivateRoute>
              }
            >
              {privateRoute("background/", ParameterDetailBackground)}
              {privateRoute("risk/", ParameterDetailRisk)}
              {privateRoute("research/", ParameterDetailClinical)}
              {privateRoute("rating/", ParameterDetailRating)}
              {privateRoute("relations/", ParameterDetailRelations)}
            </Route>
            {/* Risk Assessment Routes */}
            <Route
              path={`${URLS.MORTALITY_ASSESSMENT_DETAIL}/`}
              element={
                <PrivateRoute>
                  <MortalityAssessmentSetup />
                </PrivateRoute>
              }
            >
              {privateRoute("summary/", MortalityAssessmentSummary)}
              {privateRoute("curve/", MortalityAssessmentCurve)}
              {privateRoute("viewer/", MortalityAssessmentViewer)}
            </Route>

            {/* States Routes */}
            {privateRoute(URLS.STATES, StatesPage)}
            <Route
              path={`${URLS.STATES}:id/`}
              element={
                <PrivateRoute>
                  <StateSetup />
                </PrivateRoute>
              }
            >
              {privateRoute("broker/", StateDetailBroker)}
              {privateRoute("provider/", StateDetailProvider)}
            </Route>
          </Routes>
        </div>
        <Footer style={{ flexShrink: 0 }} />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { isSignedIn, email } = loginInfoSelector(state);
  return {
    isSignedIn,
    email,
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, { getCaseManagementEnabledAgencies })(App);
