import React from "react";
import { connect } from "react-redux";

import { activeCarrierSelector } from "reducers";

import { pricingSetup } from "../../actions";
import { Card } from "react-bootstrap";
import CarrierForm from "./CarrierForm";
import CarrierProductForm from "./CarrierProductForm";

const CarrierProductDetail = ({ carrierInfo, ...props }) => {
  React.useEffect(() => {
    if (carrierInfo && carrierInfo.name) {
      document.title = `LifeRoc | ${carrierInfo.name} | Carrier Product Detail`;
    }
  }, [carrierInfo?.name]);

  return (
    <div className={`section-card shadowed case-section`} style={{ marginTop: "5.5rem" }}>
      <Card className={"case-info"}>
        <Card.Body>
          <CarrierProductForm carrierInfo={carrierInfo} />
        </Card.Body>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    carrierInfo: activeCarrierSelector(state),
  };
};

export default connect(mapStateToProps, { pricingSetup })(CarrierProductDetail);
