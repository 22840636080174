import React from "react";
import { connect } from "react-redux";

import { showModal, createEmptyCase, setActiveTab, initPagination, clearSelectedProduct, fetchProducts } from "actions";
import ProductDatabaseFilterForm from "./ProductDatabaseFilterForm";
import ProductDatabaseTable from "./ProductDatabaseTable";
import { ButtonCreate } from "../../components/buttons";
import { PRODUCT_MODAL } from "../../components/modals";

const ProductDatabasePage = props => {
  const [activeSection, setActiveSection] = React.useState("lifeexpectancy");

  React.useEffect(() => {
    props.setActiveTab(activeSection);
    props.initPagination();
    setActiveSection(activeSection);
    props.fetchProducts();
    document.title = "LifeRoc | Products";
  }, []);

  return (
    <div>
      <ProductDatabaseFilterForm />
      <ButtonCreate
        id="create-new-case"
        onClick={() => {
          props.clearSelectedProduct();
          props.showModal(PRODUCT_MODAL);
        }}
        key="create"
      >
        Add Product
      </ButtonCreate>
      <ProductDatabaseTable />
    </div>
  );
};

export default connect(null, {
  createEmptyCase,
  setActiveTab,
  initPagination,
  clearSelectedProduct,
  showModal,
  fetchProducts,
})(ProductDatabasePage);
