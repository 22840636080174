import React from "react";
import { connect } from "react-redux";

import { showModal, downloadFile, selectCaseTask } from "actions";
import { activeCaseSelector } from "reducers";
import Table, { dateOverdueCell, datetimeCell } from "components/table";
import IconLink from "../../../components/IconLink";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { CASE_TASK_MODAL, DELETE_CASE_TASK_MODAL } from "components/modals";
import { TASK_STATUS_MAP } from "../../accounts/constants";
import IconButton from "../../../components/IconButton";
import { isAdmin, isReadMode } from "permissions";

const CaseTasksTable = ({ activeCase, ...props }) => {
  if (!activeCase) {
    // Still didn't fetch data
    return null;
  }

  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <>
            <IconButton
              Icon={props.readMode ? FaEye : FaEdit}
              tooltip={props.readMode ? "View" : "Edit"}
              iconConfig={{ className: "table--action-icon" }}
              onClick={() => {
                props.selectCaseTask(row.original);
                props.showModal(CASE_TASK_MODAL);
              }}
            />
            {!props.readMode && (
              <IconLink
                Icon={FaTrash}
                iconConfig={{ className: "table--action-icon btn--red" }}
                tooltip="Delete"
                onClick={() => {
                  props.showModal(DELETE_CASE_TASK_MODAL, {
                    id: row.original.task_obj?.id,
                  });
                }}
              />
            )}
          </>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const status_value = row.original.status;
        if (status_value !== null) {
          return TASK_STATUS_MAP[status_value] || "";
        } else {
          return "";
        }
      },
    },
    {
      Header: "Owner",
      accessor: "record_owner",
    },
    {
      Header: "Created By",
      accessor: "record_creator",
    },
    {
      Header: "Date Created",
      accessor: "date_created",
      Cell: datetimeCell,
    },
    {
      Header: "Last Edited By",
      accessor: "last_editor",
    },
    {
      Header: "Last Edit Date",
      accessor: "last_edit_date",
      Cell: datetimeCell,
    },
    {
      Header: "Due Date",
      accessor: "due_date",
      Cell: dateOverdueCell,
    },
  ];

  return (
    <Table columns={columns} data={props.tasks} defaultSort={true} paginated={false} emptyMessage="No Tasks found" />
  );
};

const mapStateToProps = state => {
  const activeCase = activeCaseSelector(state) || {};
  return {
    activeCase,
    tasks: activeCase?.tasks || [],
    hasAdminPermission: isAdmin(state),
    readMode: isReadMode(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectCaseTask,
  downloadFile,
})(CaseTasksTable);
