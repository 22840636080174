import { CLEAR_PREDICTIONS, GET_PREDICTION } from "actions/types";

export const defaultState = {
  predictions: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_PREDICTIONS:
      return defaultState;

    case GET_PREDICTION:
      return {
        ...state,
        predictions: [action.payload, ...state.predictions],
      };

    default:
      return state;
  }
};

export const predictionsSelector = state => state.predictions;
