import React from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import { useField, useFormikContext } from "formik";

import { isInvalid } from "./validation";
import Label from "./Label";
import FieldErrors from "./FieldErrors";
import FieldHelpText from "./FieldHelpText";
import { FaRegCopy } from "react-icons/all";
import * as notifications from "notifications.js";

import { ButtonCreate } from "components/buttons";
import { pricingSetup } from "actions";
import { connect } from "react-redux";

import { activeCaseSelector } from "reducers";

const PricingFolderLink = ({ label, md = "", helpText, isRequired = false, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  if (!props.autoComplete) {
    props.autoComplete = "off";
  }

  const copyToClipboard = e => {
    e.preventDefault();
    const el = document.createElement("textarea");
    let copyText = document.getElementsByName(field.name)[0];
    el.value = copyText.value;
    document.body.appendChild(el);
    el.select();
    el.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy"); /* Copy the text inside the text field */
    notifications.success("Copied to Clipboard!");
    document.body.removeChild(el);
  };

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      <Label label={label} required={isRequired} />
      <a href={props.value} target="_blank" rel="noreferrer">
        <ButtonCreate
          onClick={e => {
            e.preventDefault();
            let id = props.caseInfo.id;
            props.pricingSetup(id);
          }}
          key="create"
          className="btn--pricing-setup"
        >
          Create Pricing
        </ButtonCreate>
        <InputGroup>
          <Form.Control
            style={{ cursor: "pointer", color: "blue" }}
            {...field}
            {...props}
            onChange={e => {
              let value = e.target.value;
              setFieldValue(field.name, value);
            }}
            id={field.name}
            isInvalid={isInvalid(meta)}
          />
          <InputGroup.Append onClick={e => copyToClipboard(e)}>
            <InputGroup.Text>
              <FaRegCopy />
            </InputGroup.Text>
          </InputGroup.Append>
          <FieldErrors meta={meta} />
        </InputGroup>
      </a>
      {helpText && <FieldHelpText>{helpText}</FieldHelpText>}
    </Form.Group>
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
  };
};

export default connect(mapStateToProps, { pricingSetup })(PricingFolderLink);
