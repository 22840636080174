import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import {
  Select,
  TextInput,
  EditorField,
  StateSelect,
  SubmitButton,
  CurrencyInput,
  PercentageInput,
  BooleanSelect,
  DateTimeInput,
} from "components/forms";
import { editFunder, unsetShouldSave } from "actions";
import { managementChoicesSelector, shouldSaveSelector } from "reducers";
import { showModal } from "../../actions";
import Collapsible from "../../components/Collapsible";
import * as notifications from "../../notifications";
import {
  acquisitionMarketChoices,
  buyingStatusChoices,
  inforcePeriodChoices,
  loanPolicyChoices,
  marketRoleChoices,
  policyMaturityChoices,
  premiumFinanceBuyerChoices,
  pricingSystemChoices,
  rdbChoices,
  REVENUE_MODEL_CHOICES,
  sulChoices,
  survivalCurveChoices,
  vulChoices,
} from "../../constants";
import { leVendorChoices, ndaInPlaceChoices, servicerChoicesLazy, underwritingMethodChoices } from "./constants";
import { prepareMultipleSelect } from "../../utils";

const FunderForm = ({ funderInfo, ...props }) => {
  if (!funderInfo) {
    // Still didn't fetch data
    return null;
  }

  let initial_market_roles = prepareMultipleSelect("market_role", props.marketRoleChoices, funderInfo, ";");
  let initial_survival_curves = prepareMultipleSelect(
    "survival_curve_used",
    props.survivalCurveChoices,
    funderInfo,
    ";",
  );
  let initial_pricing_systems = prepareMultipleSelect("pricing_system", props.pricingSystemChoices, funderInfo, ";");
  let initial_le_vendors = prepareMultipleSelect("le_vendor", props.leVendorChoices, funderInfo, ";");
  let initial_servicers = prepareMultipleSelect("servicers", props.servicerChoices, funderInfo, ";");

  let [formattedMarketRoles, setFormattedMarketRoles] = React.useState();
  let [formattedSurvivalCurves, setFormattedSurvivalCurves] = React.useState();
  let [formattedPricingSystems, setFormattedPricingSystems] = React.useState();
  let [formattedLEVendors, setFormattedLEVendors] = React.useState();
  let [formattedServicers, setFormattedServicers] = React.useState();

  const handleMarketRoleChange = selectedOptions => {
    if (selectedOptions) {
      setFormattedMarketRoles(selectedOptions.map(o => o.value).join(";"));
    } else {
      setFormattedMarketRoles("");
    }
  };

  const handleSurvivalCurveChange = selectedOptions => {
    if (selectedOptions) {
      setFormattedSurvivalCurves(selectedOptions.map(o => o.value).join(";"));
    } else {
      setFormattedSurvivalCurves("");
    }
  };

  const handlePricingSystemChange = selectedOptions => {
    if (selectedOptions) {
      setFormattedPricingSystems(selectedOptions.map(o => o.value).join(";"));
    } else {
      setFormattedPricingSystems("");
    }
  };

  const handleLEVendorChange = selectedOptions => {
    if (selectedOptions) {
      setFormattedLEVendors(selectedOptions.map(o => o.value).join(";"));
    } else {
      setFormattedLEVendors("");
    }
  };

  const handleServicerChange = selectedOptions => {
    if (selectedOptions) {
      setFormattedServicers(selectedOptions.map(o => o.value).join(";"));
    } else {
      setFormattedServicers("");
    }
  };

  const submitRef = React.useRef();

  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  return (
    <Formik
      enableReinitialize
      initialValues={funderInfo}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        values.market_role = formattedMarketRoles;
        values.survival_curve_used = formattedSurvivalCurves;
        values.pricing_system = formattedPricingSystems;
        values.le_vendor = formattedLEVendors;
        values.servicers = formattedServicers;
        try {
          await props.editFunder(funderInfo.id, values);
          notifications.success("Funder saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Funder");
        }
        props.unsetShouldSave();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit} className={"form shadowed"} {...props}>
            <Collapsible title="Funder Information">
              <Form.Row>
                <TextInput label="Name" name="name" />
                <TextInput label="Phone" name="phone" />
                <TextInput label="Fax" name="fax" />
                <TextInput label="Email" lowercase={true} name="email" />
              </Form.Row>
              <Form.Row>
                <TextInput label="Street Address" name="street_address" />
                <TextInput label="City" name="city" />
                <StateSelect label="State" name="state" placeholder="Select State" />
                <TextInput label="Zip Code" name="zipcode" />
              </Form.Row>
              <Form.Row>
                <TextInput label="Website" name="website" />
                <Select
                  label="Servicer(s)"
                  name="servicers"
                  placeholder={"Select Servicer(s)"}
                  options={props.servicerChoices}
                  isMulti={true}
                  onChange={handleServicerChange}
                  defaultValue={initial_servicers}
                />
              </Form.Row>
              <Form.Row>
                <DateTimeInput label="Date Created" name="date_created" disabled />
                <TextInput label="Created By" name="created_by_name" disabled />
                <DateTimeInput label="Last Edit Date" name="last_edit_date" disabled />
                <TextInput label="Last Edited By" name="last_edited_by_name" disabled />
              </Form.Row>
              <Form.Row>
                <EditorField label="Notes" name="notes" />
              </Form.Row>
            </Collapsible>
            <Collapsible title="Funder Detail">
              <Form.Row>
                <Select
                  label="Market Role"
                  name="market_role"
                  placeholder="Select Market Role"
                  options={props.marketRoleChoices}
                  isMulti={true}
                  onChange={handleMarketRoleChange}
                  defaultValue={initial_market_roles}
                />
                <Select
                  label="NDA in Place"
                  name="nda_in_place"
                  placeholder={"Select NDA in Place"}
                  options={props.ndaInPlaceChoices}
                />
                <Select
                  label="Buying Status"
                  name="buying_status"
                  placeholder={"Select Buying Status"}
                  options={props.buyingStatusChoices}
                />
                <Select
                  label="Default Revenue Model"
                  name="default_revenue_model"
                  placeholder={"Select Default Revenue Model"}
                  options={REVENUE_MODEL_CHOICES}
                  md={3}
                />
              </Form.Row>
              <Form.Row>
                <Select
                  label="Pricing System"
                  name="pricing_system"
                  placeholder={"Select Pricing Systems"}
                  options={props.pricingSystemChoices}
                  isMulti={true}
                  onChange={handlePricingSystemChange}
                  defaultValue={initial_pricing_systems}
                />
                <Select
                  label="Survival Curve"
                  name="survival_curve_used"
                  placeholder={"Select Survival Curves Used"}
                  options={props.survivalCurveChoices}
                  isMulti={true}
                  onChange={handleSurvivalCurveChange}
                  defaultValue={initial_survival_curves}
                />
                <TextInput label="Funder Submission Email(s)" lowercase={true} name="case_submission_email" />
                <TextInput label="Funder Closing Email(s)" lowercase={true} name="funder_closing_email" />
              </Form.Row>
              <Form.Row>
                <TextInput label="Target IRR Low End" name="target_irr_low" md={3} />
                <TextInput label="Target IRR High End" name="target_irr_high" md={3} />
                <Select
                  label="Underwriting Method"
                  name="underwriting_method"
                  placeholder={"Select Underwriting Method"}
                  options={props.underwritingMethodChoices}
                  md={3}
                />
              </Form.Row>
              <Form.Row>
                <BooleanSelect
                  label="Funder Access - Current High Bid Data"
                  name="access_current_high_bid"
                  placeholder="Show Current High Bid Data?"
                  md={3}
                />
                <BooleanSelect
                  label="Winning Bid Notification"
                  name="winning_bid_notification"
                  placeholder="Show Winning Bid Notification?"
                  md={3}
                />
              </Form.Row>
            </Collapsible>
            <Collapsible title="Funder Buy Box">
              <Form.Row>
                <Select
                  label="LE Vendors"
                  name="le_vendor"
                  placeholder="Select LE Vendors"
                  options={props.leVendorChoices}
                  isMulti={true}
                  onChange={handleLEVendorChange}
                  defaultValue={initial_le_vendors}
                />
              </Form.Row>
              <Form.Row>
                <Select
                  label="Acquisition Markets"
                  name="acquisition_markets"
                  placeholder="Select Acquisition Markets"
                  options={props.acquisitionMarketChoices}
                  md={3}
                />
                <TextInput label="Market LEs Required" name="market_les_required" md={3} />
                <Select
                  label="Inforce Months"
                  name="inforce_period"
                  placeholder="Select Inforce Months"
                  options={props.inforcePeriodChoices}
                  md={6}
                />
              </Form.Row>
              <Form.Row>
                <Select
                  label="Policy Maturity Requirement"
                  name="policy_maturity_requirement"
                  placeholder={"Select Policy Maturity"}
                  options={props.policyMaturityChoices}
                />
                <Select
                  label="Premium Finance Buyer"
                  name="premium_finance_buyer"
                  placeholder="Is Premium Finance Buyer?"
                  options={props.premiumFinanceBuyerChoices}
                />
                <Select
                  label="Retained Death Benefit (RDB)"
                  name="retained_death_benefit"
                  placeholder="Select RDB"
                  options={props.rdbChoices}
                />
                <Select
                  label="Survivorship Policies (SUL)"
                  name="survivorship"
                  placeholder="Select SUL"
                  options={props.sulChoices}
                />
              </Form.Row>
              <Form.Row>
                <Select
                  label="Variable Policies (VUL)"
                  name="variable_policies"
                  placeholder="Select VUL"
                  options={props.vulChoices}
                />
                <Select
                  label="Internal Policy Loans"
                  name="loan_policies"
                  placeholder="Select Policy Loans"
                  options={props.loanPolicyChoices}
                />
                <CurrencyInput label="Min Face" name="minimum_face_amount" />
                <CurrencyInput label="Max Face" name="maximum_face_amount" />
              </Form.Row>
              <Form.Row>
                <TextInput label="Min Age" name="minimum_age" />
                <TextInput label="Max Age" name="maximum_age" />
                <TextInput label="Min LE" name="minimum_le" />
                <TextInput label="Max LE" name="max_le" />
              </Form.Row>
              <Form.Row>
                <PercentageInput label="Max BE" name="max_be" md={3} />
                <PercentageInput label="Max PSM" name="max_psm" md={3} />
              </Form.Row>
              <Form.Row>
                <EditorField label="Buy Box Notes" name="buy_box_notes" />
              </Form.Row>
            </Collapsible>
            <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  let servicerChoices = servicerChoicesLazy;
  if (choices.servicers.getChoices().length > 0) {
    servicerChoices = choices.servicers.getChoices();
  }

  return {
    shouldSave: shouldSaveSelector(state),
    marketRoleChoices,
    buyingStatusChoices,
    pricingSystemChoices,
    survivalCurveChoices,
    acquisitionMarketChoices,
    inforcePeriodChoices,
    policyMaturityChoices,
    premiumFinanceBuyerChoices,
    rdbChoices,
    sulChoices,
    vulChoices,
    loanPolicyChoices,
    leVendorChoices,
    ndaInPlaceChoices,
    underwritingMethodChoices,
    servicerChoices,
  };
};

export default connect(mapStateToProps, {
  editFunder,
  showModal,
  unsetShouldSave,
})(FunderForm);
