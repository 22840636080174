import React from "react";
import { useDispatch } from "react-redux";

import ConfirmationModal from "components/modals/ConfirmationModal";
import { CONFIRM_AUCTION_ACTIVITY_FILE_UPLOAD } from "components/modals";
import { hideModal } from "actions";

export default props => {
  const dispatch = useDispatch();

  return (
    <ConfirmationModal
      modalType={CONFIRM_AUCTION_ACTIVITY_FILE_UPLOAD}
      onConfirm={() => {
        props.onConfirm();
        dispatch(hideModal(CONFIRM_AUCTION_ACTIVITY_FILE_UPLOAD));
      }}
    >
      Uploading a new billing notice file will override the current file. Do you want to continue?
    </ConfirmationModal>
  );
};
