import React from "react";
import { connect } from "react-redux";

import { showModal, selectAccountAffiliation } from "actions";
import { engagementActivitiesSelector, loadingSelector } from "reducers";
import Table, { timeCell } from "components/table";

const EngagementActivitiesTable = ({ ...props }) => {
  if (!props.engagementActivities) {
    return null;
  }

  const columns = [
    {
      Header: "System",
      accessor: "system",
    },
    {
      Header: "From",
      accessor: "sender",
    },
    {
      Header: "To",
      accessor: "receiver",
    },
    {
      Header: "Activity Type",
      accessor: "display_type",
      Cell: ({ row }) => {
        let value = row.original.display_type || row.original.type || "";
        value = value.replace(/ - $/, "");
        value = value.replace(/^individual sent/, "Sent");
        return <div className="text-wrap">{value}</div>;
      },
    },
    {
      Header: "Date",
      accessor: "display_date",
    },
    {
      Header: "Time",
      accessor: "display_time",
      Cell: ({ row }) => {
        const value = row.original.display_time || "";
        return <div className="text-wrap">{value.replace("AM", "am").replace("PM", "pm")} </div>;
      },
    },
    {
      Header: "Duration",
      accessor: "duration",
      Cell: timeCell,
    },
    {
      Header: "Activity Score",
      accessor: "score",
    },
  ];

  return (
    <Table
      columns={columns}
      data={props.engagementActivities}
      defaultSort={true}
      paginated={false}
      emptyMessage={
        props.loading.engagementActivities ? "Loading Engagement Activities..." : "No Engagement Activities found"
      }
      sortBy={[{ id: "display_date", desc: true }]}
      darker={true}
    />
  );
};

const mapStateToProps = state => {
  const engagementActivities = engagementActivitiesSelector(state);

  return {
    engagementActivities,
    loading: loadingSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectAccountAffiliation,
})(EngagementActivitiesTable);
