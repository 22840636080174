import React from "react";
import { connect } from "react-redux";

import { createEmptyState } from "actions";
import StateFilterForm from "./StateFilterForm";
import StatesTable from "./StatesTable";
import ButtonCreate from "../../components/buttons/ButtonCreate";
import { isAdmin } from "permissions";

const StatesPage = props => {
  React.useEffect(() => {
    document.title = "LifeRoc | States";
  }, []);

  return (
    <div>
      <StateFilterForm />
      <StatesTable />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  createEmptyState,
})(StatesPage);
