import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as notifications from "notifications";

import FullPageModal from "components/modals/FullPageModal";
import { POLICY_REQUEST_MODAL } from "components/modals";
import {
  fetchInsured,
  generatePolicyRequestFiles,
  resetPolicyRequestFiles,
  sendPolicyRequestFax,
  hideModal,
  fetchPolicy,
  fetchCaseFilesByCaseDocumentId,
  updatePolicyRequestOption,
  fetchCarriers,
} from "actions";
import {
  activeCaseFileSelector,
  activeOrderFilesSelector,
  loadingSelector,
  insuredListSelector,
  activeCaseSelector,
  policySelector,
  managementChoicesSelector,
  ownerListSelector,
  carriersSelector,
  policyRequestOptionsSelector,
} from "reducers";
import File from "components/File";
import { Button, Form, Table } from "react-bootstrap";
import { FormCollapsible, Select, TextInput, EditorField } from "components/forms";
import { FaArrowLeft, FaFax } from "react-icons/fa";
import advisorhq from "apis/advisorhq";
import { arrayToObjectIndexedById } from "reducers/utils";
import { ButtonCreate } from "components/buttons";
import RequestRow from "./RequestRow";

const PolicyRequestModal = ({ ...props }) => {
  let [sendAvailable, setSendAvailable] = React.useState(false);
  let [sendingFax, setSendingFax] = React.useState(false);
  let [step, setStep] = React.useState(1);

  React.useEffect(() => {
    if (props.caseInfo.policy) {
      props.fetchPolicy(props.caseInfo.policy);
      props.fetchCarriers();
    }
  }, [props.caseInfo.id]);

  const SendButton = () => {
    let message;
    let errorMessage = "Fix errors before Sending";
    let errorFaxNumberMessage = "Please set Fax Number";
    if (sendingFax) {
      message = (
        <>
          Sending... <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
        </>
      );
    } else if (props.activeOrderFiles?.fax_cover) {
      let faxNumber = "";
      let carrier_fax = props.policy?.carrier_fax;
      if (carrier_fax) {
        if (carrier_fax.includes("(")) {
          carrier_fax = carrier_fax.replace(") ", "-");
          carrier_fax = carrier_fax.replace("(", "");
        }
        faxNumber = `1-${carrier_fax}`;
      }
      if (sendAvailable) {
        if (faxNumber) {
          message = (
            <>
              <FaFax />
              Send Fax ({faxNumber})
            </>
          );
        } else {
          message = errorFaxNumberMessage;
        }
      } else {
        message = errorMessage;
      }
    } else {
      message = (
        <>
          Generating... <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
        </>
      );
    }

    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          className="btn btn-outline-primary"
          onClick={() => {
            setStep(1);
          }}
          style={{
            width: "30%",
            marginBottom: "1rem",
          }}
        >
          <FaArrowLeft />
          Back
        </button>
        <button
          className="btn btn--secondary"
          onClick={async () => {
            setSendingFax(true);
            await props.sendPolicyRequestFax(props.activeCaseFile.id);
            props.hideModal(POLICY_REQUEST_MODAL);
          }}
          style={{
            width: "30%",
            marginBottom: "1rem",
          }}
          disabled={message === errorMessage || message === errorFaxNumberMessage}
        >
          {message}
        </button>
      </div>
    );
  };

  const ReviewInfoForm = () => {
    let policy_number = props.policy?.policy_number;

    let insuredInfo = props.insuredList;
    let insured_names = "";
    if (insuredInfo.length === 1) {
      insured_names = `${insuredInfo[0].last_name}, ${insuredInfo[0].first_name}`;
    } else if (insuredInfo.length === 2) {
      insured_names = `${insuredInfo[0].last_name}, ${insuredInfo[0].first_name}; ${insuredInfo[1].last_name}, ${insuredInfo[1].first_name}`;
    }

    let owner_names = "";
    let ownerNameList = [];
    for (const ownerListElement of props.ownerList) {
      if (ownerListElement.entity_name) {
        ownerNameList.push(ownerListElement.entity_name);
      } else if (ownerListElement.last_name) {
        ownerNameList.push(`${ownerListElement.last_name}, ${ownerListElement.first_name}`);
      }
    }
    owner_names = ownerNameList.join("; ");

    let [carrier, setCarrier] = React.useState(props.policy?.carrier);
    let [carrier_fax, setCarrierFax] = React.useState(props.policy?.carrier_fax);
    const handleCarrierChange = async o => {
      if (o && o.value) {
        setCarrier(o.value);
        try {
          const response = await advisorhq.get("/life_settlement/api/carrier/" + o.value + "/");
          setCarrierFax(response.data.fax);
        } catch (e) {
          notifications.warn("Problem getting Carrier data");
        }
      } else {
        setCarrier(o);
      }
    };

    let carrier_notes = "";
    if (carrier && props.carriersById?.[carrier]) {
      carrier_notes = props.carriersById[carrier].notes;
    }

    let illustration1 =
      "Run an Inforce Illustration using the Current Planned Premium and Current Assumptions. Please include the policy COIs & Charges break out.";
    let illustration2 =
      "Run an Inforce Illustration to Solve for the Level Premium to achieve $1.00 Cash Value at age 105; using Current Non-Guaranteed assumptions; assume all funds are in the Fixed/Declared Interest Account. Please include the policy COIs & Charges break out.";
    let illustration3 =
      "Run an Inforce Illustration to Solve for the minimum required Level Premium to maintain the policy through age 105, using Guaranteed / No Lapse assumptions. Please include the policy COIs & Charges break out.";

    let request1 = "Most Recent Annual Statement";
    let request2 = "Premium Payment History";
    let request3 = "Verification of Coverage (See Attached Form)";
    let request4 = "Certified Duplicate of the Policy";
    let request5 = "Save the attached Third-Party Authorization on file for future requests.";

    const [illustrations, setIllustrations] = React.useState([
      { name: "illustration1", label: "1", rows: 2 },
      { name: "illustration2", label: "2", rows: 3 },
      { name: "illustration3", label: "3", rows: 3 },
    ]);

    const [requests, setRequests] = React.useState([
      { name: "request1", label: "1" },
      { name: "request2", label: "2" },
      { name: "request3", label: "3" },
      { name: "request4", label: "4" },
      { name: "request5", label: "5" },
    ]);

    const addIllustrationRow = e => {
      e.preventDefault();
      const newIllustration = {
        name: `illustration${illustrations.length + 1}`,
        label: `${illustrations.length + 1}`,
        rows: 3,
      };
      setIllustrations([...illustrations, newIllustration]);
    };

    const removeIllustrationRow = index => {
      setIllustrations(illustrations.filter((_, i) => i !== index));
    };

    const addRequestRow = e => {
      e.preventDefault();
      const newRequest = {
        name: `request${requests.length + 1}`,
        label: `${requests.length + 1}`,
      };
      setRequests([...requests, newRequest]);
    };

    const removeRequestRow = index => {
      setRequests(requests.filter((_, i) => i !== index));
    };

    return (
      <Formik
        enableReinitialize
        initialValues={{
          policy_number,
          carrier,
          carrier_fax,
          carrier_notes,
          owner_names,
          insured_names,
          illustration1,
          illustration2,
          illustration3,
          request1,
          request2,
          request3,
          request4,
          request5,
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          console.log("onSubmit", values);
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => {
          const NextStepButton = () => {
            const onClick = async () => {
              props.resetPolicyRequestFiles();
              setStep(2);
              values.illustrations = illustrations;
              values.requests = requests;
              await props.generatePolicyRequestFiles(props.activeCaseFile.id, props.caseInfo.id, values);
              props.fetchInsured(props.caseInfo.id);
              props.fetchPolicy(props.caseInfo.policy);
              props.fetchCaseFilesByCaseDocumentId(props.activeCaseFile.id);
            };

            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="btn btn--secondary"
                  onClick={onClick}
                  onMouseDown={onClick}
                  style={{
                    width: "99%",
                    marginBottom: "1rem",
                  }}
                >
                  Next Step: Review Policy Request
                </button>
              </div>
            );
          };

          return (
            <>
              <FormCollapsible title="Policy Information Review">
                <Form.Row>
                  <TextInput label="Policy Number" name="policy_number" isRequired={true} />
                </Form.Row>
                <Form.Row>
                  <Select
                    label="Carrier"
                    name="carrier"
                    placeholder="Select Carrier"
                    options={props.carrierChoices.getChoices()}
                    onChange={e => {
                      handleCarrierChange(e);
                      setFieldValue("vendor", e);
                    }}
                    isRequired={true}
                  />
                  <TextInput label="Carrier Fax" name="carrier_fax" isRequired={true} />
                </Form.Row>
                <Form.Row>
                  <EditorField label="Carrier Notes" name="carrier_notes" disabled={true} />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Owner(s)" name="owner_names" disabled />
                  <TextInput label="Insured(s)" name="insured_names" disabled />
                </Form.Row>
              </FormCollapsible>
              <FormCollapsible title="Illustrations Requested">
                {illustrations.map((illustration, index) => (
                  <Form.Row key={index} style={{ alignItems: "center" }}>
                    <RequestRow name={illustration.name} label={illustration.label} rows={illustration.rows} />
                    <Button style={{ height: 35, marginTop: -10 }} onClick={() => removeIllustrationRow(index)}>
                      Remove
                    </Button>
                  </Form.Row>
                ))}
                <Form.Row>
                  <ButtonCreate onClick={addIllustrationRow}>Add</ButtonCreate>
                </Form.Row>
              </FormCollapsible>
              <FormCollapsible title="Other Requests">
                {requests.map((request, index) => (
                  <Form.Row key={index} style={{ alignItems: "center" }}>
                    <RequestRow name={request.name} label={request.label} rows={1} />
                    <Button style={{ height: 35, marginTop: -10 }} onClick={() => removeRequestRow(index)}>
                      Remove
                    </Button>
                  </Form.Row>
                ))}
                <Form.Row>
                  <ButtonCreate onClick={addRequestRow}>Add</ButtonCreate>
                </Form.Row>
              </FormCollapsible>
              <NextStepButton />
            </>
          );
        }}
      </Formik>
    );
  };

  const FilesTable = () => {
    let faxCoverFile = "";
    let pirFile = "";
    let poaFile = "";
    let policyRequestFile = "";
    let sendEnabled = true;
    let pirRows = [];
    let poaRows = [];
    if (props.activeOrderFiles?.fax_cover) {
      faxCoverFile = <File style={{ marginBottom: 0 }} file={props.activeOrderFiles.fax_cover} />;

      if (props.activeOrderFiles?.policy_request) {
        policyRequestFile = <File style={{ marginBottom: 0 }} file={props.activeOrderFiles.policy_request} />;
      }

      if (props.activeOrderFiles?.pir_data) {
        for (let data of props.activeOrderFiles?.pir_data) {
          let name = `Policy Info Release (${data.owner})`;
          if (data.status === "OK") {
            pirFile = <File style={{ marginBottom: 0 }} file={data.file} />;
          } else {
            pirFile = <div dangerouslySetInnerHTML={{ __html: data.message }} />;
            sendEnabled = false;
          }
          pirRows.push(
            <tr>
              <td>{name}</td>
              <td>{pirFile}</td>
            </tr>,
          );
        }
      }

      if (props.activeOrderFiles?.poa_data) {
        for (let data of props.activeOrderFiles?.poa_data) {
          let name = `OPOA (${data.owner})`;
          if (data.message) {
            poaFile = <div dangerouslySetInnerHTML={{ __html: data.message }} />;
            if (data.status === "Error") {
              sendEnabled = false;
            }
          } else {
            poaFile = <File style={{ marginBottom: 0 }} file={data.file} />;
          }
          poaRows.push(
            <tr>
              <td>{name}</td>
              <td>{poaFile}</td>
            </tr>,
          );
        }
      }

      setSendAvailable(sendEnabled);
    }
    return (
      <>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Type</th>
              <th>File</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Fax Cover</td>
              <td>{faxCoverFile}</td>
            </tr>
            {pirRows}
            {poaRows}
          </tbody>
        </Table>
        {policyRequestFile ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{policyRequestFile}</div>
        ) : null}
        <br />
        <SendButton />
      </>
    );
  };

  return (
    <FullPageModal modalType={POLICY_REQUEST_MODAL} title={"Policy Request"}>
      {step === 1 && <ReviewInfoForm />}
      {step === 2 && <FilesTable />}
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);

  return {
    caseInfo: activeCaseSelector(state),
    carrierChoices: choices.carriers,
    carriersById: arrayToObjectIndexedById(carriersSelector(state)),
    policy: policySelector(state),
    activeCaseFile: activeCaseFileSelector(state),
    activeOrderFiles: activeOrderFilesSelector(state),
    policyRequestOptions: policyRequestOptionsSelector(state),
    insuredList: insuredListSelector(state),
    ownerList: ownerListSelector(state),
    loading: loadingSelector(state),
  };
};

export default connect(mapStateToProps, {
  generatePolicyRequestFiles,
  resetPolicyRequestFiles,
  sendPolicyRequestFax,
  fetchInsured,
  fetchCaseFilesByCaseDocumentId,
  hideModal,
  fetchPolicy,
  fetchCarriers,
  updatePolicyRequestOption,
})(PolicyRequestModal);
