import {
  SELECT_PRODUCT,
  FETCH_PRODUCTS,
  EDIT_PRODUCT,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  FETCH_PRODUCT,
  FETCH_PRODUCT_CHOICES,
} from "actions/types";
import { arrayToObjectIndexedById } from "reducers/utils";
import { removeKey } from "../../../utils";

export const defaultState = {
  products: {},
  activeProductId: null,
  choices: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_PRODUCT:
      return { ...state, activeProductId: action.payload };

    case FETCH_PRODUCT: {
      let newState = {
        ...state,
      };
      newState.products[action.payload.id] = action.payload;
      return newState;
    }

    case FETCH_PRODUCTS:
      return { ...state, products: arrayToObjectIndexedById(action.payload) };
    case EDIT_PRODUCT:
    case CREATE_PRODUCT: {
      return {
        ...state,
        products: {
          ...state.products,
          [action.payload.id]: action.payload,
        },
        activeProductId: action.payload.id,
      };
    }

    case FETCH_PRODUCT_CHOICES:
      return { ...state, choices: action.payload.products };

    case DELETE_PRODUCT: {
      return {
        ...state,
        products: removeKey(state.products, action.payload),
      };
    }
    default:
      return state;
  }
};

export const activeProductSelector = ({ products, activeProductId }) => {
  if (activeProductId) {
    return products[activeProductId];
  }
};

export const productsSelector = state => {
  return Object.values(state.products);
};

export const productChoicesSelector = state => state.choices;
