// Auth
export const LOGIN_FIRST_STEP_SUCCESSFUL = "LOGIN_FIRST_STEP_SUCCESSFUL";
export const LOGIN_SECOND_STEP_SUCCESSFUL = "LOGIN_SECOND_STEP_SUCCESSFUL";
export const SELECT_AGENCY = "SELECT_AGENCY";
export const AUTH_ERROR = "AUTH_ERROR";
export const SMS_ERROR = "SMS_ERROR";
export const LOGIN_ATTEMPT = "LOGIN_ATTEMPT";
export const LOGIN_CANCEL = "LOGIN_CANCEL";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SET_PASSWORD = "SET_PASSWORD";
export const LOGOUT = "LOGOUT";

// Modal
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

// Life settlement
export const SELECT_LIFE_SETTLEMENT_CASE = "SELECT_LIFE_SETTLEMENT_CASE";
export const CLEAR_CASES_DATA = "CLEAR_CASES_DATA";
export const FETCH_CASE_PDF = "FETCH_CASE_PDF";
export const STORE_MAILING_DATA = "STORE_MAILING_DATA";

// Case files
export const SELECT_CASE_FILE = "SELECT_CASE_FILE";
export const FETCH_CASE_FILES = "FETCH_CASE_FILES";
export const CLEAR_CASE_FILES = "CLEAR_CASE_FILES";
export const CLEAR_ACTIVE_CASE_FILE = "CLEAR_ACTIVE_CASE_FILE";
export const FETCH_CASE_FILE = "FETCH_CASE_FILE";
export const EDIT_CASE_FILE = "EDIT_CASE_FILE";
export const EDIT_MULTIPLE_CASE_FILES = "EDIT_MULTIPLE_CASE_FILES";
export const CREATE_CASE_FILE = "CREATE_CASE_FILE";
export const DELETE_CASE_FILE = "DELETE_CASE_FILE";
export const DELETE_CASE_FILE_FILE = "DELETE_CASE_FILE_FILE";
export const STORE_CASE_FILE_CATEGORY = "STORE_CASE_FILE_CATEGORY";
export const CLEAR_CASE_FILE_CATEGORY = "CLEAR_CASE_FILE_CATEGORY";
export const STORE_CASE_FILE_DESCRIPTION = "STORE_CASE_FILE_DESCRIPTION";
export const CLEAR_CASE_FILE_DESCRIPTION = "CLEAR_CASE_FILE_DESCRIPTION";

// Life settlement: List
export const FETCH_LIFE_SETTLEMENT_CASES = "FETCH_LIFE_SETTLEMENT_CASES";
export const SET_CASES_PAGINATION_DATA = "SET_CASES_PAGINATION_DATA";
export const SET_LES_PAGINATION_DATA = "SET_LES_PAGINATION_DATA";
export const EDIT_LIFE_SETTLEMENT_CASE = "EDIT_LIFE_SETTLEMENT_CASE";
export const CREATE_EMPTY_LIFE_SETTLEMENT_CASE = "CREATE_EMPTY_LIFE_SETTLEMENT_CASE";

// Life settlement: management
export const FETCH_LIFE_SETTLEMENT_CASE = "FETCH_LIFE_SETTLEMENT_CASE";
export const FETCH_LIFE_SETTLEMENT_CHOICES = "FETCH_LIFE_SETTLEMENT_CHOICES";
export const DELETE_LIFE_SETTLEMENT_CASE = "DELETE_LIFE_SETTLEMENT_CASE";
export const CLEAN_LIFE_SETTLEMENT_CASE_DATA = "CLEAN_LIFE_SETTLEMENT_CASE_DATA";
export const CLEAN_LIFE_SETTLEMENT_SEARCH_DATA = "CLEAN_LIFE_SETTLEMENT_SEARCH_DATA";
export const CLEAN_CASES_PAGINATION_DATA = "CLEAN_CASES_PAGINATION_DATA";
export const CLEAN_RECORDS_PAGINATION_DATA = "CLEAN_RECORDS_PAGINATION_DATA";
export const FETCH_LIFE_SETTLEMENT_CASE_ERROR = "FETCH_LIFE_SETTLEMENT_CASE_ERROR";

export const FETCH_POLICY = "FETCH_POLICY";
export const EDIT_POLICY = "EDIT_POLICY";

export const SELECT_INSURED = "SELECT_INSURED";
export const FETCH_INSURED = "FETCH_INSURED";
export const EDIT_INSURED_INFO = "EDIT_INSURED_INFO";
export const CREATE_EMPTY_INSURED_INFO = "CREATE_EMPTY_INSURED_INFO";
export const DELETE_INSURED_INFO = "DELETE_INSURED_INFO";

export const FETCH_LIFE_EXPECTANCIES = "FETCH_LIFE_EXPECTANCIES";
export const FETCH_ALL_LIFE_EXPECTANCIES = "FETCH_ALL_LIFE_EXPECTANCIES";
export const EDIT_LIFE_EXPECTANCY = "EDIT_LIFE_EXPECTANCY";
export const CREATE_LIFE_EXPECTANCY = "CREATE_LIFE_EXPECTANCY";
export const FETCH_LIFE_EXPECTANCY_CHOICES = "FETCH_LIFE_EXPECTANCY_CHOICES";
export const SELECT_LIFE_EXPECTANCY = "SELECT_LIFE_EXPECTANCY";
export const EXTRACT_LIFE_EXPECTANCY_PDF_DATA = "EXTRACT_LIFE_EXPECTANCY_PDF_DATA";
export const DELETE_LIFE_EXPECTANCY = "DELETE_LIFE_EXPECTANCY";
export const FETCH_21ST_MEDICAL_CONDITIONS_SCHEMA = "FETCH_21ST_MEDICAL_CONDITIONS_SCHEMA";
export const FETCH_PREDICTIVE_MEDICAL_CONDITIONS_SCHEMA = "FETCH_PREDICTIVE_MEDICAL_CONDITIONS_SCHEMA";

export const FETCH_OWNER_PERSONS = "FETCH_OWNER_PERSONS";
export const EDIT_OWNER_PERSON = "EDIT_OWNER_PERSON";
export const CREATE_OWNER_PERSON = "CREATE_OWNER_PERSON";
export const DELETE_OWNER_PERSON = "DELETE_OWNER_PERSON";

export const FETCH_OWNER_INSUREDS = "FETCH_OWNER_INSUREDS";
export const EDIT_OWNER_INSURED = "EDIT_OWNER_INSURED";
export const CREATE_OWNER_INSURED = "CREATE_OWNER_INSURED";
export const DELETE_OWNER_INSURED = "DELETE_OWNER_INSURED";

export const FETCH_OWNER_SIGNERS = "FETCH_OWNER_SIGNERS";
export const EDIT_OWNER_SIGNER = "EDIT_OWNER_SIGNER";
export const CREATE_OWNER_SIGNER = "CREATE_OWNER_SIGNER";
export const DELETE_OWNER_SIGNER = "DELETE_OWNER_SIGNER";

export const CREATE_INSURED_DILIGENCE = "CREATE_INSURED_DILIGENCE";
export const DELETE_INSURED_DILIGENCE = "DELETE_INSURED_DILIGENCE";
export const EDIT_INSURED_DILIGENCE = "EDIT_INSURED_DILIGENCE";
export const FETCH_INSURED_DILIGENCES = "FETCH_INSURED_DILIGENCES";
export const SELECT_INSURED_DILIGENCE = "SELECT_INSURED_DILIGENCE";

export const FETCH_OWNER_ENTITIES = "FETCH_OWNER_ENTITIES";
export const EDIT_OWNER_ENTITY = "EDIT_OWNER_ENTITY";
export const CREATE_OWNER_ENTITY = "CREATE_OWNER_ENTITY";
export const DELETE_OWNER_ENTITY = "DELETE_OWNER_ENTITY";
export const SELECT_OWNER = "SELECT_OWNER";

export const SELECT_HIPAA = "SELECT_HIPAA";
export const FETCH_HIPAAS = "FETCH_HIPAAS";
export const CREATE_HIPAA = "CREATE_HIPAA";
export const EDIT_HIPAA = "EDIT_HIPAA";
export const DELETE_HIPAA = "DELETE_HIPAA";

export const SELECT_MEDICAL_RECORD = "SELECT_MEDICAL_RECORD";
export const FETCH_MEDICAL_RECORDS = "FETCH_MEDICAL_RECORDS";
export const CREATE_MEDICAL_RECORD = "CREATE_MEDICAL_RECORD";
export const EDIT_MEDICAL_RECORD = "EDIT_MEDICAL_RECORD";
export const DELETE_MEDICAL_RECORD = "DELETE_MEDICAL_RECORD";

export const CREATE_BENEFICIARY = "CREATE_BENEFICIARY";
export const DELETE_BENEFICIARY = "DELETE_BENEFICIARY";
export const EDIT_BENEFICIARY = "EDIT_BENEFICIARY";
export const FETCH_BENEFICIARIES = "FETCH_BENEFICIARIES";
export const CREATE_BENEFICIARY_SIGNER = "CREATE_BENEFICIARY_SIGNER";
export const EDIT_BENEFICIARY_SIGNER = "EDIT_BENEFICIARY_SIGNER";
export const DELETE_BENEFICIARY_SIGNER = "DELETE_BENEFICIARY_SIGNER";

export const FETCH_BILLING_NOTICES = "FETCH_BILLING_NOTICES";
export const CREATE_BILLING_NOTICE = "CREATE_BILLING_NOTICE";
export const EDIT_BILLING_NOTICE = "EDIT_BILLING_NOTICE";
export const DELETE_BILLING_NOTICE = "DELETE_BILLING_NOTICE";
export const SELECT_BILLING_NOTICE = "SELECT_BILLING_NOTICE";

export const FETCH_CHAIN_OF_TITLES = "FETCH_CHAIN_OF_TITLES";
export const CREATE_CHAIN_OF_TITLE = "CREATE_CHAIN_OF_TITLE";
export const EDIT_CHAIN_OF_TITLE = "EDIT_CHAIN_OF_TITLE";
export const DELETE_CHAIN_OF_TITLE = "DELETE_CHAIN_OF_TITLE";
export const SELECT_CHAIN_OF_TITLE = "SELECT_CHAIN_OF_TITLE";

export const FETCH_ILLUSTRATIONS = "FETCH_ILLUSTRATIONS";
export const CREATE_ILLUSTRATION = "CREATE_ILLUSTRATION";
export const EDIT_ILLUSTRATION = "EDIT_ILLUSTRATION";
export const DELETE_ILLUSTRATION = "DELETE_ILLUSTRATION";
export const SELECT_ILLUSTRATION = "SELECT_ILLUSTRATION";

export const FETCH_POLICY_AND_APPLICATIONS = "FETCH_POLICY_AND_APPLICATIONS";
export const CREATE_POLICY_AND_APPLICATION = "CREATE_POLICY_AND_APPLICATION";
export const EDIT_POLICY_AND_APPLICATION = "EDIT_POLICY_AND_APPLICATION";
export const DELETE_POLICY_AND_APPLICATION = "DELETE_POLICY_AND_APPLICATION";
export const SELECT_POLICY_AND_APPLICATION = "SELECT_POLICY_AND_APPLICATION";

export const FETCH_STATEMENTS = "FETCH_STATEMENTS";
export const CREATE_STATEMENT = "CREATE_STATEMENT";
export const EDIT_STATEMENT = "EDIT_STATEMENT";
export const DELETE_STATEMENT = "DELETE_STATEMENT";
export const SELECT_STATEMENT = "SELECT_STATEMENT";

export const FETCH_VERIFICATIONS_OF_COVERAGE = "FETCH_VERIFICATIONS_OF_COVERAGE";
export const CREATE_VERIFICATION_OF_COVERAGE = "CREATE_VERIFICATION_OF_COVERAGE";
export const EDIT_VERIFICATION_OF_COVERAGE = "EDIT_VERIFICATION_OF_COVERAGE";
export const DELETE_VERIFICATION_OF_COVERAGE = "DELETE_VERIFICATION_OF_COVERAGE";
export const SELECT_VERIFICATION_OF_COVERAGE = "SELECT_VERIFICATION_OF_COVERAGE";

export const CREATE_ENTITY_DILIGENCE = "CREATE_ENTITY_DILIGENCE";
export const DELETE_ENTITY_DILIGENCE = "DELETE_ENTITY_DILIGENCE";
export const EDIT_ENTITY_DILIGENCE = "EDIT_ENTITY_DILIGENCE";
export const FETCH_ENTITY_DILIGENCES = "FETCH_ENTITY_DILIGENCES";
export const SELECT_ENTITY_DILIGENCE = "SELECT_ENTITY_DILIGENCE";

export const CREATE_PERSON_DILIGENCE = "CREATE_PERSON_DILIGENCE";
export const DELETE_PERSON_DILIGENCE = "DELETE_PERSON_DILIGENCE";
export const EDIT_PERSON_DILIGENCE = "EDIT_PERSON_DILIGENCE";
export const FETCH_PERSON_DILIGENCES = "FETCH_PERSON_DILIGENCES";
export const SELECT_PERSON_DILIGENCE = "SELECT_PERSON_DILIGENCE";

export const CREATE_SIGNER_DILIGENCE = "CREATE_SIGNER_DILIGENCE";
export const DELETE_SIGNER_DILIGENCE = "DELETE_SIGNER_DILIGENCE";
export const EDIT_SIGNER_DILIGENCE = "EDIT_SIGNER_DILIGENCE";
export const FETCH_SIGNER_DILIGENCES = "FETCH_SIGNER_DILIGENCES";
export const SELECT_SIGNER_DILIGENCE = "SELECT_SIGNER_DILIGENCE";

export const FETCH_SECONDARY_MARKET_CLOSINGS = "FETCH_SECONDARY_MARKET_CLOSINGS";
export const CREATE_SECONDARY_MARKET_CLOSING = "CREATE_SECONDARY_MARKET_CLOSING";
export const EDIT_SECONDARY_MARKET_CLOSING = "EDIT_SECONDARY_MARKET_CLOSING";
export const DELETE_SECONDARY_MARKET_CLOSING = "DELETE_SECONDARY_MARKET_CLOSING";
export const SELECT_SECONDARY_MARKET_CLOSING = "SELECT_SECONDARY_MARKET_CLOSING";

export const FETCH_TERTIARY_MARKET_CLOSINGS = "FETCH_TERTIARY_MARKET_CLOSINGS";
export const CREATE_TERTIARY_MARKET_CLOSING = "CREATE_TERTIARY_MARKET_CLOSING";
export const EDIT_TERTIARY_MARKET_CLOSING = "EDIT_TERTIARY_MARKET_CLOSING";
export const DELETE_TERTIARY_MARKET_CLOSING = "DELETE_TERTIARY_MARKET_CLOSING";
export const SELECT_TERTIARY_MARKET_CLOSING = "SELECT_TERTIARY_MARKET_CLOSING";

export const FETCH_AUCTION_CHOICES = "FETCH_AUCTION_CHOICES";

export const FETCH_AUCTION_ROUNDS = "FETCH_AUCTION_ROUNDS";
export const SELECT_AUCTION_ROUND = "SELECT_AUCTION_ROUND";
export const CREATE_AUCTION_ROUND = "CREATE_AUCTION_ROUND";
export const EDIT_AUCTION_ROUND = "EDIT_AUCTION_ROUND";
export const DELETE_AUCTION_ROUND = "DELETE_AUCTION_ROUND";

export const FETCH_AUCTION = "FETCH_AUCTION";
export const EDIT_AUCTION = "EDIT_AUCTION";
export const FETCH_AUCTION_ACTIVITIES = "FETCH_AUCTION_ACTIVITIES";
export const FETCH_MARKET_AUCTION_ACTIVITIES = "FETCH_MARKET_AUCTION_ACTIVITIES";
export const FETCH_FUNDER_AUCTION_ACTIVITIES = "FETCH_FUNDER_AUCTION_ACTIVITIES";
export const GET_GROSS_OFFER = "GET_GROSS_OFFER";
export const SELECT_AUCTION_ACTIVITY = "SELECT_AUCTION_ACTIVITY";
export const CREATE_AUCTION_ACTIVITY = "CREATE_AUCTION_ACTIVITY";
export const CREATE_FUNDER_AUCTION_ACTIVITY = "CREATE_FUNDER_AUCTION_ACTIVITY";
export const CREATE_MARKET_AUCTION_ACTIVITY = "CREATE_MARKET_AUCTION_ACTIVITY";
export const EDIT_AUCTION_ACTIVITY = "EDIT_AUCTION_ACTIVITY";
export const EDIT_FUNDER_AUCTION_ACTIVITY = "EDIT_FUNDER_AUCTION_ACTIVITY";
export const EDIT_MARKET_AUCTION_ACTIVITY = "EDIT_MARKET_AUCTION_ACTIVITY";
export const DELETE_AUCTION_ACTIVITY = "DELETE_AUCTION_ACTIVITY";
export const DELETE_MARKET_AUCTION_ACTIVITY = "DELETE_MARKET_AUCTION_ACTIVITY";
export const DELETE_FUNDER_AUCTION_ACTIVITY = "DELETE_FUNDER_AUCTION_ACTIVITY";

export const FETCH_TRADING_CASES = "FETCH_TRADING_CASES";
export const SET_TRADING_PAGINATION_DATA = "SET_TRADING_PAGINATION_DATA";

export const FETCH_FUNDERS = "FETCH_FUNDERS";
export const FETCH_FUNDER_ACTIVITY_REPORT = "FETCH_FUNDER_ACTIVITY_REPORT";
export const FETCH_FUNDER_ACTIVITY_REPORT_TOTALS = "FETCH_FUNDER_ACTIVITY_REPORT_TOTALS";
export const SELECT_FUNDER = "SELECT_FUNDER";
export const DESELECT_FUNDER = "DESELECT_FUNDER";
export const DESELECT_ALL_FUNDERS = "DESELECT_ALL_FUNDERS";
export const SET_SELECTED_FUNDERS = "SET_SELECTED_FUNDERS";
export const PARTIAL_FETCH_FUNDERS = "PARTIAL_FETCH_FUNDERS";
export const SET_SUGGESTED_CC = "SET_SUGGESTED_CC";
export const FUNDER_SEARCH_STRING = "FUNDER_SEARCH_STRING";
export const FETCH_FUNDERS_FOR_AUCTION = "FETCH_FUNDERS_FOR_AUCTION";
export const FETCH_FUNDER = "FETCH_FUNDER";
export const EDIT_FUNDER = "EDIT_FUNDER";
export const EDIT_FUNDER_CONTACT = "EDIT_FUNDER_CONTACT";
export const EDIT_FUNDER_ENTITY = "EDIT_FUNDER_ENTITY";
export const CREATE_EMPTY_FUNDER = "CREATE_EMPTY_FUNDER";
export const DELETE_FUNDER = "DELETE_FUNDER";
export const DELETE_FUNDER_CONTACT = "DELETE_FUNDER_CONTACT";
export const DELETE_FUNDER_ENTITY = "DELETE_FUNDER_ENTITY";
export const FETCH_FUNDER_CASES = "FETCH_FUNDER_CASES";
export const FETCH_FUNDER_CASE_FITS = "FETCH_FUNDER_CASE_FITS";
export const FETCH_FUNDER_CONTACTS = "FETCH_FUNDER_CONTACTS";
export const FETCH_FUNDER_ENTITIES = "FETCH_FUNDER_ENTITIES";
export const SELECT_FUNDER_CONTACT = "SELECT_FUNDER_CONTACT";
export const SELECT_FUNDER_ENTITY = "SELECT_FUNDER_ENTITY";
export const UPDATE_FUNDER_FILTERS = "UPDATE_FUNDER_FILTERS";
export const CLEAR_SELECTED_FUNDER = "CLEAR_SELECTED_FUNDER";

export const FETCH_CLOSING_FUNDER_ENTITIES = "FETCH_CLOSING_FUNDER_ENTITIES";
export const FETCH_TRADING_FUNDER_ENTITIES = "FETCH_TRADING_FUNDER_ENTITIES";

export const CREATE_SECURITIES_INTERMEDIARY = "CREATE_SECURITIES_INTERMEDIARY";
export const EDIT_SECURITIES_INTERMEDIARY = "EDIT_SECURITIES_INTERMEDIARY";
export const SELECT_SECURITY_INTERMEDIARY = "SELECT_SECURITY_INTERMEDIARY";
export const DELETE_SECURITY_INTERMEDIARY = "DELETE_SECURITY_INTERMEDIARY";

export const CREATE_TRADING_DOCUMENT = "CREATE_TRADING_DOCUMENT";
export const EDIT_TRADING_DOCUMENT = "EDIT_TRADING_DOCUMENT";
export const SELECT_TRADING_DOCUMENT = "SELECT_TRADING_DOCUMENT";
export const DELETE_TRADING_DOCUMENT = "DELETE_TRADING_DOCUMENT";

export const FETCH_AGENTS_CHOICES = "FETCH_AGENTS_CHOICES";

export const LOADING = "LOADING";

export const CREATE_EMAIL_TEMPLATE = "CREATE_EMAIL_TEMPLATE";
export const EDIT_EMAIL_TEMPLATE = "EDIT_EMAIL_TEMPLATE";
export const FETCH_EMAIL_TEMPLATE_LIST = "FETCH_EMAIL_TEMPLATE_LIST";
export const SET_EMAIL_TEMPLATE = "SET_EMAIL_TEMPLATE";
export const SET_EMAIL_TEMPLATE_TYPE = "SET_EMAIL_TEMPLATE_TYPE";
export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE";

export const FETCH_MARKET_ACTIVITY_FILES = "FETCH_MARKET_ACTIVITY_FILES";

export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const STORE_CASES_FILTERS_DATA = "STORE_CASES_FILTERS_DATA";
export const STORE_TRADING_FILTERS_DATA = "STORE_TRADING_FILTERS_DATA";
export const STORE_TRADING_STAGE_FILTER = "STORE_TRADING_STAGE_FILTER";
export const STORE_LE_FILTERS_DATA = "STORE_LE_FILTERS_DATA";
export const SORT_BY = "SORT_BY";
export const FUNDERS_SORT_BY = "FUNDERS_SORT_BY";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";

export const STORE_RELATED_LE = "STORE_RELATED_LE";
export const SET_LE_TABLE_INDEX = "SET_LE_TABLE_INDEX";

export const SHOULD_SAVE = "SHOULD_SAVE";

// Records
export const FETCH_RECORDS = "FETCH_RECORDS";
export const FETCH_CASE_RECORDS = "FETCH_CASE_RECORDS";
export const FETCH_CASE_REQUIREMENT_RECORDS = "FETCH_CASE_REQUIREMENT_RECORDS";
export const FETCH_ACCOUNT_RECORDS = "FETCH_ACCOUNT_RECORDS";
export const FETCH_LEAD_RECORDS = "FETCH_LEAD_RECORDS";
export const SELECT_RECORD_ID = "SELECT_RECORD_ID";
export const SELECT_ACTIVE_RECORD_TYPE = "SELECT_ACTIVE_RECORD_TYPE";

export const STORE_RECORDS_FILTERS_DATA = "STORE_RECORDS_FILTERS_DATA";
export const STORE_CASE_RECORDS_FILTERS_DATA = "STORE_CASE_RECORDS_FILTERS_DATA";
export const STORE_CASE_REQUIREMENT_RECORDS_FILTERS_DATA = "STORE_CASE_REQUIREMENT_RECORDS_FILTERS_DATA";
export const STORE_ACCOUNT_RECORDS_FILTERS_DATA = "STORE_ACCOUNT_RECORDS_FILTERS_DATA";
export const STORE_LEAD_RECORDS_FILTERS_DATA = "STORE_LEAD_RECORDS_FILTERS_DATA";

export const SET_RECORDS_PAGINATION_DATA = "SET_RECORDS_PAGINATION_DATA";
export const STORE_CASE_RECORDS_PAGINATION_DATA = "STORE_CASE_RECORDS_PAGINATION_DATA";
export const STORE_CASE_REQUIREMENT_RECORDS_PAGINATION_DATA = "STORE_CASE_REQUIREMENT_RECORDS_PAGINATION_DATA";
export const STORE_ACCOUNT_RECORDS_PAGINATION_DATA = "STORE_ACCOUNT_RECORDS_PAGINATION_DATA";
export const STORE_LEAD_RECORDS_PAGINATION_DATA = "STORE_LEAD_RECORDS_PAGINATION_DATA";

// Activity Log
export const FETCH_ACTIVITY_LOG = "FETCH_ACTIVITY_LOG";

// Products
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const SELECT_PRODUCT = "SELECT_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const FETCH_PRODUCT_CHOICES = "FETCH_PRODUCT_CHOICES";

// Carriers
export const FETCH_CARRIERS = "FETCH_CARRIERS";
export const SELECT_CARRIER = "SELECT_CARRIER";
export const EDIT_CARRIER = "EDIT_CARRIER";
export const CREATE_CARRIER = "CREATE_CARRIER";
export const DELETE_CARRIER = "DELETE_CARRIER";
export const FETCH_CARRIER = "FETCH_CARRIER";
export const CREATE_EMPTY_CARRIER = "CREATE_EMPTY_CARRIER";
export const CREATE_CARRIER_FILE = "CREATE_CARRIER_FILE";
export const EDIT_CARRIER_FILE = "EDIT_CARRIER_FILE";
export const SELECT_CARRIER_FILE = "SELECT_CARRIER_FILE";

// Accounts
export const FETCH_ACCOUNTS = "FETCH_ACCOUNTS";
export const CLEAN_ACCOUNT_CASES = "CLEAN_ACCOUNT_CASES";
export const FETCH_ACCOUNT_CASES = "FETCH_ACCOUNT_CASES";
export const CLEAR_SELECTED_ACCOUNT = "CLEAR_SELECTED_ACCOUNT";
export const SELECT_ACCOUNT = "SELECT_ACCOUNT";
export const SELECT_MANAGE_ENTITY = "SELECT_MANAGE_ENTITY";
export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const FETCH_ACCOUNT = "FETCH_ACCOUNT";
export const FETCH_ACCOUNT_ENTITY_CHOICES = "FETCH_ACCOUNT_ENTITY_CHOICES";
export const SET_ACCOUNTS_PAGINATION_DATA = "SET_ACCOUNTS_PAGINATION_DATA";
export const STORE_ACCOUNTS_FILTERS_DATA = "STORE_ACCOUNTS_FILTERS_DATA";
export const FETCH_ENGAGEMENT_ACTIVITIES = "FETCH_ENGAGEMENT_ACTIVITIES";
export const SELECT_ACCOUNT_LIST = "SELECT_ACCOUNT_LIST";
export const DESELECT_ACCOUNT_LIST = "DESELECT_ACCOUNT_LIST";

// Accounts - Task
export const SELECT_ACCOUNT_TASK = "SELECT_ACCOUNT_TASK";
export const CREATE_ACCOUNT_TASK = "CREATE_ACCOUNT_TASK";
export const EDIT_ACCOUNT_TASK = "EDIT_ACCOUNT_TASK";
export const DELETE_ACCOUNT_TASK = "DELETE_ACCOUNT_TASK";

// Accounts - Carrier Appointment
export const SELECT_ACCOUNT_CARRIER_APPOINTMENT = "SELECT_ACCOUNT_CARRIER_APPOINTMENT";
export const CREATE_ACCOUNT_CARRIER_APPOINTMENT = "CREATE_ACCOUNT_CARRIER_APPOINTMENT";
export const EDIT_ACCOUNT_CARRIER_APPOINTMENT = "EDIT_ACCOUNT_CARRIER_APPOINTMENT";

// Accounts - License
export const SELECT_ACCOUNT_LICENSE = "SELECT_ACCOUNT_LICENSE";
export const CREATE_ACCOUNT_LICENSE = "CREATE_ACCOUNT_LICENSE";
export const EDIT_ACCOUNT_LICENSE = "EDIT_ACCOUNT_LICENSE";

// Accounts - Agreement
export const SELECT_ACCOUNT_AGREEMENT = "SELECT_ACCOUNT_AGREEMENT";
export const CREATE_ACCOUNT_AGREEMENT = "CREATE_ACCOUNT_AGREEMENT";
export const EDIT_ACCOUNT_AGREEMENT = "EDIT_ACCOUNT_AGREEMENT";
export const DELETE_ACCOUNT_AGREEMENT = "DELETE_ACCOUNT_AGREEMENT";

// Accounts - Hierarchy
export const FETCH_HIERARCHY = "FETCH_HIERARCHY";
export const SELECT_ACCOUNT_HIERARCHY = "SELECT_ACCOUNT_HIERARCHY";
export const CLEAR_ACCOUNT_HIERARCHY = "CLEAR_ACCOUNT_HIERARCHY";
export const CREATE_ACCOUNT_HIERARCHY = "CREATE_ACCOUNT_HIERARCHY";
export const EDIT_ACCOUNT_HIERARCHY = "EDIT_ACCOUNT_HIERARCHY";
export const DELETE_ACCOUNT_HIERARCHY = "DELETE_ACCOUNT_HIERARCHY";
export const SELECT_ACCOUNT_HIERARCHY_SPLIT = "SELECT_ACCOUNT_HIERARCHY_SPLIT";
export const CREATE_ACCOUNT_HIERARCHY_SPLIT = "CREATE_ACCOUNT_HIERARCHY_SPLIT";
export const EDIT_ACCOUNT_HIERARCHY_SPLIT = "EDIT_ACCOUNT_HIERARCHY_SPLIT";
export const DELETE_ACCOUNT_HIERARCHY_SPLIT = "DELETE_ACCOUNT_HIERARCHY_SPLIT";
export const SELECT_SPLIT = "SELECT_SPLIT";
export const FETCH_SPLITS = "FETCH_SPLITS";
export const CLEAR_SPLITS = "CLEAR_SPLITS";

// Accounts - Affiliation
export const SELECT_ACCOUNT_AFFILIATION = "SELECT_ACCOUNT_AFFILIATION";
export const CREATE_ACCOUNT_AFFILIATION = "CREATE_ACCOUNT_AFFILIATION";
export const EDIT_ACCOUNT_AFFILIATION = "EDIT_ACCOUNT_AFFILIATION";
export const DELETE_ACCOUNT_AFFILIATION = "DELETE_ACCOUNT_AFFILIATION";

// Doctors
export const SELECT_DOCTOR = "SELECT_DOCTOR";

// Case File APS Order/Policy Request
export const FETCH_ORDER_FILES = "FETCH_ORDER_FILES";
export const RESET_ORDER_FILES = "RESET_ORDER_FILES";
export const UPDATE_POLICY_REQUEST_OPTION = "UPDATE_POLICY_REQUEST_OPTION";

// Leads
export const SELECT_LEAD = "SELECT_LEAD";
export const FETCH_LEAD = "FETCH_LEAD";
export const CLEAR_SELECTED_LEAD = "CLEAR_SELECTED_LEAD";
export const SET_LEADS_PAGINATION_DATA = "SET_LEADS_PAGINATION_DATA";
export const FETCH_LEADS = "FETCH_LEADS";
export const CREATE_LEAD = "CREATE_LEAD";
export const STORE_LEADS_FILTERS_DATA = "STORE_LEADS_FILTERS_DATA";
export const EDIT_LEAD = "EDIT_LEAD";
export const DELETE_LEAD = "DELETE_LEAD";
export const EDIT_LEAD_TASK = "EDIT_LEAD_TASK";
export const SELECT_LEAD_TASK = "SELECT_LEAD_TASK";
export const FETCH_LEAD_TASK = "FETCH_LEAD_TASK";
export const FETCH_LEAD_TASKS = "FETCH_LEAD_TASKS";
export const SELECT_LEAD_LIST = "SELECT_LEAD_LIST";
export const DESELECT_LEAD_LIST = "DESELECT_LEAD_LIST";
export const DESELECT_ALL_LEAD_LIST = "DESELECT_ALL_LEAD_LIST";

// Predictions
export const CLEAR_PREDICTIONS = "CLEAR_PREDICTIONS";
export const GET_PREDICTION = "GET_PREDICTION";

// Riskx Parameters
export const SELECT_PARAMETER = "SELECT_PARAMETER";
export const FETCH_PARAMETERS = "FETCH_PARAMETERS";
export const EDIT_PARAMETER = "EDIT_PARAMETER";
export const CREATE_PARAMETER = "CREATE_PARAMETER";
export const DELETE_PARAMETER = "DELETE_PARAMETER";
export const FETCH_PARAMETER = "FETCH_PARAMETER";
export const SET_PARAMETERS_PAGINATION_DATA = "SET_PARAMETERS_PAGINATION_DATA";
export const STORE_PARAMETERS_FILTERS_DATA = "STORE_PARAMETERS_FILTERS_DATA";

// Riskx Synonyms
export const SELECT_SYNONYM = "SELECT_SYNONYM";
export const FETCH_SYNONYM = "FETCH_SYNONYM";
export const FETCH_SYNONYMS = "FETCH_SYNONYMS";
export const CREATE_SYNONYM = "CREATE_SYNONYM";
export const EDIT_SYNONYM = "EDIT_SYNONYM";
export const DELETE_SYNONYM = "DELETE_SYNONYM";
export const FETCH_PARAMETER_SYNONYMS = "FETCH_PARAMETER_SYNONYMS";
export const EDIT_PARAMETER_SYNONYM = "EDIT_PARAMETER_SYNONYM";
export const CREATE_PARAMETER_SYNONYM = "CREATE_PARAMETER_SYNONYM";
export const DELETE_PARAMETER_SYNONYM = "DELETE_PARAMETER_SYNONYM";
export const SET_SYNONYMS_PAGINATION_DATA = "SET_SYNONYMS_PAGINATION_DATA";
export const STORE_SYNONYMS_FILTERS_DATA = "STORE_SYNONYMS_FILTERS_DATA";

// Mortality Assessment
export const SELECT_MORTALITY_ASSESSMENT = "SELECT_MORTALITY_ASSESSMENT";
export const FETCH_MORTALITY_ASSESSMENTS = "FETCH_MORTALITY_ASSESSMENTS";
export const EDIT_MORTALITY_ASSESSMENT = "EDIT_MORTALITY_ASSESSMENT";
export const CREATE_MORTALITY_ASSESSMENT = "CREATE_MORTALITY_ASSESSMENT";
export const DELETE_MORTALITY_ASSESSMENT = "DELETE_MORTALITY_ASSESSMENT";
export const FETCH_MORTALITY_ASSESSMENT = "FETCH_MORTALITY_ASSESSMENT";
export const SET_MORTALITY_ASSESSMENTS_PAGINATION_DATA = "SET_MORTALITY_ASSESSMENTS_PAGINATION_DATA";
export const STORE_MORTALITY_ASSESSMENTS_FILTERS_DATA = "STORE_MORTALITY_ASSESSMENTS_FILTERS_DATA";
export const SELECT_MORTALITY_ASSESSMENT_CONDITION = "SELECT_MORTALITY_ASSESSMENT_CONDITION";

// Case Tasks
export const SELECT_CASE_TASK = "SELECT_CASE_TASK";
export const CREATE_CASE_TASK = "CREATE_CASE_TASK";
export const EDIT_CASE_TASK = "EDIT_CASE_TASK";

// Clinical Research
export const SELECT_CLINICAL_RESEARCH = "SELECT_CLINICAL_RESEARCH";
export const FETCH_CLINICAL_RESEARCH_LIST = "FETCH_CLINICAL_RESEARCH_LIST";
export const EDIT_CLINICAL_RESEARCH = "EDIT_CLINICAL_RESEARCH";
export const CREATE_CLINICAL_RESEARCH = "CREATE_CLINICAL_RESEARCH";
export const DELETE_CLINICAL_RESEARCH = "DELETE_CLINICAL_RESEARCH";
export const FETCH_CLINICAL_RESEARCH = "FETCH_CLINICAL_RESEARCH";
export const SET_CLINICAL_RESEARCH_PAGINATION_DATA = "SET_CLINICAL_RESEARCH_PAGINATION_DATA";
export const STORE_CLINICAL_RESEARCH_FILTERS_DATA = "STORE_CLINICAL_RESEARCH_FILTERS_DATA";

// WinFlex
export const FETCH_WINFLEX_CHOICES = "FETCH_WINFLEX_CHOICES";

// States
export const SELECT_STATE = "SELECT_STATE";
export const FETCH_STATES = "FETCH_STATES";
export const EDIT_STATE = "EDIT_STATE";
export const CREATE_STATE = "CREATE_STATE";
export const DELETE_STATE = "DELETE_STATE";
export const FETCH_STATE = "FETCH_STATE";
export const SELECT_STATE_BROKER_FILE = "SELECT_STATE_BROKER_FILE";
export const SELECT_STATE_PROVIDER_FILE = "SELECT_STATE_PROVIDER_FILE";
export const EDIT_STATE_BROKER_FILE = "EDIT_STATE_BROKER_FILE";
export const EDIT_STATE_PROVIDER_FILE = "EDIT_STATE_PROVIDER_FILE";
export const CREATE_STATE_BROKER_FILE = "CREATE_STATE_BROKER_FILE";
export const CREATE_STATE_PROVIDER_FILE = "CREATE_STATE_PROVIDER_FILE";
export const CREATE_EMPTY_STATE = "CREATE_EMPTY_STATE";

// Custom Curves
export const FETCH_CUSTOM_CURVES = "FETCH_CUSTOM_CURVES";
