import React from "react";
import { Form } from "react-bootstrap";
import { Field } from "formik";

import MedicalFactorsList from "./MedicalFactorsList";

// Main component
const MedicalCondition = ({ condition, conditionIndex, removeCondition }) => {
  return (
    <Form.Row key={conditionIndex} className="tf-condition">
      <MedicalConditionTitle conditionIndex={conditionIndex} condition={condition} removeFunc={removeCondition} />
      <MedicalFactorsList condition={condition} conditionIndex={conditionIndex} />
    </Form.Row>
  );
};

const MedicalConditionTitle = ({ condition, removeFunc, conditionIndex }) => (
  <div className="tf-condition__info">
    <div className="tf-condition__info__name">
      <RemoveButton removeFunc={removeFunc} />
      {condition.name}
    </div>
    <Form.Label>
      Top Condition
      <Field
        as="input"
        type="checkbox"
        // Must use medical_info because its the key used to store the medical conditions
        name={`medical_info.conditions[${conditionIndex}].top`}
        checked={condition.top}
        onClick={() => resetCondtion(condition)} // Reset factors when top is toggled
      />
    </Form.Label>
  </div>
);

export const RemoveButton = ({ removeFunc }) => (
  <button type="button" onClick={removeFunc} className="remove-item" title="Remove">
    -
  </button>
);

/* Change the condition to its "initial" state. When the user un-checks the condition
 * as top reset the condition's state
 */
const resetCondtion = condition => {
  condition.factors = [];
  // for Cancer
  condition.type = undefined;
  condition.stage = undefined;
  condition.year_diagnosed = undefined;
};

export default MedicalCondition;
