import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { SEND_ACCOUNT_EMAIL_MODAL } from "components/modals";
import SendAccountEmailForm from "./SendAccountEmailForm";

const SendAccountEmailModal = () => {
  return (
    <FullPageModal modalType={SEND_ACCOUNT_EMAIL_MODAL} title={`Send Account Email Template`}>
      <SendAccountEmailForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(SendAccountEmailModal);
