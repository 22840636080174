import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { MORTALITY_ASSESSMENT_CREATION_MODAL } from "components/modals";
import MortalityAssessmentCreationForm from "./MortalityAssessmentCreationForm";

const MortalityAssessmentCreationModal = ({ ...props }) => {
  return (
    <FullPageModal modalType={MORTALITY_ASSESSMENT_CREATION_MODAL}>
      <MortalityAssessmentCreationForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(MortalityAssessmentCreationModal);
