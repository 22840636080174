import React from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";

import { ButtonCancel, ButtonConfirm } from "components/buttons";
import { hideModal } from "actions";

export default ({
  header = "Choose Follow Up Date",
  onConfirm,
  onCancel,
  children,
  modalType, // Which "modalType" is using this modal, necessary to close it.
}) => {
  const dispatch = useDispatch();

  return (
    <Modal
      show
      centered
      className="modal modal--confirm"
      onHide={() => dispatch(hideModal(modalType))}
    >
      <Modal.Header>
        <h4 className="modal__header">{header}</h4>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer className="modal__footer">
        <ButtonConfirm onClick={onConfirm}>Confirm</ButtonConfirm>
        <ButtonCancel
          onClick={() => {
            if (onCancel !== undefined) {
              onCancel();
            }
            dispatch(hideModal(modalType));
          }}
        >
          Cancel
        </ButtonCancel>
      </Modal.Footer>
    </Modal>
  );
};
