import { FETCH_WINFLEX_CHOICES } from "../../../actions/types";

export const defaultState = {
  choices: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_WINFLEX_CHOICES:
      return { ...state, choices: action.payload };

    default:
      return state;
  }
};

export const winflexChoicesSelector = state => {
  return state.choices;
};
