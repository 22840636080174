import React from "react";

import { Select } from ".";
import SelectInlineLabel from "./selects/SelectInlineLabel";

export default props => {
  if (props.inline) {
    return (
      <SelectInlineLabel
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
        {...props}
      />
    );
  } else {
    return (
      <Select
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
        {...props}
      />
    );
  }
};
