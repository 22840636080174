import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import * as notifications from "../../../notifications";
import { Select, FormCollapsible, SubmitButton } from "components/forms";
import { copyCase, copyInsured, copyToOtherAgency, createEmptyCase } from "actions";
import { activeAgencySelector, agenciesSelector } from "../../../reducers";

const CopyCaseForm = ({ id, ...props }) => {
  console.log("agencies: ", props.agencies);
  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        if (values.action) {
          try {
            if (values.action === "case") {
              await props.copyCase(id);
            } else if (values.action === "insured") {
              await props.copyInsured(id);
            } else if (values.action === "other-agency") {
              if (values.otheragency) {
                await props.copyToOtherAgency(id, values.otheragency);
              }
            }
          } catch (error) {
            setErrors(error.response.data);
            notifications.error("Error editing auction");
          }
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, values }) => {
        const action = values.action === "other-agency";
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Copy Case / Insured">
            <Form.Row>
              <Select
                label="Select mode"
                name="action"
                placeholder="Select Copy Type"
                options={[
                  { value: "case", label: "Case" },
                  { value: "insured", label: "Insured" },
                  { value: "other-agency", label: "Other Agency" },
                ]}
              />
            </Form.Row>
            <Form.Row>
              {action ? (
                <Select
                  label="Select Agency"
                  name="otheragency"
                  placeholder="Select Agency to Copy"
                  options={props.agencies}
                />
              ) : (
                ""
              )}
            </Form.Row>
            <SubmitButton defaultText={"Copy"} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  // get the agencies and remove the active one, then display them as options array
  const activeAgency = activeAgencySelector(state);
  let agencies = agenciesSelector(state).map(agency => ({ value: agency.id, label: agency.name }));
  agencies = agencies.filter(agency => agency.value !== activeAgency.id);
  return {
    agencies,
  };
};

export default connect(mapStateToProps, {
  copyCase,
  copyInsured,
  createEmptyCase,
  copyToOtherAgency,
})(CopyCaseForm);
