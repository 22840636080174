import React from "react";
import { connect } from "react-redux";
import { FaEdit, FaFileInvoiceDollar } from "react-icons/fa";

import { moneyDisplayShort } from "../../utils";
import { showModal } from "actions";
import IconLink from "components/IconLink";
import Table, { currencyCell, dateCell, notesCell } from "components/table";
import { activeFunderCasesSelector, loadingFunderCasesSelector } from "../../reducers";
import { FUNDER_STATUS_MAPPING } from "../../constants";

const FunderCasesTable = ({ activeFunderCases, ...props }) => {
  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const caseId = row.original.id;
        const driveFolderUrl = row.original.drive_pricing_folder_url;
        return (
          <>
            <IconLink
              to={`/cases/${caseId}/trading/`}
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="Manage Life Settlement Case"
              target="_blank"
              rel="noopener noreferrer"
            />
            {driveFolderUrl ? (
              <IconLink
                to={driveFolderUrl}
                Icon={FaFileInvoiceDollar}
                iconConfig={{ className: "table--action-icon" }}
                tooltip="View Pricing Folder"
                target="_blank"
                rel="noopener noreferrer"
              />
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Application ID",
      accessor: "submission_id",
    },
    {
      Header: "Case Status",
      accessor: "status_name",
      className: "wrap-text",
    },
    {
      Header: "Funder Status",
      accessor: "funder_status",
      Cell: ({ row }) => {
        if (row.original.funder_status) {
          return FUNDER_STATUS_MAPPING[row.original.funder_status];
        } else {
          return "";
        }
      },
    },
    {
      Header: "Insured Names",
      accessor: "insured_names",
      className: "wrap-text",
    },
    {
      Header: "Carrier",
      accessor: "carrier_name",
      className: "wrap-text",
    },
    {
      Header: "Face Amount",
      accessor: "face_amount",
      Cell: ({ row }) => {
        if (row.original.face_amount) {
          return moneyDisplayShort(row.original.face_amount);
        } else {
          return "";
        }
      },
    },
    {
      Header: "Product Type",
      accessor: "display_product_type",
      className: "wrap-text",
    },
    {
      Header: "Sales Rep",
      accessor: "sales_representative",
      className: "wrap-text",
    },
    {
      Header: "National Account",
      accessor: "national_account",
      className: "wrap-text",
    },
    {
      Header: "Funder Entity",
      accessor: "funder_entity",
      className: "wrap-text",
    },
    {
      Header: "Submit Date",
      accessor: "funder_submit_date",
      Cell: dateCell,
    },
    {
      Header: "Winning Bid?",
      accessor: "winning_bid",
      Cell: ({ row }) => {
        return <div className="text-wrap">{row.original.winning_bid ? "Yes" : "No"} </div>;
      },
    },
    {
      Header: "Funder Offer",
      accessor: "current_funder_high_bid",
      Cell: currencyCell,
    },
    {
      Header: "Highest Funder Offer",
      accessor: "any_funder_high_bid",
      Cell: currencyCell,
    },
    {
      Header: "Our High Offer",
      accessor: "our_offer",
      Cell: currencyCell,
    },
    {
      Header: "Highest Market Offer",
      accessor: "highest_market_bid",
      Cell: currencyCell,
    },
    {
      Header: "Our Revenue",
      accessor: "our_revenue",
      Cell: currencyCell,
    },
    {
      Header: "Funder Notes",
      accessor: "funder_notes",
      Cell: notesCell,
    },
  ];

  return (
    <Table
      columns={columns}
      data={activeFunderCases}
      emptyMessage={props.funderCasesLoading ? "Loading Funder Cases data..." : "No data"}
      defaultSort={true}
      defaultPagination={true}
      showPaginationResults={true}
      initialPageSize={100}
      tableClass={"funderCasesFixed"}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeFunderCases: activeFunderCasesSelector(state),
    funderCasesLoading: loadingFunderCasesSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
})(FunderCasesTable);
