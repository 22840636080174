import React from "react";

import {
  GrDocument,
  GrDocumentImage,
  GrDocumentPdf,
  GrDocumentSound,
  GrDocumentTxt,
} from "react-icons/gr";

const FileLocal = ({ fileName }) => {
  const splitFileName = fileName.split(".");
  const extension = splitFileName[splitFileName.length - 1].toLowerCase();
  switch (extension) {
    case "pdf":
      return <GrDocumentPdf />;
    case "txt":
      return <GrDocumentTxt />;
    case "jpg":
    case "png":
    case "jpeg":
    case "gif":
    case "tiff":
      return <GrDocumentImage />;
    case "wav":
      return <GrDocumentSound />;
    default:
      return <GrDocument />;
  }
};

export default FileLocal;
