import React from "react";
import { Modal } from "react-bootstrap";

import SpinLoader from "components/SpinLoader";

export default ({ message }) => (
  <Modal show centered style={{ zIndex: 11000 }}>
    <Modal.Body>
      <SpinLoader />
      {message}
    </Modal.Body>
  </Modal>
);
