import { connect } from "react-redux";

import { funderAuctionActivitiesSelector } from "reducers";
import { calcWinningFunderBidValue } from "./utils";

const WinningFunderBidValue = props => calcWinningFunderBidValue(props.auction_activities);

const mapStateToProps = state => {
  const auction_activities = funderAuctionActivitiesSelector(state).filter(
    activity => activity.type === "B" || activity.type === "BC",
  );
  return {
    auction_activities,
  };
};

export default connect(mapStateToProps)(WinningFunderBidValue);
