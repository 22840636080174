import React from "react";
import { connect } from "react-redux";

import { selectAccount, cleanAccountCases, clearSelectedAccount } from "actions";
import AccountTopHeader from "./AccountTopHeader";
import { compose } from "redux";
import withRouter from "../withRouter";
import { Outlet } from "react-router-dom";

/* Data component: fetch relevant data for the current account */
const AccountsSetup = ({ accountId, cleanAccountCases, ...props }) => {
  const fetchData = () => {
    props.selectAccount(accountId);
  };

  React.useEffect(() => {
    props.clearSelectedAccount();
    fetchData();
  }, []);

  React.useEffect(() => {
    cleanAccountCases();
  }, [accountId]);

  return (
    <div>
      <AccountTopHeader />
      <Outlet />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const accountId = ownProps.params.id;

  return {
    accountId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    selectAccount,
    clearSelectedAccount,
    cleanAccountCases,
  }),
)(AccountsSetup);
