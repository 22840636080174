import React from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as Yup from "yup";

import { setPassword, loginSecondStep } from "actions";
import { loadingLoginSelector, loginInfoSelector } from "reducers";
import { TextInput } from "components/forms";
import { URLS } from "../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { LOADING } from "../actions/types";
import { compose } from "redux";
import withRouter from "../pages/withRouter";

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: "equalTo",
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref);
    },
  });
}
Yup.addMethod(Yup.string, "equalTo", equalTo);

const SetPasswordForm = ({ ...props }) => {
  const location = useLocation();
  let isNewPassword = location.pathname.includes(URLS.NEW_PASSWORD);
  const [passwordSet, setPasswordSet] = React.useState(false);
  const [errorFound, setErrorFound] = React.useState(null);
  let validationConfig = {
    new_password1: Yup.string()
      .required("Required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Password must have at least one Uppercase, one Lowercase, one Number and one Special Case character",
      ),
    new_password2: Yup.string().equalTo(Yup.ref("new_password1"), "Passwords must match").required("Required"),
  };

  // Require Auth Code for existing users
  if (!isNewPassword) {
    validationConfig.auth_code = Yup.string().required("Required");
  }

  const validationSchema = Yup.object(validationConfig);
  const dispatch = useDispatch();
  const history = useNavigate();

  return (
    <div className="login-page">
      <h3 className="form-title shadowed">Set Password</h3>
      {passwordSet && props.isSignedIn ? (
        <div>Password changed successfully! You will be logged in automatically...</div>
      ) : (
        <>
          <Formik
            initialValues={{ new_password1: "", new_password2: "", auth_code: "" }}
            validationSchema={validationSchema}
            onSubmit={async (formValues, { setSubmitting, setErrors }) => {
              try {
                dispatch({ type: LOADING, payload: { login: true } });
                const passwordResponse = await props.setPassword(formValues, props.uid, props.reset_password_token);
                setErrorFound(null);
                setPasswordSet(true);

                /* Login and redirect to Home page */
                await props.loginSecondStep({
                  email: passwordResponse.data.email,
                  password: formValues.new_password1,
                  auth_code: formValues.auth_code,
                });
                setTimeout(() => history(URLS.CASES), 2000);
              } catch (error) {
                setErrorFound(error.response.data.non_field_errors);
                setErrors(error.response.data);
              }
              dispatch({ type: LOADING, payload: { login: false } });
              setSubmitting(false);
            }}
          >
            {formik => (
              <Form onSubmit={formik.handleSubmit} className="login-form shadowed">
                <div className="input">
                  <TextInput label="New Password" name="new_password1" type="password" autoComplete="off" />
                </div>
                <div className="input">
                  <TextInput label="Confirm Password" name="new_password2" type="password" autoComplete="off" />
                </div>
                {!isNewPassword ? (
                  <div className="input">
                    <TextInput label="Authenticator Token" name="auth_code" type="text" autoComplete="off" />
                  </div>
                ) : null}
                <button type="submit" disabled={props.loadingLogin} className="btn login-btn">
                  Save
                </button>
              </Form>
            )}
          </Formik>
          {errorFound ? <div className="error">{errorFound}</div> : null}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { uid, reset_password_token } = ownProps.params;

  return {
    loadingLogin: loadingLoginSelector(state),
    uid,
    reset_password_token,
    ...loginInfoSelector(state),
  };
};

export default compose(withRouter, connect(mapStateToProps, { setPassword, loginSecondStep }))(SetPasswordForm);
