import { getViewMode } from "./utils";
import { PERMISSIONS } from "./constants";

export const isAdmin = state => {
  const roles = state.auth.activeAgencyRoles;
  if (!roles) return false;

  return roles.includes(PERMISSIONS.ADMINISTRATOR);
};

export const isSalesRep = state => {
  const roles = state.auth.activeAgencyRoles;
  if (!roles) return false;

  return roles.includes(PERMISSIONS.SALES_REPRESENTATIVE) && !roles.includes(PERMISSIONS.ADMINISTRATOR);
};

export const isReadMode = state => {
  const roles = state.auth.activeAgencyRoles;
  if (!roles) return false;

  const isViewMode = getViewMode();
  return isViewMode || !isAdmin(state);
};
