import {
  selectAccountTask,
  selectCaseRequirementTask,
  selectCaseTask,
  selectCaseTask2,
  selectLeadTask,
} from "../../actions";
import { activeRecordTypeSelector } from "../../reducers";

export function csvToArrayOfObjects(csvString) {
  return csvString.split(",").map(item => {
    const trimmedItem = item.trim();
    return { value: trimmedItem, label: trimmedItem };
  });
}

export const TASK_TYPE_TO_SELECT_TASK_ACTION_MAPPING = {
  1: selectCaseRequirementTask,
  2: selectAccountTask,
  3: selectLeadTask,
  4: selectCaseTask,
};

export const getTaskObjId = record => record?.task_obj?.id || record?.id || undefined;

export const calculateEntries = (record, records) => {
  if (!record || !record.id) {
    return {
      prevEntry: null,
      nextEntry: null,
    };
  }
  const index = records.findIndex(k => k.id === record.id);

  if (index === -1) {
    return {
      prevEntry: null,
      nextEntry: null,
    };
  } else {
    return {
      prevEntry: index > 0 ? records[index - 1] : null,
      nextEntry: index < records.length - 1 ? records[index + 1] : null,
    };
  }
};
