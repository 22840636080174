import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { FormCollapsible, Select, SubmitButton, TextInput } from "components/forms";
import { createCarrierFile, editCarrierFile, uploadCarrierFile } from "actions";
import { Form } from "react-bootstrap";
import { activeCarrierFileSelector, activeCarrierSelector } from "../../reducers";
import { partial } from "../../utils";
import { isAdmin } from "permissions";
import * as notifications from "../../notifications";

const CarrierFileForm = ({ activeCarrierFile, hasAdminPermission, ...props }) => {
  let initialValues;
  console.log("CarrierFileForm rendering activeCarrierFile", activeCarrierFile);
  if (activeCarrierFile) {
    initialValues = activeCarrierFile;

    // Switch label and value for File Type
    if (initialValues.file_type) {
      for (const fileTypeData of props.fileTypes) {
        if (fileTypeData.label === initialValues.file_type) {
          initialValues.file_type = fileTypeData.value;
        }
      }
    }
  } else {
    initialValues = {};
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        let method = props.createCarrierFile;
        if (activeCarrierFile) {
          method = partial(props.editCarrierFile, activeCarrierFile.id);
        }

        try {
          delete values.date_uploaded; // read-only
          // delete values.file; // File is saved through a different endpoint
          await method(values);
          notifications.success("Carrier File saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Carrier File");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        let title = "New Carrier File";
        if (activeCarrierFile) {
          title = "Edit Carrier File";
        }
        return (
          <>
            <FormCollapsible onSubmit={handleSubmit} title={title}>
              <Form.Row>
                <Select
                  label="File Type"
                  name="file_type"
                  placeholder="Select File Type"
                  options={props.fileTypes}
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <Form.Row>
                <TextInput
                  label="Form Description"
                  name="form_description"
                  placeholder="Enter Form Description"
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
            </FormCollapsible>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  let fileTypes = [
    { value: 10, label: "Business Certification" },
    { value: 7, label: "Carrier 3rd Party Authorization" },
    { value: 1, label: "Change of Beneficiary" },
    { value: 0, label: "Change of Ownership" },
    { value: 15, label: "Change of Owner & Beneficiary" },
    { value: 2, label: "Collateral Assignment" },
    { value: 11, label: "Duplicate Policy Request" },
    { value: 13, label: "EFT Form" },
    { value: 12, label: "Fund Allocation" },
    { value: 4, label: "Irrevocable Beneficiary Designation" },
    { value: 14, label: "Power of Attorney" },
    { value: 9, label: "Premium Payment Instructions" },
    { value: 3, label: "Release of Collateral Assignment" },
    { value: 5, label: "Release of Irrevocable Beneficiary" },
    { value: 8, label: "Surrender Request Form" },
    { value: 6, label: "Trust Certification" },
  ];
  return {
    fileTypes,
    carrierInfo: activeCarrierSelector(state),
    activeCarrierFile: activeCarrierFileSelector(state),
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  uploadCarrierFile,
  createCarrierFile,
  editCarrierFile,
})(CarrierFileForm);
