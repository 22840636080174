import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { calculateBMI } from "../../../../utils";
import { editLifeExpectancy, updateLifeExpectancy } from "actions";
import {
  BooleanSelect,
  EditorField,
  DateInput,
  FormCollapsible,
  GenderSelect,
  SSNLast4DigitsInput,
  Select,
  SubmitButton,
  TextInput,
} from "components/forms";
import { lifeExpectancyChoicesSelector, extractedLifeExpectancyPDFdataSelector } from "reducers";
import { underwritingTypeChoices, VENDORS } from "../../../../constants";
import * as notifications from "../../../../notifications";
import AgeAssessedField from "../AgeAssessedField";

const FasanoForm = ({ lifeExpectancyData, ...props }) => {
  let {
    mean,
    median,
    mortality_rating,
    adjusted_mortality_rating,
    tobacco_use,
    first_name,
    middle_initial,
    last_name,
    gender,
    date_of_birth,
    social_security_last_4_digits,
    certificate_date,
    certificate_id,
    client,
    mortality_table_name,
    age,
    underwriter,
    records_date_from,
    records_date_to,
    medical_info,
    underwriting_type,
    warnings,
  } = lifeExpectancyData;

  if (mortality_rating) {
    mortality_rating = Number(mortality_rating).toFixed(3);
  }
  if (adjusted_mortality_rating) {
    adjusted_mortality_rating = Number(adjusted_mortality_rating).toFixed(3);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        mean,
        median,
        mortality_rating,
        adjusted_mortality_rating,
        tobacco_use,
        first_name,
        middle_initial,
        last_name,
        gender,
        date_of_birth,
        social_security_last_4_digits,
        certificate_date,
        certificate_id,
        client,
        mortality_table_name,
        age,
        underwriter,
        records_date_from,
        records_date_to,
        medical_info,
        underwriting_type,
        warnings,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          await props.updateLifeExpectancy(lifeExpectancyData.id, values);
          await props.editLifeExpectancy(lifeExpectancyData.id);
          notifications.success("Fasano form saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error saving fasano form, please check form errors");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Fasano Life Expectancy">
            <Form.Row>
              <Select
                label="LE UW Type"
                name="underwriting_type"
                placeholder="Select Underwriting Type"
                options={underwritingTypeChoices}
                isRequired
              />
            </Form.Row>
            <Form.Row>
              <TextInput label="Mean LE" name="mean" placeholder="Enter Mean LE" prepend="Months" />
              <TextInput label="Median LE" name="median" placeholder="Enter Median LE" prepend="Months" />
              <TextInput label="Pre Adjusted MR" name="adjusted_mortality_rating" placeholder="Enter %" append="%" />
            </Form.Row>
            <Form.Row>
              <TextInput label="Mortality Rating" name="mortality_rating" placeholder="Enter %" append="%" />
              <BooleanSelect label="Tobacco Use" name="tobacco_use" />
            </Form.Row>
            <Form.Row>
              <TextInput label="First Name" name="first_name" isRequired />
              <TextInput label="Middle Initial" name="middle_initial" md={2} />
              <TextInput label="Last Name" name="last_name" isRequired />
            </Form.Row>
            <Form.Row>
              <GenderSelect isRequired />
              <DateInput label="Date of Birth" name="date_of_birth" isRequired />
              <SSNLast4DigitsInput isRequired name="social_security_last_4_digits" label="SSN Last 4 Digits" />
            </Form.Row>
            <Form.Row>
              <DateInput label="Certificate Date" name="certificate_date" isRequired />
              <TextInput label="Certificate ID" name="certificate_id" />
              <TextInput label="LE Client" name="client" />
            </Form.Row>
            <Form.Row>
              <Select
                label="Mortality Table"
                name="mortality_table_name"
                options={props.mortalityTableChoices.getChoices({
                  group: VENDORS.FASANO,
                })}
              />
            </Form.Row>
            <Form.Row>
              <AgeAssessedField {...values} />
              <TextInput label="Underwriter" name="underwriter" placeholder="Enter LE UW Name" />
            </Form.Row>
            <Form.Row>
              <DateInput label="Records date from" name="records_date_from" md={4} />
              <DateInput label="Records date to" name="records_date_to" md={4} />
            </Form.Row>

            <Form.SectionTitle>Medical History</Form.SectionTitle>
            <Form.Row>
              <EditorField name="medical_info.history" />
            </Form.Row>

            <Form.SectionTitle>Discussion of Significant Medical Conditions Affecting Mortality*</Form.SectionTitle>
            <Form.Row>
              <EditorField label="" name="medical_info.significant_conditions_affecting_mortality" />
            </Form.Row>

            <Form.SectionTitle>Other Detail</Form.SectionTitle>
            <Form.Row>
              <TextInput label="Height" name="medical_info.height" prepend="in" />
              <DateInput label="Height Date" name="medical_info.height_date" />
            </Form.Row>
            <Form.Row>
              <TextInput label="Weight" name="medical_info.weight" prepend="lbs" />
              <DateInput label="Weight Date" name="medical_info.weight_date" />
              <TextInput
                label="BMI"
                name="bmi"
                disabled
                value={(() => {
                  if (values.medical_info) {
                    const { height, weight } = values.medical_info;
                    if (height && weight) {
                      return calculateBMI(height, weight);
                    }
                  }
                  return "";
                })()}
              />
            </Form.Row>
            <Form.Row>
              <Select
                label="Primary Impairment Category"
                name="medical_info.primary_impairment_category"
                options={props.primaryImpairmentChoices.getChoices({
                  group: VENDORS.FASANO,
                })}
              />
              <div className="invalid-feedback" style={{ display: "block", marginLeft: "10px", marginTop: "10px" }}>
                <p>{values.warnings && values.warnings.primary_impairment_category}</p>
              </div>
            </Form.Row>
            <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state, ownProps) => {
  const extractedPDFdata = extractedLifeExpectancyPDFdataSelector(state);

  // Life expectancy data will be either the extracted data from the uploaded PDF
  // or the saved LifeExpectancy instance.
  const lifeExpectancyData = extractedPDFdata || ownProps.lifeExpectancy;

  // Make sure lifeExpectancyData has an id prop, because the extractedPDFdata won't
  // have one and we need it in the form.
  const { id } = ownProps.lifeExpectancy;
  lifeExpectancyData.id = id;

  const lifeExpectancyChoices = lifeExpectancyChoicesSelector(state);

  return {
    lifeExpectancyData,
    mortalityTableChoices: lifeExpectancyChoices.mortality_table_name,
    primaryImpairmentChoices: lifeExpectancyChoices.primary_impairment_category,
  };
};

export default connect(mapStateToProps, { updateLifeExpectancy, editLifeExpectancy })(FasanoForm);
