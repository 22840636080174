/* Render a single medical condition */

import React from "react";
import { Col, Form } from "react-bootstrap";
import ReactSelect from "react-select";

import { RemoveButton } from "./MedicalCategory.js";
import { symbolChoices } from "../../../../options";
import { Select, TextInput } from "components/forms";

const MedicalCondition = ({ categoryIndex, condition, conditionIndex, removeFunc }) => {
  return (
    <Form.Row className="factor">
      <Col md={6}>
        <RemoveButton removeFunc={removeFunc} />
        <span className="factor__name">{condition.name}</span>
      </Col>
      <Col>
        <TextInput name={`medical_info.conditions[${categoryIndex}].conditions[${conditionIndex}].notes`} />
      </Col>
      <Col md={2} style={{ marginRight: -50 }}>
        <Select
          placeholder="Symbol"
          // Must use medical_info because its the key used to store the medical conditions
          name={`medical_info.conditions[${categoryIndex}].conditions[${conditionIndex}].symbol`}
          options={symbolChoices.getChoices()}
        />
      </Col>
    </Form.Row>
  );
};

export const AddConditionSelect = ({ conditions, onChange }) => {
  // Don't show the select when there aren't conditions available
  if (conditions.length === 0) {
    return null;
  }

  return (
    <ReactSelect
      className="new-factor"
      placeholder="Add Medical Condition"
      value={null} // reset value on select
      menuPlacement="auto"
      options={conditions.map(condition => {
        let label = condition.name;
        if (condition.symbol) {
          label += ` (${condition.symbol})`;
        }
        return {
          label,
          value: condition.name,
          symbol: condition.symbol,
        };
      })}
      onChange={onChange}
    />
  );
};
export default MedicalCondition;
