import React from "react";

import SortCaret from "./SortCaret";

export default ({ headerGroups, ...props }) => {
  return (
    <thead>
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
              {column.render("Header")}
              <SortCaret column={column} />
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};
