import React, { useRef } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import {
  Select,
  FormFilter,
  SubmitSearchButton,
  ClearFiltersButton,
  DateInput,
  TextInput,
  RowSeparator,
} from "components/forms";
import { fetchRecords } from "actions/life_settlement/records";
import {
  managementChoicesSelector,
  storedAccountRecordsFiltersSelector,
  storedCaseRecordsFiltersSelector,
  storedCaseRequirementRecordsFiltersSelector,
  storedLeadRecordsFiltersSelector,
  storedRecordsFiltersSelector,
  userChoicesSelector,
} from "reducers";
import { fetchLifeSettlementChoices, fetchManagerAgents, setActiveTab, storeRecordsFiltersData } from "actions";
import { TASK_TYPES } from "../accounts/constants";
import {
  caseDocumentStatusChoices,
  PERMISSIONS,
  requiredOfChoices,
  tasksStageChoices,
  tasksStatusChoices,
} from "../../constants";
import { FETCH_RECORDS_BY_TYPE_MAP, STORE_RECORDS_FILTER_BY_TYPE_MAP, TaskTab } from "./constants";
import { isAdmin } from "permissions";
import { csvToArrayOfObjects } from "./utils";
import { FRID_CHOICES } from "../cases/caseFiles/constants";
import { ENDPOINTS_TO_ACTIVE_SECTIONS_MAPPING, getActiveSectionByTaskType } from "../../actions/pagination/constants";

const RecordsFilterForm = ({ type = TaskTab.ALL, record_owner, ...props }) => {
  React.useEffect(() => {
    props.setActiveSection(ENDPOINTS_TO_ACTIVE_SECTIONS_MAPPING[getActiveSectionByTaskType(type)]);
    props.setActiveTab(ENDPOINTS_TO_ACTIVE_SECTIONS_MAPPING[getActiveSectionByTaskType(type)]);
  }, []);
  if (!record_owner) {
    return null;
  }

  const handleStageChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedValues = selectedOptions.map(o => o.value).join(",");
      setStage(commaSeparatedValues);
    } else {
      setStage(undefined);
    }
  };

  let [stage, setStage] = React.useState("Opportunity,Price Discovery,In Closing,Asset");

  const MAP = {
    [TaskTab.ALL]: props.recordsFilters,
    [TaskTab.CASE]: props.caseRecordsFilters,
    [TaskTab.CASE_REQUIREMENT]: props.caseRequirementRecordsFilters,
    [TaskTab.ACCOUNT]: props.accountRecordsFilters,
    [TaskTab.LEAD]: props.leadRecordsFilters,
  };
  let storedRecordsFilters = MAP[type];

  const storedRecordsFiltersStage = typeof storedRecordsFilters == "object" && storedRecordsFilters.stage;
  const storedRecordsFiltersOwner = typeof storedRecordsFilters == "object" && storedRecordsFilters.record_owner;
  const storedRecordsFiltersStatus = typeof storedRecordsFilters == "object" && storedRecordsFilters.status;

  const stageInitial = storedRecordsFiltersStage ?? stage;
  const stageInitialValue = csvToArrayOfObjects(stageInitial);

  React.useEffect(() => {
    setStage(storedRecordsFiltersStage ?? stage);
  }, [storedRecordsFiltersStage]);
  const stageRef = useRef();

  const renderExtraFilters = () => {
    if (type !== TaskTab.ALL) {
      return (
        <Form.Row>
          {(type === TaskTab.CASE_REQUIREMENT || type === TaskTab.CASE) && (
            <Select
              label="Stage"
              name="stage"
              placeholder="Select Stage"
              isMulti={true}
              onChange={handleStageChange}
              options={tasksStageChoices}
              defaultValue={stageInitialValue}
              submitRef={stageRef}
              md={3}
            />
          )}
          {type === TaskTab.ACCOUNT && (
            <Select
              label="National Account"
              name="national_account"
              placeholder="Select National Account"
              options={props.nationalAccountChoices.getChoices({ sortByLabel: true })}
              md={3}
            />
          )}
          {type === TaskTab.CASE_REQUIREMENT && (
            <>
              <Select label="FRID" name="frid" placeholder="Select FRID" options={FRID_CHOICES} md={3} />
              <Select
                label="Responsible Party"
                name="responsible_party"
                placeholder="Select Responsible Party"
                options={requiredOfChoices}
                md={3}
              />
            </>
          )}
        </Form.Row>
      );
    }
  };

  const renderTaskStatusFilter = () => {
    if (type === TaskTab.CASE_REQUIREMENT) {
      return <Select label="FRID Status" name="status" options={caseDocumentStatusChoices} md={3} />;
    } else {
      return <Select label="Task Status" name="status" options={tasksStatusChoices} md={3} />;
    }
  };

  return (
    <Formik
      initialValues={{
        ...storedRecordsFilters,
        stage: storedRecordsFiltersStage ?? stage,
        record_owner: storedRecordsFiltersOwner ?? record_owner,
        status: storedRecordsFiltersStatus ?? 1,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        if (type !== TaskTab.ALL) {
          values.record_type = type;
        } else {
          values.paginate = true;
        }
        const store_filters_action = STORE_RECORDS_FILTER_BY_TYPE_MAP[type];

        props.storeRecordsFiltersData({ ...values, stage }, store_filters_action);
        const fetch_action = FETCH_RECORDS_BY_TYPE_MAP[type];
        await props.fetchTasks({ ...values, stage }, fetch_action);
        setSubmitting(false);
      }}
      onReset={async (values, { setSubmitting }) => {
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, resetForm, setFieldValue }) => {
        return (
          <FormFilter onSubmit={handleSubmit} title={"Task Search"}>
            <Form.Row>
              <TextInput label="Record ID" name="record_id" md={3} />
              <TextInput label="Record Name" name="record_name" md={3} />
              {type !== TaskTab.CASE_REQUIREMENT && type !== TaskTab.CASE && (
                <Select label="Task Type" name="task_type" options={TASK_TYPES} md={3} />
              )}
            </Form.Row>
            <Form.Row>
              <DateInput label="Due Date Start Range" name="due_date_start" />
              <DateInput label="Due Date End Range" name="due_date_end" />
              <DateInput label="Create Date Start Range" name="create_date_start" />
              <DateInput label="Create Date End Range" name="create_date_end" />
            </Form.Row>
            <Form.Row>
              <Select
                label="Task Owner"
                name="record_owner"
                options={props.userChoices}
                disabled={!props.hasAdminPermission}
                md={3}
              />
              {renderTaskStatusFilter()}
              <Select label="Task Creator" name="record_creator" options={props.userChoices} md={3} />
              <Select label="Last Editor" name="last_editor" options={props.userChoices} md={3} />
            </Form.Row>
            <RowSeparator />
            {renderExtraFilters()}
            <SubmitSearchButton />
            <ClearFiltersButton onClick={resetForm} />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const userChoices = userChoicesSelector(state).getChoices({ sortByLabel: true });

  return {
    record_owner: state.auth.user_id,
    userChoices,
    userId: state.auth.user_id,
    nationalAccountChoices: choices.national_accounts,
    hasAdminPermission: isAdmin(state),
    recordsFilters: storedRecordsFiltersSelector(state),
    caseRecordsFilters: storedCaseRecordsFiltersSelector(state),
    caseRequirementRecordsFilters: storedCaseRequirementRecordsFiltersSelector(state),
    accountRecordsFilters: storedAccountRecordsFiltersSelector(state),
    leadRecordsFilters: storedLeadRecordsFiltersSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchTasks: fetchRecords,
  fetchManagerAgents,
  fetchLifeSettlementChoices,
  storeRecordsFiltersData,
  setActiveTab,
})(RecordsFilterForm);
