/* Main component to render medical condition's factors.
 *
 * When a medical condition is "top", it can have multiple medical factors,
 * otherwise it can't have any.
 *
 * Each medical condition has it's own "schema": a set of possible medical factors.
 * The already added factors will be shown while the other factors will be available
 * in an html select (the schema is retrieved from the server).
 */

import React from "react";
import { useSelector } from "react-redux";
import { Col, Form } from "react-bootstrap";
import { FieldArray } from "formik";

import { Select } from "components/forms";
import { twentyFirstMedicalConditionsSchemaSelector } from "reducers";
import { partial, removeIdx } from "../../../../utils";
import MedicalFactor, { AddFactorSelect, AddFamilyMedicalHistoryFactorSelect } from "./MedicalFactor";

const FAMILY_MEDICAL_HISTORY_CONDITION = "Family Medical History";
const CANCER_CONDITION = "Cancer";

const MedicalFactorsList = ({ condition, conditionIndex }) => {
  // Only show factors for top conditions
  if (!condition.top) {
    return null;
  }
  switch (condition.name) {
    case FAMILY_MEDICAL_HISTORY_CONDITION:
      return <FamilyMedicalHistoryFactors factors={condition.factors} conditionIndex={conditionIndex} />;
    case CANCER_CONDITION:
      return <CancerInfo condition={condition} conditionIndex={conditionIndex} />;
    default:
      return (
        <DefaultFactorsList
          factors={condition.factors}
          conditionIndex={conditionIndex}
          conditionName={condition.name}
        />
      );
  }
};

const DefaultFactorsList = ({ factors, conditionName, conditionIndex }) => {
  // Get the "remaining factors" (possible_factors - condition.factors) to show
  // in the select
  const schema = useSelector(twentyFirstMedicalConditionsSchemaSelector).find(c => c.name === conditionName);
  let remainingFactors = [];
  if (schema) {
    remainingFactors = schema.possible_factors.filter(pf => {
      return factors.find(f => f.name === pf.name) === undefined;
    });
  }

  return (
    <FieldArray
      name={`medical_info.conditions[${conditionIndex}].factors`}
      validateOnChange={false}
      render={arrayHelpers => (
        <>
          {factors.map((factor, factorIndex) => (
            <MedicalFactor
              factor={factor}
              removeFunc={partial(arrayHelpers.remove, factorIndex)}
              conditionIndex={conditionIndex}
              factorIndex={factorIndex}
              key={factor.name}
            />
          ))}
          <AddFactorSelect
            factors={remainingFactors}
            onChange={option =>
              arrayHelpers.push({
                name: option.value,
                code: option.code,
                notes: "",
              })
            }
          />
        </>
      )}
    />
  );
};

/* Factors for Family Medical History are grouped (Father, Mother, Sibling(s))
 * so formik's array update and render is more complex
 */
const FamilyMedicalHistoryFactors = ({ factors, conditionIndex }) => {
  // Get the "remaining factors" (possible_factors - condition.factors) to show
  // in the select
  const schema = useSelector(twentyFirstMedicalConditionsSchemaSelector).find(
    c => c.name === FAMILY_MEDICAL_HISTORY_CONDITION,
  );
  let remainingGroups = [];
  if (schema) {
    for (const schemaGroup of schema.possible_factors) {
      const [groupName] = Object.keys(schemaGroup);
      const [schemaFactors] = Object.values(schemaGroup);

      let group = factors.find(g => Object.prototype.hasOwnProperty.call(g, groupName));
      if (!group) {
        group = { [groupName]: [] };
      }

      const [groupFactors] = Object.values(group);
      const toAddOptions = schemaFactors.filter(schemaFactor => !groupFactors.find(o => o.name === schemaFactor.name));
      if (toAddOptions.length > 0) {
        remainingGroups.push({ name: groupName, options: toAddOptions });
      }
    }
  }

  const renderGroup = (group, groupIndex, arrayHelpers) => {
    const [groupName] = Object.keys(group);
    const [factors] = Object.values(group);

    if (factors.length === 0) {
      return null;
    }

    const removeFactor = factorIndex => {
      const updatedGroup = { [groupName]: removeIdx(factors, factorIndex) };
      return partial(arrayHelpers.replace, groupIndex, updatedGroup);
    };

    return (
      <Form.Row>
        <div style={{ width: "100%" }}>{groupName}</div>
        {factors.map((factor, factorIndex) => (
          <MedicalFactor
            factor={factor}
            removeFunc={removeFactor(factorIndex)}
            conditionIndex={conditionIndex}
            factorIndex={factorIndex}
            key={factor.name}
            notesPath={`medical_info.conditions[${conditionIndex}].factors[${groupIndex}][${groupName}][${factorIndex}].notes`}
          />
        ))}
      </Form.Row>
    );
  };

  const addFactor = (option, arrayHelpers) => {
    const factor = { name: option.value, code: option.code, notes: "" };
    const groupName = option.group;

    const addedGroups = arrayHelpers.form.values.medical_info.conditions[conditionIndex].factors;
    let group;
    let groupIndex = 0;
    while (groupIndex < addedGroups.length) {
      const g = addedGroups[groupIndex];
      const [name] = Object.keys(g);
      if (name === groupName) {
        group = g;
        break;
      }
      groupIndex++;
    }

    if (group) {
      group[groupName].push(factor);
      arrayHelpers.replace(groupIndex, group);
    } else {
      arrayHelpers.push({ [groupName]: [factor] });
    }
  };

  return (
    <FieldArray
      name={`medical_info.conditions[${conditionIndex}].factors`}
      validateOnChange={false}
      render={arrayHelpers => (
        <>
          {factors.map((group, groupIndex) => renderGroup(group, groupIndex, arrayHelpers))}
          <AddFamilyMedicalHistoryFactorSelect
            groups={remainingGroups}
            onChange={option => addFactor(option, arrayHelpers)}
          />
        </>
      )}
    />
  );
};

const CancerInfo = ({ condition, conditionIndex }) => {
  const schema = useSelector(twentyFirstMedicalConditionsSchemaSelector).find(c => c.name === CANCER_CONDITION);
  let types = [];
  let stages = [];
  let years = [];
  if (schema) {
    types = schema.types.map(type => ({ label: type, value: type }));
    stages = schema.stages.map(stage => ({ label: stage, value: stage }));
    years = schema.years.map(year => ({ label: year, value: year }));
  }
  const rowStyle = { marginBottom: "-10px" };
  return (
    <>
      <Form.Row className="factor" style={rowStyle}>
        <Col md={4}>Type</Col>
        <Select options={types} name={`medical_info.conditions[${conditionIndex}].type`} />
      </Form.Row>
      <Form.Row className="factor" style={rowStyle}>
        <Col md={4}>Stage</Col>
        <Select options={stages} name={`medical_info.conditions[${conditionIndex}].stage`} />
      </Form.Row>
      <Form.Row className="factor" style={rowStyle}>
        <Col md={4}>Year diagnosed/treated/re-treated</Col>
        <Select options={years} name={`medical_info.conditions[${conditionIndex}].year_diagnosed`} />
      </Form.Row>
    </>
  );
};

export default MedicalFactorsList;
