import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import * as notifications from "../../../notifications";
import {
  BooleanSelect,
  Select,
  TextInput,
  DateInput,
  PhoneInput,
  SSNInput,
  StateSelect,
  FormCollapsible,
  SubmitButton,
} from "components/forms";
import { editOwnerPersonInfo } from "actions";
import { ownerPersonSelector } from "reducers";
import { isAdmin } from "permissions";
import {
  maritalStatusChoices,
  MARITAL_STATUS_MARRIED as MARRIED,
  PERMISSIONS,
  MARITAL_STATUS_SEPARATED as SEPARATED,
} from "../../../constants";

const OwnerPersonForm = ({ ownerInfo, editOwnerPersonInfo, hasAdminPermission, ...props }) => {
  const {
    first_name,
    middle_initial,
    last_name,
    suffix,
    date_of_birth,
    social_security,
    fled_bk,
    mobile_phone,
    home_phone,
    office_phone,
    street_address,
    city,
    state,
    zipcode,
    email,
    marital_status,
    spouse_name,
    active_POA,
    POA_name,
  } = ownerInfo;
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          first_name,
          middle_initial,
          last_name,
          suffix,
          date_of_birth,
          social_security,
          fled_bk,
          mobile_phone,
          home_phone,
          office_phone,
          street_address,
          city,
          state,
          zipcode,
          email,
          marital_status,
          spouse_name,
          active_POA,
          POA_name,
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await editOwnerPersonInfo(ownerInfo.id, values);
          } catch (error) {
            setErrors(error.response.data);
            notifications.error("Error editing owner information");
          }
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, values }) => {
          return (
            <FormCollapsible onSubmit={handleSubmit} title="Owner Person">
              <Form.Row>
                <TextInput label="First Name" name="first_name" isRequired disabled={!hasAdminPermission} />
                <TextInput label="Middle Initial" name="middle_initial" md={2} disabled={!hasAdminPermission} />
                <TextInput label="Last Name" name="last_name" isRequired disabled={!hasAdminPermission} />
                <TextInput label="Suffix" name="suffix" md={2} disabled={!hasAdminPermission} />
              </Form.Row>
              <Form.Row>
                <DateInput label="Date of Birth" name="date_of_birth" isRequired disabled={!hasAdminPermission} />
                <SSNInput isRequired disabled={!hasAdminPermission} />
                <BooleanSelect
                  label="Ever Fled BK?"
                  placeholder="Select choice"
                  name="fled_bk"
                  isRequired
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <Form.Row>
                <PhoneInput label="Mobile Phone" name="mobile_phone" isRequired disabled={!hasAdminPermission} />
                <PhoneInput label="Home Phone" name="home_phone" isRequired disabled={!hasAdminPermission} />
                <PhoneInput label="Office Phone" name="office_phone" isRequired disabled={!hasAdminPermission} />
              </Form.Row>
              <Form.Row>
                <TextInput label="Street Address" name="street_address" isRequired disabled={!hasAdminPermission} />
              </Form.Row>
              <Form.Row>
                <TextInput label="City" name="city" isRequired disabled={!hasAdminPermission} />
                <StateSelect isRequired />
                <TextInput label="Zip Code" name="zipcode" isRequired disabled={!hasAdminPermission} />
              </Form.Row>
              <Form.Row>
                <TextInput label="Email Address" name="email" isRequired disabled={!hasAdminPermission} />
              </Form.Row>
              <Form.Row>
                <Select
                  label="Marital Status"
                  name="marital_status"
                  placeholder="Select Marital Status"
                  options={maritalStatusChoices}
                  md="4"
                  isRequired
                  disabled={!hasAdminPermission}
                />
                <TextInput
                  label="Spouse Name"
                  name="spouse_name"
                  isRequired={values.marital_status === MARRIED || values.marital_status === SEPARATED}
                  disabled={
                    (values.marital_status !== MARRIED && values.marital_status !== SEPARATED) || !hasAdminPermission
                  }
                />
              </Form.Row>
              <Form.Row>
                <BooleanSelect
                  label="POA In Effect?"
                  name="active_POA"
                  placeholder="Select POA in effect"
                  md={4}
                  isRequired
                  disabled={!hasAdminPermission}
                />
                <TextInput
                  label="POA name"
                  name="POA_name"
                  isRequired={values.active_POA === true}
                  disabled={values.active_POA !== true || !hasAdminPermission}
                />
              </Form.Row>
              <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
            </FormCollapsible>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state, { ownerId }) => {
  return {
    ownerInfo: ownerPersonSelector(state, ownerId),
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, { editOwnerPersonInfo })(OwnerPersonForm);
