import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { ACCOUNT_CARRIER_APPOINTMENT_MODAL } from "components/modals";
import CarrierAppointmentForm from "./CarrierAppointmentForm";
import { activeCarrierAppointmentSelector } from "reducers";

const CarrierAppointmentModal = ({ activeCarrierAppointment, ...props }) => {
  let isSaved = activeCarrierAppointment && activeCarrierAppointment.id;
  const submitRef = React.useRef();
  let title = isSaved ? "Edit Carrier Appointment" : "New Carrier Appointment";

  return (
    <FullPageModal
      modalType={ACCOUNT_CARRIER_APPOINTMENT_MODAL}
      title={title}
      style={{ content: { overflow: "visible" } }}
    >
      <CarrierAppointmentForm submitRef={submitRef} />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    activeCarrierAppointment: activeCarrierAppointmentSelector(state) || {},
  };
};

export default connect(mapStateToProps, {})(CarrierAppointmentModal);
