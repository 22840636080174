import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { SEND_FUNDER_EMAIL_MODAL } from "components/modals";
import SendFunderEmailForm from "./SendFunderEmailForm";
import { deleteAuctionActivityFile } from "actions";

const SendFunderEmailModal = () => {
  return (
    <FullPageModal modalType={SEND_FUNDER_EMAIL_MODAL} title={`Send Funder Email Template`}>
      <SendFunderEmailForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  deleteAuctionActivityFile,
})(SendFunderEmailModal);
