/* Custom Error.
 *
 * If param "field" is provided we emulate an API response error
 * by attaching extra info.
 *
 * Example:
 *    try {
 *      throw new ValidationError("Error message", "some_field")
 *      someAPICall(data)
 *    } catch (error) {
 *      setErrors(error.response.data);
 *    }
 *
 *    In this way, we don't care if the error came from someAPICall or
 *    our ValidationError, because both return the same error structure,
 *    we just attach the errors to the form.
 */
export class ValidationError extends Error {
  constructor(message, field) {
    super(message);
    this.name = "ValidationError";
    if (field) {
      this.response = { data: { [field]: message } };
    }
  }
}
