import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { STATE_BROKER_FILE_MODAL } from "components/modals";
import StateBrokerFileForm from "./StateBrokerFileForm";
import SingleFileDropZone from "../../components/SingleFileDropZone";
import { fetchStates, uploadStateFile } from "../../actions";
import { activeStateBrokerFileSelector } from "../../reducers";
import { isAdmin } from "permissions";

const StateBrokerFileModal = ({ activeStateBrokerFile, hasAdminPermission, ...props }) => {
  let stateFileIsSaved = activeStateBrokerFile && activeStateBrokerFile.id;
  const submitRef = React.useRef();

  const WideSaveButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          className="btn btn--secondary"
          onClick={() => {
            submitRef.current.click();
          }}
          style={{
            width: "99%",
            marginBottom: "1rem",
          }}
        >
          Save Changes
        </button>
      </div>
    );
  };

  return (
    <FullPageModal modalType={STATE_BROKER_FILE_MODAL}>
      <StateBrokerFileForm submitRef={submitRef} />
      {hasAdminPermission && <WideSaveButton />}
      {stateFileIsSaved && hasAdminPermission ? (
        <div>
          <SingleFileDropZone
            currentFile={activeStateBrokerFile.name ? activeStateBrokerFile : null}
            uploadFile={file => {
              props.uploadStateFile(file, "BROKER");
            }}
            fileDownloadUrl={`/api/state/`}
          />
        </div>
      ) : null}
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  console.log(window.location.pathname);
  return {
    activeStateBrokerFile: activeStateBrokerFileSelector(state),
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  uploadStateFile,
  fetchStates,
})(StateBrokerFileModal);
