import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";

import { showModal, fetchEmailTemplates, setActiveEmailTemplate, setActiveEmailTemplateType } from "actions";
import AdminPageHeader from "./AdminPageHeader";
import { RecordsTable } from "../../components/table";
import EmailTemplatesTable from "./templates/EmailTemplatesTable";
import { EMAIL_TEMPLATE_MODAL } from "../../components/modals";
import { emailTemplateSelector } from "reducers";
import { EMAIL_TEMPLATE_TYPES } from "./constants";

const AdminEmailsPage = props => {
  React.useEffect(() => {
    props.fetchEmailTemplates();
    document.title = "LifeRoc | Admin Emails";
  }, []);

  const AccountEmailTemplatesTable = () => <EmailTemplatesTable templateType="Account" data={props.accountTemplates} />;
  const CaseEmailTemplatesTable = () => <EmailTemplatesTable templateType="Case" data={props.caseTemplates} />;
  const LeadEmailTemplatesTable = () => <EmailTemplatesTable templateType="Lead" data={props.leadTemplates} />;
  const FunderEmailTemplatesTable = () => <EmailTemplatesTable templateType="Funder" data={props.funderTemplates} />;

  return (
    <>
      <AdminPageHeader />
      <div className={`section-card shadowed case-section`} style={{ marginTop: "1.5rem" }}>
        <Card className={"case-info"}>
          <Card.Body>
            <RecordsTable
              title="Account Email Templates"
              createButtonLabel="Add Template"
              onCreateClick={() => {
                props.setActiveEmailTemplate(null);
                props.setActiveEmailTemplateType(EMAIL_TEMPLATE_TYPES.ACCOUNT);
                props.showModal(EMAIL_TEMPLATE_MODAL);
              }}
              Table={AccountEmailTemplatesTable}
            />
            <br />
            <RecordsTable
              title="Case Email Templates"
              createButtonLabel="Add Template"
              onCreateClick={() => {
                props.setActiveEmailTemplate(null);
                props.setActiveEmailTemplateType(EMAIL_TEMPLATE_TYPES.CASE);
                props.showModal(EMAIL_TEMPLATE_MODAL);
              }}
              Table={CaseEmailTemplatesTable}
            />
            <br />
            <RecordsTable
              title="Funder Case Email Templates"
              createButtonLabel="Add Template"
              onCreateClick={() => {
                props.setActiveEmailTemplate(null);
                props.setActiveEmailTemplateType(EMAIL_TEMPLATE_TYPES.FUNDER_CASE);
                props.showModal(EMAIL_TEMPLATE_MODAL);
              }}
              Table={FunderEmailTemplatesTable}
            />
            <RecordsTable
              title="Lead Email Templates"
              createButtonLabel="Add Template"
              onCreateClick={() => {
                props.setActiveEmailTemplate(null);
                props.setActiveEmailTemplateType(EMAIL_TEMPLATE_TYPES.LEAD);
                props.showModal(EMAIL_TEMPLATE_MODAL);
              }}
              Table={LeadEmailTemplatesTable}
            />
            <br />
            <br />
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const emailTemplates = emailTemplateSelector(state);
  return {
    accountTemplates: emailTemplates.filter(t => t.type === EMAIL_TEMPLATE_TYPES.ACCOUNT),
    caseTemplates: emailTemplates.filter(t => t.type === EMAIL_TEMPLATE_TYPES.CASE),
    funderTemplates: emailTemplates.filter(t => t.type === EMAIL_TEMPLATE_TYPES.FUNDER_CASE),
    leadTemplates: emailTemplates.filter(t => t.type === EMAIL_TEMPLATE_TYPES.LEAD),
  };
};

export default connect(mapStateToProps, {
  showModal,
  fetchEmailTemplates,
  setActiveEmailTemplate,
  setActiveEmailTemplateType,
})(AdminEmailsPage);
