import React from "react";
import { connect } from "react-redux";

import { clearSelectedParameter, setActiveTab, initPagination, showModal } from "actions";
import AdminPageHeader from "./AdminPageHeader";

const AdminEmailsPage = props => {
  const [activeSection, setActiveSection] = React.useState("admin-templates");
  React.useEffect(() => {
    props.setActiveTab(activeSection);
    props.initPagination();
    setActiveSection(activeSection);
    document.title = "LifeRoc | Admin Templates";
  }, []);

  return (
    <>
      <AdminPageHeader />
      <div className={`section-card shadowed case-section`} style={{ marginTop: "1.5rem" }}>
        TBD - Admin Templates
      </div>
    </>
  );
};

export default connect(null, {
  clearSelectedParameter,
  setActiveTab,
  initPagination,
  showModal,
})(AdminEmailsPage);
