import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";
import * as moment from "moment-timezone";

import * as notifications from "../../../../notifications";
import {
  Select,
  DateTimeInput,
  DateInput,
  EditorField,
  BooleanSelect,
  FormCollapsible,
  SubmitButton,
  TextInput,
  CurrencyInput,
} from "components/forms";
import { createMarketAuctionActivity, editMarketAuctionActivity, fetchAuctionActivities, showModal } from "actions";
import {
  auctionRoundsSelector,
  auctionChoicesSelector,
  managementChoicesSelector,
  activeCaseSelector,
  activeMarketAuctionActivitySelector,
} from "reducers";
import { partial, getValue } from "../../../../utils";
import { AUCTION_ACTIVITY_CHANGE_WINNING_BID_MODAL } from "components/modals";
import DateTimeInputWithNowAsDefault from "../../../../components/forms/DateTimeInputWithNowAsDefault";
import { activityTypeChoices, premiumPaymentCategoryChoices } from "../../../../constants";

const MarketActivityForm = ({ auctionActivity, ...props }) => {
  const {
    round,
    date,
    bid_rescinded_date,
    funder,
    provider,
    type,
    lr_spread = 0,
    custom_lr_spread,
    gross_offer = 0,
    rdb,
    rdb_offer,
    market_winning_bid,
    premium_payment_category,
    payment_description,
    premium_apportionment,
    apportionment_description,
    funder_offer_expiration_date,
    term_conversion_handling,
    term_conversion_handling_notes,
    conversion_compensation,
    notes,
    created_by_name,
    last_edited_by_name,
  } = auctionActivity;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        round,
        date: date || moment().tz("America/New_York").format("YYYY-MM-DD[T]HH:mm"),
        bid_rescinded_date,
        funder,
        provider,
        type,
        lr_spread,
        custom_lr_spread,
        gross_offer,
        rdb,
        rdb_offer,
        market_winning_bid,
        premium_payment_category,
        payment_description,
        premium_apportionment,
        apportionment_description,
        funder_offer_expiration_date,
        term_conversion_handling,
        term_conversion_handling_notes,
        conversion_compensation,
        notes,
        created_by_name,
        last_edited_by_name,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isEmpty(auctionActivity)
          ? props.createMarketAuctionActivity
          : partial(props.editMarketAuctionActivity, auctionActivity.id);

        try {
          await method(values);
          props.fetchAuctionActivities();
          notifications.success("Activity Detail saved successfully");
        } catch (error) {
          if (error.response.data.market_winning_bid) {
            props.showModal(AUCTION_ACTIVITY_CHANGE_WINNING_BID_MODAL, {
              activityValues: { ...values, id: auctionActivity.id, mode: "market" },
            });
          } else {
            setErrors(error.response.data);
            notifications.error("Error editing auction activity");
          }
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        const isBid =
          values.type === getValue(activityTypeChoices, "Bid") ||
          values.type === getValue(activityTypeChoices, "Bid Contingent") ||
          values.type === getValue(activityTypeChoices, "Bid Rescinded");
        const isBidRescinded = values.type === getValue(activityTypeChoices, "Bid Rescinded");
        const disabled = props.caseInfo.bidding_format === "LPEX";

        const onTermConversionChange = o => {
          let value = "";
          if (o.value) {
            value = `
<b>Conversion Timing:</b> The Owner shall convert the Policy prior to completion of closing documents.<br><br>
<b>Conversion Product:</b> Product Name; Premium Monthly Mode ($XXX.XX); Interest Allocation Account: Fixed Account; Gender Agent; Rate Class; Target Premium: $XXX.XX.<br><br>
<b>Broker General Agency:</b> Shall be converted using a BGA of LifeRoc’s choosing.<br><br>
<b>Agent Report Split:</b> Writing agent as XXX 0.00% and LifeRoc Financial & Insurance Services 100.00%<br><br>
<b>Term Conversion Compensation:</b> $XXX.XX (to be paid to the Broker or the Broker’s dually licensed and appointed representative by LifeRoc Financial & Insurance Services within 15 days of receipt of commission by the Carrier or BGA.)<br><br>
<b>New Owner Premium Funding:</b> Within two weeks of the Rescission expiration date any remaining unpaid target premium will be paid by the funder/new owner.`;
          }
          setFieldValue("term_conversion_handling_notes", value);
        };

        return (
          <div style={{ minHeight: 420 }}>
            <FormCollapsible onSubmit={handleSubmit} title="Market Activity Detail">
              <Form.Row>
                <Select
                  label="Provider"
                  name="provider"
                  placeholder="Select Provider"
                  options={props.providerChoices.getChoices({ sortByLabel: true })}
                  disabled={disabled}
                  isRequired
                />
                <DateTimeInput label="Activity Date" name="date" disabled={disabled} isRequired />
                <Select
                  label="Activity Type"
                  name="type"
                  placeholder="Select Activity Type"
                  options={activityTypeChoices}
                  isRequired
                  disabled={disabled}
                />
              </Form.Row>
              {isBid && (
                <Form.Row>
                  <CurrencyInput label="Gross Offer" name="gross_offer" isRequired={isBid} disabled={disabled} />
                  <DateInput
                    label="Offer Expiration"
                    name="funder_offer_expiration_date"
                    isRequired
                    disabled={disabled}
                  />
                  <BooleanSelect
                    label="Winning Bid"
                    name="market_winning_bid"
                    isRequired
                    defaultValue={{ value: false, label: "No" }}
                    disabled={disabled}
                  />
                </Form.Row>
              )}
              {isBidRescinded && (
                <Form.Row>
                  <DateTimeInputWithNowAsDefault
                    label="Bid Rescinded Date"
                    name="bid_rescinded_date"
                    md={4}
                    disabled={disabled}
                  />
                </Form.Row>
              )}
              {isBid && (
                <Form.Row>
                  <BooleanSelect
                    label="RDB"
                    name="rdb"
                    isRequired
                    defaultValue={{ value: false, label: "No" }}
                    md={4}
                    disabled={disabled}
                  />
                  {values.rdb === true && <TextInput disabled={disabled} label="RDB Offer" name="rdb_offer" />}
                </Form.Row>
              )}
              {isBid && (
                <Form.Row>
                  <Select
                    label="Premium Payment Category"
                    name="premium_payment_category"
                    placeholder="None"
                    options={premiumPaymentCategoryChoices}
                    defaultValue={{ value: "NO", label: "No" }}
                    isRequired
                    md={4}
                    disabled={disabled}
                  />
                  {values.premium_payment_category && (
                    <TextInput label="Premium Description" name="payment_description" isRequired disabled={disabled} />
                  )}
                </Form.Row>
              )}
              {isBid && (
                <Form.Row>
                  <BooleanSelect
                    label="Premium Apportionment"
                    name="premium_apportionment"
                    isRequired
                    defaultValue={{ value: false, label: "No" }}
                    md={4}
                    disabled={disabled}
                  />
                  {values.premium_apportionment === true && (
                    <TextInput label="Apportionment Description" name="apportionment_description" disabled={disabled} />
                  )}
                </Form.Row>
              )}
              {isBid && (
                <Form.Row>
                  <BooleanSelect
                    label="Term Conversion Handling"
                    name="term_conversion_handling"
                    defaultValue={{ value: false, label: "No" }}
                    onChangeCallback={onTermConversionChange}
                    md={4}
                    isRequired
                  />
                  {values.term_conversion_handling === true && (
                    <CurrencyInput label="Conversion Compensation Paid" name="conversion_compensation" />
                  )}
                </Form.Row>
              )}
              {values.term_conversion_handling ? (
                <Form.Row>
                  <EditorField label="Term Conversion Handling Notes" name="term_conversion_handling_notes" />
                </Form.Row>
              ) : null}
              <Form.Row>
                {created_by_name && <TextInput label="Created by" name="created_by_name" disabled={true} />}
                {last_edited_by_name && <TextInput label="Last edited by" name="last_edited_by_name" disabled={true} />}
              </Form.Row>
              <Form.Row>
                <EditorField label="Notes" name="notes" disabled={disabled} />
              </Form.Row>
              <SubmitButton disabled={disabled} />
            </FormCollapsible>
          </div>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const rounds = auctionRoundsSelector(state);
  const roundChoices = rounds.map(round => ({ label: round.number, value: round.id }));
  const auctionChoices = auctionChoicesSelector(state);
  const managementChoices = managementChoicesSelector(state);
  return {
    caseInfo: activeCaseSelector(state),
    auctionActivity: activeMarketAuctionActivitySelector(state) || {},
    rounds,
    roundChoices,
    providerChoices: managementChoices.case_providers,
    fundersChoices: auctionChoices.funders,
  };
};

export default connect(mapStateToProps, {
  createMarketAuctionActivity,
  editMarketAuctionActivity,
  showModal,
  fetchAuctionActivities,
})(MarketActivityForm);
