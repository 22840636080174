import React from "react";
import { connect, useDispatch } from "react-redux";
import { Formik, replace } from "formik";
import { Form } from "react-bootstrap";
import * as notifications from "../../../notifications";

import { CASE_EMAIL_MODAL } from "components/modals";
import { TextInput, EditorField, FormCollapsible, SubmitButton, Select } from "components/forms";
import { editCase, fetchActivityLog, sendCaseEmail } from "actions";
import {
  activeCaseSelector,
  managementChoicesSelector,
  agentsChoicesSelector,
  accountsSelector,
  mailFormDataSelector,
  activeCaseIdSelector,
  loadingSelector,
  emailTemplateSelector,
} from "reducers";
import { showModal, hideModal, selectCaseFile } from "../../../actions";
import MultipleFileDropZone from "../../../components/MultipleFileDropZone";
import File from "../../../components/File";

const CaseEmailForm = ({ caseInfo, currentFile, mailFormData, ...props }) => {
  if (!caseInfo) {
    // Still didn't fetch data
    return null;
  }

  if (!mailFormData?.user_fullname) {
    // Still didn't fetch data
    return null;
  }

  const {
    submission_id,
    submission_date,
    last_edit_date,
    jurisdiction,
    process,
    provider,
    source,
    broker,
    sales_representative,
    status,
    notes,
    status_date,
    case_manager,
    pricing_manager,
    auction_manager,
    closing_manager,
    sales_manager,
    national_account_manager,
  } = caseInfo;

  const { user_email, source_email, sales_rep_email, sales_manager_email, broker_email, subject } = mailFormData;
  let recipients = [source_email, sales_rep_email, broker_email].filter(n => n && n.trim());
  if (sales_manager_email && sales_manager_email !== sales_rep_email) {
    if (sales_manager_email.indexOf("none.house") === -1) {
      recipients.push(sales_manager_email);
    }
  }
  const url = `/life_settlement/api/cases/v2/${caseInfo.id}/download_file/`;
  const dispatch = useDispatch();

  recipients = recipients.join(", ").replaceAll(",", ";").trim();
  if (recipients && recipients.slice(-1) !== ";") {
    recipients += "; ";
  }

  const bcc = "";

  let email_template = props.emailTemplates.find(template => template.name === "Send Case Status");
  let email_template_id;
  let html;
  let replace_keys = ["user_fullname", "user_office_phone", "user_email", "user_organization_name"];
  if (email_template) {
    email_template_id = email_template.id;
    html = email_template.html;

    for (let k of replace_keys) {
      if (mailFormData[k]) {
        html = html.replace(`{${k}}`, mailFormData[k]);
      }
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        submission_id,
        submission_date,
        last_edit_date,
        jurisdiction,
        process,
        provider,
        source,
        broker,
        sales_representative,
        status,
        notes,
        status_date,
        case_manager,
        pricing_manager,
        auction_manager,
        closing_manager,
        sales_manager,
        national_account_manager,
        subject,
        recipients,
        user_email,
        html,
        bcc,
        email_template_id,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          let payload = { url: currentFile.url, ...values };
          await props.sendCaseEmail(payload);
          // await props[fetchMethodName]();
          // dropzoneRef.current.removeAll();
          notifications.success("Email sent");
          dispatch(hideModal(CASE_EMAIL_MODAL));
          props.fetchActivityLog(props.activeCaseId);
        } catch (error) {
          notifications.error("Error at sending email");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Send Case as Email">
            <Form.Row>
              <Select label="Email Template" name="email_template_id" options={props.templateChoices} disabled={true} />
            </Form.Row>
            <Form.Row>
              <TextInput label="From" name="user_email" required={true} disabled />
            </Form.Row>
            <Form.Row>
              <span
                style={{ marginBottom: "0.5rem", height: "2rem", paddingTop: "0.5rem" }}
                className="form-label__label"
              >
                Attachments
              </span>
              <MultipleFileDropZone setFieldValue={setFieldValue} />
            </Form.Row>
            <Form.Row>
              {props.loading.caseStatus ? (
                "Generating PDF File..."
              ) : (
                <File file={currentFile} uploadFile={props.uploadFile} fileDownloadUrl={url} />
              )}
            </Form.Row>
            <Form.Row>
              <TextInput label="To" name="recipients" required={true} isRequired={true} />
            </Form.Row>
            <Form.Row>
              <TextInput label="BCC" name="bcc" />
            </Form.Row>
            <Form.Row>
              <TextInput label="Subject" name="subject" />
            </Form.Row>
            <Form.Row>
              <EditorField label="Body" name="html" />
            </Form.Row>
            <SubmitButton defaultText="Send" />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const accounts = accountsSelector(state);
  const agentsChoices = agentsChoicesSelector(state);
  const emailTemplates = emailTemplateSelector(state);

  let templateChoices = [];
  for (let template of Object.values(emailTemplates)) {
    templateChoices.push({ label: template.name, value: template.id });
  }

  return {
    activeCaseId: activeCaseIdSelector(state),
    mailFormData: mailFormDataSelector(state),
    caseInfo: activeCaseSelector(state),
    caseProcessTypeChoices: choices.case_process_types,
    providerChoices: choices.case_providers,
    accountChoices: choices.accounts,
    agencyChoices: choices.agencies,
    salesRepChoices: choices.case_sales_representatives,
    caseManagerChoices: agentsChoices.case_managers,
    pricingManagerChoices: agentsChoices.pricing_managers,
    auctionManagerChoices: agentsChoices.auction_managers,
    closingManagerChoices: agentsChoices.closing_managers,
    accounts: accounts,
    loading: loadingSelector(state),
    emailTemplates,
    templateChoices,
  };
};

export default connect(mapStateToProps, {
  editCase,
  selectCaseFile,
  showModal,
  sendCaseEmail,
  fetchActivityLog,
})(CaseEmailForm);
