import React from "react";

import SortCaret from "../../../../components/table/SortCaret";

import { fundersOrderBy } from "../../../../actions";
import { connect } from "react-redux";

const FundersListTableHeader = ({ headerGroups, ...props }) => {
  return (
    <thead>
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              onClick={async e => {
                column.getHeaderProps(column.getSortByToggleProps()).onClick(e);
                await props.fundersOrderBy(column.id);
              }}
            >
              {column.render("Header")}
              <SortCaret column={column} />
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default connect(null, { fundersOrderBy })(FundersListTableHeader);
