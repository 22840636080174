import React from "react";
import { useParams } from "react-router-dom";

const withRouter = Component => {
  const Wrapper = props => {
    const params = useParams();
    return <Component {...props} params={params} />;
  };

  return Wrapper;
};

export default withRouter;
