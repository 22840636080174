import * as types from "actions/types";
import { arrayToObjectIndexedById } from "reducers/utils";
import { removeKey } from "utils.js";
import { choicesWrapper, undefinedChoicesProxy } from "options.js";
import { FETCH_TRADING_CASES } from "actions/types";

export const defaultState = {
  auction: {},
  auctionRounds: {},
  activeAuctionRoundId: null,
  auctionActivities: {},
  activeAuctionActivityId: null,
  choices: undefinedChoicesProxy,
  funders: [],
  selectedFunders: [],
  funderSearchString: null,
  totalTradingCases: {},
  tradeCases: {},
  tradeBlindCases: {},
  dpCases: {},
  tbdCases: {},
  funderAuctionActivities: {},
  marketAuctionActivities: {},
  activeMarketActivityFiles: null,
  suggestedCC: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_TRADING_CASES: {
      const trading_cases = action.payload;
      return {
        ...state,
        totalTradingCases: arrayToObjectIndexedById(trading_cases),
        tradeCases: arrayToObjectIndexedById(trading_cases.filter(c => c.revenue_model === "TRADING")),
        tradeBlindCases: arrayToObjectIndexedById(trading_cases.filter(c => c.revenue_model === "TRADING_BLIND")),
        dpCases: arrayToObjectIndexedById(trading_cases.filter(c => c.revenue_model === "DIRECT")),
        tbdCases: arrayToObjectIndexedById(trading_cases.filter(c => c.revenue_model === "TBD")),
      };
    }
    case types.FETCH_AUCTION_CHOICES: {
      let choices = {};
      Object.entries(action.payload).forEach(([key, value]) => {
        choices[key] = choicesWrapper(value);
      });
      return { ...state, choices };
    }

    case types.FETCH_AUCTION:
    case types.EDIT_AUCTION:
      return {
        ...state,
        auction: action.payload,
      };

    case types.FETCH_AUCTION_ROUNDS:
      return {
        ...state,
        auctionRounds: arrayToObjectIndexedById(action.payload),
      };

    case types.EDIT_AUCTION_ROUND:
    case types.CREATE_AUCTION_ROUND:
      return {
        ...state,
        auctionRounds: {
          ...state.auctionRounds,
          [action.payload.id]: action.payload,
        },
      };

    case types.SELECT_AUCTION_ROUND:
      return {
        ...state,
        activeAuctionRoundId: action.payload,
      };

    case types.DELETE_AUCTION_ROUND: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        auctionRounds: removeKey(state.auctionRounds, toDeleteId),
      };
      if (state.activeAuctionRoundId === toDeleteId) {
        newState.activeAuctionRoundId = null;
      }
      return newState;
    }

    case types.FETCH_FUNDER_AUCTION_ACTIVITIES:
      return {
        ...state,
        funderAuctionActivities: arrayToObjectIndexedById(action.payload),
      };

    case types.FETCH_MARKET_AUCTION_ACTIVITIES:
      return {
        ...state,
        marketAuctionActivities: arrayToObjectIndexedById(action.payload),
      };

    case types.GET_GROSS_OFFER:
      return {
        ...state,
        display_gross_offer: action.payload,
      };

    case types.EDIT_FUNDER_AUCTION_ACTIVITY:
    case types.CREATE_FUNDER_AUCTION_ACTIVITY:
      return {
        ...state,
        funderAuctionActivities: {
          ...state.funderAuctionActivities,
          [action.payload.id]: action.payload,
        },
      };
    case types.EDIT_MARKET_AUCTION_ACTIVITY:
    case types.CREATE_MARKET_AUCTION_ACTIVITY:
      return {
        ...state,
        marketAuctionActivities: {
          ...state.marketAuctionActivities,
          [action.payload.id]: action.payload,
        },
      };
    case types.EDIT_AUCTION_ACTIVITY:
    case types.CREATE_AUCTION_ACTIVITY:
      return {
        ...state,
        auctionActivities: {
          ...state.auctionActivities,
          [action.payload.id]: action.payload,
        },
      };

    case types.SELECT_AUCTION_ACTIVITY:
      return {
        ...state,
        activeAuctionActivityId: action.payload,
      };

    case types.DELETE_AUCTION_ACTIVITY: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        auctionActivities: removeKey(state.auctionActivities, toDeleteId),
      };
      if (state.activeAuctionActivityId === toDeleteId) {
        newState.activeAuctionActivityId = null;
      }
      return newState;
    }

    case types.DELETE_FUNDER_AUCTION_ACTIVITY: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        funderAuctionActivities: removeKey(state.funderAuctionActivities, toDeleteId),
      };
      if (state.activeAuctionActivityId === toDeleteId) {
        newState.activeAuctionActivityId = null;
      }
      return newState;
    }

    case types.DELETE_MARKET_AUCTION_ACTIVITY: {
      const toDeleteId = action.payload;
      const newState = {
        ...state,
        marketAuctionActivities: removeKey(state.marketAuctionActivities, toDeleteId),
      };
      if (state.activeAuctionActivityId === toDeleteId) {
        newState.activeAuctionActivityId = null;
      }
      return newState;
    }

    case types.FETCH_FUNDERS_FOR_AUCTION:
      return {
        ...state,
        funders: action.payload,
      };

    case types.SELECT_FUNDER:
      return {
        ...state,
        selectedFunders: [...state.selectedFunders, action.payload.id],
      };

    case types.DESELECT_FUNDER:
      return {
        ...state,
        selectedFunders: state.selectedFunders.filter(item => item !== action.payload.id),
      };

    case types.DESELECT_ALL_FUNDERS:
      return {
        ...state,
        selectedFunders: [],
      };

    case types.SET_SELECTED_FUNDERS:
      return {
        ...state,
        selectedFunders: action.payload,
      };

    case types.PARTIAL_FETCH_FUNDERS: {
      // Merge data with existing Funders list
      let funderList = action.payload;
      let newState = state;
      for (let funder of funderList) {
        newState.funders[funder.id] = {
          ...newState.funders[funder.id],
          ...funder,
        };
      }
      return newState;
    }

    case types.SET_SUGGESTED_CC:
      return {
        ...state,
        suggestedCC: action.payload,
      };

    case types.FUNDER_SEARCH_STRING:
      return {
        ...state,
        funderSearchString: action.payload,
      };

    case types.FETCH_MARKET_ACTIVITY_FILES:
      return {
        ...state,
        activeMarketActivityFiles: action.payload,
      };

    case types.CLEAN_LIFE_SETTLEMENT_CASE_DATA:
      return { ...defaultState };

    default:
      return state;
  }
};

// Selectors
export const auctionIdSelector = ({ auction }) => auction.id;

export const auctionSelector = ({ auction }) => auction;

export const auctionChoicesSelector = state => state.choices;

export const totalTradingCasesSelector = state => Object.values(state.totalTradingCases);

export const tradeCasesSelector = state => Object.values(state.tradeCases);

export const tradeBlindCasesSelector = state => Object.values(state.tradeBlindCases);

export const dpCasesSelector = state => Object.values(state.dpCases);

export const tbdCasesSelector = state => Object.values(state.tbdCases);

export const displayGrossOfferSelector = state => state.display_gross_offer;

export const activeAuctionRoundSelector = state => {
  const { activeAuctionRoundId, auctionRounds } = state;
  if (activeAuctionRoundId === null) {
    return null;
  }
  return auctionRounds[activeAuctionRoundId];
};

export const auctionRoundsSelector = state => Object.values(state.auctionRounds);

export const activeAuctionActivitySelector = state => {
  const { activeAuctionActivityId, auctionActivities } = state;
  if (activeAuctionActivityId === null) {
    return null;
  }
  return auctionActivities[activeAuctionActivityId];
};

export const activeFunderAuctionActivitySelector = state => {
  const { activeAuctionActivityId, funderAuctionActivities } = state;
  if (activeAuctionActivityId === null) {
    return null;
  }
  return funderAuctionActivities[activeAuctionActivityId];
};

export const activeMarketAuctionActivitySelector = state => {
  const { activeAuctionActivityId, marketAuctionActivities } = state;
  if (activeAuctionActivityId === null) {
    return null;
  }
  return marketAuctionActivities[activeAuctionActivityId];
};

export const auctionActivitiesSelector = state => Object.values(state.auctionActivities);

export const funderAuctionActivitiesSelector = state =>
  state.funderAuctionActivities && Object.values(state.funderAuctionActivities);

export const marketAuctionActivitiesSelector = state =>
  state.marketAuctionActivities && Object.values(state.marketAuctionActivities);

export const fundersForAuctionSelector = state => state.funders;

export const selectedFundersSelector = state => state.selectedFunders;

export const suggestedCCSelector = state => state.suggestedCC;

export const funderSearchStringSelector = state => state.funderSearchString;

export const funderWinningBidSelector = (state, activeCaseId) => {
  const winningBidList = funderAuctionActivitiesSelector(state).filter(
    f => f.funder_winning_bid === true && f.case_id === parseInt(activeCaseId),
  );
  return winningBidList ? winningBidList[0] : null;
};

export const marketWinningBidSelector = (state, activeCaseId) => {
  const winningBidList = marketAuctionActivitiesSelector(state).filter(
    f => f.market_winning_bid === true && f.case_id === parseInt(activeCaseId),
  );
  return winningBidList ? winningBidList[0] : null;
};

export const activeMarketActivityFilesSelector = state => {
  return state.activeMarketActivityFiles;
};
