import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import {} from "actions";
import { CASE_TASK_MODAL } from "components/modals";
import CaseTaskForm from "./CaseTaskForm";
import { activeCaseTaskSelector } from "../../../reducers";

const CaseTaskModal = ({ activeTask }) => {
  return (
    <FullPageModal modalType={CASE_TASK_MODAL}>
      <CaseTaskForm record={activeTask} />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    activeTask: activeCaseTaskSelector(state),
  };
};

export default connect(mapStateToProps, {})(CaseTaskModal);
