import * as types from "actions/types";
import advisorhq from "apis/advisorhq";
import {
  auctionIdSelector,
  activeAuctionActivitySelector,
  fundersSortBySelector,
} from "reducers";
import * as notifications from "notifications.js";
import { fetchFundersForAuction } from "./funders";
import {
  LOADING,
  FUNDERS_SORT_BY,
  STORE_TRADING_FILTERS_DATA,
  STORE_TRADING_STAGE_FILTER,
} from "actions/types";
import {
  DELETE_FUNDER_AUCTION_ACTIVITY_MODAL,
  DELETE_MARKET_AUCTION_ACTIVITY_MODAL,
  LOADING_MODAL,
} from "components/modals";
import { showModal, hideModal } from "actions";
import { sortByObj } from "../../utils";

export const fetchAuction = caseId => async dispatch => {
  const response = await advisorhq.get("/auctions/api/auctions/", {
    params: { case: caseId },
  });
  dispatch({ type: types.FETCH_AUCTION, payload: response.data });
};

export const fetchLightFunders = () => async dispatch => {
  const response = await advisorhq.get("/auctions/api/light_funders/");
  dispatch({ type: types.FETCH_FUNDERS, payload: response.data });
};

export const editAuction = (id, values) => async dispatch => {
  const response = await advisorhq.patch(`/auctions/api/auctions/${id}/`, values);
  dispatch({ type: types.EDIT_AUCTION, payload: response.data });
};

export const fetchAuctionRounds = () => async (dispatch, getState) => {
  const auctionId = auctionIdSelector(getState());
  const response = await advisorhq.get(`/auctions/api/rounds/`, {
    params: { auction: auctionId },
  });
  dispatch({ type: types.FETCH_AUCTION_ROUNDS, payload: response.data });
};

export const selectAuctionRound = id => ({
  type: types.SELECT_AUCTION_ROUND,
  payload: id,
});

export const createAuctionRound = values => async (dispatch, getState) => {
  const auctionId = auctionIdSelector(getState());
  const data = { auction: auctionId, ...values };
  const response = await advisorhq.post("/auctions/api/rounds/", data);
  dispatch({ type: types.CREATE_AUCTION_ROUND, payload: response.data });
  dispatch(selectAuctionRound(response.data.id));
};

export const editAuctionRound = (id, data) => async dispatch => {
  if (data.spread_type === "C") data.spread_rate = "";
  const response = await advisorhq.patch(`/auctions/api/rounds/${id}/`, data);
  dispatch({ type: types.EDIT_AUCTION_ROUND, payload: response.data });
  // After editing round, re-fetch the activities because some of the activity
  // computed fields depend on the rounds.
  dispatch(fetchMarketAuctionActivities());
  dispatch(fetchFunderAuctionActivities());
};

export const deleteAuctionRound = id => async dispatch => {
  await advisorhq.delete(`/auctions/api/rounds/${id}/`);
  dispatch({ type: types.DELETE_AUCTION_ROUND, payload: id });
};

export const fetchAuctionActivities = () => async (dispatch, getState) => {
  const auctionId = auctionIdSelector(getState());
  if (auctionId) {
    const response = await advisorhq.get(`/auctions/api/activities/`, {
      params: { auction: auctionId },
    });
    dispatch({ type: types.FETCH_AUCTION_ACTIVITIES, payload: response.data });
    dispatch({ type: LOADING, payload: { auction: false } });
  }
};

export const fetchMarketAuctionActivities = () => async (dispatch, getState) => {
  const auctionId = auctionIdSelector(getState());
  if (auctionId) {
    const response = await advisorhq.get(`/auctions/api/market_activities/`, {
      params: { auction: auctionId },
    });
    dispatch({ type: types.FETCH_MARKET_AUCTION_ACTIVITIES, payload: response.data });
    dispatch({ type: LOADING, payload: { auction: false } });
  }
};

export const fetchFunderAuctionActivities = () => async (dispatch, getState) => {
  const auctionId = auctionIdSelector(getState());
  if (auctionId) {
    const response = await advisorhq.get(`/auctions/api/funder_activities/`, {
      params: { auction: auctionId },
    });
    dispatch({ type: types.FETCH_FUNDER_AUCTION_ACTIVITIES, payload: response.data });
    dispatch({ type: LOADING, payload: { auction: false } });
  }
};

export const calculateGrossOffer =
  (date, total_offer) => async (dispatch, getState) => {
    const auctionId = auctionIdSelector(getState());
    if (auctionId) {
      const response = await advisorhq.post(`/auctions/api/calculate_gross_offer/`, {
        auction: auctionId,
        date: date,
        total_offer: total_offer,
      });
      dispatch({ type: types.GET_GROSS_OFFER, payload: response.data });
      return response;
    }
  };

export const selectAuctionActivity = id => ({
  type: types.SELECT_AUCTION_ACTIVITY,
  payload: id,
});

export const createFunderAuctionActivity = values => async (dispatch, getState) => {
  const auctionId = auctionIdSelector(getState());
  const data = { auction: auctionId, ...values };
  const response = await advisorhq.post("/auctions/api/funder_activities/", data);
  dispatch({ type: types.CREATE_AUCTION_ACTIVITY, payload: response.data });
  dispatch({ type: types.SELECT_AUCTION_ACTIVITY, payload: response.data.id });
  notifications.success("Funder Activity created successfully");

  // Re-fetch funders with status to have them automatically updated.
  dispatch(fetchFundersForAuction(auctionId));
  dispatch(fetchFunderAuctionActivities(auctionId));
  dispatch(fetchMarketAuctionActivities(auctionId));
};

export const createMarketAuctionActivity = values => async (dispatch, getState) => {
  const auctionId = auctionIdSelector(getState());
  const data = { auction: auctionId, ...values };
  const response = await advisorhq.post("/auctions/api/market_activities/", data);
  dispatch({ type: types.CREATE_AUCTION_ACTIVITY, payload: response.data });
  dispatch({ type: types.SELECT_AUCTION_ACTIVITY, payload: response.data.id });
  notifications.success("Market Activity saved successfully");

  // Re-fetch funders with status to have them automatically updated.
  dispatch(fetchFundersForAuction(auctionId));
  dispatch(fetchMarketAuctionActivities(auctionId));
};

export const editFunderAuctionActivity = (id, data) => async (dispatch, getState) => {
  const response = await advisorhq.patch(
    `/auctions/api/funder_activities/${id}/`,
    data,
  );
  dispatch({ type: types.EDIT_FUNDER_AUCTION_ACTIVITY, payload: response.data });
  dispatch({ type: types.SELECT_AUCTION_ACTIVITY, payload: response.data.id });
  notifications.success("Funder Activity saved successfully");

  // Re-fetch funders with status to have them automatically updated.
  const auctionId = auctionIdSelector(getState());
  dispatch(fetchFundersForAuction(auctionId));
  dispatch(fetchFunderAuctionActivities(auctionId));
  dispatch(fetchMarketAuctionActivities(auctionId));
};

export const editMarketAuctionActivity = (id, data) => async (dispatch, getState) => {
  const response = await advisorhq.patch(
    `/auctions/api/market_activities/${id}/`,
    data,
  );
  dispatch({ type: types.EDIT_MARKET_AUCTION_ACTIVITY, payload: response.data });
  dispatch({ type: types.SELECT_AUCTION_ACTIVITY, payload: response.data.id });
  notifications.success("Market Activity saved successfully");

  // Re-fetch funders with status to have them automatically updated.
  const auctionId = auctionIdSelector(getState());
  dispatch(fetchFundersForAuction(auctionId));
  dispatch(fetchMarketAuctionActivities(auctionId));
};

export const deleteFunderAuctionActivity = id => async (dispatch, getState) => {
  await advisorhq.delete(`/auctions/api/funder_activities/${id}/`);
  dispatch({ type: types.DELETE_FUNDER_AUCTION_ACTIVITY, payload: id });
  notifications.success("Funder Activity deleted successfully");

  // Re-fetch funders with status to have them automatically updated.
  const auctionId = auctionIdSelector(getState());
  dispatch(fetchFundersForAuction(auctionId));
  dispatch(fetchFunderAuctionActivities(auctionId));
  dispatch(fetchMarketAuctionActivities(auctionId));
  dispatch(hideModal(DELETE_FUNDER_AUCTION_ACTIVITY_MODAL));
};

export const deleteMarketAuctionActivity = id => async (dispatch, getState) => {
  await advisorhq.delete(`/auctions/api/market_activities/${id}/`);
  dispatch({ type: types.DELETE_MARKET_AUCTION_ACTIVITY, payload: id });
  notifications.success("Market Activity deleted successfully");

  // Re-fetch funders with status to have them automatically updated.
  const auctionId = auctionIdSelector(getState());
  dispatch(fetchFundersForAuction(auctionId));
  dispatch(fetchMarketAuctionActivities(auctionId));
  dispatch(hideModal(DELETE_MARKET_AUCTION_ACTIVITY_MODAL));
};

export const uploadAuctionActivityFile = file => async (dispatch, getState) => {
  const activeAuctionActivity = activeAuctionActivitySelector(getState());

  const formData = new FormData();
  formData.append("file", file, file.name);

  dispatch(
    showModal(LOADING_MODAL, {
      message: "Uploading file...",
    }),
  );
  const response = await advisorhq.post(
    `/auctions/api/activities/${activeAuctionActivity.id}/upload_file/`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

  dispatch(hideModal(LOADING_MODAL));
  dispatch({ type: types.EDIT_AUCTION_ACTIVITY, payload: response.data });
  notifications.success("File uploaded successfully");
};

export const uploadFunderAuctionActivityFile = file => async (dispatch, getState) => {
  const activeAuctionActivity = activeAuctionActivitySelector(getState());

  const formData = new FormData();
  formData.append("file", file, file.name);

  dispatch(
    showModal(LOADING_MODAL, {
      message: "Uploading file...",
    }),
  );
  const response = await advisorhq.post(
    `/auctions/api/funder_activities/${activeAuctionActivity.id}/upload_file/`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

  dispatch(hideModal(LOADING_MODAL));
  dispatch({ type: types.EDIT_FUNDER_AUCTION_ACTIVITY, payload: response.data });
  notifications.success("File uploaded successfully");
};

export const uploadMarketAuctionActivityFile = file => async (dispatch, getState) => {
  const activeAuctionActivity = activeAuctionActivitySelector(getState());

  const formData = new FormData();
  formData.append("file", file, file.name);

  dispatch(
    showModal(LOADING_MODAL, {
      message: "Uploading file...",
    }),
  );
  const response = await advisorhq.post(
    `/auctions/api/market_activities/${activeAuctionActivity.id}/upload_file/`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

  dispatch(hideModal(LOADING_MODAL));
  dispatch({ type: types.EDIT_FUNDER_AUCTION_ACTIVITY, payload: response.data });
  notifications.success("File uploaded successfully");
};

export const deleteAuctionActivityFile = id => async dispatch => {
  const response = await advisorhq.delete(
    `/auctions/api/market_activities/${id}/delete_file/`,
  );
  dispatch({ type: types.EDIT_AUCTION_ACTIVITY, payload: response.data });
};

export const deleteFunderAuctionActivityFile = id => async dispatch => {
  const response = await advisorhq.delete(
    `/auctions/api/market_activities/${id}/delete_file/`,
  );
  dispatch({ type: types.EDIT_AUCTION_ACTIVITY, payload: response.data });
};

export const deleteMarketAuctionActivityFile = id => async dispatch => {
  const response = await advisorhq.delete(
    `/auctions/api/market_activities/${id}/delete_file/`,
  );
  dispatch({ type: types.EDIT_AUCTION_ACTIVITY, payload: response.data });
};

export const fundersOrderBy = inc_sort_by => async (dispatch, getState) => {
  const sortBy = fundersSortBySelector(getState());
  dispatch({
    type: FUNDERS_SORT_BY,
    payload: sortByObj(inc_sort_by, sortBy, "funderslist"),
  });
};

export const storeTradingFiltersData = filters => async (dispatch, getState) => {
  dispatch({ type: STORE_TRADING_FILTERS_DATA, payload: filters });
};

export const storeTradingStageFiltersData = filters => async (dispatch, getState) => {
  dispatch({ type: STORE_TRADING_STAGE_FILTER, payload: filters });
};
