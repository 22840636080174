import React from "react";

import Table, { datetimeCell } from "components/table";
import { FaTrash } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DELETE_PRODUCT_TEMPLATE_MODAL } from "components/modals";
import { connect } from "react-redux";
import { editProduct, showModal } from "actions";

const ProductTemplatesTable = ({ currentProduct, ...props }) => {
  let productTemplates = [];
  if (currentProduct && currentProduct.product_templates) {
    productTemplates = currentProduct.product_templates;
  }

  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const productTemplateId = row.original.id;
        const icon = (
          <OverlayTrigger key="top" placement="top" overlay={<Tooltip>Delete Product Template</Tooltip>}>
            <FaTrash
              onClick={() =>
                props.showModal(DELETE_PRODUCT_TEMPLATE_MODAL, {
                  id: productTemplateId,
                })
              }
            />
          </OverlayTrigger>
        );
        return icon;
      },
    },
    {
      Header: "File",
      Cell: ({ row }) => {
        let filePath = row.original.file;
        let fileParts = filePath.split("/");
        let fileName = fileParts[fileParts.length - 1];
        if (fileName.indexOf("?AWSAccessKeyId") !== 1) {
          fileName = fileName.split("?AWSAccessKeyId")[0];
        }
        return <a href={filePath}>{fileName}</a>;
      },
    },
    {
      Header: "Version",
      accessor: "version",
      className: "wrap-text",
    },
    {
      Header: "Date Added",
      accessor: "date_added",
    },
    {
      Header: "Last Modified",
      accessor: "last_modified",
      Cell: datetimeCell,
    },
    {
      Header: "Default Template",
      Cell: ({ row }) => {
        return (
          <input
            type="checkbox"
            checked={row.original.id === currentProduct.product_template_id}
            onClick={e => {
              props.editProduct(currentProduct.id, {
                product_template_id: row.original.id,
              });
            }}
          />
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      data={productTemplates}
      showPaginationResults={false}
      defaultSort={true}
      defaultPagination={true}
      emptyMessage={"No Product Templates"}
    />
  );
};

export default connect(null, {
  showModal,
  editProduct,
})(ProductTemplatesTable);
