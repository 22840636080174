export const getCustomStyles = isDisabled => {
  return {
    control: (provided, state) => ({
      ...provided,
      background: isDisabled ? "#e9ecef" : "#fff",
      minHeight: "36px",
      border: "1px solid #ced4da",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      boxShadow: state.isFocused ? null : null,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0 6px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#00b050" : "inherit",
      "&:hover": { backgroundColor: state.isSelected ? "#00b050" : "#bee6c3" },
    }),
    input: (provided, state) => ({
      ...provided,
    }),
    placeholder: styles => ({
      ...styles,
      color: isDisabled ? "#495057" : "#808080",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#495057",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "36px",
    }),
    indicatorSeparator: state => ({
      display: "none",
    }),
    menuPortal: base => ({
      ...base,
      zIndex: 9999999,
    }),
    menu: provided => ({
      height: "auto", // Ensure menu height is auto to fit all options
      ...provided,
    }),
    menuList: provided => ({
      height: "auto", // Ensure the list has no height limit
      maxHeight: "none", // Explicitly remove any maxHeight
      ...provided,
    }),
  };
};
