import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { AUCTION_MARKET_ACTIVITY_MODAL } from "components/modals";
import MarketActivityForm from "./MarketActivityForm";
import { deleteMarketAuctionActivityFile, uploadMarketAuctionActivityFile } from "actions";

const MarketActivityModal = ({ ...props }) => {
  return (
    <FullPageModal modalType={AUCTION_MARKET_ACTIVITY_MODAL} title="Market Activity Detail">
      <MarketActivityForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  uploadMarketAuctionActivityFile,
  deleteMarketAuctionActivityFile,
})(MarketActivityModal);
