import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { editLifeExpectancy, updateLifeExpectancy } from "actions";
import {
  DateInput,
  FormCollapsible,
  GenderSelect,
  SubmitButton,
  TextInput,
  Select,
  EditorField,
} from "components/forms";
import * as notifications from "../../../../notifications";
import AgeAssessedField from "../AgeAssessedField";
import { extractedLifeExpectancyPDFdataSelector } from "reducers";
import { underwritingTypeChoices } from "../../../../constants";

const LapetusForm = ({ lifeExpectancyData, ...props }) => {
  const smokingOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
    { label: "Former Smoker", value: "Former Smoker" },
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={lifeExpectancyData}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          await props.updateLifeExpectancy(lifeExpectancyData.id, values);
          await props.editLifeExpectancy(lifeExpectancyData.id);
          notifications.success(`Lapetus form saved successfully`);
        } catch (error) {
          setErrors(error.response.data);
          notifications.error(`Error saving Lapetus form, please check form errors`);
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title={`Lapetus Life Expectancy`}>
            <Form.Row>
              <Select
                label="LE UW Type"
                name="underwriting_type" // ??
                placeholder="Select Underwriting Type"
                options={underwritingTypeChoices}
              />
            </Form.Row>
            <Form.Row>
              <TextInput label="First Name" name="first_name" />
              <TextInput label="Middle Initial" name="middle_initial" md={2} />
              <TextInput label="Last Name" name="last_name" />
            </Form.Row>
            <Form.Row>
              <GenderSelect />
              <Select label="Smoking" name="medical_info.smoking" options={smokingOptions} />
            </Form.Row>
            <Form.Row>
              <DateInput label="Date of Birth" name="date_of_birth" />
              <TextInput label="Certificate ID" name="certificate_id" />
            </Form.Row>
            <Form.Row>
              <DateInput label="Certificate Date" md={6} name="certificate_date" />
              <AgeAssessedField md={6} label="Evaluation Age" {...values} />
            </Form.Row>
            <Form.Row>
              <TextInput label="Mean LE" name="mean" placeholder="Enter Mean LE" prepend="Months" />
              <TextInput label="Mortality" name="mortality_rating" placeholder="Enter %" append="%" />
            </Form.Row>
            <Form.Row>
              <TextInput label="Height" name="medical_info.height" placeholder="Enter Height" />
              <TextInput label="Weight" name="medical_info.weight" placeholder="Enter Weight" />
              <TextInput label="BMI" name="medical_info.bmi" placeholder="Enter BMI" />
            </Form.Row>
            <Form.Row>
              <TextInput label="Education" name="medical_info.education" placeholder="Enter Education" />
              <TextInput label="Family Income" name="medical_info.family_income" placeholder="Enter Family Income" />
              <TextInput label="Marital Status" name="medical_info.martial_status" placeholder="Enter Marital Status" />
            </Form.Row>
            <Form.Row>
              <TextInput
                label="Physical Activity"
                name="medical_info.physical_activity"
                placeholder="Enter Physical Activity"
              />
              <TextInput
                label="Self-Reported Health"
                name="medical_info.self_reported_health"
                placeholder="Enter Self-Reported Health"
              />
              <TextInput
                label="# of Relatives Aged 85 or Over"
                name="medical_info.n_relatives_aged_85_plus"
                placeholder="Enter #"
              />
            </Form.Row>
            <Form.Row>
              <TextInput label="Cholesterol" name="medical_info.cholesterol" placeholder="Enter Cholesterol" />
              <TextInput label="Blood Sugar" name="medical_info.fasting_blood_sugar" placeholder="Enter Blood Sugar" />
              <TextInput label="Blood Pressure" name="medical_info.blood_pressure" placeholder="Enter Blood Pressure" />
            </Form.Row>
            <Form.Row>
              <TextInput label="Lapetus LE Range" name="medical_info.lapetus_le_range" placeholder="Enter LE Range" />
              <TextInput
                label="Lapetus Survival Skew"
                name="medical_info.lapetus_survival_skew"
                placeholder="Enter Survival Skew"
              />
              <TextInput
                label="Lapetus Projected Death Age"
                name="medical_info.lapetus_projected_death_age"
                placeholder="Enter Projected Death Age"
              />
            </Form.Row>
            <Form.Row>
              <EditorField label="Mortality Summary" name="medical_info.mortality_summary" />
            </Form.Row>
            <Form.Row>
              <EditorField label="Medical History Summary" name="medical_info.medical_history_summary" />
            </Form.Row>
            <Form.Row>
              <EditorField label="Misrepresentation" name="medical_info.misrepresentation" />
            </Form.Row>
            <Form.Row>
              <EditorField label="Health Status Summary" name="medical_info.health_status_summary" />
            </Form.Row>
            <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state, ownProps) => {
  const extractedPDFdata = extractedLifeExpectancyPDFdataSelector(state);

  // Life expectancy data will be either the extracted data from the uploaded PDF
  // or the saved LifeExpectancy instance.
  const lifeExpectancyData = extractedPDFdata || ownProps.lifeExpectancy;

  // Make sure lifeExpectancyData has an id prop, because the extractedPDFdata won't
  // have one and we need it in the form.
  const { id } = ownProps.lifeExpectancy;
  lifeExpectancyData.id = id;

  return {
    lifeExpectancyData,
  };
};

export default connect(mapStateToProps, { updateLifeExpectancy, editLifeExpectancy })(LapetusForm);
