import React from "react";

const TabHeader = ({ children, ...props }) => (
  <div className="table-header">{children}</div>
);

const Title = ({ children, active, ...props }) => (
  <h4
    className="title"
    style={{
      marginBottom: "0",
      textDecoration: active ? "underline" : "none",
      cursor: "pointer",
    }}
    {...props}
  >
    {children}
  </h4>
);

const Actions = ({ children, ...props }) => (
  <div className="actions" {...props}>
    {children}
  </div>
);

TabHeader.Title = Title;
TabHeader.Actions = Actions;

export default TabHeader;
