import React from "react";
import { useDispatch } from "react-redux";
import { Select, SelectCreatable } from "components/forms";
import CATEGORY_MAP, { NOTES_MAP, FRID_MAP, STATUS_CASE_TABLE_OUTSTANDING } from "./constants";
import { useFormikContext } from "formik";
import { findObjectByPropertyValue, fullNameDisplay, getValue, titleCase } from "../../../utils";
import { VENDORS } from "../../../constants";
import {
  CLEAR_CASE_FILE_CATEGORY,
  CLEAR_CASE_FILE_DESCRIPTION,
  STORE_CASE_FILE_CATEGORY,
  STORE_CASE_FILE_DESCRIPTION,
} from "../../../actions/types";
import moment from "moment";
import { FIND_DOCTOR_MODAL } from "../../../components/modals";
import { FaFirstAid } from "react-icons/fa";

const CategorySelect = ({
  values,
  ownerList,
  insuredList,
  beneficiaryList,
  policy,
  requiredOfChoices,
  ownerChoices,
  caseFile,
  activeCase,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const [subCategoryDisabled, setSubCategoryDisabled] = React.useState(false);
  const insured_needs_defining_label = "Insured (define Policy Insured Lives first)";
  const owner_needs_defining_label = "Owner (define Owners first)";
  const beneficiary_needs_defining_label = "Beneficiary (define Beneficiaries first)";
  const labelsNeedingDefining = [
    insured_needs_defining_label,
    owner_needs_defining_label,
    beneficiary_needs_defining_label,
  ];

  const getCategoryChoices = () => {
    let choices = [];
    for (let key of Object.keys(CATEGORY_MAP)) {
      let label = key;
      if (key === "Owner") {
        // When there are no Owners, show warning
        if (ownerList.length === 0) {
          choices.push({ label: owner_needs_defining_label, value: "Owner" });
        } else {
          // For each Owner, show Owner Name (Current/Past Owner) as the label
          // value is Owner + Person/Entity/Insured + ID
          for (let owner of ownerList) {
            key = `Owner-${owner.owner_type}-${owner.id}`;
            label = getOwnerLabel(key, ownerList, insuredList);
            choices.push({ label: label, value: key });
          }
        }
        continue;
      } else if (key.indexOf("Insured") !== -1) {
        let insuredNumber = _getN(key);
        if (insuredNumber === 1 || (insuredNumber === 2 && insuredList.length >= 2)) {
          label = getInsuredLabel(key, insuredList);
        } else {
          // Hide the option if the insured number exceeds the number of defined insureds
          label = null;
        }
        // When there are no Insureds, show warning
        if (label === "Insured") {
          label = insured_needs_defining_label;
        }
      } else if (key.indexOf("Beneficiary") !== -1) {
        let BeneficiaryNumber = _getN(key);
        if (BeneficiaryNumber === 1 || (BeneficiaryNumber === 2 && beneficiaryList.length >= 2)) {
          label = getBeneficiaryLabel(key, beneficiaryList);
        } else {
          // Hide the option if the insured number exceeds the number of defined insureds
          label = null;
        }
        // When there are no Beneficiaries, show warning
        if (label === "Beneficiary") {
          label = beneficiary_needs_defining_label;
        }
      }
      if (label) {
        choices.push({ label: label, value: key });
      }
    }

    // If "Insured 2" is found, move it to the second position
    const insured2Index = choices.findIndex(item => item.value === "Insured 2");
    if (insured2Index > -1) {
      const insured2Item = choices.splice(insured2Index, 1)[0];
      choices.splice(1, 0, insured2Item); // Place "Insured 2" in the second position
    }
    return choices;
  };

  React.useEffect(() => {
    if (props.doctorInfo && props.doctorInfo.npi_name) {
      let formatted_name = `Dr. ${titleCase(props.doctorInfo.npi_name)}`;
      setFieldValue("description", formatted_name);
    }
  }, [props.doctorInfo]);

  const getSubCategoryChoices = () => {
    let choices = [];
    if (values.category) {
      let sub_categories;
      if (values.category.indexOf("Owner") !== -1) {
        sub_categories = CATEGORY_MAP.Owner;
      } else {
        sub_categories = CATEGORY_MAP[values.category];
      }
      for (const key of Object.keys(sub_categories)) {
        choices.push({ label: key, value: key });
      }
    }
    return choices;
  };

  const getDescriptionChoices = () => {
    let choices = [];
    let names = [];
    if (values.category && values.sub_category) {
      let sub_categories;
      if (values.category.indexOf("Owner") !== -1) {
        sub_categories = CATEGORY_MAP.Owner;
      } else {
        sub_categories = CATEGORY_MAP[values.category];
      }

      let descriptions = sub_categories[values.sub_category];
      if (!descriptions) {
        return;
      }

      if (values.category === "Insured" && values.sub_category === "LE") {
        // The following items should be excluded from the list when selecting Insured/LE: LRLE, CLAR, CONV, FOCUS
        let caseJurisdiction = activeCase.jurisdiction;
        let isFloridaOrTexasJurisdiction = ["FL", "TX"].indexOf(caseJurisdiction) !== -1;
        let isSecondaryMarketType = activeCase.market_type === "SECONDARY";
        let secondaryFloridaTexasConditionMet = isFloridaOrTexasJurisdiction && isSecondaryMarketType;
        if (secondaryFloridaTexasConditionMet) {
          descriptions = descriptions.filter(desc => ["LRLE", "CLAR", "CONV", "FOCUS"].indexOf(desc.id) === -1);
        }
      }
      for (const description of descriptions) {
        names.push(description.name);
        const value = description.value || description.name;
        choices.push({ label: description.name, value: value });
      }
    }
    // Add the selected name if it doesn't match any of the pre-defined options
    if (names.indexOf(values.description) === -1 && Object.values(VENDORS).indexOf(values.description) === -1) {
      choices.push({ label: values.description, value: values.description });
    }

    // Add doctor name if relevant
    if (values.sub_category === "APS" && props.doctorInfo?.npi_name) {
      let formatted_name = `Dr. ${titleCase(props.doctorInfo.npi_name)}`;
      let nameAlreadyExists = findObjectByPropertyValue(choices, "label", formatted_name);
      if (!nameAlreadyExists) {
        choices.push({ label: formatted_name, value: formatted_name });
      }
    }

    if (choices) {
      choices.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        } else {
          return -1;
        }
      });
    }
    return choices;
  };

  const findFRID = (category, sub_category, description) => {
    try {
      if (category.indexOf("Owner") !== -1) {
        return CATEGORY_MAP.Owner[sub_category].find(d => d.name === description).id;
      } else {
        return CATEGORY_MAP[category][sub_category].find(d => d.name === description).id;
      }
    } catch (e) {
      return "";
    }
  };

  const fallback_FRID = (category, sub_category, description) => {
    try {
      return CATEGORY_MAP[category][sub_category][0].id;
    } catch (e) {
      console.log(`Could not find FRID for '${category}-${sub_category}-${description}'`);
      return "";
    }
  };

  const find_LE_FRID = (category, sub_category, description) => {
    try {
      return CATEGORY_MAP[category][sub_category].find(d => d.value === description).id;
    } catch (e) {
      return "";
    }
  };

  const fallback_LE_FRID = (category, sub_category, description) => {
    try {
      return CATEGORY_MAP[category][sub_category][0].id;
    } catch (e) {
      console.log(`Could not find FRID for '${category}-${sub_category}-${description}'`);
      return "";
    }
  };

  let extraButtons;
  if (activeCase.id && (values.description === "Enter Doctor Information" || values.fr_id === "MR")) {
    extraButtons = (
      <span style={{ float: "right", marginTop: "-6px" }}>
        <button
          className={`btn btn-sm btn--secondary`}
          style={{ backgroundColor: "blue", float: "right", fontSize: 14, padding: "2px 5px" }}
          onClick={e => {
            e.preventDefault();
            props.submitRef.current.click();
            props.showModal(FIND_DOCTOR_MODAL);
          }}
        >
          <FaFirstAid />
          Find Doctor
        </button>
      </span>
    );
  }

  // Handle the case where the Owner/Insured was set later deleted
  let categoryChoices = getCategoryChoices();
  if (values.category) {
    let categoryOptionExists = findObjectByPropertyValue(categoryChoices, "value", values.category);
    if (!categoryOptionExists && values.category.includes("-")) {
      values.category = values.category.split("-")[0];
    }
  }

  return (
    <>
      <Select
        label="Category"
        name="category"
        placeholder="Select Category"
        options={categoryChoices}
        disabled={props.disabled}
        onChange={option => {
          let value;
          value = option === null ? "" : option.value;
          setFieldValue("category", value);
          // When selecting an Insured/Owner/Beneficiary, if the option was already selected, don't blank
          // When changing a category completely, blank the other options
          let selectingInsured = value.includes("Insured") && values.category === "Insured";
          let selectingOwner = value.includes("Owner") && values.category === "Owner";
          let selectingBeneficiary = value.includes("Beneficiary") && values.category === "Beneficiary";
          if (!(selectingInsured || selectingOwner || selectingBeneficiary)) {
            setFieldValue("sub_category", "");
            setFieldValue("description", "");
            setFieldValue("fr_id", "");
          }
          if (value) {
            dispatch({ type: STORE_CASE_FILE_CATEGORY, payload: value });
          } else {
            dispatch({ type: CLEAR_CASE_FILE_CATEGORY });
          }
          let disabledState = option?.label ? labelsNeedingDefining.includes(option.label) : true;
          setSubCategoryDisabled(disabledState);

          let today = moment();
          let followUpDays = 7;
          if (value === "Policy") {
            // Policy - set Follow Up Date to 2 days instead of the 7 by default
            followUpDays = 2;
          }
          const follow_up_date = today.add(followUpDays, "d").format("YYYY-MM-DD");
          setFieldValue("follow_up_date", follow_up_date);
        }}
      />
      <Select
        label="Sub-Category"
        name="sub_category"
        placeholder="Select Sub-Category"
        options={getSubCategoryChoices()}
        disabled={values.category === undefined || subCategoryDisabled || props.disabled}
        onChange={option => {
          let value;
          value = option === null ? "" : option.value;
          setFieldValue("sub_category", value);
          setFieldValue("description", "");
          setFieldValue("fr_id", "");
        }}
      />
      <SelectCreatable
        label="Description"
        name="description"
        placeholder="Select Description"
        options={getDescriptionChoices()}
        disabled={
          values.category === undefined || values.sub_category === undefined || subCategoryDisabled || props.disabled
        }
        onChange={option => {
          console.log("ACTIVE CASE: ", activeCase);
          const caseManager = activeCase.case_manager;
          const closingManager = activeCase.closing_manager;
          const tradingManager = activeCase.auction_manager;
          const pricingManager = activeCase.pricing_manager;
          const underwritingManager = activeCase.underwriting_manager;
          const servicingManager = activeCase.servicing_manager;
          let value;
          value = option === null ? "" : option.value;
          if (!value && !values.fr_id) {
            return;
          }
          setFieldValue("description", value);
          let new_fr_id;
          let should_change_fr_id = true;
          if (values.sub_category === "LE") {
            new_fr_id = find_LE_FRID(values.category, values.sub_category, value);
          } else {
            new_fr_id = findFRID(values.category, values.sub_category, value);
          }
          let current_fr_id = values.fr_id;

          // Handle case when there is custom written input
          if (new_fr_id === "") {
            if (current_fr_id === "") {
              console.log("New FR_ID would be empty and current is empty - use fallback");
              if (values.sub_category === "LE") {
                new_fr_id = fallback_LE_FRID(values.category, values.sub_category, value);
              } else {
                new_fr_id = fallback_FRID(values.category, values.sub_category, value);
              }
            } else {
              console.log("New FR_ID would be empty but current is not empty - keep current");
              should_change_fr_id = false;
            }
          } else {
            console.log("Current FR_ID is:", current_fr_id);
            console.log(`New FR_ID for ${value} is:`, new_fr_id);
          }

          if (should_change_fr_id) {
            setFieldValue("fr_id", new_fr_id);
            setFieldValue("status", getStatusFromFRID(new_fr_id));

            let taskOwner = getTaskOwnerFromFRID(new_fr_id);
            if (taskOwner === "Closing Manager") {
              setFieldValue("responsible", closingManager);
            } else if (taskOwner === "Pricing Manager") {
              setFieldValue("responsible", pricingManager);
            } else if (taskOwner === "Case Manager") {
              setFieldValue("responsible", caseManager);
            } else if (taskOwner === "Trading Manager") {
              setFieldValue("responsible", tradingManager);
            } else if (taskOwner === "Underwriting Manager") {
              setFieldValue("responsible", underwritingManager);
            } else if (taskOwner === "Servicing Manager") {
              setFieldValue("responsible", servicingManager);
            } else {
              // If no taskOwner, find and set None / House
              for (let owner of ownerChoices.getChoices()) {
                if (owner.label === "None / House") {
                  setFieldValue("responsible", owner.value);
                  break;
                }
              }
            }

            setFieldValue("required_of", getResponsiblePartyFromFRID(new_fr_id, requiredOfChoices));
            if (!values.notes) {
              setFieldValue("notes", getNotesFromFRID(new_fr_id));
            }
            if (value) {
              dispatch({ type: STORE_CASE_FILE_DESCRIPTION, payload: value });
            } else {
              dispatch({ type: CLEAR_CASE_FILE_DESCRIPTION });
            }
          }

          let today = moment();
          let businessDaysToAdd = getBusinessDaysToAdd(value);
          if (businessDaysToAdd > 0) {
            let followUpDate = addBusinessDays(moment(today), businessDaysToAdd);
            let follow_up_date = followUpDate.format("YYYY-MM-DD");
            setFieldValue("follow_up_date", follow_up_date);
          }
        }}
        buttons={extraButtons}
      />
    </>
  );
};

function getBusinessDaysToAdd(value) {
  const twoDayIds = ["EHR", "Enter Doctor Information", "Quest HealthPIQ"];
  const threeDayIds = ["EHR (EIS)", "EHR (HAPI)"];

  if (twoDayIds.includes(value)) {
    return 2;
  } else if (threeDayIds.includes(value)) {
    return 3;
  }

  // Default to 0 business days to add if the value is not found in any category
  return 0;
}

function addBusinessDays(startDate, businessDaysToAdd) {
  while (businessDaysToAdd > 0) {
    startDate.add(1, "d");
    if (startDate.day() !== 0 && startDate.day() !== 6) {
      businessDaysToAdd--;
    }
  }

  return startDate;
}

const _getN = str => {
  /* Input options: Insured, Insured 2, Owner, Owner 2, Owner 3 */
  if (str.indexOf(" ") !== -1) {
    return parseInt(str.split(" ")[1]);
  } else {
    return 1;
  }
};

export const getInsuredLabel = (category, insuredList) => {
  const n = _getN(category);
  const index = n - 1;
  const insured = insuredList[index];
  if (insured) {
    const formattedName = fullNameDisplay(insured.last_name, insured.first_name);
    if (formattedName) {
      return `${formattedName} (${category})`;
    }
  }

  return category;
};

export const getBeneficiaryLabel = (category, beneficiaryList) => {
  const n = _getN(category);
  const index = n - 1;
  const bene = beneficiaryList[index];
  if (bene) {
    if (bene.beneficiaryType === "entity") {
      return `${bene.entity_name} (${category})`;
    } else {
      const formattedName = fullNameDisplay(bene.last_name, bene.first_name);
      if (formattedName) {
        return `${formattedName} (${category})`;
      }
    }
  }

  return category;
};

export const getOwnerLabel = (category, ownerList, insuredList) => {
  if (category === "Owner") {
    return category;
  }
  let label = "Owner";
  let parts = category.split("-");
  let ownerType = parts[1];
  let ownerId = Number.parseInt(parts[2]);
  for (let owner of ownerList) {
    if (owner.id === ownerId && owner.owner_type === ownerType) {
      if (owner.entity_name) {
        label = owner.entity_name;
      } else {
        label = fullNameDisplay(owner.last_name, owner.first_name);
      }
      if (!owner.ownership_end_date) {
        label += " (Owner)";
      } else {
        label += " (Past Owner)";
      }
      break;
    }
  }
  return label;
};

export const getStatusFromFRID = fr_id => {
  let status = FRID_MAP[fr_id] && FRID_MAP[fr_id][0];
  return status || STATUS_CASE_TABLE_OUTSTANDING;
};

export const getTaskOwnerFromFRID = fr_id => {
  return FRID_MAP[fr_id] && FRID_MAP[fr_id][1];
};

export const getResponsiblePartyFromFRID = (fr_id, requiredOfChoices) => {
  let responsible = FRID_MAP[fr_id] && FRID_MAP[fr_id][2];
  if (responsible) {
    return getValue(requiredOfChoices, responsible);
  }
};

export const getNotesFromFRID = fr_id => {
  return NOTES_MAP[fr_id] || "";
};

export default CategorySelect;
