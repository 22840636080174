import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { editLifeExpectancy, updateLifeExpectancy } from "actions";
import {
  BooleanSelect,
  DateInput,
  FormCollapsible,
  GenderSelect,
  SSNInput,
  Select,
  SubmitButton,
  TextInput,
  EditorField,
} from "components/forms";
import { lifeExpectancyChoicesSelector, extractedLifeExpectancyPDFdataSelector } from "reducers";
import { underwritingTypeChoices, VENDORS } from "../../../../constants";
import * as notifications from "../../../../notifications";
import AgeAssessedField from "../AgeAssessedField";

const AVSForm = ({ lifeExpectancyData, ...props }) => {
  let {
    mean,
    mortality_rating,
    mortality_table_name,
    tobacco_use,
    first_name,
    middle_initial,
    last_name,
    gender,
    date_of_birth,
    age,
    medical_info,
    social_security,
    records_date_from,
    records_date_to,
    certificate_date,
    client,
    underwriter,
    underwriting_type,
  } = lifeExpectancyData;

  if (mortality_rating) {
    mortality_rating = Number(mortality_rating).toFixed(3);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        mean,
        mortality_rating,
        mortality_table_name,
        tobacco_use,
        first_name,
        middle_initial,
        last_name,
        gender,
        date_of_birth,
        age,
        medical_info,
        social_security,
        records_date_from,
        records_date_to,
        certificate_date,
        client,
        underwriter,
        underwriting_type,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          await props.updateLifeExpectancy(lifeExpectancyData.id, values);
          await props.editLifeExpectancy(lifeExpectancyData.id);
          notifications.success("AVS form saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error saving AVS form, please check form errors");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="AVS Life Expectancy">
            <Form.Row>
              <Select
                label="LE UW Type"
                name="underwriting_type" // ??
                placeholder="Select Underwriting Type"
                options={underwritingTypeChoices}
                isRequired
              />
            </Form.Row>
            <Form.Row>
              <TextInput label="Life Expectancy" name="mean" placeholder="Enter Mean LE" prepend="Months" />
              <TextInput label="Mortality" name="mortality_rating" placeholder="Enter %" append="%" />
            </Form.Row>
            <Form.Row>
              <Select
                label="Mortality Table"
                name="mortality_table_name"
                options={props.mortalityTableChoices.getChoices({
                  group: VENDORS.AVS,
                })}
              />
              <BooleanSelect label="Tobacco Use" name="tobacco_use" />
            </Form.Row>
            <Form.Row>
              <TextInput label="First Name" name="first_name" isRequired />
              <TextInput label="Middle Name" name="middle_initial" md={2} />
              <TextInput label="Last Name" name="last_name" isRequired />
            </Form.Row>
            <Form.Row>
              <GenderSelect isRequired />
              <DateInput label="Date of Birth" name="date_of_birth" isRequired />
              <AgeAssessedField {...values} />
            </Form.Row>
            <Form.Row>
              <TextInput label="Height" name="medical_info.height" />
              <DateInput label="Height Date" name="medical_info.height_date" />
              <TextInput label="Weight" name="medical_info.weight" prepend="lbs" />
              <DateInput label="Weight Date" name="medical_info.weight_date" />
            </Form.Row>
            <Form.Row>
              <SSNInput isRequired name="social_security" label="SSN" />
              <DateInput label="Meds Start Date" name="records_date_from" isRequired />
              <DateInput label="Meds End Date" name="records_date_to" isRequired />
            </Form.Row>
            <Form.Row>
              <DateInput label="Certificate Date" name="certificate_date" isRequired />
              <TextInput label="LE Client" name="client" md={4} />
              <TextInput label="Underwriter" name="underwriter" md={4} />
            </Form.Row>
            <Form.SectionTitle>Medical Info</Form.SectionTitle>
            <Form.Row>
              <TextInput label="Primary Impairment" name="medical_info.primary_impairment_category" />
            </Form.Row>
            <Form.Row>
              <EditorField label="General Summary" name="medical_info.general_summary" />
            </Form.Row>
            <Form.Row>
              <EditorField label="History" name="medical_info.history" />
            </Form.Row>
            <Form.Row>
              <EditorField label="Underwriter's Note" name="medical_info.underwriters_note" />
            </Form.Row>
            <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state, ownProps) => {
  const extractedPDFdata = extractedLifeExpectancyPDFdataSelector(state);

  // Life expectancy data will be either the extracted data from the uploaded PDF
  // or the saved LifeExpectancy instance.
  const lifeExpectancyData = extractedPDFdata || ownProps.lifeExpectancy;

  // Make sure lifeExpectancyData has an id prop, because the extractedPDFdata won't
  // have one and we need it in the form.
  const { id } = ownProps.lifeExpectancy;
  lifeExpectancyData.id = id;

  const lifeExpectancyChoices = lifeExpectancyChoicesSelector(state);

  return {
    lifeExpectancyData,
    mortalityTableChoices: lifeExpectancyChoices.mortality_table_name,
  };
};

export default connect(mapStateToProps, { updateLifeExpectancy, editLifeExpectancy })(AVSForm);
