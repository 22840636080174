import React from "react";
import IconLink from "../IconLink";
import { FaDownload, FaEye, FaFax, FaFile, FaGlasses, FaLowVision, FaTrash } from "react-icons/fa";
import { CASE_FILE_MODAL, DELETE_CASE_FILE_MODAL } from "../modals";
import { STATUS_COLORS } from "pages/cases/caseFiles/constants";
import { getLabel } from "utils.js";
import { fetchCaseFilesByCaseDocumentId } from "../../actions";
import { Link } from "react-router-dom";
import IconButton from "../IconButton";
import { IconLinkViewOrEdit } from "../icons";

export const DateFormatter = ({ fieldName, row }) => {
  if (row[fieldName]) {
    let date = row[fieldName]; // Expected format: YYYY-MM-DD
    let dateArr = date.split("-");
    let formattedDate = `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`;
    return <>{formattedDate}</>;
  } else {
    return <></>;
  }
};

export const DateOverdueFormatter = ({ fieldName, row }) => {
  if (row[fieldName]) {
    let date = row[fieldName]; // Expected format: YYYY-MM-DD
    let dateArr = date.split("-");
    let formattedDate = `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`;

    return <b style={{ color: STATUS_COLORS[row.status] }}>{formattedDate}</b>;
  } else {
    return <></>;
  }
};

export const ActionFormatter = ({
  selectCaseFile,
  showModal,
  caseFiles,
  downloadFile,
  row,
  isViewMode,
  readMode,
  fetchCaseFilesByCaseDocumentId,
}) => {
  const objectId = row.id;
  const obj = caseFiles.find(bn => bn.id === objectId);
  let file = obj && obj.file;

  const filesUrl = `/cases/${row.case}/files/`;
  let filesDetailUrl = `${filesUrl}${row.id}/`;
  if (isViewMode) {
    filesDetailUrl += "?view";
  }

  const renderFileIcons = () => {
    if (!readMode) {
      return (
        <>
          {file ? (
            <IconLink
              Icon={FaDownload}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="Download"
              to={filesUrl}
              onClick={() => {
                const url = `/life_settlement/api/case_file/${objectId}/download_file/`;
                downloadFile(url, file.name);
              }}
            />
          ) : null}
          {file ? (
            <IconLink
              Icon={FaEye}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="Preview"
              to={filesUrl}
              onClick={() => window.open(file.url, "_blank")}
            />
          ) : null}
          {row.full_redacted_file ? (
            <IconLink
              Icon={FaGlasses}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View Full Redacted File"
              onClick={() => window.open(row.full_redacted_file.url, "_blank")}
            />
          ) : null}
          {row.partial_redacted_file ? (
            <IconLink
              Icon={FaLowVision}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View Partial Redacted File"
              onClick={() => window.open(row.partial_redacted_file.url, "_blank")}
            />
          ) : null}
          {row.order_file ? (
            <IconLink
              Icon={FaFax}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View Last Fax Order File"
              onClick={() => window.open(row.order_file.url, "_blank")}
            />
          ) : null}
        </>
      );
    } else {
      return (
        file && (
          <IconButton
            Icon={FaFile}
            iconConfig={{ className: "table--action-icon", style: { cursor: "default" } }}
            tooltip="File Received"
            onClick={() => null}
          />
        )
      );
    }
  };

  function renderDeleteButton() {
    if (objectId && !readMode) {
      return (
        <IconLink
          Icon={FaTrash}
          iconConfig={{ className: "table--action-icon btn--red" }}
          tooltip="Delete"
          to={filesUrl}
          onClick={() => {
            showModal(DELETE_CASE_FILE_MODAL, {
              id: objectId,
            });
          }}
        />
      );
    } else {
      return null;
    }
  }

  return (
    <>
      <IconLinkViewOrEdit
        to={filesDetailUrl}
        onClick={() => {
          selectCaseFile(row);
          showModal(CASE_FILE_MODAL);
          fetchCaseFilesByCaseDocumentId(row.id);
        }}
      />
      {renderDeleteButton()}
      {renderFileIcons()}
    </>
  );
};

export const DropdownFormatter = ({ fieldName, choices, row }) => {
  const value = row[fieldName];
  if (choices) {
    return getLabel(choices, value) || "";
  } else {
    return "";
  }
};

export const DropdownStatusFormatter = ({ fieldName, choices, row }) => {
  const value = row[fieldName];

  if (choices) {
    return <b style={{ color: STATUS_COLORS[value] }}>{getLabel(choices, value)}</b>;
  } else {
    return "";
  }
};

export const CheckboxFormatter = ({ row }) => {
  if (row.file_redacted) {
    return <span className={"checkmark"}>✅</span>;
  } else {
    return "";
  }
};

export const NotesFormatter = ({ selectCaseFile, showModal, fieldName, row, readMode }) => {
  const value = row[fieldName];
  let ans = " ";
  const styles = {
    height: "40px",
    color: "initial",
  };
  const filesUrl = `/cases/${row.case}/files/`;
  const filesDetailUrl = `${filesUrl}${row.id}/`;

  if (value) {
    ans = value
      .replace(/(<([^>]+)>)/gi, " ")
      .replace("&amp;nbsp;", " ")
      .replace("&nbsp;", " ");
  }
  if (readMode) {
    return <p style={styles}>{ans}</p>;
  }
  return (
    <Link
      to={filesDetailUrl}
      style={{ textDecoration: "none" }}
      onClick={() => {
        fetchCaseFilesByCaseDocumentId(row.id);
        selectCaseFile(row);
        showModal(CASE_FILE_MODAL);
      }}
    >
      <p style={styles}>{ans}</p>
    </Link>
  );
};
