import React from "react";
import { connect } from "react-redux";
import { fetchCarriers, selectCarrier, showModal } from "actions";
import { activeAgencySelector, carriersSelector, loadingCarriersSelector } from "reducers";
import Table from "components/table";
import IconLink from "components/IconLink";
import { FaEdit, FaTrash } from "react-icons/fa";
import { DELETE_CARRIER_MODAL } from "components/modals";
import { PERMISSIONS, POLICY_AUTH_HANDLING_CHOICES_MAP } from "../../constants";
import { isAdmin } from "permissions";

const CarriersTable = ({ carriers, carriersLoading, activeAgency, ...props }) => {
  React.useEffect(() => {
    props.fetchCarriers();
  }, []);

  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const caseId = row.original.id;
        return (
          <>
            <IconLink
              to={`/carriers/${caseId}/overview/`}
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View / Edit"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            />
            {props.hasAdminPermission && (
              <IconLink
                Icon={FaTrash}
                iconConfig={{ className: "table--action-icon red" }}
                tooltip="Delete Carrier"
                onClick={() => props.showModal(DELETE_CARRIER_MODAL, { id: caseId })}
              />
            )}
          </>
        );
      },
    },
    {
      Header: "Carrier Name",
      accessor: "name",
    },
    {
      Header: "Short Name",
      accessor: "short_name",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Fax",
      accessor: "fax",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Address",
      accessor: "street_address",
    },
    {
      Header: "Policy Auth Handling",
      accessor: "policy_auth_handling",
      Cell: ({ row }) => {
        const policy_auth_handling_value = row.original.policy_auth_handling;
        if (policy_auth_handling_value) {
          return POLICY_AUTH_HANDLING_CHOICES_MAP[policy_auth_handling_value] || "";
        } else {
          return "";
        }
      },
    },
  ];

  return (
    <Table
      columns={columns}
      data={carriers}
      emptyMessage={carriersLoading ? "Loading Carriers data..." : "Select a Carrier"}
      defaultSort={true}
      showPaginationResults={true}
      initialPageSize={100}
      defaultPagination={true}
      sortBy={[{ id: "name", desc: false }]}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const carriers = carriersSelector(state);
  return {
    carriers: carriers,
    activeAgency: activeAgencySelector(state),
    carriersLoading: loadingCarriersSelector(state),
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, {
  fetchCarriers,
  selectCarrier,
  showModal,
})(CarriersTable);
