import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import { Form } from "react-bootstrap";
import { BooleanSelect, FormFilter, GenderSelect, Select, SubmitButton, TextInput } from "components/forms";
import { clearPredictions, getPrediction } from "../../../actions";
import { predictorVendorChoices } from "../../funders/constants";

const LifeExpectancyPredictorForm = props => {
  return (
    <Formik
      initialValues={{
        le_age: "",
        mm: "",
        vendor: predictorVendorChoices[0].value,
      }}
      validationSchema={Yup.object().shape({
        le_age: Yup.string().required("LE Age is required"),
        mm: Yup.string().required("Mortality Multiplier is required"),
      })}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={async (values, { setSubmitting }) => {
        props.getPrediction(values);
        setSubmitting(false);
      }}
      onReset={async (values, { setSubmitting }) => {
        props.clearPredictions(values);
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, resetForm, isValid }) => {
        return (
          <FormFilter title={"Parameters"} onSubmit={handleSubmit}>
            <Form.Row>
              <TextInput label="Enter LE Age" name="le_age" type="number" />
              <TextInput label="Mortality Multiplier" name="mm" placeholder="Enter %" append="%" />
              <Select
                label="Vendors"
                name="vendor"
                placeholder={"Select Vendor"}
                options={predictorVendorChoices}
                required={true}
              />
              <GenderSelect defaultValue={"M"} disabled={true} md={2} />
              <BooleanSelect label="Smoking" name="smoking" defaultValue={false} disabled={true} md={2} />
            </Form.Row>
            <SubmitButton defaultText={"Submit"} />
            <button className="btn clear-filters" onClick={resetForm} type="reset">
              Clear
            </button>
          </FormFilter>
        );
      }}
    </Formik>
  );
};

export default connect(null, {
  getPrediction,
  clearPredictions,
})(LifeExpectancyPredictorForm);
