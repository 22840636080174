export const LEAD_ROLES_CHOICES = [
  { label: "Insured & Owner", value: 1 },
  { label: "Insured", value: 2 },
  { label: "Policy Owner", value: 3 },
  { label: "Beneficiary", value: 4 },
  { label: "Trustee", value: 5 },
  { label: "Advisor", value: 6 },
  { label: "Investor", value: 7 },
];

export const MARKETING_CAMPAIGN_SOURCES = [
  { label: "Affiliate", value: 100 },
  { label: "AnswerConnect", value: 102 },
  { label: "Bing - Organic Search", value: 110 },
  { label: "Bing - Paid Search", value: 120 },
  { label: "Direct Mail", value: 130 },
  { label: "Facebook", value: 140 },
  { label: "Google - Organic Search", value: 150 },
  { label: "Google - Paid Search", value: 160 },
  { label: "Instagram", value: 170 },
  { label: "LinkedIn", value: 180 },
  { label: "Mass Email", value: 190 },
  { label: "Referral", value: 200 },
  { label: "TikTok", value: 210 },
  { label: "Unknown", value: 220 },
  { label: "X.com", value: 230 },
  { label: "Youtube", value: 240 },
];

export const MARKETING_PROPERTIES = [
  { label: "Direct", value: 100 },
  { label: "Forbes.com", value: 110 },
  { label: "LifeRocCapital.com", value: 120 },
  { label: "LifeRoc.com", value: 130 },
  { label: "Personal Book", value: 140 },
  { label: "PolicyFastCash.com", value: 150 },
  { label: "PolicyReportCard.com", value: 160 },
  { label: "Unknown", value: 170 },
];

export const MARKETING_ACTIONS = [
  { label: "Call In", value: 100 },
  { label: "Call Center Call In", value: 102 },
  { label: "E-Mail In", value: 110 },
  { label: "Mail In", value: 120 },
  { label: "Web Form", value: 130 },
];

export const LEAD_HEALTH_STATUS_OPTIONS = [
  { label: "Excellent", value: "Excellent" },
  { label: "Minor Impairments", value: "Minor Impairments" },
  { label: "Moderate Impairments", value: "Moderate Impairments" },
  { label: "Major Impairments", value: "Major Impairments" },
  { label: "Terminally Ill", value: "Terminally Ill" },
  { label: "Unknown", value: "Unknown" },
];
