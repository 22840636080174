import React from "react";
import { connect } from "react-redux";
import { ButtonCreate } from "components/buttons";
import { editCase } from "actions";
import { activeCaseSelector } from "reducers";
import { CASE_EMAIL_MODAL } from "components/modals";
import { showModal, selectCaseFile } from "../../../actions";

const SendCasePDFButton = ({ caseInfo, ...props }) => {
  if (!caseInfo) {
    // Still didn't fetch data
    return null;
  }

  return (
    <ButtonCreate
      onClick={e => {
        props.selectCaseFile(null);
        props.showModal(CASE_EMAIL_MODAL);
        e.preventDefault();
      }}
      key="create"
      style={{ marginRight: 1 + "rem" }}
      className="btn-secondary"
    >
      Send Case Status
    </ButtonCreate>
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
  };
};

export default connect(mapStateToProps, {
  editCase,
  selectCaseFile,
  showModal,
})(SendCasePDFButton);
