import React from "react";
import { connect } from "react-redux";

import { activeLeadSelector } from "reducers";
import { FaArrowLeft } from "react-icons/fa";
import { PERMISSIONS, URLS } from "../../constants";
import { Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { setShouldSave, showModal } from "actions";
import { isAdmin } from "permissions";

const LeadTopHeader = ({ leadInfo, ...props }) => {
  const history = useNavigate();

  const SaveButton = saveFn => {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: 0 }}>
        <button
          className="btn btn--secondary navbar-save-button"
          onClick={async () => {
            await props.setShouldSave();
          }}
          style={{
            width: "100%",
          }}
        >
          Save Changes
        </button>
      </div>
    );
  };

  const BackToLeadsButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: 0 }}>
        <button
          className="btn btn--secondary navbar-save-button"
          onClick={() => history(URLS.LEADS)}
          style={{
            width: "100%",
          }}
        >
          <FaArrowLeft /> Back to Leads
        </button>
      </div>
    );
  };

  let leadName = "";
  leadName += leadInfo?.first_name && leadInfo?.last_name ? ` ${leadInfo.last_name}, ${leadInfo.first_name} | ` : "";
  leadName += leadInfo?.system_id ? leadInfo.system_id : "";

  return (
    <div
      style={{
        position: "fixed",
        top: "4rem",
        left: "0rem",
        width: "100%",
        zIndex: 25,
      }}
    >
      <Card style={{ border: "1px solid black", borderRadius: "0px" }}>
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            backgroundColor: "black",
          }}
        >
          <h5
            className="section-title navbar-title"
            style={{
              color: "white",
              alignItems: "center",
              marginBottom: 0,
              lineHeight: 2,
            }}
          >
            Lead: {leadName}
          </h5>
          <div style={{ display: "flex" }}>
            <BackToLeadsButton />
            <SaveButton saveFn={props.saveFn} />
          </div>
        </Card.Header>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    hasAdminPermission: isAdmin(state),
    leadInfo: activeLeadSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  setShouldSave,
})(LeadTopHeader);
